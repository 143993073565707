import React from "react";
import HorizontalBars from "./HorizontalBars/HorizontalBars";
import convert, {
  dynamicMapping,
  getDynamicLayerKeys,
} from "./HorizontalBars/horizontalBarDataConvertor";
import { sortByLabel } from "../utils/dataOptionsValues";

export default function FixtureSalesFunnel(props) {
  const {
    filters,
    data,
    colors,
    names,
    width,
    height,
    setTooltip,
    xKey,
    yKeyInner,
    yKeyInnerValues,
    yKeyOuter,
    barDimension,
    disableRounding,
    colorFunction,
    dynamicMode,
    orderedKeys,
    meta,
    defaultFormatting,
    xLabelFormat,
    labelFormat,
    yLabelOuter,
  } = props;

  const moneyData = data.map((d) => ({ ...d, [xKey]: +d[xKey] }));

  const category = yKeyInnerValues.find(
    (yKeyInnerValue) =>
      yKeyInnerValue === "Unknown" || yKeyInnerValue === "Other"
  );

  const mapNullToUnknown = moneyData.map((md) =>
    category && !md[yKeyInner] ? { ...md, [yKeyInner]: category } : md
  );

  const filteredData = filters.length
    ? mapNullToUnknown.filter((d) =>
        filters.map((item) =>
          item.values.find((f) => d[barDimension] === f.value)
        )
      )
    : mapNullToUnknown;

  const converted = dynamicMode
    ? dynamicMapping(data, yKeyOuter, orderedKeys, names)
    : convert(
        filteredData,
        { key: yKeyOuter, outputKey: "term" },
        [yKeyInner, barDimension],
        xKey,
        yKeyInnerValues,
        yLabelOuter
      );

  const sorted = sortByLabel(converted, "term");

  const layerKeys = dynamicMode
    ? getDynamicLayerKeys(sorted)
    : filteredData
        .map((d) => d[barDimension])
        .filter((v, i, s) => s.indexOf(v) === i)
        .reverse();

  return (
    <HorizontalBars
      xKey="levelTwoFilter"
      data={converted}
      colorKeys={names}
      layerKeys={layerKeys}
      colors={colors}
      titleSize="SM"
      width={width}
      height={height}
      setTooltip={setTooltip}
      disableRounding={disableRounding}
      colorFunction={colorFunction}
      meta={meta}
      defaultFormatting={defaultFormatting}
      xLabelFormat={xLabelFormat}
      labelFormat={labelFormat}
      yLabelOuter={yLabelOuter}
    />
  );
}

import { isEmpty, isEqual, omit } from "lodash-es";
import { normalizeResponse } from "../../../store/actions/dashboard/dashboard";
import { daysOfWeek } from "../../../utils/constants/constants";
import convertFiltersToOperatorsMode, {
  convertFiltersToConfigFormat,
} from "../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import { parseExpression } from "cron-parser";
import { addHours } from "date-fns";
import format from "date-fns/format";

const EST_TIME_OFFSET = 4;

export function getBlocks(pages) {
  const blocks = pages.flatMap((page) =>
    page.blocks.map((block) => ({ ...block, pageName: page.displayName }))
  );

  const response = { data: { blocks } };
  return normalizeResponse(response, true);
}

export function buildContentOptions(blocks = []) {
  return blocks.flatMap((block) =>
    block.charts.map((chart, index) => ({
      label:
        block.pageName +
        " | " +
        (block.displayName || block.title || chart.displayName || index),
      value: chart.uuid,
    }))
  );
}

export function getChartVisualization(blocks, content, report) {
  if (!content) {
    return;
  }

  // value is visualization uuid
  const { value } = content;

  const block =
    blocks.find((block) =>
      (block.charts ?? []).find((chart) => chart.uuid === value)
    ) ?? {};

  const chart =
    (block.charts ?? []).find((chart) => chart.uuid === value) ?? {};

  const filters =
    report.filters ??
    convertFiltersToConfigFormat((report.attachments ?? [])[0]?.filters ?? []);

  function setFilters() {
    if (value !== report.visualizationUuid) {
      return chart.filters;
    }

    return filters ?? chart.filters;
  }

  return { ...chart, filters: setFilters(), immutableFilters: chart.filters };
}

export function getFrequencyTableString(report) {
  const { frequency, day, time } = parseCronExpression(report.frequency);

  const dayOf =
    frequency === "Weekly"
      ? dayOfWeekOptions.find((option) => option.value === day)?.label
      : day;

  return `${frequency} ${dayOf} ${time}`;
}

function formatParagraphValues(htmlString) {
  return (htmlString || "").replaceAll("<p>", "").replaceAll("</p>", "\n");
}

function replaceAllEndLines(value) {
  const regexp = /(.*?)\n|(.*?$)/g;
  return (value ?? "").replace(regexp, (match, group1, group2) => {
    const clear = group1 || group2;
    return clear ? `<p>${clear}</p>` : "";
  });
}

export function setAttachmentFromChart(content, filters, chart) {
  return {
    name: content.label,
    filters: filters ?? [],
    overrides: chart.overrides ?? [],
    orderBy: chart.orders ?? [],
    limit: 500,
    queryUuid: chart.queryId,
    chartFilters: chart.immutableFilters,
  };
}

export function getGeneralPayload({ chart, content, ...rest }) {
  const {
    name,
    message,
    active = false,
    day,
    frequency,
    filters,
    hour,
    uuid,
    visualizationUuid,
    attachments,
    type,
  } = rest;

  const dayNumber = day?.value ?? day;
  const cronString = generateCronExpression(frequency.value, dayNumber, hour);
  const cronValue = convertCronScheduleTimezone(cronString, EST_TIME_OFFSET);

  const updateFromChart = visualizationUuid !== chart.uuid || !uuid;
  const attach = (attachments ?? [])[0] ?? {};

  const fromChart = setAttachmentFromChart(
    content,
    convertFiltersToOperatorsMode(filters),
    chart
  );

  const attachment = updateFromChart
    ? fromChart
    : {
        ...attach,
        filters: convertFiltersToOperatorsMode(filters),
        chartFilters: attach.chartFilters ?? chart.immutableFilters,
      };

  return {
    name,
    frequency: cronValue,
    message: replaceAllEndLines(message),
    active,
    visualizationUuid: chart.uuid,
    attachments: [{ ...attachment, type: type ?? "excel" }],
  };
}

export function getConvertedReport(report) {
  if (!report) {
    return null;
  }

  const { frequency, day, time } = parseCronExpression(report.frequency);

  const dayOption =
    frequency === "Weekly"
      ? dayOfWeekOptions.find((option) => option.value === day)
      : day;

  // replaced option.value === time to includes because cron hour removing first 0 character from hour (02:00 => 2:00)
  const hourOption = timeOptions.find((option) => option.value.includes(time));

  return {
    ...report,
    active: !!report.active,
    frequency: frequencyOptions.find((option) => option.value === frequency),
    day: dayOption,
    hour: hourOption,
    groups: report.groups ?? [],
    message: formatParagraphValues(report.message),
    filters: convertFiltersToConfigFormat(report.attachments[0]?.filters),
    type: report.attachments[0]?.type ?? "excel",
  };
}

/**
 * Generates a CRON expression based on the frequency, day of week/month, and time.
 * @param {String} frequency - 'Daily', 'Weekly', or 'Monthly'
 * @param {String|Number} day - Day of week (0-6, Sunday is 0) or day of month (1-31), used for 'Weekly' or 'Monthly' frequencies.
 * @param {String} time - Time of day in the format 'HH:MM'.
 * @returns {String} CRON expression
 */
function generateCronExpression(frequency, day, time) {
  // Split the time into hours and minutes
  let [hours, minutes] = time.value.split(":");
  minutes = minutes === "00" ? "0" : minutes;
  hours = hours === "00" ? "0" : hours;

  switch (frequency) {
    case "Daily":
      // Return a cron expression that runs at the specified hours and minutes every day
      return `${minutes} ${hours} * * *`;
    case "Weekly":
      // Return a cron expression that runs at the specified hours and minutes on a specific day of the week
      return `${minutes} ${hours} * * ${day}`;
    case "Monthly":
      // Return a cron expression that runs at the specified hours and minutes on a specific day of the month
      return `${minutes} ${hours} ${day} * *`;
    default:
      return null;
  }
}

/**
 * Parses a CRON expression and returns the frequency, day, and time.
 * @param {String} cronExpression - The CRON expression to parse.
 * @returns {Object} Object containing frequency, day, and time.
 */
export function parseCronExpression(cronExpression) {
  const timeZonedCronExpression = convertCronScheduleTimezone(
    cronExpression,
    EST_TIME_OFFSET * -1
  );
  const cronParts = timeZonedCronExpression.split(" ");
  const hours = cronParts[1] === "0" ? "00" : cronParts[1];
  const minutes = cronParts[0] === "0" ? "00" : cronParts[0];
  const time = `${hours}:${minutes}`;

  if (cronParts[2] === "*" && cronParts[3] === "*" && cronParts[4] === "*") {
    // Daily cron job
    return {
      frequency: "Daily",
      time: time,
      day: "",
    };
  } else if (cronParts[2] === "*" && cronParts[3] === "*") {
    // Weekly cron job
    return {
      frequency: "Weekly",
      day: +cronParts[4],
      time: time,
    };
  } else if (cronParts[3] === "*" && cronParts[4] === "*") {
    // Monthly cron job
    return {
      frequency: "Monthly",
      day: +cronParts[2],
      time: time,
    };
  }

  // Return a default value or handle unsupported expressions
  return {
    frequency: "Unsupported",
    time: time,
  };
}

export const frequencyOptions = [
  {
    value: "Daily",
    label: "Daily",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
];

export const dayOfWeekOptions = daysOfWeek.map((day, index) => ({
  value: index,
  label: day,
}));

export const timeOptions = [
  {
    label: "00:00 AM",
    value: "00:00",
  },
  {
    label: "01:00 AM",
    value: "01:00",
  },
  {
    label: "02:00 AM",
    value: "02:00",
  },
  {
    label: "03:00 AM",
    value: "03:00",
  },
  {
    label: "04:00 AM",
    value: "04:00",
  },
  {
    label: "05:00 AM",
    value: "05:00",
  },
  {
    label: "06:00 AM",
    value: "06:00",
  },
  {
    label: "07:00 AM",
    value: "07:00",
  },
  {
    label: "08:00 AM",
    value: "08:00",
  },
  {
    label: "09:00 AM",
    value: "09:00",
  },
  {
    label: "10:00 AM",
    value: "10:00",
  },
  {
    label: "11:00 AM",
    value: "11:00",
  },
  {
    label: "12:00 PM",
    value: "12:00",
  },
  {
    label: "01:00 PM",
    value: "13:00",
  },
  {
    label: "02:00 PM",
    value: "14:00",
  },
  {
    label: "03:00 PM",
    value: "15:00",
  },
  {
    label: "04:00 PM",
    value: "16:00",
  },
  {
    label: "05:00 PM",
    value: "17:00",
  },
  {
    label: "06:00 PM",
    value: "18:00",
  },
  {
    label: "07:00 PM",
    value: "19:00",
  },
  {
    label: "08:00 PM",
    value: "20:00",
  },
  {
    label: "09:00 PM",
    value: "21:00",
  },
  {
    label: "10:00 PM",
    value: "22:00",
  },
  {
    label: "11:00 PM",
    value: "23:00",
  },
];

export function convertCronScheduleTimezone(cronString, hours) {
  const cronParts = cronString.split(" ");
  const interval = parseExpression(cronString);
  const nextOccurrenceInEST = interval.next().toDate();
  // Convert EST time to UTC
  const nextOccurrenceInUTC = addHours(nextOccurrenceInEST, hours);

  // Format the next occurrence in UTC as components
  const minute = cronParts[0] === "*" ? "*" : format(nextOccurrenceInUTC, "m");
  const hour = cronParts[1] === "*" ? "*" : format(nextOccurrenceInUTC, "H");
  const dayOfMonth =
    cronParts[2] === "*" ? "*" : format(nextOccurrenceInUTC, "d");
  const month = cronParts[3] === "*" ? "*" : format(nextOccurrenceInUTC, "M");
  const dayOfWeek =
    cronParts[4] === "*"
      ? "*"
      : format(nextOccurrenceInUTC, "i") === "7"
      ? "0"
      : format(nextOccurrenceInUTC, "i"); // Convert the days to be 0 to 6

  // Assemble the UTC cron expression
  return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
}

export function getReportPayload(report) {
  const cleared = report.url ? report : omit(report, "url");

  return {
    ...cleared,
    active: !cleared.active,
    overrides: cleared.overrides ?? [],
    filters: cleared.filters ?? [],
    attachments: cleared.attachments.map((attachment) => ({
      ...attachment,
      filters: cleared.filters ?? [],
      overrides: cleared.overrides ?? [],
      limit: 500,
    })),
  };
}

export function getDisabledSubmitStatus({
  frequency,
  content,
  reportName,
  day,
  hour,
  report,
  convertedReport,
}) {
  const nothingChanged = isEqual(report, convertedReport);

  return (
    !frequency ||
    !content ||
    !reportName ||
    (frequency.value !== "Daily" && !Number.isInteger(day?.value ?? day)) ||
    !hour ||
    nothingChanged
  );
}

export function checkObsoleteConfig(chart, report) {
  if (!chart || isEmpty(report)) {
    return;
  }

  const attahcment = (report.attachments ?? [])[0] ?? {};

  const overridesCase = !isEqual(chart.overrides, attahcment.overrides);
  const filtersCase = !isEqual(chart.immutableFilters, attahcment.chartFilters);
  const ordersCase = !isEqual(chart.orders, attahcment.orderBy);
  const queryUuidCase = chart.queryId !== attahcment.queryUuid;

  return overridesCase || filtersCase || ordersCase || queryUuidCase;
}

export function getGeneralPayloadForReports(convertedReports, chart, content) {
  return convertedReports.map((report) => {
    const extendedFilters = extendFilters(
      chart.immutableFilters,
      report.filters
    );

    return {
      ...getGeneralPayload({
        ...report,
        chart: chart,
        content,
        uuid: report.uuid,
      }),
      attachments: [
        {
          ...setAttachmentFromChart(
            content,
            convertFiltersToOperatorsMode(extendedFilters),
            chart
          ),
          type: (report?.attachments ?? [])[0]?.type ?? "excel",
        },
      ],
      uuid: report.uuid,
    };
  });
}

export function extendFilters(chartFilters, overridenFilters) {
  // the rule: exclude all filters with same names if they exist in email overriden filters
  const extendedFilters = chartFilters.filter(
    (filter) =>
      !overridenFilters.some((overriden) => overriden.type === filter.type)
  );

  return [...overridenFilters, ...extendedFilters];
}

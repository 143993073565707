import React, { useEffect, useRef } from "react";
import BigInputItem from "./BigInputItem";
import Input from "../Form/Input/Input";
import RightArea from "./RightArea";
import produce from "immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/styledSnippets";

const Clear = styled.div`
  position: absolute;
  left: 15px;
  top: 4px;
  font-size: 14px;
  color: ${themeColor("errorMain")};
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export default function RightAreaOverrideBlock(props) {
  const { overrides, setOverrides, leftData, scrollTop, addOverride } = props;
  const ref = useRef(null);
  useEffect(() => {
    ref.current.scrollTop = scrollTop;
  }, [ref, scrollTop]);

  function hasOverride(item) {
    const hasO = overrides.find((o) => o.original.id === item.id);
    return !!hasO;
  }

  function matchedOverride(item) {
    const override = overrides.find((o) => o.original.id === item.id);
    return override;
  }

  function overrideIndex(item) {
    return overrides.findIndex((o) => o.original.id === item.id);
  }

  function handleChange(item, e) {
    const nextOverrides = produce(overrides, (draft) => {
      draft[overrideIndex(item)].override.label = e.target.value;
    });
    setOverrides(nextOverrides);
  }

  function removeOverride(item) {
    const nextOverrides = overrides.filter((_, i) => i !== overrideIndex(item));
    setOverrides(nextOverrides);
  }

  return (
    <RightArea data-cy="input-right-area-override" ref={ref} hideScroll>
      {leftData.map((item) =>
        hasOverride(item) ? (
          <div style={{ margin: "3px 0", position: "relative" }} key={item.id}>
            <Clear
              data-cy="remove-override"
              onClick={() => removeOverride(item)}
            >
              <FontAwesomeIcon icon={["fal", "times-circle"]} />
            </Clear>
            <Input
              cy="override-entry"
              value={matchedOverride(item).override.label}
              onChange={(e) => handleChange(item, e)}
              transparent
              inPlaceEdit
              small
            />
          </div>
        ) : (
          <BigInputItem
            data-cy="item-override-blank"
            locked
            onClick={() => addOverride(item)}
            key={item.id}
          >
            {item.label}
          </BigInputItem>
        )
      )}
    </RightArea>
  );
}

import Flex from "../../../UI/Flex/Flex";
import React from "react";
import styled from "@emotion/styled";

const Box = styled.div(
  ({ background }) => `
  width: 20px;
  height: 20px;
  background: ${background};
  border-radius: 50%;
`
);

const Label = styled.span`
  margin: 0px 35px 0px 10px;
  font-size: 12px;
`;

export default function MultipleColorLegend({ colorsConfig }) {
  const { labels, colors } = colorsConfig;

  return (
    <Flex alignItems="center">
      {labels.map((label, index) => (
        <React.Fragment key={label}>
          <Box background={colors[index]} />
          <Label>{label}</Label>
        </React.Fragment>
      ))}
    </Flex>
  );
}

import React from "react";
import styled from "@emotion/styled";
import Table from "../../UI/Tables/Table/Table";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  display: flex;
  font-weight: 500;
  padding: 20px 0;
`;

const TableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.background.secondary};
  }
`;

export default function Alerts() {
  return (
    <Container>
      <Title>Messages</Title>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Frequency</th>
            <th>Recipients</th>
          </tr>
        </thead>
        <tbody>
          <TableRow>
            <td>some name</td>
            <td>some Description</td>
            <td>some Type</td>
            <td>some Frequency</td>
            <td>some Recipients</td>
          </TableRow>
        </tbody>
      </Table>
    </Container>
  );
}

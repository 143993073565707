import React from "react";
import { useTheme } from "emotion-theming";
import Waterfall from "./Waterfall";
import VisualizationBase from "../BaseChart/VisualizationBase";
import { mapData, orderData, margin } from "./waterfallMappings";
import { pipe } from "../../utils/func";
import { format, parseISO } from "date-fns";
import { getMappingType } from "../../utils/getVisualizationLabel";

export default function WaterfallVisualization(props) {
  const {
    visualizationId,
    data = [],
    xValueKey,
    yValueKey,
    meta,
    order,
    edgeColumns,
    positivesFirst,
    groupKey,
    groupKeyFormat,
    yAxisFormat,
  } = props.chart;
  const theme = useTheme();

  const groups = [];
  if (groupKey) {
    const groupByKey = {};

    for (const datum of data) {
      const groupString = datum[groupKey];

      let group = groupByKey[groupString];
      if (!group) {
        group = [];
        groups.push({ group: groupString, data: group });
        groupByKey[groupString] = group;
      }

      group.push(datum);
    }
  } else {
    groups.push({ group: null, data });
  }

  const mappedDataInGroups = groups.map((group) => ({
    ...group,
    data: pipe(
      orderData(
        xValueKey,
        yValueKey,
        theme,
        order,
        edgeColumns,
        positivesFirst
      ),
      mapData(yValueKey)
    )(group.data),
  }));

  const transformedDataInGroups =
    groups.length <= 1
      ? mappedDataInGroups
      : mappedDataInGroups.map((group, groupIndex) => {
          let data = [...group.data];
          const groupValue = group.group;
          const groupFormatted = groupKeyFormat
            ? format(
                groupValue instanceof Date ? groupValue : parseISO(groupValue),
                groupKeyFormat
              )
            : groups.group;
          // Just change the first x value to the formatted group.
          data[0] = { ...data[0], [xValueKey]: groupFormatted };

          const currentGroupLastValue = data[data.length - 1][yValueKey];
          const nextGroupFirstValue =
            mappedDataInGroups[groupIndex + 1]?.data[0][yValueKey];

          if (isEqualWithDelta(currentGroupLastValue, nextGroupFirstValue, 1)) {
            // Remove the "Ending" bar for this group, because it matches with the "Beginning" value of the
            // next group that's directly to the right of it.
            data = data.slice(0, data.length - 1);
          }

          return { group: groupValue, data };
        });

  const mappedData = transformedDataInGroups.map((v) => v.data).flat();

  return (
    <div id={visualizationId} style={{ position: "relative" }}>
      <VisualizationBase {...{ ...props, margin }}>
        <Waterfall
          data={mappedData}
          xValueKey={xValueKey}
          yValueKey={yValueKey}
          meta={meta}
          yAxisFormat={yAxisFormat || getMappingType(meta?.fields, yValueKey)}
        />
      </VisualizationBase>
    </div>
  );
}

function isEqualWithDelta(num1, num2, delta) {
  if (num1 === num2) {
    return true;
  }
  return num1 != null && num2 != null && Math.abs(num1 - num2) <= delta;
}

import React, { useState, useEffect } from "react";
import { useTheme } from "emotion-theming";
import Select from "react-select";
import { useHistory } from "react-router";
import { customStyles } from "../../styles/customSelectStyles";
import { groupBy, orderBy } from "lodash-es";
import styled from "@emotion/styled";

// nested options styles
const Conteiner = styled.div`
  div:last-of-type * {
    padding-bottom: 0px;
    & > div:last-of-type {
      & > div {
        padding-left: 8px;
      }
    }
  }

  div[class*="-group"] {
    padding-bottom: 5px;
    padding-left: 4px;
  }

  div[class*="-option"] {
    padding-bottom: 8px;
    padding-left: 0px;
  }

  div[class*="-indicatorContainer"] {
    padding-bottom: 8px;
  }
`;

export default function ResponsiveTabs({ options, baseUrl }) {
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    const route = history.location.pathname.split("/");
    const item = route[route.length - 1];

    setValue(options.find((option) => item === option.slug));
  }, [history.location.pathname, options]);

  const [value, setValue] = useState();
  const styles = customStyles(theme);

  const handleChange = (value) => {
    history.push(value.link || "/" + baseUrl + "/" + value.slug);
    setValue(value);
  };

  const groupedObject = groupBy(options, "parent");
  function convertGroupedToArray() {
    return Object.keys(groupedObject).flatMap((key) =>
      key
        ? {
            label: key,
            options: groupedObject[key],
            position: groupedObject[key][0].position,
          }
        : groupedObject[key]
    );
  }
  const groupedArray = convertGroupedToArray();
  const orderedOptions = orderBy(groupedArray, "position");

  return (
    <Conteiner>
      <Select
        options={orderedOptions}
        styles={styles}
        value={value}
        getOptionLabel={(option) => option.displayName}
        onChange={(value) => handleChange(value)}
        getOptionValue={(option) => option.uuid}
      />
    </Conteiner>
  );
}

import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import { usePathName } from "../../hooks/usePathName";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../store/actions";
import { defaultLogoSrc } from "../../Layout/LeftMenu/HomeLogo";

export const dataMenuWidth = "68px";

const DataMenuContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: ${dataMenuWidth};
  background: black;
  position: fixed;
  min-height: 100vh;
  z-index: 100000;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    height: 100vh;
    position: absolute;
    top: 0;
    transform: ${(props) =>
      props.show ? "translateX(0)" : "translateX(-100%)"};
  }
`;

const MenuItem = styled.div`
  padding: 18px 4px;
  margin: 8px 0;
  border-radius: 6px;
  font-size: 12px;
  width: 50px;
  box-sizing: border-box;
  color: ${({ active, theme }) => (active ? "white" : theme.gray.gray500)};
  background-color: ${(props) =>
    props.active &&
    (props.theme.type === "dark"
      ? lightenDarkenColor(props.theme.background.secondary, 7)
      : props.theme.gray.gray800)};
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: ${(props) => props.theme.primary};
    color: white;
  }
`;

const Home = styled.div`
  background: ${(props) =>
    props.theme.type === "dark" ? props.theme.background.secondary : "black"};
  //border: 6px solid ${(props) => props.theme.primary};
  padding: 3px;
  box-sizing: border-box;
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export default function DataMenu({
  handleClick,
  active,
  list,
  logo,
  show,
  setShow,
}) {
  const node = useRef();
  const isMobile = useMediaQuery({
    query: `(max-width: ${mobileBreakpoints.mobile})`,
  });
  useOnClickOutside(node, () => isMobile && setShow());
  const defaultDashboardTab = usePathName();
  const tabs = useSelector((state) => state.layout.tabs);
  const dispatch = useDispatch();

  const onTitleClick = () => {
    if (defaultDashboardTab) {
      const link = defaultDashboardTab.split("/").pop();
      const tab = tabs.find((tab) => tab.slug === link);
      dispatch(setActiveTab(tab));
    }
  };

  const logoSrc = logo ?? defaultLogoSrc;

  return (
    <DataMenuContainer show={show} ref={node}>
      <Home onClick={onTitleClick}>
        <NavLink to="/">
          <img
            data-cy="site-logo"
            src={logoSrc}
            alt="Insight Out"
            width="100%"
            height="auto"
          />
        </NavLink>
      </Home>

      {list.map((item) => (
        <NavLink
          to={item.link}
          key={item.label}
          onClick={() => handleClick(item)}
        >
          <MenuItem
            data-cy="admin-menu-item"
            active={active.includes(item.link)}
          >
            {item.label}
          </MenuItem>
        </NavLink>
      ))}
    </DataMenuContainer>
  );
}

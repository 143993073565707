import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { NavLink, useHistory } from "react-router-dom";

const Container = styled.div`
  display: flex;
`;

const Tab = styled.div`
  display: flex;
  margin: 10px 20px;
  width: ${(props) => 100 / props.count}%;
  white-space: nowrap;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & > a {
    color: ${(props) => props.theme.text.secondary};
    padding-bottom: 10px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    opacity: 0.5;
  }
`;

export default function Tabs({ tabs }) {
  const theme = useTheme();
  const history = useHistory();

  const activeTab = {
    borderBottom: `4px solid ${theme.primary}`,
    opacity: 1,
  };

  if (!tabs.length) return null;

  return (
    <Container>
      {tabs.map((tab) => (
        <Tab count={tabs.length} key={tab.path}>
          <NavLink
            to={{ pathname: tab.path, state: history.location.state }}
            activeStyle={activeTab}
          >
            {tab.label}
          </NavLink>
        </Tab>
      ))}
    </Container>
  );
}

Tabs.defaultProps = {
  tabs: [],
};

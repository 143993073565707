import React from "react";
// import avImage from "./assistant.png";
import avImage from "./assistant-b.png";
import avImageSad from "./assistant-b-sad.png";
import styled from "@emotion/styled";

const settings = {
  message: {
    marginRight: 12,
    size: 50,
    containerSize: 80,
    left: 11,
    bottom: -32,
  },
  assistant: {
    marginRight: 0,
    size: 80,
    containerSize: 76,
    left: 2,
    bottom: -8,
  },
  large: {
    marginRight: 24,
    size: 100,
    containerSize: 120,
    left: 18,
    bottom: -20,
  },
  override: {
    marginRight: 24,
    size: 100,
    containerSize: 190,
    left: 0,
    bottom: -100,
  },
};

const AssistantMask = styled.div`
  height: ${({ mode, override }) => settings[override || mode].size}px;
  width: ${({ mode, override }) => settings[override || mode].size}px;
  border-radius: 50%;
  overflow: ${(props) => (props.mode === "assistant" ? "show" : "hidden")};
  background: ${(props) =>
    props.theme.notification[props.error ? "warningMain" : "infoMain"]};
  position: relative;
  margin-right: ${({ mode }) => settings[mode].marginRight}px;
  flex-shrink: 0;
`;

const Container = styled.div`
  width: ${({ mode, override }) => settings[override || mode].containerSize}px;
  height: ${({ mode, override }) => settings[override || mode].containerSize}px;
  ${(props) =>
    props.mode === "message" || props.mode === "large"
      ? `
    background: url(${props.image}) no-repeat;
    background-size: ${
      props.override ? 95 : props.mode === "large" ? 60 : 28
    }px;
        background-position: 0% 0%;
  `
      : ""}
  position: absolute;
  bottom: ${({ mode, override }) => settings[override || mode].bottom}px;
  left: ${({ mode, override }) => settings[override || mode].left}px;
`;

const ImgContainer = styled.div`
  position: relative;
  left: 12px;
  top: -10px;
`;

function AssistantChip(props) {
  const mode = props.large ? "large" : props.message ? "message" : "assistant";

  return (
    <AssistantMask
      mode={mode}
      error={props.error}
      override={props.imageOverride ? "override" : null}
    >
      <Container
        image={
          props.imageOverride
            ? props.imageOverride
            : props.error
            ? avImageSad
            : avImage
        }
        mode={mode}
        override={props.imageOverride ? "override" : null}
      />
      {mode === "assistant" ? (
        <ImgContainer>
          <img
            src={props.error ? avImageSad : avImage}
            alt="robot"
            width={56}
            height="auto"
          />
        </ImgContainer>
      ) : null}
    </AssistantMask>
  );
}

export default AssistantChip;

import React from "react";

export default function ProductIgnoreSummary(props) {
  const { values } = props;
  return (
    <div
      style={{
        marginTop: 24,
        fontWeight: 300,
        lineHeight: "190%",
        fontSize: 12,
      }}
    >
      {values
        .filter((v, i) => i < 6)
        .map((v, i) => (
          <div key={i}>{v.label}</div>
        ))}
      {values.length > 5 ? (
        <div style={{ opacity: 0.5, fontSize: 12, marginTop: 12 }}>more...</div>
      ) : null}
    </div>
  );
}

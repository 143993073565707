import styled from "@emotion/styled";
import { themeGrayIf } from "../../styles/styledSnippets";

export default styled.div`
  border: 1px solid ${themeGrayIf(800, 300)};
  height: ${(props) => (props.hasLabels ? "calc(100% - 26px)" : "100%")};
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  & > div {
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    padding: 12px 0;
  }
`;

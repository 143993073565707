import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../UI/Flex/Flex";
import {
  addRecipient,
  deleteRecipient,
} from "../../../store/actions/automation";
import ChipList from "../components/ChipList";

export default function Recipients({
  users,
  recipients,
  dispatch,
  uuid,
  recipientLoading,
}) {
  function addMore(user) {
    dispatch(addRecipient(uuid, { name: user.name, email: user.email }));
  }

  const allUsers = (users ?? []).filter(
    (user) => !recipients.some((recipient) => recipient.email === user.email)
  );

  function deleteUser(user) {
    dispatch(deleteRecipient(uuid, user.uuid));
  }

  return (
    <div>
      <div style={{ fontSize: 12, marginBottom: 10, marginTop: 7 }}>
        Recipients
        {recipientLoading && (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            pulse
            style={{ marginLeft: 10 }}
          />
        )}
      </div>
      <Flex style={{ width: "100%" }} alignItems="center">
        <ChipList
          all={allUsers}
          visible={recipients}
          addMore={addMore}
          width={20}
          height={20}
          fontSize={11}
          limit={7}
          deleteItem={deleteUser}
          loading={recipientLoading}
        />
      </Flex>
    </div>
  );
}

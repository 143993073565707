import React from "react";
import video from "../../background.mp4";
import styled from "@emotion/styled";
import logo from "../../styles/io-logo-white.png";

const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;

const Overlay = styled.div`
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  background-color: #333;
  border-radius: 20px;
  color: white;
  font-size: 28px;
  margin: 20px auto;
  padding: 20px;
  text-decoration: none;
`;

export default () => {
  const hostname = window.location.host;
  const protocol = window.location.protocol;

  return (
    <>
      <Video autoPlay={true} muted loop id="background">
        <source src={video} type="video/mp4" />
      </Video>
      <Overlay />
      <Container>
        <img src={logo} alt="Insight Out" />
        <Link href={protocol + "//demo." + hostname}>Go to Demo</Link>
      </Container>
    </>
  );
};

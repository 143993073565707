import * as actionTypes from "../actions/actionTypes";

/**
 * @typedef {"success"|"info"|"danger"|"warning"} MessageType
 */

export const dismissToast = (id) => {
  return {
    type: actionTypes.REMOVE_TOAST,
    id,
  };
};

export const showToast = (action, dispatch, message, type) => {
  if (typeof message === "string") {
    message = [message];
  }
  message.map((error, index) =>
    dispatch({
      type: action,
      meta: {
        toasts: [
          {
            id: action + index,
            title: type === "danger" ? "Error" : "Success",
            message: error,
            type,
            condition: action,
          },
        ],
      },
    })
  );
};

let autoIncrementingId = 0;

/**
 * @param dispatch
 * @param message {string}
 * @param type {MessageType}
 */
export const showToastWithTimeout = (dispatch, message, type) => {
  if (Array.isArray(message)) {
    return message.map((message) =>
      showToastWithTimeout(dispatch, message, type)
    );
  }

  const titles = {
    danger: "Error",
    warning: "Warning",
    success: "Success",
    info: "Info",
  };

  const id = `toast-${++autoIncrementingId}`;
  dispatch({
    type: actionTypes.SHOW_ACTION_TOAST_MESSAGE,
    meta: {
      toasts: [
        {
          id,
          title: titles[type],
          message,
          type,
          condition: actionTypes.SHOW_ACTION_TOAST_MESSAGE,
        },
      ],
    },
  });
  setTimeout(() => {
    dismissToast(id);
  }, 5000);

  return id;
};

export const showActionToast = (message, link, settings) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_ACTION_TOAST_MESSAGE,
    message,
    link,
    settings,
  });
};
export const hideActionToast = () => (dispatch) =>
  dispatch({ type: actionTypes.HIDE_ACTION_TOAST_MESSAGE });

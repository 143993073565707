import React, { useEffect, useState, useCallback, useRef } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import ToPortal from "../../etc/ToPortal";

const Container = styled.div`
  opacity: 1;
  transition: all 0.2s;
`;

const Overlay = styled.div(
  ({ theme }) => `
    background-color: ${theme.background.overlay};
    opacity: 0.8;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 500000;
    transition: all 0.3s;
`
);

const ModalContent = styled.div(
  ({ theme, fullScreen, width, rounded }) => `
    max-height: 90vh;
    background-color: ${theme.background.primary};
    box-shadow: 0px 2px 5px #333;
    color: ${theme.text.primary};
    width: ${fullScreen ? "90%" : width || "800px"};;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 600000;
    padding: 20px 0;
    transition: all 0.3s;
    border-radius: ${rounded ? 20 : 2}px;
`
);

const ModalTitle = styled.p(
  ({ theme, xPadding, textAlign }) => `
    border-bottom: 1px solid ${
      theme.type === "dark" ? theme.gray.gray800 : theme.gray.gray300
    };
    color: ${theme.text.primary};
    font-size: 18px;
    padding: 0 ${xPadding}px 14px ${xPadding}px;
    text-align: ${textAlign};
`
);

const Close = styled.p(`
  position: absolute;
  right: 20px;
  top: 0px;
  padding: 5px;
  cursor: pointer;
`);

export default function Modal(props) {
  const {
    title,
    close,
    preventClose,
    fullScreen,
    overrideShow,
    setOverrideShow,
    renderInPortal,
    controlledFromOutside,
    showClose,
    width,
    rounded,
    xPadding = 18,
    titleTextAlign = "center",
    additionalPortal,
  } = props;
  const modalPortalRef = useRef();
  if (!modalPortalRef.current) {
    modalPortalRef.current = document.getElementById("modal-portal");
  }
  const [show, setShow] = useState(false);
  const modalShow = overrideShow || show;
  const setModalShow = setOverrideShow || setShow;

  const onClose = useCallback(() => {
    if (preventClose) {
      return;
    }
    if (!controlledFromOutside) {
      setModalShow(false);
    } else {
      close();
    }
  }, [preventClose, controlledFromOutside, setModalShow, close]);

  const escDown = useCallback(
    (e) => {
      if (e.key !== "Escape") return;
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    setModalShow(true);
  }, [setModalShow]);

  useEffect(() => {
    window.addEventListener("keydown", escDown, false);
    return () => {
      window.removeEventListener("keydown", escDown, false);
    };
  }, [escDown, setOverrideShow]);

  const transitionEnd = () => {
    if (modalShow === false) {
      close();
    }
  };

  const modalContainer = (
    <Container style={{ opacity: modalShow ? "1" : "0" }}>
      <Overlay
        style={{ opacity: modalShow ? "0.8" : "0" }}
        onClick={onClose}
        data-cy="modal-overlay"
      />
      <ModalContent
        role="dialog"
        style={{ top: modalShow ? "50%" : "30%" }}
        data-cy={props.cy || props["data-cy"] || "modal-content-wrapper"}
        fullScreen={fullScreen}
        onTransitionEnd={transitionEnd}
        width={width}
        rounded={rounded}
      >
        {showClose ? (
          <Close onClick={onClose}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </Close>
        ) : null}
        {title ? (
          <ModalTitle xPadding={xPadding} textAlign={titleTextAlign}>
            {title}
          </ModalTitle>
        ) : null}
        <div style={{ padding: `0 ${xPadding}px `, overflow: "auto" }}>
          {React.Children.map(props.children, (child) => {
            return (
              child && {
                ...child,
                props: { ...child.props, onClose },
              }
            );
          })}
        </div>
      </ModalContent>
      {additionalPortal ? (
        <div
          id={additionalPortal}
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: 600001,
          }}
        />
      ) : null}
    </Container>
  );

  return renderInPortal ? (
    <ToPortal portalRef={modalPortalRef}>{modalContainer}</ToPortal>
  ) : (
    modalContainer
  );
}

Modal.propTypes = {
  cy: PropTypes.string,
  close: PropTypes.func,
  preventClose: PropTypes.bool,
  fullScreen: PropTypes.bool,
  title: PropTypes.string,
  renderInPortal: PropTypes.bool,
  width: PropTypes.string,
  rounded: PropTypes.bool,
  titleTextAlign: PropTypes.string,
};

import { showToastWithTimeout } from "../actions/message";
import { handleError, normalizeError } from "../../utils/errorHandling";
import { wrapResult } from "../../utils/apiResultWrapper";

export default (store) => (next) => (action) => {
  if (action.meta && action.meta.api) {
    next(action);
    const promise = action
      .apiClient({
        method: action.meta.api.method,
        url: action.meta.api.endpoint,
        data: action.meta.api.payload,
        params: action.meta.api.params,
        signal: action.meta.api.signal,
        responseType: action.meta.api.file ? "blob" : "json",
      })
      .then((res) => {
        if (
          action.meta.api.file &&
          (action.meta.api.apiImage || res.status !== 202)
        ) {
          const fileName = action.meta.api.fileName;
          const format =
            action.meta.api.format === "excel" ||
            action.meta.api.format === "fancy-excel"
              ? ".xlsx"
              : `.${action.meta.api.format}`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + format);
          link.click();
        }
        next({
          ...action,
          type: action.type.replace("_START", "_SUCCESS"),
          results: { ...res.data, duration: res.config.duration },
        });

        return res;
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          next({ type: action.type.replace("_START", "_FAIL") });
          store.dispatch({
            type: "AUTH_FAIL",
            meta: {
              token: {
                destroy: true,
              },
            },
          });

          throw err;
        }

        if (err.message === "canceled") {
          throw err;
          // take no action, this is only used for abort controllers now
        }

        const normalizedError = normalizeError(err);
        next({
          ...action,
          type: action.type.replace("_START", "_FAIL"),
          error: normalizedError,
          duration: err.duration,
        });

        if (action.meta.api.toastOnFailure) {
          const errors = normalizedError.errors ?? normalizedError.data;
          // Validation errors.
          const handleErrors = errors
            ? Object.values(errors).map((error) => error[0])
            : normalizedError.message;

          showToastWithTimeout(next, handleErrors, "danger");
        }
        handleError(next, err, {
          showToast: false,
          reportAllErrors: action.meta.api.reportAllErrors,
        });

        throw err;
      });
    return wrapResult(promise);
  } else {
    next(action);
  }
};

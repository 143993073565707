import React from "react";
import styled from "@emotion/styled";

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  div {
    cursor: pointer;
  }
`;

const Option = styled.div`
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.text.primary : props.theme.text.secondary};
  height: 10px;
  width: 10px;
  margin: 10px 8px 10px 2px;
  &:hover {
    div {
      background: ${(props) => props.theme.text.primary};
    }
  }
  div {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: relative;
    top: 1px;
    left: 1px;
    background: ${(props) =>
      props.active ? props.theme.text.primary : "none"};
  }
`;

export default function ValueKeyOption(props) {
  const { label, handleSelect, active, cy } = props;
  return (
    <OptionsContainer onClick={handleSelect} data-cy={cy}>
      <Option active={active}>
        <div />
      </Option>
      <div>{label}</div>
    </OptionsContainer>
  );
}

import React from "react";
import { isFlashGovernance } from "../../utils/constants/constants";
import DownloadLink from "./DownloadLink";

export default function CustomDownloads(props) {
  return (
    props.domain === isFlashGovernance && (
      <DownloadLink
        url="/flash_governance/5C3ABDE8-898E-42F6-A8A9-6248E042C5C4"
        text="Governance Report"
      />
    )
  );
}

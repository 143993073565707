import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Container = styled.div`
  text-align: right;
  margin-top: 104px;
  cursor: pointer;
  span {
    font-size: 18px;
  }
  svg {
    font-size: 36px;
    margin-left: 14px;
    position: relative;
    top: -3px;
  }
  path {
    fill: ${(props) => props.theme.notification.infoMain};
  }
`;

export default function LoadExistingMessage(props) {
  const { show, onClick } = props;
  if (!show) return null;

  return (
    <Container data-cy="load-existing-config" onClick={onClick}>
      <span>...or load an existing configuration</span>
      <FontAwesomeIcon icon={["fas", "satellite-dish"]} />
    </Container>
  );
}

import React, { useState } from "react";
import useToggleArray from "../../hooks/useToggleArray";
import LeftRightAreaContext from "./LeftRightAreaContext";
import { omit } from "lodash-es";

export default function LeftRightAreaForm(props) {
  const {
    initThirdValues = {},
    initRightArray,
    initOverrides = [],
    initFolders = [],
    initColors = [],
    handleSave,
    tripleMode,
    folderMode,
    overrideMode,
    colorMode,
  } = props;

  // Internal Form State
  const [rightArray, setRightArray] = useToggleArray(initRightArray);
  const [active, setActive] = useState(null);
  const [folders, setFolders] = useState(initFolders);
  const [thirdValues, setThirdValues] = useState(initThirdValues);
  const [overrides, setOverrides] = useState(initOverrides);
  const [colors, setColors] = useState(initColors);

  function prepareSave() {
    if (tripleMode) {
      handleSave(thirdValues);
    } else if (folderMode) {
      handleSave(folders);
    } else if (colorMode) {
      handleSave(colors);
    } else if (overrideMode) {
      handleSave(overrides);
    } else {
      handleSave(rightArray);
    }
  }

  function handleSetThirdValues(key, value) {
    const currentValues = thirdValues[key] || [];
    const nextValues = [...currentValues, value];
    setThirdValues((state) => ({ ...state, [key]: nextValues }));
  }

  function removeThirdValue(key, value) {
    const nextValues = thirdValues[key].filter((v) => v.id !== value.id);
    setThirdValues((state) => ({ ...state, [key]: nextValues }));
  }

  function cleanupRemoved(active) {
    // remove group and all children values under it
    if (folderMode) {
      setFolders((folders) => folders.filter((_, index) => index !== active));
    }
    // this is not regular setState function, check hook above
    setRightArray(active);
    // also we need to remove key from thirdValues object
    setThirdValues((state) => omit(state, active.id));
  }

  return (
    <LeftRightAreaContext.Provider
      value={{
        rightArray,
        setRightArray,
        active,
        setActive,
        folders,
        setFolders,
        thirdValues,
        colors,
        handleSetThirdValues,
        removeThirdValue,
        cleanupRemoved,
        handleSave,
        prepareSave,
        tripleMode,
        folderMode,
        overrideMode,
        overrides,
        setOverrides,
        colorMode,
        setColors,
      }}
    >
      {props.children}
    </LeftRightAreaContext.Provider>
  );
}

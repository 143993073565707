import React from "react";
import styled from "@emotion/styled";
import IOButton from "../../../UI/Form/Button/IOButton";
import Select from "../../../UI/Form/Select/Select";
import Input from "../../../UI/Form/Input/Input";
import { formats } from "../../../utils/constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Overrides = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FormatOverride = styled.div`
  border: 1px solid ${(props) => props.theme.divider};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 10px;
  margin: 1% 1% 1% 0;
  position: relative;
  & > div {
    margin-bottom: 20px;
  }
  & > svg {
    cursor: pointer;
    top: 5px;
    right: 5px;
    position: absolute;
    color: ${(props) => props.theme.notification.errorMain};
    font-size: 12px;
  }
`;

export default function FormatOverrides({ state, setState, fields }) {
  const addFormatOverride = () => {
    const formatOverrides = [...state.chart.formatOverrides];
    formatOverrides.push({
      conditionKey: null,
      conditionValue: "",
      overrideKey: "",
      overrideFormat: null,
    });

    setState({ ...state, chart: { ...state.chart, formatOverrides } });
  };

  const handleChange = (value, index, key) => {
    const formatOverrides = [...state.chart.formatOverrides];
    formatOverrides[index][key] = value;

    setState({ ...state, chart: { ...state.chart, formatOverrides } });
  };

  const removeFormatOverride = (index) => {
    setState({
      ...state,
      chart: {
        ...state.chart,
        formatOverrides: state.chart.formatOverrides.filter(
          (_, i) => i !== index
        ),
      },
    });
  };

  return (
    <Container>
      <IOButton
        style={{ width: "fit-content", alignSelf: "flex-end" }}
        smallPadding
        onClick={addFormatOverride}
        add
        outline
        disabled={!state.chart.formatOverrides}
      >
        Add Format Override
      </IOButton>

      <Overrides>
        {state.chart.formatOverrides?.map((fo, index) => (
          <FormatOverride key={"format_override_" + index}>
            <FontAwesomeIcon
              icon={["fa", "times"]}
              onClick={() => removeFormatOverride(index)}
            />
            <Select
              options={fields}
              getOptionValue={(o) => o.dataSourceFieldUuid}
              getOptionLabel={(o) => o.name}
              onChange={(o) => handleChange(o.name, index, "conditionKey")}
              isClearable={false}
              label="Condition Key"
            />
            <Input
              label="Condition Value"
              onChange={({ target }) =>
                handleChange(target.value, index, "conditionValue")
              }
              value={fo.conditionValue}
            />
            <Input
              label="Override Key"
              onChange={({ target }) =>
                handleChange(target.value, index, "overrideKey")
              }
              value={fo.overrideKey}
            />
            <Select
              label="Override Format"
              options={formats}
              placeholder="Formats"
              onChange={(o) => handleChange(o.value, index, "overrideFormat")}
            />
          </FormatOverride>
        ))}
      </Overrides>
    </Container>
  );
}

import styled from "@emotion/styled";
import Table from "../../../../UI/Tables/Table/Table";
import { scrollbarDe } from "../../../../styles/styledSnippets";

const Container = styled.div`
  margin: 20px 0px;
  overflow: auto;
  ${(props) => scrollbarDe(props.theme)};
  height: calc(100vh - 210px);
  width: calc(100vw - 100px);
`;

const Thead = styled.thead(
  ({ theme }) => `
  background:  ${theme.background.secondary};
  position: sticky;
  top: 0;
`
);

const Tbody = styled.tbody(
  ({ theme }) => `
  td {
    font-size: 12px;
  }
  tr:nth-of-type(even) {
    background-color: ${theme.background.secondary};
  }
`
);

export default function BlueprintSampleTable(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const columns = Object.keys(data[0] ?? {});

  return (
    <Container>
      <Table border>
        <Thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <tr key={row.uniqueRowUuid}>
              {columns.map((column, index) => (
                <td key={row[column] + index}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </Tbody>
      </Table>
    </Container>
  );
}

import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDrillDownDetails } from "../../../store/actions";
import { scrollbarDe } from "../../../styles/styledSnippets";
import Flex from "../../../UI/Flex/Flex";
import formatter from "../../../utils/formatters/formatter";
import getVisualizationLabel, {
  getMappingType,
} from "../../../utils/getVisualizationLabel";

const Container = styled.div`
  max-height: 120px;
  overflow-y: auto;
  ${(props) => scrollbarDe(props.theme)};
  font-size: 11px;
`;

const DrillDownItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const geoMapSelector = (state) => state.geoMap;

export default function MoreDetails({ activePopup, details }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { loading, drillDownDetails } = useSelector(geoMapSelector);
  const { dynamicFilters, primaryKey, valueKeys } = details ?? {};

  useEffect(() => {
    if (details) {
      const filters = (dynamicFilters ?? []).reduce((acc, curr) => {
        acc.push({ type: curr, value: activePopup[curr] });
        return acc;
      }, []);

      dispatch(loadDrillDownDetails(filters, details));
    }
  }, [activePopup, details, dispatch, dynamicFilters]);

  if (!details || !drillDownDetails) {
    return null;
  }

  const { data, meta } = drillDownDetails;

  const getFormattedRowData = (key, item) => {
    const label = getVisualizationLabel(meta.fields, key);
    const type = getMappingType(meta.fields, key);

    return `${label}: ${formatter(item[key], type)}`;
  };

  const renderDetails = (row) => {
    return valueKeys.map((key) => (
      <span key={row[primaryKey] + key}>{getFormattedRowData(key, row)}</span>
    ));
  };

  return (
    <>
      <hr color={theme.gray.gray300} />
      {loading ? (
        <Flex justifyContent="center">Loading Drill down details...</Flex>
      ) : (
        <Container>
          <Flex direction="column">
            {data.map((row) => (
              <DrillDownItem key={row[primaryKey]}>
                {primaryKey && (
                  <span style={{ fontWeight: 600 }}>{row[primaryKey]}</span>
                )}
                {renderDetails(row)}
              </DrillDownItem>
            ))}
          </Flex>
        </Container>
      )}
    </>
  );
}

import React from "react";
import { Tooltip, Marker } from "react-leaflet";
import L from "leaflet";

export default function TriangleMarker({
  dynamicColor,
  colorBand,
  d,
  colorLabel,
  colorKey,
  opacity,
  labeledColumns,
  showTooltip,
  setActivePopup,
}) {
  const triangleSvgIcon = `
    <svg id="triangle" viewBox="0 0 100 100" fill=${
      dynamicColor || colorBand(colorLabel || d[colorKey])
    } fill-opacity=${opacity()}>
    <polygon points="50 15, 100 100, 0 100" />
  </svg>`;

  const icon = L.divIcon({
    html: triangleSvgIcon,
    iconSize: 25,
  });

  return (
    <Marker position={d.center} icon={icon} onClick={() => setActivePopup(d)}>
      {showTooltip && (
        <Tooltip direction="bottom" offset={[0, 20]} permanent>
          {labeledColumns.map((column) => `${d[column]} `)}
        </Tooltip>
      )}
    </Marker>
  );
}

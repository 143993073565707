import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../../../UI/Form/Input/Input";
import { FieldWrapper } from "../GlobalSettings";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";

export default function Limit({ chart, setChartState, isParameterized, Note }) {
  const { limit, enablePagination, queryLevelPaginatedSorting } = chart;

  if (!limit && limit !== "") {
    return null;
  }

  const setPagination = () => {
    setChartState({
      ...chart,
      enablePagination: !enablePagination,
    });
  };

  const setQueryLevelPaginatedSorting = () => {
    setChartState({
      ...chart,
      setQueryLevelPaginatedSorting: !setQueryLevelPaginatedSorting,
    });
  };

  const setLimitValue = (e) => {
    const value = e.target.value.trim();
    if (Number.isSafeInteger(+value)) {
      setChartState({ ...chart, limit: value });
    }
  };

  return (
    <>
      <FieldWrapper>
        <Input
          value={limit}
          onChange={setLimitValue}
          style={{ width: "100%", marginTop: 15 }}
        />
      </FieldWrapper>
      {isParameterized && (
        <Note color="warningMain">
          This chart using Parameterized query, make sure that query accepts
          limit as parameter
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
        </Note>
      )}
      <br />
      <Checkbox
        label="Enable Pagination"
        checked={!!enablePagination}
        onChange={setPagination}
      />
      <br />
      {isParameterized && (
        <>
          <Checkbox
            label="Enable Query Level Paginated Sorting"
            checked={!!queryLevelPaginatedSorting}
            onChange={setQueryLevelPaginatedSorting}
          />
          <Note color="warningMain">
            This chart using Parameterized query, make sure that query accepts
            sortBy and sortASCDESC as parameter
            <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
          </Note>
        </>
      )}
    </>
  );
}

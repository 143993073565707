import RadioButton from "../../../../../../UI/RadioButton/RadioButton";

export default function PlainText(props) {
  const { selectedColumn, chart, setChartState } = props;
  const { enumColorsSettings, progressCellFields } = chart;

  const getChecked = () => {
    return (
      !progressCellFields?.includes(selectedColumn.column) &&
      !enumColorsSettings?.find(
        (settings) => settings.column === selectedColumn.column
      )
    );
  };

  const dropSpecialSettings = () => {
    setChartState({
      ...chart,
      progressCellFields: (chart.progressCellFields || []).filter(
        (field) => field !== selectedColumn.column
      ),
      enumColorsSettings: (chart.enumColorsSettings || []).filter(
        (field) => field.column !== selectedColumn.column
      ),
    });
  };

  return (
    <RadioButton
      name="Plain Text"
      checked={getChecked()}
      onChange={dropSpecialSettings}
      size={17}
      offset={5}
    />
  );
}

import React from "react";
import Error from "../../../../UI/Form/Error";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Back = styled.div`
  margin-top: 28px;
  text-align: left;
  svg {
    margin-right: 4px;
  }
`;

export default function ExplorerRunError(props) {
  const { error } = props;

  if (!error) return null;

  return (
    <div style={{ margin: "18px 0", display: "inline-block" }}>
      <Error icon bg>
        <div>
          <span style={{ fontSize: 18, lineHeight: "200%" }}>
            There was an issue running this query
          </span>{" "}
          <br />
          {error}
        </div>
      </Error>
      <Back>
        <Link to={"/admin/data-explorer"}>
          <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Go Back
        </Link>
      </Back>
    </div>
  );
}

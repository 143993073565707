import React from "react";
import BigInputItem from "./BigInputItem";
import RightArea from "./RightArea";

export default function ThirdAreaBlock(props) {
  const { tripleMode, active, thirdValues, removeThirdValue } = props;

  function handleRemoveThirdItem(item) {
    removeThirdValue(active.id, item);
  }

  if (!tripleMode) return null;

  return (
    <RightArea data-cy="input-third-area" third>
      {active && thirdValues[active.id]
        ? thirdValues[active.id].map((item, i) => (
            <BigInputItem
              key={item.id || i}
              data-cy="item-third-location"
              onClick={() => {
                handleRemoveThirdItem(item);
              }}
              noArrow
            >
              {item.label}
            </BigInputItem>
          ))
        : null}
    </RightArea>
  );
}

import {
  GET_THEME_CONFIGURATION_START,
  GET_THEME_CONFIGURATION_SUCCESS,
  GET_THEME_CONFIGURATION_FAIL,
  CHANGE_THEME_CONFIGURATION_START,
  CHANGE_THEME_CONFIGURATION_SUCCESS,
  CHANGE_THEME_CONFIGURATION_FAIL,
} from "./actionTypes";

export const getThemeConfiguration =
  (subdomain = "sentrics") =>
  async (dispatch) => {
    dispatch({ type: GET_THEME_CONFIGURATION_START });

    try {
      const themeConf = await new Promise((resolve, reject) => {
        const config = {
          globalOverride: null,
          localOverrides: null,
          chartOverrides: null,
          theme: "dark",
        };
        setTimeout(() => resolve(config), 1000);
      });

      return dispatch({
        type: GET_THEME_CONFIGURATION_SUCCESS,
        config: themeConf,
      });
    } catch (err) {
      localStorage.clear();
      return dispatch({
        type: GET_THEME_CONFIGURATION_FAIL,
        error: err.message,
      });
    }
  };

export const changeThemeConfiguration = (config) => async (dispatch) => {
  dispatch({ type: CHANGE_THEME_CONFIGURATION_START });

  try {
    const newConfig = await new Promise((resolve, reject) => {
      resolve(config);
    });

    return dispatch({
      type: CHANGE_THEME_CONFIGURATION_SUCCESS,
      config: newConfig,
    });
  } catch (err) {
    return dispatch({
      type: CHANGE_THEME_CONFIGURATION_FAIL,
      error: err.message,
    });
  }
};

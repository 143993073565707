import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getRidOfAggregation } from "../../../charts/TableView/Elements/EditableMenu";
import ManualGroupingPosition from "../SettingsMenu/Layout/Column/ManualGroupingPosition";
import { columnToLabel } from "../SettingsMenu/Layout/helper";

const Columns = styled.div(
  ({ theme, isDragging }) => `
  display: flex;
  gap: 1rem;
  padding: 1rem 0 0.75rem;

  ${isDragging && `border: 2px dashed ${theme.primary}`};
`
);

export const Column = styled.div(
  ({ theme, shake, isActive, width, maxWidth }) => `
  padding: 5px 20px;
  background: ${isActive ? theme.primary : theme.blueGray.blueGray500};
  white-space: nowrap;
  position: relative;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;

  ${
    shake &&
    `
    animation: shake 0.2s;
    animation-iteration-count: infinite;
  `
  };

  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(0.5deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-0.5deg); }
    100% { transform: rotate(0deg); }
  }

  & > svg {
    color: ${theme.notification.errorMain};
    position: absolute;
    top: -12px;
    right: -15px;
    z-index: 1;
    cursor: pointer;

  }
  ${
    width &&
    `
    min-width: ${width}px;
  `
  };

  ${
    maxWidth &&
    `
    max-width: ${maxWidth}px;
  `
  }
`
);

export default function SubTitles({
  setChartState,
  selectedColumn,
  setSelectedColumn,
  removeColumn,
  removeGroupingEffect,
  subTitles,
  overrides,
  dynamicColumnsWidth,
  generalColumnsWidth = {},
  groupIndex,
  setSelectedGroup,
  staticGroupingKeys,
  chart,
  setNavigationState,
  fields,
}) {
  const {
    column: sColumn,
    ops: sOps,
    subIndex: sSubIndex,
    index: sIndex,
  } = selectedColumn ?? {};

  const [isDragging, setIsDragging] = useState(false);

  const onDragEnd = useCallback(
    ({ destination, source }, index) => {
      if (!destination) {
        return;
      }

      const start = source.index;
      const end = destination.index;

      const columns = [...subTitles[index]];

      const [column] = columns.splice(start, 1);
      columns.splice(end, 0, column);

      setChartState({
        ...chart,
        subTitles: chart.subTitles.map((sub, i) =>
          i === index ? columns : sub
        ),
      });

      setIsDragging(false);
    },
    [setChartState, subTitles, chart]
  );

  const setColumn = (column, subIndex, index) => {
    const currentIndex = subIndex === sSubIndex && index === sIndex;

    if ((sColumn === column || sOps?.alias === column) && currentIndex) {
      setSelectedColumn(null);
      return;
    }

    const nonAggregatedColumn = getRidOfAggregation(column, overrides);

    function getColumn(fields = []) {
      return fields.find(
        (field) =>
          field.name === column ||
          field.name === nonAggregatedColumn ||
          field.ops?.alias === column
      );
    }

    const override = getColumn(overrides) ?? getColumn(fields);

    setSelectedColumn({ ...override, column, subIndex, index });
    setNavigationState("Column");
    setSelectedGroup && setSelectedGroup(null);
  };

  function getActiveColumn(column, subIndex, index) {
    return (
      (sColumn === column || sOps?.alias === column) &&
      subIndex === sSubIndex &&
      index === sIndex
    );
  }

  function renderColumn(sub, subIndex) {
    return (
      <DragDropContext
        onDragEnd={(props) => onDragEnd(props, subIndex)}
        key={"sub" + subIndex}
        onDragStart={() => setIsDragging(true)}
      >
        <Droppable droppableId="droppable2" direction="horizontal">
          {(provided) => (
            <Columns
              {...provided.droppableProps}
              ref={provided.innerRef}
              isDragging={isDragging}
            >
              {(sub || []).map((column, index) => (
                <Draggable
                  key={column + index}
                  draggableId={`column${index}`}
                  index={index}
                >
                  {(provided) => (
                    <Column
                      margin={!staticGroupingKeys}
                      shake={removeGroupingEffect}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => setColumn(column, subIndex, index)}
                      isActive={getActiveColumn(column, subIndex, index)}
                      width={
                        (dynamicColumnsWidth && dynamicColumnsWidth[column]) ||
                        generalColumnsWidth
                      }
                      data-cy="layout-subtitle-button"
                    >
                      {removeGroupingEffect && (
                        <FontAwesomeIcon
                          icon={["fas", "times-circle"]}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeColumn(chart, subIndex, index);
                          }}
                        />
                      )}
                      {columnToLabel(overrides, column)}
                      <ManualGroupingPosition
                        subTitles={subTitles}
                        setChartState={setChartState}
                        staticGroupingKeys={staticGroupingKeys}
                        subIndex={subIndex}
                        index={index}
                        column={column}
                        chart={chart}
                      />
                    </Column>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Columns>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return subTitles.map((sub, subIndex) => {
    if (Number.isInteger(groupIndex)) {
      return groupIndex === subIndex ? renderColumn(sub, subIndex) : null;
    }

    return renderColumn(sub, subIndex);
  });
}

import React from "react";
import { Route } from "../../../utils/router";
import Insights from "./Insights";
import { calculateInsightsForQuery } from "../../../store/actions/insights";
import { useDispatch, useSelector } from "react-redux";

const emptyArray = Object.freeze([]);

export default function InsightsManager(props) {
  const { filterApi, selectedQuery } = props;
  const dispatch = useDispatch();
  const insightsState = useSelector((state) => state.insights);

  function runQuery(insightConfig) {
    dispatch(
      calculateInsightsForQuery(
        selectedQuery,
        filterApi.effectiveSettings,
        insightConfig
      )
    );
  }

  return (
    <Route
      path={[
        "/admin/data-explorer/new/:id/insights",
        "/admin/data-explorer/:id/insights",
      ]}
      render={() => (
        <Insights
          fields={filterApi.settings?.overrides.fields ?? emptyArray}
          runQuery={runQuery}
          insightsState={insightsState}
        />
      )}
    />
  );
}

import { GeoJSON } from "react-leaflet";
import { useCallback, useMemo } from "react";
import { useTheme } from "emotion-theming";
import { v4 as uuid } from "uuid";
import { extent } from "d3-array";
import { scalePow } from "d3-scale";

export default function ChoroplethGeoJson({
  data,
  setActivePopup,
  colorBand,
  choroplethValueKey,
  countyKey,
  stateKey,
  geoJson,
  choroplethType,
  exponentMultiplier = 1,
}) {
  const theme = useTheme();

  const getGeoByKeys = useCallback(
    (geo, item) => {
      const { NAME, STATEFP } = geo.properties;
      // for counties need compare names and states
      if (choroplethType === "counties") {
        return NAME === item[countyKey] && STATEFP === item[stateKey];
      }

      // for states need compare only names
      return NAME === item[stateKey];
    },
    [choroplethType, countyKey, stateKey]
  );

  const [min, max] = extent(data, (item) => item[choroplethValueKey]);
  const normalizeValue = useMemo(() => {
    const absoluteMax = Math.max(Math.abs(min), max);

    return scalePow()
      .exponent(exponentMultiplier)
      .domain([-absoluteMax, absoluteMax])
      .range([0, 1]);
  }, [exponentMultiplier, max, min]);

  const geoJsonData = useMemo(() => {
    if (!geoJson) {
      return data;
    }

    return data.map((item) => {
      const countyCoordsItem =
        geoJson.features.find((geo) => getGeoByKeys(geo, item)) ?? {};

      return { ...countyCoordsItem, properties: item };
    });
  }, [data, geoJson, getGeoByKeys]);

  const setStyle = useCallback(
    (feature) => {
      const normalizedValue = normalizeValue(
        feature.properties[choroplethValueKey]
      );

      return {
        fillColor: colorBand(normalizedValue),
        weight: 1,
        opacity: 1,
        dashArray: "",
        color: theme.divider,
        fillOpacity: 1,
      };
    },
    [choroplethValueKey, colorBand, normalizeValue, theme.divider]
  );

  const highlightFeature = useCallback(
    (event) => {
      const layer = event.target;

      layer.setStyle({
        weight: 1,
        color: theme.primary,
        dashArray: "",
        fillOpacity: 1,
      });

      layer.bringToFront();
    },
    [theme.primary]
  );

  const resetHighlight = useCallback(
    (event) => {
      const layer = event.target;
      const getDefaultStyle = setStyle(layer.feature);
      layer.setStyle(getDefaultStyle);
      layer.bringToBack();
    },
    [setStyle]
  );

  const showPopup = useCallback(
    (feature, layer) => {
      const { lat, lng } = layer.getBounds().getCenter();
      setActivePopup({ lat, lng, ...feature.properties });
    },
    [setActivePopup]
  );

  const onEachFeature = useCallback(
    (feature, layer) => {
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: () => showPopup(feature, layer),
      });
    },
    [highlightFeature, resetHighlight, showPopup]
  );

  return (
    <GeoJSON
      key={uuid()}
      data={geoJsonData}
      onEachFeature={onEachFeature}
      style={setStyle}
    />
  );
}

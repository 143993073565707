import React from "react";
import PropTypes from "prop-types";
import { useTransition, animated } from "react-spring";

export default function EnterLeft({ show, children }) {
  const transitions = useTransition(show, {
    from: {
      opacity: 0,
      position: "absolute",
      left: "calc(50% - 200px)",
      top: -50,
    },
    enter: { opacity: 1, top: 10 },
    leave: { opacity: 0, top: 200 },
  });

  return (
    <div style={{ position: "relative" }}>
      {transitions(
        (styles, item) =>
          item && <animated.div style={styles}>{children}</animated.div>
      )}
    </div>
  );
}

EnterLeft.propTypes = {
  show: PropTypes.bool,
};

export default (
  data,
  levelOneGrouping,
  levelTwoGroupingKeys,
  valueKey = "MaxTotalRevenue",
  customOrder,
  yLabelOuter
) => {
  const [key0, key1] = levelTwoGroupingKeys;

  const levelTwoKeys = data.map((d) => d[key1]).filter(unique);

  const levelTwoValues = (filtered) => {
    // Calculate keys for this grouping only
    const levelTwoZeroKeys = customOrder
      ? customOrder
      : filtered.map((f) => f[key0]).filter(unique);

    return levelTwoZeroKeys.map((z) =>
      levelTwoKeys.reduce(matchFiltered(filtered, z), { levelTwoFilter: z })
    );
  };

  const matchFiltered = (filtered, z) => (acc, curr) => {
    const found = filtered.find((f) => f[key0] === z && f[key1] === curr);
    return { ...acc, [curr]: found && found[valueKey] };
  };

  const filteredDataValues = (levelOneKey) => {
    const filtered = data.filter(
      (d) => d[levelOneGrouping.key] === levelOneKey
    );
    return levelTwoValues(filtered);
  };

  const allKeys = data.map((d) => d[levelOneGrouping.key]).filter(unique);

  return allKeys.map((k) => ({
    [levelOneGrouping.outputKey]: k ?? yLabelOuter,
    values: filteredDataValues(k),
  }));
};

const unique = (v, i, s) => s.indexOf(v) === i;

export const dynamicMapping = (data, yKeyOuter, orderKeys, names) => {
  return data.map((d) => ({
    term: d[yKeyOuter],
    values: Object.keys(d)
      .filter((key) => key !== yKeyOuter)
      .map((_, i) => ({
        [orderKeys[i]]: d[orderKeys[i]],
        levelTwoFilter: orderKeys[i],
        label: names[i],
      })),
  }));
};

export const getDynamicLayerKeys = (data) =>
  data[0]?.values.map((val) => val.levelTwoFilter) || [];

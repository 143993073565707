import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTheme } from "emotion-theming";
import { getLimitable, getLimitedValue } from "./functions/tableCellHelper";

const textLimit = 100;

export default function TableMainCellLink(props) {
  const {
    linkColumns = [],
    rawValue,
    value,
    cellKey,
    dataRow,
    pinIcon,
    noTotalCalcKeys,
    rowMainKey,
  } = props;
  const theme = useTheme();
  const isMatch = linkColumns.find((col) => col.key === cellKey);

  const isLink = isMatch && dataRow[isMatch.urlKey];

  const pinnedStyles = {
    color: theme.primary,
    marginRight: 10,
    marginTop: 1,
    transform: "rotate(45deg)",
  };

  const limitable = getLimitable(value);

  const attributes = {
    "data-value": Number.isNaN(rawValue)
      ? null
      : rawValue
      ? rawValue.toString().replace(">", "")
      : null,
    ...(limitable && { title: value }),
  };

  const applySpecialReplacement = () => {
    const isCellKeyInIgnoreList = (noTotalCalcKeys ?? []).includes(cellKey);
    const isTotalRow = dataRow[rowMainKey] === "Total";

    return isCellKeyInIgnoreList && isTotalRow
      ? "--"
      : getLimitedValue(limitable, value);
  };

  function onCopy() {
    const selection = window.getSelection().toString();

    if (limitable && selection.length === textLimit) {
      return navigator.clipboard.writeText(value);
    }

    navigator.clipboard.writeText(selection);
  }

  return isLink ? (
    <a href={dataRow[isMatch.urlKey]} target="_new" {...attributes}>
      {value}
    </a>
  ) : (
    <span style={{ width: "100%" }} {...attributes} onCopy={onCopy}>
      {pinIcon ? (
        <FontAwesomeIcon icon={["fa", "thumbtack"]} style={pinnedStyles} />
      ) : null}
      {applySpecialReplacement()}
    </span>
  );
}

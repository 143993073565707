export default (data, rowGroupKey) => (row) => {
  const rowValues = row.subTitles.reduce((acc, curr) => {
    if (!curr) return acc;

    curr.forEach((current) => {
      const keys = current.split("::");
      const matchType = getMatchType(keys);

      if (matchType === "string match") {
        acc[rowGroupKey] = keys[2];
      }

      if (matchType === "where match") {
        const newValues = getValuesForFnWhere(keys, matchSubtitleKeysToData);
        acc = { ...acc, ...newValues };
      }
    });
    return acc;
  }, {});

  function matchSubtitleKeysToData(acc, curr, subTitleIndex) {
    const { keyMatch, rowMatched } = findMatchedRow(curr, data, rowGroupKey);
    const draftKey = keyMatch + "-" + subTitleIndex;
    const draftValue = rowMatched && rowMatched.values[0][keyMatch];
    return { ...acc, [draftKey]: draftValue };
  }

  const subTitleOverrides = row.subTitles.reduce((acc, curr) => {
    if (!curr) return [""];

    const subTitle = curr.map(buildSubTitle);
    acc.push(subTitle);
    return acc;
  }, []);

  return {
    [rowGroupKey]: row.rowValue,
    values: [rowValues],
    subTitleOverrides,
  };
};

function getMatchType(keys) {
  if (keys[1] === "STRING") return "string match";
  if (keys[0] === "fnWhere") return "where match";
  return false;
}

function getValuesForFnWhere(keys, matchKeysToData) {
  const getValuesFor = keys.filter((_, i) => i > 1);
  return getValuesFor.reduce(matchKeysToData, {});
}

function buildSubTitle(st) {
  const newValue = st.replace(/ *\([^)]*\)/g, "");
  const noWhere = newValue.replace("fnWhere", "fn");
  return noWhere.split("::").map(rowTitleMatcher).join("::");
}

function findMatchedRow(curr, data, rowGroupKey) {
  const rowMatcher = curr.match(/\(([^)]+)\)/)[1];
  const keyMatch = curr.split("(", 1)[0];

  const rowMatchedRow = data.rows.find((d) => d[rowGroupKey] === rowMatcher);
  return { keyMatch, rowMatched: rowMatchedRow };
}

function rowTitleMatcher(s, i) {
  return i > 1 ? s + "-" + (i - 2) : s;
}

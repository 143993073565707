import React from "react";
import { TreeIcon } from "./TtgFeature";
import { faMobile as farMobile } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageDisplayName({ page }) {
  if (!page) {
    return null;
  }
  return (
    <>
      {page.displayName}
      {page.mobile && (
        <>
          {" "}
          <FontAwesomeIcon
            icon={farMobile}
            title="This page be visible only on mobile"
          />
        </>
      )}
      {page.ttg && (
        <>
          {" "}
          <TreeIcon title="This page is visible to TTG users only" />
        </>
      )}
    </>
  );
}

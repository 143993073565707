import React, { useMemo, useEffect } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { Route } from "../../utils/router";
import { useDispatch } from "react-redux";
import { usePathName } from "../../hooks/usePathName";
import { parse } from "qs";
import ExternalGoogleIdTokenPage from "../Login/Google/ExternalGoogleIdTokenPage";
import { useShallowEqualSelector } from "../../store";
import { setActiveTab } from "../../store/actions";

export default ({ component: Component, ...rest }) => {
  const { isAuthenticated, tabs, activeTab } = useShallowEqualSelector(
    (state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      activeTab: state.layout.activeTab,
      tabs: state.layout.tabs,
    })
  );

  const dispatch = useDispatch();
  const history = useHistory();
  // Get default location if needed
  const path = usePathName();
  // Get current location for redirects
  const location = useLocation();
  const pathname = location.pathname;
  const query = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );
  const urlBlocks = pathname.split("/");
  const dashboard = urlBlocks[1] === "dashboard" ? urlBlocks[2] : null;

  // this hack need for table editor, when you come from each page to edit the table default filters from this page
  // will apply, for such tables like TTM when MonthYear is required
  const from = location.state?.prevPath;

  // when user logout from some tab which has default menu filter selected
  // and then login again we need to update activeTab to do not apply filter from tab which user logged out
  useEffect(() => {
    if (dashboard && activeTab?.slug !== dashboard) {
      const tab = tabs.find((t) => t.slug === dashboard || t.slug === from);
      const nonMobiles = tabs.filter((tab) => !tab.mobile);

      if (tab?.mobile && nonMobiles.length) {
        history.push(nonMobiles[0].slug);
        return dispatch(setActiveTab(nonMobiles[0]));
      }

      dispatch(setActiveTab(tab));
    }
  }, [dashboard, tabs, activeTab, dispatch, from, history]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} {...rest} pathname={path} />
        ) : query.googleIdToken ? (
          <ExternalGoogleIdTokenPage token={query.googleIdToken} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location, dashboard, hitPrivate: true },
            }}
          />
        )
      }
    />
  );
};

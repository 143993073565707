import axios from "../../../axios";
import * as actionTypes from "../actionTypes";
import convertDataSetup from "./convertDataSetup";
import { getPages } from "../pageManagement";
import { v4 as uuid } from "uuid";
import {
  convertBlueprintResponse,
  makeBlueprintPayload,
} from "./blueprintHelpers";
import { clone, upperFirst } from "lodash-es";
import { showToastWithTimeout } from "../message";
import { normalizeError } from "../../../utils/errorHandling";

export const createWarehouse = () => async (dispatch) => {
  // What does this need to do
};

const database = "super_duper_data";

const connection = "C68B429C-C88E-4FF5-9103-6A9D2A227776";

// export const getRawValues = () => async (dispatch, getState) => {
//   // Get for products
//   const opportunityTable = "etl.stg_delta_SalesforceOpportunity";
//   const productColumn = "Description";
//   const endpoint = `/api/v1/connections/${connection}/warehouse/distinct-column-values?dbName=${database}`;
//   const res = await axios.get(
//     `${endpoint}&tableName=${opportunityTable}&columnName=${productColumn}`
//   );
//   // const dataSetup = getState().dataSetup;
//   // console.log({ dataSetup });
// };

export const getSampleValuesFromStagingTables = (table, connectionUuid) => {
  const activeConnection = connectionUuid || connection;
  const t = connectionUuid ? table : "etl." + table;
  return {
    type: actionTypes.GET_SAMPLE_VALUES_FROM_STAGING_START,
    meta: {
      api: {
        endpoint: `api/v1/connections/${activeConnection}/data?tableName=${t}&dbName=${database}`,
        method: "GET",
        toastOnFailure: true,
      },
    },
  };
};

function mapNames(arr) {
  return arr.map((n, i) => ({ id: n, label: n }));
  // return arr.map((n, i) => ({ id: uuid(), label: n }));
}

export const initSourceValues = () => async (dispatch, getState) => {
  const productValues = await uniqueValues(
    "etl.stg_delta_SalesforceOpportunity",
    "CustomFieldProductType__c"
  );
  const filtered = productValues.data.data.filter((d) => d);
  const products = mapNames(filtered);

  dispatch({
    type: actionTypes.LOAD_SEED_STAGING_DATA,
    values: { product: products },
  });
};

const uniqueValues = async (table, column) => {
  const endpointRoot = `api/v1/connections/${connection}/warehouse/distinct-column-values`;
  const tableName = `tableName=${table}`;
  const columnName = `columnName=${column}`;
  const dbName = `dbName=${database}`;
  return await axios.get(
    `${endpointRoot}?${tableName}&${columnName}&${dbName}`
  );
};

export const getUniqueColumnValuesFromStagingTables = (table, column) => {
  const endpointRoot = `api/v1/connections/${connection}/warehouse/distinct-column-values`;
  const tableName = `tableName=etl.${table}`;
  const columnName = `columnName=${column.name}`;
  const dbName = `dbName=${database}`;
  return {
    type: actionTypes.GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_START,
    meta: {
      api: {
        endpoint: `${endpointRoot}?${tableName}&${columnName}&${dbName}`,
        method: "GET",
      },
    },
  };
};

export const clearSource = () => {
  return {
    type: actionTypes.CLEAR_SOURCE_DATA,
  };
};

function emptyFn() {}

// No Hands
export const initDataSetup =
  (stepManager = emptyFn) =>
  async (dispatch) => {
    const connectionResult = await axios.get("api/v1/connections");
    stepManager("connections loaded");
    const connectionUuid = connectionResult.data.data[1].uuid;
    // const databaseResult = await axios.get(
    //   `api/v1/connections/${connectionUuid}/databases`
    // );
    // Load from 'set' database name, this is now optional
    const tableResult = await axios.get(
      `api/v1/connections/${connectionUuid}/tables?dbName=${database}`
    );
    const allTables = tableResult.data.data;

    const stagingTables = allTables
      .filter((table) => table.namespace === "etl")
      .map((t) => ({ name: t.name, columns: t.columns }));

    const dwTables = allTables
      .filter((table) => table.namespace === "dw")
      .map((t) => ({ name: t.name, columns: t.columns }));

    stepManager("init complete");

    dispatch(loadDataStructure(connectionUuid));

    return dispatch({
      type: actionTypes.LOAD_STAGING_TABLES_COMPLETE,
      stagingTables,
      dwTables,
      connection: connectionUuid,
    });
  };

// (No Hands)
export const initSalesforceToStaging = () => async (dispatch, getState) => {
  const connectionResult = await axios.get("api/v1/connections");
  const fromConnectionUuid = connectionResult.data.data[0].uuid;
  const whConnectionUuid = connectionResult.data.data[1].uuid;

  const database = "super_duper_data";

  await axios.post(`/api/v1/connections/${whConnectionUuid}/warehouse`, {
    dbName: database,
    fillFrom: { connectionUuid: fromConnectionUuid },
  });

  dispatch({
    type: actionTypes.COMPLETE_WAREHOUSE_CREATE,
    connection: whConnectionUuid,
  });
};

// (No Hands)
export const loadDataStructure =
  (connectionUuid) => async (dispatch, getState) => {
    let whConnectionUuid;
    const { connection } = getState().dataSetup;
    if (!connection) {
      const connectionResult = await axios.get("api/v1/connections");
      whConnectionUuid = connectionResult.data.data[1].uuid;
    }
    // call connection if not already called... this I need to learn from Attila how we do this
    return dispatch({
      type: actionTypes.LOAD_DATA_STRUCTURE_START,
      meta: {
        api: {
          method: "GET",
          endpoint: `api/v1/connections/${
            connectionUuid || connection || whConnectionUuid
          }/warehouse/settings`,
        },
      },
    });
  };

// (No Hands)
export const updateDataStructure = (product) => async (dispatch, getState) => {
  const { structure, connection } = getState().dataSetup;
  const nextProduct = convertDataSetup("to", "product", product);
  const nextStructure = {
    ...structure,
    product: { ...nextProduct, values: structure.product.values },
  };
  return dispatch({
    type: actionTypes.POST_DATA_STRUCTURE_START,
    meta: {
      api: {
        method: "POST",
        endpoint: `/api/v1/connections/${connection}/warehouse/structure`,
        payload: { ...nextStructure },
      },
    },
  }).then((result) => {
    dispatch(getPages());
    return result;
  });
};

export const initBlueprint =
  (setLoadingState, setError) => async (dispatch) => {
    const connectionResult = await axios.get("api/v1/connections");
    setLoadingState("Connections loaded. Scanning warehouse tables.", true);
    const defaultConnection = connectionResult.data.data.find(
      (c) => c.primaryWarehouse
    );
    if (!defaultConnection) {
      return setError("There is no default connection set. Go get that done.");
    }
    const defaultDb = defaultConnection.database;
    const tables = await axios.get(
      `api/v1/connections/${defaultConnection.uuid}/tables?dbName=${defaultDb}`
    );
    setLoadingState("Tables loaded. Analyzing unique values.", true);
    const warehouseTables = tables.data.data;

    dispatch({
      type: actionTypes.LOAD_TABLES_FROM_WAREHOUSE,
      results: warehouseTables,
      defaultConnection,
      defaultDb,
    });

    const blueprintResponse = await axios.get("api/v1/etl-blueprints");
    setLoadingState("Blueprints loaded. Loading chain nodes.", true);

    if (!blueprintResponse.data.data.length) {
      dispatch({ type: actionTypes.BLUEPRINTS_INIT_COMPLETE });
      return setLoadingState(
        "Waiting for initial blueprint configuration.",
        false
      );
    }

    const blueprintChainResponse = await axios.get(
      "api/v1/etl-blueprint-chains"
    );

    setLoadingState("Chains loaded. Loading connections.", true);
    const blueprintChains = convertBlueprintResponse(
      blueprintChainResponse.data.data
    );
    setLoadingState(false);

    // dispatch to loading complete
    dispatch({
      type: actionTypes.LOAD_BLUEPRINTS,
      blueprints: blueprintChains,
      etlBlueprints: blueprintResponse.data.data,
      etlChains: blueprintChainResponse.data.data,
    });
    dispatch({ type: actionTypes.BLUEPRINTS_INIT_COMPLETE });
  };

export const createBlueprint =
  (name, table, field, connection, db, setLoading) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CREATE_BLUEPRINT_START });
      const etlBlueprint = await axios.post("api/v1/etl-blueprints", {
        name,
        preset: "excludes",
        groupId: uuid(),
        settings: { excludes: [{ originalValue: "placeholder" }] },
      });

      // Handle processing and send to redux store
      const payload = {
        name,
        connectionUuid: connection?.uuid,
        dbName: db,
        tableName: "dw." + table,
        createResultTable: false,
        removeIntermediateViews: false,
        steps: [
          {
            name: "System Generated Step Excludes",
            etlBlueprintUuid: etlBlueprint.data.data.uuid,
            otherInput: {
              rawColumnName: field,
            },
          },
        ],
      };

      await axios.post("api/v1/etl-blueprint-chains", payload);
      const blueprintResponse = await axios.get("api/v1/etl-blueprints");

      const blueprintChainResponse = await axios.get(
        "api/v1/etl-blueprint-chains"
      );

      const blueprintChains = convertBlueprintResponse(
        blueprintChainResponse.data.data
      );

      dispatch({
        type: actionTypes.CREATE_BLUEPRINT_SUCCESS,
        blueprints: blueprintChains,
        etlBlueprints: blueprintResponse.data.data,
        etlChains: blueprintChainResponse.data.data,
      });
      setLoading("Ready");
      showToastWithTimeout(dispatch, "Blueprint Input Created.", "success");
    } catch (err) {
      dispatch({
        type: actionTypes.CREATE_BLUEPRINT_FAIL,
      });
      showToastWithTimeout(dispatch, [normalizeError(err).message], "danger");
    }
  };

const loadBlueprints = () => async (dispatch) => {
  const blueprintResponse = await axios.get("api/v1/etl-blueprints");

  const blueprintChainResponse = await axios.get("api/v1/etl-blueprint-chains");

  const blueprintChains = convertBlueprintResponse(
    blueprintChainResponse.data.data
  );

  dispatch({
    type: actionTypes.CREATE_BLUEPRINT_SUCCESS,
    blueprints: blueprintChains,
    etlBlueprints: blueprintResponse.data.data,
    etlChains: blueprintChainResponse.data.data,
  });
};

// build step object for payload
function getStepProps(chain, index, outputColumnName) {
  const step = chain.steps[index] ?? chain.steps[0];

  let otherInput = step.otherInput;

  if (step.blueprint.preset === "grouping" && outputColumnName) {
    otherInput = { ...step.otherInput, outputColumnName };
  }

  return {
    name: step.name,
    customOutputName: step.customOutputName,
    otherInput,
  };
}

function getChainProps(chain) {
  return {
    name: chain.name,
    connectionUuid: chain.connectionUuid,
    dbName: chain.dbName,
    tableName: chain.tableName,
    createResultsTable: chain.createResultsTable,
    outputname: chain.tableName + "_clean",
    removeIntermediateViews: chain.removeIntermediateViews,
  };
}

const updateChains = (chainsToUpdate, allBlueprintUuids, outputColumnName) => {
  return Promise.all(
    chainsToUpdate.map((chain) => {
      const updatedChain = {
        ...getChainProps(chain),
        steps: allBlueprintUuids.map((u, index) => ({
          ...getStepProps(chain, index, outputColumnName),
          etlBlueprintUuid: u,
        })),
        connectionUuid: chain.connection.uuid,
      };
      const updatedChainClone = clone(updatedChain);
      updatedChainClone.steps.forEach((step) => {
        if (step.otherInput?.customOutputName) {
          delete step.otherInput.customOutputName;
        }
      });
      return axios.put(
        `/api/v1/etl-blueprint-chains/${chain.uuid}`,
        updatedChainClone
      );
    })
  );
};

export const saveBlueprintInput =
  (groupId, table, field, complete, chainUuid) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.SAVE_BLUEPRINT_INPUT_START });
    const { etlBlueprints, defaultConnection, defaultDb } =
      getState().dataSetup;

    const activeBlueprints = etlBlueprints.filter((b) => b.groupId === groupId);
    const name = "System Generated Step";
    const steps = activeBlueprints.map((b) => ({
      name: `${name} ${upperFirst(b.preset)}`,
      etlBlueprintUuid: b.uuid,
      otherInput: {
        rawColumnName: field,
      },
    }));

    const payload = {
      name,
      connectionUuid: defaultConnection?.uuid,
      dbName: defaultDb,
      tableName: "dw." + table,
      createResultTable: false,
      removeIntermediateViews: false,
      steps,
    };

    await axios({
      method: chainUuid ? "PUT" : "POST",
      url: chainUuid
        ? `api/v1/etl-blueprint-chains/${chainUuid}`
        : "api/v1/etl-blueprint-chains",
      data: payload,
    });

    const blueprintChainResponse = await axios.get(
      "api/v1/etl-blueprint-chains"
    );
    const chains = blueprintChainResponse.data.data;
    dispatch({
      type: actionTypes.SAVE_BLUEPRINT_INPUT_SUCCESS,
      etlChains: chains,
      blueprints: convertBlueprintResponse(chains),
    });
    complete();
  };

export const deleteBlueprintInput = (uuid) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_INPUT_CHAIN_START, uuid });

  try {
    await axios.delete(`/api/v1/etl-blueprint-chains/${uuid}`);

    const chains = await axios.get("api/v1/etl-blueprint-chains");
    const blueprintChains = convertBlueprintResponse(chains.data.data);

    dispatch({
      type: actionTypes.DELETE_INPUT_CHAIN_SUCCESS,
      uuid,
      chains: chains.data.data,
      blueprintChains,
    });
  } catch (err) {
    dispatch({ type: actionTypes.DELETE_INPUT_CHAIN_FAIL, uuid });
  }
};

// TODO: remove this if not needed
export const deleteBlueprint = (uuid) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/etl-blueprints/${uuid}`);
  } catch (err) {}
};

export const saveBlueprint =
  (groupId, preset, rawColumnArray, rawValuesObject, outputColumnName) =>
  async (dispatch, getState) => {
    dispatch({ type: "SAVE_BLUEPRINT_START" });

    const { etlBlueprints, etlChains } = getState().dataSetup;
    const payload = makeBlueprintPayload(
      groupId,
      etlBlueprints,
      preset,
      rawColumnArray,
      rawValuesObject
    );

    try {
      let nextBlueprint;
      if (payload.uuid) {
        // If uuid exists in the payload, update the blueprint
        await axios.put(`/api/v1/etl-blueprints/${payload.uuid}`, payload);
      } else {
        // If uuid does not exist, create a new blueprint
        nextBlueprint = await axios.post("/api/v1/etl-blueprints", payload);
      }

      // Update the relevant blueprint chains only when creating a new blueprint
      const chainsToUpdate = etlChains.filter((chain) =>
        chain.steps.some((step) => step.blueprint.groupId === groupId)
      );

      const allBlueprintUuids = etlBlueprints
        .filter((b) => b.groupId === groupId)
        .map((e) => e.uuid);
      if (nextBlueprint) {
        allBlueprintUuids.push(nextBlueprint.data.data.uuid);
      }

      // Possibly this can go
      if (chainsToUpdate.length > 0) {
        await updateChains(chainsToUpdate, allBlueprintUuids, outputColumnName);
      }

      dispatch({
        type: "SAVE_BLUEPRINT_SUCCESS",
        payload,
      });

      dispatch(loadBlueprints());
    } catch (error) {
      dispatch({
        type: "SAVE_BLUEPRINT_FAIL",
        error,
      });

      showToastWithTimeout(dispatch, [normalizeError(error).message], "danger");
    }
  };

export const processRuleset = (groupId) => async (dispatch, getState) => {
  // Assuming that the state holds the chains data,
  // and each chain has a property groupId and id
  const { blueprints } = getState().dataSetup;
  const findMatchingChainUuids = (blueprints, groupId) => {
    const matchingChainUuids = [];

    for (let i = 0; i < blueprints.length; i++) {
      if (blueprints[i].groupId === groupId) {
        for (let j = 0; j < blueprints[i].inputs.length; j++) {
          matchingChainUuids.push({
            chainUuid: blueprints[i].inputs[j].chainUuid,
            table: blueprints[i].inputs[j].table,
          });
        }
      }
    }

    return matchingChainUuids;
  };

  const matchedChains = findMatchingChainUuids(blueprints, groupId);

  // Iterate over each chain and make POST calls
  for (const chain of matchedChains) {
    try {
      await axios.post(
        `/api/v1/etl-blueprint-chains/${chain.chainUuid}/process`
      );

      showToastWithTimeout(
        dispatch,
        `Process for ${chain.table} completed successfully.`,
        "success"
      );
      // Dispatch success action here if needed
    } catch (error) {
      showToastWithTimeout(dispatch, [normalizeError(error).message], "danger");
      // Handle error. Dispatch fail action here if needed
    }
  }

  // Optional: Dispatch a general action indicating that processing has begun
  return { type: actionTypes.PROCESS_RULESET, groupId };
};

export const processRulesetSingleChain =
  (chainUuid, table) => async (dispatch) => {
    // Assuming that the state holds the chains data,
    // and each chain has a property groupId and id
    try {
      await axios.post(`/api/v1/etl-blueprint-chains/${chainUuid}/process`);

      showToastWithTimeout(
        dispatch,
        `Process for ${table} completed successfully.`,
        "success"
      );
    } catch (error) {
      showToastWithTimeout(dispatch, [normalizeError(error).message], "danger");
    }
  };

export const getUniqueRawValues =
  (arr, connection, dbName, page = 1) =>
  async (dispatch) => {
    const endpoint = `api/v1/connections/${connection}/warehouse/distinct-column-values`;

    const params = (item) =>
      new URLSearchParams({
        tableName: item.tableName,
        columnName: item.columnName,
        dbName,
        page,
      });

    const fetchData = async (item) => {
      try {
        const response = await axios.get(
          `${endpoint}?${params(item).toString()}`
        );
        return { data: response.data.data, meta: response.data.meta };
      } catch (err) {
        dispatch({ type: actionTypes.GET_UNIQUE_DATA_FAIL, error: err });
      }
      return [];
    };

    const allUniqueData = await Promise.all(arr.map(fetchData));

    const flattenedData = allUniqueData.flatMap((item) => item.data);

    // get max total items for pagination
    const totalCount = Math.max(
      ...allUniqueData.flatMap((item) => item.meta?.total)
    );

    dispatch({
      type: actionTypes.GET_UNIQUE_DATA_SUCCESS,
      uniqueValues: flattenedData,
      totalCount,
    });
  };

import React from "react";
import PieValueObject from "./PieValueObject";
import SummaryDetailPie from "./SummaryDetailPie";
import { pipe, groupByKey, assignAsKey, zeroPadValues } from "../../utils/func";
import { schemeTableau10 } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";
import Legend from "../Legend/Legend";
import VisualizationBase from "../BaseChart/VisualizationBase";
import Details from "../../Layout/Details/Details";
import pieValidate from "./pieValidate";
import formatter from "../../utils/formatters/formatter";

export default function SummaryDetailPieVisualization(props) {
  const { chart, width, details, dashboardId } = props;
  const {
    data,
    labelKey,
    valueKey,
    groupKey,
    height,
    meta,
    hidePieValues,
    legendConfig,
    legendItems,
    absoluteValues,
    valueKeys,
    showPercentsInTooltip,
    includeCategoryInTooltip,
    tooltipConfig,
    legendHeight,
  } = chart;

  pieValidate(chart);

  const colors = scaleOrdinal(schemeTableau10);
  const aPie = new PieValueObject(
    data.filter((d) => (valueKey ? d[valueKey] : true)),
    groupKey,
    valueKey,
    colors,
    legendItems,
    absoluteValues,
    valueKeys
  );
  const showDetailCharts = checkDetailChartVisibility();

  const detailCharts = showDetailCharts ? getDetailCharts() : null;

  function checkDetailChartVisibility() {
    return !!labelKey;
  }

  function getDetailCharts() {
    const detailChartTemp = pipe(
      assignAsKey(valueKey, "value"),
      groupByKey(labelKey),
      zeroPadValues(aPie.getSliceNames(), "values", groupKey, { value: 0 })
    )(data);

    const colorDetailValues = detailChartTemp.map(mapToDetailValues);

    function mapToDetailValues(value) {
      return value.values.map((v) => ({
        ...v,
        color: mapColorToDetailValue(v),
      }));
      function mapColorToDetailValue(v) {
        return aPie.getChart().find((s) => {
          return s.name === v[groupKey];
        })?.color;
      }
    }
    return detailChartTemp.map(assignDetailValues);
    function assignDetailValues(d, i) {
      return { ...d, values: colorDetailValues[i] };
    }
  }

  const legendValues = buildLegendBasedOnValues(legendConfig)(
    aPie.getChart(),
    data
  );

  return (
    <>
      <div
        id={chart.visualizationId}
        style={{ position: "relative" }}
        data-cy="outer-pie"
      >
        <VisualizationBase
          {...{ ...props, margin }}
          tooltipConfig={tooltipConfig}
        >
          <SummaryDetailPie
            summaryChart={aPie.getChart().slice(0, 10)}
            detailCharts={detailCharts}
            width={width}
            height={height}
            meta={meta}
            valueKey={valueKey}
            hidePieValues={hidePieValues}
            valueKeys={valueKeys}
            showPercentsInTooltip={showPercentsInTooltip}
            includeCategoryInTooltip={includeCategoryInTooltip}
            groupKey={groupKey}
            sourceData={data}
          />
        </VisualizationBase>
        <Legend
          legendItems={legendItems}
          highTop
          foreignObject
          colors={aPie.getColors()}
          sections={legendValues}
          wrapping
          horizontal
          legendHeight={legendHeight}
        />
      </div>
      {details && chart.data.length > 0 && (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
        />
      )}
    </>
  );
}

const margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 18,
};

const buildLegendBasedOnValues = (legendConfig) => (data, sourceData) => {
  if (!legendConfig) return data.map((d) => d.name);
  const { labelKey, valueFormat, valueKey } = legendConfig;

  return data.reduce((acc, curr) => {
    acc.push(
      `${labelKey ? curr.name + ":" : ""} ${formatter(
        sourceData.find((d) => d[labelKey] === curr.name)?.[valueKey] ||
          curr.value,
        valueFormat
      )}`
    );
    return acc;
  }, []);
};

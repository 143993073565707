import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import useUserAccess from "./useUserAccess";
import { useSelector } from "react-redux";
import { useChartContext } from "../charts/ChartContext";

/**
 * Returns navigation links for charts that should be shown regardless of
 * whether data loaded successfully or not.
 */
export default function () {
  const chartProps = useChartContext();
  const { dashboardId: dashboardName, chart } = chartProps;
  const visualizationId = chart?.uuid;
  const access = useUserAccess();

  const pages = useSelector((state) => state.layout.tabs);
  const page = useMemo(() => {
    return (pages ?? []).find((page) => page.slug === dashboardName);
  }, [dashboardName, pages]);
  const pageUuid = page?.uuid;

  const block = useMemo(() => {
    return page?.blocks?.find((block) =>
      block.visualizations.find(
        (visualization) => visualization.uuid === visualizationId
      )
    );
  }, [page?.blocks, visualizationId]);
  const blockUuid = block?.uuid;

  const linksNode = pageUuid && blockUuid && access.pageManagement && (
    <Link
      className="hide-unless-hover-over-container"
      to={`/admin/page-management/${pageUuid}/blocks/${blockUuid}/visualizations/${visualizationId}`}
    >
      Edit <FontAwesomeIcon icon={["fas", "caret-right"]} />
    </Link>
  );

  return { linksNode };
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../UI/Flex/Flex";
import ChipList from "../components/ChipList";
import {
  addGroupRecipient,
  deleteGroupRecipient,
} from "../../../store/actions/automation";

export default function RecipientGroups({
  all,
  groups,
  dispatch,
  uuid,
  recipientGroupLoading,
}) {
  const allGroups = (all ?? []).filter(
    (g) => !groups.some((group) => group.uuid === g.uuid)
  );

  function addMore(group) {
    dispatch(addGroupRecipient(uuid, { groupUuid: group.uuid }));
  }

  function deleteGroup(group) {
    dispatch(deleteGroupRecipient(uuid, group.uuid));
  }

  return (
    <div>
      <div style={{ fontSize: 12, marginBottom: 10, marginTop: 20 }}>
        Groups
        {recipientGroupLoading && (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            pulse
            style={{ marginLeft: 10 }}
          />
        )}
      </div>
      <Flex style={{ width: "100%" }} alignItems="center">
        <ChipList
          all={allGroups}
          visible={groups.map((item) => ({
            ...item,
            name: item.group.displayName,
          }))}
          addMore={addMore}
          width={20}
          height={20}
          fontSize={11}
          limit={7}
          addLabel="Add Group"
          deleteItem={deleteGroup}
        />
      </Flex>
    </div>
  );
}

import { EyeIcon } from "./styles";

export default function SelectionListItemIcon({ hidden }) {
  return (
    <EyeIcon
      data-cy="item-icon"
      icon={hidden ? ["fas", "eye-slash"] : ["fas", "eye"]}
    />
  );
}

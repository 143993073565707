import React from "react";
import styled from "@emotion/styled";
import NoNotifications from "../../Pages/Files/NoNotifications";

const Container = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 290px;
    height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        height: .4em;
        width: .4em;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${theme.blueGray.blueGray50};
    }
    &::-webkit-scrollbar-thumb {
        background-color: #90a4ae;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: ${theme.blueGray.blueGray500};
    }
`
);

const Header = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.gray.gray300};
`;

const Content = styled.div`
  font-size: 13px;
`;

export default function NotificationList() {
  return (
    <Container>
      <Header>Notifications</Header>
      <Content>
        <NoNotifications />
      </Content>
    </Container>
  );
}

import styled from "@emotion/styled";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useCallback, useEffect, useRef } from "react";

const DynamicContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  background: white;
  left: 40px;
  z-index: 999;
  border-radius: 5px;
  border: 1px solid ${theme.primary};
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
`
);

const Text = styled.div(
  ({ theme }) => `
  margin-bottom: 5px;
  cursor: pointer;
  padding: 2px 5px;
  font-weight: 500;
  border-radius: 5px;
  &:hover {
    background: ${theme.primary};
    color: white;
  }
`
);

export default function BreakdownByPopup({
  drilldownLevel,
  expandRow,
  rowExpandVisualizationParams,
  setDrilldownLevel,
  getExpandedRow,
}) {
  const { row, index } = drilldownLevel;
  const keys = Object.keys(rowExpandVisualizationParams);
  const ref = useRef(null);

  useOnClickOutside(ref, () =>
    setDrilldownLevel({ ...drilldownLevel, index: null })
  );

  const selectExpandVisualization = useCallback(
    (key) => {
      setDrilldownLevel({ ...drilldownLevel, key, index: null });
      expandRow(row, index, rowExpandVisualizationParams[key]);
    },
    [
      drilldownLevel,
      expandRow,
      index,
      row,
      rowExpandVisualizationParams,
      setDrilldownLevel,
    ]
  );

  const opened = keys.filter((key) => {
    const { expandedRow } = getExpandedRow(
      row,
      index,
      rowExpandVisualizationParams[key]
    );
    return expandedRow;
  });

  useEffect(() => {
    // close opened row
    if (keys.length === 1 || opened[0]) {
      const key = opened[0] ?? keys[0];
      selectExpandVisualization(key);
    }
  }, [keys, opened, selectExpandVisualization]);

  // do not show popup if there is only one config
  if (keys.length === 1 || opened[0]) {
    return null;
  }

  return (
    <tr style={{ position: "relative" }} ref={ref}>
      <td style={{ position: "absolute" }}>
        <DynamicContainer>
          <span style={{ marginBottom: 5 }}>breakdown by...</span>
          {keys.map((key) => (
            <Text key={key} onClick={() => selectExpandVisualization(key)}>
              {key}
            </Text>
          ))}
        </DynamicContainer>
      </td>
    </tr>
  );
}

import React from "react";
import IOButton from "../../../../UI/Form/Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";

const Container = styled.div`
  position: absolute;
  bottom: 12px;
  width: calc(100% - 24px);
  display: flex;
`;

const applyStyle = (filterApi, position) => {
  return {
    width: filterApi.overrides[position] ? "80%" : "100%",
    marginRight: 8,
  };
};
const addStyle = {
  width: "10%",
  paddingLeft: 18,
  paddingRight: 18,
};

export default function BuilderSaveButtons(props) {
  const { filterApi, position, handleApplySettings, addColumn } = props;
  return (
    <Container>
      <IOButton
        style={applyStyle(filterApi, position)}
        onClick={handleApplySettings}
      >
        Apply
      </IOButton>
      {filterApi.overrides[position] ? (
        <IOButton style={addStyle} onClick={addColumn} cancel>
          <FontAwesomeIcon icon={["fas", "plus"]} />
        </IOButton>
      ) : null}
    </Container>
  );
}

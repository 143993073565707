export default function (field, draftSettings, setDraftSettings) {
  const hasExistingSortForField = draftSettings.orders.find(
    (o) => o.name === field
  );
  let orders;
  if (hasExistingSortForField) {
    orders = draftSettings.orders.map((o) => {
      return o.name === field ? { ...o, sort: flip(o.sort) } : o;
    });
  } else {
    const newSort = { name: field, sort: "ASC" };
    orders = [...draftSettings.orders, newSort];
  }
  return setDraftSettings({
    ...draftSettings,
    orders,
  });

  function flip(sortOrder) {
    return sortOrder === "ASC" ? "DESC" : "ASC";
  }
}

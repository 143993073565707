import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import SimpleTooltip from "../../../../UI/SimpleTooltip/SimpleTooltip";

const ActionContainer = styled.div`
  height: 26px;
  top: -6px;
  background: ${(props) => props.theme.notification.infoMain};
  min-width: ${(props) => props.width + 24}px;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  position: relative;
  left: -18px;
  display: flex;
  justify-content: center;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 14px;
  div {
    margin: 0 3px;
  }
  path {
    fill: white;
    opacity: 0.5;
  }

  & > div:hover {
    path {
      opacity: 1;
    }
  }
`;

const Description = styled.div(
  ({ theme }) => `
  position: absolute;
  top: -22px;
  color: ${theme.text.primary};
  font-size: 12px;
  opacity: 0.5;
  text-align: left;
  pointer-events: none;
`
);

export default function ColumnActions(props) {
  const { handleMove, setEditing, setRowSort, removeOverride, i, column } =
    props;
  const { position } = i;

  return (
    <ActionContainer
      data-cy="column-action-container"
      width={i.dimensions.width}
    >
      <SimpleTooltip label="Shift Column Left">
        <div onClick={() => handleMove("left", position)}>
          <FontAwesomeIcon icon={["fas", "chevron-left"]} />
        </div>
      </SimpleTooltip>
      <SimpleTooltip label="Shift Column Right">
        <div onClick={() => handleMove("right", position)}>
          <FontAwesomeIcon icon={["fas", "chevron-right"]} />
        </div>
      </SimpleTooltip>
      {/*Editing should not exist on custom views*/}
      <SimpleTooltip label="Column Settings">
        <div data-cy="column-action-edit" onClick={() => setEditing(position)}>
          <FontAwesomeIcon icon={["far", "edit"]} />
        </div>
      </SimpleTooltip>
      <SimpleTooltip label="Sort">
        <div
          onClick={() => {
            setRowSort(i.name);
          }}
        >
          <FontAwesomeIcon icon={["far", "sort"]} />
        </div>
      </SimpleTooltip>
      <SimpleTooltip label="Remove">
        <div
          data-cy="column-action-remove"
          onClick={() => removeOverride(position)}
        >
          <FontAwesomeIcon icon={["fal", "times"]} />
        </div>
      </SimpleTooltip>
      <Description>..{column?.mapping.type}..</Description>
    </ActionContainer>
  );
}

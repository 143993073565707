import formatter from "../../utils/formatters/formatter";
import { getMappingType } from "../../utils/getVisualizationLabel";
import styled from "@emotion/styled";

// lets convert all data first to get rid of converting in table cell
// for future if we will need to implement virtualized table
export const formatData = (data, columns, meta) => {
  return data.reduce((acc, curr) => {
    const row = {};
    for (const column of columns) {
      const formatted = getFormattedValue(curr[column], column, meta);
      row[column] = formatted;
    }

    acc.push(row);
    return acc;
  }, []);
};

export const getFormattedValue = (value, column, meta) => {
  const type = getMappingType(meta.fields, column);
  return formatter(value, type);
};

// this fn return key if it exist (checked/unchecked)
export const getWatchlistExistingKey = (row, watchlist) => {
  let watchlistKey;

  for (const key of Object.keys(watchlist || {})) {
    const groups = watchlist[key];
    const isGroupExist = groups.every(
      (group) => row[group.type] === group.value
    );

    if (isGroupExist) {
      watchlistKey = key;
      break;
    }
  }

  return watchlistKey;
};

// search in all columns
export const getDataBySearchText = (data, columns, search) => {
  return data.filter((row) =>
    columns.some((column) => {
      return row[column]
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase());
    })
  );
};

export const title = "Deletion";
export const rounded = true;
export const message =
  "Do you really want to delete this item? This process cannot be undone.";

export const NEW_ROWS = "NEW_ROWS";
export const ALARMS = "ALARMS";

export const Td = styled.td(
  ({ align }) => `
  text-align: ${align} !important;
`
);

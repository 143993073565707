import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getSampleValuesFromStagingTables } from "../../../../store/actions";
import BlueprintOutputPreviewHome from "./BlueprintOutputPreviewHome";

export default function BlueprintOutputPreview(props) {
  const { etlChains, sample } = useSelector((state) => state.dataSetup);
  // Fetch the chainUuid from the route parameters
  const dispatch = useDispatch();
  const { chainUuid } = useParams();

  const chain = useMemo(() => {
    return (etlChains ?? []).find((c) => c.uuid === chainUuid);
  }, [chainUuid, etlChains]);

  const outputTable = useMemo(() => {
    if (chain?.steps?.length) {
      return chain.steps[chain.steps.length - 1]?.outputName;
    }
  }, [chain]);

  useEffect(() => {
    if (chain) {
      dispatch(
        getSampleValuesFromStagingTables(outputTable, chain.connection.uuid)
      );
    }
  }, [dispatch, outputTable, chain]);

  return (
    <BlueprintOutputPreviewHome
      sample={sample}
      chain={chain}
      etlChains={etlChains}
    />
  );
}

import { unique } from "../../utils/func";

export default function (width, allDates, bands) {
  const adjustedTickCountPre = width * 0.014;
  const uniqueDates = unique(
    allDates.map((d, i) => (bands ? d : dateString(d, i)))
  );
  return Math.min(12, adjustedTickCountPre, uniqueDates.length);
}

function dateString(d, i) {
  return d instanceof Date && !isNaN(d) ? d.toISOString() : i;
}

import React, { useState } from "react";
import SetFieldTypes, {
  defaultFieldTypesState,
  INSIGHT_FIELD_TYPE_DATE,
  INSIGHT_FIELD_TYPE_GROUP,
  INSIGHT_FIELD_TYPE_VALUE,
} from "./SetFieldTypes";
import Input from "../../../UI/Form/Input/Input";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { calculateInsightsForData } from "../../../store/actions/insights";
import InsightResultTable from "./ResultsTable/InsightResultTable";
import InsightStage from "./InsightStage";
import InsightsSubmitButton from "./InsightsSubmitButton";

const defaultThreshold = 0;
const separatorHeight = 20;

export default function Insights({
  data,
  fields,
  runQuery,
  insightsState,
  defaultFieldTypes = defaultFieldTypesState, // does this need to be a props?
}) {
  const dispatch = useDispatch();
  const draftFieldTypesState = useState(defaultFieldTypes);
  const [savedFieldTypes, setSavedFieldTypes] = useState(
    draftFieldTypesState[0]
  );
  const [fieldTypes] = draftFieldTypesState;
  const [threshold, setThreshold] = useState(defaultThreshold);
  const [weighted, setWeighted] = useState(false);

  const validForSubmit =
    fieldTypes[INSIGHT_FIELD_TYPE_VALUE] &&
    fieldTypes[INSIGHT_FIELD_TYPE_DATE] &&
    fieldTypes[INSIGHT_FIELD_TYPE_GROUP].length;

  const insightConfig = {
    groupKeys: fieldTypes[INSIGHT_FIELD_TYPE_GROUP],
    valueKey: fieldTypes[INSIGHT_FIELD_TYPE_VALUE],
    dateKey: fieldTypes[INSIGHT_FIELD_TYPE_DATE],
    threshold,
    weighted,
  };

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (insightsState.loading || !validForSubmit) {
      return;
    }
    setSavedFieldTypes(draftFieldTypesState[0]);
    if (data) {
      dispatch(calculateInsightsForData(data, insightConfig));
    } else {
      runQuery(insightConfig);
    }
  };

  return (
    <InsightStage>
      <form onSubmit={onSubmit}>
        <h1>
          Insights <small>(Beta)</small>
        </h1>

        <SetFieldTypes fieldTypesState={draftFieldTypesState} fields={fields} />

        <div style={{ height: separatorHeight }} />

        <div>Threshold</div>
        <Input
          type="number"
          value={threshold}
          onChange={(evt) => setThreshold(Number(evt.currentTarget.value))}
        />

        <div style={{ height: separatorHeight }} />

        <label>
          <Checkbox
            checked={weighted}
            onChange={(evt) => setWeighted(evt.currentTarget.checked)}
            label="Use weighting"
          />
        </label>

        <div style={{ height: separatorHeight }} />

        <InsightsSubmitButton
          loading={insightsState.loading}
          disabled={!validForSubmit}
        />

        <div style={{ height: separatorHeight }} />

        <InsightResultTable
          loading={insightsState.loading}
          results={insightsState.data}
          fields={fields}
          fieldTypes={savedFieldTypes}
        />
      </form>
    </InsightStage>
  );
}

import React, { useState } from "react";
import * as actions from "../../../store/actions/index";
import { saveQueryExec } from "../../../store/actions/index";
import styled from "@emotion/styled";
import { useHistory, useParams } from "react-router";
import FilterEditor from "./Filters/FilterEditor";
import ShowHideColumns from "./ActionBlocks/ColumnSelection";
import ExplorerTable from "./ExplorerTable/ExplorerTable";
import TableGlobalActions from "./ActionBlocks/TableGlobalActions";
import ExplorerRunError from "./DataExplorerUI/ExplorerRunError";
import ExplorerShelf from "./ActionBlocks/ExplorerShelf";
import ExplorerSave from "./SavedExecManagement/ExplorerSave";
import InitExplorerTable from "./ExplorerTable/InitExplorerTable";
import EnterTransition from "./DataExplorerUI/EnterTransition";
import InsightsManager from "../Insights/InsightsManager";
import { handleError } from "../../../utils/errorHandling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QueryExplorerContainer = styled.div`
  padding-bottom: 60px;
`;

const Back = styled.div`
  font-size: 10px;
  margin-bottom: 14px;
  opacity: ${(props) => (props.theme.type === "dark" ? 0.4 : 0.7)};
  cursor: pointer;
  display: inline-block;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.text.secondary};
  }
`;

export default function QueryExplorer(props) {
  const {
    loading,
    error,
    allFields,
    results,
    totalRecords,
    activeQuery,
    activeQueryError,
    queryExecSettingsError,
    dispatch,
    settings, // Active Exec settings (Saved)
    execSettingsForm, // Save Query in Redux
    showPanel,
    setShowPanel,
    selectedQuery,
    fields,
    lastSettings,
    filterApi,
    explorerMode,
  } = props; // settings is loaded settings

  const params = useParams();
  const history = useHistory();

  const [columnsVisibility, setColumnsVisibility] = useState(false); // Checkbox visibility
  const [showFilters, setShowFilters] = useState(true);
  const [addColumn, setAddColumn] = useState(false);
  const [showOptions, setShowOptions] = useState(null);
  const { perPage, setPerPage } = filterApi;

  // Use this to run from Table or global actions
  const runQuery = async () => {
    try {
      await dispatch(
        actions.runQuery(selectedQuery, filterApi.getSettingsUnmutedFilters())
      );
    } catch (e) {
      handleError(dispatch, e);
    }
  };

  const exportExcel = () => {
    dispatch(
      actions.exportExcel(selectedQuery, filterApi.getSettingsUnmutedFilters())
    );
  };

  const handleSave = (name, settingsUuid) => {
    const nextSettings = {
      ...filterApi.effectiveSettings,
      filters: filterApi.filters,
    };
    dispatch(
      saveQueryExec(
        name,
        activeQuery.uuid,
        nextSettings,
        settingsUuid,
        saveComplete
      )
    );
  };

  function saveComplete(uuid) {
    if (params.queryId) {
      history.push(`/admin/data-explorer/${uuid}`);
    }
  }

  const handleClear = () => {
    filterApi.clearColumns();
    // setColumnsVisibility(false);
    // setAddColumn(true);
  };

  const handleReset = () => {
    filterApi.createFromQuery(activeQuery.uuid);
    // setColumnsVisibility(false);
  };
  function goHome() {
    filterApi.setSelectedQuery(null);
    history.push("/admin/data-explorer");
  }

  return (
    <QueryExplorerContainer data-cy="query-explorer-container">
      <div>
        <Back onClick={goHome}>
          <FontAwesomeIcon icon={["fas", "arrow-left"]} />
          <span style={{ paddingLeft: 6 }}>Data Explorer Home</span>
        </Back>
      </div>

      <ExplorerRunError
        error={
          activeQueryError
            ? `Could not load query: ${activeQueryError.message}`
            : queryExecSettingsError
            ? `Could not load settings: ${queryExecSettingsError.message}`
            : error
        }
      />

      <InitExplorerTable
        error={error}
        activeQuery={activeQuery} // From Redux
        activeQueryError={activeQueryError}
        params={params}
        filterApi={filterApi}
        lastSettings={lastSettings}
        activeSettings={settings}
        queryExecSettingsError={queryExecSettingsError}
        explorerMode={explorerMode}
      >
        <EnterTransition show={columnsVisibility}>
          <ShowHideColumns
            filterApi={filterApi}
            columns={allFields}
            close={() => setColumnsVisibility(false)}
            clearAll={handleClear}
            reset={handleReset}
          />
        </EnterTransition>

        <TableGlobalActions
          show={activeQuery && !error}
          setColumnsVisibility={setColumnsVisibility}
          runQuery={() => runQuery()}
          exportExcel={() => exportExcel()}
          setShowPanel={setShowPanel}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          filterApi={filterApi}
        />

        <div style={{ height: 20 }} />

        <FilterEditor
          fields={allFields}
          filterApi={filterApi}
          filters={filterApi.filters}
          setShowFilters={setShowFilters}
          handleSetField={filterApi.setField}
          setOperator={filterApi.setOperator}
          setValues={filterApi.setValues}
          dispatch={dispatch}
          queryId={selectedQuery}
        />

        <InsightsManager filterApi={filterApi} selectedQuery={selectedQuery} />

        <ExplorerTable
          loading={loading}
          fields={allFields}
          results={results}
          filterApi={filterApi}
          runQuery={runQuery}
          showFilters={showFilters}
          addColumn={addColumn}
          setAddColumn={setAddColumn}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
        />
      </InitExplorerTable>

      <ExplorerShelf
        totalRecords={totalRecords}
        perPage={perPage}
        setPerPage={setPerPage}
        activeQuery={activeQuery}
        orders={attachLabels(filterApi.settings.orders, fields)}
        setOrderPriority={filterApi.setOrderPriority}
        setSorting={filterApi.setRowOrder}
        removeSortingKey={filterApi.removeSortingKey}
      />

      <ExplorerSave
        showSaveForm={showPanel === "save"}
        setShowPanel={setShowPanel}
        handleSave={handleSave}
        execSettingsForm={execSettingsForm}
        name={filterApi.name}
        settings={settings}
      />
    </QueryExplorerContainer>
  );
}

function attachLabels(orders, fields) {
  if (!orders || !orders.length) return [];
  return orders.map((o) => ({
    ...o,
    label: fields.find((f) => f.name === o.name)?.label,
  }));
}

import RadioButton from "../../../../../UI/RadioButton/RadioButton";

export default function GroupingTypeRadioContainer(props) {
  const { chart, setChartState } = props;
  const { dynamicSubTitleKeys } = chart;

  // enable/disable dynamicSubTitleKeys
  function setUnsetDynamicRepeatingKeys(dynamicSubTitleKeys) {
    setChartState({
      ...chart,
      dynamicSubTitleKeys,
    });
  }

  return (
    <>
      <RadioButton
        name={"Custom Column Configs (D1, D2 | D3, D4...)"}
        checked={!dynamicSubTitleKeys}
        onChange={() => setUnsetDynamicRepeatingKeys(null)}
        size={17}
        offset={5}
      />

      <RadioButton
        name={"Repeating Column Configs (D1, D2 | D1, D2...)"}
        checked={!!dynamicSubTitleKeys}
        onChange={() => setUnsetDynamicRepeatingKeys([])}
        size={17}
        offset={5}
      />

      <br />
    </>
  );
}

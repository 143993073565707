import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLevelIndicator } from "../../utils/tableEditorHelper";

const ActiveIconContainer = styled.div(
  ({ theme, level, color }) => `
  color: ${color ?? theme.primary};
  rotate: 90deg;
  position: absolute;
  top: ${level === 0 ? 60 : 42}px;
  left: -15px;
`
);

export default function LevelIndicatorIcon({
  levelIndicator,
  level,
  color,
  drilldownParent,
  indicatorParent,
}) {
  const notInCurrentLevel = getLevelIndicator(
    levelIndicator,
    level,
    drilldownParent,
    indicatorParent
  );

  if (notInCurrentLevel) {
    return null;
  }

  return (
    <ActiveIconContainer level={level} color={color}>
      <FontAwesomeIcon icon={["fas", "triangle"]} />
    </ActiveIconContainer>
  );
}

import { useMemo, useState } from "react";

const overscanRowCount = 20;

export default function useVirtualizedDataExplorerList(
  data,
  containerRef,
  itemHeight
) {
  const [scrollTop, setScrollTop] = useState(0);

  const containerHeight = containerRef.current?.clientHeight ?? 0;

  const startIndex = useMemo(() => {
    return Math.floor(scrollTop / itemHeight);
  }, [itemHeight, scrollTop]);

  const endIndex = useMemo(() => {
    return Math.min(
      startIndex + Math.ceil(containerHeight / itemHeight),
      data.length
    );
  }, [containerHeight, data.length, itemHeight, startIndex]);

  const visibleItems = useMemo(() => {
    return data.slice(startIndex, endIndex + overscanRowCount);
  }, [data, endIndex, startIndex]);

  const handleScroll = (event) => {
    setScrollTop(event.target.scrollTop);
  };

  const scrollbarContainerStyles = {
    height: `${data.length * itemHeight}px`,
  };

  const innerScrollbarContainerStyles = {
    position: "relative",
    height: `${visibleItems.length * itemHeight}px`,
    top: `${startIndex * itemHeight}px`,
  };

  return {
    visibleItems,
    handleScroll,
    itemHeight,
    startIndex,
    endIndex,
    scrollbarContainerStyles,
    innerScrollbarContainerStyles,
  };
}

import { useEffect, useState } from "react";
import Input from "../../../UI/Form/Input/Input";
import useDebounceChange from "../../../hooks/useDebounceChange";
import usePrevious from "../../../utils/usePrevious";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  margin-left: 5px;
  width: 220px;
  font-size: 12px;

  & > span {
    padding-top: 34px;
    margin-left: 15px;
  }
`;

export default function Search(props) {
  const { loading, search, setSearch, dataLength, showSearch } = props;

  const [localSearchText, setLocalSearchText] = useState(search);
  const [showLoading, setShowLoading] = useState(false);

  const { debounce } = useDebounceChange();
  const prevLength = usePrevious(dataLength);
  const prevSearch = usePrevious(search);

  const conditionToUpdate = prevLength === dataLength && search !== prevSearch;

  useEffect(() => {
    if (showLoading && (prevLength !== dataLength || conditionToUpdate)) {
      setShowLoading(false);
    }
  }, [conditionToUpdate, dataLength, prevLength, showLoading]);

  if (loading || !showSearch) {
    return null;
  }

  function handleSearch(e) {
    const value = e.target.value;
    setLocalSearchText(value);
    setShowLoading(true);
    debounce(() => setSearch(value), 500);
  }

  return (
    <Container>
      <Input
        label="Search..."
        value={localSearchText}
        onChange={handleSearch}
      />
      {showLoading && <span>Searching...</span>}
    </Container>
  );
}

export function getFilteredBySearch(data, search) {
  return (data ?? []).filter((item) => {
    const values = Object.values(item);

    return values.some((value) =>
      (value + "").toLowerCase().includes(search.toLowerCase())
    );
  });
}

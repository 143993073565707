import React from "react";
import styled from "@emotion/styled";

const Type = styled.div`
  border-radius: 50%;
  background: ${({ active, theme, deleting }) =>
    deleting
      ? theme.notification.errorMain
      : active
      ? theme.notification.infoMain
      : "black"};
  color: white;
  position: relative;
  height: ${(props) => (props.simple ? 24 : 32)}px;
  width: ${(props) => (props.simple ? 24 : 32)}px;
  right: 16px;
  top: 8px;
  border: ${(props) => (props.simple ? 0.7 : 2)}px solid
    ${({ theme, deleting, simple }) =>
      deleting || simple ? "white" : theme.notification.infoMain};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: 2px;
  flex-shrink: 0;
  font-size: ${(props) => (props.simple ? 12 : 16)}px;
  &:hover {
    background: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary};
  }
`;

export default function TypeIcon(props) {
  return <Type {...props}>{props.children}</Type>;
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mobileBreakpoints from "../styles/mobileBreakpoints";

function setOffset({ offset, menuVisible }) {
  if (offset) {
    return offset;
  }

  return menuVisible ? 250 : 0;
}

const HideTab = styled.div(
  (props) => `
  position: fixed;
  left: ${setOffset(props)}px;
  margin-right: -20px;
  transition: 0.3s ease left;
  top: calc(100vh - 70px);
  background: ${props.theme.primary};
  height: 36px;
  width: 20px;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  svg {
    margin-top: -2px;
    margin-right: 4px;
    transform: rotate(${props.menuVisible ? 0 : 180}deg);
    transition: 0.3s ease transform;
  }
  path {
    fill: rgba(255, 255, 255, 0.5);
  }
  &:hover path {
    fill: rgba(255, 255, 255, 1);
  }
  cursor: pointer;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    display: none;
  }
`
);

export default function LeftMenuHider(props) {
  const { handleClick, menuVisible, offset } = props;
  return (
    <HideTab onClick={handleClick} menuVisible={menuVisible} offset={offset}>
      <FontAwesomeIcon icon={["fal", "chevron-left"]} />
    </HideTab>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";
import Tipper from "../../../UI/Tipper/Tipper";

const IconContainer = styled.div`
  color: ${(props) => props.theme.notification.successMain};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.notification.successLight};
  }
`;

// Use the styled IconContainer component to wrap your FontAwesomeIcon
export function PlayCircle(props) {
  return (
    <Tipper label="Process Field Values">
      <IconContainer data-cy="process-ruleset" onClick={props.onClick}>
        <FontAwesomeIcon size="3x" icon={["fal", "play-circle"]} />
      </IconContainer>
    </Tipper>
  );
}

PlayCircle.propTypes = { onClick: PropTypes.func };

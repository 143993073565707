import React from "react";
import InputSet from "../../../UI/Form/InputSet/InputSet";

import styled from "@emotion/styled";
import { cloneDeep } from "lodash-es";

const Container = styled.div`
  color: ${(props) => props.theme.text.primary};
  font-size: 14px;
  margin: 12px 0 18px 0;
`;
export default function ActiveTableOptionsSettings(props) {
  const { setOptions, colDef } = props;
  const clonedColDef = cloneDeep(colDef);
  return (
    <div style={{ width: 300 }}>
      <Container>Set options to be selected</Container>
      <InputSet
        onChange={(nextOptions) => setOptions(colDef.colId, nextOptions)}
        values={clonedColDef.cellEditorParams.values.map((v) => ({ value: v }))}
      />
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { FilterEditorRow } from "./FilterEditorRow";
import axios from "../../../../axios";

import styled from "@emotion/styled";
import AddFilter from "./AddFilter";

const FilterEditorWrapper = styled.div`
  box-sizing: border-box;
  padding-top: 32px;
  position: relative;
`;

export default function FilterEditor(props) {
  const { filters, setShowFilters, ...restProps } = props;
  const nextFilters = filters ?? props.filterApi.draftFilters ?? [];
  const handleSetField = props.handleSetField || props.filterApi.setField;
  const setOperator = props.setOperator || props.filterApi.setOperator;
  const setValues = props.setValues || props.filterApi.setValues;
  return (
    <FilterEditorWrapper>
      {nextFilters.map((filter, index) => (
        <FilterEditorRow
          key={filter.name + "-" + index}
          index={index}
          filter={filter}
          axios={axios}
          handleSetField={handleSetField}
          setOperator={setOperator}
          setValues={setValues}
          {...restProps}
        />
      ))}

      <div style={{ display: "flex", alignItems: "center", marginBottom: 36 }}>
        <AddFilter onClick={props.filterApi.addFilter} />
      </div>
    </FilterEditorWrapper>
  );
}

FilterEditor.propTypes = {
  filters: PropTypes.array,
  fields: PropTypes.array,
  handleSetField: PropTypes.func,
  setOperator: PropTypes.func,
  setValues: PropTypes.func,
  filterApi: PropTypes.object.isRequired,
  queryId: PropTypes.string,
};

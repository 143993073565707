import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import Select from "../../../../../UI/Form/Select/Select";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

const Menu = styled.div`
  position: relative;
  color: white;
  margin-left: 10px;
  opacity: ${(props) => (props.show ? 1 : 0.5)};
  cursor: pointer;
  z-index: 100;
`;

const Options = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 30px;
`;

/* move column between groupings */
export default function ManualGroupingPosition(props) {
  const {
    staticGroupingKeys,
    setChartState,
    subTitles,
    column,
    subIndex,
    index,
    chart,
  } = props;

  const [show, setShow] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShow(false));

  if (!staticGroupingKeys) {
    return null;
  }

  const options = staticGroupingKeys.map((group, index) => ({
    label: group,
    value: index,
  }));

  function replaceGroupingForColumn(grouping) {
    if (grouping.value === subIndex) {
      return;
    }

    const subTitle = [column, ...subTitles[grouping.value]];

    setChartState({
      ...chart,
      subTitles: chart.subTitles.map((sub, i) =>
        i === grouping.value
          ? subTitle
          : sub.filter((s, j) => !(s === column && index === j))
      ),
    });
  }

  return (
    <>
      <Menu
        title="Change Grouping"
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
        show={show}
      >
        <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
      </Menu>

      {show && (
        <Options onClick={(e) => e.stopPropagation()} ref={ref}>
          <Select
            options={options}
            value={options[subIndex]}
            onChange={replaceGroupingForColumn}
          />
        </Options>
      )}
    </>
  );
}

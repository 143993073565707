import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.background.primary};
`;

const Dropdown = styled.div(
  ({ theme }) => `
    color: ${theme.text.secondary};
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
    
    &:hover {
        color: ${theme.text.primary};
    }
`
);

const Label = styled.p`
  margin-right: 10px;
`;

const Menu = styled.div(
  ({ theme }) => `
    background-color: ${theme.background.secondary};
    box-shadow: 1px 1px 10px ${theme.divider};
    position: absolute;
    width: 235px;
    top: 56px;
    right: -30px;
    border-radius: 4px;
    padding: 12px 0;
    z-index:1000;
`
);

export default function DropdownMenu(props) {
  const { title, cy, show, setShow } = props;

  return (
    <Container>
      <Dropdown onClick={() => setShow(!show)} data-cy={cy}>
        <Label>{title}</Label>
        <FontAwesomeIcon icon={["fas", show ? "chevron-up" : "chevron-down"]} />
      </Dropdown>

      {show && <Menu data-cy="dropdown-menu-show">{props.children}</Menu>}
    </Container>
  );
}

DropdownMenu.defaultProps = {
  background: "gray800",
};

DropdownMenu.propTypes = {
  title: PropTypes.node,
  background: PropTypes.string,
};

import styled from "@emotion/styled";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../UI/Flex/Flex";
import DrilldownItem from "./DrilldownItem";
import { NavLink } from "react-router-dom";

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
`;

const IconContiner = styled.div`
  margin-left: 50px;
  color: ${(props) =>
    props.theme.notification[props.loaded ? "successMain" : "errorMain"]};
`;

const Title = styled.div`
  margin-left: 50px;
  color: ${(props) => props.color};
`;

export default function ChartItem({ chart, executedCharts, theme, link }) {
  const { duration, loaded, type } =
    executedCharts[chart.visualizationId] ?? {};

  const color = useMemo(() => {
    return getTimeExecutionStandartColor(type, theme, loaded);
  }, [loaded, theme, type]);

  return (
    <>
      <ChartContainer>
        <Title>
          {chart.visualizationId}
          {link && (
            <NavLink to={link}>
              <FontAwesomeIcon
                icon={["fas", "pencil-alt"]}
                style={{ marginLeft: 10 }}
              />
            </NavLink>
          )}
        </Title>

        {duration && (
          <Flex>
            <Title color={color}>{duration}s</Title>
            <IconContiner loaded={loaded}>
              <FontAwesomeIcon
                icon={["fa", loaded ? "check" : "times-circle"]}
              />
            </IconContiner>
          </Flex>
        )}
      </ChartContainer>
      <DrilldownItem
        chart={chart}
        executedCharts={executedCharts}
        theme={theme}
      />
    </>
  );
}

export function getTimeExecutionStandartColor(type, theme) {
  const colors = {
    FAILED: theme.notification.errorMain,
    FAST: theme.notification.successMain,
    OK: theme.notification.warningMain,
    SLOW: theme.notification.errorMain,
  };

  return colors[type];
}

import React, { useEffect } from "react";
import MarkerCluster from "./components/MarkerCluster";

export default function MarkerClusterGroup(props) {
  const { pinData, setActivePopup } = props;
  useEffect(() => {
    const elements = document.getElementsByClassName("leaflet-marker-pane");
    const shadows = document.getElementsByClassName("leaflet-shadow-pane");
    elements[0].style.display = "block";
    shadows[0].style.display = "block";
    return () => {
      if (elements[0]) {
        elements[0].style.display = "none";
        shadows[0].style.display = "none";
      }
    };
  }, []);

  return (
    <MarkerCluster
      markers={pinData}
      showPopup={(marker) => setActivePopup(marker)}
    />
  );
}

import React from "react";
import { useTransition, animated } from "react-spring";

export default function EnterToast({ children, items }) {
  const transitions = useTransition(items, {
    from: { maxHeight: 0, marginTop: 0 },
    enter: { maxHeight: 170, marginBottom: 0 },
    leave: { maxHeight: 0, marginBottom: 0 },
  });

  return transitions((styles, item) => (
    <animated.div style={styles}>{item}</animated.div>
  ));
}

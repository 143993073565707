import { isNumber } from "lodash-es";

// non strict compare filter value
// function partialNoIndexEqual(rowIndexes, keyValue) {
//   return rowIndexes?.find((item) => (item || "").includes(keyValue));
// }

// non strict compare filter value + row index
// function partialEqual(rowIndexes, keyValue, tableRowIndex) {
//   const partialExistWithRowIndex = rowIndexes?.find((item) =>
//     (item || "").includes(keyValue + tableRowIndex)
//   );

//   return partialExistWithRowIndex ?? partialNoIndexEqual(rowIndexes, keyValue);
// }

// strict compare filter value + row index
export function rowIndexStrictComparison(rowIndexes, keyValue, tableRowIndex) {
  const number = rowIndexes?.every((rowIndex) => isNumber(rowIndex));

  const strictExist = rowIndexes?.find(
    (item) => item === keyValue + (number ? 0 : tableRowIndex) // do not add index to number because you will got new number
  );

  return strictExist;
}

/**
 * Checks if a row should be excluded from drilldown based on given criteria.
 *
 * @param {Object} excludes - The exclusion criteria with `key` and `values`.
 * @param {Object} row - The row to be checked.
 * @returns {boolean} - Returns `true` if the row should be excluded, `false` otherwise.
 */
export function checkExcludeFromDrilldownRow(excludes, row) {
  const { key, values } = excludes ?? {};
  // Validate input parameters
  if (!key || !Array.isArray(values)) {
    return false;
  }

  // Check if any item in the row's values matches the exclusion criteria
  return (row?.values ?? []).some((item) => values.includes(item[key]));
}

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const FormError = styled.div`
  background-color: ${({ theme }) => theme.notification.errorMain};
  color: ${({ theme }) => theme.text.primary};
  padding: 5px 0;
  text-align: center;
  width: 100%;
  margin-bottom: 4px;
`;

export default function LoginFormErrors(props) {
  return props.errors ? (
    <FormError data-cy={props.cy} data-login-result>
      {props.errors.map((err, i) => (
        <p key={err + i}>{err}</p>
      ))}
    </FormError>
  ) : null;
}
LoginFormErrors.propTypes = {
  errors: PropTypes.array,
  cy: PropTypes.string,
};

import React, { useCallback } from "react";
import styled from "@emotion/styled";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function getOverflow(isGlobalSticky, overflowX) {
  if (isGlobalSticky) {
    return "";
  }

  return `overflow: ${overflowX ?? "auto"};`;
}

function getMaxHeight(stickyHeaders, isGlobalSticky) {
  if (isGlobalSticky || !stickyHeaders) {
    return "";
  }

  return "max-height: 500px;";
}

const Container = styled.div(
  ({
    theme,
    overflowX,
    expanded,
    scrollBarHeight,
    stickyHeaders,
    scrollBarWidth,
    isGlobalSticky,
  }) => `
    display: flex;
    ${getOverflow(isGlobalSticky, overflowX)};
    color: ${theme.text.secondary};

    &:focus {
        outline: 0;
    }
    
    &::-webkit-scrollbar {
        height: ${scrollBarHeight}em;
        width: ${scrollBarWidth}em;
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${theme.blueGray.blueGray50};
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #90a4ae;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background-color: ${theme.blueGray.blueGray500};
    }
    
    td, th {
        vertical-align: bottom;
    }

    & > table > tfoot {
        th, td {
            border: none;
        }
        font-weight: 600;
        color: ${theme.text.primary};
    }
    @media (max-width: ${mobileBreakpoints.mobile}) {
        ${expanded ? null : `width: calc(100vw - 18px)`};
        overflow-x: auto;
    }
    ${getMaxHeight(stickyHeaders, isGlobalSticky)}
`
);

export default React.memo(function TableContainer(props) {
  const domain = useSelector((state) => state.layout.domain);
  const scrollBarHeight = domain === "ccg" ? 1.1 : 0.5;
  const scrollBarWidth = domain === "ccg" ? 1.1 : 0.5;
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  // scroll table to concrete row/column
  const { scrollConfig } = props;

  const scrollTo = useCallback((table, element, type, offset) => {
    if (element) {
      const key = type === "horizontal" ? "left" : "top";
      table.scrollTo({
        [key]: element.getBoundingClientRect()[key] - offset,
        behavior: "smooth",
      });
    }
  }, []);

  const scrollByStyleComparison = useCallback(
    (table, elements) => {
      const { type, offset, style, mobileOffset } = scrollConfig;
      const element = Array.from(elements).find(
        (el) => getComputedStyle(el)[style.property] === style.value
      );

      scrollTo(table, element, type, isMobile ? mobileOffset : offset);
    },
    [scrollConfig, scrollTo, isMobile]
  );

  const scrollCallback = useCallback(
    (table) => {
      if (!scrollConfig) {
        return;
      }

      if (table) {
        const { type, offset, anchorElement, anchorWord, style, mobileOffset } =
          scrollConfig;
        const elements = table.getElementsByTagName(anchorElement);

        if (style) {
          return scrollByStyleComparison(table, elements);
        }

        const element = Array.from(elements).find((el) =>
          el.innerHTML.includes(anchorWord)
        );

        scrollTo(table, element, type, isMobile ? mobileOffset : offset);
      }
    },
    [scrollConfig, scrollByStyleComparison, scrollTo, isMobile]
  );

  return (
    <Container
      freezeLeft={props.freezeLeft}
      freezeWidth={props.freezeWidth}
      overflowX={props.overflowX}
      expanded={props.expanded}
      scrollBarHeight={scrollBarHeight}
      scrollBarWidth={scrollBarWidth}
      stickyHeaders={props.stickyHeaders}
      isGlobalSticky={props.isGlobalSticky}
      ref={scrollCallback}
    >
      {props.children}
    </Container>
  );
});

import { dateRangeOperators } from "../../../Pages/DataManagement/DataExplorer/DataExplorerUI/ExplorerInputs/FieldOperatorList";
import { AGGREGATION_SUM } from "../../../utils/constants/constants";
import { unique } from "../../../utils/func";

// Use this to go FROM API format TO useFilters format
export default function (filters) {
  const uniqueFilters = unique((filters ?? []).map((f) => f.type));
  return uniqueFilters.map((f) => {
    const firstMatch = (filters ?? []).find((fil) => fil.type === f);

    if (firstMatch.subQueryValues) {
      const { operator, value, type } = firstMatch.subQueryValues;

      return {
        name: f,
        subQueryValues: {
          operator: operator || "==",
          values: [value],
          aggregation: { type: AGGREGATION_SUM },
          name: type,
        },
      };
    }

    return {
      name: f,
      operator: firstMatch.operator || "==",
      values: [
        ...new Set(
          (filters ?? [])
            .filter((fil) => fil.type === f)
            .map((fil) => fil.value)
        ),
      ],
    };
  });
}

// Use this to go TO API format and FROM useFilters format
export function convertFiltersToConfigFormat(filters) {
  return (filters ?? []).reduce((acc, curr) => {
    if (curr.subQueryValues) {
      const configFilter = {
        type: curr.name,
        subQueryValues: {
          ...convertFiltersToConfigFormat([curr.subQueryValues])[0],
          aggregation: AGGREGATION_SUM,
        },
      };

      return [...acc, configFilter];
    }

    return [
      ...acc,
      ...(curr.values ?? []).map((v) => {
        const configFilter = {
          type: curr.name,
          value: v,
        };

        const notRange = !dateRangeOperators.includes(curr.operator);

        if (curr.operator && curr.operator !== "==" && notRange) {
          configFilter.operator = curr.operator;
        }
        return configFilter;
      }),
    ];
  }, []);
}

// https://www.w3schools.com/js/js_cookies.asp
import { stringify, parse } from "qs";
import { localStorageFiltersKey } from "./constants/constants";

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

// https://stackoverflow.com/a/61596084/1381550
export const inIFrame = window !== window.parent;

/**
 * Amends a URL that already may or may not have query parameters with
 * additional query parameters.
 * @todo, this is currently only being used for Scheduled Export, but we need settings passed also, so handling
 * as basic object there. Leaving here since I see this coming in handy.
 *
 * @param url {string}
 * @param query {?object}
 * @returns {*}
 */
export function amendUrlWithQuery(url, query = {}) {
  const [baseUrl, ...restParts] = url.split("?");
  const baseQuery = restParts ? parse(restParts.join("?")) : {};

  return (
    baseUrl +
    stringifyMinimal({ ...baseQuery, ...query }, { addQueryPrefix: true })
  );
}

const encodeMapping = {
  "#": "%23",
  "&": "%26",
};

let toReplaceCharactersRegExp;

export function stringifyMinimal(queryObject, additionalOptions) {
  return stringify(queryObject, {
    encoder: encodeURIMinimal,
    encodeValuesOnly: true,
    ...additionalOptions,
  });
}

/**
 * Like encodeURIComponent, but conservative on which characters it encodes.
 * @param uriComponent
 * @returns {*}
 */
export function encodeURIMinimal(uriComponent) {
  if (typeof uriComponent !== "string") {
    return uriComponent;
  }
  if (!toReplaceCharactersRegExp) {
    toReplaceCharactersRegExp = new RegExp(
      Object.keys(encodeMapping)
        .map((v) => `\\${v}`)
        .join("|"),
      "g"
    );
  }
  return uriComponent?.replace(
    toReplaceCharactersRegExp,
    escapeURIMinimalReplacement
  );
}

function escapeURIMinimalReplacement(character) {
  return encodeMapping[character] ?? character;
}

export const originalQueryParsed = Object.freeze(
  parse(window.initialWindowLocationSearch, {
    ignoreQueryPrefix: true,
  })
);

/**
 * @deprecated Should be removed in the future once most localStorage filters
 *  have gotten saved to the API.
 * @returns {any|{}}
 */
export const getFiltersFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem(localStorageFiltersKey)) ?? {};
};

import React, { Fragment } from "react";
import ActiveGrid from "./ActiveGrid";
import { unique } from "../../../utils/func";
import GridSplitCollapse from "./GridSplitCollapse";
import { cloneDeep } from "lodash-es";

export default function ActiveGridDisplay(props) {
  const {
    rowData,
    columns,
    handleChange,
    splitBy,
    collapsed,
    toggleCollapsed,
    stringMatch,
    activeTableDataLoading,
    joinedMode,
  } = props;

  const filteredData =
    stringMatch?.length >= 2
      ? rowData.filter((row) =>
          Object.values(row).some(
            (val) =>
              val &&
              val.toString().toLowerCase().includes(stringMatch.toLowerCase())
          )
        )
      : rowData;

  const copiedRowData = cloneDeep(filteredData) ?? [];

  let splitKeys, keyedData;

  function getHeight(key) {
    const rowCount = keyedData[key]?.length;
    return rowCount < 6 ? rowCount * 44 + 70 : 300;
  }

  function getKeyedData(splitKeys) {
    return splitKeys.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: copiedRowData.filter((r) => r[splitBy.value] === curr),
      };
    }, {});
  }

  if (splitBy?.value) {
    splitKeys = unique(copiedRowData.map((r) => r[splitBy.value]));
    keyedData = getKeyedData(splitKeys);

    return splitKeys.map((key, index) => {
      // to collapse / expand nullable values
      const postfix = "-" + index;
      const isCollapsed = collapsed.find((c) => c === key + postfix);

      return (
        <Fragment key={key + postfix}>
          <GridSplitCollapse
            selection={key}
            handleToggle={() => toggleCollapsed(key + postfix)}
            collapsed={isCollapsed}
          />
          {isCollapsed ? null : (
            <ActiveGrid
              rowData={keyedData[key]}
              columns={columns}
              handleChange={handleChange}
              height={getHeight(key)}
              space
              activeTableDataLoading={activeTableDataLoading}
              joinedMode={joinedMode}
            />
          )}
        </Fragment>
      );
    });
  }

  return (
    <ActiveGrid
      rowData={copiedRowData}
      columns={columns}
      handleChange={handleChange}
      activeTableDataLoading={activeTableDataLoading}
      joinedMode={joinedMode}
    />
  );
}

import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Select from "../../UI/Form/Select/Select";
import { isMobileDevice } from "../../fixtures/userAgent";
import { useTheme } from "emotion-theming";
import { createDateOptions } from "../../utils/formatters/dateFormatter";
import startOfMonth from "date-fns/startOfMonth";
import startOfQuarter from "date-fns/startOfQuarter";
import startOfWeek from "date-fns/startOfWeek";
import startOfYear from "date-fns/startOfYear";
import { absoluteDate } from "../../utils/dates/dateFunc";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.notification.errorDark};
  margin: 0 12.5px;
  border-radius: 4px;
  text-align: right;
`;

const message = "Invalid Date Range";

// This is a short-term solution
// Used to match the option value for the end period, which has values only for the start of the period
function endValueCalculatedToBeginning(dateFiltersConfig, endValue, options) {
  const term = dateFiltersConfig.rangeSettingsTerm;
  const fns = {
    quarterly: startOfQuarter,
    monthly: startOfMonth,
    weekly: startOfWeek,
    yearly: startOfYear,
  };
  const startVal = fns[term] ? fns[term](absoluteDate(endValue)) : endValue;
  const startValString = isValid(startVal)
    ? format(startVal, "yyyy-MM-dd")
    : "";
  return options.find((o) => o.value === startValString);
}

// Range-only filter mode.
export default function MenuDateSelect(props) {
  const { show, dateFiltersConfig, onChange, startDate, endDate } = props;
  //we are looking at onChange
  const options = useMemo(
    () => createDateOptions(dateFiltersConfig),
    [dateFiltersConfig]
  );

  const startOfEnd = endValueCalculatedToBeginning(
    dateFiltersConfig,
    endDate,
    options
  );
  const [range, setRange] = useState({
    start: options.find(
      (option) => option.label === startDate || option.value === startDate
    ),
    end: options.find(
      (option) =>
        option.label === endDate ||
        (startOfEnd && option.value === startOfEnd.value)
    ),
    notValidDate: false,
  });

  const selectStartRef = useRef(null);
  const selectEndRef = useRef(null);
  const theme = useTheme();
  const isMobile = isMobileDevice();

  const handleChange = (period, value) => {
    if (range[period] && range[period].label === value.label) return;
    const newRange = { ...range, [period]: value };

    const notValidDate = getTime(newRange.start) > getTime(newRange.end);

    // update local and dispatch new values
    setRange({ ...newRange, notValidDate });

    if (!notValidDate) {
      onChange(newRange.start.value, newRange.end.value);
    }
  };

  const getTime = (val) => {
    return new Date(val.value).getTime();
  };

  // @todo works only after option was changed
  const scrollIntoView = (ref, option) => {
    setTimeout(() => {
      ref.current.select.scrollToFocusedOptionOnUpdate = true;
      ref.current.select.setState({ focusedOption: option });
    }, 0);
  };

  if (!show) return null;

  return (
    <div style={{ position: "relative" }} data-cy="date-range-selects">
      <div
        style={{
          textAlign: "left",
          paddingLeft: 5,
          marginTop: 12,
          marginBottom: 5,
        }}
      >
        From
      </div>
      <Select
        ref={selectStartRef}
        placeholder="Date Start..."
        options={options}
        range={range}
        onChange={(value) => handleChange("start", value)}
        value={range.start}
        onMenuOpen={() =>
          scrollIntoView(
            selectStartRef,
            options.find((option) => option.value === range?.start?.value)
          )
        }
        menuPlacement={isMobile ? "top" : "bottom"}
        bgPrimary={theme.menuPrimary}
        cy="select-date-range-start"
      />

      <div
        style={{
          textAlign: "left",
          paddingLeft: 5,
          marginTop: 12,
          marginBottom: 5,
        }}
      >
        To
      </div>
      <Select
        ref={selectEndRef}
        placeholder="Date End..."
        options={options}
        range={range}
        onChange={(value) => handleChange("end", value)}
        value={range.end}
        onMenuOpen={() =>
          scrollIntoView(
            selectEndRef,
            options.find((option) => option.value === range?.end?.value)
          )
        }
        menuPlacement={isMobile ? "top" : "bottom"}
        bgPrimary={theme.menuPrimary}
        cy="select-date-range-end"
      />
      {range.notValidDate && <ErrorMessage>{message}</ErrorMessage>}
    </div>
  );
}

MenuDateSelect.defaultProps = {
  options: [],
};

MenuDateSelect.propTypes = {
  options: PropTypes.array,
  dateFiltersConfig: PropTypes.object,
};

import React from "react";
import LeftRightArea from "../../../../UI/BigInput/LeftRightArea";
import HeroBlockLayout from "../HeroBlockLayout";

const ProductIgnore = (props) => {
  const {
    sourceValues,
    handleSave,
    values,
    getUniqueRawValuesCallback,
    uniqueValuesTotalCount,
    ...rest
  } = props;
  const message = {
    title: "Why would you ignore anything?",
    text: `You may have products you’re no longer tracking, or test products, or
      just products you don’t want anyone to know about. Adding items to the
       ignore list will help clear out unwanted noise so you can focus on your
      real business without distraction`,
  };

  const filteredSourceValues = sourceValues.filter(
    (v) => !values.find((vv) => vv.label === v.id)
  );

  return (
    <HeroBlockLayout
      message={message}
      handleSave={handleSave}
      initRightArray={values}
      {...rest}
    >
      <>
        <div style={{ height: "100%", marginBottom: 12 }}>
          <LeftRightArea
            data={filteredSourceValues}
            rightLabel="Blacklist"
            getUniqueRawValuesCallback={getUniqueRawValuesCallback}
            uniqueValuesTotalCount={uniqueValuesTotalCount}
          />
        </div>
      </>
    </HeroBlockLayout>
  );
};

export default ProductIgnore;

import styled from "@emotion/styled";
import React from "react";
import IOButton from "../../../UI/Form/Button/IOButton";
import Input from "../../../UI/Form/Input/Input";

const Hr = styled.div`
  border-bottom: 1px solid ${({ theme }) =>
    theme.menuPrimary ? "rgba(255,255, 255, 0.1)" : theme.divider};};
`;

export default function FilterInputs({
  varyBy,
  filterInputs,
  newFilters,
  isCompany,
  change,
  onSubmit,
}) {
  const shouldRender = varyBy ? (
    filterInputs.map((element) => (
      <Input
        label={element.label}
        key={element.value}
        value={newFilters[element.value] ?? ""}
        onChange={(e) => change(e.target, element)}
        overPrimary
      />
    ))
  ) : (
    <Input
      label={isCompany ? "Potential" : "Potential Per Employee"}
      onChange={(e) =>
        change(e.target, {
          value: isCompany ? "perCompany" : "perEmployee",
        })
      }
      value={newFilters.perCompany || newFilters.perEmployee || ""}
      overPrimary
    />
  );

  return (
    <>
      {shouldRender}
      <br />
      <Hr />
      {!isCompany && (
        <Input
          label="% of Company Employees to Include"
          onChange={(e) =>
            change(e.target, {
              value: "percentToEnclude",
            })
          }
          value={newFilters.percentToEnclude || ""}
          overPrimary
        />
      )}
      <IOButton smallPadding onClick={() => onSubmit(newFilters)}>
        Set Filters
      </IOButton>
    </>
  );
}

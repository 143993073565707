import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createReportedQuery,
  deleteReportedQuery,
  getReportedQueries,
} from "../../../store/actions/automation";
import Flex from "../../../UI/Flex/Flex";
import IOButton from "../../../UI/Form/Button/IOButton";
import { getReportPayload } from "../components/automationHelper";
import { useHistory } from "react-router";
import { useTheme } from "emotion-theming";
import ScheduledTable from "./SchduledTable";

const Container = styled.div`
  display: flex;
  padding-left: 230px;
  @media (max-width: 1199px) {
    padding-left: 0;
  }
  width: 100%;
  flex-direction: column;
  th,
  td {
    text-align: left !important;
  }
`;

const Title = styled.div`
  font-size: 18px;
`;

export default function ScheduledEmails() {
  const { loading, reportedQueries } = useSelector((state) => state.automation);
  const [isActive, setIsActive] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    dispatch(getReportedQueries());
  }, [dispatch]);

  function deleteReport(e, uuid) {
    e.stopPropagation();
    dispatch(deleteReportedQuery(uuid));
  }

  function changeActive(e, report) {
    e.stopPropagation();
    const active = isActive[report.uuid] ?? report.active;
    setIsActive({ [report.uuid]: !active });
    const payload = getReportPayload({ ...report, active });
    dispatch(createReportedQuery(payload, report.uuid));
  }

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="center">
        <Title>Your scheduled emails</Title>
        <IOButton
          add
          smallPadding
          outline
          to="/admin/automation/scheduled-emails/new"
        >
          Add new scheduled email
        </IOButton>
      </Flex>
      <br />
      <ScheduledTable
        reportedQueries={reportedQueries}
        loading={loading}
        history={history}
        isActive={isActive}
        changeActive={changeActive}
        deleteReport={deleteReport}
        theme={theme}
      />
    </Container>
  );
}

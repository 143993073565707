import Checkbox from "../../../../../../UI/Form/Checkbox/Checkbox";

export default function HideHeaderText({
  setChartState,
  chart,
  selectedColumn,
}) {
  const { column } = selectedColumn;

  const array = chart.hiddenSubTitles ?? [];
  const checked = array.some((sub) => sub === column);

  function hideSubTitle() {
    const newHiddenSubTitles = checked
      ? array.filter((sub) => sub !== column)
      : [...array, column];

    setChartState({ ...chart, hiddenSubTitles: newHiddenSubTitles });
  }

  return (
    <div>
      <Checkbox
        label="Hide Header Text"
        onChange={hideSubTitle}
        checked={checked}
      />
    </div>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import ProductIgnore from "../DataSetup/ProductSetup/ProductIgnore";
import ProductSynonyms from "../DataSetup/ProductSetup/ProductSynonyms";
import ProductGroupUnder from "../DataSetup/ProductSetup/ProductGroupUnder";
import ProductPrettyNames from "../DataSetup/ProductSetup/ProductPrettyNames";
import HeroGrid from "../DataSetup/UX/HeroGrid";
import Square from "../DataSetup/UX/Square";
import GridElement from "../DataSetup/UX/GridElement";
import ProductIgnoreSummary from "../DataSetup/ProductSetup/ProductIgnoreSummary";
import ProductSynonymSummary from "../DataSetup/ProductSynonymSummary";
import ProductGroupingSummary from "../DataSetup/ProductSetup/ProductGroupingSummary";
import ProductPrettySummary from "../DataSetup/ProductSetup/ProductPrettySummary";
import ignoreImage from "../DataSetup/assets/Ignore.png";
import synonymImage from "../DataSetup/assets/Synonyms.png";
import groupingImage from "../DataSetup/assets/Grouping.png";
import prettyImage from "../DataSetup/assets/Prettify.png";
import IOButton from "../../../UI/Form/Button/IOButton";
import {
  createSeeder,
  getOutputColumnName,
} from "../../../store/actions/dataSettings/blueprintHelpers";
import { useParams } from "react-router-dom";

const OuterSetup = styled.div`
  display: flex;
  height: calc(100vh - 162px);
  align-items: stretch;
  width: 100%;
`;

const Grid = styled.div`
  margin-left: 12px;
  width: 100%;
`;

const HeroGridContainer = styled.div`
  height: calc(100vh - 140px);
  display: flex;
  align-items: stretch;
`;

export default function BluePrintSettings(props) {
  const {
    sourceValues = [],
    saveBlueprint,
    blueprints,
    getUniqueRawValuesCallback,
    uniqueValuesTotalCount,
    etlChains,
    chainUuid,
  } = props;

  const { blueprintGroupId } = useParams();
  const seed = createSeeder(blueprints, blueprintGroupId);

  const [activeIndex, setActiveIndex] = useState(null);
  const [ignored, setIgnored] = useState(seed("excludes"));
  const [synonyms, setSynonyms] = useState(seed("synonyms"));
  const [groupUnders, setGroupUnders] = useState(seed("grouping"));
  const [labels, setLabels] = useState(seed("prettify"));

  const [outputColumnName, setOutputColumnName] = useState(
    getOutputColumnName(etlChains, chainUuid)
  );

  function saveIgnored(nextIgnored) {
    setIgnored(nextIgnored);
    saveBlueprint("excludes", nextIgnored);
  }

  function saveSynonyms(nextSynonyms) {
    setSynonyms(nextSynonyms);
    saveBlueprint("synonyms", null, nextSynonyms);
  }

  function savePrettify(nextPrettify) {
    setLabels(nextPrettify);
    saveBlueprint("prettify", nextPrettify);
  }

  function setGrouping(nextGroups) {
    setGroupUnders(nextGroups);
    saveBlueprint("grouping", nextGroups, null, outputColumnName);
  }

  const components = [
    <ProductIgnore
      values={ignored}
      sourceValues={sourceValues}
      uniqueValuesTotalCount={uniqueValuesTotalCount}
      handleSave={saveIgnored}
      getUniqueRawValuesCallback={getUniqueRawValuesCallback}
    />,
    <ProductSynonyms
      sourceValues={sourceValues}
      uniqueValuesTotalCount={uniqueValuesTotalCount}
      synonyms={synonyms} // Why is this one different?
      handleSave={saveSynonyms}
      values={Object.keys(synonyms)}
      getUniqueRawValuesCallback={getUniqueRawValuesCallback}
    />,
    <ProductGroupUnder
      sourceValues={sourceValues}
      uniqueValuesTotalCount={uniqueValuesTotalCount}
      values={groupUnders}
      handleSave={setGrouping}
      getUniqueRawValuesCallback={getUniqueRawValuesCallback}
      setOutputColumnName={setOutputColumnName}
      outputColumnName={outputColumnName}
    />,
    <ProductPrettyNames
      sourceValues={sourceValues}
      uniqueValuesTotalCount={uniqueValuesTotalCount}
      values={labels}
      handleSave={savePrettify}
      getUniqueRawValuesCallback={getUniqueRawValuesCallback}
    />,
  ];

  const summaries = [
    <ProductIgnoreSummary values={ignored} />,
    <ProductSynonymSummary values={synonyms} />,
    <ProductGroupingSummary values={groupUnders} />,
    <ProductPrettySummary values={labels} />,
  ];

  const fallbacks = [ignoreImage, synonymImage, groupingImage, prettyImage];

  const emptySmall = [true, false, false, true];

  return (
    <OuterSetup>
      <Grid>
        <HeroGridContainer>
          <HeroGrid activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
            {[0, 1, 2, 3].map((v, i) => (
              <Square cy="product-setup-tile" key={i}>
                <GridElement
                  title={gridTitles[v]}
                  component={components[v]}
                  infoLabel={infoLabels[v]}
                  empty={fallbacks[v]}
                  emptySmall={emptySmall[v]}
                  summary={summaries[v]}
                />
              </Square>
            ))}
          </HeroGrid>
        </HeroGridContainer>
      </Grid>
      <div style={{ width: 200, paddingLeft: 12 }}>
        <div>Preview</div>
        <div style={{ marginTop: 24 }}>
          <Link to="/admin/data-blueprint">
            <IOButton cy="back" cancel>
              Back
            </IOButton>
          </Link>
        </div>
      </div>
    </OuterSetup>
  );
}

const gridTitles = ["Ignore", "Synonyms", "Grouping", "Prettify"];

const infoLabels = [
  "Refine your included product list by creating a blacklist",
  "Clean up typos and alternate namings for your product list",
  "Define your product hierarchy",
  "Set your human readable proper product names",
];

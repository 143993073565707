import React from "react";
import styled from "@emotion/styled";
import ButtonOutline from "../ButtonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../UI/Form/Input/Input";
import { faTasks } from "@fortawesome/pro-light-svg-icons";
import Tippy from "@tippyjs/react";

const Container = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  & > div {
    display: inline-block;
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

export default function ActiveTableColumnSelectorControls(props) {
  const {
    decreaseTileSize,
    increaseTileSize,
    stringMatch,
    handleStringMatchChange,
    showEditable,
    showVisible,
    toggleShowVisible,
    toggleShowEditable,
    hideVisible,
    hideChecklist,
    maxChars,
  } = props;

  return (
    <Container>
      {!hideChecklist ? (
        <Tippy
          content={
            <span style={{ background: "white", color: "red" }}>
              Coming Soon
            </span>
          }
        >
          <div>
            <ButtonOutline title="Checklist mode">
              <FontAwesomeIcon icon={faTasks} />
            </ButtonOutline>
          </div>
        </Tippy>
      ) : null}
      {!hideVisible ? (
        <ButtonOutline
          title="Visible fields"
          onClick={toggleShowVisible}
          active={showVisible}
        >
          <FontAwesomeIcon icon={["fal", "eye"]} />
        </ButtonOutline>
      ) : null}

      <ButtonOutline
        title="Editable fields"
        onClick={toggleShowEditable}
        active={showEditable}
      >
        <FontAwesomeIcon icon={["fal", "pencil"]} />
      </ButtonOutline>
      <ButtonOutline
        title="Smaller titles to fit more"
        onClick={decreaseTileSize}
        disabled={maxChars <= 8}
      >
        S-
      </ButtonOutline>
      <ButtonOutline
        title="Larger titles to show more text"
        onClick={increaseTileSize}
        disabled={maxChars >= 60}
      >
        L+
      </ButtonOutline>
      <span style={{ paddingLeft: 18 }}>
        <Input
          placeholder="filter..."
          search
          value={stringMatch}
          onChange={handleStringMatchChange}
        />
      </span>
    </Container>
  );
}

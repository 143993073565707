import styled from "@emotion/styled";
import LeftMenuContainer from "../../../../Layout/LeftMenuContainer";

const Container = styled.div`
  & > div {
    left: 0;
    margin-top: 0;
    width: 300px;
    padding-top: 30px;
    height: calc(100vh - 30px);
  }
`;

export default function MenuFilters(props) {
  // update preview on filter select
  function selectPowerEditorModeFilter() {
    props.showPreview(props.chart, true);
  }

  return (
    <Container>
      <LeftMenuContainer
        show
        selectPowerEditorModeFilter={selectPowerEditorModeFilter}
      />
    </Container>
  );
}

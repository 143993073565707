import * as actions from "../actions/actionTypes";

const initialState = {
  users: null,
  roles: null,
  groups: null,
  accounts: null,
  loading: false,
  profileImageUpload: null,
  permissions: [],
  roleSuccess: false,
  roleLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_LIST_START:
    case actions.CREATE_USER_GROUP_START:
    case actions.CREATE_USER_START:
    case actions.DELETE_USER_START:
    case actions.DELETE_USER_GROUP_START:
    case actions.UPDATE_USER_START:
    case actions.UPLOAD_PROFILE_IMAGE_START:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.results.data,
      };

    case actions.CREATE_USER_SUCCESS:
    case actions.CREATE_USER_GROUP_SUCCESS:
    case actions.GET_USER_LIST_FAIL:
    case actions.UPDATE_USER_GROUP_FAIL:
    case actions.CREATE_USER_GROUP_FAIL:
    case actions.CREATE_USER_FAIL:
    case actions.DELETE_USER_FAIL:
    case actions.DELETE_USER_GROUP_FAIL:
    case actions.DELETE_USER_GROUP_SUCCESS:
    case actions.UPDATE_USER_SUCCESS:
    case actions.UPDATE_USER_FAIL:
    case actions.UPLOAD_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.UPDATE_USER_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: state.groups.map((group) =>
          group.uuid === action.results.data.uuid
            ? { ...group, ...action.results.data }
            : group
        ),
      };

    case actions.UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileImageUpload: action.results,
      };

    case actions.DELETE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        success: "Image deleted",
        error: null,
        profileImageUpload: null,
      };

    case actions.LOGOUT_START:
      return {
        ...state,
        profileImageUpload: null,
      };

    case actions.DELETE_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        error: "En error occured while deleting an image",
        success: null,
      };

    case actions.LOAD_USER_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.results.data,
      };

    case actions.LOAD_USER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.results.data,
      };

    case actions.CREATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
      };

    case actions.LOAD_USER_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.results.data,
      };

    case actions.LOAD_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        permissions: action.results.data,
      };

    case actions.CREATE_USER_ROLE_START:
    case actions.UPDATE_USER_ROLE_START:
      return {
        ...state,
        roleSuccess: false,
        roleLoading: true,
      };

    case actions.CREATE_USER_ROLE_SUCCESS:
    case actions.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        roleSuccess: true,
        roleLoading: false,
      };

    case actions.CREATE_USER_ROLE_FAIL:
    case actions.UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        roleLoading: false,
      };

    default:
      return state;
  }
};

import { dayFormat } from "../../../utils/formatters/ourFormatter";
import {
  addWeeks,
  startOfWeek,
  startOfMonth,
  addMonths,
  addDays,
} from "date-fns";
import { absoluteDate } from "../../../utils/dates/dateFunc";

const fns = {
  month: { add: addMonths, start: startOfMonth },
  week: { add: addWeeks, start: startOfWeek },
};

export default (start, end, type, count, hasFuture = false) => {
  const isFuture = absoluteDate(end) > new Date();
  const d = new Date();
  d.setHours(0, 0, 0, 0);

  const startDate = !hasFuture && isFuture ? d : absoluteDate(end);

  const startFn = fns[type].start(startDate);
  const timeBacked = fns[type].add(startFn, -(count - 1));
  const adjusted = type === "week" ? addDays(timeBacked, -1) : timeBacked; // Make this Saturday @todo make this configurable
  return { start: dayFormat(adjusted) };
};

import React from "react";
import { arc, pie } from "d3-shape";
import Arc from "../GaugeChart/Arc";
import { scaleOrdinal } from "d3-scale";
import "d3-transition";
import styled from "@emotion/styled";
import { summed } from "../../utils/func";
import { format } from "d3-format";
import formatter from "../../utils/formatters/formatter";

const PieLabel = styled.text`
  fill: rgba(255, 255, 255, 0.9);
  text-anchor: middle;
  font-size: 14px;
  pointer-events: none;
`;

const defaultColors = scaleOrdinal([
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
]);

export default function PieChart(props) {
  const {
    width,
    height,
    colors,
    data,
    delay,
    labels,
    setTooltip,
    meta,
    valueKey,
    hidePieValues,
    valueKeys,
    showPercentsInTooltip,
    disableTooltip,
    includeCategoryInTooltip,
  } = props;

  const chartColors = colors || defaultColors;

  const size = Math.min(...[+height, +width]);
  const leftMargin = width > height ? (width - size) / 2 : 0;

  const total = summed(data.map((d) => Math.abs(d.value)));
  const arcs = pie().value((d) => d.value)(data);

  const createArc = arc()
    .outerRadius(size / 2)
    .innerRadius(0);

  const textArc = arc()
    .innerRadius(size * 0.1)
    .outerRadius(size * 0.6);

  const tipArc = arc()
    .innerRadius(0)
    .outerRadius(size * 0.6);

  const percentArcs = arcs.map((a) => ({
    ...a,
    percent: hidePieValues ? "" : Math.abs(+a.value) / total,
  }));

  const centerPos = (a, i) => {
    const res = textArc.centroid(a);
    return [res[0], res[1]];
  };

  const calcTooltipPercent = (arc) => {
    const total = percentArcs.reduce((a, b) => (a += b.value), 0);
    return formatter(arc.value / total, "percent");
  };

  return (
    <g
      transform={`translate(${leftMargin + size / 2} ${size / 2})`}
      data-cy="chart-pie"
      width-attr={width}
    >
      {percentArcs.map((a, i) => (
        <Arc
          key={a.data + "-" + i}
          data={{
            ...a,
            ...(showPercentsInTooltip && {
              percentValue: calcTooltipPercent(a),
            }),
          }}
          index={i}
          createArc={createArc}
          colors={chartColors}
          delay={delay}
          width={width}
          setTooltip={!disableTooltip && setTooltip}
          meta={meta}
          valueKey={valueKeys ? a.data.name : valueKey}
          tipArc={tipArc}
          showPercentsInTooltip={showPercentsInTooltip}
          disableTooltip={disableTooltip}
          includeCategoryInTooltip={includeCategoryInTooltip}
          cy="pie-slice"
        />
      ))}

      {labels &&
        percentArcs.map((a, i) =>
          a.percent > 0.02 ? (
            <PieLabel
              key={i}
              transform={`translate(${centerPos(a, i)})`}
              data-cy={`pie-label-${a.data.name}`}
            >
              {format(".1%")(a.percent)}
            </PieLabel>
          ) : null
        )}
    </g>
  );
}

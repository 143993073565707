import styled from "@emotion/styled";
import FilterItem from "../FilterItem";

const Container = styled.div`
  font-style: italic;
`;

export default function SelectAllCheckboxItem({
  inComparisonList,
  filters = [],
  checked,
  selectAll,
  type = "checkbox",
  allSelected,
}) {
  const label = allSelected ? "Unselect" : "Select";

  if (!inComparisonList || filters.length < 2) {
    return null;
  }

  return (
    <Container data-cy="select-all-conatainer">
      <FilterItem
        name={`${label} All (${filters.length})`}
        checked={checked ? "checked" : ""}
        onChange={selectAll}
        cy="filter-menu-input-select-all"
        type={type}
      />
    </Container>
  );
}

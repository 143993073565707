import { createContext, useContext } from "react";

const Context = createContext({});

export function ChartProvider(chartProps) {
  return <Context.Provider {...chartProps} />;
}

export function useChartContext() {
  return useContext(Context);
}

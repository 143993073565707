const defaultValues = [
  { originalValue: "placeholder", synonym: "placeholder" },
];

export function convertBlueprintResponse(chainData) {
  const response = [];

  // Maps to track group IDs and corresponding inputs
  const groupIdMap = new Map();

  chainData.forEach((item) => {
    item.steps.forEach((step) => {
      if (!groupIdMap.has(step.blueprint.groupId)) {
        // Create a new index for new group ID
        groupIdMap.set(step.blueprint.groupId, {
          name: step.blueprint.name,
          uuid: step.blueprint.uuid,
          groupId: step.blueprint.groupId,
          inputs: [],
        });
        response.push(groupIdMap.get(step.blueprint.groupId));
      }

      const currentEntry = groupIdMap.get(step.blueprint.groupId);
      const newInput = {
        table: item.tableName,
        field: step.otherInput.rawColumnName,
        chainUuid: item.uuid,
      };

      // Check for duplicate before adding new input
      if (
        !currentEntry.inputs.some(
          (input) =>
            input.table === newInput.table && input.field === newInput.field
        )
      ) {
        currentEntry.inputs.push(newInput);
      }
    });
  });

  return response;
}

function buildSettingsExcludes(excludes) {
  return {
    excludes: excludes.length
      ? excludes.map((v) => ({ originalValue: v.id }))
      : defaultValues,
  };
}

function buildSettingsSynonyms(synonymsObject) {
  const synonyms = Object.keys(synonymsObject);
  return {
    synonyms: synonyms.length
      ? synonyms.reduce((acc, curr) => {
          return [
            ...acc,
            ...synonymsObject[curr].map((v) => ({
              originalValue: v.label,
              synonym: curr,
            })),
          ];
        }, [])
      : defaultValues,
  };
}

function buildSettingsPrettify(prettify) {
  return {
    synonyms: prettify.length
      ? prettify.map((prettify) => ({
          originalValue: prettify.original.label,
          synonym: prettify.override.label,
        }))
      : defaultValues,
  };
}

function buildSettingsGroupings(groups) {
  return {
    grouping: groups.reduce((acc, curr) => {
      return [
        ...acc,
        ...(curr.children ?? []).map((child) => ({
          originalValue: child.label,
          groupName: curr.label,
        })),
      ];
    }, []),
  };
}

const getFirstBlueprintName = (arr, groupId) => {
  const blueprint = arr.find((blueprint) => blueprint.groupId === groupId);
  return blueprint ? blueprint.name : "Untitled";
};

export function makeBlueprintPayload(
  groupId,
  blueprints,
  preset,
  rawColumnNamesArray,
  rawValuesObject
) {
  const blueprint = blueprints.find(
    (bp) => bp.groupId === groupId && bp.preset === preset
  );

  const name = getFirstBlueprintName(blueprints, groupId);

  let settings;

  switch (preset) {
    case "excludes":
      settings = buildSettingsExcludes(rawColumnNamesArray);
      break;
    case "synonyms":
      settings = buildSettingsSynonyms(rawValuesObject);
      break;
    case "grouping":
      settings = buildSettingsGroupings(rawColumnNamesArray);
      break;
    case "prettify":
      settings = buildSettingsPrettify(rawColumnNamesArray);
      break;

    default:
      settings = [];
  }

  return {
    uuid: blueprint ? blueprint.uuid : undefined,
    preset,
    groupId,
    settings,
    name,
  };
}

function generatePresetKey(preset) {
  return preset === "prettify" ? "synonyms" : preset;
}

export const createSeeder = (blueprints, groupId) => (preset) => {
  const results = preset === "synonyms" ? {} : [];

  blueprints
    .filter(
      (blueprint) =>
        blueprint.groupId === groupId && blueprint.preset === preset
    )
    .forEach((blueprint) => {
      const key = generatePresetKey(preset);
      const settings = blueprint.settings[key] ?? [];

      settings.forEach((setting) => {
        if (preset === "excludes" && setting.originalValue !== "placeholder") {
          results.push({
            id: setting.originalValue,
            label: setting.originalValue,
          });
        }

        if (preset === "synonyms" && setting.originalValue !== "placeholder") {
          if (!results[setting.synonym]) {
            results[setting.synonym] = [];
          }
          results[setting.synonym].push({
            id: setting.originalValue,
            label: setting.originalValue,
          });
        }

        if (preset === "prettify" && setting.originalValue !== "placeholder") {
          results.push({
            original: {
              id: setting.originalValue,
              label: setting.originalValue,
            },
            override: { label: setting.synonym },
          });
        }

        if (preset === "grouping" || preset === "outputColumnName") {
          const index = results.findIndex(
            (res) => res.label === setting.groupName
          );

          if (index !== -1) {
            results[index].children.push({
              id: setting.originalValue,
              label: setting.originalValue,
            });
          } else {
            results.push({
              label: setting.groupName,
              children: [
                { id: setting.originalValue, label: setting.originalValue },
              ],
            });
          }
        }
      });
    });

  return results;
};

function filterTypes(column) {
  return column.type === "string" || column.type === "text";
}

function filterIOTags(column) {
  const ioTags = [
    "IOUUID",
    "IOLastUpdatedBy",
    "IOCreatedBy",
    "io_uuid",
    "io_last_updated_by",
    "io_last_updated",
    "io_created",
    "io_created_by",
  ];

  return !ioTags.find((t) => column.name === t);
}

export function filterTableColumns(tables, name) {
  return (tables.find((table) => table.name === name)?.columns ?? [])
    .filter(filterIOTags)
    .filter(filterTypes)
    .map((c) => ({ value: c.name, label: c.name }));
}

export function getOutputColumnName(etlChains, chainUuid) {
  const chain = etlChains.find((chain) => chain.uuid === chainUuid);
  const { otherInput } =
    chain.steps.find((step) => step.blueprint.preset === "grouping") ?? {};

  return otherInput?.outputColumnName ?? "";
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Message = styled.div`
  font-size: 18px;
  text-align: left;
  margin-top: 8px;
  color: ${(props) => props.theme.text.secondary};
  svg {
    font-size: 14px;
    margin-right: 8px;
  }
  margin-bottom: 12px;
`;

export default function FilterInstruction(props) {
  if (!props.show) return null;
  return (
    <Message data-cy={props.cy}>
      <FontAwesomeIcon icon={["fas", "arrow-left"]} />
      {props.message}
    </Message>
  );
}

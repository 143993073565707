import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ToastManager from "./ToastManager";
import * as actions from "../../store/actions";

export default function Toaster(props) {
  const dispatch = useDispatch(); // this will be used for dismissing only
  const message = useSelector((state) => state.message);
  // onRest when leaving
  return (
    <ToastManager
      toasts={message.toasts}
      handleDismiss={(id) => dispatch(actions.dismissToast(id))}
    />
  );
}

Toaster.defaultProps = {
  toasts: [],
};

Toaster.propTypes = {
  toasts: PropTypes.array,
};

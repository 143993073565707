import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";
import Tipper from "../../../../UI/Tipper/Tipper";
import EditSortPanel from "./EditSortPanel";

const SortItem = styled.div`
  padding: 2px 12px;
  border: 1px solid ${(props) => props.theme.background.primary};
  margin-right: 8px;
  cursor: pointer;
  border-radius: 2px;
  background: ${(props) =>
    props.isDragging ? props.theme.notification.infoMain : "transparent"};
  &:hover {
    background: ${(props) => props.theme.background.primary};
  }
  svg {
    margin-left: 8px;
    font-size: 10px;
  }
`;

const SortContainer = styled.div`
  display: flex;
  span {
    opacity: 0.5;
    padding: 3px 18px 3px 32px;
    svg {
      margin-left: 12px;
    }
  }
`;

const Remove = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding-top: 3px;
  cursor: pointer;
  color: ${(props) => props.theme.text.primary};
  &:hover {
    opacity: 1;
  }
`;

function getListStyle() {
  return {
    display: "flex",
    overflow: "visible",
  };
}

export default function SortMapping(props) {
  const { orders, setOrderPriority, setSorting, removeSortingKey } = props;
  const [editMode, setEditMode] = useState(false);

  if (!orders?.length || orders?.length < 2) return null;

  function handleDragEnd(e, i) {
    e.stopPropagation();
    const newArray = orders.map((o) => o.name);
    const temp = newArray[i - 1];
    newArray[i - 1] = newArray[i];
    newArray[i] = temp;
    setOrderPriority(newArray);
  }

  function handleClick(name) {
    setSorting(name);
  }

  return (
    <SortContainer data-cy="sort-order-display">
      <span>
        Sorting
        <FontAwesomeIcon icon={["fas", "arrow-right"]} />
      </span>
      <div style={{ display: "flex" }}>
        {orders.map((item, i) => (
          <SortItem
            data-cy={`sort-order-item`}
            data-sort-id={item.name}
            key={item.name}
            onClick={() => handleClick(item.name)}
          >
            {item.label || item.name}
            <FontAwesomeIcon icon={["fas", icons[item.sort]]} />
          </SortItem>
        ))}
      </div>

      <Tipper label="Edit Sorting Options">
        <Remove
          data-cy="toggle-enter-sort-edit-mode"
          onClick={() => setEditMode((mode) => !mode)}
        >
          <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
        </Remove>
      </Tipper>
      {editMode ? (
        <EditSortPanel
          orders={orders}
          handleDragEnd={handleDragEnd}
          getListStyle={getListStyle}
          handleClick={handleClick}
          handleRemove={removeSortingKey}
          close={() => setEditMode(false)}
        />
      ) : null}
    </SortContainer>
  );
}

const icons = {
  ASC: "chevron-down",
  DESC: "chevron-up",
};

import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  //background: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: ${(props) => props.theme.notification.infoMain};
  padding: 6px;
  font-size: 9px;
  margin-right: 12px;
  min-width: 40px;
  height: 32px;
  span {
    font-size: 16px;
    margin-top: 4px;
  }
`;

export default function SampleBox(props) {
  const { label, value, style } = props;
  return (
    <Container style={style}>
      <div data-cy="filter-sample-label">{label}</div>
      <div>
        <span data-cy="filter-sample-value">{value}</span>
      </div>
    </Container>
  );
}

import React from "react";

import styled from "@emotion/styled";
import { themeColor, themeText } from "../../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonOutline from "../ButtonOutline";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import Scorecard from "./Scorecard";

const Container = styled.div`
  color: ${themeText("primary")};
  font-size: 14px;
  height: 220px;
  padding-top: 12px;
  position: relative;
  max-width: 720px;
`;

const Message = styled.div`
  background: ${themeColor("warningMain")};
  color: white;
  border-radius: 4px;
  padding: 18px;
  display: flex;
  align-items: center;
  svg {
    font-size: 24px;
    margin-right: 12px;
  }
`;

const ButtonsContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.emptyColumns ? 60 : 36)}px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  & > div {
    font-size: ${(props) => (props.emptyColumns ? 14 : 12)}px;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-right: 12px;
    min-width: ${(props) => (props.emptyColumns ? 220 : 100)}px;
    box-sizing: border-box;
  }
  svg {
    font-size: ${(props) => (props.emptyColumns ? 20 : 16)}px;
    margin-right: 12px;
    margin-left: 4px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: ${(props) => (props.emptyColumns ? 36 : 10)}px;
`;

const ScorecardWrapper = styled.div`
  display: flex;
  & > div {
    margin-right: 12px;
  }
`;

export default function ViewDetailsSummary(props) {
  const {
    visibleColumns,
    setAllVisible,
    setAllInvisible,
    allColumns = [],
  } = props;
  const emptyColumns = !visibleColumns.length;
  const editableColumns = visibleColumns.filter((c) => c.editable);
  const dateColumns = visibleColumns.filter(
    (c) => c.type === "date" || c.type === "datetime"
  );
  const totalEditableColumns = allColumns.filter((c) => c.editable);
  const hiddenEditableCount =
    totalEditableColumns.length - editableColumns.length;
  return (
    <Container>
      {!emptyColumns ? (
        <div style={{ fontWeight: 300, fontSize: 12 }}>View summary</div>
      ) : null}
      {emptyColumns ? (
        <Message data-cy="views-message">
          <FontAwesomeIcon icon={["fal", "exclamation-triangle"]} />
          There are no visible columns selected. Add the columns you want to see
          in this view.
        </Message>
      ) : null}
      <ContentWrapper emptyColumns={emptyColumns}>
        {!emptyColumns ? (
          <ScorecardWrapper>
            <Scorecard label="Visible" value={visibleColumns.length} visible />
            <Scorecard
              label="Editable"
              value={editableColumns.length}
              editable
            />
            <Scorecard label="Dates" value={dateColumns.length} />
            <Scorecard
              style={{ marginLeft: 48 }}
              label="Tot. Available"
              value={allColumns.length}
            />
            <Scorecard label="Hidden Edit" value={hiddenEditableCount} />
          </ScorecardWrapper>
        ) : null}
        <ButtonsContainer emptyColumns={emptyColumns}>
          {!emptyColumns ? (
            <ButtonOutline onClick={setAllInvisible}>
              <FontAwesomeIcon icon={faBan} />
              Clear all
            </ButtonOutline>
          ) : null}
          <ButtonOutline>
            <FontAwesomeIcon icon={["fal", "folder"]} />
            Start from existing view
          </ButtonOutline>
          <ButtonOutline onClick={setAllVisible}>
            <FontAwesomeIcon icon={["fal", "check"]} />
            Mark all columns visible
          </ButtonOutline>
        </ButtonsContainer>
      </ContentWrapper>
    </Container>
  );
}

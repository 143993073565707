import React from "react";
import { scaleLinear, scaleBand } from "d3-scale";
import { stack } from "d3-shape";
import YAxis from "../Axes/YAxis";
import SimpleXAxis from "../SimpleXAxis";
import BarLabels from "../BarLabels";
import { maxed } from "../../utils/func";
import SpringBar from "../GroupedBar/SpringBar";
import StackedLinesVisualization from "./StackedLinesVisualization";

export default function StackedBarTwo(props) {
  const {
    data,
    width,
    height,
    keys,
    colors,
    yFormat,
    yLabelFormat,
    labelFormat,
    xKeys,
    summedValues,
    setTooltip,
    term,
    bands,
    yAxisFormat,
    legendItems,
    hideBarLabels,
    initialData,
    useSameYAxisScale,
    lineKyes,
    circleKeys,
  } = props;
  if (!data) return null;

  const dataStack = stack().keys(keys);
  const seriesA = data[0]?.second ? dataStack(data.map((d) => d.second)) : null;
  const seriesB = dataStack(data.map((d) => d.first)); // Main

  const x = scaleBand().domain(xKeys).range([0, width]).paddingInner(0.4);

  // We create an arbitrary array that would have a length of up to 2 depending on how many bar groups are used.
  // We pass this array to domain().
  const arbitraryArray = [seriesA, seriesB].filter((v) => v != null);

  const xInner = scaleBand()
    .domain(arbitraryArray)
    .range([0, x.bandwidth()])
    .paddingInner(0.4);

  const rightYAxisKeys = [...lineKyes, ...circleKeys];

  const lineAndCircleYValues = initialData.flatMap((item) =>
    rightYAxisKeys.map((key) => item[key])
  );

  const leftAxisMaxValue = maxed(summedValues.map((s) => +s.total));
  const rightAxisMaxValue = maxed(lineAndCircleYValues);

  // on useSameYAxisScale we need to compare both axes max values to get biger one
  const max = useSameYAxisScale
    ? Math.max(leftAxisMaxValue, rightAxisMaxValue)
    : leftAxisMaxValue;

  // left y axis scale
  const yScale1 = scaleLinear().domain([0, max]).range([height, 0]);

  // right y axis scale
  const yScale2 = scaleLinear()
    .domain([0, rightAxisMaxValue])
    .range([height, 0]);

  const setStaticColor = (key, i) => {
    const { color } = legendItems.find((lI) => lI.name === key) ?? {};

    if (!color) {
      return colors(i);
    }

    return color;
  };

  return (
    <g data-cy="stacked-bar-two-container">
      <g data-cy="grouped-bar-value-axis">
        <YAxis
          {...props}
          yScale={yScale1}
          yTicksCount={props.yTicksCount}
          yTicksColor={props.yTicksColor}
          hideYAxisLine={props.hideYAxisLine}
          hideYAxisTicks={props.hideYAxisTicks}
          yAxisGrid={props.yAxisGrid}
          yAxisGridColor={props.yAxisGridColor}
          width={width}
          yAxisFormat={yAxisFormat || yFormat}
        />
      </g>
      {seriesA
        ? seriesA.map((s, i) =>
            s.map((v, j) =>
              isNaN(x(xKeys[j])) ? null : (
                <SpringBar
                  key={j}
                  x={x(xKeys[j])}
                  width={xInner.bandwidth() * 0.6}
                  y={yScale1(v[1])}
                  height={
                    height - yScale1(isNaN(v[1] - v[0]) ? 0 : v[1] - v[0])
                  }
                  color={legendItems ? setStaticColor(s.key, i) : colors(i)}
                  startPos={height}
                  opacity={0.4}
                  skipAnimation={seriesB[0].length > 20}
                />
              )
            )
          )
        : null}
      {seriesB
        ? seriesB.map((s, i) =>
            s.map((v, j) =>
              isNaN(x(xKeys[j])) ? null : (
                <SpringBar
                  key={j}
                  x={x(xKeys[j]) + x.bandwidth() * 0.4}
                  width={xInner.bandwidth()}
                  y={yScale1(v[1])}
                  height={
                    height - yScale1(isNaN(v[1] - v[0]) ? 0 : v[1] - v[0])
                  }
                  color={legendItems ? setStaticColor(s.key, i) : colors(i)}
                  startPos={height}
                  onMouseEnter={() =>
                    setTooltip({
                      key: s.key,
                      keyLabel: s.key,
                      data: v.data,
                      xPos: Math.floor(
                        x(xKeys[j]) +
                          x.bandwidth() * 0.45 +
                          xInner.bandwidth() * 0.6
                      ),
                      yPos: height - yScale1(v[1]),
                    })
                  }
                  onMouseLeave={() => setTooltip(null)}
                  skipAnimation={seriesB[0].length > 20}
                  useLines={seriesB[0].length > 100}
                />
              )
            )
          )
        : null}
      <SimpleXAxis
        width={width + 15}
        height={height}
        xScale={x}
        values={xKeys}
        xAxisDate={props.xAxisDate}
        xKeyFormat={props.xKeyFormat ?? term}
        bands={bands}
        xInner={xInner}
        allTicks
      />

      {!hideBarLabels && (
        <BarLabels
          skipAnimation={seriesB[0] && seriesB[0].length > 20}
          values={summedValues}
          x={x}
          y={yScale1}
          yFormat={labelFormat || yLabelFormat || yFormat}
          xInner={xInner}
        />
      )}

      <StackedLinesVisualization
        {...props}
        initialData={initialData}
        width={width}
        height={height}
        xScale={x}
        setStaticColor={setStaticColor}
        bandWidth={xInner.bandwidth()}
        yScale={useSameYAxisScale ? yScale1 : yScale2}
      />
    </g>
  );
}

StackedBarTwo.defaultProps = {
  labelFormat: ".2s",
  valueKey1: "value1",
  valueKey2: "value2",
  xKey: "month",
  labelSize: 12,
  labelWeight: "normal",
  labelColor: "black",
  yFormat: "$.3s",
  intraGroupSpacing: 1,
  valuePadding: 1,
  xTicksColor: "black",
  barWidth1: 1,
  barWidth2: 1,
  meta: { fields: [] },
};

import { passwordMinLength } from "./constants/constants";

/**
 * @returns {true|string}
 */
export function validateJson(v) {
  try {
    const parsed = JSON.parse(v);
    return (
      (typeof parsed === "object" && !Array.isArray(parsed)) ||
      "Must be a JSON object."
    );
  } catch (e) {
    return "Must be valid JSON.";
  }
}

// At least 1 number, 1 lowercase character, one uppercase character.
const passwordCharactersRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

export function validateUserPassword(password, confirmPassword) {
  if (!password) {
    return "Please enter a password.";
  }
  if (!passwordCharactersRegex.test(password)) {
    return "Password must contain at least 1 number, 1 uppercase letter, and 1 lowercase letter.";
  }
  if (password.length < passwordMinLength) {
    return `Password must be at least ${passwordMinLength} characters long.`;
  }
  if (!confirmPassword) {
    return "Please confirm your password.";
  }
  if (password !== confirmPassword) {
    return "Password fields do not match.";
  }
  return null;
}

export function validateRequired(input) {
  if (!input) {
    return VALIDATION_MESSAGES.required;
  }

  return null;
}

export function validateUrl(url) {
  if (!url) {
    return null;
  }

  let isValidUrl =
    typeof url === "string" &&
    (url.startsWith("http://") || url.startsWith("https://"));

  if (!isValidUrl) {
    return VALIDATION_MESSAGES.urlHttp;
  }

  try {
    new URL(url);
    return null;
  } catch (e) {}

  return VALIDATION_MESSAGES.url;
}

export const VALIDATION_MESSAGES = {
  required: "This field is required.",
  urlHttp: "The URL must begin with http:// or https://",
  url: "The URL is not valid.",
};

import {
  customFilterOptionsByValue,
  dateFiltersTypeDependency,
  DATE_FILTER_MODE_CUSTOM,
  DATE_FILTER_MODE_NONE,
  DATE_FILTER_MODE_RANGE_ONLY,
  rangeAndAggregationOptionsByValue,
} from "../constants/constants";

export const dateFiltersLatestVersion = 2;

export const defaultDateFilterConfig = {
  version: dateFiltersLatestVersion,
  filterMode: DATE_FILTER_MODE_NONE,
  dynamicAggregationEnabled: false,
  dynamicAggregationValues: [],
};

/**
 * Ensures that the date filters that's stored as JSON in the API are migrated
 * to the latest version.
 */
export function migrateDateFilters(existingSettings) {
  if (!existingSettings) {
    return defaultDateFilterConfig;
  }

  const version = existingSettings.version ?? 1;

  if (version >= dateFiltersLatestVersion) {
    return existingSettings;
  }

  const ret = {
    version: 2,
    fieldName: existingSettings.fieldName,
    dynamicAggregationEnabled: !!existingSettings.aggregation?.length,
    dynamicAggregationValues: existingSettings.aggregation ?? [],
    displayName: existingSettings.displayName,
    dynamicAggregationDefault: existingSettings.dynamicAggregationDefault,
  };
  if (existingSettings.hideOnPages) {
    ret.hideOnPages = existingSettings.hideOnPages;
  }

  if (customFilterOptionsByValue[existingSettings.term]) {
    ret.filterMode = DATE_FILTER_MODE_CUSTOM;
    ret.filterCustomTerm = existingSettings.term;
    ret.filterCustomValues = [
      "today",
      "yesterday",
      "wtd",
      "mtd",
      "ytd",
      "rw",
      "rm",
      "ry",
      "cr",
    ];
    ret.approved = existingSettings.approved;
    ret.fiscalQuarterStartMonth = existingSettings.fiscalQuarterStartMonth;
    ret.defaultFiscalQuarter = existingSettings.defaultFiscalQuarter;
    ret.startOfWeek = existingSettings.startOfWeek;
    ret.pageOriented = existingSettings.pageOriented;
  } else if (rangeAndAggregationOptionsByValue[existingSettings.term]) {
    ret.filterMode = DATE_FILTER_MODE_RANGE_ONLY;
    ret.rangeSettingsTerm = existingSettings.term;
    ret.rangeSettingsRange = existingSettings.range;
    ret.rangeSettingsStart = existingSettings.rangeStart;
    ret.rangeSettingsEnd = existingSettings.rangeEnd;
  } else {
    throw new Error(`Unknown term: ${existingSettings.term}`);
  }

  return ret;
}

export function convertLocalDraftToDateFilters(localDraft) {
  const ret = {
    version: 2,
    filterMode: localDraft.filterMode,
    dynamicAggregationEnabled: localDraft.aggregation.checked,
    dynamicAggregationValues: localDraft.aggregation.list ?? [],
    dynamicAggregationDefault: localDraft.aggregation.default,
    displayName: localDraft.displayName,
  };

  const setRangeSettings = () => {
    ret.rangeSettingsTerm = localDraft.term;
    ret.rangeSettingsRange = localDraft.range;
    ret.rangeSettingsStart = localDraft.rangeStart;
    ret.rangeSettingsEnd = localDraft.rangeEnd;
    ret.globalMinimumDate = null;
  };

  if (localDraft.filterMode !== DATE_FILTER_MODE_NONE) {
    ret.fieldName = localDraft.fieldName;
    ret.hideOnPages = localDraft.hideOnPages;
  }

  if (localDraft.filterMode === DATE_FILTER_MODE_CUSTOM) {
    ret.filterCustomTerm = localDraft.filterCustomTerm;
    ret.filterCustomValues = localDraft.filterCustomValues ?? [];
    ret.approved = localDraft.approved;
    ret.fiscalQuarterStartMonth = localDraft.fiscalQuarterStartMonth;
    ret.defaultFiscalQuarter = localDraft.defaultFiscalQuarter;
    ret.startOfWeek = localDraft.startOfWeek;
    ret.pageOriented = localDraft.pageOriented;

    ret.filterCustomValues?.find((fcv) =>
      dateFiltersTypeDependency.includes(fcv)
    ) && setRangeSettings();
  } else if (localDraft.filterMode === DATE_FILTER_MODE_RANGE_ONLY) {
    setRangeSettings();
  }

  if (localDraft.globalMinimumDate) {
    ret.globalMinimumDate = localDraft.globalMinimumDate;
  }

  if (localDraft.globalMaximumDate) {
    ret.globalMaximumDate = localDraft.globalMaximumDate;
  }

  return ret;
}

export function convertDateFiltersToLocalDraft(dateFilters) {
  const localDraft = {};

  localDraft.filterMode = dateFilters.filterMode;
  if (
    dateFilters.filterMode === DATE_FILTER_MODE_RANGE_ONLY ||
    dateFilters.filterCustomValues?.find((fcv) =>
      dateFiltersTypeDependency.includes(fcv)
    )
  ) {
    localDraft.term = dateFilters.rangeSettingsTerm;
  }
  if (dateFilters.filterMode === DATE_FILTER_MODE_CUSTOM) {
    localDraft.filterCustomTerm = dateFilters.filterCustomTerm;
    localDraft.approved = dateFilters.approved;
    localDraft.fiscalQuarterStartMonth = dateFilters.fiscalQuarterStartMonth;
    localDraft.defaultFiscalQuarter = dateFilters.defaultFiscalQuarter;
    localDraft.startOfWeek = dateFilters.startOfWeek;
    localDraft.pageOriented = dateFilters.pageOriented;
  }
  localDraft.filterCustomValues = dateFilters.filterCustomValues ?? [];
  localDraft.range = dateFilters.rangeSettingsRange ?? 1;
  localDraft.rangeStart = dateFilters.rangeSettingsStart;
  localDraft.rangeEnd = dateFilters.rangeSettingsEnd;
  localDraft.fieldName = dateFilters.fieldName;
  localDraft.displayName = dateFilters.displayName;
  localDraft.aggregation = {
    list: dateFilters.dynamicAggregationValues ?? [],
    checked: dateFilters.dynamicAggregationEnabled,
    default: dateFilters.dynamicAggregationDefault ?? null,
  };
  localDraft.hideOnPages = dateFilters.hideOnPages || [];
  if (dateFilters.globalMinimumDate) {
    localDraft.globalMinimumDate = dateFilters.globalMinimumDate;
  }

  if (dateFilters.globalMaximumDate) {
    localDraft.globalMaximumDate = dateFilters.globalMaximumDate;
  }

  return localDraft;
}

export const getPageOrientedData = (pageOriented, pageUuid) => {
  return (
    pageOriented.find(
      (po) => po.pageUuids?.includes(pageUuid) || po.pageUuid === pageUuid
    ) ?? {}
  );
};

import styled from "@emotion/styled";
import Table from "../../../UI/Tables/Table/Table";
import Headers from "../Headers";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import { scrollbarDe } from "../../../styles/styledSnippets";
import { omit } from "lodash-es";
import { getWatchlistExistingKey, Td } from "../utils";
import { v4 as uuid } from "uuid";

const Container = styled.div(
  ({ theme }) => `
  height: 400px;
  overflow-y: auto;
  margin: 20px 0px;
  ${scrollbarDe(theme)}
`
);

const CheckboxCell = styled.td`
  label {
    margin-top: -26px;
  }
`;

export default function UniqueTable({
  uniqueColumns,
  meta,
  data,
  watchlist,
  setWatchlist,
  getColumnTextAlign,
}) {
  const buildFilters = (row) => {
    return uniqueColumns.reduce((acc, curr) => {
      acc.push({
        type: curr,
        value: row[curr],
      });
      return acc;
    }, []);
  };

  const onWatchlistChange = (row) => {
    const key = getWatchlistExistingKey(row, watchlist);

    if (watchlist && watchlist[key]) {
      setWatchlist(omit(watchlist, [key]));
      return;
    }

    const filter = buildFilters(row);
    setWatchlist((prev) => ({ ...prev, [uuid()]: filter }));
  };

  const getCheckedFilter = (row) => {
    return !!getWatchlistExistingKey(row, watchlist);
  };

  return (
    <Container>
      <Table border>
        <Headers
          columns={["", ...uniqueColumns]}
          meta={meta}
          sticky
          getColumnTextAlign={getColumnTextAlign}
        />
        <tbody>
          {data.map((row, index) => (
            <tr key={"row-" + index}>
              <CheckboxCell>
                <Checkbox
                  height="17px"
                  width="17px"
                  top="1px"
                  left="5px"
                  onChange={() => onWatchlistChange(row, index)}
                  checked={getCheckedFilter(row)}
                />
              </CheckboxCell>
              {uniqueColumns.map((key, tdIndex) => (
                <Td key={key + tdIndex + index} align={getColumnTextAlign(key)}>
                  {row[key]}
                </Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import ActionChip from "../../../UI/UserChip/ActionChip";
import Input from "../../../UI/Form/Input/Input";
import Modal from "../../../UI/Modal/Modal";
import CreateUserOrUpdate from "../Users/CreateUserOrUpdate";

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const SeeMore = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline solid ${(props) => props.theme.primary} 1px;
    cursor: pointer;
  }
  svg {
    color: ${(props) => props.theme.primary};
    margin-left: 5px;
  }
`;

export const FullList = styled.div`
  position: absolute;
  top: 45px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f5f5f5;
  max-height: 300px;
  overflow-y: auto;
  font-size: 12px;
`;

export const ListItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #525252;
  & > span {
    line-height: 1.5;
  }
  &:hover {
    background: ${(props) => props.theme.primary};
    color: white;
  }

  & > svg {
    position: absolute;
    right: 5px;
    top: 7px;
    color: ${(props) => props.theme.notification.errorLight};
    &:hover {
      color: ${(props) => props.theme.notification.errorMain};
    }
  }
`;

const VISIBLE_USERS_QUANTITY = 5;

export default function GroupMembers(props) {
  const { members, group, state, setState, deleteFromGroup } = props;
  const [showFullList, setShowFullList] = useState(false);
  const [search, setSearch] = useState("");
  const [user, setUser] = useState(null);

  const filteredMembers = members.filter((member) =>
    member.groups.some((g) => g.uuid === group.uuid)
  );

  const ref = useRef(null);

  useOnClickOutside(ref, () => setShowFullList(false));

  const renderMember = (user, index, array) => {
    if (index === VISIBLE_USERS_QUANTITY) {
      return (
        <SeeMore
          key={user.email}
          onClick={() => setShowFullList(!showFullList)}
        >
          {array.length - VISIBLE_USERS_QUANTITY} more
          <FontAwesomeIcon
            icon={["fas", `chevron-${showFullList ? "down" : "right"}`]}
          />
        </SeeMore>
      );
    }
    if (index > VISIBLE_USERS_QUANTITY) {
      return null;
    }

    return (
      <ActionChip
        label={user.name}
        image={user.avatar && user.avatar["40x40"]}
        clickItem={(member, e) => {
          e.stopPropagation();
          setState({
            ...state,
            member: state.member ? null : member,
          });
          setShowFullList(false);
        }}
        width={18}
        height={18}
        fontSize={12}
        deleteItem={(member) => deleteFromGroup(member, group.uuid)}
        data={user}
        key={user.uuid}
        chipOnly={!state.member || state.member.uuid !== user.uuid}
        deleteText="Remove user from group"
      />
    );
  };

  return (
    <Container ref={ref}>
      {filteredMembers.map(renderMember)}
      {showFullList && (
        <FullList>
          <Input
            placeholder="Search..."
            style={{ margin: "5px" }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          {filteredMembers
            .filter((m) => m.name.toLowerCase().includes(search.toLowerCase()))
            .map((m) => (
              <ListItem
                key={m.email}
                onClick={() => setUser(m)}
                title="Click to open user information"
              >
                <span>{m.email}</span>
                <span>{m.name}</span>
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteFromGroup(m, group.uuid);
                  }}
                  title="Remove user from group"
                />
              </ListItem>
            ))}
        </FullList>
      )}

      {user && (
        <div style={{ maxHeight: "80%" }}>
          <Modal
            title={`Edit User`}
            close={() => setUser(null)}
            data-cy="edit-user-overlay"
          >
            <CreateUserOrUpdate userUuid={user.uuid} />
          </Modal>
        </div>
      )}
    </Container>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { themeGrayIf } from "../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "d3-format";
import ops, { findWhere } from "./OKRDataOps";
import Loading from "../../UI/Loading/Loading";
import { useSelector } from "react-redux";

function setSuccessColor(props) {
  return props.theme.notification[
    (props.isSuccess ? "success" : "error") + "Main"
  ];
}

const Wrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.text.primary};
  background: ${(props) => props.theme.background.secondary};
  padding: 18px 12px;
  box-sizing: border-box;
  font-size: 14px;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
`;
const SmallTitle = styled.div`
  font-weight: 300;
  font-size: 12px;
`;

const SlideContainer = styled.div`
  background: ${themeGrayIf(800, 200)};
  height: 48px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

const SlideBar = styled.div`
  background: ${setSuccessColor};
  position: absolute;
  left: 0;
  top: 0;
  height: 48px;
  width: ${(props) =>
    props.size * 6}%; // 7 is arbitrary until we link to actual targets
`;

const DetailTop = styled.div`
  border-bottom: 1px solid ${themeGrayIf(800, 200)};
  display: flex;
  padding-bottom: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  div {
    text-align: center;
  }
`;

const DetailTopTitle = styled.div`
  font-weight: 700;
`;
const DetailTopDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.text.secondary};
`;

const DetailBottom = styled.div`
  color: ${setSuccessColor};
  font-weight: 700;
  font-size: 16px;
  padding-top: 4px;
  text-align: center;
  svg {
    font-size: 10px;
    margin-left: 8px;
    position: relative;
    top: -2px;
  }
`;

export default function OKRCard(props) {
  const {
    compareKey,
    valueKey,
    title,
    subTitle,
    data,
    valueTitle,
    compareTitle,
    valueFn,
    compareFn,
    numberFormat = ",.0f",
    reverse,
  } = props.chart;

  const selectedDateFilter = useSelector(
    (state) => state.layout.dateFilters.start
  ); // single use solution, temp

  if (!data?.length) return <Loading />;

  let values;
  if (compareFn) {
    const bValue = ops(data, compareFn.type, compareKey);
    const aValue = findWhere(
      data,
      valueKey,
      valueFn.key,
      selectedDateFilter.value
    );
    values = [bValue, aValue];
  } else if (compareKey) {
    values = [
      data[0][compareKey], // b
      data[0][valueKey], // a
    ];
  } else {
    values = data.map((d) => d[valueKey]);
  }

  const a = values[1];
  const b = values[0];
  const percentChange = (a - b) / b;
  const isSuccess = reverse ? percentChange < 0 : percentChange >= 0;

  return (
    <Wrapper>
      <div style={{ width: "20%", textAlign: "center", marginRight: 12 }}>
        <Title data-cy="okr-title">{title}</Title>
        <SmallTitle data-cy="okr-subtitle">{subTitle}</SmallTitle>
      </div>
      <div style={{ width: "50%" }}>
        <SlideContainer>
          <SlideBar
            isSuccess={isSuccess}
            size={Math.abs(percentChange * 100)}
          />
        </SlideContainer>
      </div>
      <div style={{ width: "30%", marginLeft: 24 }}>
        <DetailTop>
          <div>
            <DetailTopTitle>{format(numberFormat)(values[1])}</DetailTopTitle>
            <DetailTopDescription>{valueTitle}</DetailTopDescription>
          </div>
          <div>vs</div>
          <div>
            <DetailTopTitle>{format(numberFormat)(values[0])}</DetailTopTitle>
            <DetailTopDescription>{compareTitle}</DetailTopDescription>
          </div>
        </DetailTop>
        <DetailBottom isSuccess={isSuccess}>
          {format(".2%")(percentChange)}
          <FontAwesomeIcon
            icon={["fas", isSuccess ? "arrow-up" : "arrow-down"]}
          />
        </DetailBottom>
      </div>
    </Wrapper>
  );
}

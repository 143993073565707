import React from "react";
import styled from "@emotion/styled";
import theme from "../../../styles/theme";
import PropTypes from "prop-types";

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${(props) =>
    props.checked ? props.theme.accent : props.color};
  border-radius: 50%;
  &:after {
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 4px rgb(0, 0, 0, 0.3);
    content: "";
    position: absolute;
    display: ${(props) => (props.checked ? "block" : "none")};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const Container = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover span {
    background-color: ${theme.accent};
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
  }
`;

export default function RadioButton(props) {
  return (
    <Container>
      <Checkmark color={props.color} checked={props.checked} />
      <input type="radio" {...props} data-cy={props.cy || props["data-cy"]} />
    </Container>
  );
}

RadioButton.defaultProps = {
  color: "gray",
};

RadioButton.propTypes = {
  color: PropTypes.string,
  cy: PropTypes.string,
};

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";
import { useDispatch } from "react-redux";
import ResponsiveTabs from "../ResponsiveTabs/ResponsiveTabs";
import { setActiveTab } from "../../store/actions";
import DashboardTabs from "./DashboardTabs";
import { assembleRootPages } from "../../utils/pages";

const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 6px;
  @media (max-width: 800px) {
    margin-left: 0;
  }
  a {
    text-decoration: none;
  }
`;

const ResponsiveDropdownContainer = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 920px) {
    display: block;
  }
`;

const timeResetOpacity = window.Cypress ? 1 : 0;

const Tabs = React.memo((props) => {
  const dispatch = useDispatch();
  const ani = useSpring({
    opacity: 1,
    from: { opacity: timeResetOpacity },
  });

  const { list, ...rest } = props;

  if (!list || !list.length || list.length === 1) return null;
  const combinedMainList = assembleRootPages(list.filter((v) => !v.mobile));

  const activeTab = list.find((l) => l.slug === props.current);
  const hasSubTabs = activeTab?.parent;

  const subTabs =
    hasSubTabs && list.filter((tab) => tab.parent === activeTab.parent);

  const currentTab = props.list.find(
    (l) => l.slug === props.current || l.key === props.current
  );

  const responsiveTabs = list.filter((v) => !v.mobile);

  return (
    <animated.div style={ani}>
      <Container>
        <ResponsiveDropdownContainer>
          <ResponsiveTabs options={responsiveTabs} baseUrl={props.baseUrl} />
        </ResponsiveDropdownContainer>
        <DashboardTabs
          dispatch={dispatch}
          setActiveTab={setActiveTab}
          list={combinedMainList}
          subTabs={subTabs}
          currentTab={currentTab}
          {...rest}
        />
      </Container>
    </animated.div>
  );
});

Tabs.propTypes = {
  list: PropTypes.array.isRequired,
  current: PropTypes.string,
  baseUrl: PropTypes.string,
  domain: PropTypes.string,
  subTabs: PropTypes.bool,
};

export default Tabs;

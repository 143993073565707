import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { loadRowExpandChart } from "../../../store/actions/dashboard/dashboard";
import { omit } from "lodash-es";
import Loading from "../../../UI/Loading/Loading";
import ChartFactory from "../../ChartFactory/ChartFactory";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../../UI/Form/Button/IOButton";
import { useShallowEqualSelector } from "../../../store";

const Container = styled.div`
  overflow-y: auto;
  max-height: 70vh;
  span {
    font-style: normal !important;
  }
`;

export default function ExportAllDrillDown({
  chart,
  handleExport,
  onClose,
  title,
}) {
  const dispatch = useDispatch();

  const expandedAllInOneChart = useShallowEqualSelector(
    (state) => state.dashboard.exportAllCharts
  );

  const { expandAllconfig } = chart.rowExpandVisualizationParams;
  const { ignoreParentFilters } = expandAllconfig;

  useEffect(() => {
    const withAllRows = {
      ...chart,
      ...chart.rowExpandVisualizationParams,
      ...expandAllconfig,
      overflowX: "auto",
      filters: [
        ...(ignoreParentFilters
          ? expandAllconfig.filters || []
          : chart.rowExpandVisualizationParams.filters || []),
      ],
      exportId: "all-expanded-table",
    };

    if (!chart.localRowExpandGrouping) {
      dispatch(
        loadRowExpandChart(
          null,
          omit(withAllRows, "rowExpandVisualizationParams"),
          true
        )
      );
    }
  }, [dispatch, chart, expandAllconfig, ignoreParentFilters]);

  if (!expandedAllInOneChart?.loaded) {
    return <Loading />;
  }

  return (
    <Container>
      <ChartFactory
        chart={expandedAllInOneChart}
        type={expandedAllInOneChart.type}
      />
      <ButtonGroup position="center" spacing={20} style={{ marginTop: 30 }}>
        <IOButton onClick={onClose} cancel>
          Cancel
        </IOButton>
        <IOButton
          onClick={() =>
            handleExport(
              expandedAllInOneChart.exportId,
              !expandedAllInOneChart.convertPercentsBack,
              title
            )
          }
          icon={["fa", "download"]}
        >
          Export
        </IOButton>
      </ButtonGroup>
    </Container>
  );
}

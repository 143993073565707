import { useTheme } from "emotion-theming";
import React from "react";

export default function TransparentRect({ xOffset, width, height, onClose }) {
  const theme = useTheme();
  const widthOffset = 15;
  const background = theme.type === "dark" ? "white" : "black";

  return (
    <>
      <rect
        x={xOffset}
        y={0}
        width={width + widthOffset}
        height={height}
        fill={background}
        opacity={0.2}
        style={{ borderRight: `1px solid ${theme.primary}` }}
      />

      <>
        <line
          strokeWidth={3}
          stroke={theme.primary}
          transform={"rotate(180)"}
          x1={-width - xOffset - widthOffset}
          y1={0}
          x2={-width - xOffset - widthOffset}
          y2={-height}
        />

        {/* left arrow */}
        <path
          d={`m ${width + xOffset} ${
            height / 2
          } 8 -5 0 2.5 8 0 0 4 -8 0 0 3  z`}
          fill={theme.primary}
          cursor="pointer"
          onClick={onClose}
        />
      </>
    </>
  );
}

import { useEffect, useRef, useState } from "react";
import IOButton from "../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import { Link, useLocation } from "react-router-dom";
import useUserAccess from "../../hooks/useUserAccess";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  margin-right: 10px;
`;

const Menu = styled.div(
  ({ theme }) => `
    width: 130px;
    height: 60px;
    position: absolute;
    font-size: 12px;
    background: ${lightenDarkenColor(theme.background.primary, 10)};
    top: 35px;
    padding: 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 8px;
      color: ${theme.text.secondary};
      &:hover {
        cursor: pointer;
        color: ${theme.text.primary};
      }
    }
`
);

export default function EditorButton() {
  const [open, setOpen] = useState(false);
  const icon = `chevron-${open ? "up" : "down"}`;

  const location = useLocation();
  const access = useUserAccess();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  if (!location.pathname.includes("/data-settings") || !access.dataSources) {
    return null;
  }

  return (
    <Container ref={ref}>
      <IOButton
        expandable
        textColorCircle
        smallPadding
        expandableIcon={icon}
        onClick={() => setOpen(!open)}
        cy="add-new-data-query-source"
      >
        Add new
      </IOButton>
      {open && (
        <Menu onClick={() => setOpen(false)}>
          <Link to="/admin/data-settings/mapping/data-sources/create">
            <span>New Data Source</span>
          </Link>
          <Link to="/admin/data-settings/mapping/queries/create">
            <span>New Data Query</span>
          </Link>
          <Link to="/admin/data-settings/mapping/queries/create-parameterized">
            <span data-cy="option-param-query">Parameterized Query</span>
          </Link>
        </Menu>
      )}
    </Container>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertIcons, alertIconsLight } from "../../utils/constants/constants";

const Container = styled.div(
  ({ theme, status, iconPosition, outline, stacked }) => `
    display: flex;
    flex-direction: ${iconPosition === "top" ? "column" : "row"};
    background: ${theme.notification[status + "Main"]};
    padding: 10px 10px;
    align-items: center;
    margin: 5px 0px;
    border-radius: 4px;
    // color: ${
      theme.notification[status + "Dark"]
    }; //@todo need to confirm colors
    ${
      outline
        ? `
      background: none;
      color: ${theme.text.primary};
      border: 1px solid ${theme.notification[status + "Main"]};
      border-radius: 4px;
      padding: 0 12px;
      font-weight: 300;
      align-items: stretch;
      padding: 0;
      max-width: 680px;
div:nth-of-type(2) {
  padding: 10px 0;
}
    `
        : null
    }
    ${
      stacked
        ? `
    flex-direction: column;
    div:nth-of-type(2) {
    padding: 2px 0;
}
    
    `
        : null
    }
`
);

const IconBox = styled.div`
  margin: 0 10px;
  color: white;
  font-size: 20px;
  box-sizing: border-box;
  ${(props) =>
    props.outline
      ? `
      background: ${props.theme.notification[props.status + "Main"]};
      width: 76px;
     padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 18px 0 0;
      font-size: 32px;
      padding: 20px;
    `
      : null}
  ${(props) =>
    props.stacked
      ? `
  width: 100%;
font-size: 24px;
padding: 8px 12px;
  `
      : null}
`;

const ButtonGroup = styled.div`
  margin-top: 18px;
  button {
    margin-right: 8px;
  }
`;

export default function AlertMessage({
  status,
  message,
  iconPosition,
  cy,
  children,
  outline,
  stacked,
  actions,
}) {
  const icons = outline ? alertIconsLight : alertIcons;
  return (
    <Container
      outline={outline}
      stacked={stacked}
      status={status}
      iconPosition={iconPosition}
      data-cy={cy}
    >
      <IconBox status={status} outline={outline} stacked={stacked}>
        <FontAwesomeIcon icon={icons[status]} />
      </IconBox>
      <div>
        <div style={{ padding: stacked ? 12 : 0 }}>
          <span style={{ marginRight: 10 }}>{message || children}</span>
        </div>
        {actions ? <ButtonGroup>{actions}</ButtonGroup> : null}
      </div>
    </Container>
  );
}

AlertMessage.defaultProps = {
  status: "info",
  message: "",
  iconPosition: "left",
};

AlertMessage.propTypes = {
  status: PropTypes.string,
  message: PropTypes.node,
  iconPosition: PropTypes.oneOf(["top", "left"]),
  width: PropTypes.string,
  outline: PropTypes.bool,
  stacked: PropTypes.bool,
  cy: PropTypes.string,
};

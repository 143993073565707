import React from "react";
import DataMapTile from "./DataMapTile";
import QueryTile from "./QueryTile";

export default function DataMapConnections(props) {
  const { flows, type } = props;

  return flows.map((db, i) => (
    <div key={i} style={{ display: "flex" }}>
      <DataMapTile
        key={db.uuid || i}
        cy={`tile-${type}-connection`}
        name={db.name}
      >
        {db.name}
      </DataMapTile>
      <div>
        <QueryTile queries={db.datasource?.queries} />
      </div>
    </div>
  ));
}

import React, { useEffect, useState } from "react";
import Input from "../../../../UI/Form/Input/Input";
import IOButton from "../../../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../../UI/Loading/Loading";

const Container = styled.div`
  label {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: ${(props) => (props.saving ? 0.4 : 1)};
  transition: 1s linear opacity;
  position: relative;
  svg {
    margin-right: 6px;
  }
  button {
    font-size: 14px;
  }
  & > div {
    position: absolute;
    top: -20px;
    left: 160px;
  }
`;

export default function SaveExplorerForm(props) {
  const { handleSave, name = "", settings, loading } = props;
  const [draftName, setDraftName] = useState(name);

  useEffect(() => {
    setDraftName(name);
  }, [name, setDraftName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(draftName);
  };

  const handleUpdate = () => {
    handleSave(draftName, settings.id);
  };

  return (
    <Container>
      <form data-cy="explorer-save-form" onSubmit={handleSubmit}>
        <div style={{ marginBottom: 12 }}>
          <Input
            value={draftName}
            onChange={(e) => setDraftName(e.target.value)}
            fit
            cy="explorer-settings-name"
            label="Name"
          />
        </div>
        <ButtonContainer saving={loading}>
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : null}
          {name && settings ? (
            <IOButton
              type="button"
              style={{ marginRight: 10 }}
              data-cy="explorer-settings-update"
              onClick={handleUpdate}
              smallPadding
            >
              <FontAwesomeIcon icon={["fal", "plus-square"]} />
              Update
            </IOButton>
          ) : null}
          <IOButton
            disabled={!draftName}
            fit
            cy="explorer-settings-save"
            success
            style={{ maxWidth: 180 }}
            smallPadding
          >
            <FontAwesomeIcon icon={["far", "save"]} />
            {settings?.id ? "Save As..." : "Save"}
          </IOButton>
        </ButtonContainer>
      </form>
    </Container>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import RemoveButton from "../../UI/RemoveButton/RemoveButton";

const styles = {
  success: { background: "successMain", icon: "🚀" },
  danger: { background: "errorMain", icon: "🤬" },
  warning: { background: "warningMain", icon: "⚠️" },
  info: { background: "infoMain", icon: "😇" },
};

const ToastContainer = styled.div`
  width: 380px;
  line-height: 160%;
  padding: 12px 18px 12px 64px;
  background: ${(props) => props.theme.background.secondary};
  border-radius: 2px;
  margin-bottom: 18px;
  box-shadow: ${(props) => props.theme.general.shadow2dp};
  font-size: 14px;
  border: 0.02rem solid ${(props) => props.theme.divider};
  border-left: 6px solid
    ${({ theme, type }) => theme.notification[styles[type].background]};
  //background: ${(props) =>
    props.theme.notification[styles[props.type].background]};
  color: ${(props) => props.theme.text.secondary};
  position: relative;
  box-sizing: border-box;
  //border: 1px solid
`;

const Icon = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  font-size: 28px;
  border: 0.05rem solid
    ${(props) => props.theme.notification[styles[props.type].background]};
  // background: ${(props) =>
    props.theme.notification[styles[props.type].background]};
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  font-weight: 700;
  color: ${(props) => props.theme.notification[styles[props.type].background]};
`;

const DismissContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  opacity: 0.5;
`;

export default function Toast(props) {
  const { type, title, message, handleDismiss } = props;
  return (
    <ToastContainer type={type}>
      <Icon type={type}>{styles[type].icon}</Icon>
      <Title type={type}>{title}</Title>
      <div style={{ whiteSpace: "pre-wrap" }}>{message}</div>
      <DismissContainer>
        <RemoveButton light bw onClick={handleDismiss} />
      </DismissContainer>
    </ToastContainer>
  );
}

Toast.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  handleDismiss: PropTypes.func.isRequired,
};

import React from "react";
import BaseChart from "./BaseChart";
import ResizeDetector from "react-resize-detector";

export default function ResizeBaseChart(props) {
  return (
    <ResizeDetector
      handleWidth
      handleHeight
      refreshMode="throttle"
      refreshRate={200}
    >
      <BaseChart
        margin={props.margin}
        ignoreSave={props.ignoreSave}
        tooltipConfig={props.tooltipConfig}
        metaFields={props.metaFields}
        visualizationId={props.visualizationId}
        canvasOff={props.canvasOff}
        hideTooltip={props.hideTooltip}
        offsetHeight={props.offsetHeight}
        offsetWidth={props.offsetWidth}
      >
        {props.children}
      </BaseChart>
    </ResizeDetector>
  );
}

import {
  bulkSyncWithOriginTemplate,
  getReportedQueries,
} from "../../../store/actions/automation";
import SyncTableConfig from "../../Automation/ScheduledEmails/SyncTableConfig";
import {
  buildContentOptions,
  checkObsoleteConfig,
  getConvertedReport,
  getGeneralPayloadForReports,
} from "../../Automation/components/automationHelper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function AutomationSyncWarning({
  chart,
  block,
  visualizationUuid,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getReportedQueries());
  }, [dispatch]);

  const { reportedQueries, syncLoading } = useSelector(
    (state) => state.automation
  );

  const chartReport = {
    ...chart,
    immutableFilters: (chart.filters ?? []).filter(
      (filter) => !filter.removeOnSave
    ),
  };

  const reports = (reportedQueries ?? []).filter(
    (report) => report.visualizationUuid === visualizationUuid
  );

  const obsoleteReports = reports.filter((report) =>
    checkObsoleteConfig(chartReport, report)
  );

  const report = obsoleteReports[0];

  if (!report) {
    return null;
  }

  const convertedReports = obsoleteReports.map((report) =>
    getConvertedReport(report)
  );

  const convertedReport = getConvertedReport(report);
  const contentOptions = buildContentOptions([block]);

  const content = contentOptions.find(
    (option) => option.value === convertedReport?.visualizationUuid
  );

  function saveQueryReport() {
    const payloads = getGeneralPayloadForReports(
      convertedReports,
      chartReport,
      content
    );
    dispatch(bulkSyncWithOriginTemplate(payloads, history));
  }

  return (
    <SyncTableConfig
      chart={chartReport}
      report={convertedReport}
      content={content}
      setReport={saveQueryReport}
      loading={syncLoading}
    />
  );
}

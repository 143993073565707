import React, { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";

export default function ActiveGrid(props) {
  const {
    rowData,
    columns,
    handleChange,
    height = 500,
    space,
    activeTableDataLoading,
    joinedMode,
  } = props;
  const gridRef = useRef(null);
  const includePagination = rowData?.length > 20;

  // override no rows message
  const noRowsOverlayComponentParams = useMemo(() => {
    const message = columns.length
      ? "No rows to show"
      : "No visible columns to show";

    return {
      noRowsMessageFunc: () => message,
    };
  }, [columns.length]);

  function getRowNodeId(params) {
    return params.data[joinedMode?.columnToJoinName] ?? params.data.uuid;
  }

  const paginationProps = {
    ...(includePagination && {
      pagination: true,
      paginationPageSizeSelector: [20, 50, 100],
      paginationPageSize: 20,
      paginationAutoPageSize: false,
    }),
  };

  return (
    <div
      className="ag-theme-quartz"
      style={{ height, marginBottom: space ? 24 : 0 }}
      data-cy="active-grid-block"
    >
      {/* The AG Grid component */}
      <AgGridReact
        ref={gridRef}
        rowData={columns.length ? rowData : activeTableDataLoading ? null : []}
        columnDefs={columns}
        onCellValueChanged={handleChange}
        getRowId={getRowNodeId}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        loading={activeTableDataLoading}
        columnTypes={{
          date: {},
          datetime: {},
          image: {},
          select: {},
          text: {},
          string: {},
          currency: {},
          boolean: {},
          decimal: {},
          bigint: {},
          integer: {},
          float: {},
          number: {},
        }}
        {...paginationProps}
      />
    </div>
  );
}

// showing custom message if there is no any visible column
function CustomNoRowsOverlay(props) {
  return <div>{props.noRowsMessageFunc()}</div>;
}

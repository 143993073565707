import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const Container = styled.div`
  position: absolute;
  bottom: 28px;
  left: -4px;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  path {
    fill: ${(props) => props.theme.divider};
  }
`;

export default function MarkerToggleSwitch(props) {
  return (
    <Container onClick={props.onClick}>
      <FontAwesomeIcon
        size="xs"
        icon={[props.active ? "fas" : "far", "circle"]}
      />
    </Container>
  );
}

MarkerToggleSwitch.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default function (chart) {
  const { subTitles, fixedRows, rowGroupKey, rollup } = chart;

  if (fixedRows) {
    if (!rollup && !subTitles && !rowGroupKey) {
      throw new Error(
        "Subtitles, Rollup or rowGroupKey are required for fixed rows format"
      );
    }
  }

  if (
    subTitles &&
    subTitles.length &&
    subTitles.find((st) => !Array.isArray(st))
  ) {
    throw new Error("Subtitles must be defined as a nested array");
  }
}

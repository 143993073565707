import React from "react";
import styled from "@emotion/styled";
import { hexToRgba } from "../../../../styles/colorConvertor";

const Container = styled.div`
  height: 3px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: ${(props) =>
    hexToRgba(props.theme.notification.warningMain, 0.2)};
  z-index: 1;

  div {
    position: absolute;
    width: 100%;
    height: 3px;
    background: ${(props) => props.theme.notification.warningMain};
    animation: barSlide 3s infinite;
    @keyframes barSlide {
      0% {
        left: -100%;
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        left: 100%;
        opacity: 0.2;
      }
    }
  }
`;

export default function TableLoader(props) {
  return (
    <Container data-cy="table-loader">
      <div />
    </Container>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Tipper from "../Tipper/Tipper";

const Container = styled.div`
  position: relative;
  margin-right: 10px;
  font-size: 18px;
  cursor: pointer;
  width: 20px;
  color: ${(props) => props.theme.text.secondary};
`;

export default function ShowHide(props) {
  const { isMuted, onClick, cy = "toggle-hide", style } = props;
  const icon = isMuted ? "eye-slash" : "eye";

  return (
    <Tipper label={isMuted ? "Hide" : "Show"}>
      <Container onClick={onClick} data-cy={cy} style={style}>
        <FontAwesomeIcon icon={["fal", icon]} />
      </Container>
    </Tipper>
  );
}

ShowHide.propTypes = {
  isMuted: PropTypes.bool,
  onClick: PropTypes.func,
  cy: PropTypes.string,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExplorerLeftTray from "../DataExplorerUI/ExplorerLeftTray";
import {
  flexCenter,
  scrollbarConfig,
  themeGray,
} from "../../../../styles/styledSnippets";
import Flex from "../../../../UI/Flex/Flex";
import SimpleTooltip from "../../../../UI/SimpleTooltip/SimpleTooltip";
import Input from "../../../../UI/Form/Input/Input";
import ColumnFiltering from "./ColumnFiltering";

const Column = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 12px;

  svg {
    opacity: 0.8;
    margin-right: 12px;
    font-size: 18px;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const Instructions = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
  ${(props) =>
    props.hr
      ? `
          border-top: 1px solid ${props.theme.gray.gray800};
          padding-top: 60px;
          `
      : ""}
`;

const ListContainer = styled.div`
  height: calc(100vh - 250px);
  overflow: auto;
  ${scrollbarConfig({ size: ".4em" })};
`;

const Blank = styled.div`
  cursor: pointer;
  margin-bottom: 18px;
  color: ${(props) => props.theme.notification.infoMain};
  ${flexCenter}

  svg {
    font-size: 36px;
    margin-right: 8px;
  }
`;

const SearchContainer = styled.div`
  max-width: 200px;
  position: absolute;
  width: 180px;
  top: 18px;
  right: 12px;
  svg {
    color: ${themeGray(400)};
  }
`;

export default function ColumnSelection(props) {
  const { columns, close, filterApi, clearAll, reset } = props;
  const [searchString, setSearchString] = useState("");

  const visibleColumns = filterApi?.overrides.map((o) => o.name) || [];

  function getNestedTypes() {
    return (filterApi?.types ?? []).flatMap((t) => t.types).filter(Boolean);
  }

  function getTypesCondition(column) {
    const types = getNestedTypes();
    return types.length ? types.includes(column.mapping?.type) : true;
  }

  function getBookmarked() {
    return !!(filterApi?.types ?? []).find((t) => t.bookmark);
  }

  function getBookmarkedCondition(column) {
    return getBookmarked() ? column.bookmark : true;
  }

  function getLowerCaseCNames(column) {
    const name = column.name.toLowerCase();
    const label = column.mapping?.displayName;

    return {
      name,
      displayName: label ? label.toLowerCase() : name,
    };
  }

  const displayedColumns = columns.filter((c) => {
    // check columns with same types
    const typeCondition = getTypesCondition(c);
    // check columns with bookmark: true prop
    const bookmarkCondition = getBookmarkedCondition(c);

    const { name, displayName } = getLowerCaseCNames(c);
    const lowerCaseSearchString = searchString.toLowerCase();

    // check columns for name/displayName including search value
    const searchCondition =
      name.includes(lowerCaseSearchString) ||
      displayName.includes(lowerCaseSearchString);

    return searchCondition && typeCondition && bookmarkCondition;
  });

  const handleChange = (col) => {
    const position = overridePosition();

    if (position === -1) {
      filterApi.addOverride(col.name);
    } else {
      filterApi.removeOverride(position);
    }

    function overridePosition() {
      return visibleColumns.findIndex((v) => v === col.name);
    }
  };

  function handleSetSearchString(e) {
    setSearchString(e.target.value);
  }

  return (
    <ExplorerLeftTray
      cy="column-visibility-list"
      title="Show / Hide"
      handleClose={close}
    >
      <Flex>
        <SimpleTooltip
          label={
            <span>
              <span style={{ lineHeight: "2em" }}>
                <b>Start fresh!</b>
              </span>{" "}
              <br />
              Build your explore your data <br />
              from the ground up.
            </span>
          }
        >
          <Blank onClick={clearAll} style={{ marginRight: 30 }}>
            <FontAwesomeIcon icon={["fal", "cube"]} />
            <span>Clear columns</span>
          </Blank>
        </SimpleTooltip>
        <SimpleTooltip
          label={
            <span>
              <span style={{ lineHeight: "2em" }}>
                <b>See it all</b>
              </span>{" "}
              <br />
              Start with your complete
              <br />
              data source and refine
            </span>
          }
        >
          <Blank onClick={reset}>
            <FontAwesomeIcon icon={["fal", "cubes"]} />
            Full query
          </Blank>
        </SimpleTooltip>
      </Flex>
      <div style={{ position: "relative", width: "100%" }}>
        <ColumnFiltering filterApi={filterApi} />
        {columns?.length > 8 ? (
          <SearchContainer>
            <Input
              fit
              search
              value={searchString}
              onChange={handleSetSearchString}
              style={{
                background: "#333",
                color: "white",
                borderColor: "#333",
              }}
            />
          </SearchContainer>
        ) : null}
        <Instructions hr>Check columns to show</Instructions>
      </div>
      <ListContainer>
        {displayedColumns.map((column) => (
          <Column
            data-cy="selection-column-item"
            key={column.name}
            onClick={() => handleChange(column)}
          >
            <FontAwesomeIcon
              icon={
                visibleColumns.find((col) => col === column.name)
                  ? ["fas", "eye"]
                  : ["fal", "eye-slash"]
              }
            />
            <span>{column.mapping.displayName}</span>
          </Column>
        ))}
      </ListContainer>
    </ExplorerLeftTray>
  );
}

ColumnSelection.propTypes = {
  columns: PropTypes.array,
};

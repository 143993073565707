import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep, differenceBy } from "lodash-es";
import HeaderLabels from "./HeaderLabels";
import LeftRightAreaContext from "./LeftRightAreaContext";
import LeftAreaBlock from "./LeftAreaBlock";
import RightAreaBlock from "./RightAreaBlock";
import ThirdAreaBlock from "./ThirdAreaBlock";
import OuterContainer from "./OuterContainer";

const LeftRightArea = (props) => {
  const {
    data,
    leftLabel,
    rightLabel,
    tripleLabel,
    getUniqueRawValuesCallback,
    uniqueValuesTotalCount,
  } = props;
  const context = useContext(LeftRightAreaContext);
  const [scrollTop, setScrollTop] = useState(null);
  if (!context)
    throw new Error(
      "LeftRightArea component must be wrapped in LeftRightAreaContext"
    );

  const {
    rightArray,
    setRightArray,
    thirdValues,
    handleSetThirdValues,
    removeThirdValue,
    cleanupRemoved,
    folders,
    setFolders,
    active,
    setActive,
    tripleMode,
    folderMode,
    overrideMode,
    overrides,
    setOverrides,
    colorMode,
    colors,
    setColors,
  } = context;

  const allFolderChildren = folders?.length
    ? folders.reduce((acc, cur) => [...acc, ...cur.children], [])
    : [];

  const differenced = folderMode ? allFolderChildren : rightArray;
  const leftData = differenceBy(data, differenced, "id");

  function handleLeftToRight(item) {
    if (splitMode()) {
      return setRightArray(item);
    }
    if (tripleModeSetToCenter()) {
      setRightArray(item);
      return setActive(item);
    }

    if (colorMode) {
      return setActive(item);
    }

    if (tripleModeSetToRight()) {
      return handleSetThirdValues(active.id, item);
    }

    if (overrideMode) {
      const overrideExists = overrides.find((o) => o.original.id === item.id);
      if (overrideExists) return;
      const overrideItem = { ...item };
      const newItem = { original: item, override: overrideItem };
      return setOverrides([...overrides, newItem]);
    }

    if (activeFolder()) {
      const nextFolders = cloneDeep(folders);
      nextFolders[active].children.push(item);
      setFolders(nextFolders);
    }
    function splitMode() {
      return (
        !active && !folderMode && !tripleMode && !overrideMode && !colorMode
      );
    }
    function tripleModeSetToCenter() {
      return tripleMode && !active;
    }
    function tripleModeSetToRight() {
      return tripleMode && active;
    }
    function activeFolder() {
      return folderMode && active !== null;
    }
  }

  function handleRemove() {
    cleanupRemoved(active);
    setActive(null);
  }

  function handleAddFolder() {
    if (folderMode) {
      const temp = { label: `Name ${folders.length + 1}`, children: [] };
      setActive(folders.length);
      const nextFolders = [...folders, temp];
      setFolders(nextFolders);
    } else {
      setActive(null);
    }
  }

  function handleScroll(e) {
    if (!overrideMode) return;
    setScrollTop(e.target.scrollTop);
  }

  return (
    <>
      <HeaderLabels
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        tripleMode={tripleMode}
        tripleLabel={tripleLabel}
      />

      <OuterContainer hasLabels={leftLabel || rightLabel}>
        <LeftAreaBlock
          thirdValues={thirdValues}
          folders={folders}
          folderMode={folderMode}
          leftData={leftData}
          handleLeftToRight={handleLeftToRight}
          handleScroll={handleScroll}
          colorMode={colorMode}
          colors={colors}
          getUniqueRawValuesCallback={getUniqueRawValuesCallback}
          uniqueValuesTotalCount={uniqueValuesTotalCount}
        />

        <RightAreaBlock
          rightArray={rightArray}
          setRightArray={setRightArray}
          tripleMode={tripleMode}
          active={active}
          setActive={setActive}
          folders={folders}
          setFolders={setFolders}
          handleRemove={handleRemove}
          handleAddFolder={handleAddFolder}
          folderMode={folderMode}
          colorMode={colorMode}
          colors={colors}
          setColors={setColors}
          overrideMode={overrideMode}
          overrides={overrides}
          addOverride={handleLeftToRight}
          setOverrides={setOverrides}
          leftData={leftData}
          scrollTop={scrollTop}
        />

        <ThirdAreaBlock
          active={active}
          thirdValues={thirdValues}
          removeThirdValue={removeThirdValue}
          tripleMode={tripleMode}
        />
      </OuterContainer>
    </>
  );
};

LeftRightArea.defaultProps = {
  data: [],
};

LeftRightArea.propTypes = {
  data: PropTypes.array,
  rightArray: PropTypes.array,
  setRightArray: PropTypes.func,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  tripleMode: PropTypes.bool,
};

export default LeftRightArea;

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";

const resizePadding = 24;

function topPos(props) {
  return props.dimensions.top - 22;
}

const ColumnOptionsContainer = styled.div`
  position: absolute;
  top: ${topPos}px;
  left: ${(props) => props.dimensions.left - 18 + resizePadding}px;
  overflow: visible;
  z-index: 100;
  min-width: ${(props) => props.dimensions.width}px;
  display: flex;
  justify-content: flex-end;
`;

export default function HeaderTooltip(props) {
  const {
    actions,
    showOptions,
    columns,
    handleLeave,
    handleEnter,
    handleForceHideOptions,
  } = props;

  const styles = useSpring({
    from: { opacity: 0, position: "relative", top: 0 },
    opacity: 1,
    top: 0,
  });

  useEffect(() => {
    window.addEventListener("scroll", handleForceHideOptions);
    return () => window.removeEventListener("scroll", handleForceHideOptions);
  });

  return (
    <ColumnOptionsContainer
      onMouseLeave={handleLeave}
      onMouseEnter={handleEnter}
      dimensions={showOptions?.dimensions}
    >
      <animated.div style={styles}>
        {React.cloneElement(actions, {
          i: showOptions,
          column: columns[showOptions.position],
        })}
      </animated.div>
    </ColumnOptionsContainer>
  );
}

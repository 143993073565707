import { useEffect, useState } from "react";
import isEqual from "react-fast-compare";

export default ({ views = [] }) => {
  let initialDefaultView = views?.length ? views[0].uuid : null;

  const [previousViews, setPreviousViews] = useState(initialDefaultView);
  const [selectedView, setSelectedView] = useState(null);

  useEffect(() => {
    if (!isEqual(previousViews, views)) {
      if (selectedView === "new") {
        const newCreatedView = views.find(
          (v) => !previousViews.find((pv) => pv.uuid === v.uuid)
        );
        setSelectedView(newCreatedView?.uuid);
      } else {
        const view =
          (views ?? []).find((view) => view.displaySettings?.isDefaultView) ??
          views[0];

        setSelectedView(selectedView || view?.uuid || null);
      }
      setPreviousViews(views);
    }
  }, [views, previousViews, selectedView]);

  return { selectedView, setSelectedView };
};

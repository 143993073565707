import {
  checkObsoleteConfig,
  extendFilters,
  setAttachmentFromChart,
} from "../components/automationHelper";
import styled from "@emotion/styled";
import IOButton from "../../../UI/Form/Button/IOButton";
import AlertMessage from "../../../UI/Alerts/AlertMessage";
import Flex from "../../../UI/Flex/Flex";
import { useState } from "react";
import convertFiltersToOperatorsMode from "../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";

const Container = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  & > span {
    font-size: 12px;
    font-weight: 500;
  }
`;

export default function SyncTableConfig({
  chart,
  report,
  setReport,
  content,
  loading,
  setRefreshFilters,
}) {
  const isObsolete = checkObsoleteConfig(chart, report);

  const [hide, setHide] = useState(false);
  if (!isObsolete || hide) {
    return null;
  }

  function syncConfigs() {
    const extendedFilters = extendFilters(
      chart.immutableFilters,
      report.filters
    );

    const attachment = setAttachmentFromChart(
      content,
      convertFiltersToOperatorsMode(extendedFilters),
      chart
    );

    setReport({
      ...report,
      attachments: [attachment],
      filters: extendedFilters,
    });
    setRefreshFilters && setRefreshFilters(true);
  }

  const message =
    "Notice: Your page template has been updated and no longer matches this email template.";

  const actions = (
    <Flex>
      <IOButton cancel smallPadding onClick={() => setHide(true)}>
        Cancel
      </IOButton>
      <IOButton
        info
        smallPadding
        onClick={syncConfigs}
        processing={loading}
        type="button"
      >
        Refresh email template
      </IOButton>
    </Flex>
  );

  return (
    <Container>
      <AlertMessage
        outline
        status="warning"
        message={message}
        actions={actions}
      />
    </Container>
  );
}

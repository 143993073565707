import React, { Fragment } from "react";
import { array, object } from "prop-types";
import { keyBy } from "lodash-es";
import {
  // INSIGHT_FIELD_TYPE_DATE,
  INSIGHT_FIELD_TYPE_GROUP,
  INSIGHT_FIELD_TYPE_VALUE,
} from "../SetFieldTypes";
import styled from "@emotion/styled";
import { css } from "../../../../utils/ide";
import Loading from "../../../../UI/Loading/Loading";
import ChangeCell from "./ChangeCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useValueToggle from "../../../../hooks/useValueToggle";
import InsightTableDetailRow from "./InsightTableDetailRow";

const Container = styled.div(
  ({ theme }) => css`
    color: ${theme.text.primary};

    td,
    th {
      padding: 5px 18px;
    }
  `
);

export default function InsightResultTable({
  results,
  fields,
  fieldTypes,
  loading,
}) {
  const [expandedRowIndex, setExpandedRowIndex] = useValueToggle(null);
  if (loading) return <Loading />;
  if (!results) return null;

  const fieldNames = keyBy(fields, "name");
  const groupKeys = fieldTypes[INSIGHT_FIELD_TYPE_GROUP];
  const valueKey = fieldTypes[INSIGHT_FIELD_TYPE_VALUE];

  return (
    <Container>
      <h3>
        Testing for values of <b>{valueKey}</b>
      </h3>
      <div>These popped out...</div>
      <div style={{ height: 20 }} />
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>To Date</th>
            {groupKeys.map((groupKey) => (
              <th key={groupKey}>
                {fieldNames[groupKey].mapping?.displayName ??
                  fieldNames[groupKey]}
              </th>
            ))}
            <th>Starting</th>
            <th>Ending</th>
            <th>Impact</th>
            <th>..</th>
          </tr>
        </thead>
        <tbody>
          {results.map((resultRow, index) => (
            <Fragment key={index}>
              <tr>
                <td>{resultRow["dateStart"]}</td>
                <td>{resultRow["dateEnd"]}</td>

                {groupKeys.map((groupKey) => (
                  <td key={groupKey}>{resultRow[groupKey]}</td>
                ))}
                <td style={{ textAlign: "right" }}>
                  {resultRow["start_" + valueKey]}
                </td>
                <td style={{ textAlign: "right" }}>
                  {resultRow["end_" + valueKey]}
                </td>

                <ChangeCell resultRow={resultRow} />
                <td
                  onClick={() => setExpandedRowIndex(index)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={["fal", "plus-circle"]} />
                </td>
              </tr>

              {expandedRowIndex === index ? (
                <tr>
                  <td colSpan={groupKeys.length + 6}>
                    <InsightTableDetailRow />
                  </td>
                </tr>
              ) : null}
            </Fragment>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

InsightResultTable.propTypes = {
  results: array,
  fields: array,
  fieldTypes: object,
};

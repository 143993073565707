import { allKeys, summed } from "../../utils/func";

export default (
  data,
  rowKey,
  totalsAverages = [],
  totalLabel = "Total",
  includeBreakdown,
  totalColumnsStatic,
  excludeRowFromTotalCalculation = [],
  totalsOverride,
  noCalcKeys = [],
  distinctTotalsCounts = []
) => {
  const dataKeys = allKeys(data);

  function excludeFromTotals(item) {
    const notExist = !excludeRowFromTotalCalculation.find((ex) =>
      item[ex.column]?.includes(ex.value)
    );
    return notExist;
  }

  function setOverrideTotals(row, key) {
    if (!totalsOverride || !row) {
      return +row[key];
    }

    const { overrideColumn, overrideValue, conditionColumn, conditionValue } =
      totalsOverride;

    if (key === overrideColumn && row[conditionColumn] === conditionValue) {
      return overrideValue;
    }

    return +row[key];
  }

  const allTotals = data
    .filter(excludeFromTotals)
    .reduce((acc, curr, _, array) => {
      dataKeys.forEach((key) => {
        if (distinctTotalsCounts.includes(key)) {
          acc[key] = getDistinctCount(key, array);
        } else if (totalsAverages.includes(key)) {
          acc[key] = getAvg(array.map((x) => +x[key]));
        } else {
          if (totalColumnsStatic?.includes(key)) {
            acc[key] = +curr[key];
          } else {
            acc[key] = (acc[key] || 0) + setOverrideTotals(curr, key);
          }
        }
      });
      return acc;
    }, {});

  const validatedTotals = dataKeys.reduce((acc, curr) => {
    const isValidTotal = getIsValidTotal(noCalcKeys, acc, curr);
    return { ...acc, [curr]: isValidTotal ? acc[curr] : null };
  }, allTotals);

  if (includeBreakdown) {
    const breakdowns = dataKeys.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          values: data.map((d) => {
            return { key: d[rowKey], value: d[curr] };
          }),
          type: "pie",
        },
      };
    }, {});
    validatedTotals.breakdown = breakdowns;
  }

  return { ...validatedTotals, [rowKey]: totalLabel };
};

function getDistinctCount(key = "", array = []) {
  return [...new Set(array.map((item) => item[key]))].length;
}

function getAvg(arr) {
  return (arr.reduce((a, b) => a + (b ?? 0), 0) / arr.length).toFixed(4);
}

export const getSubTotalRow = (arr = [], getKey, setKey, groupingKey) => {
  const total = getTotalByKey(arr, groupingKey, getKey, setKey);
  const withTotal = mergeWithTotal(arr, groupingKey, setKey);

  return [...withTotal, total].filter((d) => d);
};

export const clearIfSameKeys = (arr, getKey, setKey, groupingKey) => {
  return arr.map((group) => {
    if (getKey === setKey && group[groupingKey] !== "Total") {
      return { ...group };
    }
    return group;
  });
};

function mergeWithTotal(arr, groupingKey) {
  return arr.map((item, index) => ({
    ...item,
    ...(item[groupingKey] && index !== 0 && { [groupingKey]: " " }),
  }));
}

function getTotalByKey(arr, groupingKey, getKey, setKey) {
  const keys = allKeys(arr);
  const sum = arr.map((element) => element[getKey]).reduce((a, b) => a + b, 0);

  return keys.reduce((acc, curr) => {
    acc[curr] = null;
    acc[groupingKey] = "Total";
    acc[setKey] = sum;
    return acc;
  }, {});
}

function getIsValidTotal(noCalcKeys, acc, curr) {
  return (
    !noCalcKeys.find((v) => v === curr) &&
    !isNaN(acc[curr]) &&
    acc[curr] !== null
  );
}

// horizontal totals calculation
export function withCalculatedHorizontalTotals({
  headers,
  rows,
  columnKeys,
  horizontalTotals,
  meta,
}) {
  if (horizontalTotals?.columns && meta) {
    const { columns, header = "Total", type } = horizontalTotals;

    // There are two distinct types of subtitles:
    // 1. Regular subtitles with or without grouping: [["1"...], ["2"...], ["3"...], ...]
    // 2. Single-line subtitles: [["1", "2", "3", ...]]
    // Horizontal totals must be calculated differently for each type.
    const inline = columnKeys?.length === 1 && columnKeys[0].length > 1;

    const keys = inline
      ? [[...columnKeys[0], header]]
      : [...columnKeys, [header]];

    return {
      columnKeys: keys,
      headers: headers?.length ? [...headers, ""] : headers,
      rows: calculateHorizontalTotalValues(rows, columns, header, inline),
      meta: { ...meta, fields: [...meta.fields, { name: header, type }] },
    };
  }

  return {
    rows,
    headers,
    columnKeys,
    meta,
  };
}

function calculateHorizontalTotalValues(rows, columns, header, inline) {
  if (!columns) {
    return rows;
  }

  return rows.map((row) => {
    const totalValue = row.values.reduce((acc, curr) => {
      const validValues = (columns ?? [])
        .map((column) => curr[column])
        .filter((val) => val && !isNaN(+val));

      return acc + summed(validValues);
    }, 0);

    const values = inline
      ? [{ ...row.values[0], [header]: totalValue }]
      : [...row.values, { [header]: totalValue }];

    return {
      ...row,
      values,
    };
  });
}

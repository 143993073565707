import React from "react";
import styled from "@emotion/styled";

const Table = styled.table(
  ({
    theme,
    border,
    noWrap,
    borderSpacing = 0,
    borderCollapse = "collapse",
  }) => `
    border-spacing: ${borderSpacing};
    border-collapse: ${borderCollapse};
    display: table;
    font-size: 13px;
    width: 100%;
    
    td, th {
      border: none;
      ${border && `border-bottom: 1px solid ${theme.divider};`};
      height: 30px;
      padding: 2px 10px;
      text-align: center;
      white-space: ${noWrap ? "nowrap" : "normal"};
      text-overflow: ${noWrap ? "ellipsis" : "auto"};
      padding-bottom: 6px;
    }

    td {
      text-align: left;
    }
`
);

export default React.memo((props) => {
  return (
    <Table
      border={props.border}
      id={props.tableId}
      noWrap={props.noWrap}
      borderSpacing={props.borderSpacing}
      borderCollapse={props.borderCollapse}
    >
      {props.children}
    </Table>
  );
});

import styled from "@emotion/styled";
import Pagination from "../../../../UI/Pagination/Pagination";
import { useState } from "react";

const PaginationContainer = styled.div`
  font-size: 10px;
  margin-top: 12px;
  & > div {
    margin: 0;
    height: 10px;
  }
`;

const PER_PAGE_DEFAULT_VALUE = 15;

export default function BlueprintPagination(props) {
  const [page, setPage] = useState(1);

  const { onPageChange, uniqueValuesTotalCount = 0 } = props;
  const pages = parseInt(uniqueValuesTotalCount / PER_PAGE_DEFAULT_VALUE) + 1;

  if (pages < 2) {
    return null;
  }

  function setFirstPage() {
    setPage(1);
    onPageChange(1);
  }

  function setLastPage() {
    setPage(pages);
    onPageChange(pages);
  }

  function setNextPage() {
    setPage(page + 1);
    onPageChange(page + 1);
  }

  function setPreviousPage() {
    setPage(page - 1);
    onPageChange(page - 1);
  }

  return (
    <PaginationContainer>
      <Pagination
        totalRows={uniqueValuesTotalCount}
        currentPage={page}
        position="center"
        first={page === 1 ? null : setFirstPage}
        last={page === pages ? null : setLastPage}
        next={page === pages ? null : setNextPage}
        previous={page === 1 ? null : setPreviousPage}
      />
    </PaginationContainer>
  );
}

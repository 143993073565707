import React from "react";
import { TileLayer as LeafletTileLayer } from "react-leaflet";

export default function TileLayer({ noWrap }) {
  return (
    <LeafletTileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      noWrap={noWrap}
    />
  );
}

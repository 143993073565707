import { lightenDarkenColor } from "../../styles/colorConvertor";
import {
  faCalendar,
  faImage,
  faSortNumericUp,
  faText,
  faCheckSquare,
  faListOl,
} from "@fortawesome/pro-solid-svg-icons";
import { faDollarSign } from "@fortawesome/pro-regular-svg-icons";

export const colors = {
  editable: lightenDarkenColor("#39968F", -50),
  visible: "#39968F",
};

export const iconTypes = {
  text: faText,
  string: faText,
  select: faText,
  decimal: faSortNumericUp,
  date: faCalendar,
  currency: faDollarSign,
  image: faImage,
  boolean: faCheckSquare,
  integer: faListOl,
};

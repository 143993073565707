import styled from "@emotion/styled";
import React from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import Tabs from "../../Layout/Tabs/Tabs";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import TableConfig from "./TableConfig/TableConfig";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function ChartsPreview() {
  const tabList = [
    {
      slug: "table-config",
      displayName: "Table Config",
      access: ["development"],
    },
  ];

  const location = useLocation();
  const current = tabList.reduce(
    (acc, curr) => (location.pathname.includes(curr.slug) ? curr.slug : acc),
    ""
  );

  return (
    <Container>
      <div style={{ paddingLeft: 24 }}>
        <Tabs list={tabList} baseUrl={"admin/charts"} current={current} admin />
      </div>

      <Switch>
        <PrivateRoute
          component={TableConfig}
          path={"/admin/charts/table-config"}
        />
        <Redirect to={"/admin/charts/table-config"} />
      </Switch>
    </Container>
  );
}

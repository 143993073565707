import styled from "@emotion/styled";
import getFieldLabel, {
  getMappingType,
} from "../../../utils/getVisualizationLabel";
import formatter from "../../../utils/formatters/formatter";

const Container = styled.div(
  ({ theme }) => `
  min-width: 200px;
  font-size: 12px;
  padding: 5px;
`
);

const RowItem = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

export default function TableTooltip({ row, tooltipConfig = {}, meta = {} }) {
  const { tooltipRows = [] } = tooltipConfig;

  function getColumnDisplayName(tooltipRow) {
    const { label, key } = tooltipRow;
    // Return the label if it exists, otherwise get the field label from meta.fields
    return label || getFieldLabel(meta.fields ?? [], key);
  }

  function getFormattedValue(tooltipRow) {
    const { type, key } = tooltipRow;
    // Determine the format using type or fallback to mapping type from meta.fields
    const format = type || getMappingType(meta.fields ?? [], key);

    // Format the value from row[key] using the determined format
    return formatter(row[key], format);
  }

  return (
    <Container>
      {tooltipRows.map((tRow) => (
        <RowItem key={tRow.key}>
          <span style={{ fontWeight: 300 }}>{getColumnDisplayName(tRow)}:</span>
          <span style={{ fontWeight: 500 }}>{getFormattedValue(tRow)}</span>
        </RowItem>
      ))}
    </Container>
  );
}

import { useSelector } from "react-redux";
import { defaultPathname } from "../utils/constants/constants";

export const usePathName = () => {
  const pages = useSelector((state) => state.layout.tabs);

  return defaultPathname.concat(
    pages.find((page) => page.position === 0)?.slug || "home"
  );
};

import React, { useState } from "react";
import styled from "@emotion/styled";
import UserChip from "../../../../UI/UserChip/UserChip";
import format from "date-fns/format";
import FlexWide from "../../../../UI/Flex/FlexWide";
import Flex from "../../../../UI/Flex/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteSettingOptions from "./DeleteSettingOptions";

const Item = styled.div`
  margin-bottom: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &:hover {
    color: ${(props) => props.theme.gray.gray100};
    & > div > div:last-of-type {
      display: block;
    }
  }
`;

const Name = styled.span`
  padding-left: 12px;
  svg {
    color: ${(props) => props.theme.notification.warningDark};
    font-size: 14px;
    margin-left: 6px;
  }
`;

const DateBlock = styled.div`
  font-size: 12px;
  padding-left: 12px;
  opacity: 0.5;
`;

const Actions = styled.div`
  opacity: ${(props) => (props.confirming ? 1 : 0.5)};
  display: ${(props) => (props.confirming ? "block" : "hidden")};
  position: relative;
  &:hover {
    opacity: 1;
  }
`;

const DeleteBar = styled.div`
  position: absolute;
  top: -3px;
  height: 42px;
  width: calc(100% + 16px);
  opacity: 0.5;
  border-radius: 18px;
  left: -8px;
  background: ${(props) => props.theme.notification.errorLight};
  animation: pulse 3s infinite;
  @keyframes pulse {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 0.1;
    }
  }
`;

export default function SavedExplorerItem(props) {
  const {
    name,
    createdAt,
    createdBy,
    onClick,
    handleDelete,
    deleting,
    active,
  } = props;
  const [confirming, setConfirming] = useState(false);

  const formattedCreatedAt = createdAt
    ? "created " + format(new Date(createdAt), "MM.dd.yy hh::mm aaa")
    : null;

  return (
    <Item
      active={active}
      data-active={active ? "1" : ""}
      data-cy="saved-explorer-item"
    >
      {deleting ? <DeleteBar /> : null}
      <FlexWide>
        <Flex data-cy="saved-exec-item" onClick={onClick}>
          <UserChip name={createdBy?.name} />
          <div>
            <Name>
              {name}
              {active ? <FontAwesomeIcon icon={["fas", "arrow-left"]} /> : null}
            </Name>
            <DateBlock>{formattedCreatedAt}</DateBlock>
          </div>
        </Flex>
        <Actions confirming={confirming}>
          {confirming ? (
            <DeleteSettingOptions
              handleDelete={handleDelete}
              setConfirming={setConfirming}
            />
          ) : (
            <div
              data-cy="remove-setting-button"
              onClick={() => setConfirming(true)}
            >
              <FontAwesomeIcon icon={["fas", "trash-alt"]} />
            </div>
          )}
        </Actions>
      </FlexWide>
    </Item>
  );
}

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div(
  ({ theme }) => `
  font-size: 20px;
  display: flex;
  .format-overrides-clone {
    color: ${theme.primary};
    margin: 24px 10px 0px 10px;
    cursor: pointer;
  }
  .format-overrides-remove {
    color: ${theme.notification.errorMain};
    cursor: pointer;
    margin: 24px 0px 0px 10px;
  }
`
);

export default function CloneFormatOverrideGroup({
  removeFormatOverrideGroup,
  cloneFormatOverrideGroup,
  disabled,
}) {
  return (
    <Container>
      <FontAwesomeIcon
        icon={["fas", "plus-circle"]}
        className="format-overrides-clone"
        title="Add Format Override Group"
        onClick={cloneFormatOverrideGroup}
      />
      <FontAwesomeIcon
        icon={["fas", "times-circle"]}
        className="format-overrides-remove"
        title="Remove Format Override Group"
        onClick={removeFormatOverrideGroup}
        style={{
          pointerEvents: disabled ? "none" : "inherit",
          opacity: disabled ? 0.4 : 1,
        }}
      />
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { format } from "d3-format";
import Input from "../../../../UI/Form/Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortMapping from "./SortMapping";
import Shelf from "../../../../UI/Shelf/Shelf";
import { DEFAULT_PER_PAGE } from "../hooks/useExplorerFilters/useExplorerFilters";

const Records = styled.div`
  text-align: left;
`;

const PerPage = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  width: 100px;
  input {
    font-size: 12px;
  }
`;

const Query = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text.secondary};
  svg {
    margin-right: 12px;
    font-size: 20px;
  }
`;

export default function ExplorerShelf(props) {
  const {
    totalRecords,
    perPage = DEFAULT_PER_PAGE,
    setPerPage,
    activeQuery,
    orders,
    setOrderPriority,
    setSorting,
    removeSortingKey,
  } = props;
  const [tempPerPage, setTempPerPage] = useState(perPage);

  useEffect(() => {
    setTempPerPage(perPage);
  }, [perPage]);

  const handleSetPerPage = (e) => {
    setTempPerPage(e.target.value);
  };

  const handleCommitPerPage = () => {
    setPerPage(tempPerPage);
  };

  if (!totalRecords) return null;

  const displayTotalRecords =
    typeof totalRecords === "number" ? format(",")(totalRecords) : totalRecords;

  return (
    <Shelf cy="records-shelf">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Records data-cy="total-records">
          Total Records: {displayTotalRecords}
        </Records>
        <PerPage>
          Show:{" "}
          <Input
            inPlaceEdit
            cy="explorer-per-page"
            value={tempPerPage}
            onChange={handleSetPerPage}
            onBlur={handleCommitPerPage}
            fit
          />
        </PerPage>

        <SortMapping
          orders={orders}
          setOrderPriority={setOrderPriority}
          setSorting={setSorting}
          removeSortingKey={removeSortingKey}
        />
      </div>
      <Query data-cy="shelf-explorer-query">
        <FontAwesomeIcon icon={["fal", "cubes"]} />
        {activeQuery?.name}
      </Query>
    </Shelf>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  inputMenu,
  scrollbarConfig,
} from "../../../../../styles/styledSnippets";
import { lightenDarkenColor } from "../../../../../styles/colorConvertor";
import Loading from "../../../../../UI/Loading/Loading";

const Container = styled.div`
  overflow: auto;
  position: absolute;
  width: 200px;
  left: ${(props) => props.dimensions?.left}px;
  top: ${(props) => props.dimensions?.top + props.dimensions?.height}px;
  margin-top: 6px;
  max-height: 180px;
  z-index: 10000;
  ${inputMenu}
  ${scrollbarConfig({
    hoverBorder: "1px",
    thumbBorder: "1px",
    size: ".6em",
    lightBorderDarkTheme: true,
  })}
  .auto-item {
    cursor: pointer;
    &:hover {
      background: ${(props) => lightenDarkenColor(props.theme.primary, 30)};
      color: white;
    }
  }
`;

export default function ExploreList(props) {
  const { list, keyName, handleSelect, dimensions, loading } = props;

  function parse(unique) {
    return unique[keyName] == null ? "null" : unique[keyName];
  }

  return (
    <Container dimensions={dimensions} data-cy="explore-list">
      {list &&
        list.map((unique, index) => (
          <div
            className="auto-item"
            data-cy="explore-list-auto-item"
            onMouseDown={() => handleSelect(unique)}
            key={unique[keyName] + "-" + index}
          >
            {parse(unique)}
          </div>
        ))}
      {loading && (
        <div style={{ margin: "auto" }}>
          <Loading size="small" />
        </div>
      )}
    </Container>
  );
}

ExploreList.propTypes = {
  list: PropTypes.array,
  keyName: PropTypes.string.isRequired,
};

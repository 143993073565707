import React from "react";
import FormInputGroup from "./FormInputGroup";
import Input from "../../UI/Form/Input/Input";
import IOButton from "../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginFormErrors from "./LoginFormErrors";

const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-radius: 10px;
  padding: 20px 50px;
  width: 300px;
  margin: auto;
`;

const Message = styled.div`
  text-align: left;
  font-size: 16px;
  padding-top: 12px;
  line-height: 1.5em;
  display: flex;
  svg {
    margin-right: 18px;
  }
  path {
    fill: ${(props) => props.theme.primary};
  }
`;

export default function ForgotPasswordForm(props) {
  const {
    email,
    setEmail,
    setFocus,
    setForgot,
    loading,
    authErrors,
    forgotComplete,
  } = props;

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Container>
      {forgotComplete ? (
        <Message>
          <div>
            <FontAwesomeIcon icon={["fas", "envelope-open-text"]} size="3x" />
          </div>
          <div>
            <span>Check your email to complete your password reset</span>
          </div>
        </Message>
      ) : (
        <>
          <p>Enter your email to reset your password</p>
          <LoginFormErrors errors={authErrors} cy="error-login-page" />
          <FormInputGroup>
            <Input
              type="text"
              name="email"
              placeholder="Email"
              data-cy="email-login"
              onChange={onEmailChange}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </FormInputGroup>

          <div style={{ marginTop: 8 }}>
            <IOButton
              fit
              processing={loading}
              cy="submit-login"
              disabled={!email}
            >
              Send Password
            </IOButton>
          </div>
        </>
      )}
      <div
        onClick={() => setForgot(false)}
        style={{
          marginTop: 26,
          paddingLeft: 12,
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
        <span style={{ paddingLeft: 6 }}>
          {forgotComplete ? "Login" : "Cancel Reset"}
        </span>
      </div>
    </Container>
  );
}

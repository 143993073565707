import React from "react";
import styled from "@emotion/styled";
import IOButton from "../../UI/Form/Button/IOButton";
import Input from "../../UI/Form/Input/Input";
import FormInputGroup from "./FormInputGroup";
import LoginFormErrors from "./LoginFormErrors";

const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-radius: 10px;
  padding: 20px 50px;
  width: 300px;
  margin: auto;
`;

export default function TwoFactorForm({
  goBack,
  setFocus,
  loading,
  code,
  setCode,
  authErrors,
}) {
  return (
    <Container>
      <p>TWO-FACTOR AUTHENTICATION</p>

      <p>
        Please confirm access to your account by entering the authentication
        code provided by your authenticator application.
      </p>

      <LoginFormErrors errors={authErrors} />

      <FormInputGroup>
        <Input
          type="text"
          name="code"
          placeholder="Six-digit code, e.g. 472859"
          maxLength={6}
          value={code}
          onChange={(evt) => setCode(evt.currentTarget.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </FormInputGroup>

      <div style={{ marginTop: 8 }}>
        <IOButton fit processing={loading} disabled={code.length !== 6}>
          Submit code
        </IOButton>
      </div>

      <div
        onClick={goBack}
        style={{
          marginTop: 26,
          paddingLeft: 12,
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        Back to Login
      </div>
    </Container>
  );
}

import React, { useMemo, useState } from "react";
import FilterContainer from "./FilterContainer";
import PropTypes from "prop-types";
import PortalSelectInput from "../DataExplorerUI/ExplorerInputs/PortalSelectInput";

export default function FilterEditorBox(props) {
  const { value, handleSetField, i, options, placeholder, cy } = props;
  const [showValues, setShowValues] = useState(false);

  const selectValue = useMemo(() => {
    return options.find((v) => v.value === value);
  }, [options, value]);

  const doSetField = (option) => {
    handleSetField(i, option.value);
  };

  return (
    <FilterContainer active data-cy={cy}>
      <PortalSelectInput
        showValues={showValues}
        setShowValues={setShowValues}
        placeholder={placeholder}
        onChangeValue={doSetField}
        uniqueOptions={options}
        keyName="label"
        selectedValue={selectValue}
        cy="explorer-filter-field"
      />
    </FilterContainer>
  );
}

FilterEditorBox.propTypes = {
  // value: PropTypes.object,
  handleSetField: PropTypes.func.isRequired,
  i: PropTypes.number,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

import React from "react";
import styled from "@emotion/styled";

const SwatchContainer = styled.div`
  height: 16px;
  width: 16px;
  background: ${(props) => props.background};
  position: absolute;
  left: 4px;
  margin-right: 4px;
  border-radius: 2px;
`;

export default function TableKeySwatch(props) {
  return <SwatchContainer background={props.background} />;
}

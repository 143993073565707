import React from "react";
import {
  insightTestData,
  insightTestFields,
  insightTestFieldTypes,
} from "./insightsTestData";
import Insights from "./Insights";
import styled from "@emotion/styled";

const Container = styled.div`
  padding: 24px;
`;

export default function InsightsPage() {
  return (
    <Container>
      <Insights
        data={insightTestData}
        fields={insightTestFields}
        defaultFieldTypes={insightTestFieldTypes}
      />
    </Container>
  );
}

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Checkbox from "../../../../../../UI/Form/Checkbox/Checkbox";
import {
  checkIsColored,
  checkIsRegularColor,
  checkIsSpecialColored,
} from "../../../../utils/tableValidation";

const ColorModeContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ColorModeItem = styled.div(
  ({ theme, color, isActive, disabled }) => `
  width: 40px;
  height: 40px;
  background: ${isActive ? theme.primary : theme.background.primary};
  ${
    color &&
    `
    color: ${theme.notification[color]};
  `
  };
  ${
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `
  };
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
`
);

const GradientBlock = styled.div`
  width: 16px;
  height: 16px;
  background-image: linear-gradient(
    0deg,
    hsl(0deg 100% 48%) 0%,
    hsl(0deg 90% 63%) 5%,
    hsl(0deg 89% 70%) 9%,
    hsl(0deg 89% 75%) 14%,
    hsl(0deg 88% 79%) 18%,
    hsl(0deg 88% 83%) 23%,
    hsl(0deg 87% 86%) 27%,
    hsl(0deg 87% 89%) 32%,
    hsl(0deg 87% 92%) 36%,
    hsl(0deg 86% 95%) 41%,
    hsl(0deg 86% 97%) 45%,
    hsl(0deg 0% 100%) 50%,
    hsl(121deg 100% 98%) 55%,
    hsl(121deg 100% 95%) 59%,
    hsl(121deg 100% 93%) 64%,
    hsl(121deg 100% 90%) 68%,
    hsl(121deg 100% 87%) 73%,
    hsl(121deg 100% 84%) 77%,
    hsl(121deg 100% 80%) 82%,
    hsl(121deg 100% 76%) 86%,
    hsl(121deg 100% 71%) 91%,
    hsl(122deg 100% 65%) 95%,
    hsl(127deg 100% 50%) 100%
  );
`;

export default function ColorMode({
  selectedColumn,
  setSelectedColumn,
  setChartState,
  coloredColumns = [],
  dynamicShadeBackgrounds = [],
  dynamicShadeBackgroundsBaseZero = [],
  chart,
  immutableChart,
}) {
  const { column, initialColumnForReset } = selectedColumn;
  const reverseDisabled = !column.includes("fn::");
  const isColumnExist = coloredColumns.includes(column);
  const isDynamicShadeBackgrounds = dynamicShadeBackgrounds.includes(column);
  const isDynamicShadeBackgroundsBaseZero =
    dynamicShadeBackgroundsBaseZero.includes(column);
  const isReversed = column.includes("ReverseColor::");
  const isBadDebt = column.includes("BAD_DEBT::");

  const {
    enumColorsSettings: immutableEnumColorsSettings,
    coloredColumns: immutableColoredColumns,
    dynamicShadeBackgrounds: immutableDynamicShadeBackgrounds,
    dynamicShadeBackgroundsBaseZero: immutableDynamicShadeBackgroundsBaseZero,
  } = immutableChart;

  function setRegularColors() {
    if (isBadDebt) {
      return;
    }

    setChartState({
      ...chart,
      coloredColumns: chart.coloredColumns?.filter((cc) => cc !== column),
      dynamicShadeBackgrounds: chart.dynamicShadeBackgrounds?.filter(
        (dsb) => dsb !== column
      ),
      dynamicShadeBackgroundsBaseZero: dynamicShadeBackgroundsBaseZero.filter(
        (db) => db !== column
      ),
    });
    if (isReversed) {
      makeUnReverse();
    }
  }

  function makeUnReverse() {
    const unReversed = column.replace("ReverseColor::", "");
    setChartState({
      ...chart,
      coloredColumns: chart.coloredColumns?.map((cc) =>
        cc === column ? unReversed : cc
      ),
      subTitles: chart.subTitles.map((subTitle) =>
        subTitle.map((sub) => (sub === column ? unReversed : sub))
      ),
      dynamicShadeBackgrounds: chart.dynamicShadeBackgrounds?.filter(
        (dsb) => dsb !== column
      ),
      dynamicShadeBackgroundsBaseZero: dynamicShadeBackgroundsBaseZero.filter(
        (db) => db !== column
      ),
    });

    setSelectedColumn({ ...selectedColumn, column: unReversed });
  }

  function setPositiveNegativeColors() {
    if (isColumnExist && !isReversed) {
      return;
    }

    if (isReversed) {
      makeUnReverse();
      return;
    }

    setChartState({
      ...chart,
      coloredColumns: [...(chart.coloredColumns || []), column],
      dynamicShadeBackgrounds: chart.dynamicShadeBackgrounds?.filter(
        (dsb) => dsb !== column
      ),
      dynamicShadeBackgroundsBaseZero: dynamicShadeBackgroundsBaseZero.filter(
        (db) => db !== column
      ),
    });
    setSelectedColumn({ ...selectedColumn, column });
  }

  function setReversePositiveNegativeColors() {
    if (reverseDisabled) {
      return;
    }

    const splited = column.split("::");
    const label = splited.pop();
    splited.push("ReverseColor", label);

    const withReverse = splited.join("::");
    if (coloredColumns.includes(withReverse)) {
      return;
    }

    setChartState({
      ...chart,
      coloredColumns: [
        ...(chart.coloredColumns ?? []).filter((cc) => cc !== column),
        withReverse,
      ],
      subTitles: chart.subTitles.map((subTitle) =>
        subTitle.map((sub) => (sub === column ? withReverse : sub))
      ),
      dynamicShadeBackgrounds: chart.dynamicShadeBackgrounds?.filter(
        (dsb) => dsb !== column
      ),
      dynamicShadeBackgroundsBaseZero: dynamicShadeBackgroundsBaseZero.filter(
        (db) => db !== column
      ),
    });
    setSelectedColumn({ ...selectedColumn, column: withReverse });
  }

  function restoreDefaultStyles() {
    setChartState({
      ...chart,
      coloredColumns: immutableColoredColumns,
      enumColorsSettings: immutableEnumColorsSettings,
      dynamicShadeBackgrounds: immutableDynamicShadeBackgrounds,
      dynamicShadeBackgroundsBaseZero: immutableDynamicShadeBackgroundsBaseZero,
      // subTitles not work if user did his changes and then switch to another column
      subTitles: chart.subTitles.map((subTitle) =>
        subTitle.map((sub) => (sub === column ? initialColumnForReset : sub))
      ),
    });
  }

  function setDynamicShadeBackgrounds() {
    if (dynamicShadeBackgrounds.includes(column)) {
      return restoreDefaultStyles();
    }

    const unReversed = column.replace("ReverseColor::", "");
    setChartState({
      ...chart,
      coloredColumns: chart.coloredColumns?.filter((cc) => cc !== column),
      subTitles: chart.subTitles.map((subTitle) =>
        subTitle.map((sub) => (sub === column ? unReversed : sub))
      ),
      dynamicShadeBackgrounds: [
        ...(chart.dynamicShadeBackgrounds || []),
        unReversed,
      ],
    });

    setSelectedColumn({
      ...selectedColumn,
      column: unReversed,
      initialColumnForReset: column, // not work if user did his changes and then switch to another column
    });
  }

  function changeDynamicShadeBackgroundsBaseZero() {
    const baseZero = isDynamicShadeBackgroundsBaseZero
      ? dynamicShadeBackgroundsBaseZero.filter((db) => db !== column)
      : [...dynamicShadeBackgroundsBaseZero, column];

    setChartState({
      ...chart,
      dynamicShadeBackgroundsBaseZero: baseZero,
    });
  }

  const isRegularColor = checkIsRegularColor(
    chart,
    column,
    isReversed,
    isBadDebt
  );

  const isColored = checkIsColored(chart, column, isBadDebt);

  if (checkIsSpecialColored(chart, column)) {
    return null;
  }

  return (
    <>
      <span>Color mode</span>
      <br />
      <ColorModeContainer>
        <ColorModeItem
          isActive={isRegularColor}
          title="Regular color"
          onClick={setRegularColors}
          style={{ color: isRegularColor && "white" }}
          disabled={isBadDebt}
        >
          <FontAwesomeIcon icon={["fas", "text-size"]} />
        </ColorModeItem>
        <ColorModeItem
          isActive={isColored}
          color="successMain"
          title="Positive / Negative colors"
          onClick={setPositiveNegativeColors}
        >
          <FontAwesomeIcon icon={["fas", "arrow-up"]} />
        </ColorModeItem>
        <ColorModeItem
          color="errorMain"
          title="Reverse Positive / Negative colors"
          isActive={isReversed}
          disabled={reverseDisabled}
          onClick={setReversePositiveNegativeColors}
        >
          <FontAwesomeIcon icon={["fas", "arrow-up"]} />
        </ColorModeItem>
        <ColorModeItem
          isActive={isDynamicShadeBackgrounds}
          title="Colored cells based on values"
          onClick={setDynamicShadeBackgrounds}
        >
          <GradientBlock />
        </ColorModeItem>
      </ColorModeContainer>
      <br />
      {isDynamicShadeBackgrounds && (
        <Checkbox
          label="Base zero for dynamic shade"
          checked={isDynamicShadeBackgroundsBaseZero}
          onChange={changeDynamicShadeBackgroundsBaseZero}
        />
      )}
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 40%;
  width: 100%;
  line-height: 150%;
  font-family: "Source Sans Pro", sans-serif;
  display: none;
  transition: 0.5s linear all;
  text-align: center;
  font-size: 20px;
  padding: 24px;
  box-sizing: border-box;
`;

export default function GridElementHover(props) {
  const { infoLabel } = props;
  return <Container data-cy="hover-state">"{infoLabel}"</Container>;
}

import React from "react";
import { monthNames } from "../../utils/constants/constants";
import { getFiscalYear } from "../../utils/formatters/dateFormatter";
import Th from "./Elements/Th";

const yearsMapping = {
  "-2": "2 Years Ago",
  "-1": "Last Year",
  0: "Current Year",
  1: "Next Year",
};

export default React.memo(function HighLevelHeaders({
  highLevelHeaders,
  dynamicHighLevelKeysConfig,
  dynamicSubTitleKeys,
  ...rest
}) {
  if (!highLevelHeaders && !dynamicHighLevelKeysConfig) {
    return null;
  }

  function getFiscalOffsetByMonthName(name) {
    return monthNames.findIndex((month) => month === name);
  }

  function getFiscalYears(dates, offset) {
    return dates.map((date) => getFiscalYear(date, offset));
  }

  function groupByQuantity(years) {
    return years.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + (dynamicSubTitleKeys || []).length || 1;
      return acc;
    }, {});
  }

  function buildRestHeaders(groupped = {}) {
    const curentYear = new Date().getFullYear();
    const array = [];

    Object.keys(groupped).forEach((year) => {
      const key = year - curentYear;
      array.push({ key: yearsMapping[key], colSpan: groupped[year] });
    });

    return array;
  }

  function getDynamicHeaders() {
    const { fiscalStartMonth, emptyColumns } = dynamicHighLevelKeysConfig;
    const result = [];
    const dates = rest.headers.slice();

    if (emptyColumns) {
      result.push({ key: "", colSpan: emptyColumns });
      dates.splice(0, emptyColumns);
    }

    const offset = getFiscalOffsetByMonthName(fiscalStartMonth);

    if (offset === -1) {
      return [];
    }

    const fiscalYears = getFiscalYears(dates, offset);
    const groupped = groupByQuantity(fiscalYears);
    const restHeaders = buildRestHeaders(groupped);

    return [...result, ...restHeaders];
  }

  const headers = highLevelHeaders || getDynamicHeaders();

  const columns = headers.map((header, index) => (
    <Th
      colSpan={header.colSpan}
      key={header.key + index}
      background={index % 2}
      freeze={index === 0 && rest.freezeLeft}
      {...rest}
    >
      {header.key}
    </Th>
  ));

  return <tr>{columns}</tr>;
});

import { blockFormatConverter } from "./blocks";

export const headerKpisFormatConverter = {
  response: {
    toLocal(response) {
      const ret = {};
      if (response.title) {
        ret.title = response.title;
      }
      if (response.height) {
        const height = Number(response.height);
        if (height > 0) {
          ret.height = height;
        }
      }
      ret.blocks = response.blocks.map((block) => {
        const blockLocal = blockFormatConverter.apiResponse.toLocal(block);

        // To ensure that each chart in a block line up horizontally.
        blockLocal.rows = [
          {
            charts: blockLocal.charts.map((v) => ({
              id: v.visualizationId,
            })),
          },
        ];
        blockLocal.charts = blockLocal.charts.map((v) => ({
          ...v,
          id: v.visualizationId,
        }));

        return blockLocal;
      });
      return ret;
    },
  },
};

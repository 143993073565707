import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import Loading from "../../../UI/Loading/Loading";
import SavedExplorerList from "./SavedExecManagement/SavedExplorerList";
import ExplorerQuerySelector from "./SelectedQuery/ExplorerQuerySelector";
import SelectedQueryContext from "./SelectedQuery/SelectedQueryContext";
import EnterTransition from "./DataExplorerUI/EnterTransition";
import ExplorerHomeOptions from "./ExplorerHomeOptions";

const DataExplorerContainer = styled.div`
  color: ${(props) => props.theme.text.secondary};
  position: relative;
  box-sizing: border-box;
`;

export default function DataExplorer(props) {
  const {
    queries,
    activeQuery,
    error,
    queriesLoaded,
    runQuery,
    runQueryByKey,
    lastSettings,
  } = useSelector((state) => state.dataSettings);

  const execSettings = useSelector((state) => state.queryExecSettings);
  const authUserName = useSelector((state) => state.auth.name);
  const dispatch = useDispatch();

  const [selectedQuery, doSetSelectedQuery] = useState(null);
  const [allQueryFields, setAllQueryFields] = useState(null);
  const [showPanel, setShowPanel] = useState(null);
  const initial = props.location.pathname.split("/").length < 4;

  useEffect(() => {
    dispatch(actions.resetQuery());
    dispatch(actions.listQueries());
    return () => dispatch(actions.clearExplorer());
  }, [dispatch]);

  const setSelectedQuery = useCallback(
    (value) => {
      doSetSelectedQuery(value);
      if (queries?.length) {
        setQueryFields();
      }
      function setQueryFields() {
        const activeQuery = queries.find((q) => q.uuid === value);
        if (activeQuery) {
          const selectedQueryFields = activeQuery.dataSources[0].fields;
          setAllQueryFields(selectedQueryFields);
        }
      }
    },
    [queries]
  );

  const {
    activeSettings,
    saveQuery,
    explorerMode,
    errors: queryExecSettingsError,
  } = execSettings;
  const allFields = activeQuery?.dataSources[0].fields;

  const handleExecSelect = (id) => {
    props.history.push(`/admin/data-explorer/${id}`);
  };

  const handleQuerySelect = (val) => {
    props.history.push(`/admin/data-explorer/new/${val}`);
  };

  let totalRecords;
  const paginationResults = runQueryByKey?.pagination;
  if (paginationResults) {
    if (paginationResults.loading) {
      totalRecords = "Loading...";
    } else if (paginationResults.error) {
      totalRecords = "Failed to load";
    } else {
      totalRecords = paginationResults.totalRecords;
    }
  }

  if (!queriesLoaded) return <Loading />;

  function handlePanelState(panel, open) {
    setShowPanel(open ? panel : null);
  }

  return (
    <>
      <DataExplorerContainer>
        <SelectedQueryContext.Provider
          value={{
            selectedQuery,
            setSelectedQuery,
            allQueryFields,
            setAllQueryFields,
          }}
        >
          <ExplorerQuerySelector
            initial={initial}
            queries={queries}
            showSavedList={showPanel === "list"}
            setShowSavedList={setShowPanel}
            handleQuerySelect={handleQuerySelect}
          />

          <EnterTransition show={showPanel === "list"}>
            <SavedExplorerList
              handleClose={() => handlePanelState(null, false)}
              handleSelect={handleExecSelect}
              authUserName={authUserName}
              dispatch={dispatch}
              {...execSettings}
            />
          </EnterTransition>

          <ExplorerHomeOptions
            runQuery={{
              ...runQuery,
              totalRecords,
            }}
            lastSettings={lastSettings}
            allFields={allFields}
            activeQuery={activeQuery}
            activeQueryError={error}
            dispatch={dispatch}
            activeSettings={activeSettings}
            queryExecSettingsError={queryExecSettingsError}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
            selectedQuery={selectedQuery}
            setSelectedQuery={setSelectedQuery}
            saveQuery={saveQuery}
            explorerMode={explorerMode}
          />
        </SelectedQueryContext.Provider>
      </DataExplorerContainer>
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { scaleBand, scaleLinear } from "d3-scale";
import { stack } from "d3-shape";
import { extent } from "d3-array";
import { format } from "d3-format";
import SpringHorizontalBar from "./SpringHorizontalBar";
import formatter, { labelFormatter } from "../../utils/formatters/formatter";
import getVisualizationLabel, {
  getFieldTypeByAlias,
} from "../../utils/getVisualizationLabel";

const Label = styled.text(
  ({ theme, color }) => `
    dominant-baseline: middle;
    text-anchor: end;
    fill: ${theme.text.secondary};
    font-size: 8px;
    opacity: .7;
`
);

const Total = styled.text(
  ({ theme, color }) => `
    dominant-baseline: middle;
    font-size: 10px;
    fill: ${theme.text.secondary};
    font-weight: 500;
`
);

const Title = styled.text(
  ({ theme, color, size }) => `
    dominant-baseline: hanging;
    text-anchor: end;
    fill: ${theme.text.secondary};
    font-size: ${size === "SM" ? 10 : 14}px;
    transform: translate(-4px, -10px);
`
);

export default function HorizontalBars(props) {
  const {
    data,
    layerKeys,
    xKey,
    width,
    height,
    colors,
    titleSize,
    colorKeys,
    setTooltip,
    colorFunction,
    meta,
    defaultFormatting,
    xLabelFormat,
    labelFormat,
    yLabelOuter,
  } = props;

  const allBarKeys = data[0] ? data[0].values.map((d) => d[xKey]) : [];
  const dataStack = stack().keys(layerKeys);
  const seriesArray = data.map((d) => dataStack(d.values));
  const groupTerms = data.map((d) => d.term);
  const outerOffset = data.length === 1 ? 200 : 30;

  const yOuter = scaleBand()
    .domain(groupTerms)
    .range([0, height - outerOffset]) // Outer bars @todo replace with correct padding
    .paddingInner(0.4);

  const y = scaleBand() //Inner axis
    .domain(allBarKeys)
    .range([0, height / groupTerms.length - outerOffset]) //  @todo replace hard-coded outer padding with dynamic
    .paddingInner(0.2)
    .paddingOuter(0.3);
  const allValuesForGrouping = (series) =>
    series.reduce((acc, curr) => {
      return [...acc, ...curr.map((d) => d[1])];
    }, []);

  const allValues = seriesArray.reduce(
    (acc, curr) => [...acc, ...allValuesForGrouping(curr)],
    [0]
  );

  const calcTotal = (row) =>
    layerKeys.reduce((acc, curr) => acc + (row[curr] ? row[curr] : 0), 0);

  const totals = data.map((d) =>
    d.values.map((v) => ({ name: v[xKey], value: calcTotal(v) }))
  );

  const x = scaleLinear().domain(extent(allValues)).range([0, width]);

  const showTooltip = (
    x1,
    x2,
    amount,
    businessUnit,
    type,
    allValues,
    yPos,
    isDefaultFormatting
  ) => {
    const offsetX = x(x2 - (x2 - x1) + (x2 - x1) / 2);
    const xPos = x1 === 0 ? x(amount) / 2 : offsetX;

    const tooltip = [];

    if (isDefaultFormatting) {
      tooltip.push({
        label: getVisualizationLabel(meta.fields, type),
        value: amount,
        type: getFieldTypeByAlias(meta.fields, type),
      });
    } else {
      tooltip.push(
        { label: "Business", value: businessUnit, type: "string" },
        { label: yLabelOuter ?? "Type", value: type, type: "string" },
        { label: "Amount", value: amount, type: "currency" }
      );
    }

    // @todo refactor this out to normalize
    const tooltipData = {
      x: xPos,
      y: yPos,
      tooltip,
      allValues,
    };

    setTooltip(tooltipData);
  };

  const doFormat = ({ value, name }) => {
    if (labelFormat) {
      return labelFormatter(value, labelFormat);
    } else if (defaultFormatting) {
      const type = getFieldTypeByAlias(meta.fields, name);
      return formatter(value, type);
    }

    return format("$.2s")(value);
  };

  const getBarLabel = (item = {}, index) => {
    return (
      item.values[index]?.label ||
      (item.values[index][xKey] && item.values[index][xKey].toUpperCase())
    );
  };

  return (
    <g>
      {seriesArray.map((series, seriesInt) => (
        <g
          transform={`translate(0 ${yOuter(data[seriesInt].term)})`}
          key={seriesInt}
        >
          <Title
            size={titleSize}
            style={{
              dominantBaseline: "hanging",
              textAnchor: "end",
              fontSize: titleSize === "SM" ? 10 : 14,
              transform: "translate(-4px, -10px)",
            }}
          >
            {formatter(data[seriesInt].term, xLabelFormat)}
          </Title>
          {series.map((d, i) =>
            d.map((v, j) => (
              <g key={d[xKey] + String(j)}>
                {/*Label*/}
                {i === 0 ? (
                  <Label
                    transform={`translate(-6, ${
                      y(allBarKeys[j]) + y.bandwidth() / 2
                    })`}
                    style={{
                      dominantBaseline: "middle",
                      textAnchor: "end",
                      fontSize: 8,
                      opacity: 0.7,
                    }}
                  >
                    {getBarLabel(data[0], j)}
                  </Label>
                ) : null}

                {/*Total Label*/}
                {i === 0 ? (
                  <Total
                    transform={`translate(${
                      x(totals[seriesInt][j].value) + 4
                    } ${y(allBarKeys[j]) + y.bandwidth() / 2})`}
                    style={{
                      dominantBaseline: "middle",
                      fontSize: 10,
                      fontWeight: 500,
                    }}
                  >
                    {doFormat(totals[seriesInt][j])}
                  </Total>
                ) : null}

                <SpringHorizontalBar
                  data-cy="hor-bars-bar"
                  width={x(v[1] - v[0])}
                  height={y.bandwidth()}
                  chartNum={i + 1}
                  x={x(v[0])}
                  y={y(allBarKeys[j])}
                  color={
                    colorFunction
                      ? colors(i)
                      : colors[colorKeys.findIndex((c) => c === d.key)]
                  }
                  onMouseOver={(e) =>
                    showTooltip(
                      v[0],
                      v[1],
                      v.data[d.key],
                      d.key,
                      v.data[xKey],
                      d,
                      e.nativeEvent.offsetY,
                      defaultFormatting
                    )
                  }
                  onMouseOut={() => setTooltip(null)}
                />
              </g>
            ))
          )}
        </g>
      ))}
    </g>
  );
}

HorizontalBars.defaultProps = {
  colors: ["b33040", "#d25c4d", "#f2b447", "#d9d574"],
};

HorizontalBars.propTypes = {
  data: PropTypes.array,
  layerKeys: PropTypes.array.isRequired,
};

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import AssistantChip from "./AssistantChip";

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 60px;
`;

const WordBubble = styled.div`
  width: 300px;
  background: ${(props) => props.theme.notification.infoMain};
  color: white;
  min-height: 50px;
  position: absolute;
  right: 100px;
  bottom: 12px;
  padding: 12px 22px;
  line-height: 150%;
  border-radius: 2px;
`;
const ArrowContainer = styled.div`
  position: absolute;
  right: -6px;
  bottom: 20px;
  font-size: 10px;
  path {
    fill: ${(props) => props.theme.notification.infoMain};
  }
`;
const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  max-width: 760px;
  line-height: 150%;
`;

export default function Assistant(props) {
  if (props.message) {
    return (
      <MessageBlock data-cy="assistant-message-block">
        <AssistantChip
          message
          error={props.error}
          large={props.large}
          imageOverride={props.imageOverride}
        />
        <div>{props.children}</div>
      </MessageBlock>
    );
  }
  if (!props.show) return null;

  return (
    <Wrapper data-cy="assistant-wrapper">
      <AssistantChip error={props.error} />
      <WordBubble>
        {props.text}
        <ArrowContainer>
          <FontAwesomeIcon icon={["fas", "triangle"]} rotation={90} />
        </ArrowContainer>
      </WordBubble>
    </Wrapper>
  );
}

Assistant.defaultProps = {
  text: `Enough with the Jibber Jabber, start forming your data.`,
};

Assistant.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
  message: PropTypes.bool,
  error: PropTypes.bool,
  large: PropTypes.bool,
};

import { useState } from "react";

import useBooleanToggle from "../../../hooks/useBooleanToggle";

let timeout;
export default (allColumns) => {
  const [showEditable, toggleShowEditable] = useBooleanToggle(false);
  const [showVisible, toggleShowVisible] = useBooleanToggle(false);
  const [stringMatch, setStringMatch] = useState("");
  const [appliedStringMatch, setAppliedStringMatch] = useState("");
  const [maxChars, setMaxChars] = useState(60);

  function getAccessMutableProps(column) {
    const { editable, cellEditorParams, visible } =
      (allColumns ?? []).find((item) => item.colId === column.colId) ?? {};

    return {
      editable,
      cellEditorParams,
      visible,
    };
  }

  const filteredColumns = allColumns
    .filter((c) => {
      if (c.name) {
        throw new Error(
          "API columns are being passed, these must be in Grid columns format"
        );
      } else {
        return c;
      }
    })
    .filter((c) => (showEditable ? c.editable : c))
    .filter((c) => c.field !== undefined)
    .filter((c) =>
      c.field.toLowerCase().includes(appliedStringMatch.toLowerCase())
    )
    .map((c) => ({
      ...c,
      ...getAccessMutableProps(c),
      displayName: c.field.substring(0, Math.floor(maxChars / 3)),
    }));

  function handleStringMatchChange(e) {
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(function () {
      setAppliedStringMatch(e.target.value);
      timeout = null;
    }, 2000);
    return setStringMatch(e.target.value);
  }

  function decreaseTileSize() {
    return setMaxChars((curr) => Math.max(curr - 4, 8));
  }

  function increaseTileSize() {
    return setMaxChars((curr) => Math.min(curr + 4, 80));
  }

  return {
    filteredColumns,
    showEditable,
    toggleShowEditable,
    toggleShowVisible,
    showVisible,
    stringMatch,
    handleStringMatchChange,
    maxChars,
    decreaseTileSize,
    increaseTileSize,
  };
};

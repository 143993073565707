import * as actions from "../../actions/actionTypes";
import produce from "immer";
import { sortBy } from "lodash-es";

const initialState = {
  etlConfigs: [],
  etlConfigLog: null,
  selectedEtlConfig: null,
  loading: false,
  errors: [],
  redirect: false,
  databases: [],
  sourceDbs: [],
  sourceTables: [],
  salesforceObjects: [],
  sageObjects: [],
  shipStationObjects: [],
  flows: {},
  autodetect: {
    loading: false,
    config: null,
    error: null,
  },
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_ETL_CONFIG_INIT:
      draft.redirect = false;
      draft.selectedEtlConfig = null;
      break;

    case actions.CREATE_ETL_CONFIG_START:
      draft.loading = true;
      draft.errors = [];
      break;

    case actions.CREATE_ETL_CONFIG_SUCCESS:
      draft.redirect = true;
      draft.loading = false;
      draft.errors = [];
      break;

    case actions.CREATE_ETL_CONFIG_FAIL:
      draft.loading = false;
      draft.errors = action.errors;
      break;

    case actions.GET_ETL_CONFIG_LIST_START:
      draft.loading = true;
      break;

    case actions.GET_ETL_CONFIG_LIST_SUCCESS:
      draft.loading = false;
      draft.etlConfigs = action.results.data;
      break;

    case actions.GET_ETL_CONFIG_LIST_FAIL:
      draft.loading = false;
      break;

    case actions.GET_ETL_CONFIG_START:
      draft.loading = true;
      break;

    case actions.GET_ETL_CONFIG_SUCCESS:
      draft.loading = false;

      delete action.results.data.lastExecutionDate;
      draft.selectedEtlConfig = {
        ...action.results.data,
        connectionId: action.results.data.connection
          ? action.results.data.connection.uuid
          : null,
        connectionDestinationId: action.results.data.connectiondestination
          ? action.results.data.connectiondestination.uuid
          : null,
      };

      if (action.results.data.type === "database") {
        draft.selectedEtlConfig.configJson =
          draft.selectedEtlConfig.configJson.map((t) => ({
            ...t,
            columns: t.columns.map((c) => ({
              ...c,
              action: null,
              original_name: c.name,
            })),
          }));
      }

      if (action.results.data.fileType === "excel") {
        draft.selectedEtlConfig.configJson.params =
          draft.selectedEtlConfig.configJson.params.map((s) => ({
            ...s,
            columns: s.columns.map((c) => ({
              ...c,
              action: "update",
              original_name: c.name,
            })),
          }));
      }

      if (action.results.data.configJson.columns) {
        draft.selectedEtlConfig.configJson = {
          ...action.results.data.configJson,
          columns: action.results.data.configJson.columns.map((c) => ({
            ...c,
            action: null,
            original_name: c.name,
          })),
        };
      }

      break;

    case actions.GET_ETL_CONFIG_FAIL:
      draft.loading = false;
      break;

    case actions.DELETE_ETL_CONFIG_SUCCESS:
      draft.etlConfigs = draft.etlConfigs.filter(
        (config) => config.uuid !== action.uuid
      );
      break;

    case actions.UPDATE_ETL_CONFIG_SUCCESS:
      draft.redirect = true;
      draft.selectedEtlConfig = {
        ...action.results.data,
      };

      if (action.results.data.columns) {
        draft.selectedEtlConfig.configJson = {
          ...action.results.data.configJson,
          columns: action.results.data.configJson.columns.map((c) => ({
            ...c,
            action: null,
            original_name: c.name,
          })),
        };
      }
      break;

    case actions.ETL_CONFIG_LIST_DATABASES_CONFIG_SUCCESS:
      draft.databases = sortBy(action.results.data, "name");
      break;

    case actions.ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_SUCCESS:
      draft.sourceDbs = action.results.data;
      break;

    case actions.ETL_CONFIG_LIST_TABLES_SUCCESS:
      draft.sourceTables = action.results.data.map((t) => ({
        table: t.namespace ? t.namespace + "." + t.name : t.name,
        ...t,
      }));
      break;

    case actions.ETL_CONFIG_LIST_FIELDS_SUCCESS:
      draft.sourceTables = draft.sourceTables.map((t) =>
        t.table === action.payload.table
          ? { ...t, fields: action.payload.data }
          : t
      );
      break;

    case actions.ETL_CONFIG_RUN_JOB_SUCCESS:
    case actions.ETL_CONFIG_PAUSE_JOB_SUCCESS:
    case actions.ETL_CONFIG_RESUME_JOB_SUCCESS:
      const config = action.results.data;
      draft.etlConfigs = draft.etlConfigs.map((c) =>
        c.uuid === config.uuid ? { ...config } : c
      );
      break;

    case actions.ETL_CONFIG_GET_LOGS_START:
      draft.etlConfigLog = null;
      break;

    case actions.ETL_CONFIG_GET_LOGS_SUCCESS:
      draft.etlConfigLog = action.results.data;
      break;

    case actions.ETL_CONFIG_CLEAR_LOGS:
      draft.etlConfigLog = null;
      break;

    case actions.GET_DATA_FLOW_SUCCESS:
      const queries = action.results.data;

      const allConnections = queries.reduce((acc, curr) => {
        return [...acc, ...curr.data_sources.map((ds) => ds.connection)];
      }, []);

      const uniqueConnectionIds = allConnections
        .map((c) => c.uuid)
        .filter((v, i, s) => s.indexOf(v) === i);
      const rawConnections = uniqueConnectionIds.map((uc) =>
        allConnections.find((c) => c.uuid === uc)
      );

      const allDataSources = queries.reduce((acc, curr) => {
        return [...acc, ...curr.data_sources];
      }, []);

      const uniqueDataSourceIds = allDataSources
        .map((ds) => ds.uuid)
        .filter((v, i, s) => s.indexOf(v) === i);

      const uniqueDataSources = uniqueDataSourceIds.map((udsId) =>
        allDataSources.find((ads) => ads.uuid === udsId)
      );

      const connections = rawConnections.map((rc) => ({
        ...rc,
        dataSources: uniqueDataSources.filter(
          (uds) => rc.uuid === uds.connection.uuid
        ),
      }));

      // const allData
      // const mappedConnections = connections.map(c => {...c, dataSources: })

      const uploads = connections.filter((d) => d.type === "file_upload");
      const databases = connections.filter((d) => d.type === "database");
      const salesforce = connections.filter((d) => d.type === "salesforce");
      const shipstation = connections.filter((d) => d.type === "shipstation");

      draft.flows = { uploads, databases, salesforce, shipstation };
      break;

    case actions.SALESFORCE_GET_OBJECTS_START:
      draft.salesforceObjects = [];
      break;

    case actions.SALESFORCE_GET_OBJECTS_SUCCESS:
      draft.salesforceObjects = action.results.data;
      break;

    case actions.SAGE_GET_OBJECTS_START:
      draft.sageObjects = [];
      break;

    case actions.SAGE_GET_OBJECTS_SUCCESS:
      draft.sageObjects = action.results.data;
      break;

    case actions.SHIPSTATION_GET_ORDERS_START:
      draft.shipStationObjects = [];
      break;

    case actions.SHIPSTATION_GET_ORDERS_SUCCESS:
      draft.shipStationObjects = action.results.data;
      break;

    case actions.ETL_AUTODETECT_INIT:
      draft.autodetect.loading = false;
      draft.autodetect.config = null;
      draft.autodetect.error = null;
      break;

    case actions.ETL_AUTODETECT_START:
      draft.autodetect.loading = true;
      draft.autodetect.config = null;
      draft.autodetect.error = null;
      break;

    case actions.ETL_AUTODETECT_SUCCESS:
      draft.autodetect.loading = false;
      draft.autodetect.config = action.results.data;
      break;

    case actions.ETL_AUTODETECT_FAIL:
      draft.autodetect.loading = false;
      draft.autodetect.config = null;
      draft.autodetect.error = action.error;
      break;

    default:
      return draft;
  }
});

import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../styles/colorConvertor";

const NotificationItem = styled.div(
  ({ theme }) => `
    display: flex;
    border-bottom: 1px solid ${theme.gray.gray300};
    align-items: center;
    min-height: 45px;
    &:hover {
        background: ${lightenDarkenColor(theme.gray.gray300, 1)}
    }
    svg {
        color: orange;
        margin-left: 10px;
        font-size: 14px;
    }
    a {
        color: ${theme.gray.gray800};
    }
    a, .item-inner {
        padding: 5px 10px;
    }
`
);

export default NotificationItem;

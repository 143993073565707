import React from "react";
import styled from "@emotion/styled";
import { schemeTableau10 } from "d3-scale-chromatic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Circle = styled.div`
  height: 24px;
  width: 24px;
  background: ${(props) => schemeTableau10[props.i]};
  margin-right: ${(props) => (props.optionMode ? 10 : 2)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  color: white;
  flex-shrink: 0;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text.secondary};
  font-size: 12px;
`;
export default function AccessGroupOption(props) {
  const { value, i, optionMode, active, handleClick, singleSelected } = props;
  function handleClickOption() {
    if (handleClick) {
      handleClick();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        color: "rgba(255, 255, 255, 0.6)",
        paddingRight: 6,
      }}
      onClick={handleClickOption}
    >
      <Name>
        <Circle optionMode={optionMode || singleSelected} i={i}>
          {value.displayName.charAt(0)}
        </Circle>{" "}
        {optionMode || singleSelected ? value.displayName : null}
      </Name>
      <div>{!!active ? <FontAwesomeIcon icon={["fas", "check"]} /> : null}</div>
    </div>
  );
}

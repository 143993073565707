import { useEffect } from "react";
import { laravelEchoWrapper } from "../utils/websockets";
import { useSelector } from "react-redux";

export default function useSubscribeToImportantNotifications(
  subscribeCallback
) {
  const tenantUuid = useSelector((state) => state.layout.tenantId);
  const channelName = `App.Models.Tenant.${tenantUuid}`;

  useEffect(() => {
    const channel = laravelEchoWrapper.private(channelName);
    const eventName = "ImportantNotificationsChanged";
    channel.listen(eventName, subscribeCallback);

    return () => {
      channel.stopListening(eventName, subscribeCallback);
      try {
        laravelEchoWrapper.leave(channelName);
      } catch (e) {
        console.error(e);
      }
    };
  }, [channelName, subscribeCallback]);
}

import formatter from "../../utils/formatters/formatter";

class Target {
  constructor(target, actual, format = "%", precision = 0) {
    this.target = target;
    this.actual = actual;
    this.format = format;
    this.invertSuccess = false;
    this.precision = precision;
    this.value = actual / target;
    this.progress = this.value;
  }

  setInvertSuccess() {
    const diff = this.actual - this.target;
    this.value = diff < 0 ? 1 : diff / this.target;
    this.invertSuccess = true;
    this.progress = Math.abs(this.value);
  }

  setNewValue(newValue) {
    this.value = newValue;
  }

  get formatted() {
    return formatter(this.value, this.format, this.precision);
  }

  get status() {
    return this.value >= 1 ? "positive" : "negative";
  }
}

export default Target;

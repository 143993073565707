import React from "react";
import PieChart from "./PieChart";
import styled from "@emotion/styled";

const PieLabel = styled.text`
  text-anchor: middle;
  fill: ${(props) => props.theme.text.secondary};
  font-size: 12px;
`;

export default function SummaryDetailPie(props) {
  const {
    summaryChart,
    detailCharts,
    width,
    height,
    colors,
    setTooltip,
    meta,
    valueKey,
    margin,
    hidePieValues,
    valueKeys,
    showPercentsInTooltip,
    includeCategoryInTooltip,
    groupKey,
    sourceData,
  } = props;

  const bigMultiplier = 0.65;

  const useAsSize = height < width ? height : width;
  const translateYOffset = (i) => {
    return (useAsSize * 0.2 + (detailCharts.length > 3 ? 5 : 22)) * i + 42;
  };

  const heroWidth = detailCharts ? width * bigMultiplier : width;
  const detailWidth = width * 0.17;

  function sendTooltip(val) {
    const name = val?.tooltip[0]?.name;
    const match = sourceData.find((d) => d[groupKey] === name);
    setTooltip(
      val
        ? { ...val, key: groupKey, sourceData: match, piePercent: true }
        : null
    );
  }

  return (
    <g
      data-cy="chart-summary-detail-pie"
      transform={`translate(${-margin.left}, 0)`}
    >
      <g data-cy="summary-pie" transform={`translate(0, 40)`}>
        <PieChart
          outline
          labels
          data={summaryChart}
          width={heroWidth}
          height={height * 0.7}
          colors={colors}
          setTooltip={sendTooltip}
          meta={meta}
          valueKey={valueKey}
          hidePieValues={hidePieValues}
          valueKeys={valueKeys}
          showPercentsInTooltip={showPercentsInTooltip}
          includeCategoryInTooltip={includeCategoryInTooltip}
        />
      </g>

      {detailCharts &&
        detailCharts.map((detailChart, i) => (
          <g
            key={i}
            transform={`translate(${heroWidth + 38}, ${translateYOffset(i)})`}
            data-cy="detail-pie-chart"
          >
            <g transform={`translate(${detailWidth / 2}, -5)`}>
              <PieLabel
                style={{ textAnchor: "middle", fontSize: 12 }}
                data-cy={`pie-label-detail-${detailChart.key}`}
              >
                {detailChart.key}
              </PieLabel>
            </g>
            <PieChart
              width={width * 0.17}
              height={height * 0.17}
              data={detailChart.values}
              colors={colors}
              delay={300 + i * 300}
              outline
              setTooltip={setTooltip}
              meta={meta}
              valueKey={valueKey}
            />
          </g>
        ))}
    </g>
  );
}

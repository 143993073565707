import React from "react";
import styled from "@emotion/styled";
import IOButton from "../../../UI/Form/Button/IOButton";
import Select from "../../../UI/Form/Select/Select";
import Input from "../../../UI/Form/Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1%;
  position: relative;
  border: 1px solid ${(props) => props.theme.divider};
  border-radius: 5px;
  padding: 10px;
  & > svg {
    cursor: pointer;
    top: 5px;
    right: 5px;
    position: absolute;
    color: ${(props) => props.theme.notification.errorMain};
    font-size: 12px;
  }
`;

export default function CalculatedNAColumns({ state, setState, fields }) {
  const addCalculatedNAColumn = () => {
    const calculateNAColumns = [...state.chart.calculateNAColumns];
    calculateNAColumns.push({ key: "", value: "" });

    setState({ ...state, chart: { ...state.chart, calculateNAColumns } });
  };

  const selectField = ({ name, value }, index, isValue) => {
    const calculateNAColumns = [...state.chart.calculateNAColumns];
    calculateNAColumns[index][isValue ? "value" : "key"] = isValue
      ? value
      : name;

    setState({ ...state, chart: { ...state.chart, calculateNAColumns } });
  };

  const removeCalculatedNAColumn = (index) => {
    const calculateNAColumns = state.chart.calculateNAColumns.filter(
      (_, i) => i !== index
    );
    setState({ ...state, chart: { ...state.chart, calculateNAColumns } });
  };

  return (
    <Container>
      <IOButton
        style={{ width: "fit-content", alignSelf: "flex-end" }}
        smallPadding
        onClick={addCalculatedNAColumn}
        add
        outline
        disabled={!state.chart.calculateNAColumns}
      >
        Add Subtitle
      </IOButton>

      <br />

      <Columns>
        {state.chart.calculateNAColumns?.map((cnc, index) => (
          <Column key={"column_" + index}>
            <Select
              options={fields}
              getOptionValue={(o) => o.dataSourceFieldUuid}
              getOptionLabel={(o) => o.name}
              onChange={(o) => selectField(o, index)}
              isClearable={false}
              label="Key"
            />

            <br />

            <Input
              label="Value"
              onChange={({ target }) => selectField(target, index, true)}
            />

            <FontAwesomeIcon
              icon={["fa", "times"]}
              onClick={() => removeCalculatedNAColumn(index)}
            />
          </Column>
        ))}
      </Columns>
    </Container>
  );
}

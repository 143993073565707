import React from "react";
import LoginElementContainer from "./LoginElementContainer";
import styled from "@emotion/styled";
import IOButton from "../../UI/Form/Button/IOButton";

const Title = styled.p`
  text-transform: uppercase;
`;

const TextContainer = styled.div`
  // The aim is to make sure the container is the same height as the login one.
  height: 140px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

/**
 * Nags the user about setting up 2FA if not set up yet :)
 */
export default function TwoFactorNudger() {
  return (
    <LoginElementContainer>
      <Title>Login Success</Title>

      <TextContainer>
        <p>You have successfully logged in.</p>
      </TextContainer>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
        data-login-result=""
      >
        <IOButton type="button" smallPadding to="/user-profile">
          Set up Two-Factor Auth
        </IOButton>
        <IOButton
          type="button"
          smallPadding
          cancel
          to="/"
          data-cy="skip-2fa-setup"
        >
          Skip
        </IOButton>
      </div>
    </LoginElementContainer>
  );
}

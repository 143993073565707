import Switcher from "../../../../../UI/Switcher/Switcher";
import React, { useCallback } from "react";
import ButtonLink from "../../../../../UI/ButtonLink/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PinnedRowItem from "./PinnedRowItem";

export default function PinnedRows(props) {
  const { overrides, setChartState, pinnedRows, theme, chart } = props;

  const onPinItemChange = useCallback(
    (value, index, name) => {
      setChartState({
        ...chart,
        pinnedRows: chart.pinnedRows.map((pin, i) =>
          index === i ? { ...pin, [name]: value } : pin
        ),
      });
    },
    [chart, setChartState]
  );

  const addPinnedRow = () => {
    setChartState({
      ...chart,
      pinnedRows: [...pinnedRows, { position: "bottom" }],
    });
  };

  return (
    <>
      <Switcher
        rightLabel="Pin/Unpin Row"
        checked={!!pinnedRows}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            pinnedRows: !pinnedRows ? [] : null,
          })
        }
      />
      <br />
      {(pinnedRows ?? []).map((pinnedRow, index) => (
        <PinnedRowItem
          pinnedRow={pinnedRow}
          overrides={overrides}
          onPinItemChange={onPinItemChange}
          index={index}
          key={pinnedRow.value + index}
        />
      ))}
      {pinnedRows && (
        <ButtonLink style={{ justifyContent: "right" }} onClick={addPinnedRow}>
          <FontAwesomeIcon icon={["fas", "plus"]} style={{ marginRight: 10 }} />
          Add More
        </ButtonLink>
      )}
      <br />
      <hr color={theme.background.secondary} />
      <br />
    </>
  );
}

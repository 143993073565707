import React, { useState, useEffect } from "react";
import DatePickerContainer from "../../../UI/DatePicker/DatePickerContainer";
import { useTheme } from "emotion-theming";
import { getCustomRangeDates } from "../../../utils/formatters/dateFormatter";
import { isDateBetweenCorrectPeriod } from "./customDateUtils";

export default function CustomRange({ dateConfig }) {
  const theme = useTheme();
  const { start, end } = getCustomRangeDates();
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  useEffect(() => {
    if (startDate <= endDate) {
      const isCorrectStart = isDateBetweenCorrectPeriod(startDate);
      const isCorrectEnd = isDateBetweenCorrectPeriod(endDate);

      if (isCorrectStart && isCorrectEnd) {
        dateConfig.setCustomDateRange(startDate, endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  const fn = { start: setStartDate, end: setEndDate };

  function setDate(e, key) {
    if (!e.target.value) {
      return;
    }

    fn[key](e.target.value);
  }

  return (
    <>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          value={startDate || ""}
          onChange={(e) => setDate(e, "start")}
          data-cy="date-range-start-input"
          max={endDate}
        />
      </DatePickerContainer>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          value={endDate || ""}
          onChange={(e) => setDate(e, "end")}
          data-cy="date-range-end-input"
          min={startDate}
        />
      </DatePickerContainer>
    </>
  );
}

import React from "react";
import IOButton from "../../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import {
  themeColor,
  themeGrayIf,
  themeText,
} from "../../../styles/styledSnippets";
import Flex from "../../../UI/Flex/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  color: ${themeText("primary")};
  padding-top: 24px;
`;

const Instruction = styled.div`
  margin-bottom: 28px;
  max-width: 480px;
  padding-left: 12px;
  display: flex;
  align-items: center;
`;

const Card = styled.div`
  background: ${themeGrayIf(900, 200)};
  padding: 24px;
  border-radius: 4px;
  margin: 0 12px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${themeGrayIf(800, 300)};
  font-size: 14px;
  line-height: 150%;
  button {
    width: 100%;
    margin-top: 24px;
  }
  font-weight: 300;
`;
const Bolt = styled.div`
  background: ${themeColor("warningDark")};
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${themeColor("warningLight")};
  margin-right: 18px;
  font-size: 36px;
`;
export default function ActiveTableEntryScreen(props) {
  return (
    <Container data-cy="create-active-table-start">
      <Instruction>
        <Bolt>
          <FontAwesomeIcon icon={["fal", "bolt"]} />
        </Bolt>
        <div>
          Let's begin by setting up your active table. Please choose the desired
          mode for your table to proceed.
        </div>
      </Instruction>
      <Flex>
        <Card>
          <div>
            <span>
              For tables that are already present in your data warehouse.
              <br />
              <br />
              Should you have any doubts or require assistance, please don't
              hesitate to reach out to InsightOut technical support. We're here
              to provide guidance and help.
            </span>
          </div>
          <IOButton
            cy="select-existing-table"
            onClick={() => props.setCreateType("existing")}
          >
            <div>Update existing table</div>
          </IOButton>
        </Card>
        <Card>
          <div>
            Utilize this option to construct a fresh table from the ground up.
            <br />
            <br />
            Once it's established, you can access this data the same way as any
            other tables in your warehouse - be it through the Data Explorer,
            chart and table creations, dashboards, or any other functions.
          </div>
          <IOButton
            onClick={() => props.setCreateType("new")}
            cy="create-new-table"
          >
            <div>Create new table</div>
          </IOButton>
        </Card>
      </Flex>
    </Container>
  );
}

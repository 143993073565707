import React from "react";
import styled from "@emotion/styled";
import { themeGrayIf, themeText } from "../../../styles/styledSnippets";

const Container = styled.div`
  padding: 4px 12px;
  font-size: 11px;
  background: ${themeGrayIf(800, 300)};
  border-radius: 2px;
  cursor: pointer;
  color: ${themeText("secondary")};
  &:hover {
    background: ${themeGrayIf(700, 400)};
  }
  svg {
    margin-right: 8px;
    font-size: 8px;
    position: relative;
    top: -1px;
  }
`;
const SmallButton = (props) => {
  return <Container {...props}>{props.children}</Container>;
};

export default SmallButton;

import { getTime } from "date-fns";

export function dateIsAfter(second, first) {
  const firstDate = getTimeFromString(first);
  const secondDate = getTimeFromString(second);
  return firstDate <= secondDate;
}

function getTimeFromString(str) {
  return getTime(new Date(str));
}

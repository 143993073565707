import React from "react";

import styled from "@emotion/styled";
import { themeGrayIf, themeText } from "../../styles/styledSnippets";
import PropTypes from "prop-types";

const Container = styled.div`
  border: 1px solid ${themeGrayIf(700, 300)};
  &:hover {
    border-color: ${themeGrayIf(600, 400)};
  }
  color: ${themeText("primary")};
  padding: 9px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  ${(props) => (props.hideOutline ? `border: none;` : "")}
  ${(props) =>
    props.color
      ? `
  border-color: ${props.theme.notification[props.color]};
  `
      : null}
  svg {
    //margin-left: 16px;
  }
  overflow: hidden;
  user-select: none;
  ${(props) =>
    props.active
      ? `
    background: ${props.theme.notification.infoMain};
    color: white;
  `
      : ""}

  ${(props) =>
    props.disabled
      ? `pointer-events: none;
    opacity: 0.4;`
      : null}
`;
export default function ButtonOutline(props) {
  return (
    <Container
      hideOutline={props.hideOutline}
      onClick={props.onClick}
      title={props.title}
      data-active={!!props.active}
      data-cy={props.cy}
      color={props.color}
      active={props.active}
      disabled={props.disabled}
    >
      {props.children}
    </Container>
  );
}

ButtonOutline.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  cy: PropTypes.string,
  active: PropTypes.bool,
};

import Switcher from "../../../../UI/Switcher/Switcher";

export default function Divider({ chart, setChartState }) {
  function removeBorder(str) {
    return str.replace("::BORDERED", "");
  }

  function removeAllBorders() {
    setChartState({
      ...chart,
      allowGroupsRightBorder: !chart.allowGroupsRightBorder,
      subTitles: chart.subTitles.map((subTitle) => subTitle.map(removeBorder)),
      ...(chart.staticGroupingKeys && {
        staticGroupingKeys: chart.staticGroupingKeys.map(removeBorder),
      }),
      ...(chart.coloredColumns && {
        coloredColumns: chart.coloredColumns.map(removeBorder),
      }),
    });
  }

  function addBorderToSubTitles() {
    const changedRows = {};

    const newSubTitles = chart.subTitles.map((subTitle) =>
      subTitle.map((sub, index, array) => {
        if (index === array.length - 1 && sub.includes("fn::")) {
          const splitted = sub.split("::");
          const label = splitted.pop();
          splitted.push("BORDERED");
          splitted.push(label);

          const splitedToString = splitted.join("::");
          changedRows[sub] = splitedToString;

          return splitedToString;
        }

        return sub;
      })
    );

    return { newSubTitles, changedRows };
  }

  function addBorderToGroupings() {
    return {
      staticGroupingKeys: chart.staticGroupingKeys.map((grouping, index) =>
        chart.subTitles[index].length > 1 ? grouping + "::BORDERED" : grouping
      ),
    };
  }

  function addBorderToColoredColumn(changedRows) {
    return {
      coloredColumns: chart.coloredColumns.map((cc) => changedRows[cc] || cc),
    };
  }

  const allowGroupsRightBorderCahnge = () => {
    if (chart.allowGroupsRightBorder) {
      removeAllBorders();
      return;
    }

    const { newSubTitles, changedRows } = addBorderToSubTitles();

    setChartState({
      ...chart,
      allowGroupsRightBorder: !chart.allowGroupsRightBorder,
      subTitles: newSubTitles,
      ...(chart.staticGroupingKeys && addBorderToGroupings()),
      ...(chart.coloredColumns && addBorderToColoredColumn(changedRows)),
    });
  };

  return (
    <Switcher
      rightLabel="Add Divider for Groups"
      checked={!!chart.allowGroupsRightBorder}
      handleSwitchChange={allowGroupsRightBorderCahnge}
      disabled={!(chart.staticGroupingKeys || chart.groupingKey)}
    />
  );
}

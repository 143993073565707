import { useEffect } from "react";
import { useLeaflet } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import retinaIcon from "leaflet/dist/images/marker-icon-2x.png";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const mcg = L.markerClusterGroup();
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: retinaIcon,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

export default function MarkerCluster({ markers, showPopup }) {
  const { map } = useLeaflet();

  useEffect(() => {
    mcg.clearLayers();

    markers.map((marker) =>
      L.marker(new L.LatLng(marker.lat, marker.lng))
        .addTo(mcg)
        .on("click", () => showPopup(marker))
    );

    map.addLayer(mcg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  return null;
}

import ButtonGroup from "../../../../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../../../../UI/Form/Button/IOButton";

export default function Tabs({ onStyles, setOnStyles }) {
  return (
    <ButtonGroup rounded position="center" style={{ marginBottom: 20 }}>
      <IOButton cancel={onStyles} onClick={() => setOnStyles(false)}>
        Settings
      </IOButton>
      <IOButton cancel={!onStyles} onClick={() => setOnStyles(true)}>
        Styles
      </IOButton>
    </ButtonGroup>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import "../../../styles/fontLibrary";

const ButtonContainer = styled.div`
  display: flex;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  path {
    fill: ${(props) =>
      props.color
        ? props.color
        : props.primary
        ? props.theme.primary
        : props.theme.text.secondary};
  }
  &:hover {
    path {
      fill: ${(props) =>
        props.color
          ? lightenDarkenColor(props.color, 20)
          : props.primary
          ? lightenDarkenColor(props.theme.primary, 20)
          : props.theme.text.primary};
    }
  }
`;

export default function ActionButton(props) {
  const { cy, primary, large, onClick, disabled, icon, color, ...rest } = props;

  return (
    <ButtonContainer
      primary={primary}
      large={large}
      disabled={disabled}
      onClick={onClick}
      color={color}
      data-cy={cy}
      {...rest}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} size={`${large ? 2 : 1}x`} />
      ) : (
        props.iconCustom
      )}
    </ButtonContainer>
  );
}

ActionButton.propTypes = {
  primary: PropTypes.bool,
  large: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  color: PropTypes.string,
  cy: PropTypes.string,
  iconCustom: PropTypes.object,
};

import { monthNames } from "./constants";
import { getDateFromMonthYear } from "../dates/dateFunc";

const date = new Date();
date.setMonth(date.getMonth() - 1);

export const customFilterTypes = [
  {
    type: "MonthYear",
    direction: "desc",
    fn: getDateFromMonthYear,
    defaultSelected: [`${monthNames[date.getMonth()]} ${date.getFullYear()}`],
  },
];

import React from "react";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getComparisonModeItem } from "../../../Layout/Block/interruptDashboard";
import { selectFilter } from "../../../store/actions";
import { lightenDarkenColor } from "../../../styles/colorConvertor";

const UncheckIcon = styled.span(
  ({ theme }) => `
  margin-right: 10px;
  color: ${theme.primary};
  &:hover {
    color: ${lightenDarkenColor(theme.primary, 50)};
  }
`
);

export default React.memo(function UnSelectFilterIcon({
  index,
  value,
  updatePreview,
}) {
  const dispatch = useDispatch();

  const { activeTab, comparisonMode, comparisonModeFilterName, menuFilters } =
    useSelector((state) => state.layout);

  const { useFilterUnselect } =
    getComparisonModeItem(comparisonMode, activeTab?.uuid) ?? {};

  if (!useFilterUnselect || index !== 0 || !comparisonModeFilterName) {
    return null;
  }

  const { values, type } =
    menuFilters.find((filter) => filter.name === comparisonModeFilterName) ??
    {};

  const checked = values.filter((val) => val.checked);
  const filter = checked.find((item) => item.value === value);

  if (!filter) {
    return null;
  }

  function uncheckFilter(e) {
    e.stopPropagation();
    const shouldReloadCharts = !updatePreview;

    dispatch(selectFilter(filter, type, shouldReloadCharts));

    // updatePreview mean that you trying to use this feature
    // from power editor or json table editor
    if (updatePreview) {
      updatePreview();
    }
  }

  return (
    <UncheckIcon onClick={uncheckFilter}>
      <FontAwesomeIcon
        icon={["fas", "eye-slash"]}
        title={`Remove "${value}" from filters`}
      />
    </UncheckIcon>
  );
});

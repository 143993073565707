import React from "react";
import { useSelector } from "react-redux";
import { isRunningInTest } from "../utils/env";

/**
 * For hiding stuff unless the TTG flag is TRUE for the user.
 * A render prop, passing a treeIcon you can use to indicate that the feature will only be visible
 * to TTG users.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode=} props.renderElse What to render if the current user
 *  is not deemed a TTG user.
 * @return React.ReactNode
 */
export default function TtgFeature({ children, renderElse = null }) {
  return useRenderIfTtgUser(children) ?? renderElse;
}

export function TreeIcon({
  title = "This feature is visible to TTG users only",
}) {
  return (
    <span
      role="img"
      aria-label="Tree"
      title={title}
      style={{ fontSize: 12, position: "relative", top: -1 }}
    >
      🌲
    </span>
  );
}

/**
 * Renders what the passed callback returns if the current user is a TTG user.
 * You can use this function directly instead of the TtgFeature component if it's important for
 * the children to not end up wrapped in another component (TtgFeature), e.g. for use in <Switch>
 * which is sensitive to which exact components get rendered as its direct children.
 */
export function useRenderIfTtgUser(toRenderCallback) {
  if (!useShouldShowTtgFeature()) {
    return null;
  }

  return toRenderCallback({
    //eslint-disable-next-line jsx-a11y/accessible-emoji
    treeIcon: <TreeIcon />,
  });
}

export function useShouldShowTtgFeature() {
  const isTtgUser =
    // Treat all users as TTG users in tests.
    isRunningInTest ||
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useSelector((state) => state?.auth?.ttg);

  return isTtgUser || process.env.NODE_ENV === "development";
}

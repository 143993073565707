import React from "react";
import styled from "@emotion/styled";
import { format, isValid } from "date-fns";
import { absoluteDate } from "../../utils/dates/dateFunc";

const Label = styled.div`
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.text.primary};
`;

const DateLabel = styled.div`
  padding-left: 12px;
  font-size: 11px;
  color: ${(props) => props.theme.text.secondary};
`;

export default function UserChipGroup(props) {
  const ignoreGroupWrapper = !props.label && !props.date;
  if (ignoreGroupWrapper) return props.children;

  const dateObj = absoluteDate(props.date);
  const isDate = isValid(dateObj);
  const formattedDate =
    isDate && format(absoluteDate(props.date), "EE MMM d, yyyy");
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.children}
      {props.label ? <Label>{props.label}</Label> : null}
      {formattedDate ? <DateLabel>{formattedDate}</DateLabel> : null}
    </div>
  );
}

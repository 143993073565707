import * as actions from "../actions/actionTypes";

const initialState = {
  tableData: [],
  processing: false,
  watchlist: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_WATCHLIST_TABLE_DATA_START:
      return {
        ...state,
        processing: true,
      };

    case actions.GET_WATCHLIST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tableData: action.results.data,
        processing: false,
      };

    case actions.GET_WATCHLIST_TABLE_DATA_FAIL:
      return {
        ...state,
        processing: false,
      };

    case actions.GET_WATCHLISTS_SUCCESS:
    case actions.SET_WATCHLIST_CONFIG_SUCCESS:
      const watchlist = action.results.data;

      return {
        ...state,
        watchlist: watchlist?.items?.reduce((acc, curr) => {
          acc[curr.uuid] = curr.filters;
          return acc;
        }, {}),
      };

    default:
      return state;
  }
};

import React from "react";
import IOButton from "../../../UI/Form/Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/pro-regular-svg-icons";

export default function InsightsSubmitButton(props) {
  const { loading, disabled } = props;
  return (
    <div>
      <IOButton
        style={{ textTransform: "uppercase" }}
        processing={loading}
        disabled={disabled}
        cy="submit-insights"
      >
        {!loading && <FontAwesomeIcon icon={faRocket} pull="left" />} Get
        Insights
      </IOButton>
    </div>
  );
}

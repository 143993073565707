import React from "react";
import styled from "@emotion/styled";
import useResizeObserver from "use-resize-observer";
import PropTypes from "prop-types";

const squarePadding = 16;
const tileSize = 120;
function getTop({ height, i, active, heroMode, count, activeIndex }) {
  if (heroMode) {
    if (active) return 0;
    const multiplier = activeIndex > i ? i : i - 1;
    return (height / 5) * multiplier + squarePadding;
  } else {
    return i < 3 ? 0 : height / 2;
  }
}

function getLeft({ width, i, active, heroMode }) {
  if (heroMode) {
    return active ? 0 : width - tileSize;
  } else {
    return ((width + squarePadding) / 3) * (i % 3);
  }
}

function getWidth({ width, active, heroMode }) {
  if (heroMode) {
    return active ? width - tileSize - squarePadding : tileSize;
  }
  return width / 3 - squarePadding;
}

function getHeight({ height, active, heroMode }) {
  if (heroMode) {
    return active ? height : height / 5 - squarePadding;
  } else {
    return height / 2 - squarePadding;
  }
}

const GridSquare = styled.div`
  display: flex;
  align-items: stretch;
  position: absolute;
  box-sizing: border-box;
  top: ${getTop}px;
  left: ${getLeft}px;
  height: ${getHeight}px;
  width: ${getWidth}px;
  transition: 0.2s ease all;
`;

const HeroGrid = (props) => {
  const { activeIndex, setActiveIndex } = props;

  const { ref, width, height } = useResizeObserver();

  function handleClick(i) {
    if (activeIndex === i) return;
    setActiveIndex(i);
  }

  function isThumbnail(i) {
    return activeIndex !== null && activeIndex !== i;
  }
  return (
    <div ref={ref} style={{ position: "relative", width: "100%" }}>
      {React.Children.map(props.children, (child, i) => (
        <GridSquare
          i={i}
          width={width}
          height={height}
          onClick={() => handleClick(i)}
          active={i === activeIndex}
          activeIndex={activeIndex}
          heroMode={activeIndex !== null}
          count={props.children.length}
        >
          {React.cloneElement(child, {
            active: activeIndex === i,
            thumbnail: isThumbnail(i),
            onClose: () => setActiveIndex(null),
          })}
        </GridSquare>
      ))}
    </div>
  );
};

HeroGrid.propTypes = {
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

export default HeroGrid;

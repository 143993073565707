import React from "react";
import { useTheme } from "emotion-theming";
import Select from "../../UI/Form/Select/Select";
import { rangeAndAggregationOptions } from "../../utils/constants/constants";

export default React.memo(function DateType({ aggregation, term, onChange }) {
  const options = aggregation.map((item) =>
    rangeAndAggregationOptions.find(({ value }) => value === item)
  );

  const theme = useTheme();
  const value = options.find((item) => item.value === term) || options[0];

  return (
    <div>
      <Select
        cy="date-type-select"
        options={options}
        defaultValue={value}
        onChange={onChange}
        bgPrimary={theme.menuPrimary}
      />
    </div>
  );
});

export const mapCustomFields = (fields, overrides) => {
  return fields.length && overrides?.length
    ? overrides.map(mappedCustomFields(fields))
    : [];
};

export const mappedCustomFields = (fields) => (override) => {
  const matchedField = matchImmutable(fields, override);

  if (override.mapping) {
    matchedField.mapping = { ...override.mapping };
  }

  if (override.aggregation) {
    const type = override.aggregation.type;
    const prefix = getPrefix(type);
    const dateCode = dateCodes.find((code) => +code.code === type);

    applyAggregationKeys(prefix, dateCode);
  }

  function applyAggregationKeys(prefix, dateCode) {
    matchedField.aggregation = override.aggregation;
    matchedField.aggregationName = prefix + override.name;
    if (dateCode) {
      matchedField.mapping.aggType = dateCode.format;
    }
  }

  return matchedField;
};

function getPrefix(type) {
  const matchType = isNaN(+type) ? "text" : "num";
  const match = codes.find((code) => code[matchType] === type);
  return match.prefix;
}

function matchImmutable(fields, override) {
  const matched = fields.find((f) => f.name === override.name);
  return matched
    ? { ...matched, mapping: { ...matched.mapping } }
    : { ...override, mapping: { displayName: override.name } };
}

const dateCodes = [
  { code: 6, format: "date-year" },
  { code: 7, format: "date-quarter" },
  { code: 8, format: "date-month" },
  { code: 9, format: "date-week" },
  { code: 10, format: "date-day" },
];

const codes = [
  { num: 1, text: "MAX", prefix: "Max" },
  { num: 2, text: "AVG", prefix: "Avg" },
  { num: 3, text: "COUNT", prefix: "Count" },
  { num: 4, text: "MIN", prefix: "Min" },
  { num: 6, text: "YEAR", prefix: "Year" },
  { num: 7, text: "QUARTER", prefix: "Quarter" },
  { num: 8, text: "MONTH", prefix: "Month" },
  { num: 9, text: "WEEK", prefix: "Week" },
  { num: 10, text: "DAY", prefix: "Day" },
  { num: 11, text: "SUM", prefix: "Sum" },
];

export default mappedCustomFields;

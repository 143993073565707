import Modal from "../../UI/Modal/Modal";

export default function WatchlistModal(props) {
  const { show, children, close } = props;

  if (!show) {
    return null;
  }

  return (
    <Modal close={close} rounded width="600px">
      {children}
    </Modal>
  );
}

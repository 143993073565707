import { keyBy } from "lodash-es";
import { getBands, getDateFromMonthYear } from "../dateFunc";
import { TYPE_GROUPS } from "./dataTypes";
import { frontendBaseUrl } from "../env";
import {
  permissionKey,
  permissionName,
} from "../../Pages/UserManagement/Roles/models";

export const defaultPathname = "/dashboard/";
export const defaultPage = { template: "dashboard.json" };
export const currentEnv = process.env.NODE_ENV;

export const headers = ["Name", "Email", "Role", "Groups", "Created"]; // Make sure that order of headers match order of columns

export const ROLE_TENANT_OWNER = "tenant_owner";
export const ROLE_ADMIN = "admin";
export const ROLE_USER = "user";
export const READ_ACCESS = "read_access";

export const adminPanelMenuList = [
  {
    label: "Users",
    link: "/admin/user-management/users",
    permission: permissionKey.userManagement,
  },
  {
    label: "Explorer",
    link: "/admin/data-explorer",
    permission: permissionKey.dataExplorer,
  },
  {
    label: "Data",
    link: "/admin/data-settings",
    permission: permissionKey.dataSources,
  },
  {
    label: "Files",
    link: "/admin/files",
    permission: permissionKey.fileUploading,
  },
  {
    label: "Config",
    link: "/admin/site-configuration",
    permission: permissionKey.siteConfiguration,
  },
  // {
  //   label: "Charts",
  //   link: "/admin/charts",
  //   access: ["development"],
  //   role: [ROLE_TENANT_OWNER],
  // },
  {
    label: "Pages",
    link: "/admin/page-management",
    permission: permissionKey.pageManagement,
  },
  // {
  //   label: "Alerts",
  //   link: "/admin/alerts",
  //   access: ["development"],
  //   role: [ROLE_TENANT_OWNER],
  // },
  {
    label: "Monitor",
    link: "/admin/monitoring",
    permission: permissionKey.monitoring,
  },
  {
    label: "Emails",
    link: "/admin/automation/scheduled-emails",
    permission: permissionKey.emailReports,
  },
];

export const dataManagementTabList = [
  {
    slug: "mapping",
    displayName: "Data Mapping",
    permissions: [
      permissionName.DATA_SOURCE_READ,
      permissionName.VISUALIZATIONS_READ,
    ],
  },
  {
    slug: "data-explorer",
    displayName: "Data Explorer",
    link: "/admin/data-explorer",
    permissions: [permissionName.DATA_EDITOR],
  },
  {
    slug: "attributes",
    displayName: "Data Grooming",
    permissions: [permissionName.DATA_SOURCE_UPDATE],
  },
  {
    slug: "connections",
    displayName: "Connections",
    permissions: [permissionName.CONNECTIONS_READ],
  },
  {
    slug: "etl-configs",
    displayName: "Etl Configurations",
    permissions: [permissionName.DATA_SOURCE_UPDATE],
  },
  {
    slug: "data-editor",
    displayName: "Data Editor",
    permissions: [permissionName.DATA_SOURCE_UPDATE],
  },
  {
    slug: "query-monitoring",
    displayName: "Query Monitoring",
    permissions: [permissionName.DATA_SOURCE_UPDATE],
  },
];

export const imageUploadFormats = ["png", "jpeg"];

export const hideSectionForUrls = [
  "/upload-profile-image",
  "/user-profile",
  "/dashboard-message",
  "/data-dictionary",
];

export const alertIcons = {
  error: ["fas", "times-circle"],
  success: ["fas", "check-circle"],
  info: ["fas", "info-circle"],
  warning: ["fas", "exclamation-triangle"],
};

export const alertIconsLight = {
  error: ["fal", "times-circle"],
  success: ["fal", "check-circle"],
  info: ["fal", "info-circle"],
  warning: ["fal", "exclamation-triangle"],
};

export const themeList = [
  { value: "dark", label: "Dark", color: "#000" },
  { value: "light", label: "Light", color: "#fff" },
];

/**
 * These options act both as the possible aggregations, and also as the
 * possible simple range-only date filter options.
 */
export const rangeAndAggregationOptions = [
  {
    value: "daily",
    label: "Daily",
    periodName: "days",
    min: 30,
    max: 90,
    displayFormat: "M/d/yyyy",
  },
  {
    value: "weekly",
    label: "Weekly",
    periodName: "weeks",
    min: 8,
    max: 24,
    displayFormat: "M/d/yyyy",
  },
  {
    value: "monthly",
    label: "Monthly",
    periodName: "months",
    min: 6,
    max: 24,
    displayFormat: "MMMM - yyyy",
  },
  {
    value: "quarterly",
    label: "Quarterly",
    periodName: "quarters",
    min: 1,
    max: 8,
    displayFormat: "QQQ - yyyy",
  },
  {
    value: "yearly",
    label: "Annual",
    periodName: "years",
    min: 2,
    max: 10,
    displayFormat: "yyyy",
  },
];

export const customFilterOptions = [
  {
    value: "today",
    label: "Today",
    periodName: "today",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Days",
  },
  {
    value: "yesterday",
    label: "Yesterday",
    periodName: "yesterday",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Days",
  },
  {
    value: "wtd",
    label: "Week-To-Date",
    periodName: "wtd",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Weeks",
  },
  {
    value: "last5Weeks",
    label: "Last 5 Weeks",
    periodName: "last5Weeks",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Weeks",
  },
  {
    value: "mtd",
    label: "Month-To-Date",
    periodName: "mtd",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Months",
  },
  {
    value: "qtd",
    label: "Quarter-To-Date",
    periodName: "qtd",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Quarters",
  },
  {
    value: "ytd",
    label: "Year-To-Date",
    periodName: "ytd",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "csy",
    label: "Current-School-Year",
    periodName: "csy",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Others",
  },
  {
    value: "psy",
    label: "Previous-School-Year",
    periodName: "psy",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Others",
  },
  {
    value: "ltm",
    label: "Last 12 Months",
    periodName: "ltm",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Months",
  },
  {
    value: "oneYear",
    label: "1 Year",
    periodName: "oneYear",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "rw",
    label: "Recent Weeks",
    periodName: "rw",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    hasParameter: true,
    group: "Weeks",
  },
  {
    value: "rm",
    label: "Most Recent Months",
    periodName: "rm",
    alias: "recentMonths",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    hasParameter: true,
    approvedPeriod: true,
    group: "Months",
  },
  {
    value: "rq",
    label: "Recent Quarters",
    alias: "recentQuarters",
    periodName: "rq",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    hasParameter: true,
    group: "Quarters",
  },
  {
    value: "ry",
    label: "Recent Years",
    periodName: "ry",
    min: 1,
    max: 1,
    alias: "recentYears",
    approvedPeriod: true,
    displayFormat: "M/d/yyyy",
    hasParameter: true,
    group: "Years",
  },
  {
    value: "cr",
    label: "Custom Range",
    periodName: "custom range",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    hasParameter: true,
    group: "Others",
  },
  {
    value: "lw",
    label: "Last Week",
    periodName: "last week",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Weeks",
  },
  {
    value: "90days",
    label: "90 Days",
    periodName: "90 days",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Days",
  },
  {
    value: "sd",
    label: "Single Day",
    periodName: "singleDay",
    min: 1,
    max: 1,
    displayFormat: "yyyy-MM-dd",
    hasParameter: true,
    group: "Days",
  },
  {
    value: "mtm",
    label: "Month to Month",
    periodName: "monthToMonth",
    min: 1,
    max: 1,
    displayFormat: "MMMM - yyyy",
    hasParameter: true,
    group: "Months",
  },
  {
    value: "dayOfWeek",
    label: "Day Of Week",
    periodName: "dayOfWeek",
    min: 1,
    max: 1,
    displayFormat: "EEEE",
    hasParameter: true,
    group: "Days",
  },
  {
    value: "singleQuarter",
    label: "Single Quarter",
    periodName: "singleQuarter",
    min: 1,
    max: 1,
    displayFormat: "QQQ - yyyy",
    group: "Quarters",
  },
  {
    value: "singleFiscalQuarter",
    label: "Single Fiscal Quarter",
    periodName: "singleFiscalQuarter",
    min: 1,
    max: 8,
    displayFormat: "QQQ - yyyy",
    fiscalQuarterStartMonth: true,
    group: "Quarters",
  },
  {
    value: "fiscalQuarterRange",
    label: "Fiscal Quarter Range",
    periodName: "fiscalQuarterRange",
    min: 1,
    max: 8,
    displayFormat: "QQQ - yyyy",
    fiscalQuarterStartMonth: true,
    hasParameter: true,
    group: "Quarters",
  },
  {
    value: "previousYearToDate",
    label: "Previous Year-To-Date",
    periodName: "previousYearToDate",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "twoYearsAgoToDate",
    label: "Two Years Ago-To-Date",
    periodName: "twoYearsAgoToDate",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "currPrevYear",
    label: "Current and Previous Year",
    periodName: "currPrevYear",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "currPrevTwoYearsAgo",
    label: "Current, Previous, 2 Years Ago",
    periodName: "currPrevTwoYearsAgo",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "biweeklySchedule",
    label: "Biweekly Schedule",
    periodName: "biweeklySchedule",
    min: 1,
    max: 10,
    displayFormat: "M/d/yyyy",
    hasParameter: true,
    approvedPeriod: true,
    group: "Weeks",
  },
  {
    value: "none",
    label: "None...",
    periodName: "none",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Others",
  },
  {
    value: "thisYear",
    label: "This Year",
    periodName: "thisYear",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  {
    value: "thisQuarter",
    label: "This Quarter",
    periodName: "thisQuarter",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Quarters",
  },
  {
    value: "thisYearToEndOfQuarter",
    label: "This Year to End of Quarter ",
    periodName: "thisYearToEndOfQuarter",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Years",
  },
  // clife date presets
  {
    value: "currentMondayToSunday",
    label: "Next week",
    periodName: "currentMondayToSunday",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Weeks",
  },
  {
    value: "thisMonth",
    label: "This Month",
    periodName: "thisMonth",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Months",
  },
  {
    value: "nextMonth",
    label: "Next Month",
    periodName: "nextMonth",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Months",
  },
  {
    value: "nextQuarter",
    label: "Next Quarter",
    periodName: "nextQuarter",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Quarters",
  },
  {
    value: "next13weeks",
    label: "Next 13 weeks",
    periodName: "next13weeks",
    min: 1,
    max: 1,
    displayFormat: "M/d/yyyy",
    group: "Weeks",
  },
];

export const dateGroupsOrder = [
  "Days",
  "Weeks",
  "Months",
  "Quarters",
  "Years",
  "Others",
];

export const rangeAndAggregationOptionsByValue = keyBy(
  rangeAndAggregationOptions,
  "value"
);
export const customFilterOptionsByValue = keyBy(customFilterOptions, "value");

export const DATE_RANGE_START_CUSTOM = "custom";
export const DATE_RANGE_END_CUSTOM = "endCustom";
export const DATE_TERM_MONTH_TO_MONTH = "mtm";
export const DATE_TERM_SINGLE_QUARTER = "singleQuarter";

export const dateRangeOptions = {
  end: [
    { value: 0, label: "current date" },
    { value: 6, label: "+ six months" },
    { value: 12, label: "+ 1 year" },
    { value: 24, label: "+ 2 years" },
    { value: 60, label: "+ 5 years" },
    { value: 120, label: "+ 10 years" },
    { value: DATE_RANGE_END_CUSTOM, label: "custom end date" },
  ],
  start: [
    { value: 0, label: "current date" },
    { value: -6, label: "- six months" },
    { value: -12, label: "- 1 year" },
    { value: -24, label: "- 2 years" },
    { value: -60, label: "- 5 years" },
    { value: DATE_RANGE_START_CUSTOM, label: "custom start date" },
  ],
};

export const DATE_FILTER_MODE_NONE = "none";
export const DATE_FILTER_MODE_RANGE_ONLY = "rangeOnly";
export const DATE_FILTER_MODE_CUSTOM = "custom";

export const images = [
  {
    key: "background",
    label: "Background Image (User Login)",
    info: "Appears on login screen background",
  },
  { key: "favicon", label: "Favicon", info: "Appears on your browser tabs" },
  { key: "logo", label: "Logo", info: "Logo used throughout the site" },
];

export const tooltipOffsets = {
  x: 0,
  y: 20,
};

export const recordsPerPage = 200;

export const FILTER_TYPE_CHECKBOX = "checkbox";
export const FILTER_TYPE_SINGLE_LIST = "singleList";
export const FILTER_TYPE_MULTI_LIST = "multiList";
export const FILTER_TYPE_RADIO = "radio";
export const FILTER_TYPE_MULTI_RANGE = "multiRange";
export const FILTER_TYPE_SLIDER = "slider";
export const FILTER_TYPE_CHECKBOX_BOOLEAN = "checkboxBoolean";
export const FILTER_TYPE_DATE_RANGE = "dateRange";
export const FILTER_TYPE_SINGLE_DATE = "singleDate";
export const FILTER_TYPE_BUCKET_RANGE = "bucketRange";
export const FILTER_TYPE_BOOLEAN = "boolean";
export const FILTER_TYPE_DATE_PRESETS = "datePresets";
export const SELECT_ALL_FILTERS = "selectAll";

export const filterTypeVau = [
  { value: FILTER_TYPE_CHECKBOX, label: "Checkbox", isMulti: true },
  { value: FILTER_TYPE_SINGLE_LIST, label: "List - Single", isMulti: false },
  { value: FILTER_TYPE_MULTI_LIST, label: "List - Multiple", isMulti: true },
  { value: FILTER_TYPE_RADIO, label: "Radio", isMulti: false },
];

export const filterTypes = [
  { value: FILTER_TYPE_CHECKBOX, label: "Checkbox", isMulti: true },
  {
    value: FILTER_TYPE_CHECKBOX_BOOLEAN,
    label: "Checkbox (Boolean)",
    isMulti: true,
  },
  { value: FILTER_TYPE_SINGLE_LIST, label: "List - Single", isMulti: false },
  { value: FILTER_TYPE_MULTI_LIST, label: "List - Multiple", isMulti: true },
  { value: FILTER_TYPE_RADIO, label: "Radio", isMulti: false },
  { value: FILTER_TYPE_SLIDER, label: "Slider", isMulti: false },
  { value: FILTER_TYPE_MULTI_RANGE, label: "Range Slider", isMulti: true },
  { value: FILTER_TYPE_DATE_RANGE, label: "Date Range", isMulti: true },
  { value: FILTER_TYPE_SINGLE_DATE, label: "Single Date", isMulti: false },
  { value: FILTER_TYPE_BUCKET_RANGE, label: "Grouped Ranges", isMulti: true },
  { value: FILTER_TYPE_DATE_PRESETS, label: "Date Presets", isMulti: false },
];

export const lists = [FILTER_TYPE_SINGLE_LIST, FILTER_TYPE_MULTI_LIST];
export const checkboxOrRadio = [
  FILTER_TYPE_CHECKBOX,
  FILTER_TYPE_CHECKBOX_BOOLEAN,
  FILTER_TYPE_RADIO,
];

export const isFlashGovernance = "flash";

export const scheduleMessage =
  "Your export is too large to process immediately...";

export const dateFormats = {
  default: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -12,
    },
    recentQuarters: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "QQQ-yyyy",
      isReverse: true,
      count: -8,
    },
    recentYears: {},
  },
  ccg: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -13,
    },
    recentQuarters: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "QQQ-yyyy",
      isReverse: true,
      count: -8,
    },
    recentYears: {},
  },
  excelsia: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -12,
    },
    recentQuarters: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "QQQ-yyyy",
      isReverse: true,
      count: -8,
    },
    recentYears: {},
  },
  fusion92: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -12,
    },

    recentYears: {},
  },
  cj: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -12,
    },
  },
  "demo-finance": {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -24,
    },
    recentYears: {},
  },
  usaninja: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -12,
    },
    recentYears: {},
  },
  pinnacle: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -24,
      alias: "rm",
      label: "Months",
    },
    recentQuarters: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "QQQ-yyyy",
      isReverse: true,
      count: -8,
      alias: "rq",
      label: "Quarters",
    },
    recentYears: {},
  },
  timetracker: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -24,
      alias: "rm",
      label: "Months",
    },
    recentYears: {},
  },
  thejoint: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -24,
      alias: "rm",
      label: "Months",
    },
    recentYears: {},
  },
  bosco: {
    recentMonths: {
      valueFormat: "yyyy-MM-dd",
      labelFormat: "MMMM-yyyy",
      isReverse: true,
      count: -12,
    },
    recentYears: {},
  },
};

export const connectionTypeMap = {
  sqlite: 1,
  mysql: 2,
  pgsql: 3,
  sqlsrv: 4,
  mongodb: 5,
  salesforce: 6,
  googleAnalytics: 7,
  sage: 8,
  salesforceMarketingCloud: 9,
  dbt: 10,
  shipstation: 11,
  apiToken: 12,
  googleBigQuery: 13,
};

export const connectionTypes = [
  { value: connectionTypeMap.sqlite, label: "SQLite" },
  { value: connectionTypeMap.mysql, label: "MySQL" },
  { value: connectionTypeMap.pgsql, label: "PostgreSQL" },
  { value: connectionTypeMap.sqlsrv, label: "Microsoft SQL Server" },
  { value: connectionTypeMap.mongodb, label: "MongoDB" },
  { value: connectionTypeMap.salesforce, label: "Salesforce" },
  { value: connectionTypeMap.googleAnalytics, label: "Google Analytics" },
  { value: connectionTypeMap.sage, label: "Sage" },
  {
    value: connectionTypeMap.salesforceMarketingCloud,
    label: "Salesforce Marketing Cloud",
  },
  { value: connectionTypeMap.dbt, label: "DBT" },
  { value: connectionTypeMap.shipstation, label: "ShipStation" },
  { value: connectionTypeMap.apiToken, label: "API Token" },
  { value: connectionTypeMap.googleBigQuery, label: "Google Big Query" },
];

export const dataSourceTypeMap = {
  report: 1,
  reportThumbnail: 2,
  database: 3,
  localDatabase: 4,
  databaseLink: 5,
  api: 6,
};

export const joinTypes = [
  { value: "left", label: "LEFT" },
  { value: "right", label: "RIGHT" },
  { value: "inner", label: "INNER" },
];

export const formats = [
  { value: "date", label: "Date" },
  { value: "percent", label: "Percent" },
  { value: "percent-whole", label: "Percent" },
  { value: "percent-sig", label: "Percent - Sig" },
  { value: "currency", label: "Currency" },
  { value: "currency-round", label: "Currency Abbreviated Form" },
  { value: "currency-whole", label: "Currency - Whole" },
  { value: "number", label: "Number" },
  { value: "date-short", label: "Date - Short" },
  { value: "minutesToHours", label: "Minutes To Hours" },
  { value: "boolean", label: "Boolean" },
  { value: "integer", label: "Integer" },
  { value: "decimal", label: "Decimal" },
  { value: "string", label: "String" },
  { value: "percent-tenth", label: "Percent - Tenth" },
  { value: "month", label: "Month" },
  { value: "datetime", label: "Date - Time" },
  { value: "date-micro", Label: "Date - Micro" },
  { value: "date-month", Label: "Date - Month" },
  { value: "date-week", Label: "Date - Week" },
  { value: "rounded", Label: "Rounded" },
  { value: "hourOfDay", Label: "Hour Of Day" },
  { value: "hourOfDayRange", Label: "Hour Of Day Range" },
  { value: "daily", Label: "Daily" },
  { value: "monthly", Label: "Monthly" },
  { value: "weekly", Label: "Weekly" },
  { value: "mixedMonthsQuarters", Label: "Mixed Months Quarters" },
];

export const percentFormats = formats
  .filter((format) => format.value.includes("percent"))
  .map((format) => format.value);

export const dateFormatsValues = [{ value: "MMM yyyy", label: "MMM yyyy" }];

export const AGGREGATION_MAX = 1;
export const AGGREGATION_AVG = 2;
export const AGGREGATION_COUNT = 3;
export const AGGREGATION_MIN = 4;
export const AGGREGATION_DISTINCT_COUNT = 5;
export const AGGREGATION_YEAR = 6;
export const AGGREGATION_QUARTER = 7;
export const AGGREGATION_MONTH = 8;
export const AGGREGATION_WEEK = 9;
export const AGGREGATION_DAY = 10;
export const AGGREGATION_SUM = 11;

export const aggregations = [
  { value: AGGREGATION_MAX, label: "MAX", prefix: "Max" },
  { value: AGGREGATION_AVG, label: "AVG", prefix: "Avg" },
  { value: AGGREGATION_COUNT, label: "COUNT", prefix: "Count" },
  { value: AGGREGATION_MIN, label: "MIN", prefix: "Min" },
  {
    value: AGGREGATION_DISTINCT_COUNT,
    label: "DISTINCT COUNT",
    prefix: "DistinctCount",
  },
  { value: AGGREGATION_YEAR, label: "YEAR", prefix: "Year" },
  { value: AGGREGATION_QUARTER, label: "QUARTER", prefix: "Quarter" },
  { value: AGGREGATION_MONTH, label: "MONTH", prefix: "Month" },
  { value: AGGREGATION_WEEK, label: "WEEK", prefix: "Week" },
  { value: AGGREGATION_DAY, label: "DAY", prefix: "Day" },
  { value: AGGREGATION_SUM, label: "SUM", prefix: "Sum" },
];

export const aggregationMap = keyBy(aggregations, "value");

export const dateAggregationValues = [
  AGGREGATION_YEAR,
  AGGREGATION_QUARTER,
  AGGREGATION_MONTH,
  AGGREGATION_WEEK,
  AGGREGATION_DAY,
];

export const aggregationBuckets = {
  date: ["YEAR", "QUARTER", "MONTH", "WEEK", "DAY"],
  number: ["SUM", "MIN", "MAX", "AVG", "COUNT"],
};

export const orderTypes = [
  { value: "ASC", label: "ASC" },
  { value: "DESC", label: "DESC" },
];

export const tableConfigTabs = [
  { label: "General Settings", path: "/admin/charts/table-config/general" },
  { label: "Fields Settings", path: "/admin/charts/table-config/fields" },
  { label: "Subtitles", path: "/admin/charts/table-config/subtitles" },
  { label: "Calculated Rows", path: "/admin/charts/table-config/rows" },
  {
    label: "Calculated NA Columns",
    path: "/admin/charts/table-config/columns",
  },
  { label: "Format Overrides", path: "/admin/charts/table-config/overrides" },
  { label: "Rollup & Fixed Rows", path: "/admin/charts/table-config/rollup" },
];

export const roundConstants = {
  "1k": 1e3,
  "10k": 1e4,
  "100k": 1e5,
  "1M": 1e6,
  "10M": 1e7,
  "100M": 1e8,
};

export const possibleOperators = [
  ">",
  "<",
  ">=",
  "<=",
  "!=",
  "==",
  "not in",
  "like",
  "lastDays",
];

export const possibleMathOperations = [
  {
    label: "Subtract",
    value: "sub",
    sign: "-",
  },
  {
    label: "Add",
    value: "add",
    sign: "+",
  },
  {
    label: "Divide",
    value: "div",
    sign: "/",
  },
  {
    label: "Multiply",
    value: "mul",
    sign: "x",
  },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const sortDaysOfWeek = (days) => {
  return daysOfWeek
    .map((dayOfWeek) =>
      days.find((day) => day.toLowerCase() === dayOfWeek.toLowerCase())
    )
    .filter(Boolean);
};

export const sortIfDaysOfWeek = (filters) => {
  if (!filters.find((filter) => filter.label === daysOfWeek[0])) {
    return filters;
  }

  return daysOfWeek
    .map((day) => filters.find((filter) => filter.label === day))
    .filter(Boolean);
};

const getDefaultForMonthYear = () => {
  const current = new Date();
  current.setMonth(current.getMonth() - 1);

  return `${current.toLocaleString("default", {
    month: "long",
  })} ${current.getFullYear()}`;
};

export const customFilterTypes = [
  {
    type: "MonthYear",
    direction: "desc",
    fn: getDateFromMonthYear,
    defaultSelected: [getDefaultForMonthYear()],
  },
  {
    type: "ACCOUNT_REVENUE_FILTER_BAND",
    direction: "asc",
    fn: getBands,
    defaultSelected: [],
  },
  {
    type: "ACCOUNT_EMPLOYEE_FILTER_BAND",
    direction: "asc",
    fn: getBands,
    defaultSelected: [],
  },
];

export const dependencyFiltersMapping = {
  percentToEnclude: "PercentOfEmployeesToInclude",
  perEmployee: "PotentialValuePerEmployee",
  "0-100": "PotentialValueEmployee0to100",
  "100-999": "PotentialValueEmployee100to999",
  "1000+": "PotentialValueEmployee1000",
  perCompany: "PotentialValuePerCompany",
  "$5M-$10M": "PotentialValueRevenue5to10M",
  "$10M-$99M": "PotentialValueRevenue10to99M",
  "$100M-$500M": "PotentialValueRevenue100to500M",
  "$500M-$1B": "PotentialValueRevenue500to1B",
  "$1B+": "PotentialValueRevenue1B",
};

// export const fusion92DefaultHiddenFields = [
//   "Device",
//   "25Percent",
//   "50Percent",
//   "75Percent",
//   "campaignname",
// ];

export const filterValuesLimit = 10;
export const chartSectionsLimit = 10;

export const rightAligns = [
  "currency",
  "currency-whole",
  "percent",
  "percent-sig",
  "integer",
  "number",
  "float",
  "decimal",
  "decimal-tenth",
  "minutesToHours",
  "percent-whole",
  "percent-tenth",
  "percent-round",
  "pretty-decimal",
  "percent-whole-2",
  "string-right",
];

export const dateRelatedKeys = [
  "allDates",
  "quarterRange",
  "quarterRangeKey",
  "dateKey",
  "maxDateType",
  "maxDateCount",
  "dateEndOnly",
  "dateEndField",
];

export const settingsOptions = [
  {
    value: "chart",
    label: "Chart Config",
  },
  {
    value: "date",
    label: "Date Helper Config",
  },
  {
    value: "padZero",
    label: "Pad Zero",
  },
  {
    value: "overrides",
    label: "Overrides",
  },
  {
    value: "expandedOverrides",
    label: "Row Expanded Overrides",
  },
  {
    value: "orders",
    label: "Orders",
  },
];

export const dateFiltersPageDependency = ["ridership", "waterfall"];
export const dateFiltersTypeDependency = [
  DATE_TERM_MONTH_TO_MONTH,
  DATE_TERM_SINGLE_QUARTER,
];

// TODO: need to change this, very bad solution
export const hardCodedFilterLabels = {
  SameStoreDynamic: {
    0: "Non-Comp",
    1: "Comp",
  },
  SameStoreStatic: {
    0: "Non-Comp",
    1: "Comp",
  },
};

export const groupingFormats = [
  {
    value: null,
    label: "None",
  },
  {
    value: "[WEEK_RANGE]",
    label: "mm/dd/yy - mm/dd/yy - (Last Week, Current Week and etc.)",
  },
  {
    value: "[WEEK_RANGE_TO_US_DATE]",
    label: "mm/dd/yy - mm/dd/yy (Convert range to US style)",
  },
  {
    value: "[MONTHLY]",
    label: "January - 2023 (Monthly)",
  },
  {
    value: "MMM",
    label: "Month Short",
  },
  {
    value: "MMM yyyy",
    label: "Month Year",
  },
  {
    value: "MMMM",
    label: "Month Long",
  },
  {
    value: "MMMM yyyy",
    label: "Month Year Long",
  },
];

export const columnFormats = [
  { label: "None", options: [{ label: "None" }] },
  {
    label: "Text",
    options: [{ label: "String", value: "string" }],
  },
  {
    label: "Percent",
    group: TYPE_GROUPS.number,
    options: [
      {
        label: "Percent Guided (0% or 9%)",
        value: "percent-sig",
      },
      {
        label: "Percent Rounded (8.8% => 9%)",
        value: "percent-round",
      },
      {
        label: "Percent (90.1%)",
        value: "percent-tenth",
      },
      {
        label: "Percent whole (90%)",
        value: "percent-whole",
      },
      {
        label: "Percent Actual (90.3456%)",
        value: "percent",
      },
    ],
  },
  {
    label: "Currency",
    group: TYPE_GROUPS.number,
    options: [
      {
        label: "Currency ($1,236,012.12)",
        value: "currency",
      },
      {
        label: "Currency Abbreviated Form (1236.9 => $1,2k)",
        value: "currency-round",
      },
      {
        label: "Currency whole (1236012.12 => $1,236,012)",
        value: "currency-whole",
      },
    ],
  },
  {
    label: "Number",
    group: TYPE_GROUPS.number,
    options: [
      {
        label: "Number (10.5 => 10)",
        value: "number",
      },
      {
        label: "Integer (10.5 => 10)",
        value: "integer",
      },
      {
        label: "Decimal (10.56)",
        value: "decimal",
      },
      {
        label: "Decimal (10.565 => 10.5)",
        value: "decimal-tenth",
      },
      {
        label: "Decimal (0.5050 => 0.505)",
        value: "pretty-decimal",
      },
      {
        label: "Decimal two fixed (0.5050 => 0.51)",
        value: "two-fixed",
      },
      {
        label: "Rounded (10.5 => 11)",
        value: "rounded",
      },
    ],
  },
  {
    label: "Date & time",
    group: TYPE_GROUPS.date,
    options: [
      {
        label: "Date (January 1, 2022)",
        value: "date",
      },
      {
        label: "Month (January)",
        value: "month",
      },
      {
        label: "Month and Year (Jan 2022)",
        value: "month-year",
      },
      {
        label: "Datetime (1/1/2022 09:55 am)",
        value: "datetime",
      },
      {
        label: "Date-short (1/1/2022)",
        value: "date-short",
      },
      {
        label: "Date-micro (1/1/22)",
        value: "date-micro",
      },
      {
        label: "Date-mini (1/22) (M/yy) ",
        value: "date-mini",
      },
      {
        label: "Date-month (Jan 2022)",
        value: "date-month",
      },
      {
        label: "Date from week start (1/1/2022)",
        value: "date-week-start",
      },
      {
        label: "Date from week (1/1/2022)",
        value: "date-week",
      },
      {
        label: "Minutes to hours (130 => 2.2)",
        value: "minutesToHours",
      },
      {
        label: "Hour Of Day (13 => 01 PM)",
        value: "hourOfDay",
      },
      {
        label: "Hour Of Day Range (08 AM - 09 AM)",
        value: "hourOfDayRange",
      },
      {
        label: "Date from daily (1/1/2022)",
        value: "daily",
      },
      {
        label: "Date from weekly (1/1/2022)",
        value: "weekly",
      },
      {
        label: "Date from monthly (1/1/2022)",
        value: "monthly",
      },
      {
        label: "Date from Months and Quarters (January)",
        value: "mixedMonthsQuarters",
      },

      {
        label: "Day-of-week (Monday)",
        value: "day-of-week",
      },
      {
        label: "Quarter-to-fiscal (offset = 2, Q1 => FQ3)",
        value: "quarter-to-fiscal",
      },
      {
        label: "Date to US (01/01/22)",
        value: "WEEK_RANGE_TO_US_DATE_SHORT",
      },
      {
        label: "Month and Day (01/23)",
        value: "month-day",
      },
    ],
  },
  {
    label: "Boolean",
    options: [
      {
        label: "Boolean (Icon check / uncheck)",
        value: "boolean",
      },
    ],
  },
  {
    label: "Others",
    options: [
      {
        label: "API Image",
        value: "api-image",
      },
      {
        label: "Rating (Stars)",
        value: "stars",
      },
      {
        label: "Rating (Stars with Values)",
        value: "stars-and-values",
      },
    ],
  },
];

export const dateFormatOptions = [
  {
    label: "Year",
    value: "yyyy",
  },
  {
    label: "Year Month number",
    value: "yyyy 'M'MM",
  },
  {
    label: "Month Year",
    value: "MMM yyyy",
  },
  {
    label: "Month",
    value: "MMMM",
  },
  {
    label: "Month Year mini",
    value: "M/yy",
  },
  {
    label: "Quarter Year",
    value: "'Q'Q yyyy",
  },
  {
    label: "Year Quarter",
    value: "yyyy 'Q'Q",
  },
  {
    label: "Date time",
    value: "M/d/yyyy hh:mm a",
  },
  {
    label: "Date short",
    value: "M/d/yyyy",
  },
  {
    label: "Date micro",
    value: "M/d/yy",
  },
  {
    label: "Day of week",
    value: "EEEE",
  },
  {
    label: "Weekly range",
    value: "date-week",
    example: "05/12/2024 - 05/18/2024",
  },
];

export const formulaOptions = [
  {
    label: "Percents %",
    options: [
      {
        label: "Percent (A / B)",
        value: "PERCENT",
        count: 2,
      },
      {
        label: "Percent Whole (A / B)",
        value: "PERCENT_WHOLE",
        count: 2,
      },
      {
        label: "Percent minus division ((A - B) / C)",
        value: "PERCENT_MINUS_DIVISION",
        count: 3,
      },
      {
        label: "Percent Cancelation YoY ((A / (A + B)) - (C / (C + D)))",
        value: "PERCENT_CANCEL_YOY",
        count: 4,
      },
      {
        label: "Value as percent (A => %)",
        value: "VALUE_AS_PERCENT",
        count: 1,
      },
      {
        label: "Percent division addition (A / (B + C))",
        value: "PERCENT_DIVISION_ADDITION",
        count: 3,
      },
      {
        label: "Precalculated percent difference (A - B)",
        value: "PRE_CALCULATED_PERCENT_DIFFERENCE",
        count: 2,
      },
      {
        label: "Difference on division percent ((A - B) / B - (C - D) / D)",
        value: "DIFFERENCE_ON_DIVISION_PERCENT",
        count: 4,
      },
      {
        label: "Compare division on division percent ((A / B) / (C / D))",
        value: "COMPARE_DIVISION_ON_DIVISION_PERCENT",
        count: 4,
      },
      {
        label: "Division percent (A / B)",
        value: "DIVISION_PERCENT",
        count: 2,
      },
      {
        label: "Division percent 2 digits (A / B)",
        value: "DIVISION_PERCENT_PRECISION_2",
        count: 2,
      },
      {
        label: "Division percent 3 digits (A / B)",
        value: "DIVISION_PERCENT_PRECISION_3",
        count: 2,
      },
      {
        label: "Percent point difference ((A / B) - (C / D))",
        value: "PERCENT_POINT_DIFFERENCE",
        count: 4,
      },
      {
        label: "Division percent minus one (A / B - 1)",
        value: "DIVISION_PERCENT_MINUS_ONE",
        count: 2,
      },
      {
        label: "One minus percent (1 - A / B)",
        value: "ONE_MINUS_PERCENT",
        count: 2,
      },
      {
        label: "Bad debt",
        value: "BAD_DEBT",
        count: 1,
      },
      {
        label: "Percent difference ((B - A) / A)",
        value: "PERCENT_DIFFERENCE",
        count: 2,
      },
      {
        label: "Percent difference 2 digits ((B - A) / A)",
        value: "PERCENT_DIFFERENCE_PRECISION_2",
        count: 2,
      },
      {
        label: "Percent difference 3 digits ((B - A) / A)",
        value: "PERCENT_DIFFERENCE_PRECISION_3",
        count: 2,
      },
      {
        label: "Difference division percent (((A / B) - (C / D)) / (C / D))",
        value: "DIFF_DIV_PERCENT",
        count: 4,
      },
      {
        label: "Difference substruct division percent (A / B - C / D)",
        value: "DIFF_SUB_DIV_PERCENT",
        count: 4,
      },
      {
        label: "Percent over percent (((C / D) - (A / B)) / (A / B))",
        value: "PERCENT_OVER_PERCENT",
        count: 4,
      },
      {
        label: "Percent difference 2 digits (A - B)",
        value: "DIFFERENCE_PERCENT",
        count: 2,
      },
      {
        label: "Percent difference 1 digit (A - B)",
        value: "DIFFERENCE_PERCENT_1",
        count: 2,
      },
      {
        label: "Percent difference division (A - (B / C))",
        value: "PERCENT_MINUS_DIVISION_DENOMINATOR",
        count: 3,
      },
      {
        label: "Percent Net Cancel YoY ((A / (B + C)) - (D / (E + F)))",
        value: "PERCENT_NET_CANCEL_YOY",
        count: 6,
      },
    ],
  },
  {
    label: "Custom Formats",
    options: [
      {
        label: "Replace with icon (yes/no => icons)",
        value: "REPLACE_WITH_ICON",
        count: 1,
      },
      {
        label: "Grouped months (0-6 Months, 6-12 Month, 12+ Months)",
        value: "GROUPED_MONTHS",
        count: 1,
      },
      {
        label: "Static (Value => Value)",
        value: "STATIC",
        count: 1,
      },
    ],
  },
  {
    label: "Integers",
    options: [
      {
        label: "Integer",
        value: "INTEGER",
        count: 1,
      },
      {
        label: "Difference integer (A - B)",
        value: "DIFFERENCE_INTEGER",
        count: 2,
      },
      {
        label: "Addition integer (A + B)",
        value: "ADDITION_INTEGER",
        count: 2,
      },
      {
        label: "Difference integer from addition (A + B - C)",
        value: "DIFFERENCE_INTEGER_FROM_ADDITION",
        count: 4,
      },
      {
        label: "Division (A / B)",
        value: "DIVISION",
        count: 2,
      },
      {
        label: "Division 0 digit (A / B)",
        value: "DIVISION_0",
        count: 2,
      },
      {
        label: "Division 1 digit (A / B)",
        value: "DIVISION_1",
        count: 2,
      },
      {
        label: "Division Difference (A / (B - C))",
        value: "DIVISION_DIFFERENCE",
        count: 3,
      },
      {
        label: "Division Addition (A / (B + C))",
        value: "DIVISION_ADDITION",
        count: 3,
      },
      {
        label: "Difference division ((A / B) - (C / D))",
        value: "DIFF_DIV",
        count: 4,
      },
      {
        label: "Division 2 digits (A - B)",
        value: "DIFFERENCE_DECIMAL",
        count: 2,
      },
      {
        label: "Division 1 digit (A - B)",
        value: "DIFFERENCE_DECIMAL_1",
        count: 2,
      },
    ],
  },
  {
    label: "Currency $",
    options: [
      {
        label: "Value as currency",
        value: "VALUE_AS_CURRENCY",
        count: 1,
      },
      {
        label: "Division currency (A / B)",
        value: "DIVISION_CURRENCY",
        count: 2,
      },
      {
        label: "Division currency UK (A / B) $,.2f-uk",
        value: "DIVISION_CURRENCY_UK",
        count: 2,
      },
      {
        label: "Compare division on division currency ((A / B) / (C / D))",
        value: "COMPARE_DIVISION_ON_DIVISION_CURRENCY",
        count: 4,
      },
      {
        label: "Difference (A - B)",
        value: "DIFFERENCE",
        count: 2,
      },
      {
        label:
          "Division & Difference (Current Month A / Current Month B - Previous Month A / Previous Month B)",
        value: "PERIOD_DIVISION_DIFFERENCE_CURRENCY",
        count: 2,
      },
    ],
  },
  {
    label: "Date Formats",
    options: [
      {
        label: "Week range UK (01/01/2022 - 07/01/2022)",
        value: "WEEK_RANGE_UK",
        count: 1,
      },
    ],
  },
];

export const toDateTypeOptions = [
  {
    value: "LAST_12_MONTHS",
    label: "Last Full 12 Months",
  },
  {
    value: "LAST_8_QUARTERS",
    label: "Last 8 Quarters",
  },
  {
    value: "CURRENT_YEAR",
    label: "Current Year",
  },
  {
    value: "MTD",
    label: "Month To Date",
  },
  {
    value: "QTD",
    label: "Quarter To Date",
  },
  {
    value: "YTD",
    label: "Year To Date",
  },
  {
    value: "PRIOR_30_DAYS",
    label: "Prior 30 Days",
  },
];

export const dataSourceFieldFormats = [
  {
    name: "Date",
    icon: "calendar",
    innerFormats: [
      {
        name: "Date",
        value: "date",
      },

      {
        name: "Date time",
        value: "datetime",
      },

      {
        name: "Time",
        value: "time",
      },
    ],
  },
  {
    name: "String",
    icon: "align-left",
    innerFormats: [
      {
        name: "String",
        value: "string",
      },
      {
        name: "Text",
        value: "text",
      },
    ],
  },
  {
    name: "Number",
    icon: "hashtag",
    innerFormats: [
      {
        name: "Bigint",
        value: "bigint",
      },
      {
        name: "Decimal",
        value: "decimal",
      },
      {
        name: "Float",
        value: "float",
      },
      {
        name: "Guid",
        value: "guid",
      },
      {
        name: "Integer",
        value: "integer",
      },
      {
        name: "Small int",
        value: "smallint",
      },
    ],
  },
  {
    name: "Other",
    icon: "th-large",
    innerFormats: [
      {
        name: "Boolean",
        value: "boolean",
      },
    ],
  },
];

export const writeModes = {
  typeOnly: "typeOnly",
  none: "none",
  all: "all",
};

export const userInviteStatuses = {
  notInvited: "not_invited",
  invited: "invited",
  hasPassword: "has_password",
};

export const inviteReturnUrl = `${frontendBaseUrl}/login/activate`;

/**
 * Reusable readonly empty array that can be used as a React hook dependency
 * which - unlike literal arrays - does not change on re-renders.
 */
export const emptyArray = Object.freeze([]);

export const localTimeFormatsBasedOnDomain = {
  ccg: {
    format: "MM/dd/yyyy hh:mm a",
  },
};

export const passwordMinLength = 9;
export const localStorageFiltersKey = "SAVED_FILTERS";

export const getGlobalStickyOffset = (domain) => {
  const domains = {
    pinnacle: 88,
  };

  return domains[domain] ?? 0;
};

export const siteConfigFilterTypesOptions = [
  {
    label: "Checkbox",
    options: [
      {
        label: "Multiple",
        value: "checkbox",
      },
      {
        label: "Single",
        value: "checkboxBoolean",
      },
    ],
  },
  {
    label: "Slider",
    options: [
      {
        label: "Multiple",
        value: "multiRange",
      },
      {
        label: "Single",
        value: "slider",
      },
    ],
  },

  {
    label: "List",
    options: [
      {
        label: "Multiple",
        value: "multiList",
      },
      {
        label: "Single",
        value: "singleList",
      },
    ],
  },
  {
    label: "Date",
    options: [
      {
        label: "Range",
        value: "dateRange",
      },
      {
        label: "Single",
        value: "singleDate",
      },
    ],
  },
  {
    label: "Ranges",
    value: "bucketRange",
    options: [],
  },
  {
    label: "Radio",
    value: "radio",
    options: [],
  },
  {
    label: "Date Presets",
    value: "datePresets",
    options: [],
  },
];

export const extraColumnOperators = [
  { value: "PERCENT_DIFFERENCE", label: "PERCENT_DIFFERENCE", count: 2 },
  {
    value: "PERCENT_DIFFERENCE_ONE_KEY",
    label: "PERCENT_DIFFERENCE_ONE_KEY",
    count: 1,
  },
  { value: "DIFFERENCE", label: "DIFFERENCE", count: 1 },
  {
    value: "DIFFERENCE_TO_TOTALS",
    label: "DIFFERENCE_TO_TOTALS (Value / Total)",
    count: 1,
  },
];

export const menuFilterDefaultPerPageValues = 1000;

export const secialDateFormats = [
  "[WEEK_RANGE]",
  "[MONTH_RANGE]",
  "[MONTH_YEAR]",
  "[MONTHLY]",
  "[WEEK_RANGE_TO_US_DATE]",
];

export const POWER_EDITOR_NO_VISUALIZATION_TEXT =
  "The current visualization does not exist. Please check your link.";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "emotion-theming";
import useConfirmDelete from "../../../hooks/useConfirmDelete";

export default function BlueprintInputDelete(props) {
  const { deletingInput, chainUuid, onBlueprintChainDelete } = props;

  const theme = useTheme();

  const confirm = useConfirmDelete(
    () => onBlueprintChainDelete(chainUuid),
    `Do you want to delete input ?`
  );

  if (deletingInput === chainUuid) {
    return (
      <FontAwesomeIcon
        icon={["fas", "spinner"]}
        pulse
        style={{
          marginRight: 13,
        }}
      />
    );
  }

  return (
    <>
      <FontAwesomeIcon
        icon={["fas", "trash-alt"]}
        style={{
          marginRight: 15,
          color: theme.notification.errorMain,
          cursor: "pointer",
        }}
        onClick={confirm.setConfirming}
      />
      {confirm.confirming}
    </>
  );
}

import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import RoutingItems from "./components/RoutingItems";

const Container = styled.div(
  ({ theme }) => `
  width: 200px;
  height: 100vh;
  background: ${lightenDarkenColor(theme.background.primary, -20)};
  z-index: 999;
  top: 0;
  position: fixed;
  left: 68px;
  padding: 24px 8px;
    @media (max-width: 1199px) {
    display: none;
  }
`
);

const routings = [
  {
    title: "Send an email",
    description: "Scheduled alerts",
    icon: ["fas", "envelope"],
    path: "/admin/automation/scheduled-emails",
  },
];

export default function LeftMenu() {
  return (
    <Container>
      {routings.map((route) => (
        <RoutingItems route={route} key={route.title} />
      ))}
    </Container>
  );
}

import { ErrorOption } from "react-hook-form/dist/types/errors";
import { FieldName, FieldValues } from "react-hook-form";

/**
 * Adds validations coming from the API to the react-hook-form.
 *
 * https://www.carlrippon.com/react-hook-form-server-validation/
 */
export function addServerErrors<T extends FieldValues>(
  errors: { [P in keyof T]?: string[] },
  setError: (fieldName: FieldName<T>, error: ErrorOption) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as FieldName<T>, {
      type: "server",
      message: errors[key as keyof T]!.join(". "),
    });
  });
}

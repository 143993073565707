import { CALCULATE_INSIGHTS_START } from "./actionTypes";
import produce from "immer";
import prepareSettings from "./dataSettings/prepareSettings";

export function calculateInsightsForQuery(
  queryUuid,
  querySettings,
  insightConfig
) {
  querySettings = prepareSettings(querySettings);
  return (dispatch) => {
    dispatch({
      type: CALCULATE_INSIGHTS_START,
      meta: {
        api: {
          method: "POST",
          endpoint: `api/v1/queries/${queryUuid}/exec-insights`,
          payload: {
            ...querySettings,
            insightConfig,
          },
          toastOnFailure: true,
        },
      },
    });
  };
}

export function calculateInsightsForData(data, insightConfig) {
  const { valueKey } = insightConfig;
  return async (dispatch) => {
    const transformedData = produce(data, (draft) => {
      for (const row of draft) {
        if (typeof row[valueKey] == "string") {
          // The Insights API endpoint does not like strings as values.
          row[valueKey] = Number(row[valueKey]);
        }
      }
    });

    dispatch({
      type: CALCULATE_INSIGHTS_START,
      meta: {
        api: {
          method: "POST",
          endpoint: "api/v1/insights/exec-custom",
          payload: {
            data: transformedData,
            ...insightConfig,
          },
          toastOnFailure: true,
        },
      },
    });
  };
}

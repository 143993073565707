import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Button = styled.div`
  cursor: pointer;
  &: hover {
    path {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

export default function ColumnForwardBack(props) {
  const { position, setPosition } = props;

  return (
    <div style={{ display: "flex" }}>
      <Button
        style={{ marginRight: 8 }}
        onClick={() => setPosition(position - 1)}
      >
        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
      </Button>
      <Button onClick={() => setPosition(position + 1)}>
        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
      </Button>
    </div>
  );
}

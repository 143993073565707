import { useSelector } from "react-redux";
import { useMemo } from "react";
import { uniqBy } from "lodash-es";

export function useValueOptionsByFieldNameFromMenuFilters() {
  const menuFilters = useSelector((state) => state.layout.menuFilters);

  return useMemo(() => {
    const ret = {};

    for (const menuFilter of menuFilters) {
      const fieldName = menuFilter.name;

      const valueOptions = menuFilter.values;

      if (!(fieldName in ret)) {
        ret[fieldName] = valueOptions;
      } else {
        ret[fieldName] = uniqBy(
          [...ret[fieldName], ...valueOptions],
          (option) => option.value
        );
      }
    }

    return Object.keys(ret).length ? ret : null;
  }, [menuFilters]);
}

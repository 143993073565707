import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flexCenter, inputBorders } from "../../../../styles/styledSnippets";

export default function AddFilter(props) {
  const Container = styled.div`
    display: inline-block;
    ${inputBorders}
    font-size: 14px;
    width: 200px;
    margin-top: 8px;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.2s linear opacity;

    &:hover {
      opacity: 1;
      border: 1px dashed ${(props) => props.theme.notification.infoMain};
    }

    svg {
      margin-left: 8px;
    }

    ${flexCenter}
  `;
  return (
    <Container onClick={props.onClick} data-cy="add-filter-button">
      Add Filter
      <FontAwesomeIcon icon={["fal", "plus"]} />
    </Container>
  );
}

import axiosInstance, { apiUrlObject } from "../axios";
import Echo from "laravel-echo";
import pusherClient from "pusher-js";
import { getToken } from "./getToken";

window.Pusher = pusherClient;

const authEndpoint = `${apiUrlObject.protocol}//${apiUrlObject.host}/broadcasting/auth`;

// Mutate-able options.
const laravelEchoOptions = {
  // This needs to be updated to 'pusher' if the pusher key is available before Laravel Echo
  // gets instantiated.
  broadcaster: "null",

  cluster: "eu",
  authEndpoint,
  auth: {
    headers: {
      Accept: "application/json",
    },
  },
};

let laravelEchoInstance;

function getLaravelEchoInstance() {
  if (!laravelEchoInstance) {
    const previousAxios = window.axios;
    // Silly laravel-echo relies on globals to register the request interceptor for X-Socket-ID.
    window.axios = axiosInstance;

    laravelEchoInstance = new Echo(laravelEchoOptions);

    window.axios = previousAxios;
  }

  return laravelEchoInstance;
}

// Lazy-loading wrapper around Laravel Echo.
export const laravelEchoWrapper = {
  options: laravelEchoOptions,

  /** Subscribe to a private channel you can subscribe to */
  private(channel) {
    // Set the latest token for auth.
    laravelEchoWrapper.options.auth.headers.Authorization = `Bearer ${getToken()}`;

    return getLaravelEchoInstance().private(channel);
  },

  leave(channel) {
    return getLaravelEchoInstance().leave(channel);
  },
};

export const TYPE_GROUPS = {
  string: "string",
  number: "number",
  date: "date",
};

export const buckets = {
  string: TYPE_GROUPS.string,
  text: TYPE_GROUPS.string,
  number: TYPE_GROUPS.number,
  decimal: TYPE_GROUPS.number,
  float: TYPE_GROUPS.number,
  integer: TYPE_GROUPS.number,
  currency: TYPE_GROUPS.number,
  date: TYPE_GROUPS.date,
  datetime: TYPE_GROUPS.date,
};

export function dataTypeAlignment(type) {
  const bucket = buckets[type];
  return bucket === "number" ? "right" : "left";
}

export const mappingTypes = {
  string: { lib: "fas", icon: "text-size", parameter: true },
  currency: { lib: "far", icon: "dollar-sign" },
  "currency-whole": { lib: "fal", icon: "dollar-sign" },
  decimal: { lib: "fas", icon: "calculator-alt" },
  percent: { lib: "fas", icon: "percent" },
  date: { lib: "fas", icon: "calendar-week", parameter: true },
  datetime: { lib: "fas", icon: "calendar-week" },
  integer: { lib: "far", icon: "list-ol" },
  boolean: { lib: "fas", icon: "toggle-on" },
};

export const typeNames = [
  "string",
  "currency",
  "currency-whole",
  "decimal",
  "percent",
  "date",
  "integer",
];

export const allowed = {
  [TYPE_GROUPS.string]: ["string"],
  [TYPE_GROUPS.number]: ["string", "currency", "decimal", "percent", "integer"],
  [TYPE_GROUPS.date]: ["string", "date"],
};

import React, { useRef, useState } from "react";
import Slider from "./Slider";
import formatter from "../../utils/formatters/formatter";
import styled from "@emotion/styled";
import { hexToRgba } from "../../styles/colorConvertor";
import FilledSlider from "./FilledSlider";
import PropTypes from "prop-types";
import useDebounceChange from "../../hooks/useDebounceChange";
import { clamp } from "lodash-es";

const Input = styled.input`
  width: 100%;
  position: absolute;
  top: -6px;
  z-index: 3;
  padding: 0;
  margin: 0;
  padding-inline: 0;
  opacity: 0;
  &::-webkit-slider-thumb {
    opacity: 0.5;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
`;

const Container = styled.div`
  position: relative;
`;

const size = 10;
function position(props) {
  return `calc(${props.percent}% - ${(12 * props.percent) / 100}px)`;
}

const Handle = styled.div`
  position: absolute;
  left: ${position};
  height: ${size}px;
  width: ${size}px;
  border-radius: 50%;
  top: -7px;
  border: 5px solid ${hexToRgba("#110000", 0.2)};
  background: ${(props) => props.background ?? props.theme.primary};
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
  box-sizing: content-box;
`;

const HandleContainer = styled.div`
  height: 20px;
  position: relative;
  //padding-right: 12px;
  width: calc(100%);
`;

const ValueContainer = styled.div`
  position: relative;
  height: 5px;
  top: -32px;
  left: ${({ clampLeft, clampRight }) =>
    clampLeft ? 12 : clampRight ? -28 : 0}px;
  pointer-events: all;
  div {
    font-size: 12px;
    input {
      display: inline-block;
      padding: 2px 8px;
      color: rgba(255, 255, 255, 0.6);
      border: none;
      border-radius: 2px;
      background: ${(props) =>
        props.background ?? hexToRgba(props.theme.primary, 1)};
      width: ${(props) => (props.length || 3) * 8}px;
      text-align: center;
    }
  }
  display: flex;
  justify-content: center;
`;

export default function ValueSlider(props) {
  const { value, format, onChange, min, max, background } = props;
  // use local value for debounce
  const [localValue, setLocalValue] = useState(value);
  const [showDisplayValue, setShowDisplayValue] = useState(true);
  const rangeRef = useRef(null);
  const formattedValue = formatter(localValue, format || "integer");
  const { debounce } = useDebounceChange();

  function handleChange(e) {
    const value = clamp(+e.target.value, min, max);
    setLocalValue(value);
    debounce(() => onChange(value));
  }

  function leftPos() {
    return ((localValue - min) / (max - min)) * 100;
  }

  const clampLeft = leftPos() < 10;
  const clampRight = leftPos() > 90;

  const step = max <= 1 ? 0.01 : 1;

  return (
    <Container data-cy="value-slider-container">
      <div style={{ position: "relative" }}>
        <Input
          type="range"
          min={min}
          max={max}
          value={localValue}
          step={step}
          onChange={handleChange}
        />
        <Slider rangeRef={rangeRef} />
        <FilledSlider percentFilled={leftPos()} background={background} />

        <HandleContainer>
          <Handle percent={leftPos()} background={background} max={max}>
            <ValueContainer
              data-cy="value-slider-value"
              length={formattedValue.length}
              clampLeft={clampLeft}
              clampRight={clampRight}
              background={background}
            >
              <div>
                <input
                  data-cy="value-slider-value"
                  type="text"
                  value={showDisplayValue ? formattedValue : localValue}
                  onChange={handleChange}
                  onFocus={() => setShowDisplayValue(false)}
                  onBlur={() => setShowDisplayValue(true)}
                />
              </div>
            </ValueContainer>
          </Handle>
        </HandleContainer>
      </div>
    </Container>
  );
}

ValueSlider.propTypes = {
  value: PropTypes.any,
  format: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};

ValueSlider.defaultProps = {
  value: 10,
  format: "number",
};

import React from "react";
import styled from "@emotion/styled";
import { scaleLinear } from "d3-scale";

const OuterFancyWeekContainer = styled.g`
  font-size: 9px;
  text-anchor: middle;
  fill: ${(props) => props.theme.text.secondary};
`;

export default function FancyWeekScale(props) {
  const { height, width } = props;
  const x = scaleLinear()
    .domain([1, 52])
    .range([0, width - 15]);
  const weeks = [...Array(52).keys()].map((v) => v + 1);
  return (
    <OuterFancyWeekContainer
      transform={`translate(0, ${height})`}
      data-cy="fancy-week-x-axis"
    >
      {weeks.map(
        (w) =>
          w % 6 === 1 && (
            <text
              key={w}
              transform={`translate(${x(w)} ${w % 2 === 0 ? 10 : -2}) rotate(${
                width < 440 ? -30 : 0
              })`}
            >
              Week {w}
            </text>
          )
      )}
    </OuterFancyWeekContainer>
  );
}

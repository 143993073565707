import React, { useMemo, useState, useEffect } from "react";
import GeoMap from "./GeoMap/GeoMap";
import { unique } from "../utils/func";
import { scaleOrdinal, scaleLinear } from "d3-scale";
import { interpolateRdYlGn } from "d3-scale-chromatic";
import { extent } from "d3-array";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import CircleLegend from "./GeoMap/CircleLegend";
import ModeSelector from "./GeoMap/ModeSelector";
import Loading from "../UI/Loading/Loading";
import Details from "../Layout/Details/Details";
import GradientKey from "./GeoMap/components/GradientKey";
import Refreshing from "./BaseChart/Refreshing";
import {
  loadCountiesCoordinates,
  loadStatesCoordinates,
} from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../UI/Flex/Flex";
import MultipleColorLegend from "./GeoMap/components/MultipleColorLegend";
import MapLoading from "./GeoMap/components/MapLoading";

const LegendContainer = styled.div`
  padding: 8px 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  color: ${(props) => props.theme.text.primary};
`;

function vp(pan, zoom) {
  return {
    center: pan || [38.8, -99.5795],
    zoom: zoom || 4,
    minZoom: 3,
  };
}

const choroplethTypesActions = {
  counties: loadCountiesCoordinates,
  states: loadStatesCoordinates,
};

export default function GeoMapVisualization(props) {
  const {
    data,
    sizeKey,
    latKey,
    lngKey,
    height,
    fullHeight,
    colorKey,
    colorLabel,
    tooltip,
    meta,
    visualizationId,
    loading,
    refreshing,
    mapViewMode = "circle",
    // colors = schemeCategory10,
    colorKeyOrder,
    pan,
    zoom,
    rMultiplier,
    dynamicRColor,
    noSwitch,
    legendMappings,
    maxCircleRadius,
    triangleShapeOptions,
    legendItems,
    isChoropleth,
    colorsConfig,
    dropMaxBounds,
    forceDomain,
    choroplethType,
    choroplethValueKey,
    countyKey,
    stateKey,
    exponentMultiplier,
  } = props.chart;

  const [mode, setMode] = useState(mapViewMode);
  const dispatch = useDispatch();
  const viewport = vp(pan, zoom);
  const geoJson = useSelector((state) => state.geoMap[choroplethType]);

  useEffect(() => {
    if (isChoropleth && !geoJson) {
      dispatch(choroplethTypesActions[choroplethType]());
    }
  }, [choroplethType, dispatch, geoJson, isChoropleth]);

  const uniqueColorMappings =
    colorKeyOrder ||
    unique(colorLabel ? [colorLabel] : data.map((d) => d[colorKey]));

  const uniqueColorResults = unique(data.map((d) => d[colorKey]));

  const setColors = () => {
    if (colorKeyOrder) {
      return scaleOrdinal().domain(uniqueColorMappings).range(colors);
    }

    if (colorsConfig) {
      const { colors, column, mode } = colorsConfig;

      if (mode === "multiple") {
        return interpolateRdYlGn;
      }

      const minMax = extent(data, (item) => item[column]);
      return scaleLinear()
        .domain(forceDomain ?? minMax)
        .range(colors);
    }

    return scaleOrdinal(colors);
  };

  const colorBand = setColors();

  const mappedData = useMemo(() => {
    if (isChoropleth) {
      return data;
    }

    return data
      .filter((d) => d[latKey])
      .map((d) => ({
        ...d,
        center: [d[latKey], d[lngKey]],
        lat: d[latKey],
        lng: d[lngKey],
      }));
  }, [data, isChoropleth, latKey, lngKey]);

  const chartHeight = fullHeight ? "calc(100vh - 153px)" : height + "px";

  if (loading) {
    return <MapLoading height={chartHeight} />;
  }

  return (
    <Flex
      id={visualizationId}
      height={chartHeight}
      position="relative"
      direction="column"
    >
      <Refreshing show={refreshing} comp={<Loading />} zIndex={1000} />
      <LegendContainer data-cy="legend-container">
        {dynamicRColor && <GradientKey colorsConfig={colorsConfig} />}

        {colorsConfig?.mode === "multiple" && (
          <MultipleColorLegend colorsConfig={colorsConfig} />
        )}

        {!isChoropleth && (
          <CircleLegend
            uniqueColorResults={uniqueColorResults}
            uniqueColorMappings={uniqueColorMappings}
            colorBand={colorBand}
            legendMappings={legendMappings}
            legendItems={legendItems}
          />
        )}

        {noSwitch ? null : <ModeSelector mode={mode} setMode={setMode} />}
      </LegendContainer>

      <GeoMap
        data={mappedData}
        height="100%"
        sizeKey={sizeKey}
        colorBand={colorBand}
        colorKey={colorKey}
        colorLabel={colorLabel}
        mode={mode}
        tooltip={tooltip}
        meta={meta}
        viewport={viewport}
        rMultiplier={rMultiplier}
        dynamicRColor={dynamicRColor}
        maxCircleRadius={maxCircleRadius}
        triangleShapeOptions={triangleShapeOptions}
        legendItems={legendItems}
        isChoropleth={isChoropleth}
        colorsConfig={colorsConfig}
        dropMaxBounds={dropMaxBounds}
        choroplethValueKey={choroplethValueKey}
        countyKey={countyKey}
        stateKey={stateKey}
        geoJson={geoJson}
        multiplier={colorsConfig?.multiplier}
        choroplethType={choroplethType}
        exponentMultiplier={exponentMultiplier}
      />
      {props.details ? (
        <Details
          dashboardName={props.dashboardId}
          visualizationId={props.chart.visualizationId}
        />
      ) : null}
    </Flex>
  );
}

GeoMapVisualization.propTypes = {
  chart: PropTypes.shape({
    mapViewMode: PropTypes.oneOf(["circle", "pin"]),
    data: PropTypes.array,
    sizeKey: PropTypes.string,
    latKey: PropTypes.string,
    lngKey: PropTypes.string,
    height: PropTypes.number,
    colorKey: PropTypes.string,
    colorLabel: PropTypes.string,
    tooltip: PropTypes.shape({
      title: PropTypes.shape({
        keys: PropTypes.arrayOf(PropTypes.string),
        label: PropTypes.string,
        separator: PropTypes.string,
      }),
      values: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string })),
      stringValues: PropTypes.arrayOf(
        PropTypes.shape({ key: PropTypes.string })
      ),
      drillDownDetails: PropTypes.object,
    }),
    noSwitch: PropTypes.bool,
    dynamicRColor: PropTypes.bool,
    rMultiplier: PropTypes.number,
    visualizationId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    refreshing: PropTypes.bool,
    isChoropleth: PropTypes.bool,
    colorsConfig: PropTypes.shape({
      colors: PropTypes.arrayOf(PropTypes.string),
      legendLeftLabel: PropTypes.string,
      legendRightLabel: PropTypes.string,
      column: PropTypes.string,
      multiplier: PropTypes.number,
      mode: PropTypes.string,
    }),
    dropMaxBounds: PropTypes.bool,
    forceDomain: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.arrayOf(PropTypes.string),
    choroplethType: PropTypes.string,
    choroplethValueKey: PropTypes.string,
    countyKey: PropTypes.string,
  }),
};

const colors = [
  "#e69d00",
  "#56b3e9",
  "#00c590",
  "#f0e442",
  "#0072b2",
  "#d55c00",
  "#cc79a7",
  "#000000",
  "#8d0f55",
  "#21671d",
  "#b0b0b0",
  "#cd3cff",
];

import styled from "@emotion/styled";
import getVisualizationLabel, {
  getMappingType,
} from "../../utils/getVisualizationLabel";
import { useMemo } from "react";
import formatter from "../../utils/formatters/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  padding: 50px 10px;
  font-size: 12px;
  position: relative;
  margin-left: 50px;
  border-left: 1px solid ${(props) => props.theme.divider};
`;

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 5px;
`;

const Item = styled.div(
  ({ theme }) => `
    padding: 10px;
    border-radius: 5px;
    background: ${theme.primary};
    margin-bottom: 10px;
    color: white;
    width: 220px;
    svg {
      margin-right: 8px;
      color: ${theme.notification.warningMain};
    }
`
);

export default function CountryDetails({ details, closeDetails, meta }) {
  const renderedDetails = useMemo(() => {
    if (!details) {
      return null;
    }

    return Object.keys(details).map((key, index) => {
      const label = getVisualizationLabel(meta.fields, key);
      const format = getMappingType(meta.fields, key);
      const value = formatter(details[key], format);
      return (
        <Item key={key}>
          <FontAwesomeIcon icon={["fas", "bookmark"]} />
          {label}: {value}
        </Item>
      );
    });
  }, [details, meta.fields]);

  if (!details) {
    return null;
  }

  return (
    <Container>
      <Title>Details</Title>
      {renderedDetails}
    </Container>
  );
}

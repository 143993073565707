import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import { debounce } from "lodash-es";
import { castToNumber } from "../../utils/func";
import SliderValues from "./SliderValues";
import Slider from "./Slider";
import ThreeDotLoader from "./ThreeDotLoader";

const Container = styled.div`
  padding: 10px;
  margin: 0 5px 0 10px;
  position: relative;
`;

const inputThumb = `
  border: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
`;

const InputRange = styled.input(
  ({ theme, left }) => `  
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  pointer-events: none;
  position: absolute;
  height: 0;
  width: 98%;
  outline: none;
  left: 0;

  z-index: ${left ? 3 : 4};

  &::-webkit-slider-thumb {
    background: ${theme.primary};
    ${inputThumb}
  }
  &:hover::-webkit-slider-thumb {
    background: ${lightenDarkenColor(theme.primary, -30)};
  }

  &:hover::-moz-range-thumb {
    background: ${lightenDarkenColor(theme.primary, -30)};
  }

  &::-moz-range-thumb {
    background: ${theme.primary};
    ${inputThumb}
  }
`
);

export default function MultiRangeSlider({
  onChange,
  multiRangeFilters,
  // type,
  format,
  min, // limit min
  max, // limit max
  checked,
}) {
  const [minValue, setMinValue] = useState(checked.min ?? min);
  const [maxValue, setMaxValue] = useState(checked.max ?? max);
  const [changing, setChanging] = useState(false);

  const minValRef = useRef(checked.min);
  const maxValRef = useRef(checked.max);
  const rangeRef = useRef(null);

  // width of range in percent to show active range colored
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [minValue, maxValue]
  );

  // send a request
  const debouncedChange = useMemo(
    () =>
      debounce((min, max, value) => {
        setChanging(false);
        onChange(min, max, value);
      }, 500),
    [onChange]
  );

  useEffect(() => {
    if (!multiRangeFilters) {
      // set remembered checked values
      const minimum = minValRef.current ?? checked?.min;
      const maximum = maxValRef.current ?? checked?.max;

      // setMinValue(minimum);
      // setMaxValue(maximum);
      const minPercent = getPercent(minimum);
      const maxPercent = getPercent(maximum);

      if (rangeRef.current) {
        rangeRef.current.style.left = `${minPercent}%`;
        rangeRef.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [getPercent, multiRangeFilters, minValRef, maxValRef]);

  const handleRangeChange = (minValue, maxValue) => {
    if (!isNaN(minValue) && !isNaN(maxValue)) {
      setChanging(true);
      minValRef.current = minValue;
      maxValRef.current = maxValue;
      debouncedChange(minValue, maxValue);
    }
  };

  return (
    <Container>
      <InputRange
        type="range"
        min={min}
        max={max}
        left={true}
        value={minValRef.current}
        onChange={(e) => {
          const value = Math.min(castToNumber(e.target.value), maxValue - 1);
          setMinValue(value);
          minValRef.current = value;
          handleRangeChange(value, maxValue);
        }}
        style={{ zIndex: minValue > maxValue - 2 && 5 }}
      />
      <InputRange
        type="range"
        min={min}
        max={max}
        value={maxValRef.current}
        onChange={(e) => {
          const value = Math.max(castToNumber(e.target.value), minValue + 1);
          setMaxValue(value);
          maxValRef.current = value;
          handleRangeChange(minValue, value);
        }}
      />
      <Slider rangeRef={rangeRef} />
      <SliderValues
        limitMin={min}
        limitMax={max}
        minValue={minValue}
        maxValue={maxValue}
        format={format}
        handleRangeChange={(nextMin, nextMax) => {
          setMinValue(nextMin);
          setMaxValue(nextMax);
          handleRangeChange(nextMin, nextMax);
        }}
      />
      {changing ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ThreeDotLoader />
        </div>
      ) : null}
    </Container>
  );
}

MultiRangeSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
};

MultiRangeSlider.defaultValues = {
  filter: { values: {} },
};

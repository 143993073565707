import { lightenDarkenColor } from "./colorConvertor";

export const customStyles = (theme) => ({
  option: (provided, state) => ({
    ...provided,
    color: theme.text.secondary,
    padding: 10,
    fontSize: "0.8em",
    borderBottom: "1px solid " + theme.divider,
    background: state.isSelected ? theme.primary : theme.background.primary,
    "&:hover": {
      background: lightenDarkenColor(theme.background.primary, 20),
      color: theme.text.primary,
    },
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    borderBottom: `1px solid ${theme.text.secondary}`,
    borderRadius: 0,
    "&:focus": {
      outline: "none",
      borderBottom: `1px solid ${theme.text.primary}`,
    },
    "&:hover": {
      borderColor: theme.text.primary,
    },
    boxShadow: "none",
    backgroundColor: "transparent",
    fontsize: "5px",
    width: "100%",
    margin: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.text.secondary,
    fontSize: "0.8em",
    marginLeft: 0,
    marginRight: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 5px",
  }),
  menu: (provided) => ({
    ...provided,
    background: theme.background.secondary,
    color: theme.text.secondary,
    textAlign: "left",
    marginTop: 0,
  }),
  input: (provided) => ({
    ...provided,
    color: theme.text.secondary,
  }),
});

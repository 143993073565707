import React from "react";
import BigInputItem from "./BigInputItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FoldersRows from "./FoldersRows";
import InListActionButtons from "./InListActionButtons";
import SmallButton from "./SmallButton/SmallButton";
import { cloneDeep } from "lodash-es";
import RightArea from "./RightArea";
import RightAreaOverrideBlock from "./RightAreaOverrideBlock";
import RightAreaColorBlock from "./RightAreaColorBlock";

export default function RightAreaBlock(props) {
  const {
    rightArray,
    setRightArray,
    tripleMode,
    folders,
    setFolders,
    active,
    setActive,
    handleRemove,
    handleAddFolder,
    folderMode,
    overrideMode,
    colorMode,
    colors,
    setColors,
    overrides,
    setOverrides,
    addOverride,
    leftData,
    scrollTop,
  } = props;

  function handleInputChange(e) {
    const nextFolders = cloneDeep(folders);
    nextFolders[active].label = e.target.value;
    setFolders(nextFolders);
  }

  function handleRightColumnClick(item) {
    if (tripleMode) {
      setActive(item);
    } else {
      setRightArray(item);
    }
  }

  function removeFromFolder(childIndex, folderIndex) {
    const nextFolders = cloneDeep(folders);
    const nextChildren = folders[folderIndex].children.filter(
      (v, i) => i !== childIndex
    );
    nextFolders[folderIndex].children = nextChildren;
    setFolders(nextFolders);
  }

  if (colorMode)
    return (
      <RightAreaColorBlock
        active={active}
        setActive={setActive}
        colors={colors}
        setColors={setColors}
      />
    );

  if (overrideMode) {
    return (
      <RightAreaOverrideBlock
        overrides={overrides}
        setOverrides={setOverrides}
        addOverride={addOverride}
        leftData={leftData}
        scrollTop={scrollTop}
      />
    );
  }

  return (
    <RightArea data-cy="input-right-area">
      {rightArray.map((item, index) => (
        <BigInputItem
          key={item.id + index}
          data-cy="item-destination-location"
          onClick={() => handleRightColumnClick(item)}
          active={active && item.id === active.id}
          noArrow
        >
          {!tripleMode ? (
            <FontAwesomeIcon icon={["fal", "arrow-left"]} />
          ) : null}
          {item.label}
        </BigInputItem>
      ))}

      <FoldersRows
        folders={folders}
        activeIndex={active}
        setActive={setActive}
        handleInputChange={handleInputChange}
        removeFromFolder={removeFromFolder}
      />

      {active || folderMode ? (
        <InListActionButtons>
          {active !== null ? (
            <SmallButton onClick={handleRemove} data-cy="area-remove-action">
              <FontAwesomeIcon icon={["fal", "minus"]} />
              Remove
            </SmallButton>
          ) : null}
          <SmallButton onClick={handleAddFolder} data-cy="area-add-action">
            <FontAwesomeIcon icon={["fal", "plus"]} />
            Add {folderMode ? "Group" : "Rule"}
          </SmallButton>
        </InListActionButtons>
      ) : null}
    </RightArea>
  );
}

import * as actionTypes from "../../actions/actionTypes";
import produce from "immer";

const initialState = {
  pages: [],
  loading: false,
  inScreenshotMode: false,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    function getIndexes(action) {
      const pageIndex = state.pages.findIndex(
        (p) => p.uuid === action.pageUuid
      );
      const blockIndex = state.pages[pageIndex].blocks.findIndex(
        (b) => b.uuid === action.blockUuid
      );
      return { pageIndex, blockIndex };
    }

    switch (action.type) {
      case actionTypes.GET_PAGES_START:
      case actionTypes.CREATE_PAGE_START:
      case actionTypes.UPDATE_PAGE_START:
      case actionTypes.UPDATE_VISUALIZATION_START:
      case actionTypes.DELETE_PAGE_START: {
        draft.loading = true;
        break;
      }

      case actionTypes.GET_PAGES_SUCCESS: {
        draft.pages = action.results;
        draft.loading = false;
        break;
      }

      case actionTypes.GET_PAGES_FAIL:
      case actionTypes.CREATE_PAGE_FAIL:
      case actionTypes.UPDATE_PAGE_FAIL:
      case actionTypes.DELETE_PAGE_FAIL: {
        draft.loading = false;
        break;
      }

      case actionTypes.DELETE_PAGE_SUCCESS: {
        draft.pages = state.pages.filter(
          (page) => page.uuid !== action.results.uuid
        );
        draft.loading = false;
        break;
      }
      case actionTypes.DELETE_PAGE_BLOCK_SUCCESS: {
        if (!action.pageUuid) {
          return;
        }
        const pageIndex = state.pages.findIndex(
          (p) => p.uuid === action.pageUuid
        );
        const pageBlocks = state.pages[pageIndex].blocks;
        draft.pages[pageIndex].blocks = pageBlocks.filter(
          (b) => b.uuid !== action.blockUuid
        );
        break;
      }

      case actionTypes.CREATE_PAGE_BLOCK_SUCCESS: {
        const pageIndex = state.pages.findIndex(
          (p) => p.uuid === action.pageUuid
        );

        draft.pages[pageIndex].blocks.push(action.results.data);
        break;
      }

      // @todo This is wrong
      case actionTypes.CREATE_PAGE_BLOCK_FAIL: {
        draft.errors = [action.error];
        break;
      }

      case actionTypes.SORT_BLOCKS_SUCCESS: {
        const pageIndex = state.pages.findIndex(
          (p) => p.uuid === action.pageUuid
        );
        const currentPage = state.pages[pageIndex];
        draft.pages[pageIndex].blocks = action.updatedBlockOrder.map((b) => {
          const sb = currentPage.blocks.find((sb) => sb.uuid === b.uuid);
          return { ...sb, sortOrder: sb.sortOrder };
        });
        draft.sortLoading = false;
        break;
      }

      case actionTypes.SORT_BLOCKS_START: {
        draft.sortLoading = true;
        break;
      }

      case actionTypes.CREATE_VISUALIZATION_SUCCESS:
      case actionTypes.UPDATE_VISUALIZATION_SUCCESS: {
        const { pageIndex, blockIndex } = getIndexes(action);
        const visualizationIndex = action.visualizationUuid
          ? state.pages[pageIndex].blocks[blockIndex].visualizations.findIndex(
              (v) => v.uuid === action.visualizationUuid
            )
          : -1;

        const isArrayExist = () => {
          return draft.pages[pageIndex].blocks[blockIndex].visualizations;
        };

        const addVisualization = () => {
          draft.pages[pageIndex].blocks[blockIndex].visualizations.push(
            action.results.data
          );
        };

        const createFirstVisualization = () => {
          draft.pages[pageIndex].blocks[blockIndex].visualizations = [
            action.results.data,
          ];
        };

        const updateVisualization = () => {
          draft.pages[pageIndex].blocks[blockIndex].visualizations[
            visualizationIndex
          ] = action.results.data;
        };

        if (visualizationIndex !== -1) {
          updateVisualization();
        } else {
          isArrayExist() ? addVisualization() : createFirstVisualization();
        }
        draft.loading = false;

        break;
      }

      case actionTypes.DELETE_VISUALIZATION_SUCCESS: {
        const { pageIndex, blockIndex } = getIndexes(action);
        const visualizations =
          draft.pages[pageIndex].blocks[blockIndex].visualizations;
        draft.pages[pageIndex].blocks[blockIndex].visualizations =
          draftVisualization();

        function draftVisualization() {
          return visualizations.filter(
            (vis) => vis.uuid !== action.visualizationUuid
          );
        }
        draft.loading = false;
        break;
      }

      case actionTypes.SET_FULL_PAGE_SCREENSHOT_MODE:
        return {
          ...state,
          inScreenshotMode: action.mode,
        };

      default:
        return draft;
    }
  });

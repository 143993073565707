import React from "react";
import ValueKeyOption from "./ValueKeyOption";
import styled from "@emotion/styled";

const ActionsPalettePosition = styled.div`
  position: absolute;
  top: -48px;
  right: -2px;
`;

const ActionsPalette = styled.div`
  background: ${(props) => props.theme.background.secondary};
  box-shadow: ${(props) => props.theme.general.shadow2dp};
  padding: 8px 22px 8px 14px;
  border-radius: 4px;
  box-sizing: border-box;
`;

export default function ChartActions(props) {
  const { variableValueKeys, setVariableValueKey, activeValueKey, show } =
    props;
  if (!show) return null;

  function handleSelect(valueKey) {
    if (valueKey.valueKey !== activeValueKey.valueKey) {
      setVariableValueKey(valueKey);
    }
  }

  return (
    <ActionsPalettePosition>
      <ActionsPalette>
        {variableValueKeys.map((valueKey) => (
          <ValueKeyOption
            label={valueKey.displayName}
            cy="value-key-option-select"
            key={valueKey.valueKey}
            active={activeValueKey?.valueKey === valueKey.valueKey}
            handleSelect={() => handleSelect(valueKey)}
          />
        ))}
      </ActionsPalette>
    </ActionsPalettePosition>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Row = styled.div`
  display: flex;
  svg {
    margin-right: 10px;
    position: relative;
    top: 1px;
    font-size: 16px;
    color: ${(props) => props.theme.notification.warningMain};
  }
`;

export default function ProductGroupingSummary(props) {
  const { values } = props;
  return (
    <div
      style={{
        marginTop: 24,
        fontWeight: 300,
        lineHeight: "190%",
        fontSize: 12,
      }}
    >
      {values
        .filter((v, i) => i < 6)
        .map((value) => (
          <Row key={value.label}>
            <FontAwesomeIcon icon={["fal", "folder"]} />
            {value.label}
          </Row>
        ))}
      {values.length > 5 ? (
        <div style={{ opacity: 0.5, fontSize: 12, marginTop: 12 }}>more...</div>
      ) : null}
    </div>
  );
}

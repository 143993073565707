import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tipper from "../../../../UI/Tipper/Tipper";

const Icon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
`;

export default function TableGlobalActionItem(props) {
  const { onClick, icon, tooltip, cy, hidden } = props;

  if (hidden) {
    return null;
  }

  return (
    <Tipper label={tooltip}>
      <Icon icon={icon} data-cy={cy} onClick={onClick} />
    </Tipper>
  );
}

import React from "react";
import BlueprintMap from "./BlueprintMap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { saveBlueprintInput } from "../../../store/actions";
import Loading from "../../../UI/Loading/Loading";
import { deleteBlueprintInput } from "../../../store/actions/dataSettings/dataSetup";
import { apiEndpoint } from "../../../utils/env";

export default function BlueprintMapHome({ setLoading, createBlueprint }) {
  // load individual values per table
  const {
    blueprints,
    dwTables,
    creating,
    isInitComplete,
    deletingInput,
    // etlBlueprints,
  } = useSelector((state) => state.dataSetup);
  const dispatch = useDispatch();

  function processRuleset(groupId) {
    dispatch(actions.processRuleset(groupId));
  }
  function handleSaveInput(groupId, table, field, closeCb, chainUuid) {
    dispatch(saveBlueprintInput(groupId, table, field, closeCb, chainUuid));
  }

  function handleDeleteInput(uuid) {
    dispatch(deleteBlueprintInput(uuid));
  }

  const isDevEnv =
    apiEndpoint?.includes("dev") ||
    apiEndpoint?.includes("cypress") ||
    apiEndpoint?.includes("example.com");

  if (!isDevEnv) {
    return <div>Access Denied</div>;
  }

  if (!isInitComplete) return <Loading text="Gathering Existing Blueprints" />;

  return (
    <>
      <BlueprintMap
        rulesets={blueprints}
        tables={dwTables}
        createBlueprint={createBlueprint}
        creating={creating}
        handleSaveInput={handleSaveInput}
        processRuleset={processRuleset}
        handleDeleteInput={handleDeleteInput}
        deletingInput={deletingInput}
      />

      {/* {(etlBlueprints ?? []).map((blueprint) => (
        <div onClick={() => dispatch(actions.deleteBlueprint(blueprint.uuid))}>
          {blueprint.uuid}
        </div>
      ))} */}
    </>
  );
}

import React from "react";
import Switcher from "../../../../UI/Switcher/Switcher";
import styled from "@emotion/styled";
import SectionLabel from "../DataExplorerUI/SectionLabel";
import { aggregationBuckets } from "../../../../utils/constants/constants";
import { buckets } from "../../../../utils/constants/dataTypes";

export const AggOption = styled.div`
  background: ${(props) => (props.active ? props.theme.primary : "none")};
  color: ${(props) => (props.active ? "white" : props.theme.text.secondary)};
  margin: 3px;
  padding: 2px 12px;
  border: 1px solid ${(props) => props.theme.divider};
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.active ? props.theme.primary : props.theme.hover};
  }

  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 0.4;
  `};
`;

const AggContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export default function BuilderAggregation(props) {
  const { aggregate, setAggregate, filterApi, position, matchedField } = props;

  const handleSelectAggregation = (val) => {
    filterApi.setAggregation(position, aggregations[val]);
  };

  const baseType = matchedField.mapping.type;

  const aggregationOptionsArray = aggregationOptions(baseType);
  const showAggregations = aggregate && aggregationOptionsArray;
  const aggregationType = filterApi.overrides[position]?.aggregation?.type;

  function active(a) {
    // const stringType = isNaN(a); // Failed
    return aggregationType === aggregations[a] || aggregationType === a;
  }

  function getDisabled(aggregation) {
    return !!filterApi.overrides.find(
      (override) =>
        override.aggregation?.type === aggregation &&
        matchedField.name === override.name
    );
  }

  return (
    <div style={{ marginTop: 24 }}>
      <SectionLabel>Aggregate this field?</SectionLabel>
      <Switcher
        leftLabel="no"
        rightLabel="yes"
        checked={aggregate}
        handleSwitchChange={() => setAggregate((agg) => !agg)}
        disabled={!matchedField}
        cy="show-aggregation-switch"
      />
      {showAggregations ? (
        <AggContainer>
          {aggregationOptionsArray.map((a) => (
            <AggOption
              data-cy="builder-aggregation-option"
              key={a}
              data-active={active(a) ? "1" : ""}
              active={active(a)}
              onClick={() => handleSelectAggregation(a)}
              disabled={getDisabled(a)}
            >
              {a}
            </AggOption>
          ))}
        </AggContainer>
      ) : null}
    </div>
  );
}

const aggregationOptions = (type) => {
  return aggregationBuckets[buckets[type]];
};

const aggregations = {
  SUM: 11,
  MAX: 1,
  AVG: 2,
  COUNT: 3,
  MIN: 4,
  YEAR: 6,
  QUARTER: 7,
  MONTH: 8,
  WEEK: 9,
  DAY: 10,
};

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Link = styled.span(
  ({ italic, theme, underline }) => `
    white-space: nowrap;
    font-style: ${italic ? "italic" : "regular"};
    padding: 2px 5px;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-decoration: ${underline ? "underline" : "none"};
    &:hover {
        text-decoration: underline;
        color: ${theme.text.primary};
    }
`
);

/**
 * Span that looks like a link, but acts like a button.
 */
export default function ButtonLink(props) {
  return (
    <Link {...props} data-cy={props.cy}>
      {props.children}
    </Link>
  );
}
ButtonLink.propTypes = {
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]),
};

import React from "react";
import styled from "@emotion/styled";
import { cloneDeep } from "lodash-es";
import Select from "../../../UI/Form/Select/Select";
import {
  aggregations,
  formats,
  orderTypes,
} from "../../../utils/constants/constants";
import Input from "../../../UI/Form/Input/Input";

const Block = styled.div`
  display: flex;
  flex-direction: column;
  input {
    font-size: 12px;
  }
  & > div {
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div:first-of-type {
    width: 30%;
  }

  & > div:last-of-type {
    width: 90%;
    padding: 0 20px;
  }
`;

const Wrapper = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    font-size: 12px;
    border: 1px solid ${theme.divider};
    border-radius: 5px;
    margin: 15px 2% 0px 0px;
    padding: 5px;
    & > span {
        display: block;
        padding: 5px;
        color: ${theme.primary || theme.text.primary};
    }
`
);

export default function RowFieldConfig({ field, state, setState, fields }) {
  const updateOverrideField = (updatedField) => {
    const fields = state.chart.overrides.map((o) =>
      o.dataSourceFieldUuid === updatedField.dataSourceFieldUuid
        ? updatedField
        : o
    );

    setState({
      ...state,
      fields: fields,
      chart: {
        ...state.chart,
        overrides: fields,
      },
    });
  };

  const setOrder = (field, option) => {
    const index = state.chart.orders.findIndex((or) => or.name === field.name);
    const cloneOrder = cloneDeep(state.chart.orders);

    if (!option) {
      setState({
        ...state,
        chart: {
          ...state.chart,
          orders: cloneOrder.filter((co) => co.name !== field.name),
        },
      });
      return;
    }

    if (index !== -1) {
      cloneOrder[index].sort = option.value;
    } else {
      cloneOrder.push({ name: field.name, sort: option.value });
    }

    setState({
      ...state,
      chart: { ...state.chart, orders: cloneOrder },
    });
  };

  return (
    <Wrapper>
      <span>{field.name}</span>
      <Container>
        <Block>
          <Select
            options={aggregations}
            placeholder="Aggregations"
            onChange={(o) =>
              updateOverrideField({ ...field, aggregation: o.value })
            }
            value={aggregations.find(
              (aggr) => field.aggregation === aggr.value
            )}
            label="Aggregation"
          />
          <Select
            options={formats}
            placeholder="Formats"
            onChange={(o) =>
              updateOverrideField({
                ...field,
                mapping: { ...field.mapping, type: o.value },
              })
            }
            value={formats.find(
              (format) => field.mapping?.type === format.value
            )}
            label="Format"
          />
          <Select
            options={orderTypes}
            onChange={(o) => setOrder(field, o)}
            label="Order"
            isClearable={true}
            placeholder="Orders"
          />
          <Select
            options={fields}
            getOptionValue={(o) => o.dataSourceFieldUuid}
            getOptionLabel={(o) => o.name}
            label="Grouping Key"
            onChange={(o) =>
              setState({
                ...state,
                chart: { ...state.chart, groupingKey: o.name },
              })
            }
          />
          <Select
            options={fields}
            getOptionValue={(o) => o.dataSourceFieldUuid}
            getOptionLabel={(o) => o.name}
            label="Row Group Key"
            onChange={(o) =>
              setState({
                ...state,
                chart: { ...state.chart, rowGroupKey: o.name },
              })
            }
          />
          <Input
            placeholder="Display Name"
            value={field.mapping.displayName}
            onChange={(e) =>
              updateOverrideField({
                ...field,
                mapping: { ...field.mapping, displayName: e.target.value },
              })
            }
            label="Display Name"
          />
        </Block>
      </Container>
    </Wrapper>
  );
}

import React, { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "../../../UI/Form/Select/Select";
import { orderBy } from "lodash-es";

export default function WriteBacksCrmFieldMenuFilter({
  writeBackOptions,
  inputProps,
  fieldName,
  control,
  updateValue,
}) {
  const values = useSelector((state) =>
    state.layout.menuFilters
      .find((v) => v.uuid === writeBackOptions.menuFilterUuid)
      ?.values.map((v) => v.value)
  );

  const value = useWatch({
    control,
    name: fieldName,
  });

  const options = useMemo(() => {
    const withSortField = values.map((value) => ({
      value,
      label: value,
      sortField: (value || "").toString().toLowerCase(),
    }));

    return orderBy(withSortField, "sortField", "asc");
  }, [values]);

  useEffect(() => {
    if (!value && values.length === 1 && value !== values[0]) {
      updateValue(values[0]);
    }
  }, [updateValue, value, values]);

  return (
    <div>
      <Select
        simpleValue={value}
        options={options}
        label={inputProps.label}
        required={inputProps.required}
        tooltip={inputProps.tooltip}
        onChange={({ value }) => updateValue(value)}
        isDisabled={value && values.length === 1}
      />
    </div>
  );
}

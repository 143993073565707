import React, { useState } from "react";
import PropTypes from "prop-types";
import TipperTip from "./TipperTip";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export default function Tipper({ label, click, noBg, children, cy, ...rest }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [elementClientRect, setElementClientRect] = useState(null);
  const ref = React.useRef();
  const ignoreRef = React.useRef(null);

  const toggleShow = () => {
    return showTooltip ? handleHide() : handleShow();
  };

  useOnClickOutside(
    ref,
    () => {
      click && handleHide();
    },
    ignoreRef
  );

  const handleShow = () => {
    // Force a synchronous layout update
    ref.current.firstChild.getBoundingClientRect();

    const elementClientRect = ref.current.firstChild.getBoundingClientRect();
    setShowTooltip(true);
    setElementClientRect(elementClientRect);
  };

  const handleHide = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div
        ref={ref}
        style={{ position: "relative" }}
        onMouseEnter={!click ? handleShow : undefined}
        onMouseLeave={!click ? handleHide : undefined}
        onClick={click ? toggleShow : undefined}
        data-cy="tipper-wrapper"
      >
        {children}
      </div>

      <TipperTip
        showTooltip={showTooltip}
        label={label}
        noBg={noBg}
        elementClientRect={elementClientRect}
        data-cy={cy || rest["data-cy"]}
        {...rest}
      />
    </>
  );
}

Tipper.propTypes = {
  label: PropTypes.any,
  click: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  cy: PropTypes.string,
};

import styled from "@emotion/styled";
import Loading from "../../UI/Loading/Loading";
import { POWER_EDITOR_NO_VISUALIZATION_TEXT } from "../../utils/constants/constants";

const VisualizationNotExist = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
`;

export default function TableEditorFallback({
  chartState,
  queriesReady,
  pages,
  page,
  children,
}) {
  if (pages && !page) {
    return (
      <VisualizationNotExist data-cy="visualization-not-exist-container">
        {POWER_EDITOR_NO_VISUALIZATION_TEXT}
      </VisualizationNotExist>
    );
  } else if (!chartState || !queriesReady) {
    return <Loading />;
  }

  return children;
}

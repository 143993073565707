import styled from "@emotion/styled";
import { memo } from "react";
import Table from "../../UI/Tables/Table/Table";
import ChartContainer from "../BaseChart/ChartContainer";
import Headers from "./Headers";
import Rows from "./Rows";
import { getDataBySearchText } from "./utils";

const WarningMessage = styled.div(
  ({ theme }) => `
  background: ${theme.notification.warningMain};
  padding: 5px;
  font-size: 12px;
  margin: 10px;
  color: #fff;
  border-radius: 4px;
`
);

export default memo(function Watchlist(props) {
  const {
    data = [],
    meta = {},
    loading,
    refreshing,
    restricted,
    error,
    fixedHeight,
    columns,
    processing,
    searchText,
    confirm,
    watchlist,
    watchlistConfig,
    getColumnTextAlign,
    noResultsMessageOverride,
  } = props;

  const withAdditionalIcons = ["", ...columns, ""];

  if (!watchlist) {
    return (
      <WarningMessage>
        You are not currently watching any {watchlistConfig.anchorText} items.
        Click to start watching...
      </WarningMessage>
    );
  }

  return (
    <ChartContainer
      autoHeight
      hasData={!!data.length}
      loading={loading}
      refreshing={refreshing || processing}
      error={error}
      restricted={restricted}
      fixedHeight={fixedHeight}
      noResultsMessageOverride={noResultsMessageOverride}
    >
      <Table borderSpacing="0 5px" borderCollapse="separate">
        <Headers
          columns={withAdditionalIcons}
          meta={meta}
          getColumnTextAlign={getColumnTextAlign}
        />
        <Rows
          rows={getDataBySearchText(data, columns, searchText)}
          keys={columns}
          confirm={confirm}
          getColumnTextAlign={getColumnTextAlign}
        />
      </Table>
    </ChartContainer>
  );
});

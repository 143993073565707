import { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { ARROW_WIDTH, getPositions } from "./tipperBubblePosition";

const MARGIN_FROM_ELEMENT = 2;

const BubbleContainer = styled.div(
  ({ theme, shouldWrap, width, noBg, elementClientRect }) => {
    const background =
      theme.type === "light" ? theme.gray.gray800 : theme.gray.gray100;
    const color =
      theme.type === "light" ? theme.gray.gray100 : theme.gray.gray800;

    return `
      position: absolute;
      bottom: ${
        window.innerHeight -
        elementClientRect.top +
        ARROW_WIDTH +
        MARGIN_FROM_ELEMENT
      }px;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      border-radius: 0.125rem;

      ${getPositions(elementClientRect, width).bubble}

      background: ${noBg ? "transparent" : background};
      white-space: ${shouldWrap ? "normal" : "nowrap"};
      color: ${color};
      max-width: 200px;
      ${shouldWrap ? `width: 200px;` : ""}

      &:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        bottom: -${ARROW_WIDTH}px;
        border-left:${ARROW_WIDTH}px solid transparent;
        border-right: ${ARROW_WIDTH}px solid transparent;
        border-top:8px solid ${noBg ? theme.primary : background};
        ${getPositions(elementClientRect, width).arrow}
      }
    `;
  }
);

export default function TipperBubble({
  label,
  noBg,
  elementClientRect,
  ...rest
}) {
  const [shouldWrap, setShouldWrap] = useState(false);
  const [width, setWidth] = useState(0);
  const containerRef = useRef();

  useLayoutEffect(() => {
    const element = containerRef.current;
    if (containerRef.current) {
      // setTimeout is needed here to be sure that getBoundingClientRect return correct values
      setTimeout(() => {
        const clientRect = element.getBoundingClientRect();
        setShouldWrap(clientRect.width > 200);
        setWidth(clientRect.width);
      });
    }
  }, []);

  return (
    <BubbleContainer
      ref={containerRef}
      noBg={noBg}
      shouldWrap={shouldWrap}
      width={width}
      elementClientRect={elementClientRect}
      {...rest}
    >
      {label}
    </BubbleContainer>
  );
}

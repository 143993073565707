import styled from "@emotion/styled";
import Table from "../../UI/Tables/Table/Table";
import { useCallback } from "react";
import Checkbox from "../../UI/Form/Checkbox/Checkbox";
import Flex from "../../UI/Flex/Flex";

const Container = styled.div`
  display: flex;
  width: 50%;
  padding: 5px;
  margin-bottom: 20px;
`;

const Td = styled.td`
  text-align: right !important;
  font-weight: ${(props) => props.fontWeight};
`;

export default function SummaryByPages({
  mapSettingsToConfigurations,
  executedCharts,
  visualizationQuantity,
  setFilteredPages,
  filteredPages,
}) {
  const getVisualizationQuantity = useCallback((blocks) => {
    return blocks.map((block) => block.charts).flat().length;
  }, []);

  const totalExecTime = Object.values(executedCharts).reduce((acc, curr) => {
    acc += curr.duration;
    return Math.round(acc * 100) / 100;
  }, 0);

  const getPageExecTime = useCallback(
    (blocks) => {
      return blocks.reduce((acc, curr) => {
        curr.charts.forEach((chart) => {
          if (executedCharts[chart.visualizationId]) {
            acc += executedCharts[chart.visualizationId].duration;
          }
        });

        return Math.round(acc * 100) / 100;
      }, 0);
    },
    [executedCharts]
  );

  const formatTime = (blocks) => {
    const time = getPageExecTime(blocks);
    return time ? time + "s" : "--";
  };

  const excludePage = (uuid) => {
    const pages = filteredPages.includes(uuid)
      ? filteredPages.filter((pageUuid) => uuid !== pageUuid)
      : [...filteredPages, uuid];
    setFilteredPages(pages);
  };

  const allIncluded = mapSettingsToConfigurations.every((page) =>
    filteredPages.includes(page.uuid)
  );

  const includeAllPages = () => {
    if (filteredPages.length) {
      return setFilteredPages([]);
    }

    setFilteredPages(mapSettingsToConfigurations.map((page) => page.uuid));
  };

  return (
    <Container>
      <Table border>
        <thead>
          <tr>
            <th>
              <Flex alignItems="center">
                Include All
                <div style={{ marginBottom: 20 }}>
                  <Checkbox checked={allIncluded} onChange={includeAllPages} />
                </div>
              </Flex>
            </th>
            <th>Page</th>
            <th>Chart Count</th>
            <th>Total Time</th>
          </tr>
        </thead>
        <tbody>
          {mapSettingsToConfigurations.map((page) => (
            <tr key={page.uuid}>
              <td>
                <div style={{ marginBottom: 20 }}>
                  <Checkbox
                    checked={filteredPages.includes(page.uuid)}
                    onChange={() => excludePage(page.uuid)}
                  />
                </div>
              </td>
              <td>{page.displayName}</td>
              <Td>{getVisualizationQuantity(page.blocks)}</Td>
              <Td>{formatTime(page.blocks)}</Td>
            </tr>
          ))}
          <tr>
            <td />
            <td>Total</td>
            <Td fontWeight={500}>{visualizationQuantity}</Td>
            <Td fontWeight={500}>{totalExecTime}s</Td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

import { useState } from "react";

export default (setShowOptions) => {
  const [activePosition, setActivePosition] = useState(null);
  let timer;

  function handleShow(attemptedPosition, args) {
    if (activePosition !== attemptedPosition) {
      setShowOptions(attemptedPosition, args);
    }
    clearTimeout(timer);
    return setActivePosition(attemptedPosition);
  }

  function handleHide() {
    timer = setTimeout(() => {
      setActivePosition(null);
      setShowOptions(null);
    }, 200);
  }

  function handleStay() {
    return clearTimeout(timer);
  }

  return {
    triggerShow: handleShow,
    triggerHide: handleHide,
    triggerStay: handleStay,
  };
};

import styled from "@emotion/styled";
import getVisualizationLabel from "../../utils/getVisualizationLabel";

const THead = styled.thead(
  ({ sticky, theme }) => `
  ${
    sticky &&
    `
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 1;
    background: ${theme.background.primary};
  `
  }
`
);

const Th = styled.th(
  ({ align }) => `
  text-align: ${align} !important;
`
);

export default function Headers({
  columns = [],
  meta,
  sticky,
  getColumnTextAlign,
}) {
  if (!columns.length) {
    return null;
  }

  return (
    <THead sticky={sticky}>
      <tr>
        {columns.map((column, index) => (
          <Th key={column + index} align={getColumnTextAlign(column)}>
            {getVisualizationLabel(meta.fields, column)}
          </Th>
        ))}
      </tr>
    </THead>
  );
}

import styled from "@emotion/styled";
import Flex from "../../../../../UI/Flex/Flex";
import RadioButton from "../../../../../UI/Form/RadioButton/RadioButton";
import FormulaColumn from "./FormulaColumn";
import RegularColumn from "./RegularColumn";
import OpenMathFormulaColumn from "./OpenMathFormulaColumn";

const RadioButtonContainer = styled.div`
  margin: 10px 0px;
`;

const RadioButtonLabel = styled.label`
  margin-top: 5px;
  cursor: pointer;
`;

export default function Settings(props) {
  return (
    <>
      <span>Column mode</span>
      <RadioButtonContainer>
        <Flex>
          <RadioButton
            label="Normal"
            checked={!props.formulaType && !props.openMathFormulaType}
            onChange={props.switchColumnType}
            cy="option-column-normal"
          />
          <RadioButtonLabel>Normal</RadioButtonLabel>
        </Flex>
        <Flex>
          <RadioButton
            label="Formula"
            checked={props.formulaType}
            onChange={props.switchColumnType}
            cy="option-column-formula"
          />
          <RadioButtonLabel>Formula</RadioButtonLabel>
        </Flex>
        <Flex>
          <RadioButton
            label="Open Math Formula"
            checked={props.openMathFormulaType}
            onChange={props.switchToOpenMathColumnType}
            cy="option-column-open-math"
          />
          <RadioButtonLabel>Open Math Formula</RadioButtonLabel>
        </Flex>
      </RadioButtonContainer>

      {props.formulaType ? (
        <FormulaColumn {...props} />
      ) : props.openMathFormulaType ? (
        <OpenMathFormulaColumn {...props} />
      ) : (
        <RegularColumn {...props} />
      )}

      <hr
        color={props.theme.background.secondary}
        style={{ margin: "10px 0px" }}
      />
    </>
  );
}

import Flex from "../../../UI/Flex/Flex";
import ColumnsSelectionSidebar from "./ColumnsSelectionSidebar";
import ColumnSelectionListItem from "./ColumnSelectionListItem";
import SelectionListItemIcon from "./SelectionListItemIcon";
import { ColumnsList, ColumnListItem } from "./styles";

export default function GrouppedColumnsSelection({
  isOpened,
  meta,
  allGroups,
  hiddenGroupings,
  allColumns,
  hiddenColumns,
  onClose,
  hiddenGroupsChange,
  hiddenColumnsChange,
}) {
  const groupList = allGroups.map((header, index) => {
    const isHeaderHidden = hiddenGroupings.includes(header);
    const indexOverride =
      allColumns.length - 1 < index ? allColumns.length - 1 : index;

    return {
      hidden: isHeaderHidden,
      name: header,
      columns:
        allColumns
          .at(indexOverride)
          ?.map((column) => ({
            hidden: isHeaderHidden || hiddenColumns.includes(column),
            name: column,
          }))
          .filter((column) => !!column.name) ?? [],
    };
  });

  const onSelectGroup = (group, hide) => {
    if (hide) {
      hiddenGroupsChange([...hiddenGroupings, group.name]);
      hiddenColumnsChange([
        ...hiddenColumns,
        ...group.columns.map((column) => column.name),
      ]);
      return;
    }

    hiddenGroupsChange(
      hiddenGroupings.filter((hiddenGroup) => hiddenGroup !== group.name)
    );
    hiddenColumnsChange(
      hiddenColumns.filter(
        (hiddenColumn) =>
          !group.columns.find((column) => column.name === hiddenColumn)
      )
    );
  };

  const onSelectColumn = (column, hide, groupIndex) => {
    const group = groupList.at(groupIndex);

    if (hide) {
      // if the last column has left as visible
      // and it should be hidden
      if (group.columns.filter((column) => !column.hidden).length === 1) {
        hiddenGroupsChange([...hiddenGroupings, group.name]);
      }

      return hiddenColumnsChange([...hiddenColumns, column.name]);
    }

    hiddenGroupsChange(
      hiddenGroupings.filter((hiddenGroup) => hiddenGroup !== group.name)
    );
    hiddenColumnsChange(
      hiddenColumns.filter((hiddenColumn) => hiddenColumn !== column.name)
    );
  };

  const onClearGroups = () => {
    hiddenGroupsChange(allGroups.slice());
    hiddenColumnsChange(allColumns.flat());
  };

  const onFullQueryGroups = () => {
    hiddenGroupsChange([]);
    hiddenColumnsChange([]);
  };

  return (
    <ColumnsSelectionSidebar
      isOpened={isOpened}
      onClose={onClose}
      onClearClick={onClearGroups}
      onFullQueryClick={onFullQueryGroups}
    >
      {groupList?.map((group, groupIndex) => (
        // we use index to make unique key for empty group name
        <Flex
          key={group.name + `-${groupIndex}`}
          direction="column"
          gap="1.5rem"
        >
          <ColumnsList>
            <ColumnListItem
              data-cy="selection-group-item"
              onClick={() => onSelectGroup(group, !group.hidden)}
            >
              <SelectionListItemIcon hidden={group.hidden} />
              {group.name || "Group"}
            </ColumnListItem>
            {!!group.columns.length && (
              <ColumnsList paddingLeft="1rem">
                {group.columns.map((column) => (
                  <ColumnSelectionListItem
                    key={column.name}
                    column={column}
                    metaFields={meta?.fields}
                    onItemClick={(item, hide) =>
                      onSelectColumn(item, hide, groupIndex)
                    }
                  />
                ))}
              </ColumnsList>
            )}
          </ColumnsList>
        </Flex>
      ))}
    </ColumnsSelectionSidebar>
  );
}

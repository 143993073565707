/**
 * Source: https://stackoverflow.com/a/48161723/1381550
 * @param {string} message
 * @returns {Promise<string>}
 */
export async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new window.TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

/**
 * Hashes the input passed to a API query exec call for use as a cache key.
 * @param apiObject
 * @param loadBundleId
 * @returns {Promise<string>}
 */
export async function hashQueryExecCall(apiObject, loadBundleId) {
  const { endpoint, method, payload } = apiObject;
  if (!(endpoint && method && (payload || method === "GET"))) {
    throw new Error("Missing properties in apiObject");
  }
  if (!loadBundleId) {
    throw new Error("Missing loadBundleId");
  }

  const string = `${endpoint}-${method}-${JSON.stringify(
    payload
  )}-${loadBundleId}`;

  return sha256(string);
}

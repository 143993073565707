import { lightenDarkenColor } from "./colorConvertor";

export const scrollbar = (props) => `
    &::-webkit-scrollbar {
        height: .8em;
        width: .8em;
    }
   
    &::-webkit-scrollbar-track {
      background: ${(props) =>
        props.theme.type === "dark"
          ? props.theme.background.primary
          : "rgba(255, 255, 255, .2)"};
      border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        border: .2em solid ${props.theme.background.primary};
        transition: .8s linear border;
        background-color: ${
          props.theme.type === "dark"
            ? "rgba(255, 255, 255, 0.15)"
            : props.theme.gray.gray400
        };
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        border: 0;
        background-color: ${
          props.theme.type === "dark"
            ? lightenDarkenColor(props.theme.notification.infoMain, -30)
            : props.theme.gray.gray500
        };
    }
`;

export const scrollbarConfig =
  (config = {}) =>
  (props) =>
    `
    &::-webkit-scrollbar {
        height: ${config.size || ".8em"};
        width: ${config.size || ".8em"};
    }
   
    &::-webkit-scrollbar-track {
      background: ${config.background || `rgba(255, 255, 255, 0.2)`};
      border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        border: ${config.thumbBorder === false ? 0 : ".2em"} solid ${
      props.theme.type === "dark" && config.lightBorderDarkTheme
        ? themeGray(300)
        : props.theme.background[
            config.thumbBorderSecondary ? "secondary" : "primary"
          ]
    };
        transition: .8s linear border;
        background-color: ${
          config.color
            ? config.color
            : props.theme.type === "dark"
            ? "rgba(255, 255, 255, 0.15)"
            : props.theme.gray.gray400
        };
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        border: ${config.hoverBorder || "0"};
        background-color: ${
          props.theme.type === "dark"
            ? lightenDarkenColor(props.theme.notification.infoDark, -30)
            : props.theme.gray.gray500
        };
    }
`;

export const scrollbarDe = (theme) => scrollbar({ theme });

const screenColor = (type) =>
  type === "dark" ? "rgba(255, 255, 255, 0.12)" : "rgba(0,0,0,0.14)";

const hoverColor = (type) =>
  type === "dark" ? "rgba(255, 255, 255, 0.55)" : "rgba(0,0,0,0.35)";

export const inputBorders = (props) => `
  padding: 4px 12px;
  box-sizing: border-box;
  background: transparent;
  border-radius: 4px;
  height: 38px;
  color: ${props.theme.text.secondary};
  border: 1px solid ${screenColor(props.theme.type)};
    &:hover {
      border: 1px solid ${hoverColor(props.theme.type)};      
    }
`;

export const inputFill = (props) => `
  path {
    fill: ${screenColor(props.theme.type)};
  }
  &: hover path {
    fill: ${hoverColor(props.theme.type)};
  }
`;

export const inputMenu = (props) => `
  ${inputBorders(props)}
  &:hover {
    border: 1px solid ${screenColor(props.theme.type)};
  }
  box-shadow: ${(props) => props.theme.general.shadow1dp};
  z-index: 20;
  background: ${props.theme.background.primary};
  height: auto;
  padding: 2px 0;
  font-size: 12px;
  &>div {
    padding: 6px 18px;
    border-bottom: 1px solid ${screenColor(props.theme.type)}
  }
`;

export const flexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const themeGray = (value) => (props) => props.theme.gray[`gray${value}`];
export const themeColor = (value) => (props) => props.theme.notification[value];
export const themeBackground = (value) => (props) =>
  props.theme.background[value];
export const themeText = (value) => (props) => props.theme.text[value];
export const themeShadow = (value) => (props) =>
  props.theme.general[`shadow${value}dp`];

export const themeGrayIf = (valueDark, valueLight) => (props) =>
  props.theme.gray[
    `gray${props.theme.type === "dark" ? valueDark : valueLight}`
  ];

import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Container = styled.div`
  color: ${(props) => props.theme.notification.errorMain};
  text-transform: uppercase;
  font-size: 12px;
  margin: 10px 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 44px;
    margin-right: 8px;
  }
  ${(props) =>
    props.bg
      ? `
    border: 1px solid ${props.theme.notification.errorMain};
    padding: 12px 36px;
    border-radius: 2px;
    background: ${props.theme.notification.errorMain};
    color: white;
  `
      : null}
`;

export default function Error(props) {
  return (
    <Container data-cy={props.cy} bg={props.bg}>
      {props.icon ? (
        <FontAwesomeIcon icon={["fal", "exclamation-circle"]} />
      ) : null}
      {props.children || props.text}
    </Container>
  );
}

Error.defaultProps = {
  cy: null,
};

Error.propTypes = {
  icon: PropTypes.bool,
  bg: PropTypes.bool,
  text: PropTypes.string,
  cy: PropTypes.string,
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProductSynonymSummary(props) {
  const { values } = props;
  const allSynonyms = Object.keys(values);
  return (
    <div
      style={{
        marginTop: 24,
        fontWeight: 300,
        lineHeight: "190%",
        fontSize: 12,
      }}
    >
      {allSynonyms.map((v, i) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={i}
        >
          <div>{v}</div>
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: 12, padding: "0 12px" }}>
              <FontAwesomeIcon icon={["fal", "arrow-right"]} />
            </div>
            <div>({values[v].length})</div>
          </div>
        </div>
      ))}
    </div>
  );
}

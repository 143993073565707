import Flex from "../../../UI/Flex/Flex";
import Loading from "../../../UI/Loading/Loading";

export default function MapLoading({ height = "100%" }) {
  return (
    <Flex height={height} alignItems="center" justifyContent="center">
      <div>
        <Loading />
        <div>Accessing Geo Coordinates from Central Hub...</div>
      </div>
    </Flex>
  );
}

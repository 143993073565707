import { groupBy } from "lodash-es";

export default [
  {
    value: "regular",
    title: "This is a Regular attribute",
    copy: "This is a regular value",
  },
  {
    value: "date",
    title: "This is a Date field",
    copy: "This is a value that indicates a date",
  },
  {
    value: "metric",
    title: "This is a Metric field",
    copy: "This is a value that can be used as a filter",
  },
  {
    value: "unique",
    title: "This is a unique ID",
    copy: "This is an identifier that can be used across different data sets",
  },
];

export const doMerge = (
  blocks,
  visualizationId,
  data,
  cacheKey,
  meta,
  error
) => {
  const con = blocks.find((b) =>
    b.charts.find((q) => q.visualizationId === visualizationId)
  );

  if (!con) {
    return;
  }

  return {
    data,
    loading: false,
    refreshing: false,
    loaded: true,
    meta,
    cacheKey,
    error,
  };
};

// Use for Sales Funnel Category Chart only
export const sortByLabel = (arr, key, forward = true) => {
  return arr.sort((a, b) => {
    const yearA = +a[key].substring(3, 7);
    const yearB = +b[key].substring(3, 7);
    const quarterA = +a[key].substring(1, 2);
    const quarterB = +b[key].substring(1, 2);
    if (yearA < yearB) return forward ? 1 : -1;
    if (yearA > yearB) return forward ? -1 : 1;
    if (quarterA < quarterB) return forward ? 1 : -1;
    if (quarterA > quarterB) return forward ? -1 : 1;
    return 0;
  });
};

// this funrtion convetring table data to drilldown without sending request on row expand
export const buildLocalRowExpandGroupedData = (data, chart, meta) => {
  const { rowExpandVisualizationParams, localRowExpandGrouping } = chart;
  // Group data by the specified grouping key
  const grouped = groupBy(data, localRowExpandGrouping);

  const innerVisId = rowExpandVisualizationParams?.visualizationId;

  const rowExpandedCharts = {};

  // Iterate over grouped data to build mainData and rowExpandedCharts
  const mainData = Object.values(grouped).reduce((acc, curr) => {
    const [parent, ...rest] = curr;

    // key example "1-row-visualization-7154a67e-60d7-47aa-a4da-2079746da693"
    const key = parent[localRowExpandGrouping] + "-" + innerVisId;

    rowExpandedCharts[key] = {
      ...chart,
      ...(rowExpandVisualizationParams ?? {}),
      loading: false,
      loaded: true,
      data: rest.length ? rest : [parent],
      meta,
      refreshing: false,
    };

    acc.push(parent);

    return acc;
  }, []);

  return {
    mainData, // summary data
    rowExpandedCharts, // all drilldown rows data
  };
};

import * as actionTypes from "./actionTypes";
import queryBuilder from "./queryBuilder/queryBuilder";
import * as queryString from "qs";
import axios from "../../axios";

export const loadQueryMonitoringList = () => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_LIST_START,
    meta: {
      api: {
        method: "GET",
        endpoint: "api/v1/monitoring/reports",
      },
    },
  });
};

export const createQueryMonitoring = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_CREATE_START,
    meta: {
      api: {
        method: "POST",
        endpoint: "api/v1/monitoring/reports",
        payload: data,
      },
    },
  });
};

export const deleteQueryMonitoring = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_DELETE_SALESFORCE_REPORT_START,
    meta: {
      api: {
        method: "DELETE",
        endpoint: "api/v1/monitoring/reports/" + uuid,
        payload: {
          uuid: uuid,
        },
      },
    },
  });
};

export const getSalesforceQueryReports = (connectionUuid) => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_GET_SALESFORCE_REPORTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint:
          "api/v1/monitoring/reports/get_salesforce_reports/" + connectionUuid,
      },
    },
  });
};

export const getSalesforceReportColumns =
  (connectionUuid, reportId) => (dispatch) => {
    dispatch({
      type: actionTypes.MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_START,
      meta: {
        api: {
          method: "GET",
          endpoint:
            "api/v1/monitoring/reports/get_salesforce_report_columns/" +
            connectionUuid +
            "/" +
            reportId,
        },
      },
    });
  };

let createdMonitor;
export const executeCharts =
  (charts, loadingType, pages, createMonitor = false) =>
  async (dispatch, getState) => {
    const [chart, ...remaining] = charts;
    const isDrilldown = loadingType === "drilldownLoading";

    // on run we need to create a monitor-runs report
    // next recursive call should not create new monitor-runs report
    if (!createMonitor) {
      createdMonitor = await axios.post("/api/v1/visualizations/monitor-runs");
    }

    // Retrieve the page UUID to pass to the query builder and eliminate unnecessary filters.
    const { uuid } =
      pages.find((page) =>
        page.blocks.some((block) =>
          block.charts.some((ch) => ch.uuid === chart.uuid)
        )
      ) ?? {};

    const qs = queryBuilder(chart, getState, false, "chart", undefined, {
      uuid,
    });

    try {
      dispatch({
        type: actionTypes.CHARTS_MONITORING_START,
        loadingType,
        isLast: !remaining.length,
      });

      const res = await axios({
        method: chart.convertGetToPost ? "POST" : "GET",
        url: `api/v1/queries/${chart["queryId"]}/exec?${
          chart.convertGetToPost ? "" : qs
        }`,
        ...(chart.convertGetToPost && {
          // for the nested array parsing we need set depth as option
          data: queryString.parse(qs, { depth: 10 }),
        }),
      });

      const duration = convertMillisecondsToSecods(res.config.duration);

      dispatch({
        type: actionTypes.CHARTS_MONITORING_SUCCESS,
        isLast: !remaining.length,
        loadingType,
        visualizationId: chart.visualizationId,
        results: { data: res.data, duration },
      });

      // use recently created monitor uuid to add duration and chart results
      if (createdMonitor) {
        await axios.post(
          `/api/v1/visualizations/monitor-runs/${createdMonitor.data.data.uuid}/results`,
          {
            durationSeconds: duration,
            visualizationUuid: chart.uuid,
            isDrilldown,
            success: true,
          }
        );
      }
    } catch (err) {
      const duration = convertMillisecondsToSecods(err.duration);
      dispatch({
        type: actionTypes.CHARTS_MONITORING_FAIL,
        duration,
        visualizationId: chart.visualizationId,
      });

      // use recently created monitor uuid to add duration and chart results (fails also)
      if (createdMonitor) {
        await axios.post(
          `/api/v1/visualizations/monitor-runs/${createdMonitor.data.data.uuid}/results`,
          {
            durationSeconds: duration,
            visualizationUuid: chart.visualizationId,
            isDrilldown,
            success: false,
          }
        );
      }
    }

    if (remaining.length) {
      dispatch(executeCharts(remaining, loadingType, pages, true));
    } else {
      await axios.put(
        `/api/v1/visualizations/monitor-runs/${createdMonitor.data.data.uuid}`,
        {
          completed: true,
        }
      );
    }
  };

export const getMonitoringHistory =
  (page = 1, minDate, maxDate) =>
  async (dispatch) => {
    const params = new URLSearchParams({
      perPage: 15,
      page,
      ...(minDate &&
        maxDate && {
          minDate,
          maxDate,
        }),
    });

    dispatch({
      type: actionTypes.GET_CHARTS_MONITORING_HISTORY_START,
      meta: {
        api: {
          method: "GET",
          endpoint: `/api/v1/visualizations/monitor-runs?${params.toString()}`,
        },
      },
    });
  };

function convertMillisecondsToSecods(time) {
  return time / 1000;
}

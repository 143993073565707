import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import Input from "../../../UI/Form/Input/Input";
import SplitBy from "../components/SplitBy";

export default function ActiveTableSettings(props) {
  const {
    name,
    setName,
    displaySettings,
    setDefaultViewFlag,
    columns,
    setViewSplitBy,
  } = props;

  const { isDefaultView, splitBy } = displaySettings;

  return (
    <div>
      <Input
        label="View Name"
        style={{ width: "50%" }}
        inPlaceEdit
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <Checkbox
        label="Default View"
        style={{ marginLeft: 0 }}
        onChange={() => setDefaultViewFlag()}
        checked={!!isDefaultView}
      />
      <div style={{ marginBottom: 40 }} />
      <SplitBy
        columns={columns}
        handleSplit={(option) => setViewSplitBy(option.value)}
        splitBy={splitBy}
        label="Split By"
      />
    </div>
  );
}

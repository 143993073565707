import styled from "@emotion/styled";
import Flex from "../../../../UI/Flex/Flex";
import React, { useState } from "react";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import CollapsableMenu from "./CollapsableMenu";
import FormatOverrideActions from "./FormatOverrideActions";
import FormatOverrideGroupedRow from "./FormatOverrideGroupedRow";
import AddFormatOverride from "./AddFormatOverride";

const List = styled.div(
  ({ theme }) => `
  overflow-y: auto;
  height: calc(100vh - 380px);
  ${scrollbarDe(theme)};

  .format-override-collapse {
    position: absolute;
    right: 3%;
    top: 38px;
    cursor: pointer;
  }

  .format-override-remove {
    position: absolute;
    right: 7%;
    top: 38px;
    color: ${theme.notification.errorMain};
    cursor: pointer;
  }
`
);

export default function ActiveFormatOverrides(props) {
  const [collapseIndex, setCollapseIndex] = useState(null);

  const {
    groupedFormatOverrides,
    conditionKeyOptions,
    onOverrideItemChange,
    onOverrideItemRemove,
    onOverrideItemAdd,
    disabled,
    pair,
  } = props;

  function showMoreOptions(index) {
    setCollapseIndex(index === collapseIndex ? null : index);
  }

  return (
    <List>
      <br />
      {groupedFormatOverrides.map((override, index) => (
        <React.Fragment key={override.overrideKey + index}>
          <Flex position="relative" mb2>
            <FormatOverrideGroupedRow
              conditionKeyOptions={conditionKeyOptions}
              override={override}
              onOverrideItemChange={onOverrideItemChange}
              pair={pair}
            />

            <FormatOverrideActions
              collapseIndex={collapseIndex}
              index={index}
              showMoreOptions={showMoreOptions}
              onOverrideItemRemove={onOverrideItemRemove}
              override={override}
            />
          </Flex>

          <CollapsableMenu
            index={index}
            collapseIndex={collapseIndex}
            override={override}
            onOverrideItemChange={onOverrideItemChange}
          />
          <br />
        </React.Fragment>
      ))}
      <br />
      <AddFormatOverride
        onOverrideItemAdd={onOverrideItemAdd}
        disabled={disabled}
      />
    </List>
  );
}

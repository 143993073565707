import styled from "@emotion/styled";
import RadioButton from "../../../../../../UI/RadioButton/RadioButton";
import { useMemo } from "react";
import { omit } from "lodash-es";
import Checkbox from "../../../../../../UI/Form/Checkbox/Checkbox";
import ActionButton from "../../../../../../UI/Form/ActionButton/ActionButton";
import Flex from "../../../../../../UI/Flex/Flex";
import { Note } from "../../../General/LimitRows/LimitRows";
import ColorsBasedOnDependencyColumn from "./ColorsBasedOnDependencyColumn";
import ValueColorPair from "./ValueColorPair";
import ColorPicker from "../../../../../../UI/ColorPicker/ColorPicker";

const Container = styled.div`
  margin: 20px 0px;
`;

const Block = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: space-between;
  & > div {
    width: fit-content;
  }

  & > svg {
    cursor: pointer;
    color: ${(props) => props.theme.notification.errorDark};
  }
`;

export default function CustomTextColor(props) {
  const {
    chart,
    selectedColumn,
    setChartState,
    theme,
    previewChart,
    formulaType,
  } = props;
  const { enumColorsSettings } = chart;

  const setting = enumColorsSettings?.find(
    (settings) => settings.column === selectedColumn.column
  );

  const {
    column,
    conditionalColumn,
    values,
    color,
    useForColumn,
    asTextColor,
    asBackground,
    asHeaderBackground,
    ...rest
  } = setting ?? {};

  // we have column: color values
  const dynamicColumnKeys = Object.keys(rest);

  // column data options
  const dynamicValueOptions = useMemo(
    () =>
      previewChart?.data.map((item) => ({
        label: item[column],
        value: item[column],
      })),
    [column, previewChart?.data]
  );

  const changeColor = (color, key) => {
    setChartState({
      ...chart,
      enumColorsSettings: chart.enumColorsSettings.map((settings) =>
        settings.column === column
          ? { ...settings, ...(key ? { [key]: color } : { color }) }
          : settings
      ),
    });
  };

  const setUseForColumn = () => {
    setChartState({
      ...chart,
      enumColorsSettings: chart.enumColorsSettings.map((settings) =>
        settings.column === column
          ? {
              ...settings,
              useForColumn: !settings.useForColumn,
              asTextColor: false,
              asBackground: false,
              asHeaderBackground: false,
              conditionalColumn: null,
              values: [],
            }
          : settings
      ),
    });
  };

  const setSetting = (key) => {
    setChartState({
      ...chart,
      enumColorsSettings: chart.enumColorsSettings.map((settings) =>
        settings.column === column
          ? {
              ...settings,
              [key]: !settings[key],
              conditionalColumn: null,
              values: [],
            }
          : settings
      ),
    });
  };

  const addNewValueColorPair = () => {
    setChartState({
      ...chart,
      enumColorsSettings: chart.enumColorsSettings.map((settings) => {
        if (settings.column === column) {
          return {
            ...settings,
            ["value" + dynamicColumnKeys.length + 1]: theme.primary,
          };
        }

        return settings;
      }),
    });
  };

  const addNewColorSetting = () => {
    if (!setting) {
      setChartState({
        ...chart,
        enumColorsSettings: [
          ...(enumColorsSettings || []),
          { column: selectedColumn.column },
        ],
      });
    }
  };

  const changeDynamicValue = (value, key) => {
    setChartState({
      ...chart,
      enumColorsSettings: chart.enumColorsSettings.map((setting) => {
        if (setting.column === column) {
          const copy = { ...setting };
          delete Object.assign(copy, { [value]: copy[key] })[key];
          return copy;
        }
        return setting;
      }),
    });
  };

  const removeDynamicValue = (key) => {
    setChartState({
      ...chart,
      enumColorsSettings: chart.enumColorsSettings.map((setting) => {
        if (setting.column === column) {
          return omit(setting, key);
        }
        return setting;
      }),
    });
  };

  const isDynamic = !formulaType && !useForColumn;

  return (
    <>
      <RadioButton
        name="Customize Text Color"
        checked={column === selectedColumn.column}
        value={column === selectedColumn.column}
        onChange={addNewColorSetting}
        size={17}
        offset={5}
      />
      {column === selectedColumn.column && (
        <Container>
          <Block align="end">
            <ColorPicker
              initialColor={color}
              onChangeComplete={changeColor}
              isDisabled={!useForColumn}
              label="Pick Color"
            />

            <div>
              <Checkbox
                checked={!!useForColumn}
                label="Use On Entry Column "
                onChange={setUseForColumn}
              />
              {useForColumn && (
                <>
                  <Checkbox
                    checked={!!asTextColor}
                    label="Text"
                    onChange={() => setSetting("asTextColor")}
                  />

                  <Checkbox
                    checked={!!asBackground}
                    label="Background"
                    onChange={() => setSetting("asBackground")}
                  />

                  <Checkbox
                    checked={!!asHeaderBackground}
                    label="Header Background"
                    onChange={() => setSetting("asHeaderBackground")}
                  />
                </>
              )}
            </div>
          </Block>

          {formulaType && (
            <Note color="warningMain">
              Dynamic coloring is not available for formula type columns
            </Note>
          )}
          {isDynamic && (
            <>
              <br />
              {!conditionalColumn && (
                <Flex alignItems="center" mt2 mb2>
                  <span>Colors Based on Value</span>

                  <ActionButton
                    icon={["fal", "plus-circle"]}
                    style={{ marginLeft: 10 }}
                    onClick={addNewValueColorPair}
                  />
                </Flex>
              )}
              {dynamicColumnKeys.map((key) => (
                <ValueColorPair
                  options={dynamicValueOptions}
                  item={{ value: key, color: rest[key] }}
                  index={key}
                  key={key}
                  onSelectChange={changeDynamicValue}
                  onInputChange={changeColor}
                  onRemove={removeDynamicValue}
                  colorOnly
                />
              ))}
            </>
          )}

          <ColorsBasedOnDependencyColumn
            {...props}
            useForColumn={!!useForColumn}
          />
        </Container>
      )}

      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
    </>
  );
}

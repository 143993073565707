import React from "react";
import { typeNames } from "../../../../utils/constants/dataTypes";
import { getRidOfAggregation } from "../EditableMenu";
import Select from "../../../../UI/Form/Select/Select";

function Type({ visualization, setVisualization, column }) {
  const clearColumn = getRidOfAggregation(column, visualization.overrides);

  const override = visualization.overrides.find(
    (override) => override.name === clearColumn
  );

  const changeType = ({ value }) => {
    setVisualization((prev) => {
      return {
        ...prev,
        overrides: prev.overrides.map((override) =>
          override.name === clearColumn
            ? {
                ...override,
                mapping: { ...override.mapping, type: value },
              }
            : override
        ),
      };
    });
  };

  const options = typeNames.map((type) => ({ label: type, value: type }));

  return (
    <>
      <Select
        options={options}
        value={options.find((o) => o.value === override?.mapping?.type)}
        menuHeight={130}
        onChange={changeType}
        label="Format"
        height="33px"
      />
    </>
  );
}

export default Type;

export default function (comparisonMode, dashboardId, nonStrict) {
  if (!comparisonMode || !dashboardId) {
    return false;
  }

  const comparisonModeItem = getComparisonModeItem(comparisonMode, dashboardId);

  if (!comparisonModeItem || !comparisonModeOnPage()) {
    return false;
  }

  if (!comparisonModeItem.checked) {
    return true;
  } else if (nonStrict) {
    return comparisonModeItem.checked <= comparisonModeItem.count;
  } else if (!Number.isFinite(comparisonModeItem.count)) {
    return !comparisonModeItem.checked;
  } else {
    return comparisonModeItem.checked < comparisonModeItem.count;
  }

  function comparisonModeOnPage() {
    if (!comparisonModeItem.pages) return true;
    return !!comparisonModeItem.pages.find((page) => page === dashboardId);
  }
}

export function getComparisonModeItem(comparisonMode, tabUuid) {
  return (comparisonMode ?? []).find((item) =>
    item.pages?.find((page) => page === tabUuid)
  );
}

import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default function TechnicalMessage({ message }) {
  return (
    <Container>
      <span>{message}</span>
    </Container>
  );
}

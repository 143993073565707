import React from "react";
import styled from "@emotion/styled";

const bg = {
  dark: "gray900",
  light: "gray300",
};

const ExplorerShelfContainer = styled.div`
  width: calc(100% - 68px);
  position: fixed;
  bottom: 0;
  left: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  height: 42px;
  background: ${(props) => props.theme.gray[bg[props.theme.type]]};
  color: ${(props) => props.theme.text.secondary};
  font-size: 14px;
  box-sizing: border-box;
  user-select: none;
`;

export default function Shelf(props) {
  return (
    <ExplorerShelfContainer data-cy={props.cy}>
      {props.children}
    </ExplorerShelfContainer>
  );
}

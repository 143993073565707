import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { tableConfigTabs } from "../../../utils/constants/constants";
import * as actions from "../../../store/actions/index";
import IOButton from "../../../UI/Form/Button/IOButton";
import Modal from "../../../UI/Modal/Modal";
import Tabs from "../../../UI/Tabs/Tabs";
import RowFieldConfig from "./RowFieldConfig";
import JsonConfiguration from "./JsonConfiguration";
import GeneralSettings from "./GeneralSettings";
import Subtitles from "./Subtitles";
import CalculatedRows from "./CalculatedRows";
import CalculatedNAColumns from "./CalculatedNAColumns";
import FormatOverrides from "./FormatOverrides";
import RollupAndFixedRows from "./RollupAndFixedRows";
import { Route } from "../../../utils/router";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.gray.gray600};
  flex-grow: 1;
  font-size: 1.25em;
  font-weight: bold;
`;

export default function TableConfig() {
  const dispatch = useDispatch();
  const { queries } = useSelector((state) => state.dataSettings);
  const [state, setState] = useState({
    query: null,
    fields: [],
    chart: {},
  });

  const [jsonConfig, setJsonConfig] = useState(null);

  useEffect(() => {
    dispatch(actions.listQueries());
  }, [dispatch]);

  const removeNewLines = (prop) => {
    if (!prop?.length) return [];
    return prop.join(",").replaceAll("\n", "").split(",");
  };

  const fillFixedRows = () => {
    const arr = [];

    state.chart.fixedRows.forEach((fr) => {
      arr.push({ type: fr.type, label: fr.label, color: fr.color });
      fr.values.forEach((v) => {
        arr.push({ type: v.type, value: v.value });
      });
    });

    return arr;
  };

  const logJsonConfig = () => {
    const { chart } = {
      ...state,
      chart: {
        ...state.chart,
        overrides: state.chart.overrides.map(
          ({ dataSourceFieldUuid, ...rest }) => rest
        ),
        subTitles: state.chart.subTitles.map((subTitle) =>
          removeNewLines(subTitle)
        ),
        staticGroupingKeys: removeNewLines(state.chart.staticGroupingKeys),
        hideOnFilters: removeNewLines(state.chart.hideOnFilters),
        calculatedRows: state.chart.calculatedRows.map((cr) =>
          cr.subTitles.map((subTitle) => removeNewLines(subTitle))
        ),
        fixedRows: state.chart.fixedRows ? fillFixedRows() : [],
      },
    };

    dispatch(actions.loadTableChartPreview(chart));
    setJsonConfig(chart);
  };

  const addSubtitle = () => {
    const newSubtitle = [...state.chart.subTitles];
    newSubtitle.push([""]);
    setState({ ...state, chart: { ...state.chart, subTitles: newSubtitle } });
  };

  const onSubtitleChange = ({ value }, index) => {
    const subTitles = [...state.chart.subTitles];
    subTitles[index] = value.split(",");

    setState({ ...state, chart: { ...state.chart, subTitles } });
  };

  const deleteSubtitle = (index) => {
    setState({
      ...state,
      chart: {
        ...state.chart,
        subTitles: state.chart.subTitles.filter((_, i) => i !== index),
      },
    });
  };

  const fieldsOptions =
    state.query?.dataSources.map((ds) => ds.fields).flat() || [];
  const hasOverrides = !!state.chart?.overrides?.length;

  return (
    <Container>
      <Header>
        <Title>Table Config</Title>
        <IOButton disabled={!hasOverrides} onClick={logJsonConfig}>
          Preview
        </IOButton>
      </Header>

      <Tabs tabs={tableConfigTabs} />

      <Switch>
        <Route path={"/admin/charts/table-config/general"}>
          <GeneralSettings
            showSettings={hasOverrides}
            state={state}
            setState={setState}
            queries={queries}
            fieldsOptions={fieldsOptions}
          />
        </Route>

        <Route path={"/admin/charts/table-config/fields"}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {state.fields.map((f) => (
              <React.Fragment key={f.dataSourceFieldUuid}>
                <RowFieldConfig
                  field={f}
                  state={state}
                  setState={setState}
                  fields={fieldsOptions}
                />
              </React.Fragment>
            ))}
          </div>
        </Route>

        <Route path={"/admin/charts/table-config/subtitles"}>
          <Subtitles
            subTitles={state.chart?.subTitles}
            disabled={!hasOverrides}
            addSubtitle={addSubtitle}
            onSubtitleChange={onSubtitleChange}
            deleteSubtitle={deleteSubtitle}
          />
        </Route>

        <Route path={"/admin/charts/table-config/rows"}>
          <CalculatedRows
            state={state}
            setState={setState}
            disabled={!hasOverrides}
          />
        </Route>

        <Route path={"/admin/charts/table-config/columns"}>
          <CalculatedNAColumns
            state={state}
            setState={setState}
            fields={fieldsOptions}
          />
        </Route>

        <Route path={"/admin/charts/table-config/overrides"}>
          <FormatOverrides
            state={state}
            setState={setState}
            fields={fieldsOptions}
          />
        </Route>

        <Route path={"/admin/charts/table-config/rollup"}>
          <RollupAndFixedRows
            state={state}
            setState={setState}
            fields={fieldsOptions}
          />
        </Route>

        <Redirect to={"/admin/charts/table-config/general"} />
      </Switch>

      {jsonConfig && (
        <Modal title={"Preview"} close={() => setJsonConfig(null)}>
          <JsonConfiguration config={jsonConfig} setConfig={setJsonConfig} />
        </Modal>
      )}
    </Container>
  );
}

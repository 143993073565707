import { useEffect, useState } from "react";
import produce from "immer";
import useToggleArray from "../../../../../hooks/useToggleArray";
import { dateRangeOperators } from "../../DataExplorerUI/ExplorerInputs/FieldOperatorList";
import { isInteger } from "lodash-es";
import convertFiltersToOperatorsMode from "../../../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import usePrevious from "../../../../../utils/usePrevious";
import isEqual from "react-fast-compare";
import { omit } from "lodash-es";
import { AGGREGATION_SUM } from "../../../../../utils/constants/constants";

const applyConversion = (filters, doConversion) =>
  doConversion ? convertFiltersToOperatorsMode(filters) : filters;

export function useFilters(initialFilters = [], doConversion = false) {
  const initialFiltersState = applyConversion(initialFilters, doConversion);
  const [draftFilters, setDraftFilters] = useState(initialFiltersState);
  const [muted, setMuted] = useToggleArray();
  const previousFilters = usePrevious(initialFilters);

  useEffect(() => {
    if (!isEqual(initialFilters, previousFilters)) {
      setDraftFilters(applyConversion(initialFilters, doConversion));
    }
  }, [doConversion, initialFilters, previousFilters]);

  const addFilter = () => {
    return setDraftFilters((filters) => [
      ...filters,
      { name: null, values: [] },
    ]);
  };

  const setField = (position, field) => {
    if (Array.isArray(draftFilters) && draftFilters.length) {
      validatePosition(position);
    }
    const nextState = produce(draftFilters, (draftState) => {
      draftState[position] = {
        name: field,
        operator: null,
        values: [],
      };
    });
    return setDraftFilters(nextState);
  };

  function updateSubQueryItem(filter, key, value) {
    // Check if the filter object has a subQueryValues property
    if (filter.subQueryValues) {
      // If it does, update the value of the specified key in subQueryValues
      filter.subQueryValues[key] = value;
    } else {
      // Otherwise, update the value of the specified key in the filter object directly
      filter[key] = value;
    }
  }

  const setOperator = (position, operator) => {
    validatePosition(position);
    const nextState = produce(draftFilters, (draftState) => {
      const filter = draftState[position];
      // change operator of sub query if exist
      updateSubQueryItem(filter, "operator", operator);

      if (dateRangeOperators.includes(operator)) {
        draftState[position].values = [null, null];
      }
    });
    return setDraftFilters(nextState);
  };

  const setValues = (position, values) => {
    validatePosition(position);
    const nextState = produce(draftFilters, (draftState) => {
      const filter = draftState[position];
      // change value of sub query if exist
      updateSubQueryItem(filter, "values", values);
    });
    return setDraftFilters(nextState);
  };

  const removeRow = (position) => {
    validatePosition(position);
    return setDraftFilters(draftFilters.filter((s, i) => i !== position));
  };

  function isMuted(index) {
    return muted.find((m) => m === index) !== undefined;
  }

  function validatePosition(position) {
    if (!isInteger(position) || !draftFilters[position]) {
      throw new Error("Parameter 1 must be the filter index");
    }
  }

  const invalidFilters = draftFilters.filter(
    (f) =>
      !f.name || !f.operator || !f.values?.length || notZeroNumber(f.values[0])
  );

  // const apiFilters

  function setAggregating(index) {
    const filters = draftFilters.map((filter, i) => {
      if (i === index) {
        return filter.subQueryValues
          ? omit(filter, "subQueryValues")
          : {
              name: "",
              subQueryValues: {
                ...filter,
                aggregation: { type: AGGREGATION_SUM },
              },
            };
      }
      return filter;
    });
    return setDraftFilters(filters);
  }

  function setAggregatedGroupBy(index, field) {
    const filters = draftFilters.map((filter, i) =>
      i === index ? { ...filter, name: field } : filter
    );
    return setDraftFilters(filters);
  }

  return {
    draftFilters,
    setDraftFilters,
    addFilter,
    setField,
    setOperator,
    setValues,
    removeRow,
    muted,
    setMuted,
    isMuted,
    invalidFilters,
    // apiFilters
    setAggregating,
    setAggregatedGroupBy,
  };
}

function notZeroNumber(val) {
  return !val && val !== 0;
}

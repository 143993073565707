import ColumnsSelectionSidebar from "./ColumnsSelectionSidebar";
import ColumnSelectionListItem from "./ColumnSelectionListItem";
import { ColumnsList } from "./styles";
import { useCallback } from "react";

export default function SimpleColumnsSelection({
  isOpened,
  allColumns,
  hiddenColumns,
  meta,
  onClose,
  hiddenColumnsChange,
}) {
  const columnsList = allColumns.flat().map((column) => ({
    hidden: hiddenColumns.includes(column),
    name: column,
  }));

  const onSelectColumn = useCallback(
    (column, hidden) => {
      if (hidden) {
        return hiddenColumnsChange([...hiddenColumns, column.name]);
      }

      return hiddenColumnsChange(
        hiddenColumns.filter((hiddenColumn) => hiddenColumn !== column.name)
      );
    },
    [hiddenColumns, hiddenColumnsChange]
  );

  const onClearColumns = () => {
    hiddenColumnsChange(allColumns.flat());
  };

  const onFullQueryColumns = () => {
    hiddenColumnsChange([]);
  };

  return (
    <ColumnsSelectionSidebar
      isOpened={isOpened}
      onClose={onClose}
      onClearClick={onClearColumns}
      onFullQueryClick={onFullQueryColumns}
    >
      <ColumnsList>
        {columnsList.map((column, index) => (
          <ColumnSelectionListItem
            key={column.name + `-${index}`}
            column={column}
            metaFields={meta?.fields}
            onItemClick={onSelectColumn}
          />
        ))}
      </ColumnsList>
    </ColumnsSelectionSidebar>
  );
}

export default function (targetRanges, value, data, reverse) {
  if (!targetRanges || !data?.length) return null;
  const lowerRange = data[0][targetRanges.bands[0].key];
  const upperRange = data[0][targetRanges.bands[1].key];
  if (value < lowerRange) {
    return reverse ? "upper" : "lower";
  } else if (value > upperRange) {
    return reverse ? "lower" : "upper";
  } else return "neutral";
}

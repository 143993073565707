import React from "react";
import styled from "@emotion/styled";
import {
  flexCenter,
  themeColor,
  themeText,
} from "../../../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Info from "../../../../UI/Info/Info";
import IOButton from "../../../../UI/Form/Button/IOButton";
import GridElementHover from "./GridElementHover";

const GridElementContainer = styled.div`
  color: ${themeText("primary")};
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  &:hover [data-cy="hover-state"] {
    display: block !important;
  }
  &:hover [data-cy="empty-image"] {
    opacity: 0.3;
  }
`;
const CircleButton = styled.div`
  position: absolute;
  right: ${(props) => (props.thumbnail ? -10 : 0)}px;
  top: ${(props) => (props.thumbnail ? -10 : 2)}px;
  color: ${themeText("secondary")};
  cursor: pointer;
  font-size: ${(props) => (props.active ? 24 : props.thumbnail ? 14 : 16)}px;
  transform: rotate(${(props) => (props.active ? 45 : 0)}deg);
  display: ${(props) => (props.active ? "block" : "none")};
`;

const Title = styled.div`
  font-size: ${(props) => (props.thumbnail ? 12 : 16)}px;
  font-weight: 400;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
`;

const EditButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const size = 20;
const sizeThumbnail = 16;
function getSize(props) {
  return props.thumbnail ? sizeThumbnail : props.active ? size : 24;
}
const CountCircle = styled.div`
  height: ${getSize}px;
  width: ${getSize}px;
  border-radius: 50%;
  background: ${themeColor("infoMain")};
  font-size: ${(props) => (props.thumbnail ? 9 : props.active ? 10 : 12)}px;
  font-weight: 700;
  color: white;
  position: absolute;
  right: ${(props) => (props.thumbnail ? -11 : props.active ? 32 : -2)}px;
  top: ${(props) => (props.thumbnail ? -10 : props.active ? 6 : -4)}px;
  ${flexCenter}
`;

const GridElement = (props) => {
  const {
    onClose,
    component,
    thumbnail,
    title,
    active,
    infoLabel,
    empty,
    emptySmall,
    summary,
  } = props;
  const circleCount = component?.props?.values?.length;

  const imagePlaceholder = (
    <div
      style={{ marginTop: thumbnail ? 18 : 25, textAlign: "center" }}
      data-cy="empty-image"
    >
      <img
        width={emptySmall ? "70%" : "90%"}
        src={empty}
        alt="No Values"
        style={{ maxWidth: emptySmall ? 220 : 300 }}
      />
    </div>
  );

  return (
    <GridElementContainer>
      <Title data-cy="grid-element-title" thumbnail={thumbnail}>
        {title}
        {!thumbnail ? <Info label={infoLabel || "Info coming"} /> : null}
      </Title>

      {circleCount ? (
        <CountCircle
          active={active}
          thumbnail={thumbnail}
          children={<span>{circleCount}</span>}
          data-cy="count-bubble"
        />
      ) : null}

      <CircleButton
        data-cy="grid-master-action"
        thumbnail={thumbnail}
        active={active}
        onClick={() => (active ? onClose() : null)}
      >
        <FontAwesomeIcon icon={["fal", "plus-circle"]} />
      </CircleButton>

      {!active && thumbnail ? imagePlaceholder : null}
      {!active && !thumbnail && circleCount ? summary : null}
      {(!active && !thumbnail && !circleCount) || (!active && !summary)
        ? imagePlaceholder
        : null}

      {!!active ? React.cloneElement(component, { onClose }) : null}

      {!active && !thumbnail ? (
        <EditButtonContainer>
          <IOButton cancel cy={title}>
            Edit
          </IOButton>
        </EditButtonContainer>
      ) : null}
      {!active && !thumbnail && !circleCount ? (
        <GridElementHover infoLabel={infoLabel} />
      ) : null}
    </GridElementContainer>
  );
};

export default GridElement;

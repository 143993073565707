import React from "react";
import Assistant from "../../../Editors/Assistant/Assistant";
import styled from "@emotion/styled";
import { themeGrayIf } from "../../../styles/styledSnippets";
import assistantGuy from "./assets/assistant.png";

const WizardMessage = styled.div`
  border-top: 2px solid ${themeGrayIf(900, 200)};
  padding-top: 18px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  ${(props) =>
    props.theme.type === "dark"
      ? `
  span {
    font-weight: 100;
    font-size: 14px;
    color: ${props.theme.text.secondary};
  }
  `
      : ""}
`;

const WizardBlock = (props) => {
  return (
    <WizardMessage>
      <Assistant message large imageOverride={assistantGuy}>
        <div>
          <b>{props.title}</b>
        </div>
        <div>
          <span>{props.text}</span>
        </div>
      </Assistant>
    </WizardMessage>
  );
};

export default WizardBlock;

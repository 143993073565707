import React from "react";
import LineLegend from "./LineLegend";
import { chartSectionsLimit } from "../../utils/constants/constants";
import { orderBy } from "lodash-es";
import {
  addScaleDate,
  assignAsKey,
  dateSort,
  doIf,
  doIfElse,
  groupByKey,
  groupUnder,
  pipe,
} from "../../utils/func";
import { toDateType } from "../../utils/dates/dateFunc";

const hasAttr = (attrs, key) => attrs && attrs.find((a) => a === key);

export default function JustLegend(props) {
  const { chart, QO } = props;

  const {
    data,
    xKey,
    colors,
    valueKeys,
    dashedKeys,
    curvedKeys,
    meta,
    groupBy,
    legendItems,
    dateParserStyle,
    dateParserYearKey,
    nullLegend,
    verticalLegend,
    legendSize,
    defaultColors,
    legendLinkKey,
    bands,
    hideLegend,
    valueKeysRightAxis = [],
    yearOverYear,
    disableLegendSizeOrder,
  } = chart;

  const dated = filterOutW();

  function filterOutW() {
    return data.filter((d) => d[xKey] !== " W");
  }

  const initialLineGroups = pipe(
    assignAsKey(xKey, "xValue"),
    doIf(!bands, toDateType("xValue", dateParserStyle, dateParserYearKey)),
    doIf(yearOverYear, addScaleDate()),
    dateSort("xValue"),
    doIfElse(
      groupBy,
      groupByKey(groupBy),
      groupUnder(valueKeys, valueKeysRightAxis)
    )
  )(dated);

  const preFormulaLineGroup = initialLineGroups.map((l) => ({
    ...l,
    curved: hasAttr(curvedKeys, l.key),
    dashed: hasAttr(dashedKeys, l.key),
    link: l[legendLinkKey],
    values: l.values.map((v) => ({ ...v, value: +v.value })),
    sum: l.values.reduce((acc, curr) => acc + (curr.value ?? 0), 0),
    rightAxisValues: l.rightAxisValues?.map((v) => ({ ...v, value: +v.value })), // if has right Y axis
  }));

  const ordered = disableLegendSizeOrder
    ? preFormulaLineGroup
    : orderBy(preFormulaLineGroup, "summ", "desc");

  return (
    <LineLegend
      foreignObject
      legendItems={legendItems}
      lineGroups={ordered.slice(0, chartSectionsLimit)}
      chartColors={colors || chartColors}
      fieldMapping={meta && meta.fields}
      nullLegend={nullLegend}
      verticalLegend={verticalLegend}
      defaultColors={defaultColors}
      hide={hideLegend}
      legendSize={legendSize}
      QO={QO}
      legendOnly
    />
  );
}

const chartColors = [
  "#4caf50",
  "#308a34",
  "#f28e2c",
  "#e15759",
  "#76b7b2",
  "#edc949",
  "#af7aa1",
  "#ff9da7",
  "#9c755f",
  "#bab0ab",
];

import React, { useMemo } from "react";
import ButtonOutline from "../ButtonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBooleanToggle from "../../../hooks/useBooleanToggle";
import ActiveTableViewsNavigationList from "./ActiveTableViewsNavigationList";
import styled from "@emotion/styled";
import { themeColor } from "../../../styles/styledSnippets";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Indicator = styled.div`
  background: ${themeColor("infoMain")};
  width: 36px;
  height: calc(100% + 20px);
  position: relative;
  top: -10px;
  left: -14px;
  box-sizing: border-box;
  svg {
    color: ${themeColor("infoLight")};
    font-size: 12px;
    position: relative;
    top: 10px;
    right: -12px;
  }
`;

export default function ActiveTableViews(props) {
  const [showList, setShowList] = useBooleanToggle(false);
  // This is wrong now, must update thinking here
  const { views, selectedView } = props;

  const activeView = useMemo(() => {
    if (!views) {
      return null;
    }

    return views.find((view) => view.uuid === selectedView);
  }, [selectedView, views]);

  return (
    <div style={{ position: "relative", width: 200 }}>
      <ButtonOutline onClick={setShowList} color="infoMain">
        <Container data-cy="active-table-views-list">
          <div style={{ display: "flex", alignItems: "stretch" }}>
            <Indicator>
              <FontAwesomeIcon icon={["fas", "circle"]} />
            </Indicator>
            <div>{activeView?.name ?? "Default view"}</div>
          </div>
          <FontAwesomeIcon icon={["fal", "chevron-down"]} />
        </Container>
      </ButtonOutline>
      {showList ? (
        <ActiveTableViewsNavigationList
          setShowList={setShowList}
          views={props.views}
          selectedView={props.selectedView}
          setSelectedView={props.setSelectedView}
          columns={props.columns}
          queryId={props.queryId}
          handleSaveView={props.handleSaveView}
          user={props.user}
          handleSplit={props.handleSplit}
        />
      ) : null}
    </div>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProductPrettySummary(props) {
  const { values } = props;
  return (
    <div
      style={{
        marginTop: 24,
        fontWeight: 300,
        lineHeight: "190%",
        fontSize: 12,
      }}
    >
      {values
        .filter((v, i) => i < 6)
        .map((v, i) => (
          <div
            key={i}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                width: "40%",
                flexShrink: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {v.original.label}
            </div>
            <div
              style={{
                fontSize: 12,
                padding: "0 12px",
                flexShrink: 0,
                width: "10%",
              }}
            >
              <FontAwesomeIcon icon={["fal", "arrow-right"]} />
            </div>
            <div
              style={{
                width: "40%",
                flexShrink: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <div>{v.override.label}</div>
            </div>
          </div>
        ))}
    </div>
  );
}

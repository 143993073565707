import AlertMessage from "../../../UI/Alerts/AlertMessage";
import IOButton from "../../../UI/Form/Button/IOButton";

export default function QueryFieldsWarningLink({
  isParameterized,
  fields = [],
  queryUuid,
}) {
  if (!isParameterized || fields.length) {
    return null;
  }

  const action = (
    <IOButton
      info
      smallPadding
      type="button"
      to={`/admin/data-settings/mapping/queries/${queryUuid}`}
      cy="query-fields-warning-button"
    >
      Open Query
    </IOButton>
  );

  return (
    <div data-cy="query-fields-warning-container">
      <AlertMessage
        outline
        status="warning"
        message={
          "You haven't mapped your fields, please follow the link to map them."
        }
        actions={action}
      />
    </div>
  );
}

import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const RouteItem = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  background: ${lightenDarkenColor(theme.background.primary, 30)};
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
`
);

const IconBox = styled.div(
  ({ theme }) => `
  border-radius: 50%;
  background: ${lightenDarkenColor(theme.background.secondary, -20)};
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  `
);

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export default function RoutingItems({ route }) {
  return (
    <Link to={`/admin/automation/scheduled-emails`}>
      <RouteItem>
        <IconBox>
          <FontAwesomeIcon icon={route.icon} />
        </IconBox>
        <div>
          <Title>{route.title}</Title>
          <div style={{ fontSize: 10 }}>{route.description}</div>
        </div>
      </RouteItem>
    </Link>
  );
}

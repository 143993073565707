import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import saveImage from "../../utils/saveImage/saveImage";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";

const Container = styled.div`
  position: absolute;
  top: -20px;
  right: -10px;
  color: ${(props) => props.theme.text.secondary};
  text-align: right;
  cursor: pointer;
  opacity: 0.2;
  &:hover {
    opacity: 0.4;
  }
  path {
    fill: ${(props) => props.theme.text.secondary};
  }
  &:hover path {
    fill: ${(props) => props.theme.text.primary};
  }
`;

export default function SaveAsImage(props) {
  const { visualizationId } = props;
  const theme = useTheme();
  return (
    <Container
      onClick={() => saveImage(visualizationId, theme)}
      data-html2canvas-ignore="true"
    >
      <FontAwesomeIcon icon={["fas", "image"]} size="2x" />
    </Container>
  );
}

SaveAsImage.propTypes = {
  visualizationId: PropTypes.string,
};

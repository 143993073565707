import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IOButton from "../../UI/Form/Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NewTenant(props) {
  const { hasPages, hasData } = useSelector((state) => state.auth);
  // @ todo hasData is not implemented, and will cause unreliable message
  return (
    <div data-cy="new-tenant-message">
      {hasPages ? null : (
        <div data-cy="no-pages">
          You have not defined any page layouts to display. Setup a dashboard
          view now....
        </div>
      )}
      {hasData ? null : (
        <div data-cy="no-data-notification">
          You have not defined any datasets to query against
        </div>
      )}
      Nothing to see here, man.
      <p>
        I'd suggest you do some data setup...{" "}
        <Link to="/admin/data-setup">
          <IOButton outline>
            <span style={{ paddingRight: 8 }}>go</span>{" "}
            <FontAwesomeIcon icon={["fas", "arrow-right"]} />
          </IOButton>
        </Link>
      </p>
    </div>
  );
}

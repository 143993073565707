import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";

const Container = styled.circle`
  fill: ${({ mood, theme, color }) =>
    !mood
      ? color
        ? color
        : "#2E86C1"
      : mood === "good"
      ? theme.notification.successLight
      : theme.notification.errorLight};
  stroke: ${({ mood, theme }) =>
    !mood
      ? "white"
      : mood === "good"
      ? theme.notification.successDark
      : theme.notification.errorDark};
  stroke-width: 0;
  cursor: pointer;
  transition: r 0.2s linear, cy 0.2s linear, cx 0.2s linear;
  r: 3;
  &:hover {
    r: ${({ radius }) => radius * 1.4};
    stroke: ${({ mood, theme }) =>
      !mood
        ? theme.notification.infoLight
        : mood === "good"
        ? theme.notification.successLight
        : theme.notification.errorLight};
    stroke-width: 4;
  }
`;

export default function PositionCircle(props) {
  const theme = useTheme();

  return (
    <Container
      {...props}
      style={{
        fill: !props.mood
          ? props.color
            ? props.color
            : "#2E86C1"
          : props.mood === "good"
          ? theme.notification.successLight
          : theme.notification.errorLight,
        stroke: !props.mood
          ? "white"
          : props.mood === "good"
          ? theme.notification.successDark
          : theme.notification.errorDark,
        strokeWidth: 0,
        transition: "r .2s linear, cy .2s linear, cx .2s linear",
        r: props.radius || 3,
      }}
    />
  );
}

PositionCircle.defaultProps = {
  radius: 5,
  cx: 0,
};

PositionCircle.propTypes = {
  radius: PropTypes.number,
  mood: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

import { unique } from "../../utils/func";

export default function (rollup, data) {
  let rollupData = null;

  const { matchForRow, dynamicColumnKey, dynamicColumnValue } = rollup;

  const sectionMatch = matchForRow[0];
  const itemMatch = matchForRow[1];
  const rolledUpRowValues = unique(data.map(rowName));

  function rowName(d) {
    return d[sectionMatch] + "-" + d[itemMatch];
  }

  rollupData = rolledUpRowValues.reduce((acc, curr) => {
    const sectionKey = curr.split("-")[0];
    const itemKey = curr.split("-")[1] || sectionKey;

    const matchRow = (c) => (d) => {
      const matchedSection = d[sectionMatch] === sectionKey;
      const matchedItem = d[itemMatch] === itemKey;
      return d[dynamicColumnKey] === c && matchedSection && matchedItem;
    };

    const rollupSubtitlesArray = uniqueColumnValues(data, dynamicColumnKey);
    const row = rowFromData(
      rollupSubtitlesArray,
      data,
      dynamicColumnValue,
      matchRow
    );

    const draftKeyValues = {
      rowValue: curr,
      [sectionMatch]: sectionKey,
      [itemMatch]: itemKey,
    };

    return [...acc, { ...row, ...draftKeyValues }];
  }, []);

  return rollupData;
}

function rowFromData(rollupSubtitlesArray, data, dynamicColumnValue, matchRow) {
  return rollupSubtitlesArray.reduce((a, c) => {
    const foundRow = data.find(matchRow(c));

    const matched = foundRow ? foundRow[dynamicColumnValue] : null;
    return { ...a, [c]: matched };
  }, {});
}

function uniqueColumnValues(data, key) {
  return unique(data.map((d) => d[key]));
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/fontLibrary";
import PropTypes from "prop-types";

const Checkbox = styled.input`
  position: absolute;
  height: 0;
  width: 0;

  ~ span {
    opacity: 0.5;
    cursor: pointer;
  }
  ~ div {
    opacity: 0.5;
    cursor: pointer;
  }

  &:checked ~ div {
    opacity: 1;
  }
  &:checked ~ span {
    opacity: 1;
    path {
      fill: ${(props) =>
        props.isPrimary ? props.theme.primary : props.theme.text.primary};
    }
  }
`;

const Label = styled.label`
  path {
    fill: ${(props) => props.theme.text.primary};
  }
  svg {
    width: 20px !important;
  }
`;

const LabelSpan = styled.span`
  padding-left: 12px;
  color: ${(props) => props.theme.text.primary};
`;

export default function IconCheckbox(props) {
  const icon = props.icon;
  if (props.lib) {
    icon[0] = props.lib;
  }
  if (props.iconName) {
    icon[1] = props.iconName;
  }
  if (props.checked && props.activeIconName) {
    icon[1] = props.activeIconName;
  }

  return (
    <Label title={props.title || undefined}>
      <Checkbox
        data-cy={props.cy || "checkbox-input"}
        onChange={props.onChange}
        type="checkbox"
        checked={props.checked}
        isPrimary={props.isPrimary}
      />
      <div style={{ float: "left", width: 12 }}>
        <FontAwesomeIcon icon={icon} data-cy="checkbox-icon" />
      </div>
      {props.label ? (
        <LabelSpan>
          {props.checked && props.activeLabel ? props.activeLabel : props.label}
        </LabelSpan>
      ) : null}
    </Label>
  );
}

IconCheckbox.defaultProps = {
  icon: ["fas", "check"],
};

IconCheckbox.propTypes = {
  icon: PropTypes.array,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  cy: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  activeLabel: PropTypes.string,
};

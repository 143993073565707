import React from "react";
import styled from "@emotion/styled";

const FormContainer = styled.form(
  ({ theme }) => `
    & > button {
        margin: 12px auto;
    }
`
);

export default function LoginFormContainer(props) {
  const { onLogin, children } = props;
  return <FormContainer onSubmit={onLogin}>{children}</FormContainer>;
}

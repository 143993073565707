import React from "react";
import styled from "@emotion/styled";
import { css } from "../../../utils/ide";
import SimpleTooltip from "../../SimpleTooltip/SimpleTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledLabel = styled.label(
  ({ theme, overPrimary }) => css`
    font-size: 12px;
    text-align: left;
    padding: 4px 10px 8px 0;
    display: flex;
    color: ${overPrimary ? theme.text.textOnPrimary : theme.text.secondary};

    & svg {
      margin-left: 5px;
      font-size: 10px;
      cursor: pointer;
    }
  `
);

export function FormLabel({ children, required, tooltip, overPrimary, style }) {
  return (
    <StyledLabel required={required} overPrimary={overPrimary} style={style}>
      {children}
      {required ? "*" : ""}

      {tooltip && (
        <SimpleTooltip position="top" label={tooltip} usePortal>
          <FontAwesomeIcon
            icon={["fas", "info-circle"]}
            style={{ fontSize: 14 }}
          />
        </SimpleTooltip>
      )}
    </StyledLabel>
  );
}

export default function LabelWrapper({
  label,
  required,
  overPrimary,
  tooltip,
  children,
}) {
  if (!label) return children;
  return (
    <Container>
      <FormLabel
        tooltip={tooltip}
        required={required}
        overPrimary={overPrimary}
      >
        {label}
      </FormLabel>
      {children}
    </Container>
  );
}

import * as actionTypes from "./actionTypes";

export const getDataFreshness = () => {
  return {
    type: actionTypes.GET_DATA_FRESHNESS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: "/api/v1/data_freshness",
      },
    },
  };
};

export const getInsights = () => {
  return {
    type: actionTypes.GET_INSIGHTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: "/api/v1/insights-notifications",
      },
    },
  };
};

// we can use it in future
export const getImportantNotifications = () => {
  return {
    type: actionTypes.GET_ISSUE_NOTIFICATIONS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: "/api/v1/important_notifications",
      },
    },
  };
};

export const saveImportantNotifications = (importantNotifications) => {
  const { active } = importantNotifications[0];
  const ending = ` It will be ${
    active ? "shown" : "deleted"
  } in after 10 seconds.`;

  const message = active
    ? "Notification saved successfully."
    : "Notification switched off.";

  return {
    type: actionTypes.SAVE_DATA_LOAD_ISSUE_NOTIFICATION_START,
    meta: {
      api: {
        method: "PUT",
        endpoint: `/api/v1/important_notifications`,
        payload: {
          importantNotifications,
        },
        toastOnFailure: true,
      },
      toasts: [
        {
          type: "success",
          title: "Dashboard Notification",
          message: message + ending,
          condition: actionTypes.SAVE_DATA_LOAD_ISSUE_NOTIFICATION_SUCCESS,
        },
      ],
    },
  };
};

export const updateImportantNotificationsBySocket =
  (notifications) => (dispatch) => {
    dispatch({
      type: actionTypes.GET_ISSUE_NOTIFICATIONS_SUCCESS,
      data: notifications,
    });
  };

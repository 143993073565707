import React from "react";
import NavigationCell from "./NavigationCell";
import styled from "@emotion/styled";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import Flex from "../../../../UI/Flex/Flex";

import useVirtualizedDataExplorerList from "./useVirtualizedDataExplorerList";

const layoutPadding = 158;
const TableCellsContainer = styled.div`
  max-width: calc(
    100vw - ${(props) => props.subtractWidth}px - ${layoutPadding}px
  );

  box-sizing: border-box;
  overflow-x: auto;
  color: ${(props) => props.theme.text.secondary};
  overflow-x: hidden;
  height: calc(100vh - 250px);
  ${(props) => scrollbarDe(props.theme)};
`;

const rowHeight = 27.2;

const NavigationTableBody = React.forwardRef((props, ref) => {
  const { data, columns, columnWidths, showActive, activeKey } = props;

  const {
    visibleItems,
    handleScroll,
    scrollbarContainerStyles,
    innerScrollbarContainerStyles,
  } = useVirtualizedDataExplorerList(data, ref, rowHeight);

  return (
    <TableCellsContainer
      ref={ref}
      data-cy="navigation-table-body"
      onScroll={handleScroll}
    >
      <div style={scrollbarContainerStyles}>
        <div style={innerScrollbarContainerStyles}>
          {visibleItems.map((d, dataRowIndex) => (
            <Flex key={dataRowIndex}>
              {columns.map((column, i) => (
                <NavigationCell
                  key={i}
                  cellWidth={columnWidths[i] || 140}
                  d={d}
                  column={column}
                  active={showActive && activeKey === i}
                />
              ))}
            </Flex>
          ))}
        </div>
      </div>
    </TableCellsContainer>
  );
});

export default NavigationTableBody;

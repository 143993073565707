import React from "react";

export function ProductionWarningText() {
  return (
    <div>
      <strong>WARNING:</strong> this site is using the real Production API. Any
      changes/updates you make <em>will</em> apply to the real production app,
      and the client will see them. Be careful.
    </div>
  );
}

function ProductionWarning() {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "red",
        zIndex: 100000,
      }}
    >
      <ProductionWarningText />
    </div>
  );
}

export default ProductionWarning;

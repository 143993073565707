import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import mobileBreakpoints from "../../styles/mobileBreakpoints";

const Container = styled.div(
  ({ theme, color, opacity }) => `
    padding: 6px 14px;  
    margin-right: 12px;
    opacity: ${opacity};
    border-bottom: 6px solid ${color};
    
    @media (max-width: ${mobileBreakpoints.mobile}) {
      padding: 5px;
    }
`
);

const Wrapper = styled.div`
  display: flex;
  margin: 12px 0 18px 18px;
  font-size: 11px;
  color: ${({ theme }) => theme.text.primary};
  border-radius: 2px;
`;

export default function LegendBlocks(props) {
  const { colors, names, filters, colorFunction } = props;
  const opacity = (val) => {
    const activeFilters = filters
      .map((item) => item.values.filter((f) => f.checked))
      .flat();

    if (!activeFilters.length) return 1;
    return activeFilters.find((f) => {
      const value = typeof f.value === "number" ? f.value.toString() : f.value;
      return value.replace(" ", "") === val.replace(" ", "");
    })
      ? 1
      : 0.2;
  };

  return (
    <Wrapper>
      {names.map((n, i) => (
        <Container
          opacity={opacity(n)}
          key={n + i}
          color={colorFunction ? colors(i) : colors[i]}
        >
          {n}
        </Container>
      ))}
    </Wrapper>
  );
}

LegendBlocks.propTypes = {
  colors: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  names: PropTypes.array,
};

import { useEffect, useState } from "react";
import Flex from "../../../UI/Flex/Flex";
import Input from "../../../UI/Form/Input/Input";
import IOButton from "../../../UI/Form/Button/IOButton";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import UniqueTable from "./UniqueTable";
import Title from "./AddNewRowsTitle";
import { getDataBySearchText } from "../utils";

export default function AddNewRows(props) {
  const {
    setVisibleModalComponent,
    data,
    meta,
    watchlist,
    saveAppliedFilters,
    loading,
    watchlistConfig,
    getColumnTextAlign,
  } = props;

  // local search
  const [search, setSearch] = useState("");

  // local filters
  const [localWatchlist, setLocalWatchlist] = useState(watchlist);

  useEffect(() => {
    setLocalWatchlist(watchlist);
  }, [watchlist]);

  const { uniqueColumns, anchorText } = watchlistConfig;

  const searchedData = getDataBySearchText(data, uniqueColumns, search);

  return (
    <Flex direction="column">
      <Title
        anchorText={anchorText}
        closeModal={() => setVisibleModalComponent(null)}
      />

      <Input
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      <UniqueTable
        data={searchedData}
        meta={meta}
        uniqueColumns={uniqueColumns}
        watchlist={localWatchlist}
        setWatchlist={setLocalWatchlist}
        getColumnTextAlign={getColumnTextAlign}
      />

      <ButtonGroup position="flex-end">
        <IOButton
          onClick={() => saveAppliedFilters(localWatchlist)}
          processing={loading}
        >
          Add
        </IOButton>
      </ButtonGroup>
    </Flex>
  );
}

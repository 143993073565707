import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatter from "../../utils/formatters/formatter";

const TotalRows = styled.span`
  font-size: 12px;
  margin-right: 50px;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => position};
  margin: 20px 10px;
  color: ${({ theme }) => theme.text.secondary};

  & div {
    border: 1px solid #666;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.background.secondary};
    }
  }

  & div:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  & div:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

const CurrentPage = styled.div`
  cursor: default;
  padding: 5px 10px;
`;

const PageItem = styled.div(
  ({ disabled }) => `
    pointer-events: ${disabled ? "none" : "auto"};
    opacity: ${disabled ? 0.7 : 1};
`
);

export default function Pagination({
  currentPage,
  first,
  last,
  previous,
  next,
  showTotalRows,
  totalRows,
  position = "flex-end",
}) {
  if (!totalRows) return null;

  return (
    <PaginationContainer data-cy="pagination-container" position={position}>
      {showTotalRows && (
        <TotalRows>Total Rows: {formatter(totalRows, "number")}</TotalRows>
      )}
      <PageItem onClick={first} disabled={!first} data-cy="pagination-first">
        <FontAwesomeIcon
          style={{ fontSize: "1em" }}
          icon={["fas", "angle-double-left"]}
        />
      </PageItem>
      <PageItem onClick={previous} disabled={!previous}>
        <FontAwesomeIcon
          style={{ fontSize: "1em" }}
          icon={["fas", "angle-left"]}
        />
      </PageItem>
      <CurrentPage>{currentPage}</CurrentPage>
      <PageItem onClick={next} disabled={!next}>
        <FontAwesomeIcon
          style={{ fontSize: "1em" }}
          icon={["fas", "angle-right"]}
        />
      </PageItem>
      <PageItem onClick={last} disabled={!last}>
        <FontAwesomeIcon
          style={{ fontSize: "1em" }}
          icon={["fas", "angle-double-right"]}
        />
      </PageItem>
    </PaginationContainer>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { flexCenter } from "../../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  position: ${(props) => (props.relative ? "relative" : "absolute")};
  width: 28px;
  height: 24px;
  left: ${(props) => (props.relative ? 0 : -34)}px;
  top: 9px;
  overflow: ${(props) => (props.relative ? "visible" : "hidden")};
  margin-right: ${(props) => (props.relative ? 12 : 0)}px;
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
  div {
    left: 6px;
  }
`;

const Container = styled.div`
  position: relative;
  left: 18px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.theme.notification.errorMain};
  ${flexCenter};
  color: white;
  cursor: pointer;
  transition: 0.1s ease left;
`;

export default function RemoveDot(props) {
  return (
    <Wrapper
      onClick={props.onClick}
      data-cy={props.cy ?? "explore-list-remove-value"}
      relative={props.relative}
    >
      <Container>
        <FontAwesomeIcon icon={["fal", "minus"]} />
      </Container>
    </Wrapper>
  );
}

RemoveDot.propTypes = {
  onClick: PropTypes.func,
  relative: PropTypes.bool,
};

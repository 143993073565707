import React from "react";
import SampleNumberPreview from "../NumberInput/SampleNumberPreview";
import FilterInstruction from "../FilterInstruction";
import styled from "@emotion/styled";

const entryWidth = 226;

const getWidth = (props) => {
  if (props.double) {
    return entryWidth * 2 + 12;
  } else {
    return entryWidth;
  }
};

const Wrapper = styled.div`
  font-size: 12px;
  height: 10px;
  margin-right: 12px;
  position: relative;
  margin-left: 4px;
  width: ${getWidth}px;
`;

const Container = styled.div`
  position: absolute;
  width: ${getWidth}px;
  display: flex;
  align-items: center;
  div {
    flex-shrink: 0;
  }
`;

export default function DateInputWrapper(props) {
  const { operator, sampleResults, isFocus, isRangeType } = props;
  return (
    <Wrapper double={operator === "range"}>
      <SampleNumberPreview
        sampleResults={sampleResults}
        show={isFocus}
        date
        double={operator === "range"}
      />

      <Container data-cy="explorer-date-input" double={isRangeType}>
        <FilterInstruction
          show={!operator}
          message="Select range type"
          cy="explorer-date-message"
        />
        {props.children}
      </Container>
    </Wrapper>
  );
}

import formatter from "../../../../utils/formatters/formatter";

export default class {
  constructor(d, column) {
    this.d = d;
    this.column = column;
  }

  display() {
    const columnName = this.column.aggregationName || this.column.name;
    const { type, aggType } = this.column.mapping;
    return formatter(this.d[columnName], aggType || type || "string");
  }

  align() {
    return alignments[this.column.mapping.type];
  }
}

const alignments = {
  currency: "right",
  decimal: "right",
  float: "right",
  number: "right",
  string: "left",
  date: "left",
};

import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import { handleError } from "../../utils/errorHandling";

export const loadEtlConfigs = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_ETL_CONFIG_LIST_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/etl_configs`,
      },
    },
  });
};

export const initCreateEtlConfig = () => (dispatch) => {
  dispatch({ type: actionTypes.CREATE_ETL_CONFIG_INIT });
};

export const getEtlConfig = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_ETL_CONFIG_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/etl_configs/${uuid}`,
      },
    },
  });
};

export function createEtlConfig(config) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_ETL_CONFIG_START });

    try {
      const res = await axios.post("api/v1/etl_configs", config);
      dispatch({ type: actionTypes.CREATE_ETL_CONFIG_SUCCESS });
      return res.data.data;
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionTypes.CREATE_ETL_CONFIG_FAIL,
        errors:
          err.response.status_code === 422
            ? err.response.data.errors
            : [err.response.data.message],
      });
      throw err;
    }
  };
}

export function updateEtlConfig(uuid, config, reloadConfigs) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_ETL_CONFIG_START });

    try {
      const res = await axios.put("api/v1/etl_configs/" + uuid, config);
      dispatch({
        type: actionTypes.UPDATE_ETL_CONFIG_SUCCESS,
        results: res.data,
      });
      if (reloadConfigs) {
        await dispatch(loadEtlConfigs());
      }
      return res.data.data;
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionTypes.UPDATE_ETL_CONFIG_FAIL,
        errors:
          err.response.status_code === 422
            ? err.response.data.errors
            : [...err.response.data.message],
      });
      throw err;
    }
  };
}

export const deleteEtlConfig = (uuid) => (dispatch) => {
  dispatch({ type: actionTypes.DELETE_ETL_CONFIG_START });

  axios.delete(`api/v1/etl_configs/${uuid}`).then((res) => {
    dispatch({ type: actionTypes.DELETE_ETL_CONFIG_SUCCESS, uuid: uuid });
  });
};

export const listConfigDatabases = (connection) => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_CONFIG_LIST_DATABASES_CONFIG_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/connections/${connection}/databases`,
      },
    },
  });
};

export const listSourceDatabases = (connection) => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/connections/${connection}/databases`,
      },
    },
  });
};

export const listDatabases = (connection) => {
  return {
    type: actionTypes.ETL_CONFIG_LIST_DATABASES_CONFIG_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/connections/${connection}/databases`,
      },
    },
  };
};

export const listTables = (connection, dbName) => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_CONFIG_LIST_TABLES_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/connections/${connection}/tables?dbName=${dbName}`,
      },
    },
  });
};

export const listFields = (connection, dbName, tableName) => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_CONFIG_LIST_FIELDS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/connections/${connection}/columns?dbName=${dbName}&tableName=${tableName}`,
      },
    },
    payload: {
      table: tableName,
    },
  });
};

export const controlEtlJob = (uuid, etlAction) => (dispatch) => {
  const actionsMap = {
    "cron-run": actionTypes.ETL_CONFIG_RUN_JOB_START,
    "cron-pause": actionTypes.ETL_CONFIG_PAUSE_JOB_START,
    "cron-resume": actionTypes.ETL_CONFIG_RESUME_JOB_START,
  };

  dispatch({
    type: actionsMap[etlAction],
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/etl_configs/${uuid}/${etlAction}`,
      },
      toasts: [
        {
          type: "success",
          title: "Job started",
          message: "ETL Job is running",
          condition: actionTypes.ETL_CONFIG_RUN_JOB_SUCCESS,
        },
        {
          type: "success",
          title: "Job paused",
          message:
            "ETL Job schedule is paused. Next job execution will not happen.",
          condition: actionTypes.ETL_CONFIG_PAUSE_JOB_SUCCESS,
        },
        {
          type: "success",
          title: "Job resumed",
          message: "ETL Job is now scheduled.",
          condition: actionTypes.ETL_CONFIG_RESUME_JOB_SUCCESS,
        },
      ],
    },
  });
};

export const viewJobLogs = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_CONFIG_GET_LOGS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/etl_configs/${uuid}/get-logs`,
      },
    },
  });
};

export const clearJobLogs = () => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_CONFIG_CLEAR_LOGS,
  });
};

export const getDataFlow = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_DATA_FLOW_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `/api/v1/data-flow`,
      },
    },
  });
};

export const salesforceGetObjects = (connectionUuid) => (dispatch) => {
  dispatch({
    type: actionTypes.SALESFORCE_GET_OBJECTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/salesforce/${connectionUuid}/get_objects`,
      },
    },
  });
};

export const salesforceMarketingCloudGetObjects =
  (connectionUuid) => (dispatch) => {
    dispatch({
      type: actionTypes.SALESFORCE_MARKETING_CLOUD_GET_OBJECTS_START,
      meta: {
        api: {
          method: "GET",
          endpoint: `api/v1/salesforce/${connectionUuid}/get_marketing_cloud_objects`,
        },
      },
    });
  };

export const sageGetObjects = (connectionUuid) => (dispatch) => {
  dispatch({
    type: actionTypes.SAGE_GET_OBJECTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/sage/${connectionUuid}/get_objects`,
      },
    },
  });
};

export const shipStationGetObjects = (connectionUuid) => (dispatch) => {
  dispatch({
    type: actionTypes.SHIPSTATION_GET_ORDERS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/ship_station/${connectionUuid}/get_objects`,
      },
    },
  });
};

export const autodetectInit = () => (dispatch) => {
  dispatch({
    type: actionTypes.ETL_AUTODETECT_INIT,
  });
};

export const autodetectFile = (file) => (dispatch) => {
  const formData = new FormData();

  formData.append("file", file);

  dispatch({
    type: actionTypes.ETL_AUTODETECT_START,
  });

  return axios
    .post(`api/v1/etl_configs/autocomplete`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.ETL_AUTODETECT_SUCCESS,
        results: res.data,
      });
    })
    .catch((err) => {
      handleError(dispatch, err);
      dispatch({
        type: actionTypes.ETL_AUTODETECT_FAIL,
        errors: err.response.data.errors,
      });
    });
};

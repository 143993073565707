import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import Checkbox from "../../UI/Form/Checkbox/Checkbox";
import TextArea from "../../UI/Form/Textarea/Textarea";
import IOButton from "../../UI/Form/Button/IOButton";
import { saveImportantNotifications } from "../../store/actions/userNotifications";
import { useShallowEqualSelector } from "../../store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px 0px;
  width: 90%;
  button {
    align-self: flex-end;
    width: fit-content;
  }
`;

export default function DashboardMessage() {
  const [state, setState] = useState({
    active: true,
    message: "",
    uuid: null,
  });

  const { importantNotifications, loading } = useShallowEqualSelector(
    (state) => ({
      importantNotifications: state.layout.importantNotifications,
      loading: state.layout.importantNotificationsLoading,
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (importantNotifications.length) {
      setState(importantNotifications[0]);
    }
  }, [importantNotifications]);

  return (
    <Container>
      <span>
        Message will appear on dashboards for all users until set to inactive
      </span>
      <br />
      <Checkbox
        label="Active"
        checked={state.active}
        onChange={() => setState({ ...state, active: !state.active })}
      />
      <br />
      <TextArea
        label="Message"
        rows={15}
        value={state.message}
        onChange={(e) => setState({ ...state, message: e.target.value })}
      />
      <br />
      <IOButton
        smallPadding
        disabled={!state.message}
        onClick={() => dispatch(saveImportantNotifications([state]))}
        processing={loading}
      >
        Save
      </IOButton>
    </Container>
  );
}

import styled from "@emotion/styled";
import convertFiltersToOperatorsMode, {
  convertFiltersToConfigFormat,
} from "../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import { useFilters } from "../../DataManagement/DataExplorer/hooks/useExplorerFilters/useFilters";
import FilterPanel from "../../../Editors/BlockEdit/VisualizationGuiEditSection/FilterPanel";
import usePrevious from "../../../utils/usePrevious";
import shouldRunQuery from "../../DataManagement/DataExplorer/hooks/useExplorerFilters/shouldRunQuery";
import { useEffect } from "react";
import { isEmpty } from "lodash-es";

const Container = styled.div(
  ({ disabled }) => `
  margin: 50px 0px 12px 0;
  display: flex;
  flex-direction: column;
  ${
    disabled &&
    `
      pointer-events: none;
      opacity: 0.4;
  `
  };
`
);

const settings = { filters: [] };

export default function Filtering({
  chart,
  setReport,
  refreshFilters,
  setRefreshFilters,
}) {
  const { filters = [] } = chart ?? {};

  const converted = convertFiltersToOperatorsMode(filters);
  const filterApi = useFilters(converted);
  const draftFilters = filterApi.draftFilters;
  const muted = filterApi.muted;

  const draftFiltersNotMuted = draftFilters.filter(
    (_, index) => !muted.includes(index)
  );

  const prevFilters = usePrevious(draftFiltersNotMuted);

  const run = shouldRunQuery(
    settings,
    settings,
    chart?.queryId,
    draftFiltersNotMuted,
    prevFilters
  );

  // this need for email automation extend filters sync feature
  useEffect(() => {
    if (refreshFilters) {
      filterApi.setDraftFilters(converted);
      filterApi.setMuted([], true);
      setRefreshFilters(false);
    }
  }, [converted, filterApi, refreshFilters, setRefreshFilters]);

  useEffect(() => {
    if (run) {
      const filters = convertFiltersToConfigFormat(draftFiltersNotMuted);
      setReport((prev) => ({ ...prev, filters }));
    }
  }, [draftFilters, run, setReport, draftFiltersNotMuted]);

  return (
    <Container disabled={isEmpty(chart)}>
      <div style={{ marginBottom: -20, fontSize: 12 }}>Filtering</div>
      <FilterPanel filterApi={filterApi} queryId={chart?.queryId} />
    </Container>
  );
}

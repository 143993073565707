import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../../UI/Form/Input/Input";
import { FieldWrapper } from "./GlobalSettings";
import { RowStyleContainer } from "./RowStyles";
import styled from "@emotion/styled";

const Type = styled.div(
  ({ theme }) => `
  svg {
    color: ${theme.notification.successMain};
    margin-left: 10px;
  }
`
);

export default function ParameterizedQuery({
  chart,
  setChartState,
  theme,
  isParameterized,
}) {
  const { parameterizedFilterPrefix } = chart;

  return (
    <RowStyleContainer>
      <Type>
        <span>Query type: {isParameterized ? "Parameterized" : "Regular"}</span>
        <FontAwesomeIcon icon={["fas", "check-circle"]} />
      </Type>
      <br />
      {isParameterized && (
        <FieldWrapper>
          <Input
            label="Parameterized Prefix"
            value={parameterizedFilterPrefix ?? ""}
            onChange={(e) =>
              setChartState({
                ...chart,
                parameterizedFilterPrefix: e.target.value,
              })
            }
          />
        </FieldWrapper>
      )}
      <hr color={theme.background.secondary} />
    </RowStyleContainer>
  );
}

import React from "react";
import styled from "@emotion/styled";
import {
  themeBackground,
  themeShadow,
  themeText,
} from "../../../../styles/styledSnippets";
import PropTypes from "prop-types";

const Container = styled.div`
  background: ${themeBackground("secondary")};
  color: ${themeText("primary")};
  border-radius: 4px;
  padding: 18px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: ${themeShadow(2)};
  overflow: hidden;
  display: flex;
  align-items: stretch;
`;

const Square = (props) => {
  return (
    <Container data-cy={props.cy || "grid-square"} active={props.active}>
      {props.text
        ? props.text
        : React.Children.map(props.children, (child) =>
            React.cloneElement(child, { ...props })
          )}
    </Container>
  );
};

Square.propTypes = {
  cy: PropTypes.string,
};

export default Square;

import {
  GET_HEADER_KPIS_START,
  GET_HEADER_KPIS_SUCCESS,
  GET_HEADER_KPIS_FAIL,
  RESET_HEADER_KPIS,
  DELETE_PAGE_BLOCK_SUCCESS,
  SORT_HEADER_BLOCKS_SUCCESS,
} from "../actions/actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export default function headerKpisReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HEADER_KPIS_START: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      });
    }

    case GET_HEADER_KPIS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = null;
        draft.data = action.results.data;
      });
    }

    case GET_HEADER_KPIS_FAIL: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.error;
        draft.data = null;
      });
    }

    case SORT_HEADER_BLOCKS_SUCCESS: {
      const data = state.data;
      return {
        ...state,
        data: {
          ...data,
          blocks: action.orderedBlocks.map((block) =>
            data.blocks.find((d) => d.uuid === block.uuid)
          ),
        },
      };
    }

    case RESET_HEADER_KPIS: {
      return initialState;
    }
    case DELETE_PAGE_BLOCK_SUCCESS: {
      if (action.pageUuid || !state.data) {
        return state;
      }

      return produce(state, (draft) => {
        draft.data.blocks = draft.data.blocks.filter(
          (b) => b.uuid !== action.blockUuid
        );
      });
    }

    default:
      return state;
  }
}

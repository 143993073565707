import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../../../UI/Tables/Table/Table";
import {
  buildContentOptions,
  checkObsoleteConfig,
  getBlocks,
  getChartVisualization,
  getConvertedReport,
  getFrequencyTableString,
} from "../components/automationHelper";
import Flex from "../../../UI/Flex/Flex";
import ChipList from "../components/ChipList";
import Switcher from "../../../UI/Switcher/Switcher";
import Loading from "../../../UI/Loading/Loading";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const TemplateMessage = styled.span`
  color: ${(props) => props.theme.notification.warningDark};
  font-weight: bold;
  margin-left: 10px;
  svg {
    margin-right: 5px;
  }
`;

export default function ScheduledTable({
  reportedQueries,
  loading,
  history,
  isActive,
  changeActive,
  deleteReport,
  theme,
}) {
  const pages = useSelector((state) => state.pageManagement.pages);

  if (loading) {
    return <Loading />;
  }

  function getEditIcon(report) {
    if (report.visualizationUuid) {
      return (
        <FontAwesomeIcon
          icon={["fas", "pencil-alt"]}
          onClick={() =>
            history.push(`/admin/automation/scheduled-emails/${report.uuid}`)
          }
          style={{ cursor: "pointer" }}
        />
      );
    }

    return <FontAwesomeIcon icon={["fal", "robot"]} />;
  }

  const { blocks } = getBlocks(pages);
  function renderObsoleteMessageComponent(report) {
    const contentOptions = buildContentOptions(blocks);
    const convertedReport = getConvertedReport(report);

    const content = contentOptions.find(
      (option) => option.value === convertedReport.visualizationUuid
    );

    const chart = getChartVisualization(blocks, content, convertedReport);
    const isObsolete = checkObsoleteConfig(chart, convertedReport);

    return isObsolete ? (
      <TemplateMessage>
        <FontAwesomeIcon icon={["fas", "info-circle"]} />
        Page template has changed
      </TemplateMessage>
    ) : null;
  }

  return (
    <Table border>
      <thead>
        <tr>
          <th width="40px" />
          <th>Content</th>
          <th>Schedule</th>
          <th>Recipients</th>
          <th width="40px" />
          <th />
        </tr>
      </thead>
      <tbody>
        {reportedQueries.map((report) => (
          <tr key={report.uuid}>
            <td>{getEditIcon(report)}</td>
            <td>
              <span>{report.name}</span>
              {renderObsoleteMessageComponent(report)}
            </td>
            <td>{getFrequencyTableString(report)}</td>
            <td>
              <Flex alignItems="center">
                <ChipList
                  visible={report.recipients}
                  width={20}
                  height={20}
                  fontSize={11}
                  limit={7}
                />
              </Flex>
            </td>
            <td>
              <Switcher
                checked={isActive[report.uuid] ?? !!report.active}
                handleSwitchChange={(e) => changeActive(e, report)}
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={["fas", "trash-alt"]}
                onClick={(e) => deleteReport(e, report.uuid)}
                style={{
                  color: theme.notification.errorMain,
                  cursor: "pointer",
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

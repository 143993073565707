import React from "react";
import LoginFormErrors from "./LoginFormErrors";
import FormInputGroup from "./FormInputGroup";
import Input from "../../UI/Form/Input/Input";
import IOButton from "../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";

const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-radius: 10px;
  padding: 20px 50px;
  width: 300px;
  margin: auto;
`;

export default function LoginForm(props) {
  const {
    authErrors,
    loading,
    setFocus,
    email,
    setEmail,
    password,
    setPassword,
    setForgot,
  } = props;

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  return (
    <Container>
      <p>LOGIN CREDENTIALS</p>
      <LoginFormErrors errors={authErrors} cy="error-login-page" />

      <FormInputGroup>
        <Input
          type="text"
          name="email"
          placeholder="Email"
          data-cy="email-login"
          onChange={onEmailChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </FormInputGroup>

      <FormInputGroup>
        <Input
          type="password"
          name="password"
          placeholder="Password"
          data-cy="password-login"
          onChange={onPasswordChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </FormInputGroup>

      <div style={{ marginTop: 8 }}>
        <IOButton
          fit
          processing={loading}
          cy="submit-login"
          disabled={!email || !password}
        >
          Login
        </IOButton>
      </div>

      <div
        onClick={() => setForgot(true)}
        style={{
          marginTop: 26,
          paddingLeft: 12,
          textAlign: "left",
          cursor: "pointer",
        }}
      >
        Forgot password?
      </div>
    </Container>
  );
}

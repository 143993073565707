import React from "react";
import IOButton from "../../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeColor, themeText } from "../../../styles/styledSnippets";

const Group = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  button {
    margin-right: 6px;
  }
`;

const NotificationGroup = styled.div`
  color: ${themeText("primary")};
  display: flex;
`;

const Title = styled.p`
  font-weight: 800;
  font-size: 18px;
`;

const FieldName = styled.span`
  color: ${themeColor("infoMain")};
  font-weight: 800;
`;

export default function ConfirmDeleteActiveSettings(props) {
  return (
    <div data-cy="delete-warning">
      <NotificationGroup>
        <div style={{ fontSize: 80, marginRight: 12 }}>☠️</div>
        <div style={{ lineHeight: "1em" }}>
          <Title>Warning!</Title>
          <p>
            There is data in the field{" "}
            <FieldName>'{props.column.field}'</FieldName> you're trying to
            delete.
          </p>
          <p>If you continue, this data will be lost. This cannot be undone.</p>
        </div>
      </NotificationGroup>
      <Group>
        <IOButton
          onClick={props.cancelDeleting}
          cancel
          cy="cancel-delete-field"
        >
          Cancel
        </IOButton>
        <IOButton info cy="confirm-delete" onClick={props.confirmDelete}>
          <FontAwesomeIcon icon={["fas", "trash"]} />
          <span style={{ paddingLeft: 10 }}>
            I understand, delete this field anyway
          </span>
        </IOButton>
      </Group>
    </div>
  );
}

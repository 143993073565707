import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import "tippy.js/animations/scale.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/shift-toward.css";
import "tippy.js/animations/perspective.css";

export default function TippyTooltipWrapper({ children, content, ...rest }) {
  return (
    <Tippy
      placement="top"
      animation="scale"
      arrow={true}
      theme="material"
      // Need to ensure it can be tabbed to directly after with no clipping issues
      appendTo="parent"
      content={content}
      {...rest}
    >
      <div>{children}</div>
    </Tippy>
  );
}

import Select from "../../../../../UI/Form/Select/Select";
import { extraColumnOperators } from "../../../../../utils/constants/constants";

export default function Operator({ chart, setChartState }) {
  const { groupingExtraColumns } = chart;
  const { operator } = groupingExtraColumns;

  const operatorOption = extraColumnOperators.find(
    (option) => option.value === operator
  );

  function onOperatorChange(option) {
    setChartState({
      ...chart,
      groupingExtraColumns: { ...groupingExtraColumns, operator: option.value },
    });
  }

  return (
    <Select
      options={extraColumnOperators}
      value={operatorOption}
      onChange={onOperatorChange}
      label="Operator"
    />
  );
}

import { useState } from "react";

export default (initial) => {
  const [state, setState] = useState(!!initial);

  function setter() {
    setState((s) => !s);
  }
  return [state, setter];
};

import React from "react";
import Loading from "../../../../UI/Loading/Loading";
import PropTypes from "prop-types";

export default function FilterLoadingOrError(props) {
  const { error, setError, setLoading } = props;
  function clearError() {
    setError(null);
    setLoading(false);
  }
  return !error ? (
    <Loading />
  ) : (
    <div data-cy="filter-error" onClick={clearError}>
      Error: {error}
    </div>
  );
}

FilterLoadingOrError.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
};

/**
 * Formats:
 * - local (file json)
 * - request (API)
 * - response (API)
 */
import { isEqual } from "lodash-es";

/**
 * @typedef VisualizationFormFormat
 * @typedef VisualizationLocalFormat
 * @typedef VisualizationRequestFormat
 * @typedef VisualizationResponseFormat
 */

export const visualizationFormatConverter = {
  local: {
    toRequest: convertLocalToRequest,
  },
  activityLog: {
    toForm: convertActivityLogToForm,
  },
  form: {
    toRequest: convertFormToRequest,
  },
  response: {
    toLocal: convertResponseToLocal,
    toForm: convertResponseToForm,
  },
};

/**
 * @param {VisualizationResponseFormat} response
 * @returns {VisualizationFormFormat}
 */
function convertResponseToForm(response) {
  const { visualizationId, ...localFormatWithoutVisualizationId } =
    convertResponseToLocal(response);
  const ret = {
    stagedForDelete: false,
    jsonDraft: JSON.stringify(localFormatWithoutVisualizationId, null, 2),
  };
  if (response.uuid) {
    ret.uuid = response.uuid;
  }
  return ret;
}

/**
 @param {VisualizationFormFormat} form
 @returns {VisualizationRequestFormat}
 */
function convertFormToRequest(form) {
  const ret = {
    ...convertLocalToRequest(JSON.parse(form.jsonDraft)),
  };
  if (form.uuid) {
    ret.uuid = form.uuid;
  }
  return ret;
}

/**
 * @param {VisualizationLocalFormat} input
 * @returns {VisualizationRequestFormat}
 */
function convertLocalToRequest(input) {
  const {
    filters = null,
    overrides = null,
    orders = null,
    queryId,
    visualizationId,
    ...settings
  } = input;

  return {
    filters,
    overrides,
    orders,
    settings,
    queryUuid: queryId ?? null,
  };
}

/**
 * @param {VisualizationResponseFormat} input
 * @returns {VisualizationLocalFormat}
 */
function convertResponseToLocal(input) {
  const queryId = input.query?.uuid;

  const { filters = null, overrides = null, orders = null, settings } = input;

  return {
    filters,
    overrides,
    orders,
    ...(queryId != null ? { queryId } : {}),
    visualizationId: input.uuid,
    ...settings,
  };
}
function convertRequestToLocal(input) {
  const queryId = input.queryUuid;

  const { filters = null, overrides = null, orders = null, settings } = input;

  return {
    filters,
    overrides,
    orders,
    ...(queryId != null ? { queryId } : {}),
    visualizationId: input.uuid,
    ...settings,
  };
}

function convertRequestToForm(input) {
  const { visualizationId, ...localFormatWithoutVisualizationId } =
    convertRequestToLocal(input);
  const ret = {
    stagedForDelete: false,
    jsonDraft: JSON.stringify(localFormatWithoutVisualizationId, null, 2),
  };
  return ret;
}

function convertActivityLogToForm(input) {
  const ret = convertRequestToForm(input);
  if (input.uuid) {
    ret.uuid = input.uuid;
  }
  return ret;
}

/**
 * Returns if a given form format visualization should be included when mass
 * updating/inserting.
 *
 * @param {VisualizationFormFormat} form
 * @returns {boolean}
 */
export function shouldVisualizationFormItemBeIncluded(form) {
  return (
    !form.stagedForDelete &&
    !areFormItemsEqual(form, createNewVisualizationField())
  );
}

/**
 * @param {VisualizationFormFormat} form1
 * @param {VisualizationFormFormat} form2
 * @returns {boolean}
 */
function areFormItemsEqual(form1, form2) {
  return isEqual(
    { ...form1, jsonDraft: JSON.parse(form1.jsonDraft) },
    { ...form2, jsonDraft: JSON.parse(form2.jsonDraft) }
  );
}

/**
 * @returns {VisualizationFormFormat}
 */
export function createNewVisualizationField() {
  return {
    isNew: true,
    ...visualizationFormatConverter.response.toForm({
      overrides: [],
      filters: [],
      orders: [],
      settings: {},
      query: {
        uuid: "",
      },
    }),
  };
}

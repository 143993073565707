import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { findBestMatch } from "string-similarity";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import RadioButton from "../../../UI/RadioButton/RadioButton";
import { extendMenuFilters } from "../../../store/actions";
import FilterInputs from "./FilterInputs";
import { useTheme } from "emotion-theming";
import {
  customFilterTypes,
  dependencyFiltersMapping as mappings,
} from "../../../utils/constants/constants";
import { orderBy } from "lodash-es";

const Container = styled.div`
  padding: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.menuPrimary ? "rgba(255,255, 255, 0.1)" : theme.divider};
  & > p {
    text-align: left;
    margin-left: 10px;
  }
  color: ${({ theme }) => (theme.menuPrimary ? "white" : theme.secondary)};
`;

const Radios = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`;

export default function DependencyFilter({ filters }) {
  const [potential, setPotential] = useState(COMPANY);
  const [varyBy, setVaryBy] = useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [newFilters, setNewFilters] = useState({});
  const isCompany = potential === COMPANY;
  const dispatch = useDispatch();
  const theme = useTheme();

  const hasDependency = filters.some(
    (f) => f.name === REVENUE || f.name === EMPLOYEES
  );

  const buildFilters = useCallback((newFilters) => {
    return Object.keys(newFilters).map((key) => {
      const { bestMatch } = findBestMatch(key, Object.keys(mappings));
      const name = mappings[bestMatch.target];

      return {
        name,
        type: "input",
        extended: true,
        values: [
          {
            checked: true,
            key: name,
            type: name,
            value: newFilters[key],
          },
        ],
      };
    });
  }, []);

  const onSubmit = useCallback(
    (newFilters) => {
      const res = buildFilters(newFilters);
      dispatch(extendMenuFilters(res, true));
    },
    [buildFilters, dispatch]
  );

  useEffect(() => {
    const avgPotentialBy = isCompany ? REVENUE : EMPLOYEES;
    const values =
      filters.find((mf) => mf.name === avgPotentialBy)?.values ?? [];

    const inputs = (
      values.some((v) => v.checked) ? values.filter((v) => v.checked) : values
    ).filter((i) => i.value);

    setFilterInputs(inputs);
  }, [filters, isCompany]);

  useEffect(() => {
    const existingFilters = {
      ...filterInputs.reduce((acc, curr) => {
        const existing = newFilters[curr.value];
        if (existing) {
          acc[curr.value] = existing;
        }
        return acc;
      }, {}),
      ...(newFilters.percentToEnclude && {
        percentToEnclude: newFilters.percentToEnclude,
      }),
    };

    setNewFilters(existingFilters);
    if (hasDependency) {
      onSubmit(existingFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputs.length, hasDependency]);

  const onChange = useCallback(
    ({ value }, el) => {
      setNewFilters({ ...newFilters, [el.value]: value });
    },
    [newFilters]
  );

  const onRadioChange = useCallback(
    (potential) => {
      setNewFilters({});
      setPotential(potential);
      dispatch(extendMenuFilters([], true));
    },
    [dispatch]
  );

  const onCheckboxChange = useCallback(
    (varyBy) => {
      const filters = newFilters.percentToEnclude
        ? { percentToEnclude: newFilters.percentToEnclude }
        : {};

      setNewFilters(filters);

      const res = buildFilters(filters);
      setVaryBy(varyBy);
      dispatch(extendMenuFilters(res, !varyBy));
    },
    [buildFilters, dispatch, newFilters.percentToEnclude]
  );

  const { fn, direction } =
    customFilterTypes.find((x) => x.type === filterInputs[0]?.type) ?? {};

  const withSortValue = fn
    ? filterInputs.map((fi) => ({ ...fi, sortValue: fn(fi.value) }))
    : filterInputs;

  const ordered = orderBy(withSortValue, "sortValue", direction);

  if (!hasDependency) {
    return null;
  }

  return (
    <Container>
      <p>Avg. Potential by</p>
      <Radios>
        <RadioButton
          name={COMPANY}
          checked={isCompany}
          onChange={() => onRadioChange(COMPANY)}
          size={17}
          offset={5}
        />
        <RadioButton
          name={EMPLOYEE}
          checked={!isCompany}
          onChange={() => onRadioChange(EMPLOYEE)}
          size={17}
          offset={5}
        />
      </Radios>
      <br />
      <Checkbox
        label={`Vary by ${
          potential === COMPANY ? "company size" : "# employees"
        }`}
        checked={varyBy}
        onChange={() => onCheckboxChange(!varyBy)}
        overPrimary={theme.menuPrimary}
      />
      <br />
      <FilterInputs
        varyBy={varyBy}
        filterInputs={ordered}
        newFilters={newFilters}
        isCompany={isCompany}
        change={onChange}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const COMPANY = "Company";
const EMPLOYEE = "Employee";
const REVENUE = "ACCOUNT_REVENUE_FILTER_BAND";
const EMPLOYEES = "ACCOUNT_EMPLOYEE_FILTER_BAND";

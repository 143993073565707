import React from "react";
import styled from "@emotion/styled";
import { flexCenter } from "../../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  width: 28px;
  height: 30px;
  right: 88px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
  div {
    top: 6px;
  }
  ${(props) =>
    !props.relative &&
    `
    position: absolute;
    bottom: -30px;
  `}
`;

const Container = styled.div`
  position: relative;
  top: -11px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.theme.notification.infoMain};
  ${flexCenter};
  color: white;
  cursor: pointer;
  transition: 0.1s ease top;
`;

export default function AddDot(props) {
  return (
    <Wrapper
      onClick={props.onClick}
      data-cy={props.cy || "explore-list-add-value"}
      relative={props.relative}
    >
      <Container>
        <FontAwesomeIcon icon={["fal", "plus"]} />
      </Container>
    </Wrapper>
  );
}

import React, { useRef, useState } from "react";
import IconCheckbox from "../../../UI/IconCheckbox/IconCheckbox";
import styled from "@emotion/styled";
import AccessGroupOption from "./AccessGroupOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../UI/Flex/Flex";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { themeGrayIf } from "../../../styles/styledSnippets";
import { useTheme } from "emotion-theming";

const Container = styled.div`
  width: 200px;
  border-left: 1px solid gray;
  padding-left: 12px;
`;

function border(props) {
  return props.open
    ? `2px solid ${props.theme.notification.infoMain}; margin-top: -1px;`
    : `1px solid ${themeGrayIf(700, 300)(props)}`;
}

const SelectMain = styled.div`
  background: ${(props) => (props.theme.type === "dark" ? "#303030" : "white")};
  padding: 7px;
  border-radius: 4px;
  box-sizing: border-box;
  border: ${border};
  max-width: 172px;
  &:hover {
    ${(props) =>
      !props.open ? `border: 1px solid ${props.theme.gray.gray500}` : ""}
  }
`;

const Chevron = styled.div`
  path {
    fill: ${(props) => props.theme.gray.gray400};
  }
  svg {
    position: relative;
    top: -2px;
  }
  padding: 4px 4px 4px 12px;
  height: 12px;
  border-left: 1px solid ${(props) => props.theme.gray.gray400};
`;

export default function ActiveTableColumnSettingsPanel(props) {
  const {
    accessGroups = [],
    setAccessGroups,
    colDef,
    activeTableUpdateLoading,
  } = props;
  const [selecting, setSelecting] = useState(false);
  const theme = useTheme();
  const ref = useRef(null);
  useOnClickOutside(ref, closeSelecting);
  const values = colDef?.cellEditorParams?.accessGroups || [];

  const emptyValue = { uuid: "777", displayName: "Everyone" };
  const accessGroupsOptions = [emptyValue, ...accessGroups];

  function closeSelecting() {
    setSelecting(false);
  }

  function handleAccessChange(val) {
    if (val.uuid === "777") return setAccessGroups(colDef.colId, []);
    const valueAlreadySelected = values.find((v) => v === val.uuid);
    if (valueAlreadySelected) {
      const nextValues = values.filter((v) => v !== val.uuid);
      setAccessGroups(colDef.colId, nextValues);
    } else {
      const nextAccessGroups = [...values.filter((v) => v !== "777"), val.uuid];
      setAccessGroups(colDef.colId, nextAccessGroups);
    }
  }

  const activeValues = values.length ? values : ["777"];

  return (
    <Container>
      <div style={{ marginTop: props.locked ? 6 : -2 }}>
        <IconCheckbox
          lib="fal"
          iconName="lock-open"
          activeIconName="lock"
          title="Locked"
          label="Unlocked"
          activeLabel="Locked"
          cy="toggle-locked"
          onChange={props.handleLocked}
          checked={props.locked}
        />
        {activeTableUpdateLoading && (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            pulse
            style={{ color: theme.text.primary, marginLeft: 10 }}
          />
        )}
      </div>
      {props.locked ? null : (
        <div style={{ marginTop: 9 }} data-cy="access-groups-selection">
          <SelectMain open={selecting}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setSelecting((s) => !s)}
            >
              <Flex>
                {activeValues.map((v, i, s) => (
                  <AccessGroupOption
                    i={accessGroupsOptions.findIndex((o) => o.uuid === v)}
                    value={accessGroupsOptions.find((o) => o.uuid === v)}
                    singleSelected={s.length === 1}
                    key={v}
                  />
                ))}
              </Flex>
              <Chevron>
                <FontAwesomeIcon icon={["fas", "chevron-down"]} />
              </Chevron>
            </Flex>
          </SelectMain>
          {selecting ? (
            <div ref={ref} style={{ position: "relative", color: "white" }}>
              <div style={{ position: "absolute", zIndex: 100, width: 172 }}>
                <SelectMain style={{ marginTop: 12 }}>
                  {accessGroupsOptions.map((g, i) => (
                    <div key={g.uuid} style={{ margin: "8px 0" }}>
                      <AccessGroupOption
                        i={i}
                        value={g}
                        active={activeValues.find((v) => {
                          return v === g.uuid;
                        })}
                        optionMode
                        handleClick={() => handleAccessChange(g)}
                      />
                    </div>
                  ))}
                </SelectMain>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Container>
  );
}

import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import { columnFormats } from "../../../../utils/constants/constants";
import { getNestedFormat } from "../Layout/helper";

const SelectWrapper = styled.div`
  width: ${(props) => props.width}%;
  position: relative;
  margin-right: 30px;
`;

export default function FormatOverrideGroupedRow(props) {
  const { conditionKeyOptions, override, onOverrideItemChange, pair } = props;
  const { conditionKey } = pair ?? {};

  return (
    <>
      <SelectWrapper width={43}>
        <Select
          options={conditionKeyOptions.filter(
            (option) => option.value !== conditionKey
          )}
          label="Column Name"
          value={conditionKeyOptions.find(
            (option) => option.value === override.overrideKey
          )}
          onChange={(option) =>
            onOverrideItemChange(option, "overrideKey", override.index)
          }
        />
      </SelectWrapper>

      <SelectWrapper width={40}>
        <Select
          options={columnFormats}
          label="Format"
          value={getNestedFormat(override.overrideFormat)}
          onChange={(option) =>
            onOverrideItemChange(option, "overrideFormat", override.index)
          }
        />
      </SelectWrapper>
    </>
  );
}

import React from "react";
import FilterEditor from "../../../Pages/DataManagement/DataExplorer/Filters/FilterEditor";
import { useSelector } from "react-redux";

export default function ActiveTableViewFilterEditor(props) {
  const { queryFields } = useSelector((state) => state.activeTable);
  const filterApi = props.filterApi;

  return (
    <>
      <div>Added filters</div>
      <FilterEditor
        {...filterApi}
        filterApi={filterApi}
        queryId={props.queryId}
        fields={queryFields}
      />

      {/*<div*/}
      {/*  style={{*/}
      {/*    margin: "32px 0",*/}
      {/*    width: "100%",*/}
      {/*    borderTop: "1px solid rgba(100, 100, 100, 0.4)",*/}
      {/*    height: 1,*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  );
}

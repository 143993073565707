import * as actions from "../actions/actionTypes";

export const initialState = {
  loading: false,
  counties: null,
  states: null,
  drillDownDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_COUNTIES_START:
    case actions.LOAD_STATES_START:
    case actions.LOAD_GEO_MAP_DETAILS_START:
      return {
        ...state,
        loading: true,
      };

    case actions.LOAD_COUNTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        counties: action.results.data,
      };

    case actions.LOAD_COUNTIES_FAIL:
    case actions.LOAD_STATES_FAIL:
    case actions.LOAD_GEO_MAP_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.LOAD_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        states: action.results.data,
      };

    case actions.LOAD_GEO_MAP_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        drillDownDetails: {
          data: action.results.data,
          meta: action.results.meta,
        },
      };

    default:
      return state;
  }
};

/* eslint-disable no-console */
import React, { useEffect } from "react";
import { laravelEchoWrapper } from "../../utils/websockets";
import { useDispatch } from "react-redux";
import { showToastWithTimeout } from "../../store/actions/message";
import axios from "../../axios";
import { normalizeError } from "../../utils/errorHandling";
import IOButton from "../../UI/Form/Button/IOButton";

/**
 * Secret page for testing websockets.
 */
export default function WebsocketTestPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const channelName = "test";
    const channel = laravelEchoWrapper.private(channelName);
    const listenCallback = (message) => {
      console.info("message received", message);
      showToastWithTimeout(dispatch, [message.message], "success");
    };
    const testEventName = "WebsocketTestSent";
    channel.listen(testEventName, listenCallback);

    return () => {
      channel.stopListening(testEventName, listenCallback);
      try {
        laravelEchoWrapper.leave(channelName);
      } catch (e) {
        console.log("error: ", e);
      }
    };
  }, [dispatch]);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        Clicking on the button below should trigger an API call, and you should
        see a success toast message if the websocket message got received
        successfully.
      </div>

      <IOButton
        type="button"
        onClick={() =>
          axios.post("/api/v1/test_websockets").catch((e) => {
            console.error(e);
            showToastWithTimeout(
              dispatch,
              [normalizeError(e).message],
              "danger"
            );
          })
        }
      >
        Test websocket
      </IOButton>
    </div>
  );
}

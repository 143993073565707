import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from ".././../../store/actions/index";
import DataMapStage from "./DataMapStage";

export default function DataMap() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getDataFlow());
  }, [dispatch]);

  const flows = useSelector((state) => state.etlConfigs.flows);

  return (
    <div>
      <DataMapStage flows={flows} />
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tipper from "../../../../UI/Tipper/Tipper";

const Container = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.gray.gray300};
  background: ${(props) => props.theme.background.primary};
  // margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -32px;
  top: -1px;

  &:hover {
    background: ${(props) => props.theme.hover};
  }
`;

export default function AddOverrideColumn(props) {
  return (
    <div style={{ position: "relative" }}>
      <Tipper label="Add a column...">
        <Container onClick={props.handleClick}>
          <FontAwesomeIcon icon={["fas", "plus"]} />
        </Container>
      </Tipper>
    </div>
  );
}

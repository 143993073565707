export function doOverride(formatOverrides, dataRow, subKey, formatsByKey) {
  const overrideMatch = formatOverrides.find((formatOverride) => {
    const { conditionKey, conditionValue, overrideKey, overrideFormat } =
      formatOverride;

    function shouldUseOverride() {
      const matchedValue = dataRow[conditionKey]?.trim() === conditionValue;
      const configuredToMatch = subKey === overrideKey;
      return matchedValue && configuredToMatch;
    }

    return shouldUseOverride() ? overrideFormat : null;
  });

  return overrideMatch || formatsByKey[subKey];
}

import styled from "@emotion/styled";
import React from "react";
import IOButton from "../../../UI/Form/Button/IOButton";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import FixedRows from "./FixedRows";
import Rollup from "./Rollup";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function RollupAndFixedRows(props) {
  const { state, setState } = props;

  const addRollup = () => {
    setState({
      ...state,
      chart: {
        ...state.chart,
        rollup: {
          matchForRow: [],
          matchForRowLabelKey: null,
          dynamicColumnKey: "",
          dynamicColumnValue: "",
          dynamicColumnFormat: null,
          dynamicHeaderFormat: null,
        },
      },
    });
  };

  const addFixedRow = () => {
    const fixedRows = state.chart.fixedRows ? [...state.chart.fixedRows] : [];

    fixedRows.push({
      type: "header",
      label: "",
      color: "",
      values: [],
    });

    setState({
      ...state,
      chart: {
        ...state.chart,
        fixedRows,
      },
    });
  };

  return (
    <Container>
      <ButtonGroup spacing={20} position="flex-end">
        <IOButton
          style={{ width: "fit-content", alignSelf: "flex-end" }}
          smallPadding
          onClick={addRollup}
          add
          outline
          disabled={!state.query || !!state.chart.rollup}
        >
          Add Rollup
        </IOButton>

        <IOButton
          style={{ width: "fit-content", alignSelf: "flex-end" }}
          smallPadding
          onClick={addFixedRow}
          add
          outline
          disabled={!state.query}
        >
          Add Fixed Row
        </IOButton>
      </ButtonGroup>

      <br />

      <Rollup {...props} />

      <br />

      <FixedRows {...props} />
    </Container>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IOButton from "../../../UI/Form/Button/IOButton";
import TextArea from "../../../UI/Form/Textarea/Textarea";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    width: ${(props) => (props.fullSize ? 100 : 48)}%;
  }
`;

const Subtitle = styled.div`
  position: relative;
  & > svg {
    position: absolute;
    right: -5px;
    top: 12px;
    font-size: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.notification.errorMain};
  }
`;

export default function Subtitles({
  disabled,
  subTitles,
  onSubtitleChange,
  deleteSubtitle,
  addSubtitle,
  fullSize,
}) {
  return (
    <>
      <Container fullSize={fullSize}>
        {subTitles?.map((st, index) => (
          <Subtitle key={"sub" + index}>
            <TextArea
              onChange={({ target }) => onSubtitleChange(target, index)}
              label={"Subtitle " + (index + 1)}
              value={st.join(",")}
            />
            <FontAwesomeIcon
              icon={["fa", "times"]}
              onClick={() => deleteSubtitle(index)}
            />
          </Subtitle>
        ))}
      </Container>
      <br />

      <IOButton
        style={{ width: "fit-content", alignSelf: "flex-end" }}
        smallPadding
        onClick={addSubtitle}
        add
        outline
        disabled={disabled}
      >
        Add Subtitle
      </IOButton>
    </>
  );
}

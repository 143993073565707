import * as actionTypes from "../../actions/actionTypes";
import produce from "immer";
import { setDataUniqueRowUuid } from "../layoutReducer";
import padEmptyDateValues from "../../../utils/dates/padEmptyDateValues";
import { buildLocalRowExpandGroupedData } from "../../../utils/dataOptionsValues";

const initialState = {
  tablePreview: {
    loading: true,
  },
  visualizations: {},
  builder: {
    chartType: null,
    xValue: null,
    yValue: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TABLE_PREVIEW_DATA_SUCCESS:
      return {
        ...state,
        tablePreview: {
          loading: false,
          ...action.table,
        },
      };

    case actionTypes.GET_VISUALIZATION_PREVIEW_START:
      return produce(state, (draft) => {
        draft.visualizations[action.payload] = createNewVisualizationState(
          action.config.padZero
        );
      });

    case actionTypes.GET_VISUALIZATION_PREVIEW_SUCCESS:
      let tData = setDataUniqueRowUuid(action.result.data);
      const { chart } = action;

      // set only sumarry data fro drilldown here
      // drilldown rows data set in runVisualizationPreviewExec action
      if (chart?.localRowExpandGrouping) {
        const { mainData } = buildLocalRowExpandGroupedData(
          tData,
          chart,
          action.result.meta
        );

        tData = mainData;
      }

      return produce(state, (draft) => {
        const padZero = (draft.visualizations[action.key] ?? {}).padZero;

        const formattedData = padEmptyDateValues(
          tData,
          action.dateFilters,
          padZero
        );

        draft.visualizations[action.key] = {
          ...(draft.visualizations[action.key] ?? {}),
          loading: false,
          data: formattedData,
          meta: action.result.meta,
          term: action.term,
          cacheKey: action.cacheKey,
          invalidMessage: null,
        };
      });

    case actionTypes.GET_VISUALIZATION_PREVIEW_FAIL:
      return produce(state, (draft) => {
        draft.visualizations[action.payload.key] = {
          error: action.payload.error,
          loading: false,
        };
      });

    case actionTypes.RESET_VISUALIZATION_PREVIEW:
      return produce(state, (draft) => {
        delete draft.visualizations[action.payload];
      });

    case actionTypes.SET_INVALID_PREVIEW_CONFIG_MESSAGE:
      return produce(state, (draft) => {
        if (draft.visualizations[action.visualizationUuid]) {
          draft.visualizations[action.visualizationUuid].loading = false;
          draft.visualizations[action.visualizationUuid].invalidMessage =
            action.message;
        }
      });

    // I think all below is legacy

    case actionTypes.CHART_BUILDER_SET_CHART_TYPE:
      return {
        ...state,
        builder: { ...state.builder, chartType: action.chartType },
      };

    case actionTypes.CHART_BUILDER_SET_X_VALUE:
      return {
        ...state,
        builder: { ...state.builder, xValue: action.xValue },
      };

    case actionTypes.CHART_BUILDER_SET_Y_VALUE:
      return {
        ...state,
        builder: { ...state.builder, yValue: action.yValue },
      };

    case actionTypes.CHART_BUILDER_SET_GROUP_BY:
      return {
        ...state,
        builder: { ...state.builder, groupBy: action.groupBy },
      };

    default:
      return state;
  }
};

export function createNewVisualizationState(padZero) {
  return {
    loading: true,
    padZero,
  };
}

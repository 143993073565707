export const sales = {
  columns: [
    {
      name: "ImmutableOpportunityId",
      source: {
        table: "etl.stg_delta_SalesforceOpportunity",
        column: "Id",
      },
    },
    {
      name: "CloseDateKey",
      source: {
        table: "etl.stg_delta_SalesforceOpportunity",
        column: "CloseDate",
      },
    },
    {
      name: "ProductKey",
      source: {
        table: "etl.stg_delta_SalesforceOpportunity",
        column: "CustomFieldProductType__c",
      },
    },
    {
      name: "CustomerKey",
      source: {
        table: "etl.stg_delta_SalesforceOpportunity",
        column: "AccountId",
      },
    },
    {
      name: "TotalRevenue",
      source: {
        table: "etl.stg_delta_SalesforceOpportunity",
        column: "Amount",
      },
    },
    {
      name: "Stage",
      source: {
        table: "etl.stg_delta_SalesforceOpportunity",
        column: "StageName",
      },
    },
  ],
  sourceFilters: [
    {
      table: "etl.stg_delta_SalesforceOpportunity",
      column: "Id",
      operator: "!=",
      value: ["null"],
    },
  ],
  orderBy: {
    name: "Name",
  },
};

export const customer = {
  columns: [
    {
      name: "ImmutableCustomerId",
      source: {
        table: "etl.stg_delta_SalesforceAccount",
        column: "Id",
      },
    },
    {
      name: "CustomerName",
      source: {
        table: "etl.stg_delta_SalesforceAccount",
        column: "Name",
      },
    },
  ],
  sourceFilters: [
    {
      table: "etl.stg_delta_SalesforceAccount",
      column: "IsDeleted",
      operator: "==",
      value: ["false"],
    },
  ],
};

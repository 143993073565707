import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRidOfAggregation } from "../../../../charts/TableView/Elements/EditableMenu";
import { getAggregationPrefix } from "../Layout/Column/RegularColumn";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  margin: 20px 0px;
  align-items: end;
  & > svg {
    cursor: pointer;
    font-size: 22px;
    color: ${theme.primary};
    margin: 0px 5px 10px 10px;
  }
`
);

export default function TooltipDependencySettings({
  tooltipConfig,
  setChartState,
  chart,
  fields,
  rows,
  setRows,
}) {
  if (!tooltipConfig) {
    return null;
  }

  const { showOnColumn } = tooltipConfig;

  const value = fields.find(
    (field) => field.name === getRidOfAggregation(showOnColumn, chart.overrides)
  );

  /**
   * Adds a new tooltip row item to the chart state.
   */
  function addTooltipRowItem() {
    const row = { key: "", type: "", label: "" };
    const newRows = [...rows, row];

    // set to local state for dnd
    setRows(newRows);

    setChartState({
      ...chart,
      tooltipConfig: {
        ...tooltipConfig,
        tooltipRows: newRows,
      },
    });
  }

  function changeShowOnColumnn(option) {
    const { name } = option;

    // Find the override object in the overrides array where the name matches the option name
    const { aggregation } =
      (chart.overrides ?? []).find((override) => override.name === name) ?? {};

    // Get the aggregation prefix using the option name and the aggregation from the found override
    const prefix = getAggregationPrefix(aggregation);

    setChartState({
      ...chart,
      tooltipConfig: {
        ...tooltipConfig,
        showOnColumn: prefix + name,
      },
    });
  }

  return (
    <Container>
      <Select
        options={fields}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        value={value}
        onChange={changeShowOnColumnn}
        label="Show on column"
      />

      <FontAwesomeIcon
        icon={["fal", "plus-circle"]}
        onClick={addTooltipRowItem}
        title="Add field"
      />
    </Container>
  );
}

import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import UserChip from "./UserChip";
import SimpleTooltip from "../SimpleTooltip/SimpleTooltip";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Container = styled.div(
  ({ theme, primaryColor, outline }) => `
    display: flex;
    width: fit-content;
    margin: 8px 10px;
    align-items: center;
    font-size: 14px;
    // border: 1px solid ${primaryColor ? theme.primary : theme.primary};
    background: ${
      outline ? null : primaryColor ? theme.primary : theme.chip.background
    };
    color: ${
      outline ? theme.text.primary : primaryColor ? "#FFF" : theme.text.primary
    };
    border-radius: 18px;
    cursor: pointer;

    &:hover {
        background: ${lightenDarkenColor(
          primaryColor ? theme.primary : theme.chip.background,
          -20
        )};
        color: ${primaryColor ? "#FFF" : theme.text.primary};
    }

    & > div > span {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & > div > svg {
        margin: 0px 15px;
    }

    & > div > svg:hover {
        color: ${theme.text.secondary};
    }
`
);

const Text = styled.span(
  ({ theme }) => `
  font-size: 10px;
  color: ${theme.notification.errorDark};
  &:hover {
    text-decoration: underline;
  }
  margin-right: 10px;
  vertical-align: middle;
`
);

export default function ActionChip(props) {
  const {
    label,
    image,
    deleteItem,
    data,
    tooltip,
    tooltipPosition,
    primaryColor,
    outline,
    clickItem,
    width,
    height,
    fontSize,
    chipOnly,
    deleteText,
  } = props;

  const ref = useRef(null);
  useOnClickOutside(ref, (e) => (clickItem ? clickItem(data, e) : null));

  const details = (
    <div ref={ref}>
      <span style={{ margin: "0px 5px" }}>{label}</span>
      {deleteText && <span>| </span>}
      {deleteItem && deleteText ? (
        <Text
          onClick={(e) => {
            e.stopPropagation();
            deleteItem(data);
            clickItem(data, e);
          }}
        >
          {deleteText}
        </Text>
      ) : (
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            e.stopPropagation();
            deleteItem(data);
          }}
        />
      )}
    </div>
  );

  const chip = (
    <Container
      primaryColor={primaryColor}
      outline={outline}
      onClick={(e) => (clickItem ? clickItem(data, e) : null)}
      data-cy={props.cy || "action-chip-container"}
    >
      <UserChip
        name={label}
        rounded
        image={image}
        width={width}
        height={height}
        fontSize={fontSize}
      />
      {chipOnly ? null : details}
    </Container>
  );

  return (
    <>
      {tooltip ? (
        <SimpleTooltip position={tooltipPosition} label={tooltip}>
          {chip}
        </SimpleTooltip>
      ) : (
        chip
      )}
    </>
  );
}

ActionChip.defaultProps = {
  tooltipPosition: "bottom",
};

ActionChip.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltipPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  label: PropTypes.string.isRequired,
  image: PropTypes.string,
  deleteItem: PropTypes.func,
  data: PropTypes.object,
  primaryColor: PropTypes.bool,
  outline: PropTypes.bool,
  clickItem: PropTypes.func,
  active: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  chipOnly: PropTypes.bool,
  cy: PropTypes.string,
};

import React from "react";
import styled from "@emotion/styled";

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  margin-bottom: 12px;
  height: 8px;
`;

export default function ChartDivider() {
  return <Divider />;
}

import React, { useEffect, useState } from "react";
import ChartFactory from "../../charts/ChartFactory/ChartFactory";
import { isEqual } from "lodash-es";
import styled from "@emotion/styled";
import { scrollbarDe } from "../../styles/styledSnippets";

const Error = styled.div`
  color: ${({ theme }) => theme.notification.errorMain};
  text-align: left;
  padding: 5px;
  font-size: 12px;
`;

const Container = styled.div(
  ({ theme }) => `
  height: calc(100vh - 90px);
  overflow-y: auto;
  ${scrollbarDe(theme)};
`
);

export default function Preview({ chart, showPreview, previewChart }) {
  const [prevChart, setPrevChart] = useState(chart);

  useEffect(() => {
    if (!isEqual(prevChart, chart)) {
      showPreview(chart, true);
      setPrevChart(chart);
    }
  }, [chart, prevChart, showPreview]);

  const { errors, meta, data, loading, loaded, refreshing } =
    previewChart || chart;

  return (
    <Container>
      {errors?.normalizedError ? (
        <>
          <Error>{errors.message}</Error>
          {Object.values(errors.data || {}).map((error, i) => (
            <Error key={error + i}>{error}</Error>
          ))}
        </>
      ) : (
        <ChartFactory
          chart={{
            ...previewChart,
            meta,
            data,
            loading,
            loaded,
            refreshing,
            updatePreview: () => showPreview(chart, true),
          }}
          type={chart.type}
        />
      )}
    </Container>
  );
}

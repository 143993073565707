// order data by order array schema
export const orderData = (
  xValueKey,
  yValueKey,
  theme,
  order,
  edgeColumns,
  positivesFirst
) => {
  return function reorderGroup(data) {
    let effectiveOrder = order;
    if (edgeColumns && edgeColumns.start && edgeColumns.end) {
      effectiveOrder = [
        { ...edgeColumns.start, startPosition: 0 },
        ...order,
        { ...edgeColumns.end, startPosition: 0 },
      ];
    }

    let ret = effectiveOrder.map((o) => {
      const item = data.find((d) => d[xValueKey] === o.name) || {};

      return {
        [xValueKey]: item[xValueKey],
        [yValueKey]: item[yValueKey],
        position: o.startPosition,
        color:
          o.color || o.startPosition === 0
            ? colors.total
            : item[yValueKey] > 0
            ? colors.positive
            : colors.negative,
      };
    });
    ret = ret.filter((datum) => datum[yValueKey] != null);

    if (positivesFirst) {
      // Reorder items between ones with a startPosition such that positive items would come first.

      let startIndex;
      let lastIndex;
      for (let index = 0; index < ret.length; index++) {
        const orderEntry = ret[index];
        if (orderEntry.position != null) {
          if (startIndex !== undefined && lastIndex !== undefined) {
            // We got the range of items to reorder that we wanted.
            break;
          }

          continue;
        }

        if (startIndex === undefined) {
          startIndex = index;
        }
        lastIndex = index;
      }

      if (
        startIndex !== undefined &&
        lastIndex !== undefined &&
        startIndex < lastIndex
      ) {
        const amountToDeleteAndReplace = lastIndex - startIndex + 1;
        const sliceThatNeedsReordering = ret.slice(startIndex, lastIndex + 1);
        const slicedAndReordered = sliceThatNeedsReordering.sort((v1, v2) => {
          // All we care about when sorting is if the value is negative or not. Otherwise we want to keep
          // any other column effectiveOrder that was already set up if possible.
          const value1ToUse = Math.sign(v1[yValueKey]);
          const value2ToUse = Math.sign(v2[yValueKey]);

          return value2ToUse > value1ToUse
            ? 1
            : value1ToUse > value2ToUse
            ? -1
            : 0;
        });

        // Replace the unsorted subset of data with the sorted slice.
        ret.splice(startIndex, amountToDeleteAndReplace, ...slicedAndReordered);
      }
    }

    return ret;
  };
};

export const mapData = (yValueKey) => (data) => {
  let lastEnd = 0;

  return data.reduce((acc, curr, i) => {
    acc.push(curr);

    // add start & end props for correct positioning
    const start = Number.isInteger(curr.position) ? curr.position : lastEnd;
    acc[i].start = start;
    acc[i].end = Number.isInteger(curr.position)
      ? curr[yValueKey]
      : start + curr[yValueKey];

    lastEnd = acc[i].end;

    // swap start & end position for negative values
    if (!Number.isInteger(curr.position) && curr[yValueKey] < 0) {
      [curr.end, curr.start] = [curr.start, curr.end];
    }

    return acc;
  }, []);
};

export const margin = {
  top: 20,
  right: 30,
  bottom: 30,
  left: 40,
};

const colors = {
  positive: "rgb(89, 161, 79)",
  negative: "rgb(225, 87, 89)",
  total: "rgb(78, 121, 167)",
};

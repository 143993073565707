import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Container = styled.div(
  ({ layout, spacing, rounded, width, position, alignItems }) => {
    const isHorizontal = layout === "horizontal";

    return `
      display: flex;
      justify-content: ${position};
      align-items: ${alignItems ?? "center"};
      flex-direction: ${isHorizontal ? "row" : "column"};
      width: ${width ? width + "px" : "100%"};
      & > button, & > div,  & > a {
        margin: ${
          layout === "horizontal" ? `0px ${spacing}px` : `${spacing}px 0px`
        };
        border-radius: ${rounded && "0px"};
      }
      & > button:first-of-type {
        margin-left: 0px;
        border-top-left-radius: ${isHorizontal && rounded && "5px"};
        border-bottom-left-radius: ${isHorizontal && rounded && "5px"};
        border-top-left-radius: ${!isHorizontal && rounded && "5px"};
        border-top-right-radius: ${!isHorizontal && rounded && "5px"};
      }
      & > button:last-of-type {
        margin-right: 0px;
        border-top-right-radius: ${isHorizontal && rounded && "5px"};
        border-bottom-right-radius: ${isHorizontal && rounded && "5px"};
        border-bottom-left-radius: ${!isHorizontal && rounded && "5px"};
        border-bottom-right-radius: ${!isHorizontal && rounded && "5px"};
      }
    `;
  }
);

export default function ButtonGroup({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}
ButtonGroup.defaultProps = {
  layout: "horizontal",
  spacing: 0,
  position: "flex-start",
};

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
  ]),
  layout: PropTypes.oneOf(["horizontal", "vertical"]),
  spacing: PropTypes.number,
};

export default function showFilter(item, currentTabId, dataSourceAccessConfig) {
  return (
    (!item.hide ||
      !Array.isArray(item.hide) ||
      !item.hide.find((h) => h === currentTabId)) &&
    (!item.show ||
      (Array.isArray(item.show) &&
        !!item.show.find((h) => h === currentTabId))) &&
    // If there is only a single value that's allowed by the access config,
    // then make sure the filter doesn't show up.
    dataSourceAccessConfig.dataSourceFieldValues.byUuid[
      item.dataSourceFieldUuid
    ]?.values?.length !== 1
  );
}

import React, { useCallback, useState, useRef } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import DropdownMenu from "../UI/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "../UI/DropdownMenu/DropdownMenuItem";
import mobileBreakpoints from "../styles/mobileBreakpoints";
import UserChip from "../UI/UserChip/UserChip";
import useOnClickOutside from "../hooks/useOnClickOutside";
import SimpleTooltip from "../UI/SimpleTooltip/SimpleTooltip";
import { takeFullPageScreenshot } from "../utils/saveImage/saveImage";
import { useTheme } from "emotion-theming";
import NotificationTable from "../UI/Tables/NotificationTable";
import NotificationList from "../UI/Alerts/NotificationList";
import InsightAlert from "./MenuAlerts/InsightAlert/InsightAlert";
import useUserAccess from "../hooks/useUserAccess";
import { inIFrame } from "../utils/browser";
import { Link } from "react-router-dom";
import { setActiveTab } from "../store/actions";
import { useShallowEqualSelector } from "../store";
import TtgFeature, { TreeIcon } from "../UI/TtgFeature";
import EditorButton from "../Pages/DataManagement/EditorButton";
import { setFullPageScreenshotMode } from "../store/actions/pageManagement";

export const topMenuHeight = 52;

const Container = styled.div(
  ({ theme }) => `
    display: flex;
    background: ${theme.background.primary};
    width: 100%;
    height: ${topMenuHeight}px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 1002;
    padding: 0 15px 0 0;
    margin-top: -1px;
`
);

export const processingsPage = { path: "/admin/files", name: "Upload files" };

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    display: ${(props) => (props.static ? "block" : "none")};
  }
`;

const ActionIcon = styled.div`
  margin: 0 4px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.text.secondary};
  }
`;

const ActionIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 0;
  border-right: 1px solid ${({ theme }) => theme.divider};
`;

const Profile = styled.div`
  margin-left: 8px;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
  z-index: 1;

  svg {
    font-size: 12px;
  }

  path {
    fill: ${({ theme }) => theme.text.secondary};
  }
`;

const NamePlaceholder = styled.div`
  text-align: left;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    font-size: 12px;
    display: flex;
    justify-content: space-around;
    width: 90%;
  }
`;

const MenuButtonHandler = styled.div`
  cursor: pointer;
  font-size: 24px;
  display: none;
  margin: 0 5px;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    display: ${(props) => (!props.static ? "block" : "none")};
  }
`;

export default function TopMenu(props) {
  const [showMenu, setShowMenu] = useState(false);
  const theme = useTheme();
  const access = useUserAccess();
  const dispatch = useDispatch();

  const node = useRef(null);
  useOnClickOutside(node, () => setShowMenu(false));

  const { name, avatar, ttg } = useSelector((state) => state.auth);
  const { profileImageUpload, tabs, hasDataDictionary } =
    useShallowEqualSelector((state) => ({
      profileImageUpload: state.userManagement.profileImageUpload,
      tabs: state.layout.tabs,
      hasDataDictionary: state.layout.hasDataDictionary,
    }));

  const { siteName, dataFreshness, insights, activeTab, domain } =
    useShallowEqualSelector((state) => ({
      siteName: state.layout.siteName,
      domain: state.layout.domain,
      activeTab: state.layout.activeTab,
      dataFreshness: state.userNotifications.dataFreshness,
      insights: state.userNotifications.insights,
    }));

  const dataFreshnessTable = dataFreshness?.length ? (
    <NotificationTable data={dataFreshness} />
  ) : (
    <span>Your data is up to date</span>
  );

  const handleSetShowMenu = useCallback((value) => setShowMenu(value), []);

  const handleMenuItemClick = useCallback(() => {
    setShowMenu(false);
  }, []);

  const tenantOwnerOptions = (
    <>
      {access.fileUploading && (
        <DropdownMenuItem
          {...processingsPage}
          icon="upload"
          clicked={handleMenuItemClick}
          cy="files"
        />
      )}
      {access.userManagement && (
        <DropdownMenuItem
          path="/admin/user-management/users"
          name="User Management"
          icon="users"
          clicked={handleMenuItemClick}
          cy="user-management"
        />
      )}
      {access.dataExplorer && (
        <DropdownMenuItem
          path="/admin/data-explorer"
          name="Data Explorer"
          icon="satellite-dish"
          clicked={handleMenuItemClick}
          cy="data-explorer"
        />
      )}
      {access.dataSourcesRead && (
        <DropdownMenuItem
          path="/admin/data-settings"
          name="Data Settings"
          icon="cog"
          clicked={handleMenuItemClick}
          cy="images"
        />
      )}
      {access.siteConfiguration && (
        <DropdownMenuItem
          path="/admin/site-configuration"
          name="Site Configuration"
          icon="sliders-h"
          clicked={handleMenuItemClick}
          cy="images"
        />
      )}
      {access.pageManagement && (
        <DropdownMenuItem
          path="/admin/page-management"
          name="Page Management"
          icon="file-alt"
          clicked={handleMenuItemClick}
          cy="images"
        />
      )}
    </>
  );

  const setScreenshotMode = (mode) => {
    dispatch(setFullPageScreenshotMode(mode));
  };

  const downloadScreenshot = (siteName, pageName) => {
    setScreenshotMode(true);
    setShowMenu(false);

    // setTimeout need to make blocks offscreen false
    setTimeout(
      () =>
        takeFullPageScreenshot(theme, siteName, pageName, setScreenshotMode),
      500
    );
  };

  let title = <h3>{siteName.toUpperCase()}</h3>;
  if (props.hasLink) {
    title = <Link to={props.hasLink}>{title}</Link>;
  }

  const onTitleClick = () => {
    if (props.hasLink) {
      const link = props.hasLink.split("/").pop();
      const tab = tabs.find((tab) => tab.slug === link);
      dispatch(setActiveTab(tab));
    }
  };
  const getUserImage = () => {
    return profileImageUpload
      ? `${profileImageUpload["40x40"]}&random_number${new Date().getTime()}`
      : avatar
      ? avatar["40x40"]
      : null;
  };

  return (
    <Container data-cy="top-menu">
      <MenuButtonHandler onClick={props.show} static={props.static}>
        <FontAwesomeIcon icon={["fas", "bars"]} />
      </MenuButtonHandler>
      <NamePlaceholder onClick={onTitleClick}>{title}</NamePlaceholder>

      {!inIFrame && (
        <Actions static={props.static}>
          <ActionIconContainer>
            <EditorButton />
            <InsightAlert insights={insights} show />
            <SimpleTooltip position="bottom" label={dataFreshnessTable}>
              <ActionIcon>
                <FontAwesomeIcon icon={["fas", "life-ring"]} />
              </ActionIcon>
            </SimpleTooltip>
            <SimpleTooltip position="bottom" label="You have no new messages">
              <ActionIcon>
                <FontAwesomeIcon icon={["fas", "comments"]} />
              </ActionIcon>
            </SimpleTooltip>
            <SimpleTooltip position="bottom" label={<NotificationList />} click>
              <ActionIcon>
                <FontAwesomeIcon icon={["fas", "bell"]} />
              </ActionIcon>
            </SimpleTooltip>
          </ActionIconContainer>
          <Profile ref={node}>
            <DropdownMenu
              cy="user-menu"
              title={
                ttg ? (
                  <>
                    {name}{" "}
                    <TreeIcon title="You are a TTG user and are able to see features non-TTG users are not." />
                  </>
                ) : (
                  name
                )
              }
              background={`gray900`}
              show={showMenu}
              setShow={handleSetShowMenu}
            >
              <DropdownMenuItem
                path="/user-profile"
                name="User Profile"
                icon="id-card"
                clicked={handleMenuItemClick}
                cy="images"
              />
              <DropdownMenuItem
                name="Take Full Screenshot"
                icon="images"
                clicked={() =>
                  downloadScreenshot(siteName, activeTab?.displayName)
                }
              />
              {hasDataDictionary || access.editDataDictionary ? (
                <DropdownMenuItem
                  name="Data Dictionary"
                  icon="book"
                  path="/data-dictionary"
                  clicked={handleMenuItemClick}
                />
              ) : null}
              {tenantOwnerOptions}
              <TtgFeature>
                {({ treeIcon }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DropdownMenuItem
                      path="/dashboard-message"
                      name="Dashboard Message"
                      icon="envelope"
                      clicked={handleMenuItemClick}
                      cy="images"
                    />
                    <span style={{ float: "right" }}>{treeIcon}</span>
                  </div>
                )}
              </TtgFeature>
              {domain === "super-duper" ? (
                <DropdownMenuItem
                  path="/admin/data-setup"
                  name="No Hands Data Wiring"
                  icon="hand-sparkles"
                  clicked={handleMenuItemClick}
                  cy="images"
                  tree
                />
              ) : null}
              <DropdownMenuItem
                path="/logout"
                name="Logout"
                icon="sign-out-alt"
                cy="link-logout"
              />
            </DropdownMenu>
            <div onClick={() => setShowMenu(!showMenu)}>
              <UserChip name={name} rounded image={getUserImage()} />
            </div>
          </Profile>
        </Actions>
      )}
    </Container>
  );
}

import React from "react";
import LabelWrapper from "./LabelWrapper";

export default (label, required, overPrimary, tooltip) => (comp) => {
  if (!label) return comp;
  return (
    <LabelWrapper
      label={label}
      required={required}
      overPrimary={overPrimary}
      tooltip={tooltip}
    >
      {comp}
    </LabelWrapper>
  );
};

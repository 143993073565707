import React from "react";
import { CircleMarker as MapCircleMarker, Tooltip } from "react-leaflet";

export default function CircleMarker({
  d,
  radius,
  zoomLevel,
  dynamicColor,
  colorBand,
  colorLabel,
  colorKey,
  setActivePopup,
  opacity,
  showTooltip,
  labeledColumns,
  legendItems = [],
  colorsConfig,
}) {
  const setColor = () => {
    if (colorsConfig) {
      return colorBand(d[colorsConfig.column]);
    }

    return (
      dynamicColor ||
      legendItems.find((li) => li.name === d[colorKey])?.color ||
      colorBand(colorLabel || d[colorKey])
    );
  };

  return (
    <MapCircleMarker
      center={d.center}
      radius={radius + zoomLevel * 0.5}
      fillColor={setColor()}
      color={setColor()}
      weight={0}
      onClick={() => setActivePopup(d)}
      fillOpacity={opacity()}
    >
      {showTooltip && (
        <Tooltip direction="bottom" offset={[0, 20]} permanent>
          {labeledColumns.map((column) => `${d[column]} `)}
        </Tooltip>
      )}
    </MapCircleMarker>
  );
}

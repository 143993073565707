export const defaultMarkerIODestination =
  process.env.REACT_APP_MARKER_IO_DESTINATION;
export const showProductionWarning =
  !!process.env.REACT_APP_SHOW_PRODUCTION_WARNING && !window.Cypress;
export const isLocalDevServer = process.env.NODE_ENV === "development";
const databaseSwitcherDomainsCsv =
  process.env.REACT_APP_FEATURE_DATABASE_SWITCHER_DOMAINS_CSV;
export const databaseSwitcherDomains = databaseSwitcherDomainsCsv
  ? databaseSwitcherDomainsCsv.split(",")
  : [];
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
export const sentryTracesSampleRate =
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE;

export const frontendBaseUrl =
  window.location.protocol + "//" + window.location.host;

export const isRunningInTest = process.env.JEST_WORKER_ID !== undefined;

export const octaneApiBaseUrl =
  process.env.REACT_APP_OCTANE_API_ENDPOINT ??
  "https://dev.octane.insightout.ttgda.com";

import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { omit, isEqual } from "lodash-es";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import usePrevious from "../../utils/usePrevious";
import AddNewRows from "./AddNewRows/AddNewRows";
import Toolbar from "./Toolbar";
import Watchlist from "./Watchlist";
import WatchlistModal from "./WatchlistModal";
import {
  getWatchlist,
  loadFilteredTable,
  setWatchlist,
  deleteWatchlist,
} from "../../store/actions";
import {
  ALARMS,
  formatData,
  getWatchlistExistingKey,
  message,
  NEW_ROWS,
  rounded,
  title,
} from "./utils";
import { getMappingType } from "../../utils/getVisualizationLabel";
import { rightAligns } from "../../utils/constants/constants";

const watchlistSelector = (state) => state.watchlist;

export default function WatchlistVisualization({ chart }) {
  const { data = [], meta = {}, watchlistConfig = {}, uuid } = chart;
  const { tableData, processing, watchlist } = useSelector(watchlistSelector);

  const columns = Object.keys(data[0] ?? {});
  const { anchorText } = watchlistConfig;

  const [searchText, setSearchText] = useState("");
  const [visibleModalComponent, setVisibleModalComponent] = useState(null);

  const removeFiltersByKey = (row) => {
    const key = getWatchlistExistingKey(row, watchlist);
    if (key) {
      saveAppliedFilters(omit(watchlist, key));
    }
  };

  const dispatch = useDispatch();
  const confirm = useConfirmDelete(removeFiltersByKey, title, message, rounded);
  const prevWatchlist = usePrevious(watchlist);

  useEffect(() => {
    dispatch(getWatchlist(uuid));
  }, [dispatch, uuid]);

  useEffect(() => {
    const shouldUpdateTable = !isEqual(watchlist, prevWatchlist);

    if (watchlist && shouldUpdateTable) {
      dispatch(loadFilteredTable({ ...chart, filters: watchlist }));
    }
  }, [watchlist, dispatch, chart, prevWatchlist]);

  // all non filtered data which coming from original config
  const allData = formatData(data, columns, meta);

  // watchlist data with applied filters
  const watchlistData = formatData(tableData, columns, meta);

  const saveAppliedFilters = (newFilters) => {
    if (!newFilters || !Object.keys(newFilters).length) {
      return dispatch(deleteWatchlist(chart.uuid));
    }

    dispatch(setWatchlist(chart.uuid, newFilters, watchlist));
  };

  const getColumnTextAlign = useCallback(
    (column) => {
      const type = getMappingType(meta?.fields, column);
      return rightAligns.includes(type) ? "right" : "left";
    },
    [meta?.fields]
  );

  const components = {
    [NEW_ROWS]: (
      <AddNewRows
        setVisibleModalComponent={setVisibleModalComponent}
        data={allData}
        meta={meta}
        watchlist={watchlist}
        saveAppliedFilters={saveAppliedFilters}
        loading={processing}
        watchlistConfig={watchlistConfig}
        getColumnTextAlign={getColumnTextAlign}
      />
    ),
    [ALARMS]: null, // not ready yet
  };

  return (
    <>
      <Toolbar
        searchText={searchText}
        setSearchText={setSearchText}
        setVisibleModalComponent={setVisibleModalComponent}
        anchorText={anchorText}
      />

      <WatchlistModal
        show={[ALARMS, NEW_ROWS].includes(visibleModalComponent)}
        close={() => setVisibleModalComponent(null)}
      >
        {components[visibleModalComponent]}
      </WatchlistModal>

      <Watchlist
        {...chart}
        data={watchlistData}
        processing={processing}
        columns={columns}
        searchText={searchText}
        confirm={confirm}
        watchlist={watchlist}
        getColumnTextAlign={getColumnTextAlign}
      />

      {confirm.confirming}
    </>
  );
}

import styled from "@emotion/styled";
import Switcher from "../../../../../UI/Switcher/Switcher";
import Name from "./Name";
import Operator from "./Operator";
import RowKeys from "./RowKeys";
import Format from "./Format";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  hr {
    width: 100%;
  }
`;

const defaultConfig = {
  column: "",
  rowKeys: [],
  operator: null,
  format: "",
};

export default function GroupingExtraColumns({
  chart,
  setChartState,
  theme,
  queryType,
  fields,
}) {
  const { groupingExtraColumns } = chart;

  function enableDisableGroupingExtraColumn() {
    const config = groupingExtraColumns ? null : defaultConfig;

    setChartState({
      ...chart,
      groupingExtraColumns: config,
    });
  }

  function setReverseColor() {
    setChartState({
      ...chart,
      groupingExtraColumns: {
        ...groupingExtraColumns,
        reverseColor: !groupingExtraColumns.reverseColor,
      },
    });
  }

  return (
    <Container>
      <br />
      <div>Grouping Extra Column</div>
      <br />
      <Switcher
        rightLabel="Enable Config"
        checked={!!groupingExtraColumns}
        handleSwitchChange={enableDisableGroupingExtraColumn}
      />

      {groupingExtraColumns && (
        <>
          <br />
          <Name chart={chart} setChartState={setChartState} />
          <br />
          <Operator chart={chart} setChartState={setChartState} />
          <br />
          <RowKeys
            chart={chart}
            setChartState={setChartState}
            queryType={queryType}
            fields={fields}
          />
          <Format chart={chart} setChartState={setChartState} />
          <br />
          <Checkbox
            label="Reverse Color"
            checked={!!groupingExtraColumns.reverseColor}
            onChange={setReverseColor}
          />
        </>
      )}

      <br />
      <hr color={theme.background.secondary} />
    </Container>
  );
}

import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import tokenStorage from "./store/middlewares/tokenStorage";
import headers from "./store/middlewares/headers";
import api from "./store/middlewares/api";
import toastMiddleware from "./store/middlewares/toastMiddleware";
import { composeWithDevTools } from "@redux-devtools/extension";

import authReducer from "./store/reducers/authReducer";
import dashboardReducer from "./store/reducers/dashboardReducer";
import userManagementReducer from "./store/reducers/userManagement";
import layoutReducer from "./store/reducers/layoutReducer";
import themeConfigurationReducer from "./store/reducers/themeConfiguration";
import messageReducer from "./store/reducers/messageReducer";
import dataSettingsReducer from "./store/reducers/dataSettings/dataSettingsReducer";
import fileManagementReducer from "./store/reducers/fileManagement";
import attributesReducer from "./store/reducers/dataSettings/attributesReducer";
import siteConfigurationReducer from "./store/reducers/siteConfiguration";
import pageManagement from "./store/reducers/dashboardSetup/pageManagement";
import userNotifications from "./store/reducers/userNotifications";
import connectionsReducer from "./store/reducers/dataSettings/connectionsReducer";
import chartsReducer from "./store/reducers/dashboardSetup/chartsReducer";
import etlConfigReducer from "./store/reducers/dataSettings/etlConfigReducer";
import queryExecSettingsReducer from "./store/reducers/queryExecSettings";
import insightsReducer from "./store/reducers/insightsReducer";
import dataSetupReducer from "./store/reducers/dataSetup/dataSetupReducer";
import monitoringReducer from "./store/reducers/monitoringReducer";
import dataDictionaryReducer from "./store/reducers/dataDictionaryReducer";
import { useSelector, shallowEqual } from "react-redux";
import headerKpisReducer from "./store/reducers/headerKpisReducer";
import watchlistReducer from "./store/reducers/watchlistReducer";
import geoMapReducer from "./store/reducers/geoMapReducer";
import activeTableReducer from "./store/reducers/activeTableReducer";
import automationReducer from "./store/reducers/automationReducer";
import {
  SET_ACTIVE_TAB,
  LOAD_HEADER_CONFIG_SUCCESS,
  SET_CHART_VISIBILITY,
  SET_LOAD_BUNDLE,
  EXECUTE_QUERY_SUCCESS,
} from "./store/actions/actionTypes";
import produce from "immer";

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  dashboard: dashboardReducer,
  themeConfiguration: themeConfigurationReducer,
  userManagement: userManagementReducer,
  dataSettings: dataSettingsReducer,
  fileManagement: fileManagementReducer,
  siteConfiguration: siteConfigurationReducer,
  message: messageReducer,
  attributes: attributesReducer,
  userNotifications: userNotifications,
  pageManagement: pageManagement,
  connections: connectionsReducer,
  charts: chartsReducer,
  queryExecSettings: queryExecSettingsReducer,
  etlConfigs: etlConfigReducer,
  insights: insightsReducer,
  dataSetup: dataSetupReducer,
  monitoring: monitoringReducer,
  dataDictionary: dataDictionaryReducer,
  headerKpis: headerKpisReducer,
  watchlist: watchlistReducer,
  geoMap: geoMapReducer,
  automation: automationReducer,
  activeTable: activeTableReducer,
});

const composeEnhancers = composeWithDevTools({
  actionSanitizer,
  stateSanitizer,
});

/**
 * For when returning an object in the selector callback to save on rerenders.
 */
export function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, tokenStorage, headers, api, toastMiddleware)
  )
);

// Expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

const sanitizedText = "(sanitized)";

// Reduces memory usage in Redux Devtools.
function actionSanitizer(action) {
  const newAction = produce(action, (draft) => {
    if (action.type === SET_ACTIVE_TAB) {
      draft.data = sanitizedText;
      return draft;
    }
    if (action.type === SET_CHART_VISIBILITY) {
      draft.filters = sanitizedText;
      return draft;
    }
    if (action.type === SET_LOAD_BUNDLE) {
      draft.charts = sanitizedText;
      return draft;
    }
    if (String(action.type).endsWith("_SUCCESS")) {
      draft.results = sanitizedText;
    }
    if (action.type === LOAD_HEADER_CONFIG_SUCCESS) {
      draft.responseData = sanitizedText;
    }
    if (action.type === EXECUTE_QUERY_SUCCESS && draft.meta.api) {
      draft.meta.api.payload = sanitizedText;
    }

    return draft;
  });
  if (window.actionSanitizerCallback) {
    // Option to override sanitizer result. For use in production deploys.
    const callbackResultAction = window.actionSanitizerCallback(
      action,
      newAction
    );

    if (callbackResultAction) {
      return callbackResultAction;
    }
  }
  return newAction;
}

// Reduces memory usage in Redux Devtools.
function stateSanitizer(state) {
  const newState = produce(state, (draft) => {
    if (state.layout?.tabs) {
      draft.layout.tabs = sanitizedText;
    }
    if (state.layout?.menuFilters) {
      draft.layout.menuFilters = sanitizedText;
    }
    if (state.layout?.activeTab?.blocks) {
      draft.layout.activeTab.blocks = sanitizedText;
    }
    if (state.layout?.header?.blocks) {
      draft.layout.header.blocks = sanitizedText;
    }
    if (state.dashboard?.blocks) {
      draft.dashboard.blocks = sanitizedText;
    }
    if (state.pageManagement?.pages) {
      draft.pageManagement.pages = sanitizedText;
    }
    if (state.fileManagement?.etlConfigs) {
      draft.fileManagement.etlConfigs = sanitizedText;
    }
    if (state.etlConfigs?.etlConfigs) {
      draft.etlConfigs.etlConfigs = sanitizedText;
    }
  });
  if (window.stateSanitizerCallback) {
    // Option to override sanitizer result. For use in production deploys.
    const callbackResultState = window.stateSanitizerCallback(state, newState);

    if (callbackResultState) {
      return callbackResultState;
    }
  }

  return newState;
}

import { format } from "date-fns";
import React from "react";
import Table from "../Tables/Table/Table";

export default function NotificationTable(props) {
  const headers = ["Type", "Value", "Updated Date"];

  return (
    <>
      <h4>Your data is fresh as of:</h4>
      <Table>
        <thead>
          <tr>
            {headers.map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((data, index) => (
            <tr key={"row_" + index}>
              <td>{data.type}</td>
              <td>{data.value}</td>
              <td>{format(new Date(data.updatedAt), "M/d/yyyy HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

import React, { useMemo } from "react";
import { ThemeProvider } from "emotion-theming";
import { Switch, withRouter, useLocation } from "react-router";
import { Route } from "./utils/router";
import Layout from "./Layout/Layout";
import Login from "./Pages/Login/Login";
import PrivateRoute from "./Pages/PrivateRoute/PrivateRoute";
import { useAppStateConfig } from "./hooks/useAppStateConfig";
import { Helmet } from "react-helmet";
import AdminLayout from "./Layout/Admin/AdminLayout";
import Loading from "./UI/Loading/Loading";
import Landing from "./Pages/Landing/Landing";
import words from "./styles/words";

import "./App.css";
import TechnicalMessage from "./Pages/TechnicalMessage/ThechnicalMessage";
import Toaster from "./Editors/Toaster/Toaster";
import Logout from "./Pages/Logout/Logout";
import ScheduleToast from "./Editors/Toaster/ScheduleToast";
import { parse } from "qs";
import ExternalGoogleIdTokenPage from "./Pages/Login/Google/ExternalGoogleIdTokenPage";
import ProductionWarning from "./UI/ProductionWarning";
import { showProductionWarning } from "./utils/env";
import TableEditor from "./Pages/TableEditor/TableEditor";
import { useSelector } from "react-redux";

const App = (props) => {
  const {
    siteName,
    favicon,
    theme,
    landing,
    loading,
    themeName,
    invalidDomain,
  } = useAppStateConfig(props);

  const location = useLocation();
  const query = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );
  const { isAuthenticated, isAuthenticationComplete } = useSelector(
    (state) => state.auth
  );

  if (invalidDomain) return <TechnicalMessage message={words.not_configured} />;
  if (loading && query.googleIdToken) {
    return <ExternalGoogleIdTokenPage />;
  }
  if (landing) return <Landing />;
  if (loading || !themeName || (isAuthenticated && !isAuthenticationComplete))
    return (
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <Loading />
      </div>
    );

  return (
    <div className="App">
      <Helmet>
        <title>{siteName}</title>
        <link
          rel="icon"
          type="image/png"
          href={
            favicon
              ? favicon
              : "https://s3.amazonaws.com/dev.insightoutstatic/demo/favicon.png"
          }
        />
      </Helmet>

      <ThemeProvider theme={theme}>
        <div id="modal-portal" style={{ position: "relative", zIndex: 5000 }} />
        <div
          id="tipper-portal"
          style={{ position: "relative", zIndex: 1000000 }}
        />

        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/logout" exact component={Logout} />
          <PrivateRoute path="/admin" component={AdminLayout} />
          <PrivateRoute
            path="/table-editor/:visualizationUuid"
            component={TableEditor}
          />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
        <Toaster />
        <ScheduleToast />
      </ThemeProvider>
      {showProductionWarning && <ProductionWarning />}
    </div>
  );
};

export default withRouter(App);

import React from "react";
import styled from "@emotion/styled";
import { flexCenter } from "../../../styles/styledSnippets";
import { hexToRgba } from "../../../styles/colorConvertor";

const Tile = styled.div`
  background: ${(props) => hexToRgba(props.theme.background.secondary, 0.5)};
  color: ${(props) => props.theme.text.secondary};
  padding: 24px 38px;
  margin: 12px 0;
  width: 240px;
  border-radius: 6px;
  ${flexCenter}
`;

const Line = styled.div`
  width: 38px;
  height: 1px;
  background: ${(props) => props.theme.primary};
`;

export default function DataMapTile(props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Line />
      <Tile data-cy={props.cy}>{props.name}</Tile>
    </div>
  );
}

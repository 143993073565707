import { rangeAndAggregationOptions } from "./constants/constants";

export const findMaxIndex = (aggregation) => {
  if (aggregation.length === 0) return null;

  let max = 0;
  aggregation.forEach((aggr) => {
    const current = rangeAndAggregationOptions.findIndex(
      (date) => date.value === aggr
    );
    if (current > max) {
      max = current;
    }
  });

  return rangeAndAggregationOptions[max].value;
};

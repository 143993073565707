import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadioButton from "../../../../../../UI/RadioButton/RadioButton";

const Container = styled.div`
  display: flex;
  padding: 10px 0px;
  align-items: center;
`;

const ColorBox = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${(props) => props.background};
  margin-right: 5px;
`;

export default function ProgressBars(props) {
  const { chart, selectedColumn, setChartState, setSelectedColumn, theme } =
    props;

  const { progressCellFields } = chart;
  const { column, index, subIndex } = selectedColumn;
  const isReversed = column.includes("ReverseColor");
  const hasProgressBar = (progressCellFields ?? []).includes(column);

  const addReverseColor = () => {
    return column
      .split("::")
      .reduce((acc, curr, index, arr) => {
        acc.push(curr);
        if (index === arr.length - 2) {
          acc.push("ReverseColor");
        }
        return acc;
      }, [])
      .join("::");
  };

  const updateProgressBarFields = (updated) => {
    const progressIndex = progressCellFields.indexOf(column);
    if (progressIndex > -1) {
      return chart.progressCellFields.map((column, index) =>
        index === progressIndex ? updated : column
      );
    }

    return [...chart.progressCellFields, updated];
  };

  const updateSubTitles = (updated) => {
    return chart.subTitles.map((subTitle, i) => {
      if (i === subIndex) {
        return subTitle.map((column, j) => (j === index ? updated : column));
      }

      return subTitle;
    });
  };

  const setReverse = () => {
    const updated = isReversed
      ? column.replace("::ReverseColor", "")
      : addReverseColor();

    setSelectedColumn({ ...selectedColumn, column: updated });

    setChartState({
      ...chart,
      subTitles: updateSubTitles(updated),
      progressCellFields: updateProgressBarFields(updated),
    });
  };

  const addProgressBar = () => {
    if (!hasProgressBar) {
      setChartState({
        ...chart,
        progressCellFields: [...(chart.progressCellFields ?? []), column],
      });
    }
  };

  return (
    <>
      <RadioButton
        name="Add Progress Bar"
        checked={hasProgressBar}
        onChange={addProgressBar}
        size={17}
        offset={5}
      />

      {hasProgressBar && (
        <Container>
          <ColorBox
            background={
              theme.notification[isReversed ? "infoMain" : "errorMain"]
            }
          />
          <span>Negative</span>

          <FontAwesomeIcon
            icon={["fas", "exchange-alt"]}
            style={{ fontSize: 16, margin: "0 30px", cursor: "pointer" }}
            onClick={setReverse}
          />

          <ColorBox
            background={
              theme.notification[isReversed ? "errorMain" : "infoMain"]
            }
          />
          <span>Positive</span>
        </Container>
      )}
      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
    </>
  );
}

import Flex from "../../../UI/Flex/Flex";
import Input from "../../../UI/Form/Input/Input";
import Select from "../../../UI/Form/Select/Select";
import IOButton from "../../../UI/Form/Button/IOButton";
import { useEffect } from "react";
import { setAttachmentFromChart } from "../components/automationHelper";
import { chartTypeKeyMap } from "../../../utils/constants/chartConstants";
import convertFiltersToOperatorsMode from "../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";

export default function Content({
  report,
  setReport,
  setContent,
  chart = {},
  content,
  contentOptions,
}) {
  useEffect(() => {
    if (report.visualizationUuid !== chart.visualizationId) {
      setReport({
        ...report,
        visualizationUuid: chart.visualizationId,
        filters: chart.filters ?? [],
        attachments: [
          setAttachmentFromChart(
            content,
            convertFiltersToOperatorsMode(chart.filters ?? []),
            chart
          ),
        ],
      });
    }
  }, [chart, content, report, setReport]);

  return (
    <>
      <Input
        label="Report Name"
        inPlaceEdit
        value={report.name ?? ""}
        onChange={(e) =>
          setReport((prev) => ({ ...prev, name: e.target.value }))
        }
      />
      <br />
      <Flex alignItems="end">
        <Select
          options={contentOptions}
          label="Content"
          value={content}
          onChange={(option) => setContent(option)}
        />
        {chart.type === chartTypeKeyMap.TableChart && (
          <IOButton
            smallPadding
            to={`/table-editor/${chart.visualizationId}`}
            style={{ marginLeft: 10, marginBottom: 5 }}
          >
            Go&nbsp;to&nbsp;Power&nbsp;Editor
          </IOButton>
        )}
      </Flex>
    </>
  );
}

import React from "react";
import { useTheme } from "emotion-theming";
import { lightenDarkenColor } from "../../styles/colorConvertor";

export default function QuarterShade(props) {
  const theme = useTheme();
  const sectionWidth = props.width / 4;
  function xPos(quarter) {
    return (quarter - 1) * sectionWidth;
  }

  function fill(quarter) {
    return lightenDarkenColor(theme.background.secondary, quarter % 2 ? 5 : -5);
  }

  // Now create widths for each quarter. This will work only where width = 1 year
  return [1, 2, 3, 4].map((quarter) => (
    <rect
      key={quarter}
      width={props.width / 4}
      height={props.height + 20}
      y={-20}
      x={xPos(quarter)}
      fill={fill(quarter)}
    />
  ));
}

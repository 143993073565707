import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import useResizeObserver from "use-resize-observer/polyfilled";
import usePrevious from "../../../../utils/usePrevious";
import { isRunningInTest } from "../../../../utils/env";

const initialWidth = 140;

const Column = styled.div`
  padding: 8px 12px;
  white-space: nowrap;
  overflow: hidden;
  width: ${initialWidth}px;
  position: relative;
  resize: horizontal;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.theme.type === "dark"
      ? props.theme.gray.gray800
      : props.theme.gray.gray300};
  box-sizing: border-box;
  div:last-of-type {
    opacity: 0;
  }
  &:hover {
    div:last-of-type {
      opacity: 1;
    }
  }
`;

const ResizeIcon = styled.div`
  position: absolute;
  height: 22px;
  width: 0;
  bottom: 2px;
  right: 2px;
  padding: 0 3px;
  pointer-events: none;
  cursor: e-resize;
  border-right: 2px solid ${(props) => props.theme.notification.infoLight};
`;

export default function ResizableDiv(props) {
  let width = 1,
    ref;

  if (!isRunningInTest) {
    //eslint-disable-next-line react-hooks/rules-of-hooks
    const resizeObserver = useResizeObserver();
    width = resizeObserver.width ?? 1;
    ref = resizeObserver.ref;
  } else {
    /* useResizeObserver() does not play well with Jest in Create React App v4. */
    //eslint-disable-next-line react-hooks/rules-of-hooks
    ref = useRef();
  }
  const prevWidth = usePrevious(width);

  useEffect(() => {
    if (prevWidth !== width) {
      props.handleChange(Math.ceil(width) + 24);
    }
  }, [width, prevWidth, props]);

  return (
    <Column ref={ref} data-cy={props.cy} active={props.active}>
      {props.children}
      <ResizeIcon />
    </Column>
  );
}

import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { schemeTableau10 } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";
import UserChipGroup from "./UserChipGroup";
import robot from "./system-robot.png";

const colorFn = scaleOrdinal(schemeTableau10);

const Container = styled.div(
  ({ width, height, theme, rounded, image, dynamicKey, system }) => `
    flex-shrink: 0;
    width: ${width}px;
    height: ${height}px;
    text-align: center;
    border-radius: ${rounded ? "50%" : 0};
    background: ${
      image
        ? `url('${image}')`
        : dynamicKey
        ? colorFn(dynamicKey)
        : system
        ? theme.text.secondary
        : theme.primary
    } !important;
    background-size: contain !important;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: ${rounded ? "50%" : 0};
    -moz-border-radius: ${rounded ? "50%" : 0};
    cursor: pointer;
    overflow: hidden;
    svg {
        position: relative;
        top: 4px;
    }
    path {
        fill: white;
    }
`
);

const Initials = styled.span(
  ({ theme, fontSize }) => `
    position: relative;
    color: ${theme.text.textOnPrimary};
    font-size: ${fontSize}px;
    font-weight: bold;
`
);

const Img = styled.img`
  position: relative;
  top: 0px;
  left: 0;
`;

export default function UserChip(props) {
  const {
    name,
    width,
    height,
    fontSize,
    rounded,
    cy,
    image,
    system,
    dynamicColor,
  } = props;

  const inner = system ? (
    <Img src={robot} alt="system loaded" width={25} height="auto" />
  ) : image ? null : (
    <Initials fontSize={fontSize} data-cy="avatar-initial">
      {name.substring(0, 1).toUpperCase()}
    </Initials>
  );

  return (
    <UserChipGroup label={props.label} date={props.date}>
      <Container
        data-cy={cy || "avatar-chip"}
        width={width}
        height={height}
        rounded={rounded}
        image={image}
        dynamicKey={dynamicColor && name.length}
        title={name}
        system={system}
      >
        {inner}
      </Container>
    </UserChipGroup>
  );
}

UserChip.defaultProps = {
  name: "-",
  width: 28,
  height: 28,
  fontSize: 18,
  rounded: true,
};

UserChip.propTypes = {
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  rounded: PropTypes.bool,
  system: PropTypes.bool,
  image: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.any,
};

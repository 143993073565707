import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.type};
`;

export default function ValueOrientationDiv(props) {
  const type = props.operator === "range" ? "row" : "column";
  return <Container type={type}>{props.children}</Container>;
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../UI/Form/Input/Input";
import LabelWrapper, {
  FormLabel,
} from "../../../UI/Form/LabelWrapper/LabelWrapper";
import { Controller, useFormState } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import WriteBacksCrmFieldMenuFilter from "./WriteBacksCrmFieldMenuFilter";
import WriteBacksCrmFieldMonthYear from "./WriteBacksCrmFieldMonthYear";
import { useDispatch } from "react-redux";
import WriteBacksCrmSubField from "./WriteBacksCrmSubField";
import Select from "../../../UI/Form/Select/Select";
import axios from "../../../axios";
import { getRowIoUuid } from "../WriteBacksContext";
import Loading from "../../../UI/Loading/Loading";
import { determineWriteBackFieldRequired } from "../../../utils/writeBacks";

const execQuery = async (uuid, overrides) => {
  return await axios.post(`api/v1/queries/${uuid}/exec?format=chart`, {
    ...overrides,
  });
};

export default function WriteBacksCrmField({
  register,
  control,
  setValue,
  writeBackOptions,
  fieldName,
  meta,
  type,
  row,
}) {
  const { label } = meta;
  const [inputValue, setInputValue] = useState(null);
  const { errors, isValid } = useFormState({
    control,
    name: fieldName,
  });
  const required = determineWriteBackFieldRequired(writeBackOptions, type);
  const dispatch = useDispatch();
  const [queryResults, setQueryResults] = useState({});

  const errorMessage = !isValid && (
    <ErrorMessage name={fieldName} errors={errors} />
  );

  useEffect(() => {
    if (writeBackOptions.query) {
      execQuery(writeBackOptions.query.id, {
        ...writeBackOptions.query.overrides,
      }).then((res) => {
        setQueryResults((q) => ({
          ...q,
          [writeBackOptions.query.id]: res.data.data,
        }));
      });
    }

    if (
      row.values?.[0] &&
      writeBackOptions.drilldownQuerySave &&
      row.values[0][writeBackOptions.drilldownQuerySave.uuidField]
    ) {
      execQuery(writeBackOptions.drilldownQuerySave.id, {
        filters: [
          {
            name: writeBackOptions.drilldownQuerySave.uuidField,
            values:
              row.values[0][writeBackOptions.drilldownQuerySave.uuidField],
          },
        ],
      }).then((res) => {
        setQueryResults((q) => ({
          ...q,
          [writeBackOptions.drilldownQuerySave.id]: res.data.data,
        }));
      });
    }
  }, [dispatch, writeBackOptions, row.values]);

  const queryValues = useMemo(() => {
    if (writeBackOptions.query && queryResults[writeBackOptions.query.id]) {
      return queryResults[writeBackOptions.query.id].reduce((acc, curr) => {
        acc[curr[meta.name]] = acc[curr[meta.name]] || [];
        const searchValue = acc[curr[meta.name]].filter(
          (v) => v.name === curr[writeBackOptions.drilldownField]
        );

        if (
          (!searchValue.length || !writeBackOptions.query.uniqueValues) &&
          curr[writeBackOptions.drilldownField]
        ) {
          const values = queryResults[writeBackOptions.drilldownQuerySave.id]
            ? queryResults[writeBackOptions.drilldownQuerySave.id]
            : queryResults[writeBackOptions.query.id];

          const valueSearch = values.find(
            (d) =>
              d[writeBackOptions.drilldownField] ===
              curr[writeBackOptions.drilldownField]
          );

          const preparedValues = {
            name: curr[writeBackOptions.drilldownField],
            value: valueSearch
              ? valueSearch[writeBackOptions.drilldownValue]
              : "",
            uuid: valueSearch ? getRowIoUuid(valueSearch) : "",
            additionalLabel:
              curr[writeBackOptions.drilldownFieldAdditionalLabel] ?? "",
          };

          if (writeBackOptions.additionalField && valueSearch) {
            const field = writeBackOptions.additionalField.field;
            const value = writeBackOptions.additionalField.condition.value;
            const label = writeBackOptions.additionalField.label;

            if (valueSearch[field] === value) {
              preparedValues["additionalField"] = {
                label: label,
                name: field,
                saveField: writeBackOptions.additionalField.saveField,
                value: valueSearch[writeBackOptions.additionalField.saveField],
              };
            }
          }

          acc[curr[meta.name]].push(preparedValues);
        }

        return acc;
      }, {});
    }

    return [];
  }, [
    meta.name,
    queryResults,
    writeBackOptions.query,
    writeBackOptions.drilldownField,
    writeBackOptions.drilldownQuerySave,
    writeBackOptions.drilldownFieldAdditionalLabel,
    writeBackOptions.additionalField,
    writeBackOptions.drilldownValue,
  ]);

  const inputProps = {
    required,
    label,
    errorMessage,
    tooltip: writeBackOptions.tooltip,
    // readOnly: writeBackOptions.readOnly,
  };

  const displayLabel = `${inputProps.label}${required ? "*" : ""}`;

  const registerOptions = {
    required,
  };

  const updateValue = useCallback(
    (value) => {
      return setValue(fieldName, value, {
        shouldDirty: true,
      });
    },
    [fieldName, setValue]
  );

  const selectOptions = useMemo(() => {
    return Object.keys(queryValues).map((k) => ({ label: k, value: k }));
  }, [queryValues]);

  return (
    <div>
      {writeBackOptions.menuFilterUuid ? (
        <WriteBacksCrmFieldMenuFilter
          writeBackOptions={writeBackOptions}
          inputProps={inputProps}
          registerOptions={registerOptions}
          fieldName={fieldName}
          register={register}
          control={control}
          errorMessage={errorMessage}
          updateValue={updateValue}
        />
      ) : type === "date" || type === "date-iso" ? (
        <Input
          type="date"
          {...inputProps}
          {...register(fieldName, registerOptions)}
          max={
            writeBackOptions.restrictFutureDates
              ? new Date().toISOString().substring(0, 10)
              : ""
          }
        />
      ) : type === "month-year" ? (
        <WriteBacksCrmFieldMonthYear
          writeBackOptions={writeBackOptions}
          inputProps={inputProps}
          registerOptions={registerOptions}
          fieldName={fieldName}
          register={register}
          control={control}
          errorMessage={errorMessage}
          updateValue={updateValue}
        />
      ) : type === "decimal" ? (
        <Input
          type="text"
          {...inputProps}
          {...register(fieldName, {
            ...registerOptions,
            validate: {
              decimal: decimalValidator,
            },
          })}
        />
      ) : type === "integer" ? (
        <Input
          type="text"
          {...inputProps}
          {...register(fieldName, {
            ...registerOptions,
            validate: {
              integer: integerValidator,
            },
          })}
        />
      ) : type === "currency" ? (
        <LabelWrapper
          label={label}
          tooltip={inputProps.tooltip}
          required={inputProps.required}
        >
          <div style={{ display: "flex", gap: 5 }}>
            <div style={{ paddingTop: 6 }}>$</div>
            <div style={{ flex: 1 }}>
              <Input
                type="number"
                fit
                {...inputProps}
                label={undefined}
                step=".01"
                {...register(fieldName, {
                  ...registerOptions,
                  validate: {
                    decimal: decimalValidator,
                  },
                })}
              />
            </div>
          </div>
        </LabelWrapper>
      ) : type === "boolean" ? (
        <FormLabel
          style={{ display: "flex", alignItems: "center" }}
          tooltip={inputProps.tooltip}
        >
          <input
            type="checkbox"
            required={required}
            {...register(fieldName, registerOptions)}
          />
          <div style={{ width: 5 }} />
          {displayLabel}
        </FormLabel>
      ) : writeBackOptions.query ? (
        <Controller
          control={control}
          name={fieldName}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => {
            setInputValue(value);
            return selectOptions.length ? (
              <Select
                label={inputProps.label}
                options={selectOptions}
                onBlur={onBlur}
                simpleValue={value}
                menuPlacement="top"
                onChange={(option) => {
                  onChange(option.value);
                  setInputValue(option.value);
                }}
              />
            ) : (
              <Loading />
            );
          }}
        />
      ) : (
        <Input
          type="text"
          {...inputProps}
          {...register(fieldName, {
            ...registerOptions,
            onChange: (e) => setInputValue(e.target.value),
          })}
        />
      )}
      {Object.keys(queryValues).includes(inputValue) ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {row.values?.[0] &&
          writeBackOptions.drilldownQuerySave &&
          row.values[0][writeBackOptions.drilldownQuerySave.uuidField] ? (
            queryResults[writeBackOptions.drilldownQuerySave.id] ? (
              <WriteBacksCrmSubField
                fieldsArray={queryValues[inputValue]}
                register={register}
                fieldName={`${writeBackOptions.drilldownField}`}
                control={control}
                fieldsData={
                  queryResults[writeBackOptions.drilldownQuerySave.id]
                }
              />
            ) : (
              <Loading />
            )
          ) : (
            <WriteBacksCrmSubField
              fieldsArray={queryValues[inputValue]}
              register={register}
              fieldName={`${writeBackOptions.drilldownField}`}
              control={control}
              fieldsData={[]}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

const decimalPattern = /^(\d*\.)?\d+$/;
const integerPattern = /^\d+$/;

const decimalValidator = (value) =>
  value && !decimalPattern.test(value) ? "This field must be a number." : null;
const integerValidator = (value) =>
  value && !integerPattern.test(value)
    ? "This field must be an integer."
    : null;

import React from "react";
import InfoCard from "./InfoCard";
import { useTheme } from "emotion-theming";
import styled from "@emotion/styled";
import color from "../../Editors/ChartBuilder/attrColors";
import valueParser from "../../utils/valueParser";
import resultsMapper from "../../utils/resultsMapper";
import Refreshing from "../BaseChart/Refreshing";
import Loading from "../../UI/Loading/Loading";
import { useSelector } from "react-redux";

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-direction: ${(props) => props.direction};
  & > div {
    padding: 0 ${(props) => props.spaceAround}px;
  }
  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const InfoCardsTitle = styled.div`
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  padding-bottom: 8px;
  margin-bottom: 8px;
  span {
    padding-left: 6px;
    color: ${(props) => props.theme.primary};
    font-weight: 700;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const layoutSelectorMemo = (state) => state.layout.menuFilters;

export default function InfoCardGroup(props) {
  const theme = useTheme();
  const menuFilters = useSelector(layoutSelectorMemo);

  const {
    attrs,
    data,
    colorType = "success",
    title,
    loading,
    spaceAround,
    small,
    height,
    refreshing,
    direction,
    hideOnArray,
    drawZeros,
  } = props.chart;

  let processedData = data;

  if (!attrs) {
    return <div>Attributes not defined</div>;
  }

  if (data && data.length > 1 && hideOnArray) {
    processedData = [];
  }

  const overridenAttrs = attrs.map((attr) => {
    const { filterDependentValues, trendValues } = attr;

    const overridenValueKey = getFilterDependentValueKey(
      filterDependentValues,
      menuFilters
    );

    const overridenFormula = getFilterDependentValueKey(
      trendValues?.filterDependentValues,
      menuFilters
    );

    return {
      ...attr,
      title: overridenValueKey ?? attr.title,
      valueKey: overridenValueKey ?? attr.valueKey,
      trendValues: overridenFormula
        ? { ...attr.trendValues, formulas: [overridenFormula] }
        : attr.trendValues,
    };
  });

  function getKey(i, att) {
    return (att.title || "none") + i;
  }

  return (
    <div style={{ position: "relative" }} data-cy="info-card-group">
      {title ? (
        <InfoCardsTitle>
          {title.prefix}
          {title.dynamicValue ? (
            <span>{value(processedData, title.dynamicValue, loading)}</span>
          ) : null}
        </InfoCardsTitle>
      ) : null}

      <CardsContainer direction={direction} spaceAround={spaceAround}>
        <LoaderContainer>
          <Refreshing show={refreshing} comp={<Loading />} />
        </LoaderContainer>
        {overridenAttrs.map((att, i) => (
          <InfoCard
            small={small}
            key={getKey(i, att)}
            i={i}
            primaryColor={att.primaryColor}
            valueColor={
              att.colorType
                ? color(att.colorType, theme)
                : theme.notification[`${colorType}Main`]
            }
            {...att}
            loaded={processedData && !loading}
            value={value(processedData, att, loading)}
            rawValue={
              processedData &&
              processedData[i] &&
              processedData[i][att.valueKey]
            }
            lowerValue={
              att.lowerValueKey
                ? value(processedData, att, loading, att.lowerValueKey)
                : null
            }
            height={height}
            data={processedData}
            drawZeros={drawZeros}
            valueKey={att.valueKey}
            arrayFormula={att.formula}
          />
        ))}
      </CardsContainer>
      <br />
    </div>
  );
}

const value = (data, att, loading, lowerValueKey) => {
  if (!att) return "no attrs"; // This should not happen
  if (!data || loading) return "--";

  const arr = resultsMapper(data, att, lowerValueKey);
  if (att.limit) {
    arr.splice(att.limit);
  }
  return valueParser(arr, att, {}, lowerValueKey);
};

const getFilterDependentValueKey = (filterDependentValues, menuFilters) => {
  if (!filterDependentValues) {
    return;
  }

  const filter = menuFilters.find(
    (menuFilter) => menuFilter.name === filterDependentValues.name
  );

  const checkedValue = filter.values.find((value) => value.checked)?.value;
  return filterDependentValues[checkedValue];
};

import React, { Fragment } from "react";
import { scaleBand, scaleLinear } from "d3-scale";
import styled from "@emotion/styled";
import {
  doIfElse,
  extent,
  getCombinedDomain,
  pipe,
  scoop,
  zeroMax,
} from "../../utils/func";
import YAxis from "../Axes/YAxis";
import { useTheme } from "emotion-theming";
import SpringBar from "./SpringBar";
import { format } from "d3-format";
import dateFormat from "date-fns/format";
import { tooltipOffsets } from "../../utils/constants/constants";
import { xKeyFormatted, xKeyTicks } from "../../utils/charts/xKeyParser";
import { dateFromMonth } from "../../utils/dates/dateFunc";
import getVisualizationLabel, {
  getMappingType,
} from "../../utils/getVisualizationLabel";
import SpringGroup from "./SpringGroup";
import formatter, { parseAsDate } from "../../utils/formatters/formatter";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import { isValid } from "date-fns";
import { termMap } from "../../utils/charts/xKeyParser";

const Label = styled.text`
  text-anchor: ${(props) => (props.rotateProp ? "start" : "middle")};
  font-size: 10px;
  fill: ${(props) => props.theme.text.secondary};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
`;

export default function GroupedBar(props) {
  const {
    data,
    colors,
    width,
    height,
    seriesItemKeys,
    groupBy,
    groupByTerm,
    allXKeys,
    setTooltip,
    meta,
    xFormat,
    yKey,
    yKeys,
    yAxisFormat,
    xDateFromMonth,
    term,
    dateKey,
    xQuarterFromDate,
    withNegative,
    xAxisDate,
    showLabel,
    xAxisFormat,
    fiscalQuarterStartOffset,
    labelFormat,
    allTicks,
    ignoreDateTerm,
    xKey,
    xLabelRotateLimit = 70,
    emptyXAxisLabelOverride,
    forceRotateTicks,
  } = props;

  const theme = useTheme();

  const yDomain = pipe(
    scoop(["values", "value"]),
    doIfElse(withNegative, extent, zeroMax)
  )(data);

  const combinedYDomain = getCombinedDomain(withNegative, yDomain);

  const y = scaleLinear().domain(combinedYDomain).range([height, 10]);

  const xInner = scaleBand()
    .domain(yKeys || seriesItemKeys)
    .range([0, width / data.length])
    .paddingOuter(0.8);
  const xOuter = scaleBand().domain(allXKeys).range([0, width]);
  const matched = (da, key, key2, index, isDate, fullRow) => {
    if (yKeys && !isDate) {
      return da.values[index]?.value;
    }

    const match = da.values.find(
      (daa) => daa[groupByTerm ? termMap[term] + groupBy : groupBy] === key
    );
    if (fullRow) {
      return match;
    } else {
      return match ? (key2 ? match[key2] : match.value) : 0;
    }
  };

  const parseXFormat = (v) => {
    if (xFormat) return format(xFormat)(v);
    if (term && !groupByTerm && !ignoreDateTerm) return xKeyFormatted(v, term);
    if (xAxisDate) {
      const date = parseAsDate(v);
      return isValid(date) ? dateFormat(date, xAxisDate) : "";
    }
    if (xDateFromMonth) return dateFormat(new Date(dateFromMonth(v)), "MMM");
    if (xQuarterFromDate) return dateFormat(new Date(v), xQuarterFromDate);
    if (xAxisFormat) {
      const val = formatter(
        v,
        xAxisFormat,
        null,
        null,
        null,
        fiscalQuarterStartOffset,
        emptyXAxisLabelOverride
      );

      if (val.length > 18) {
        return val.substring(0, 18);
      } else {
        return val;
      }
    }
    if (v?.length > 18) {
      return v.substring(0, 18) + "...";
    } else {
      return v;
    }
  };

  const getFormat = yAxisFormat || getMappingType(meta?.fields, yKey);

  const showTooltip = (da, sik, index) => {
    const tooltipArray = [
      {
        label: getVisualizationLabel(meta.fields, yKeys ? yKeys[index] : yKey),
        value: matched(da, sik, null, index),
        type: yAxisFormat.includes("$")
          ? "currency"
          : getMappingType(meta?.fields, yKey),
      },
    ];

    // if you need to show date in tooltip please add dateKey to json file configuration
    if (dateKey && xAxisFormat) {
      const dateValue = da.key;
      tooltipArray.push({
        label: getVisualizationLabel(meta.fields, xKey || dateKey),
        value: dateValue,
        type: xAxisFormat,
      });
    }

    return tooltipArray;
  };

  const getYPos = (da, sik, index) => {
    const val = matched(da, sik, null, index);
    if (isNaN(val)) return 0;
    if (withNegative) {
      return y(Math.max(val, 0));
    } else return y(val);
  };

  const getHeight = (da, sik, index) => {
    const val = matched(da, sik, null, index);
    if (isNaN(val)) return 0;

    if (!withNegative) {
      return val >= 0 ? height - y(val) : 0;
    }

    if (val >= 0) {
      return y(0) - y(val);
    } else {
      return y(val) - y(0);
    }
  };

  const doFormat = (val) => {
    if (isNaN(val)) return "--";
    const currFormat = labelFormat || yAxisFormat;
    return formatter(val, currFormat);
  };

  const rotateXLabels =
    forceRotateTicks ||
    mobileBreakpoints.isMobile ||
    width / data.length < xLabelRotateLimit;
  const rotateBarLabels = width / data.length < 45;

  return (
    <>
      {data.map((da, dai) => (
        <g key={da.key + "-" + dai}>
          <g transform={`translate(${xOuter(da.key)}, 0)`}>
            {/*individual bars*/}
            {(yKeys || seriesItemKeys).map((sik, keyIndex) => (
              <Fragment key={keyIndex + "-" + sik + "-" + da.key}>
                <SpringBar
                  x={(function ch() {
                    return xInner(sik);
                  })()}
                  y={getYPos(da, sik, keyIndex)}
                  startPos={height}
                  height={getHeight(da, sik, keyIndex)}
                  width={xInner.bandwidth() * 0.8}
                  color={colors(keyIndex, sik, dai)}
                  onMouseOver={() =>
                    setTooltip({
                      x:
                        xOuter(da.key) +
                        xInner(sik) +
                        (xInner.bandwidth() / 2) * 0.8,
                      y: y(matched(da, sik, null, keyIndex)) + tooltipOffsets.y,
                      tooltip: showTooltip(da, sik, keyIndex),
                      selectedRow: matched(
                        da,
                        sik,
                        null,
                        keyIndex,
                        false,
                        true
                      ),
                      allValues: da,
                    })
                  }
                  onMouseOut={() => setTooltip(null)}
                  useLines={data.length > 50}
                  skipAnimation={data.length > 50}
                />
                {showLabel && (
                  <SpringGroup
                    y={getYPos(da, sik, keyIndex) - 6}
                    x={xInner(sik) + xInner.bandwidth() / 2 - 4}
                    skipAnimation
                  >
                    {/*Adjustment value of 0.43 should not be here*/}
                    {/*xInner only exists when there are series item keys*/}
                    <text
                      data-cy="grouped-bar-label"
                      textAnchor="middle"
                      fontSize="9px"
                      fontWeight="bold"
                      fill={theme.text.secondary}
                      transform={
                        rotateBarLabels ? "translate(6, -10) rotate(-90)" : null
                      }
                    >
                      {doFormat(matched(da, sik, null, keyIndex))}
                    </text>
                  </SpringGroup>
                )}
              </Fragment>
            ))}
          </g>

          {xKeyTicks(data.length, dai, allTicks) ? (
            <g
              data-cy="grouped-bar-x-label"
              transform={`translate(${
                xOuter(da.key) + xOuter.bandwidth() * 0.43
              }, ${height + 24})`}
            >
              <Label
                style={{
                  textAnchor: rotateXLabels ? "end" : "middle",
                  fontSize: 10,
                  fontWeight: 400,
                }}
                transform={
                  rotateXLabels
                    ? "translate(8 -10) rotate(-25) "
                    : "translate(8)"
                }
                title="I am a title for hover"
              >
                <title>{da.key}</title>
                <tspan>{parseXFormat(da.key)}</tspan>
              </Label>
            </g>
          ) : null}

          {da.label && data.length < 35 ? (
            // xInner(true) is not a thing
            <g
              transform={`translate(${
                xOuter(da.key) + xInner(true) + xInner.bandwidth() / 2
              }, ${y(da.values[0][yKey])})`}
            >
              <Label
                rotateProp
                transform={`rotate(-90) translate(3, 1)`}
                bold
                style={{
                  textAnchor: "start",
                  fontSize: 9,
                  fontWeight: 700,
                }}
              >
                {format(".1%")(da.label)}
              </Label>
            </g>
          ) : null}
        </g>
      ))}
      <YAxis
        {...props}
        yScale={y}
        yAxisGridColor={theme.divider}
        yAxisGrid
        yTicksCount={5}
        width={width}
        hideYAxisLine
        yAxisFormat={getFormat}
        domain={combinedYDomain}
      />
    </>
  );
}

GroupedBar.defaultProps = {
  xFormat: "",
};

import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  text-align: left;
  font-weight: 700;
  margin-bottom: 22px;
  position: relative;

  input {
    background: none;
    border: 1px solid ${(props) => props.theme.divider};
    margin-left: 12px;
    padding: 5px 12px;
    color: ${(props) => props.theme.text.secondary};
  }
`;

export default function SectionLabel({ children, style }) {
  return <Container style={style}>{children}</Container>;
}

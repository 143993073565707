import { useSelector } from "react-redux";
import styled from "@emotion/styled";

const Filter = styled.span`
  margin: 2px 20px 0px 0px;
  font-weight: 500;
`;

export default function SelectedFilter({ selectedMenuFilter }) {
  const menuFilters = useSelector((state) => state.layout.menuFilters);
  const selectedFilter =
    menuFilters.find((filter) => filter.name === selectedMenuFilter) ?? {};
  const filter = (selectedFilter.values || []).find((value) => value.checked);

  if (!filter) {
    return null;
  }

  return (
    <Filter>
      {selectedFilter.displayName}: {filter.value}
    </Filter>
  );
}

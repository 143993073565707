import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";

import Portal from "../../../UI/Portal/Portal";
import Flex from "../../../UI/Flex/Flex";
import { scrollbarDe } from "../../../styles/styledSnippets";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { lightenDarkenColor } from "../../../styles/colorConvertor";

export const SIDEBAR_WIDTH = "17.5rem";

const Container = styled.div(
  ({ theme, isOpened }) => `
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: ${isOpened ? 0 : "-" + SIDEBAR_WIDTH};
  bottom: 0;
  width: ${SIDEBAR_WIDTH};
  padding: 2rem 1rem;
  color: ${theme.text.secondary};
  background: ${lightenDarkenColor(theme.background.secondary, 5)};
  overflow-y: auto;
  // z-index should 1 here
  // we use 1003 because top menu and dashboards legend have a strange z-index > 1000
  z-index: 1003;
  transition: 0.3s;

  ${scrollbarDe(theme)}
`
);

const BackIcon = styled(FontAwesomeIcon)`
  margin-left: auto;
  cursor: pointer;
`;

const ActionContainer = styled(Flex)(
  ({ theme }) => `
  gap: 0.625rem;
  align-items: center;
  cursor: pointer;

  & .svg-inline--fa {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    color: ${theme.primary}
  }
`
);

const InfoText = styled.span(
  ({ theme }) => `
  color: ${theme.text.secondary};
  font-weight: 300;
  opacity: 0.5;
`
);

export default function ColumnsSelectionSidebar({
  children,
  isOpened,
  onClose,
  onClearClick,
  onFullQueryClick,
}) {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  return (
    <Portal>
      <Container
        data-cy="selection-sidebar"
        role="dialog"
        ref={ref}
        isOpened={isOpened}
      >
        <Flex direction="column" gap="2.5rem">
          <BackIcon
            data-cy="back-icon-button"
            icon={["fas", "long-arrow-left"]}
            onClick={onClose}
          />
          <Flex justifyContent="space-between">
            <ActionContainer data-cy="show-none-button" onClick={onClearClick}>
              <FontAwesomeIcon icon={["fal", "cube"]} />
              Show none
            </ActionContainer>
            <ActionContainer
              data-cy="show-all-button"
              onClick={onFullQueryClick}
            >
              <FontAwesomeIcon icon={["fal", "cubes"]} />
              Show all
            </ActionContainer>
          </Flex>
          <Flex direction="column" gap="1.5rem">
            <InfoText>Check columns to show</InfoText>
            {children}
          </Flex>
        </Flex>
      </Container>
    </Portal>
  );
}

import React from "react";
import { Popup as LefleatPopup } from "react-leaflet";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import formatter from "../../../utils/formatters/formatter";
import { getRidOfAggregation } from "../../TableView/Elements/EditableMenu";
import MoreDetails from "./MoreDetails";

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopupHeader = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  border-bottom: 1px solid darkgray;
  font-weight: 700;
`;

const Label = styled.label`
  padding-right: 5px;
`;

export default function Popup(props) {
  const { activePopup, closePopup, tooltip, meta, pinMode } = props;

  const formatMeta = (meta, type) => (key) => {
    const rootKey = getRidOfAggregation(key);
    const byAlias = meta.fields.find((f) => f.alias === key) ?? {};
    const byName = meta.fields.find((f) => f.name === rootKey) ?? {};

    return byAlias[type] ?? byName[type];
  };

  const formattedMeta = formatMeta(meta, "type");
  const formattedDisplayName = formatMeta(meta, "label");

  const { mappings = {}, separator = "", keys, label } = tooltip.title ?? {};
  const offset = pinMode ? [0, -35] : [0, -5];

  const insertSeparator = (index) => {
    if (index < keys?.length - 1) {
      return `${separator} `;
    }
  };

  return (
    <LefleatPopup
      minWidth={250}
      position={activePopup.center || [activePopup.lat, activePopup.lng]}
      onClose={closePopup}
      offset={offset}
      autoPan={false}
    >
      <PopupContainer>
        <PopupHeader>
          {label ||
            keys?.map((key, index) => (
              <span key={key}>
                {mappings[activePopup[key]] || activePopup[key]}
                {insertSeparator(index)}
              </span>
            ))}
        </PopupHeader>
        <div>
          {tooltip.values.map((value) => (
            <div key={value.key} style={{ marginBottom: 2 }}>
              <Label>{formattedDisplayName(value.key)}:</Label>
              <Label>
                {formatter(activePopup[value.key], formattedMeta(value.key))}
              </Label>
            </div>
          ))}
          <label>
            {tooltip.stringValues &&
              tooltip.stringValues.map((value, i, s) => (
                <span key={value.key}>
                  {i > 0 ? ", " : ""}
                  {activePopup[value.key]}
                </span>
              ))}
          </label>
          <MoreDetails
            details={tooltip?.drillDownDetails}
            activePopup={activePopup}
          />
        </div>
      </PopupContainer>
    </LefleatPopup>
  );
}

Popup.propTypes = {
  activePopup: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
};

Popup.defaultProps = {
  activePopup: {},
};

import { format, differenceInCalendarQuarters } from "date-fns";
import { min, max } from "lodash-es";

export default (term = "year") => {
  const formats = {
    month: "yyyyMM",
    quarter: "yyyy QQQ",
    year: "yyyy",
  };

  const d = new Date();
  return format(d, formats[term]);
};

export const getDateDifference = (latestDate, dateFilters) => {
  if (!latestDate || latestDate?.term === "year") {
    return true;
  }

  const { end } = dateFilters ?? {};

  if (!end?.value) {
    return true;
  }

  const dates = [new Date(end.value), new Date()];
  const [maxDate, minDate] = [max(dates), min(dates)];

  return differenceInCalendarQuarters(maxDate, minDate) === 0;
};

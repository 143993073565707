import React, { forwardRef, useMemo } from "react";
import selectStyles from "../../../styles/selectStyles";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import ReactCreatableSelect from "react-select/creatable";
import { useTheme } from "emotion-theming";
import PropTypes from "prop-types";
import useLabel from "../LabelWrapper/useLabel";
import { noop } from "lodash-es";

/**
 * Common hook for getting a styled either regular or a creatable react-select component.
 *
 * `simpleValue` is an alternative prop to value where you simply provide the
 * value or an array of values rather than having to redundantly pass the labels
 * back in with {value: x, label: y} objects.
 */
const useSelect = ({ value, ...props }, ref, SelectComponent) => {
  const theme = useTheme();
  const withLabel = useLabel(
    props.label,
    props.required,
    undefined,
    props.tooltip
  );
  const valueFoundBySimpleValue = useMemo(() => {
    return simpleValueToValue(
      props.simpleValue,
      props.options,
      props.getOptionValue
    );
  }, [props.getOptionValue, props.options, props.simpleValue]);

  let el = (
    <SelectComponent
      classNamePrefix="list"
      {...props}
      value={value ?? valueFoundBySimpleValue ?? false}
      ref={ref}
      data-cy={props.cy}
      id={props.cy}
      styles={selectStyles(
        theme,
        props.range,
        props.belowValue || theme.primary,
        props.bgPrimary,
        props.menuHeight
      )}
    />
  );

  if (!props.disabled && props.required) {
    // HTML5 "required" emulation.
    // https://github.com/JedWatson/react-select/issues/3140#issuecomment-514754657
    el = (
      <>
        {el}
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={(value?.value ?? valueFoundBySimpleValue) != null ? "1" : ""}
          onChange={noop}
          required
          data-cy={props.cy}
        />
      </>
    );
  }

  return withLabel(el);
};

const Select = (props, ref) => {
  return useSelect(props, ref, props.async ? AsyncSelect : ReactSelect);
};

const CreatableSelect = forwardRef((props, ref) => {
  return useSelect(props, ref, ReactCreatableSelect);
});

export default forwardRef(Select);

export { CreatableSelect };

export function simpleValueToValue(
  simpleValue,
  options = [],
  getOptionValue = (option) => option.value || option.value
) {
  if (simpleValue === undefined) {
    return undefined;
  }

  if (options[0]?.options) {
    // Options are grouped.
    options = options.map((v) => v.options).flat();
  }

  if (Array.isArray(simpleValue)) {
    return options.filter((option) =>
      simpleValue.includes(getOptionValue(option))
    );
  }
  return options.find((option) => getOptionValue(option) === simpleValue);
}

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  simpleValue: PropTypes.oneOf([
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]),
    null,
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  bgPrimary: PropTypes.bool,
  label: PropTypes.string,
  cy: PropTypes.string,
  height: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isMulti: PropTypes.bool,
};

Select.propTypes = propTypes;
CreatableSelect.propTypes = propTypes;

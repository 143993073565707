import styled from "@emotion/styled";
import PropTypes from "prop-types";
import getVisualizationLabel from "../../utils/getVisualizationLabel";

const DetailItem = styled.div(
  ({ theme }) => `
  width: 45%;
  max-height: 500px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 8px 10px;
  background: ${theme.divider};
`
);

const Label = styled.span`
  margin-right: 10px;
  font-weight: 500;
`;

export default function ProductStyleItem({ getFormattedValue, fields, name }) {
  return (
    <DetailItem>
      <Label data-cy="detail-item-label">
        {getVisualizationLabel(fields, name)}
      </Label>
      <span data-cy="detail-item-formatted-value">
        {getFormattedValue(name)}
      </span>
    </DetailItem>
  );
}

ProductStyleItem.propTypes = {
  getFormattedValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  fields: PropTypes.array,
};

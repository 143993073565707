import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../UI/Form/Input/Input";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 1%;
    position: relative;
    border: 1px solid ${(props) => props.theme.divider};
    border-radius: 5px;
    padding: 10px;
    svg {
      cursor: pointer;
      position: absolute;
      font-size: 12px;
      top: 5px;
      right: 5px;
      color: ${(props) => props.theme.notification.errorMain};
    }
  }
`;

export default function Filters({ state, setState }) {
  const theme = useTheme();

  const addFilter = () => {
    if (state.chart.filters) {
      const temp = [...state.chart.filters];
      temp.push({ type: "", value: "" });

      setState({ ...state, chart: { ...state.chart, filters: temp } });
      return;
    }

    setState({
      ...state,
      chart: { ...state.chart, filters: [{ type: "", value: "" }] },
    });
  };

  const changeFilter = ({ value, name }, index) => {
    const filters = [...state.chart.filters];

    filters[index][name] = value;

    setState({ ...state, chart: { ...state.chart, filters } });
  };

  const removeFilter = (index) => {
    const filters = state.chart.filters.filter((_, i) => i !== index);

    if (filters.length === 0) {
      const { filters, ...rest } = state.chart;
      setState({ ...state, chart: { ...rest } });
      return;
    }

    setState({ ...state, chart: { ...state.chart, filters } });
  };

  return (
    <Container>
      <div>
        <span>Filters</span>
        <FontAwesomeIcon
          icon={["fa", "plus"]}
          style={{ cursor: "pointer", marginLeft: 10, color: theme.primary }}
          onClick={addFilter}
        />
      </div>

      <br />

      <InputGroup>
        {state.chart.filters?.map((filter, index) => (
          <div key={"filter_" + index}>
            <Input
              label="Type"
              name="type"
              value={filter.type}
              onChange={({ target }) => changeFilter(target, index)}
            />
            <Input
              label="Value"
              name="value"
              value={filter.value}
              onChange={({ target }) => changeFilter(target, index)}
            />
            <FontAwesomeIcon
              icon={["fa", "times"]}
              style={{ cursor: "pointer" }}
              onClick={() => removeFilter(index)}
            />
          </div>
        ))}
      </InputGroup>
    </Container>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/Tooltip";
import SaveAsImage from "./SaveAsImage";
import mobileBreakpoints from "../../styles/mobileBreakpoints";

export default function BaseChart(props) {
  const {
    width,
    height,
    margin,
    tooltipConfig,
    metaFields,
    visualizationId,
    canvasOff,
    hideTooltip,
    offsetHeight = 0,
    offsetWidth = 0,
  } = props;
  const [tooltip, setTooltip] = useState(null);
  const [saveImage, setSaveImage] = useState(false);
  const deviceWidth = mobileBreakpoints.isMobile
    ? window.screen.width - 20
    : width;

  const { top, bottom } = margin;
  const marginTop = mobileBreakpoints.isMobile ? top * 2 : top;
  const marginBottom = mobileBreakpoints.isMobile ? bottom * 2 : bottom;

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => (canvasOff ? null : setSaveImage(true))}
      onMouseLeave={() => setSaveImage(false)}
    >
      {saveImage ? <SaveAsImage visualizationId={visualizationId} /> : null}

      <svg
        height={
          height -
            (mobileBreakpoints.isMobile ? top + bottom : 0) -
            offsetHeight || 10
        }
        width={deviceWidth || 10}
        opacity={width ? 1 : 0}
        data-attr="svg"
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          {height &&
            React.Children.map(props.children, (child) =>
              React.cloneElement(child, {
                ...child.props,
                height: height - marginTop - marginBottom - offsetHeight,
                width: width - margin.left - margin.right - offsetWidth,
                margin: margin,
                setTooltip: hideTooltip ? () => null : setTooltip,
              })
            )}
        </g>
      </svg>

      {tooltip && (
        <Tooltip
          config={tooltipConfig}
          metaFields={metaFields}
          tooltip={tooltip}
          data={tooltip.data || tooltip}
          left={margin.left + (tooltip.data ? tooltip.xPos : tooltip.x)}
          bottom={
            tooltip.data ? tooltip.yPos + margin.bottom : height - tooltip.y
          }
        />
      )}
    </div>
  );
}

BaseChart.defaultProps = {
  margin: {
    top: 20,
    left: 20,
    bottom: 20,
    right: 80,
  },
};

if (process.env.NODE_ENV === "test") {
  BaseChart.defaultProps.width = 100;
  BaseChart.defaultProps.height = 100;
}

BaseChart.propTypes = {
  margin: PropTypes.object.isRequired,
};

import * as actions from "../../actions/actionTypes";
import produce from "immer";

const initialState = {
  errors: [],
  attributes: {
    loading: false,
    list: [],
  },
  options: {
    loading: false,
    list: [],
  },
  dataSources: [],
  dataSourceFields: {},
  dataSourcesLoading: false,
  dataSourceFieldsLoading: false,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actions.GET_ATTRIBUTE_LIST_START:
      draft.attributes.loading = true;
      break;

    case actions.GET_ATTRIBUTE_LIST_FAIL:
      draft.attributes.loading = false;
      break;

    case actions.GET_ATTRIBUTE_LIST_SUCCESS:
      draft.attributes.loading = false;
      draft.attributes.list = action.results.data;
      break;

    case actions.CREATE_ATTRIBUTE_SUCCESS:
      draft.attributes.list.unshift(action.result);
      break;

    case actions.CREATE_ATTRIBUTE_FAIL:
      draft.errors = action.errors;
      break;

    case actions.GET_ATTRIBUTE_OPTION_START:
    case actions.CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_START:
      draft.options.loading = true;
      draft.options.list = [];
      break;

    case actions.UPDATE_ATTRIBUTE_SUCCESS:
      const newAttributes = draft.attributes.list.map((a) =>
        a.uuid === action.result.uuid ? { ...a, name: action.result.name } : a
      );

      draft.attributes.list = newAttributes;
      break;

    case actions.DELETE_ATTRIBUTE_SUCCESS:
      draft.attributes.list = draft.attributes.list.filter(
        (a) => a.uuid !== action.result
      );
      break;

    case actions.GET_ATTRIBUTE_OPTION_SUCCESS:
      draft.options.loading = false;
      draft.options.list = action.results.data.map((o) => ({
        ...o,
        standardizedUuid: o.standardizedOption
          ? o.standardizedOption.uuid
          : null,
      }));
      break;

    case actions.GET_ATTRIBUTE_OPTION_FAIL:
    case actions.CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_FAIL:
      draft.options.loading = false;
      break;

    case actions.CREATE_ATTRIBUTE_OPTION_SUCCESS:
      if (action.index >= 0) {
        draft.options.list[action.index] = action.result;
      } else {
        draft.options.list.push(action.result);
      }
      break;

    case actions.UPDATE_ATTRIBUTE_OPTION_SUCCESS:
      draft.options.list = draft.options.list.map((o) =>
        o.uuid === action.result.uuid ? action.result : o
      );
      break;

    case actions.UPDATE_ATTRIBUTE_OPTION_VALUE:
      draft.options.list = draft.options.list.map((o) =>
        o.uuid === action.uuid
          ? {
              ...o,
              [action.key]: action.value,
              updated: true,
            }
          : o
      );
      break;

    case actions.DELETE_ATTRIBUTE_OPTION_SUCCESS:
      if (action.index) {
        draft.options.list.splice(action.index, 1);
      } else {
        draft.options.list = draft.options.list.filter(
          (o) => o.uuid !== action.result
        );
      }
      break;

    case actions.GET_ATRIBUTE_DATA_SOURCES_START:
      draft.dataSourcesLoading = true;
      break;

    case actions.GET_ATRIBUTE_DATA_SOURCE_FIELDS_START:
      draft.dataSourceFieldsLoading = true;
      break;

    case actions.GET_ATRIBUTE_DATA_SOURCES_SUCCESS:
      draft.dataSources = action.data;
      draft.dataSourcesLoading = false;
      break;

    case actions.GET_ATRIBUTE_DATA_SOURCE_FIELDS_SUCCESS:
      draft.dataSourceFields = action.data;
      draft.dataSourceFieldsLoading = false;
      break;

    case actions.GET_ATRIBUTE_DATA_SOURCES_FAIL:
    case actions.GET_ATRIBUTE_DATA_SOURCE_FIELDS_FAIL:
      draft.dataSourceFieldsLoading = false;
      draft.dataSourcesLoading = false;
      break;

    case actions.CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_SUCCESS:
      draft.options.loading = false;
      draft.options.list = action.results.data.map((o) => ({
        ...o,
        standardizedUuid: o.standardizedOption
          ? o.standardizedOption.uuid
          : null,
      }));
      break;

    default:
      return draft;
  }
});

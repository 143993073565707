import { createPortal } from "react-dom";

/**
 * Utility to create a component that will render to a portal
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ToPortal({ portalRef, children }) {
  return portalRef.current ? createPortal(children, portalRef.current) : null;
}

/**
 * Warns before closing the browser window.
 *
 * Also if you put the returned {prompt} in the JSX in render(), then navigating away from the route
 * gets hooked into too.
 *
 * @param condition
 * @param {boolean} onlyIfPathNotMatches If TRUE, the prompt should not show up
 *  if navigation results in the same path.
 */
import { useEffect, useMemo } from "react";
import { Prompt, useRouteMatch, matchPath } from "react-router";

export default function useWarnBeforeTabWindowClose(
  condition,
  onlyIfPathNotMatches
) {
  const match = useRouteMatch();

  useEffect(() => {
    const listener = (event) => {
      if (condition) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", listener);
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [condition]);

  const prompt = useMemo(() => {
    return (
      <Prompt
        when={condition}
        message={(location) =>
          !onlyIfPathNotMatches || !matchPath(location.pathname, match)
            ? "There are unsaved changes. Are you sure you want to leave?"
            : true
        }
      />
    );
  }, [condition, match, onlyIfPathNotMatches]);

  return {
    prompt,
  };
}

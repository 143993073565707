/**
 * Inspired by Redux Toolkit's createAsyncThunk function.
 *
 * Allows for being able to wrap a promise such that both success and failure
 * could be handled, but avoiding _having_ to handle the error, and thus not
 * getting unresolved rejection errors in the console.
 *
 * The returned wrapped promise never throws, but rather either gets resolved
 * (successfully) with a payload property, or resolved unsuccessfully with an
 * error property.
 * Also, an unwrap() method is exposed in the returned promise that either
 * gives you the resolved value directly, or throws the error directly in the
 * event that you do intend to handle the error.
 *
 * @link https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
 * @link https://github.com/reduxjs/redux-toolkit/issues/618#issuecomment-645490761
 *
 * @template T
 * @param promise
 * @returns Promise<{payload?: T, error?: any}> & {unwrap: () => Promise<T>}
 */
export function wrapResult(promise) {
  const wrapped = promise.then(
    (response) => {
      return {
        payload: response,
      };
    },
    (error) => {
      return {
        error,
      };
    }
  );
  wrapped.unwrap = () => {
    return wrapped.then(unwrapApiResult, unwrapApiResult);
  };
  return wrapped;
}

/**
 * Wraps a thunk function such that dispatching it returns a wrapped promise.
 *
 * Make sure any error is thrown in the callback, otherwise the wrapper will
 * pick it up as a success.
 *
 * @param thunk {(dispatch: Function, getState: Function) => Promise}
 */
export function wrapAsyncThunk(thunk) {
  return (dispatch, getState) => {
    return wrapResult(thunk(dispatch, getState));
  };
}

function unwrapApiResult(result) {
  if (result.error) {
    throw result.error;
  }
  if (!("payload" in result)) {
    throw new Error("Missing payload: " + JSON.stringify(result.payload));
  }
  return result.payload;
}

export const permissionName = {
  DATA_SOURCE_READ: "data_sources-read",
  DATA_SOURCE_UPDATE: "data_sources-update",
  INSIGHTS_READ: "insights-read",
  QUERIES_READ: "queries-read",
  QUERIES_UPDATE: "queries-update",
  DATA_EDITOR: "data-editor",
  DATA_IMPORT: "data-import",
  USERS_UPDATE: "users-update",
  VISUALIZATIONS_READ: "visualizations-read",
  CONNECTIONS_READ: "connections-read",
};

export const permissionKey = {
  userManagement: "userManagement",
  siteConfiguration: "siteConfiguration",
  pageManagement: "pageManagement",
  dataSources: "dataSources",
  dataSourcesRead: "dataSourcesRead",
  dataExplorer: "dataExplorer",
  fileUploading: "fileUploading",
  insights: "insights",
  editDataDictionary: "editDataDictionary",
  databaseSwitching: "databaseSwitching",
  monitoring: "monitoring",
  emailReports: "emailReports",
};

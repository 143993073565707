import axios from "../../axios";
import { getQuery } from "./dataSettings/dataSettings";
import * as actionTypes from "./actionTypes";
import { getPages } from "./pageManagement";
import { loadDashboardConfiguration } from "./dashboard/dashboard";
import { loadDefaultConnection } from "./connections";
import prepareSettings from "./dataSettings/prepareSettings";
import convertFiltersToOperatorsMode, {
  convertFiltersToConfigFormat,
} from "../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import { omit } from "lodash-es";

export const listActiveTables = () => {
  return {
    type: actionTypes.LOAD_ACTIVE_TABLES_START,
    meta: {
      api: {
        endpoint: "/api/v1/active-tables",
        method: "GET",
      },
    },
  };
};

export const getActiveTable = (uuid, queryId) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ACTIVE_TABLE_START });
  const activeTableResponse = await axios.get(`/api/v1/active-tables/${uuid}`);
  const queryResponse = await axios.get(`/api/v1/queries/${queryId}`);

  dispatch({
    type: actionTypes.GET_ACTIVE_TABLE_SUCCESS,
    activeTable: activeTableResponse.data.data,
    query: queryResponse.data.data,
  });
};

// remove filters which are not on current page
function getMEnuFiltersOnPage(getState, filters) {
  const { activeTab, menuFilters } = getState().layout;

  return (filters ?? []).filter((item) => {
    const { show, hide } = menuFilters.find((mf) => mf.name === item.key) || {};

    if (show) {
      return (show ?? []).includes(activeTab?.uuid);
    }

    return !(hide ?? []).includes(activeTab?.uuid);
  });
}

export const loadActiveTableData =
  (queryUuid, view, checkedMenuFilters = []) =>
  (dispatch, getState) => {
    const configFilters = convertFiltersToConfigFormat(view?.filters || []);

    const existingCheckedMenuFilters = getMEnuFiltersOnPage(
      getState,
      checkedMenuFilters
    );

    const allFilters = [...configFilters, ...existingCheckedMenuFilters];
    const filters = convertFiltersToOperatorsMode(allFilters);

    const query = {
      format: "chart",
      order: {
        io_created_at: "DESC",
      },
      limit: 100,
      id: queryUuid,
    };

    const preparedSettings = prepareSettings({
      ...query,
      filters,
    });

    const payload = {
      ...preparedSettings,
      perPage: preparedSettings?.perPage || 300,
    };

    return dispatch({
      type: actionTypes.GET_ACTIVE_TABLE_DATA_START,
      meta: {
        api: {
          method: "POST",
          endpoint: `/api/v1/queries/${queryUuid}/exec`,
          payload,
        },
      },
    });
  };

function locateVisualizationUuid(visualizationUuid, pageSlug, getState) {
  const { pages } = getState().pageManagement;

  const page = pages.find((page) => {
    return page.slug === pageSlug;
  });

  const block = (page?.blocks ?? []).find((b) => {
    if (visualizationUuid) {
      return b.visualizations.find((v) => v.uuid === visualizationUuid);
    } else {
      return b.visualizations.find((v) => v.settings.type === "ActiveTable");
    }
  });

  const pageUuid = page.uuid;
  const blockUuid = block.uuid;

  return { pageUuid, blockUuid };
}

export const activeTableVisPayload = {
  filters: [],
  overrides: [],
  orders: [],
  settings: {
    type: "ActiveTable",
  },
};
export const initializeActiveTable = (page, block) => async (dispatch) => {
  // check if visualization already exists
  const nextPayload = {
    ...block,
    visualizations: [...block.visualizations, activeTableVisPayload],
  };

  await dispatch({
    type: actionTypes.INITIALIZE_ACTIVE_TABLE_START,
    pageUuid: page.uuid,
    meta: {
      api: {
        method: "PUT",
        endpoint: `/api/v1/pages/${page.uuid}/blocks/${block.uuid}`,
        payload: nextPayload,
      },
    },
  });
  dispatch(getPages());
};

// This is the initial column in a table, when this is new, there will be
// no values, so at least one needs to be editable
const defaultColumn = {
  name: "start_here",
  type: "string",
  defaultValue: null,
  valueOptions: {}, // Select options list
  displaySettings: { activeOnly: true },
  validationRules: {},
  isNullable: true,
  isEditable: true,
};

export const createNewActiveTable =
  (tableName, visualizationUuid, pageSlug, createType, joinedMode) =>
  async (dispatch, getState) => {
    const defaultConnection = loadDefaultConnection(getState);
    const { pageUuid, blockUuid } = locateVisualizationUuid(
      visualizationUuid,
      pageSlug,
      getState
    );

    const isJoinType = createType !== "new" && joinedMode.mode;

    let createTableResponse;

    const payload = {
      connectionUuid: defaultConnection.uuid,
      dbName: defaultConnection.database,
      tbl: tableName, // existing or new source table
      fromExistingTable: createType === "existing",
      columns: [defaultColumn],
    };

    if (createType !== "new") {
      const {
        mode,
        viewName,
        activeTableName,
        columnToJoinName,
        columnToJoinType,
        namespace,
      } = joinedMode;

      // Join Mode
      if (mode) {
        payload.fromExistingTable = false;
        payload.tbl = activeTableName; // Active Table Table Name
        payload.joinedMode = {
          viewName, // Output View
          columnToJoinName, // Foreign Key
          tableToJoinName: (namespace ? namespace + "." : "") + tableName,
        };
        payload.columns = [
          defaultColumn,
          {
            isNullable: false,
            isEditable: false,
            name: columnToJoinName,
            type: columnToJoinType,
          },
        ];
      }
    }

    try {
      createTableResponse = await dispatch({
        type: actionTypes.CREATE_NEW_ACTIVE_TABLE_START,
        meta: {
          api: {
            endpoint: `/api/v1/active-tables`,
            method: "POST",
            toastOnFailure: true,
            payload,
          },
        },
      });
      if (createTableResponse.error) {
        return dispatch({
          type: actionTypes.CREATE_NEW_ACTIVE_TABLE_FAIL,
          error: createTableResponse.error,
        });
      } else {
        dispatch({
          type: actionTypes.CREATE_NEW_ACTIVE_TABLE_SUCCESS,
          results: createTableResponse.payload.data,
        });
      }
    } catch (e) {
      return dispatch({
        type: actionTypes.CREATE_NEW_ACTIVE_TABLE_FAIL,
        error: e,
      });
    }

    const queryTable = isJoinType ? joinedMode.viewName : tableName;

    const queryResponse = await createQueryFromTable(
      queryTable,
      defaultConnection.uuid,
      defaultConnection.database
    );

    dispatch({
      type: actionTypes.UPDATE_ACTIVE_TABLE_CHART_CONFIG_START,
    });

    await axios.request({
      method: "PUT",
      url: `api/v1/pages/${pageUuid}/blocks/${blockUuid}/visualizations/${visualizationUuid}`,
      data: {
        queryUuid: queryResponse.uuid,
        filters: null,
        overrides: null,
        orders: null,
        settings: {
          type: "ActiveTable",
          activeTableUuid: createTableResponse.payload.data.data.uuid,
        },
      },
    });
    dispatch({ type: actionTypes.CREATE_NEW_ACTIVE_TABLE_PROCESS_COMPLETE });
    dispatch(loadDashboardConfiguration(pageSlug));
  };

// Move below to dataSettings
export const createQueryFromTable = async (
  tableName,
  connectionUuid,
  database
) => {
  const displayName = `Active Table - ${tableName}`;
  // create DataSource
  const dataSourcePayload = {
    typeId: 3, // force to type 3, database type
    connectionUuid,
    displayName,
    db: database,
    tbl: tableName,
  };

  const dataSourceResponse = await axios.post(
    "/api/v1/data_sources",
    dataSourcePayload
  );
  const queryPayload = {
    name: displayName,
    dataSources: [dataSourceResponse.data.data],
  };
  const queryResponse = await axios.post("/api/v1/queries", queryPayload);
  return queryResponse.data.data;
};

// api do not support dates with time like: 2024-08-28 12:56:25
// here we drop time part
function convertIfDate(columns, name, value) {
  const isDate = columns.some(
    (column) => column.name === name && column.type === "date"
  );

  if (isDate) {
    return (value ?? "").slice(0, 10);
  }

  return value;
}

export const createRow = (tableUuid, payload, columns, joinId) => {
  const body = columns.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: convertIfDate(columns, curr.name, payload[curr.name]),
    };
  }, {});

  // For API image types: If a file is not uploaded, the API validation will not accept string inputs.
  // Therefore, to avoid validation errors, this column should be excluded if its not new file when adding a new row.
  const { name } = columns.find((column) => column.type === "api-image") ?? {};
  const exclude = name && !(body[name] instanceof File);

  return {
    type: actionTypes.ADD_ACTIVE_TABLE_ROW_START,
    meta: {
      api: {
        endpoint: `/api/v1/active-tables/${tableUuid}/rows`,
        method: "POST",
        payload: exclude ? omit(body, name) : body,
      },
    },
    joinId,
    apiImageColumn: name,
  };
};

export const updateRow =
  (tableUuid, payload, columns, changedFieldName) => (dispatch) => {
    // Create a new FormData instance
    dispatch({ type: actionTypes.UPDATE_ACTIVE_TABLE_ROW_START });

    const imageFields = columns.filter((c) => c.type === "api-image");

    const { io_created_at, io_updated_at, ...updatePayload } = payload;
    const formData = new FormData();

    // update only changed column, not all of them (uuid of row is required)
    // reason: there can be fields which cant be modified
    const directPayload = {
      uuid: updatePayload.uuid,
      [changedFieldName]: convertIfDate(
        columns,
        changedFieldName,
        updatePayload[changedFieldName]
      ),
    };

    // Iterate over the payload object
    for (let key in directPayload) {
      // If the payload's property is a File or an array of Files, append it to formData as a file
      if (
        payload[key] instanceof File ||
        (Array.isArray(payload[key]) && payload[key][0] instanceof File)
      ) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((file, index) => {
            formData.append(`${key}${index}`, file);
          });
        } else {
          formData.append(key, payload[key]);
        }
      } else {
        if (!imageFields.find((f) => f.name === key) && payload[key] !== null) {
          const value =
            payload[key] === true
              ? 1
              : payload[key] === false
              ? 0
              : payload[key];
          formData.append(key, value);
        }
      }
    }
    formData.append("_method", "PUT");

    axios
      .post(`/api/v1/active-tables/${tableUuid}/rows`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.UPDATE_ACTIVE_TABLE_ROW_SUCCESS,
          results: response.data,
        });
      })
      .catch((error) => {
        const errorObject = error.response?.data;
        dispatch({
          type: actionTypes.UPDATE_ACTIVE_TABLE_ROW_FAIL,
          error: errorObject,
        });
      });
  };

export const updateActiveTableConfig = (nextConfig) => {
  return {
    type: actionTypes.UPDATE_ACTIVE_TABLE_START,
    meta: {
      api: {
        endpoint: `/api/v1/active-tables/${nextConfig.uuid}`,
        method: "PUT",
        payload: nextConfig,
      },
    },
  };
};

export const updateActiveTableColumn =
  (queryUuid, nextTableConfig, checkedMenuFilters) => async (dispatch) => {
    const { activeView, ...rest } = nextTableConfig;

    const payload = {
      ...rest,
      columns: rest.columns.map((column) => omit(column, "displayName")),
    };

    dispatch({ type: actionTypes.UPDATE_ACTIVE_TABLE_START });

    const activeTableResponse = await axios.put(
      `api/v1/active-tables/${rest.uuid}`,
      payload
    );

    // @todo Need to add Toast on failure

    const queryResponse = await dispatch(getQuery(queryUuid));
    const query = queryResponse.payload.data.data;
    const queryDataSourceUuid = query.dataSources[0].uuid;

    // Rescan data source updates datasource
    await axios.get(
      `api/v1/data_sources/${queryDataSourceUuid}/rescan?writeMode=all`
    );
    const ds = await axios.get(`api/v1/data_sources/${queryDataSourceUuid}`);

    const nextQueryPayload = {
      ...query,
      dataSources: [ds.data.data],
      // fields: [], // what are fields for?
    }; // We MIGHT want to have query fields added here precisely later

    // Update Query
    const nextQueryResponse = await axios.put(
      `api/v1/queries/${queryUuid}/`,
      nextQueryPayload
    );

    dispatch({
      type: actionTypes.UPDATE_ACTIVE_TABLE_COLUMN_SUCCESS,
      activeTable: activeTableResponse,
      query: nextQueryResponse,
    });

    await dispatch(
      loadActiveTableData(queryUuid, activeView, checkedMenuFilters)
    );
  };

export const saveActiveTableView =
  (activeTableUuid, nextViewConfig, views, reloadData) =>
  async (dispatch, getState) => {
    const viewUuid = nextViewConfig.uuid;
    const method = viewUuid ? "PUT" : "POST";

    const owner = {
      uuid: getState().auth.uuid,
      email: getState().auth.email,
      role: getState().auth.role,
      name: getState().auth.name,
      ttg: getState().auth.ttg,
    };

    // Create or update
    const startType = !!viewUuid
      ? actionTypes.UPDATE_AT_VIEW_START
      : actionTypes.SAVE_AT_VIEW_START;

    const remainingViews = (views ?? []).filter(
      (view) => view.uuid !== nextViewConfig.uuid
    );

    // when isDefaultView is set to some view, need to drop isDefaultView from other views
    if (nextViewConfig?.displaySettings?.isDefaultView) {
      await Promise.all(
        remainingViews.map((view) =>
          axios.put(
            `/api/v1/active-tables/${activeTableUuid}/views/${view.uuid}`,
            {
              ...view,
              ...(view.displaySettings && {
                displaySettings: JSON.stringify({
                  ...view.displaySettings,
                  isDefaultView: false,
                }),
              }),
            }
          )
        )
      );
    }

    const displaySettings = { ...(nextViewConfig.displaySettings ?? {}) };

    if (!viewUuid) {
      displaySettings.owner = owner;
    }

    await dispatch({
      type: startType,
      meta: {
        api: {
          endpoint: `/api/v1/active-tables/${activeTableUuid}/views/${
            viewUuid ?? ""
          }`,
          method,
          payload: {
            ...nextViewConfig,
            ...(displaySettings && {
              displaySettings: JSON.stringify(displaySettings),
            }),
          },
        },
      },
    });

    reloadData && reloadData(viewUuid, nextViewConfig);
  };

export const clearActiveTable = () => (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ACTIVE_TABLE });
};

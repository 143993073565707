import React from "react";
import PropTypes from "prop-types";
import SpringGroup from "./GroupedBar/SpringGroup";
import styled from "@emotion/styled";
import mobileBreakpoints from "../styles/mobileBreakpoints";
import formatter from "../utils/formatters/formatter";

const Label = styled.text`
  fill: ${(props) => props.theme.text.secondary};
  text-anchor: middle;
  font-size: 9px;
  font-weight: 700;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    transform: rotate(-90deg);
  }
`;

export default function BarLabels(props) {
  const { x, y, values, yFormat, skipAnimation, xInner } = props;
  if (values.length > 100) return null;
  const offset = {
    x: mobileBreakpoints.isMobile ? 4 : values.length > 12 ? 4 : 0,
    y: mobileBreakpoints.isMobile ? -20 : values.length > 12 ? -10 : 0,
  };

  return values.map((v, i) => {
    return (
      <SpringGroup
        skipAnimation={skipAnimation}
        key={v.x + "-" + i}
        x={x(v.x) + x.bandwidth() * 0.45 + xInner.bandwidth() * 0.6 + offset.x}
        y={y(Math.round(v.total)) - 6 + offset.y}
      >
        <Label
          style={{
            textAnchor: `${values.length > 12 ? "left" : "middle"}`,
            fontSize: 9,
            fontWeight: 700,
            transform: `rotate(${
              values.length > 12 || mobileBreakpoints.isMobile
                ? "-90deg"
                : "0deg"
            })`,
          }}
        >
          {formatter(v.labelTotal || v.total, yFormat).replace("G", "B")}
        </Label>
      </SpringGroup>
    );
  });
}

BarLabels.propTypes = {
  x: PropTypes.func,
  y: PropTypes.func,
  values: PropTypes.array,
};

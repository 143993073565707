import React, { useRef } from "react";
import ResizableDiv from "../DataExplorerUI/ResizableDiv";
import styled from "@emotion/styled";
import TableSorting from "./TableSorting";
import { dataTypeAlignment } from "../../../../utils/constants/dataTypes";

const Active = styled.div`
  ${(props) => `
  position: absolute;
  width: ${props.width}px;
  left: 0;
  top: 0;
  height: calc(100vh);
  z-index: 10000;
  padding: 2px 6px;
  box-sizing: border-box;
  border: 2px solid ${props.theme.notification.infoMain};
  pointer-events: none;
`}
`;

const InnerHeader = styled.div`
  position: relative;
  text-align: ${(props) => props.align};
  top: -1px;
  user-select: none;
  box-sizing: border-box;
  div:first-of-type {
    pointer-events: none;
  }
`;

export default function NavigationTableHeader(props) {
  const {
    column,
    columnWidth,
    handleSetColumnWidth,
    active,
    setShowOptions,
    activeOptions,
    hideOptions,
    orders,
    setRowOrder,
  } = props;
  const ref = useRef(null);

  const handleShowOptions = () => {
    const dims = ref.current.getBoundingClientRect();
    setShowOptions(dims);
  };

  function checkSorting(column) {
    if (!orders) return false;
    const columnHasSorting = orders.find((o) => o.name === column.name);
    if (!columnHasSorting) return false;
    return (
      <TableSorting
        direction={columnHasSorting.sort}
        name={column.name}
        setRowOrder={setRowOrder}
      />
    );
  }

  const align = dataTypeAlignment(column?.mapping?.type);

  return (
    <div style={{ position: "relative" }} ref={ref}>
      {active ? (
        <Active
          data-cy="active-column-highlight"
          active={active}
          width={columnWidth}
        />
      ) : null}

      <ResizableDiv handleChange={handleSetColumnWidth} active={activeOptions}>
        <InnerHeader
          data-cy="nav-th"
          onMouseEnter={handleShowOptions}
          onMouseLeave={hideOptions}
          onClick={() => setRowOrder(column.name)}
          align={align}
        >
          {checkSorting(column)}
          {column.mapping.displayName}
        </InnerHeader>
      </ResizableDiv>
    </div>
  );
}

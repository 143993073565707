import { format } from "date-fns";
import { getTimeExecutionStandartColor } from "../ChartItem";
import { getDateWithoutTimezoneDifference } from "../../../utils/formatters/dateFormatter";

export function getNonEmptyResuts(array) {
  return (array ?? []).filter((item) => item.results.length);
}

function formatDate(createdAt) {
  return format(
    getDateWithoutTimezoneDifference(createdAt),
    "MM/dd/yyyy HH:mm:ss"
  );
}

function wrapNameWithQuotes(name) {
  return name.includes("/") ? `"${name}"` : name;
}

function getPage(result, pages) {
  return (
    pages.find((page) =>
      page.blocks.some((block) =>
        block.visualizations.some(
          (visualization) => visualization.uuid === result?.visualization?.uuid
        )
      )
    ) ?? {}
  );
}

function setChartQueryName(result, pages, index) {
  const page = getPage(result, pages);

  const pageParentName = page.parentName
    ? wrapNameWithQuotes(page.parentName) + " / "
    : "";

  const pageName = wrapNameWithQuotes(page.displayName || "");

  const chartName = wrapNameWithQuotes(
    result?.visualization?.block.name || "Untitled " + (index + 1)
  );

  return `${pageParentName}${pageName} / ${chartName}`;
}

function setIsDrillable(result) {
  return !!+result.isDrilldown;
}

export function buildTableChartConfigData(list, pages) {
  return list.reduce((acc, curr) => {
    curr.results.forEach((result, index) => {
      const page = getPage(result, pages);
      if (page && !page.mobile) {
        acc.push({
          createdAt: formatDate(curr.createdAt),
          "Parent Page (optional) / Page / Chart name": setChartQueryName(
            result,
            pages,
            index
          ),
          Duration: result.durationSeconds,
          "Is Drilldown": setIsDrillable(result),
          Status: getExecutionTimeType(result.durationSeconds, result.success),
          uniqueRowUuid: curr.uniqueRowUuid + index,
          urlKey: `${window.origin}/dashboard/${page.slug}`,
        });
      }
    });

    return acc;
  }, []);
}

export function buildTableChartConfig(data, theme) {
  return {
    data,
    loading: false,
    loaded: true,
    type: "TableChart",
    isSortable: true,
    freezeLeft: true,
    groupingKey: "createdAt",
    dynamicSubTitleKeys: ["Duration"],
    rowGroupKey: "Parent Page (optional) / Page / Chart name",
    hiddenSubTitles: ["Duration"],
    maxColumnsWidth: { createdAt: 100 },
    isGroupingSortable: true,
    enumColorsSettings: [
      {
        column: "Duration",
        conditionalColumn: "Status",
        drilldownConditionalIcon: "Is Drilldown",
        values: [
          {
            value: "FAILED",
            background: "black",
            color: "white",
          },
          {
            value: "SLOW",
            background: getTimeExecutionStandartColor("SLOW", theme),
            color: "white",
          },
          {
            value: "OK",
            background: getTimeExecutionStandartColor("OK", theme),
            color: "white",
          },
          {
            value: "FAST",
            background: getTimeExecutionStandartColor("FAST", theme),
            color: "white",
          },
        ],
      },
    ],
    linkColumns: [
      {
        key: "Parent Page (optional) / Page / Chart name",
        urlKey: "urlKey",
      },
    ],
  };
}

const FAST = 1.5; // second
const OK = 3; // seconds

export function getExecutionTimeType(time, loaded) {
  if (!loaded) {
    // not loaded
    return "FAILED";
  }

  if (time < FAST) {
    // fast
    return "FAST";
  }

  if (time > FAST && time < OK) {
    // ok
    return "OK";
  }

  return "SLOW"; // slow
}

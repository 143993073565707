import React from "react";
import { useSpring, animated } from "react-spring";
import theme from "../../styles/theme";

export default function SpringHorizontalBar(props) {
  const { width, x, startPos, color, chartNum, ...rest } = props;
  const aniProps = useSpring({
    to: {
      width: width || 0,
      x: x || 0,
      fill: color || theme["chart" + chartNum] || "gray",
    },
    from: {
      x: 0,
      width: 0,
      fill: "gray",
    },
  });

  return <animated.rect style={aniProps} {...rest} />;
}

import { buildDynamicFiltersQuery } from "../../charts/TableView/functions/dynamicDrillDown";
import ChartItem from "./ChartItem";

export default function DrilldownItem({ chart, executedCharts, theme }) {
  if (!chart.hasRowExpand) {
    return null;
  }

  return (
    <div style={{ marginLeft: 20 }}>
      <ChartItem
        chart={{ ...chart, ...chart.rowExpandVisualizationParams }}
        executedCharts={executedCharts}
        theme={theme}
      />
    </div>
  );
}

export function buildDrilldownInnerChart({
  visualizations,
  allLoaded,
  executedCharts,
}) {
  return visualizations.reduce((acc, curr) => {
    function check(chart) {
      const { hasRowExpand, visualizationId, rowExpandVisualizationParams } =
        chart;
      if (hasRowExpand && executedCharts[visualizationId]) {
        const innerChart = {
          ...chart,
          ...rowExpandVisualizationParams,
          ...(allLoaded && {
            filters: buildDynamicFiltersQuery(
              executedCharts[visualizationId].data[0],
              chart.rowExpandVisualizationParams.dynamicFilters,
              chart.rowExpandVisualizationParams.dynamicFilterValue,
              chart.parameterizedFilterPrefix
            ),
          }),
        };
        acc.push(innerChart);
        check(innerChart);
      }
    }

    check(curr);
    return acc;
  }, []);
}

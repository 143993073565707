import React from "react";
import { hexToRgba } from "../../styles/colorConvertor";
import { useTransition, animated } from "react-spring";
import { useTheme } from "emotion-theming";

export default function Refreshing({ comp, show, zIndex = 1 }) {
  const theme = useTheme();
  const transitions = useTransition(show, {
    from: {
      opacity: 0,
      width: "100%",
      height: "0%",
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      top: 0,
      left: 0,
      background: hexToRgba(theme.background.primary, 0.5),
      zIndex,
    },
    enter: {
      opacity: 1,
      height: "100%",
    },
    leave: {
      opacity: 0,
      height: "0%",
    },
  });
  if (!show) return null;

  return transitions(
    (styles, item) => item && <animated.div style={styles}>{comp}</animated.div>
  );
}

import * as actions from "../actions/actionTypes";

const initialState = {
  loading: false,
  reportedQueries: [],
  reportedQuery: null,
  reportedQueryLoading: true,
  error: false,
  previewLoading: false,
  recipientLoading: false,
  recipientGroupLoading: false,
  syncLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_REPORTED_QUERIES_START:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_REPORTED_QUERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedQueries: action.results.data.map((item) => ({
          ...item,
          active: !!+item.active,
        })),
      };

    case actions.GET_REPORTED_QUERIES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.UPDATE_REPORTED_QUERIES_START:
      return {
        ...state,
        syncLoading: true,
      };

    case actions.GET_REPORTED_QUERY_SUCCESS:
    case actions.UPDATE_REPORTED_QUERIES_SUCCESS:
      return {
        ...state,
        reportedQuery: {
          ...action.results.data,
          active: !!+action.results.data.active,
        },
        reportedQueryLoading: false,
        error: !action.results.data.visualizationUuid,
        syncLoading: false,
      };

    case actions.GET_REPORTED_QUERY_FAIL:
      return {
        ...state,
        reportedQueryLoading: false,
        syncLoading: false,
        error: true,
      };

    case actions.CLEAR_REPORTED_QUERY:
      return {
        ...state,
        reportedQuery: null,
        error: false,
      };

    case actions.CREATE_REPORTED_QUERIES_RECIPIENT_SUCCESS:
      const nextRecipients = [
        ...state.reportedQuery.recipients,
        action.results.data,
      ];
      return {
        ...state,
        reportedQuery: { ...state.reportedQuery, recipients: nextRecipients },
        recipientLoading: false,
      };

    case actions.CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS:
      const nextRecipientGroups = [
        ...state.reportedQuery.recipientGroups,
        action.results.data,
      ];

      return {
        ...state,
        reportedQuery: {
          ...state.reportedQuery,
          recipientGroups: nextRecipientGroups,
        },
        recipientGroupLoading: false,
      };

    case actions.PREVIEW_EMAIL_START:
      return {
        ...state,
        previewLoading: true,
      };

    case actions.PREVIEW_EMAIL_SUCCESS:
    case actions.PREVIEW_EMAIL_FAIL:
      return {
        ...state,
        previewLoading: false,
      };

    case actions.CREATE_REPORTED_QUERIES_RECIPIENT_START:
    case actions.DELETE_REPORTED_QUERIES_RECIPIENT_START:
      return {
        ...state,
        recipientLoading: true,
      };

    case actions.CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_START:
    case actions.DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_START:
      return {
        ...state,
        recipientGroupLoading: true,
      };

    case actions.CREATE_REPORTED_QUERIES_RECIPIENT_FAIL:
    case actions.DELETE_REPORTED_QUERIES_RECIPIENT_SUCCESS:
    case actions.DELETE_REPORTED_QUERIES_RECIPIENT_FAIL:
      return {
        ...state,
        recipientLoading: false,
      };

    case actions.CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL:
    case actions.DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS:
    case actions.DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL:
      return {
        ...state,
        recipientGroupLoading: false,
      };

    default:
      return state;
  }
};

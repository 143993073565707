import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import Dates from "./Dates";
import GlobalSettings from "./GlobalSettings";
import LimitRows from "./LimitRows/LimitRows";
import Orders from "./Orders";
import RowStyles from "./RowStyles";
import { isEqual } from "lodash-es";
import ParameterizedQuery from "./ParameterizedQuery";
import GroupingExtraColumns from "./GroupingExtraColumns/GroupingExtraColumns";
import ExportType from "./ExportType";
import RunQueryOnFilters from "./RunQueryOnFilters";

const Container = styled.div(
  ({ theme }) => `
  padding: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;
  ${scrollbarDe(theme)};
`
);

export default function GeneralSettings({
  chart,
  setChartState,
  filters,
  query,
  immutableChart,
  fields,
  isParameterized,
  previewChart,
}) {
  const theme = useTheme();
  const [prevChart, setPrevChart] = useState(chart);

  useEffect(() => {
    if (isParameterized && !isEqual(prevChart, chart)) {
      setPrevChart(chart);
      setChartState({ ...chart, isParameterized: true });
    }
  }, [chart, isParameterized, prevChart, setChartState]);

  return (
    <Container>
      <ParameterizedQuery
        chart={chart}
        setChartState={setChartState}
        theme={theme}
        isParameterized={isParameterized}
      />
      <br />
      <RowStyles
        setChartState={setChartState}
        chart={chart}
        theme={theme}
        fields={fields}
      />

      <GlobalSettings
        setChartState={setChartState}
        chart={chart}
        theme={theme}
        filters={filters}
        isParameterized={isParameterized}
        immutableChart={immutableChart}
        fields={fields}
        previewChart={previewChart}
      />

      <Orders
        setChartState={setChartState}
        chart={chart}
        theme={theme}
        fields={fields}
      />
      <Dates
        setChartState={setChartState}
        chart={chart}
        isParameterized={isParameterized}
        immutableChart={immutableChart}
        fields={fields}
        parameters={query?.parameters}
        theme={theme}
      />
      <LimitRows
        setChartState={setChartState}
        isParameterized={isParameterized}
        chart={chart}
        theme={theme}
        parameters={query?.parameters}
      />
      <GroupingExtraColumns
        setChartState={setChartState}
        chart={chart}
        theme={theme}
        isParameterized={isParameterized}
        fields={fields}
      />
      <ExportType setChartState={setChartState} chart={chart} theme={theme} />
      <RunQueryOnFilters
        setChartState={setChartState}
        chart={chart}
        theme={theme}
      />
    </Container>
  );
}

import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import useOnClickOutside from "../hooks/useOnClickOutside";
import FormatSelector from "./QueryEditor/FormatSelector";
import TypeIcon from "./TypeIcon";
import { mappingTypes } from "../utils/constants/dataTypes";

const TypesContainer = styled.div`
  height: 100%;
  display: flex;
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
`;

const EditContainer = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
`;

export default function TypeEditor(props) {
  const { type, setValue, deleting, open, readOnly } = props;
  const closeRef = useRef(null);
  const [editing, setEditing] = useState(false);
  useOnClickOutside(closeRef, () => setEditing(false));

  const handleSetValue = (val) => {
    if (val !== type) {
      setValue(val);
    }
    setEditing(false);
  };

  const iconValue = (t) => {
    const useType = mappingTypes[t] ? t : "string";
    return [mappingTypes[useType].lib, mappingTypes[useType].icon];
  };

  return (
    <Wrapper data-type={type}>
      <TypesContainer>
        {!readOnly && (editing || open) ? (
          <EditContainer ref={closeRef}>
            <FormatSelector
              handleSetValue={handleSetValue}
              active={type}
              allowed={props.allowed}
            />
          </EditContainer>
        ) : (
          <TypeIcon
            data-cy="type-editor-view"
            onClick={() => setEditing(true)}
            deleting={deleting}
          >
            <FontAwesomeIcon
              data-cy="type-editor-display-icon"
              size="sm"
              icon={iconValue(type)}
            />
          </TypeIcon>
        )}
      </TypesContainer>
    </Wrapper>
  );
}

TypeEditor.defaultProps = {
  type: "string",
};

TypeEditor.propTypes = {
  type: PropTypes.string,
  setValue: PropTypes.func,
  open: PropTypes.bool,
  allowed: PropTypes.array,
};

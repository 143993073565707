export default (fields) => (segment, yLabel) => {
  const bySegment = fields.find(
    (item) => item.name === segment || item.alias === segment
  );
  const byYLabel = fields.find(
    (item) => item.name === yLabel || item.alias === yLabel
  );

  return {
    type: bySegment?.type || byYLabel?.type,
    label: bySegment?.label || byYLabel?.label,
  };
};

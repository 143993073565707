import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import SimpleTooltip from "../../../UI/SimpleTooltip/SimpleTooltip";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/index";

const paddingAmount = "6px";
const Container = styled.div`
  cursor: pointer;
  padding: ${paddingAmount};
  position: relative;
  border-radius: 50%;
  svg {
    position: relative;
    top: 1px;
    left: -1px;
  }
  path {
    fill: ${(props) => props.theme.notification.warningMain};
  }
  &:hover {
    background: ${(props) => props.theme.background.secondary};
  }
`;

const ApplySpan = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.notification.warningMain};
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

const InsightContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 106px);
  padding: 46px;
  border-top: 1px solid ${(props) => props.theme.divider};
  background: ${(props) => props.theme.background.primary};
  display: flex;
  text-align: left;
  path {
    fill: ${(props) => props.theme.notification.warningMain};
  }
  svg {
    margin-right: 18px;
  }
  span {
    padding-left: 6px;
  }
`;

const Close = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  cursor: pointer;
`;

export default function InsightAlert(props) {
  const [show, setShow] = useState(false);
  const [insightKey, setInsightKey] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      const nextKey = insightKey === 0 ? 1 : 0;
      setInsightKey(nextKey);
    }, 4500);
    return () => clearInterval(interval);
  }, [insightKey]);

  if (!props.show) return null;

  const handleApply = (filters) => {
    dispatch(actions.setFilters(filters));
    // setShow(false);
  };

  if (!props.insights?.length) return null;

  return (
    <>
      {show ? (
        <InsightContainer>
          <div>
            <FontAwesomeIcon icon={["fal", "rocket"]} size="3x" />
          </div>
          <div style={{ maxWidth: 600 }}>
            {props.insights[insightKey].text}{" "}
            <ApplySpan
              onClick={() => handleApply(props.insights[insightKey].filters)}
            >
              Apply
            </ApplySpan>
          </div>
        </InsightContainer>
      ) : null}
      {!show ? (
        <SimpleTooltip label="You have a new Insight" position="bottom">
          <Container onClick={() => setShow((show) => !show)}>
            <FontAwesomeIcon icon={["fal", "rocket"]} />
          </Container>
        </SimpleTooltip>
      ) : null}
      {show ? (
        <Close onClick={() => setShow(false)}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </Close>
      ) : null}
    </>
  );
}

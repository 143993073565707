import { useCallback, useEffect } from "react";
import { getAggregationPrefix } from "../SettingsMenu/Layout/Column/RegularColumn";

export default function RawRowsHeaders({ chart, setChartState }) {
  const {
    subTitles,
    overrides,
    rawRows,
    staticGroupingKeys,
    groupingKey,
    dynamicSubTitleKeys,
    rowGroupKey,
  } = chart;

  const buildSubTitlesForOrder = useCallback(
    (overrides, chart) => {
      const subTitles = overrides.reduce((acc, curr) => {
        const aggregation = curr.ops?.aggregation || curr.aggregation;
        const prefix = getAggregationPrefix(aggregation) ?? "";

        acc.push(prefix + curr.name);
        return acc;
      }, []);

      setChartState({
        ...chart,
        subTitles: [subTitles],
        rawRows: !rowGroupKey,
      });
    },
    [rowGroupKey, setChartState]
  );

  // [["1"], ["2"], ["3"], ...] => [["1", "2","3", ...]] this need for DND
  const covertToSingleSubTitle = useCallback(
    (chart) => {
      setChartState({ ...chart, subTitles: [chart.subTitles.flat()] });
    },
    [setChartState]
  );

  useEffect(() => {
    if (dynamicSubTitleKeys) {
      return;
    }

    if (!subTitles && !staticGroupingKeys) {
      buildSubTitlesForOrder(overrides, chart);
    }

    const hasOneFieldinAll =
      subTitles?.length > 1 && subTitles.every((sub) => sub.length === 1);

    if (!staticGroupingKeys && hasOneFieldinAll && !groupingKey) {
      covertToSingleSubTitle(chart);
    }
  }, [
    chart,
    groupingKey,
    buildSubTitlesForOrder,
    covertToSingleSubTitle,
    overrides,
    rawRows,
    staticGroupingKeys,
    subTitles,
    dynamicSubTitleKeys,
  ]);

  return null;
}

import Modal from "../../../UI/Modal/Modal";
import ChartFactory from "../../ChartFactory/ChartFactory";

export default function DrilldownInModal({ onClose, chart }) {
  return (
    <Modal renderInPortal close={onClose} fullScreen showClose>
      <ChartFactory {...chart} />
    </Modal>
  );
}

import React from "react";
import HeroBlockLayout from "../HeroBlockLayout";
import LeftRightArea from "../../../../UI/BigInput/LeftRightArea";

export default function ProductPrettyNames(props) {
  const { sourceValues, ...rest } = props;
  const message = {
    title: "Names look like a computer write them?",
    text: `
        Product names are often set with codes or other names that are not
        very easy to decipher for us humans. Set these to the correct labels
        and stop the questions.
      `,
  };

  return (
    <HeroBlockLayout
      overrideMode
      message={message}
      initOverrides={props.values}
      {...rest}
    >
      <LeftRightArea data={sourceValues} />
    </HeroBlockLayout>
  );
}

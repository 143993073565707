import styled from "@emotion/styled";
import monster from "../../../../../images/monster.png";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  padding: 7px;
  align-items: center;
  background: ${theme.background.secondary};
  justify-content: space-between;
  border-radius: 20px;
  margin-top: 40px;
  height: 70px;
`
);

const Monster = styled.div`
  background: #322d46;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background-image: url(${monster});
  background-size: 65px;
  background-repeat: no-repeat;
`;

const Label = styled.span`
  width: calc(100% - 90px);
  font-weight: 500;
  font-size: 14px;
`;

export default function ColumnMonster() {
  return (
    <Container>
      <Monster />
      <Label>
        Select a column or setting to the left to continue configuration
      </Label>
    </Container>
  );
}

import { customFilterTypes } from "./constants/filterConstants";
import { orderBy } from "lodash-es";
import { sortIfDaysOfWeek } from "./constants/constants";

export function sortMenuFilters(values) {
  return sortByDate(values);
}

function sortByDate(values) {
  if (!values.length) {
    return values;
  }

  const type = customFilterTypes.find((d) => d.type === values[0].type);

  if (type) {
    const elements = values.map((x) => ({
      ...x,
      sortValue: type.fn(x.value),
    }));

    const sorted = orderBy(elements, "sortValue", type.direction);

    return sorted.map((x) => {
      delete x.sortValue;
      return x;
    });
  }

  return sortIfDaysOfWeek(values);
}

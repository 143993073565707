import React, { useState, useMemo, useRef } from "react";
import Select from "../../../UI/Form/Select/Select";
import { getSingleFiscalQuarters } from "../../../utils/formatters/dateFormatter";

export default React.memo(function QuarterRange({
  dateFiltersConfig,
  getPosition,
  RecentPeriods,
  quarterChange,
}) {
  const ref = useRef();
  const { defaultFiscalQuarter } = dateFiltersConfig;

  const options = useMemo(
    () => getSingleFiscalQuarters(dateFiltersConfig),
    [dateFiltersConfig]
  );

  const defaultStartEnd = options.find((o) => o.label === defaultFiscalQuarter);
  const [period, setPeriod] = useState({
    start: defaultStartEnd || options[0],
    end: defaultStartEnd || options[options.length - 1],
  });

  const start = period.start.from || options[0].from;
  const end = period.end.to || options[options.length - 1].to;

  return (
    <RecentPeriods ref={ref}>
      <Select
        options={options.filter((_, i) =>
          period.end ? i <= period.end.index : true
        )}
        onChange={(option) => {
          setPeriod((prevPeriod) => ({ ...prevPeriod, start: option }));
          quarterChange({ from: option.from, to: end });
        }}
        getOptionValue={(o) => o.from}
        placeholder="From"
        value={period.start}
        menuPlacement={getPosition(ref)}
      />
      <br />
      <Select
        options={options.filter((_, i) =>
          period.start ? i >= period.start.index : true
        )}
        onChange={(option) => {
          setPeriod((prevPeriod) => ({ ...prevPeriod, end: option }));
          quarterChange({ from: start, to: option.to });
        }}
        getOptionValue={(o) => o.from}
        placeholder="To"
        value={period.end}
        menuPlacement={getPosition(ref)}
      />
    </RecentPeriods>
  );
});

import React from "react";
import styled from "@emotion/styled";

const BottomScroll = styled.div`
  position: fixed;
  left: 60px;
  bottom: 38px;
  background: black;
  height: 10px;
  width: calc(100vw - 60px);
  display: ${(props) => (props.active ? "block" : "none")};
  padding: 4px 0;
`;

export default function BottomScrollSection(props) {
  return <BottomScroll active>{props.children}</BottomScroll>;
}

import { Switch } from "react-router-dom";
import Flex from "../../UI/Flex/Flex";
import LeftMenu from "./LeftMenu";
import ScheduledEmails from "./ScheduledEmails/ScheduledEmails";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ScheduleDetails from "./ScheduledEmails/ScheduleDetails";

export default function Automation() {
  return (
    <Flex>
      <LeftMenu />
      <Switch>
        <PrivateRoute
          exact
          path={"/admin/automation/scheduled-emails"}
          component={ScheduledEmails}
        />
        <PrivateRoute
          exact
          path={"/admin/automation/scheduled-emails/new"}
          component={ScheduleDetails}
        />
        <PrivateRoute
          exact
          path={"/admin/automation/scheduled-emails/:uuid"}
          component={ScheduleDetails}
        />
      </Switch>
    </Flex>
  );
}

import styled from "@emotion/styled";
import Table from "../../UI/Tables/Table/Table";
import { useMemo } from "react";
import { getTimeExecutionStandartColor } from "./ChartItem";

const Container = styled.div`
  display: flex;
  padding: 5px;
  margin-bottom: 20px;
  height: fit-content;
`;

const Td = styled.td`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default function SummaryByCharts({
  executedCharts,
  visualizations,
  theme,
  label = "Total Charts",
  staticQuantity,
  onFilterChange,
}) {
  const tableData = useMemo(
    () =>
      visualizations.reduce(
        (acc, curr) => {
          const chart = executedCharts[curr.visualizationId];
          const { type } = chart ?? {};
          type && acc[type]++;
          return acc;
        },
        { FAST: 0, OK: 0, SLOW: 0, FAILED: 0 }
      ),
    [visualizations, executedCharts]
  );

  return (
    <Container>
      <Table border>
        <tbody>
          <tr>
            <td>{label}</td>
            <td>{staticQuantity ?? visualizations.length}</td>
          </tr>
          {Object.keys(tableData).map((key) => (
            <tr
              key={key}
              style={{ color: getTimeExecutionStandartColor(key, theme) }}
            >
              <Td onClick={() => onFilterChange([key])}>{key}</Td>
              <td>{tableData[key]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

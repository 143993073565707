import React, { useState, useEffect } from "react";
import qs from "qs";
import Input from "../../UI/Form/Input/Input";
import IOButton from "../../UI/Form/Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import FormInputGroup from "./FormInputGroup";
import LoginFormContainer from "./LoginFormContainer";
import LoginFormErrors from "./LoginFormErrors";
import LoginElementContainer from "./LoginElementContainer";
import FieldErrors from "../../UI/Form/FieldErrors";
import { validateUserPassword } from "../../utils/validation";

const Message = styled.div`
  text-align: left;
  font-size: 16px;
  padding-top: 12px;
  line-height: 1.5em;
  display: flex;
  svg {
    margin-right: 18px;
  }
  path {
    fill: ${(props) => props.theme.primary};
  }
`;

export default function ResetPassword(props) {
  const {
    setFocus,
    loading,
    onSubmit,
    resetComplete,
    authErrors,
    isInviteActivate,
    error,
  } = props;
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [checked, setChecked] = useState(false);
  const [valid, setValid] = useState(null);
  const href = window.location.href;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const enterYourNewPasswordText = isInviteActivate
    ? "Create a secure password to access your account."
    : "Enter your new password";
  const successMessage = isInviteActivate
    ? "You have successfully activated your account. Log in with the password you just provided."
    : "Your password has been successfully reset. Log in with your new password.";

  useEffect(() => {
    const p = href.split("?")[1];
    const obj = qs.parse(p);
    const token = obj.token ?? obj.inviteCode;
    if (!token || !obj.email) {
      setValid(false);
    } else {
      setToken(token);
      setEmail(obj.email);
      setValid(true);
    }
    setChecked(true);
  }, [href]);

  const handleSubmit = (e) => {
    onSubmit(e, {
      password,
      password_confirmation: confirmPassword,
      token,
      email,
    });
  };

  const errors = error?.data;
  const localValidationMessage = validateUserPassword(
    password,
    confirmPassword
  );

  return checked && valid ? (
    <div>
      {resetComplete ? (
        <LoginElementContainer>
          <Message>{successMessage}</Message>
          <div style={{ textAlign: "left", marginTop: 24 }}>
            <Link
              style={{
                paddingTop: 36,
                paddingLeft: 12,
                textAlign: "left",
                cursor: "pointer",
              }}
              to="/login"
            >
              <FontAwesomeIcon icon={["fas", "arrow-left"]} />
              <span style={{ paddingLeft: 6 }}>Login</span>
            </Link>
          </div>
        </LoginElementContainer>
      ) : (
        <LoginFormContainer onLogin={handleSubmit}>
          <LoginElementContainer>
            <input type="hidden" id="token" value={token} />
            <input type="hidden" id="email" value={email} />

            {isInviteActivate && <h2>You're almost there!</h2>}

            <p>
              {password && localValidationMessage
                ? localValidationMessage
                : !password
                ? enterYourNewPasswordText
                : "Save your password"}
            </p>

            {authErrors && (
              <LoginFormErrors errors={authErrors} cy="error-login-page" />
            )}

            <FormInputGroup>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                data-cy="password-login"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
              <FieldErrors errors={errors?.password} />
            </FormInputGroup>

            <FormInputGroup>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                data-cy="confirm-password-login"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
            </FormInputGroup>

            <div style={{ marginTop: 8 }}>
              <IOButton
                type="submit"
                fit
                processing={loading}
                cy="submit-login"
                disabled={!password || localValidationMessage}
              >
                Save
              </IOButton>
            </div>

            <FieldErrors errors={errors?.inviteCode} />

            {!isInviteActivate && (
              <div style={{ textAlign: "left", marginTop: 24 }}>
                <Link
                  style={{
                    paddingTop: 36,
                    paddingLeft: 12,
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                  to="/login"
                >
                  <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                  <span style={{ paddingLeft: 6 }}>Login</span>
                </Link>
              </div>
            )}
          </LoginElementContainer>
        </LoginFormContainer>
      )}
    </div>
  ) : checked ? (
    <LoginElementContainer>
      <Message>
        {" "}
        <FontAwesomeIcon size="2x" icon={["fas", "exclamation-triangle"]} />
        This password reset is not valid.
      </Message>
      <Link to="/login">
        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
        <span style={{ paddingLeft: 6 }} />
        Back
      </Link>
    </LoginElementContainer>
  ) : (
    <div>Accessing...</div>
  );
}

import React from "react";
import Flex from "../../../../UI/Flex/Flex";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  font-size: 14px;
  border-right: 4px solid ${(props) => props.theme.notification.infoMain};
  padding: 12px 18px;
  margin-right: -22px;
  position: absolute;
  right: 0;
  top: -29px;
  width: 180px;
  background: ${(props) => props.theme.gray.gray900};
`;

const Gradient = styled.div`
  left: -160px;
  width: 160px;
  top: 0;
  height: 56px;
  background: linear-gradient(
    to left,
    ${(props) => props.theme.gray.gray900},
    transparent
  );
  position: absolute;
`;

const Title = styled.div`
  margin-top: -4px;
  margin-bottom: 4px;
`;

const Yes = styled.div`
  &:hover {
    color: white;
  }

  margin-right: 18px;

  svg {
    margin-right: 3px;

    path {
      fill: ${(props) => props.theme.notification.successMain};
    }
  }
`;

const Cancel = styled.div`
  &:hover {
    color: white;
  }

  svg {
    margin-right: 5px;

    path {
      fill: ${(props) => props.theme.notification.warningMain};
    }
  }
`;

export default function DeleteSettingOptions(props) {
  const { handleDelete, setConfirming } = props;
  return (
    <Container>
      <Gradient />
      <Title>You sure?</Title>
      <Flex>
        <Yes data-cy="remove-setting-confirm" onClick={handleDelete}>
          <FontAwesomeIcon icon={["fas", "check"]} /> Yes, Delete
        </Yes>
        <Cancel
          data-cy="remove-setting-cancel"
          onClick={() => setConfirming(false)}
        >
          <FontAwesomeIcon icon={["far", "ban"]} />
          Cancel
        </Cancel>
      </Flex>
    </Container>
  );
}

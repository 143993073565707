import React from "react";
import Spinner from "../../../UI/Spinner/Spinner";
import { useSelector } from "react-redux";
import TechnicalMessage from "../../TechnicalMessage/ThechnicalMessage";

function ExternalGoogleIdTokenPage() {
  const authErrors = useSelector((state) => state.auth.authErrors);

  if (authErrors) {
    const messages = authErrors[0]?.includes(
      "The provided Google ID Token is expired or invalid."
    )
      ? [authErrors[0]]
      : authErrors;
    messages.push("Please refresh the page.");

    return (
      <TechnicalMessage
        message={
          <div style={{ whiteSpace: "pre-wrap" }}>{messages.join("\n")}</div>
        }
      />
    );
  }
  return <Spinner />;
}

export default ExternalGoogleIdTokenPage;

import styled from "@emotion/styled";
import React from "react";
import Switcher from "../../../UI/Switcher/Switcher";
import LimitRowsInput from "./LimitRowsInput";
import SelectedFilter from "./SelectedFilter";

const Container = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
  span {
    font-size: 12px;
  }
`;

export default function StickyHeader({
  stickyHeaders,
  setSticky,
  sticky,
  loading,
  limit,
  displayRows,
  setLimit,
  rowsQuantity,
  selectedMenuFilter,
}) {
  if (loading) {
    return null;
  }

  return (
    <Container>
      {selectedMenuFilter && (
        <SelectedFilter selectedMenuFilter={selectedMenuFilter} />
      )}
      {displayRows && (
        <LimitRowsInput
          limit={limit}
          displayRows={displayRows}
          width={stickyHeaders ? 85 : 99}
          rowsQuantity={rowsQuantity}
          setLimit={setLimit}
        />
      )}
      {stickyHeaders && (
        <Switcher
          handleSwitchChange={() => setSticky(!sticky)}
          checked={sticky}
          rightLabel="Vertical scroll"
          containerStyle={{ marginBottom: 10 }}
        />
      )}
    </Container>
  );
}

import { useState } from "react";
import isEqual from "react-fast-compare";

export default (initial) => {
  const [state, setState] = useState(initial || null);

  function handleSetState(nextState) {
    const match = isEqual(state, nextState);
    const draftState = match ? null : nextState;
    setState(draftState);
  }
  return [state, handleSetState];
};

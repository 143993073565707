import React from "react";
import SpringGroupDriver from "./SpringGroupDriver";

export default function SpringGroup(props) {
  const { x, y, skipAnimation, children } = props;

  return skipAnimation ? (
    <g transform={`translate(${x} ${y})`}>{props.children}</g>
  ) : (
    <SpringGroupDriver x={x} y={y} children={children} />
  );
}

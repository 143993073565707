import React from "react";
import { useTransition, animated } from "react-spring";

export default function EnterMenu(props) {
  const { show, children } = props;
  const transitions = useTransition(show, {
    from: { left: -300, position: "fixed", top: 0, zIndex: 50000 },
    enter: { left: 0 },
    leave: { left: -300 },
  });

  return transitions(
    (styles, item) =>
      item && <animated.div style={styles}>{children}</animated.div>
  );
}

import { formulaOption, getExtraColumnOption } from "../helper";
import DynamicSubTitlesContainer from "./DynamicSubTitlesContainer";
import GroupingFormatContainer from "./GroupingFormatContainer";
import GroupingKeyContainer from "./GroupingKeyContainer";
import GroupingTypeRadioContainer from "./GroupingTypeRadioContainer";
import RowGroupKeyContainer from "./RowGroupKeyContainer";

export default function RepeatingGroupLogic({
  chart,
  setChartState,
  repeatingOptions,
  removeDynamicSubTitleColumn,
  removeAnyColumnType,
  isParameterized,
}) {
  const { groupingKey, groupingExtraColumns } = chart;

  if (!groupingKey) {
    return null;
  }

  const options = [formulaOption, ...repeatingOptions];
  const extraColumnOption = getExtraColumnOption(groupingExtraColumns);

  if (extraColumnOption) {
    options.push(extraColumnOption);
  }

  return (
    <>
      <GroupingKeyContainer
        repeatingOptions={repeatingOptions}
        isParameterized={isParameterized}
        chart={chart}
        setChartState={setChartState}
      />

      <GroupingFormatContainer chart={chart} setChartState={setChartState} />

      <RowGroupKeyContainer
        chart={chart}
        setChartState={setChartState}
        removeAnyColumnType={removeAnyColumnType}
        repeatingOptions={repeatingOptions}
      />

      <GroupingTypeRadioContainer chart={chart} setChartState={setChartState} />

      <DynamicSubTitlesContainer
        chart={chart}
        options={options}
        removeDynamicSubTitleColumn={removeDynamicSubTitleColumn}
        setChartState={setChartState}
        repeatingOptions={repeatingOptions}
        isParameterized={isParameterized}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import FilterItem from "../FilterItem";
import Switcher from "../../../UI/Switcher/Switcher";

export default function DeluxeBoolean(props) {
  const {
    checked,
    value = true,
    onChange,
    onLabelOverride,
    offLabelOverride,
  } = props;
  const [firstTime, setFirstTime] = useState(true);

  const handleValueChange = () => {
    onChange({ checked: true, value: +!value });
  };

  useEffect(() => {
    if (firstTime) {
      const filter = checked
        ? { checked: false }
        : { checked: true, value: +value };

      onChange(filter);
      setFirstTime(false);
    }
  }, [checked, firstTime, onChange, value]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ position: "relative", top: 4, marginRight: -16 }}>
        <Switcher
          cy="boolean-filter-toggle"
          leftLabel={
            value
              ? `${onLabelOverride || "Value: Yes"}`
              : `${offLabelOverride || "Value: No"}`
          }
          checked={value}
          handleSwitchChange={handleValueChange}
          inContent={false}
        />
      </div>
    </div>
  );
}

DeluxeBoolean.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

import React, { lazy, Suspense, useState, useCallback } from "react";
import styled from "@emotion/styled";
import LeftMenuContainer from "./LeftMenuContainer";
import TopMenu from "./TopMenu";
import { Redirect, Switch, useLocation } from "react-router";
import PrivateRoute from "../Pages/PrivateRoute/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { hideSectionForUrls } from "../utils/constants/constants";
import { usePathName } from "../hooks/usePathName";
import Loading from "../UI/Loading/Loading";
import WebsocketTestPage from "../Pages/WebsocketTest/WebsocketTestPage";
import DashboardMessage from "../Pages/DashboardMessage/DashboardMessage";
import AlertMessage from "../UI/Alerts/AlertMessage";
import useSubscribeToImportantNotifications from "../hooks/useSubscribeToImportantNotifications";
import { updateImportantNotificationsBySocket } from "../store/actions/userNotifications";
import { useRenderIfTtgUser } from "../UI/TtgFeature";
import NewTenant from "../Pages/TenantSetup/NewTenant";

const Container = styled.div(
  ({ theme, isMenuOpen }) => `
    background-color: ${theme.background ? theme.background.primary : "white"};
    color: ${theme.text ? theme.text.secondary : "black"};
    display: flex;
    width: 100%;
    position: ${isMenuOpen ? "fixed" : "relative"};
`
);

const Content = styled.div`
  margin-top: 6px;
  min-height: calc(100vh - 97px);
  padding-bottom: 24px;
`;

// removing overflow in position sticky 270px is width of left side bar with p & m
const MainArea = styled.div(
  ({ menuVisible, isSticky }) => `
  width: ${isSticky ? "auto" : `calc(100vw - ${menuVisible ? 270 : 0}px)`};
  margin-left: ${menuVisible ? 0 : -270}px;
  @media (max-width: 800px) {
    width: 100%;
  }
`
);

const MainContentArea = styled.div`
  padding-top: 12px;
  padding-left: 30px;
  @media (max-width: 800px) {
    padding-left: 0;
    margin-right: 0;
  }
  transition: 0.3s ease margin-left;
`;

// This loads ChartFactory which loads a lot of charts.
const HeaderContent = lazy(() => import("./HeaderContent/HeaderContent"));
const FullDetails = lazy(() => import("./FullDetails"));
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const UserProfile = lazy(() => import("../Pages/UserProfile/UserProfile"));
const DataDictionary = lazy(() =>
  import("../Pages/DataDictionary/DataDictionaryPage")
);

export default function Layout({ pathname }) {
  const { importantNotifications, isGlobalSticky } = useSelector(
    (state) => state.layout
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(true);
  const path = usePathName();
  const location = useLocation();
  const hidden = hideSectionForUrls.some((url) => url === location.pathname);

  const dispatch = useDispatch();

  useSubscribeToImportantNotifications(
    useCallback(
      (importantNotifications) => {
        dispatch(updateImportantNotificationsBySocket(importantNotifications));
      },
      [dispatch]
    )
  );

  return (
    <Container isMenuOpen={isMenuOpen}>
      <LeftMenuContainer
        show={isMenuOpen}
        setShow={() => setIsMenuOpen(false)}
        hidden={hidden}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
      />
      <MainArea menuVisible={menuVisible} isSticky={isGlobalSticky}>
        <Suspense fallback={<Loading />}>
          {!hidden && <HeaderContent />}
        </Suspense>
        <MainContentArea setMenuVisible={setMenuVisible}>
          {importantNotifications?.length
            ? importantNotifications.map(
                (notification) =>
                  notification.active && (
                    <AlertMessage
                      status="warning"
                      message={notification.message}
                      key={notification.uuid}
                      cy="layout-alert-message"
                    />
                  )
              )
            : null}
          <TopMenu show={() => setIsMenuOpen(true)} hasLink={path} />

          <Content>
            <Suspense fallback={<Loading />}>
              <Switch>
                <PrivateRoute
                  path={"/dashboard/:dashboardId/details/:chartId"}
                  exact
                  component={FullDetails}
                />
                <PrivateRoute
                  path={"/dashboard/:dashboardId"}
                  menuVisible={menuVisible}
                  exact
                  component={Dashboard}
                />
                <PrivateRoute path={"/setup"} exact component={NewTenant} />
                <PrivateRoute
                  path={"/user-profile"}
                  exact
                  component={UserProfile}
                />
                <PrivateRoute
                  path={"/websocket-test"}
                  exact
                  component={WebsocketTestPage}
                />
                {useRenderIfTtgUser(() => (
                  <PrivateRoute
                    path={"/dashboard-message"}
                    exact
                    component={DashboardMessage}
                  />
                ))}
                <Redirect
                  from={"/upload-profile-image"}
                  to={"/user-profile"}
                  exact
                />
                <PrivateRoute
                  path={"/data-dictionary"}
                  component={DataDictionary}
                />
                <Redirect to={pathname} />
              </Switch>
            </Suspense>
          </Content>
        </MainContentArea>
      </MainArea>
    </Container>
  );
}

import styled from "@emotion/styled";
import Flex from "../../UI/Flex/Flex";
import IOButton from "../../UI/Form/Button/IOButton";
import Input from "../../UI/Form/Input/Input";
import { ALARMS, NEW_ROWS } from "./utils";

const ToolbarActions = styled.div`
  display: flex;
  & > input,
  & > button {
    margin-right: 15px;
    font-size: 12px;
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export default function Toolbar(props) {
  const { searchText, setSearchText, setVisibleModalComponent, anchorText } =
    props;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Title>Watchlist</Title>
      <ToolbarActions>
        <Input
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <IOButton
          rounded
          smallPadding
          icon={["fas", "alarm-clock"]}
          gray100
          type="button"
          onClick={() => setVisibleModalComponent(ALARMS)}
          disabled
        >
          Alarm Setting
        </IOButton>
        <IOButton
          rounded
          smallPadding
          onClick={() => setVisibleModalComponent(NEW_ROWS)}
          type="button"
        >
          Add New {anchorText}
        </IOButton>
      </ToolbarActions>
    </Flex>
  );
}

import React, { useContext, useEffect } from "react";
import Loading from "../../../../UI/Loading/Loading";
import SelectedQueryContext from "../SelectedQuery/SelectedQueryContext";
import { handleError } from "../../../../utils/errorHandling";
import { useDispatch } from "react-redux";
import Flex from "../../../../UI/Flex/Flex";

export default function InitExplorerTable(props) {
  const {
    activeQuery,
    activeQueryError,
    params,
    filterApi,
    lastSettings,
    activeSettings,
    queryExecSettingsError,
    explorerMode,
  } = props;
  const { selectedQuery } = useContext(SelectedQueryContext);
  const { queryId, settingsUuid } = params;
  const { createFromQuery, loadFromSettings } = filterApi;
  const dispatch = useDispatch();

  useEffect(() => {
    // Switch mode
    if (firstRun()) handleFirstRun();
    else if (switchToQueryFromSettings()) initQuery();
    else if (switchToSettingsMode()) initReport();
    else if (changedQuery()) initQuery();
    else if (changedReport()) initReport();

    async function initReport() {
      try {
        await loadFromSettings(params.settingsUuid);
      } catch (e) {
        handleError(dispatch, e);
      }
    }

    async function initQuery() {
      try {
        await createFromQuery(params.queryId);
      } catch (e) {
        handleError(dispatch, e);
      }
    }

    function changedQuery() {
      return (
        queryId &&
        (activeQuery?.uuid || activeQueryError) &&
        activeQuery?.uuid !== queryId
      );
    }

    function handleFirstRun() {
      params.queryId ? initQuery() : initReport();
    }

    function changedReport() {
      return settingsUuid && settingsUuid !== activeSettings?.uuid;
    }

    function firstRun() {
      return !explorerMode;
    }

    function switchToQueryFromSettings() {
      return params.queryId && explorerMode === "report";
    }

    function switchToSettingsMode() {
      return params.settingsUuid && explorerMode === "query";
    }
    /* eslint-disable */
  }, [queryId, settingsUuid, explorerMode]);

  if (activeQueryError || queryExecSettingsError) {
    return;
  }

  if (
    (!activeQuery || !selectedQuery || activeQuery.uuid !== selectedQuery) &&
    !lastSettings
  ) {
    return (
      <Flex>
        <Loading />
      </Flex>
    );
  } else {
    return props.children;
  }
}

InitExplorerTable.defaultProps = {
  params: {},
};

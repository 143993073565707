import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const FlexContainer = styled.div(
  ({
    direction,
    gap,
    alignItems,
    justifyContent,
    wrap,
    alignSelf,
    mb2,
    mt2,
    pl2,
    height,
    position,
    center,
    flexGrow,
    width,
  }) => `
  display: flex;
  ${direction ? `flex-direction: ${direction}` : ""};
  ${gap ? `gap: ${formatSize(gap)}` : ""};
  ${!center && alignItems ? `align-items: ${alignItems}` : ""};
  ${!center && justifyContent ? `justify-content: ${justifyContent}` : ""};
  ${wrap ? `flex-wrap ${wrap}` : ""};
  ${alignSelf ? `align-self: ${alignSelf}` : ""};
  ${mb2 ? `margin-bottom: .5rem;` : ""};
  ${mt2 ? `margin-top: .5rem;` : ""};
  ${pl2 ? `padding-left: .5rem;` : ""};
  ${height && `height: ${formatSize(height)}`};
  ${position && `position: ${position}`};
  ${center && `align-items: center; justify-content: center;`};
  ${flexGrow && `flex-grow: ${flexGrow}`};
  ${width && `width: ${width}`};
`
);

function formatSize(size) {
  return `${size}${size && typeof size === "number" ? "px" : ""}`;
}

export default function Flex(props) {
  return (
    <FlexContainer {...props} data-cy={props["data-cy"] || props.cy}>
      {props.children}
    </FlexContainer>
  );
}

const align = ["baseline", "stretch", "start", "center", "end"];

Flex.propTypes = {
  direction: PropTypes.oneOf([
    "row",
    "column",
    "row-reverse",
    "column-reverse",
  ]),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignItems: PropTypes.oneOf(align),
  justifyContent: PropTypes.oneOf([
    "center",
    "space-between",
    "flex-start",
    "flex-end",
    "space-around",
  ]),
  alignSelf: PropTypes.oneOf(align),
  wrap: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string,
  center: PropTypes.bool,
  flexGrow: PropTypes.number,
  cy: PropTypes.string,
};

import { useEffect } from "react";

export default (ref, handler, ignore = null) => {
  useEffect(() => {
    const listener = (event) => {
      const ignoreBlockClicked =
        ignore !== null &&
        ignore.current !== null &&
        ignore?.current &&
        ignore.current.contains(event.target);
      const currentBlockClicked =
        ref.current !== null && ref.current?.contains(event.target);
      if (!ref.current || currentBlockClicked || ignoreBlockClicked) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler, ignore]);
};

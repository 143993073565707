import React from "react";
import styled from "@emotion/styled";
import SimpleTooltip from "../../../../UI/SimpleTooltip/SimpleTooltip";
import TableGlobalActionItem from "./TableGlobalActionItem";
import { css } from "../../../../utils/ide";
// import InsightsLink from "./InsightsLink";
// import StartChartButton from "../../../../Editors/ChartBuilder/StartChartButton";
// import TtgFeature from "../../../../UI/TtgFeature";
import useUserAccess from "../../../../hooks/useUserAccess";
import Flex from "../../../../UI/Flex/Flex";

const GlobalActionsWrapper = styled.div(
  (props) => css`
    font-size: 14px;
    color: ${props.theme.text.secondary};
    text-align: left;
    position: sticky;
    top: 50px;
    z-index: 1;
    background: ${props.theme.background.admin};

    a:hover,
    .change-color-on-hover:hover {
      color: ${props.theme.notification.infoMain};
      path {
        fill: ${props.theme.notification.infoMain};
      }
    }
  `
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  background: ${(props) => props.theme.background.admin};
`;

const Span = styled.span`
  cursor: pointer;
`;

// const ChartIconContainer = styled.div`
//   position: relative;
//   margin-left: 10px;
//   top: -7px;
//   display: flex;
//   align-items: center;
// `;

export default function TableGlobalActions(props) {
  const {
    setColumnsVisibility,
    show,
    filterApi,
    runQuery,
    exportExcel,
    setShowPanel,
  } = props;

  const access = useUserAccess();

  if (!show) return null;

  const hasDataSourceWriteAccess = access.dataSources;

  return (
    <GlobalActionsWrapper data-cy="explorer-global-actions-wrapper">
      <Container>
        <div style={{ display: "flex" }}>
          <SimpleTooltip label="Hide some fields, increase your focus">
            <Span
              className="change-color-on-hover"
              onClick={() => setColumnsVisibility(true)}
            >
              Show / Hide Columns
            </Span>
          </SimpleTooltip>

          {/*<InsightsLink />*/}
        </div>

        <Flex gap="1.5rem">
          <TableGlobalActionItem
            onClick={() => setShowPanel("list")}
            icon={["fal", "folder"]}
            tooltip="Your Settings List"
            cy="show-saved-settings"
            hidden={!hasDataSourceWriteAccess}
          />
          <TableGlobalActionItem
            onClick={() => setShowPanel("save")}
            icon={["far", "save"]}
            tooltip="Save Settings"
            cy="show-save-query"
            hidden={!hasDataSourceWriteAccess}
          />
          <TableGlobalActionItem
            onClick={exportExcel}
            icon={["far", "file-excel"]}
            tooltip="Export Excel"
          />
          <TableGlobalActionItem
            onClick={runQuery}
            icon={["far", "sync-alt"]}
            cy="run-query"
            tooltip="Refresh"
          />
          {/*<TtgFeature>*/}
          {/*  {({ treeIcon }) => (*/}
          {/*    <ChartIconContainer>*/}
          {/*      <StartChartButton*/}
          {/*        handleClick={() =>*/}
          {/*          history.push({*/}
          {/*            pathname: "/admin/data-explorer/chart-builder",*/}
          {/*            state: {*/}
          {/*              settings: filterApi.effectiveSettings,*/}
          {/*              selectedQuery: filterApi.selectedQuery,*/}
          {/*            },*/}
          {/*          })*/}
          {/*        }*/}
          {/*      />*/}
          {/*      {treeIcon}*/}
          {/*    </ChartIconContainer>*/}
          {/*  )}*/}
          {/*</TtgFeature>*/}
        </Flex>
      </Container>
    </GlobalActionsWrapper>
  );
}

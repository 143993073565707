import React from "react";
import "../../App.css";
export default function Spinner(props) {
  return (
    <div className="spinner" {...props}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect, Switch } from "react-router";
import { Route } from "../../utils/router";
import { usePathName } from "../../hooks/usePathName";
import LoginContainer from "./LoginContainer";
import LoginFormContainer from "./LoginFormContainer";
import LoginForm from "./LoginForm";
import EnterLeft from "./EnterLeft";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPassword from "./ResetPassword";
import TwoFactorForm from "./TwoFactorForm";
import TwoFactorNudger from "./TwoFactorNudger";
import { activateInvite } from "../../store/actions/auth";

export default (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isFocused, setFocus] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [twoFactorForm, setTwoFactorForm] = useState(false);
  const authState = useSelector((state) => state.auth);
  const {
    isAuthenticated,
    authErrors,
    loading,
    forgotComplete,
    resetComplete,
    error,
  } = authState;
  const layoutState = useSelector((state) => state.layout);
  const twoFactorAuthRequired = layoutState.twoFactorAuthRequired;
  const { tenantId, logo, loginBackground } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const path = usePathName();
  const redirectTo = props.location.state?.from;
  const dashboardToLoad = props.location.state?.dashboard;

  const currentPath = props.location.pathname;

  const showStandardLoginForm = useMemo(
    () => !forgot && !twoFactorForm,
    [forgot, twoFactorForm]
  );
  const showTwoFactorForm = useMemo(
    () => !forgot && twoFactorForm,
    [forgot, twoFactorForm]
  );

  useEffect(() => {
    dispatch(actions.clearAuthErrors());
  }, [dispatch, forgot, currentPath]);

  const onSubmit = (e, payload) => {
    e.preventDefault();
    if (currentPath.includes("reset")) {
      return dispatch(actions.resetPassword(payload));
    }
    if (currentPath.includes("activate")) {
      return dispatch(activateInvite(payload));
    }

    if (forgot) {
      dispatch(actions.forgotPassword(email));
    } else if (twoFactorForm) {
      dispatch(actions.performTwoFactorAuthChallenge(twoFactorCode));
    } else {
      dispatch(actions.login(email, password, dashboardToLoad, tenantId));
    }
  };

  useEffect(() => {
    if (authState && authState.twoFactor) {
      // When the user logs in, and 2FA is enabled, show the 2FA form.
      setTwoFactorForm(true);
    }
  }, [authState]);

  const formProps = {
    email,
    setEmail,
    password,
    setPassword,
    setFocus,
    authErrors,
    error,
    loading,
    setForgot,
  };

  const resetPasswordProps = {
    setFocus,
    loading,
    onSubmit,
    resetComplete,
    authErrors,
    error,
  };

  return (
    <LoginContainer
      isFocused={isFocused}
      background={loginBackground}
      logo={logo}
    >
      {!isAuthenticated ? (
        <Switch>
          <Route path="/login/reset/">
            <ResetPassword {...resetPasswordProps} />
          </Route>
          <Route path="/login/activate">
            <ResetPassword
              {...resetPasswordProps}
              error={error}
              isInviteActivate
            />
          </Route>
          <Route path="/login" exact>
            <LoginFormContainer onLogin={onSubmit}>
              {/*@todo refactor out LoginElementContainer stuff to inside elements*/}
              <EnterLeft show={showStandardLoginForm}>
                <LoginForm {...formProps} />
              </EnterLeft>

              <EnterLeft show={showTwoFactorForm}>
                <TwoFactorForm
                  {...formProps}
                  goBack={() => setTwoFactorForm(false)}
                  code={twoFactorCode}
                  setCode={setTwoFactorCode}
                />
              </EnterLeft>

              <EnterLeft show={forgot}>
                <ForgotPasswordForm
                  {...formProps}
                  forgotComplete={forgotComplete}
                />
              </EnterLeft>
            </LoginFormContainer>
          </Route>
        </Switch>
      ) : twoFactorAuthRequired && !authState.twoFactor ? (
        // User is authenticated, 2FA enabled, but 2FA not set up for the user... so nag him about 2FA.
        <TwoFactorNudger />
      ) : (
        // Otherwise you are simply already logged in, so redirect away from the login page.
        <Redirect to={redirectTo || path} />
      )}
    </LoginContainer>
  );
};

import { saveAs } from "file-saver";
import html2canvas from "@lzhoucs/html2canvas";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
// import watermark from '../styles/watermark.png';

// const ratio = .537; // For watermark
const margin = 36;
const oldPosition = "absolute";
const newPosition = "relative";

const changeColumnPosition = (elements) => {
  const indexes = [];

  elements.forEach((td, index) => {
    if (window.getComputedStyle(td).position === oldPosition) {
      td.style.position = newPosition;
      indexes.push(index);
    }
  });

  return indexes;
};

const revertColumnPosition = (elements, indexes) => {
  elements.forEach((td, index) => {
    if (indexes.includes(index)) {
      td.style.position = oldPosition;
    }
  });
};

const fixTableElementsPosition = (wrapper, tagName) => {
  const elements = Array.from(wrapper.getElementsByTagName(tagName));

  return {
    elements,
    indexes: changeColumnPosition(elements),
  };
};

export const takeFullPageScreenshot = async (
  theme,
  siteName,
  pageName,
  setScreenshotMode
) => {
  const el = document.getElementById("wrapper");

  if (!el) return;

  const textElements = el.getElementsByTagName("text");
  const details = Array.from(document.getElementsByClassName("details"));

  el.style = "background:" + theme.background.primary;
  window.scrollTo(0, 0);

  setTextStyles(textElements, theme);
  details.forEach((detail) => (detail.style.display = "none"));

  const { elements: ths, indexes: thIndexes } = fixTableElementsPosition(
    el,
    "th"
  );
  const { elements: tds, indexes: tdIndexes } = fixTableElementsPosition(
    el,
    "td"
  );

  await html2canvas(el).then(function (canvas) {
    const data = canvas.toDataURL("image/png");
    save(data, siteName, pageName);

    revertColumnPosition(ths, thIndexes);
    revertColumnPosition(tds, tdIndexes);

    details.forEach((detail) => (detail.style.display = "flex"));
  });

  if (setScreenshotMode) {
    setScreenshotMode(false);
  }
};

export default async (id, theme) => {
  const el = document.getElementById(id);

  setTextStyles(el.getElementsByTagName("text"), theme);
  window.scrollTo(0, 0);
  el.style = "background:" + theme.background.primary;

  const svg = el.getAttribute("offset-height")
    ? el.querySelector('[data-attr="svg"]')
    : null;
  if (svg)
    svg.style.height = mobileBreakpoints.isMobile
      ? svg.clientHeight - 40
      : svg.clientHeight - 20;

  html2canvas(el).then((canvas) => {
    const width = canvas.getAttribute("width");
    const height = canvas.getAttribute("height");

    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, +width, +height);
    ctx.clearRect(0, 0, +width, +height);
    canvas.width = +width + margin * 2;
    canvas.height = +height + margin * 2;
    ctx.fillStyle = "white";

    // Move over margin distance
    ctx.fillRect(0, 0, +width + margin * 2, +height + margin * 2);
    ctx.putImageData(imageData, margin, margin);

    // Add watermark as it makes sense
    // const watermarkWidth = width / 2;
    // const waterMarkHeight = watermarkWidth * ratio;
    // const waterMarkLeftMargin = (width / 2) - (watermarkWidth *.3);
    // const waterMarkTopMargin = (height / 2) - (waterMarkHeight * .4);
    // const fontSize = height / 44;
    //
    // ctx.fillStyle = '#1A4379';
    // ctx.font = `${fontSize}px Helvetica`;
    // ctx.textAlign = 'end';
    // ctx.textBaseline = 'bottom';
    // ctx.fillText('SOURCE: tbri.com', width - 2, height - 2);
    //
    // const img = new Image();
    // img.onload = function() {
    //     ctx.globalAlpha = 0.10;
    //     ctx.drawImage(img, waterMarkLeftMargin, waterMarkTopMargin, watermarkWidth, waterMarkHeight);
    // };
    //
    // img.src = watermark;

    const data = canvas.toDataURL("image/png");
    save(data);
    if (svg)
      svg.style.height = mobileBreakpoints.isMobile
        ? svg.clientHeight + 40
        : svg.clientHeight + 20;
    // el.scrollIntoView(true);
  });
};

function save(dataBlob, siteName, pageName) {
  const name =
    !siteName || !pageName
      ? "insight_out_visualization.png"
      : `${siteName} - ${pageName}.png`;

  saveAs(dataBlob, name); // FileSaver.js function
}

function setTextStyles(text, theme) {
  for (let i = 0; i < text.length; i++) {
    text[i].style.fontFamily = 'Roboto, "Helvetica Neue", sans-serif';
    text[i].style.fill = theme.text.secondary;
  }
}

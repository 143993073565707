export const ARROW_WIDTH = 6;
export const MARGIN_FROM_BORDER_SCREEN = 16;

export function getPositions(elementClientRect, bubbleWidth) {
  if (elementClientRect.left - bubbleWidth / 2 < 0) {
    return {
      bubble: `left: ${MARGIN_FROM_BORDER_SCREEN}px;`,
      arrow: `left: ${
        elementClientRect.left +
        elementClientRect.width / 2 -
        ARROW_WIDTH -
        MARGIN_FROM_BORDER_SCREEN
      }px;`,
    };
  }

  const widthWithoutScroll = document.body.clientWidth;

  if (
    elementClientRect.right - elementClientRect.width / 2 + bubbleWidth / 2 >
    widthWithoutScroll
  ) {
    return {
      bubble: `right: ${MARGIN_FROM_BORDER_SCREEN}px;`,
      arrow: `right: ${
        widthWithoutScroll -
        elementClientRect.right +
        elementClientRect.width / 2 -
        ARROW_WIDTH -
        MARGIN_FROM_BORDER_SCREEN
      }px;`,
    };
  }

  return {
    bubble: `left: ${
      elementClientRect.left + elementClientRect.width / 2 - bubbleWidth / 2
    }px;`,
    arrow: `left: ${bubbleWidth / 2 - ARROW_WIDTH}px`,
  };
}

import React, { useEffect } from "react";
import formatter from "../../../../utils/formatters/formatter";
import ExplorerLeftTray from "../DataExplorerUI/ExplorerLeftTray";
import SaveExplorerForm from "./SaveExplorerForm";
import usePrevious from "../../../../utils/usePrevious";
import EnterTransition from "../DataExplorerUI/EnterTransition";
import styled from "@emotion/styled";

const Updated = styled.div`
  font-size: 12px;
  opacity: 0.5;
  margin-top: 36px;
`;

export default function ExplorerSave(props) {
  const {
    showSaveForm,
    setShowPanel,
    handleSave,
    execSettingsForm,
    name,
    settings,
  } = props;

  const prevSave = usePrevious(execSettingsForm);

  useEffect(() => {
    if (
      prevSave?.loading &&
      !execSettingsForm?.loading &&
      !execSettingsForm?.error
    ) {
      setShowPanel(null);
    }
  }, [prevSave, execSettingsForm, setShowPanel]);

  return (
    <EnterTransition show={showSaveForm}>
      <ExplorerLeftTray
        title="Save Settings"
        cy="save-settings-form"
        handleClose={() => setShowPanel(null)}
      >
        <SaveExplorerForm
          handleSave={handleSave}
          name={name}
          settings={settings}
          loading={execSettingsForm?.loading}
        />

        {execSettingsForm?.error ? (
          <div data-cy="explorer-settings-save-error" style={{ marginTop: 24 }}>
            There was a problem saving your report. Please contact Customer
            Support.
          </div>
        ) : null}

        {settings?.updatedAt ? (
          <Updated>
            Last updated: {formatter(settings.updatedAt, "date")}
          </Updated>
        ) : null}
      </ExplorerLeftTray>
    </EnterTransition>
  );
}

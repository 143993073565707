import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tipper from "../../../UI/Tipper/Tipper";
import ExternalTag from "./ExternalTag";

export default function (draftValue, subKey) {
  if (!draftValue) {
    return {
      value: null,
      formatted: (
        <div
          style={{
            fontSize: 60,
            position: "relative",
            padding: "0 70px",
          }}
        >
          <FontAwesomeIcon icon={["fas", "image"]} style={{ opacity: 0.15 }} />
          <div
            style={{
              position: "absolute",
              left: 90,
              top: 18,
              fontSize: 24,
            }}
          >
            <FontAwesomeIcon icon={["fas", "question"]} />
          </div>
        </div>
      ),
    };
  }
  const isVideo = draftValue.includes(".mp4");
  const isAudio = draftValue.includes(".mp3");
  const isImage = draftValue.match(/.jpg|.jpeg|.png|.gif/);
  const isHtml = draftValue.includes(".html");
  const Image = <ImageWithTipper alt={subKey} src={draftValue} />;

  const Video = (
    <video controls width={200} key={draftValue}>
      <source src={draftValue} type="video/mp4" />
    </video>
  );

  const Audio = (
    <div style={{ padding: "8px 0" }}>
      <audio controls src={draftValue} style={{ width: 200 }} />
    </div>
  );

  const External = <ExternalTag draftValue={draftValue} />;

  return {
    value: draftValue,
    formatted: isVideo ? (
      Video
    ) : isAudio ? (
      Audio
    ) : isImage ? (
      Image
    ) : isHtml ? (
      <div>{External}</div>
    ) : (
      <div style={{ width: 250 }}>
        <ExternalTag draftValue={draftValue} />
      </div>
    ),
    align: null,
  };
}

export function ImageWithTipper({
  alt,
  src,
  width = 200,
  tipperSrc = src,
  tipperLabel,
  contain,
  ...props
}) {
  return (
    <Tipper
      noBg
      usePortal
      label={
        tipperLabel ?? (
          <img
            height="auto"
            width={contain ? 200 : 420}
            alt={alt}
            src={tipperSrc}
          />
        )
      }
    >
      {contain ? (
        <div
          style={{
            width: "80px",
            height: "40px",
            backgroundImage: `url(${src})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            // margin: "10px 0",
          }}
          alt={alt}
          {...props}
        />
      ) : (
        <img
          height="auto"
          width={width}
          style={{ margin: "10px 0" }}
          alt={alt}
          src={src}
          {...props}
        />
      )}
    </Tipper>
  );
}

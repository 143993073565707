let timer;
export default function () {
  function debounce(func, timeout = 500) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, timeout);
  }

  return { debounce };
}

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  position: fixed;
  left: 68px;
  top: 0;
  width: calc(100% - 70px);
  height: calc(100vh);
  background: ${(props) => props.theme.background.primary};
  z-index: 10000;
  padding: 12px 48px;
  box-sizing: border-box;
`;

const Container = styled.div`
  color: ${(props) => props.theme.text.secondary};
  position: relative;
  box-sizing: border-box;
  text-align: left;
  h1 {
    font-size: 20px;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;
  svg {
    margin-right: 6px;
  }
`;

function upOne(location) {
  return location.pathname.replace("/insights", "");
}

export default function InsightStage(props) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <Wrapper>
      <Container>
        <Close>
          <Link to={upOne}>
            <FontAwesomeIcon icon={["fal", "times-circle"]} />
            close
          </Link>
        </Close>
        {props.children}
      </Container>
    </Wrapper>
  );
}

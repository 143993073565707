import React, { useEffect, useState } from "react";
import { Switch } from "react-router";
import PrivateRoute from "../../PrivateRoute/PrivateRoute";
import BlueprintMapHome from "./BlueprintMapHome";
import CreateBlueprint from "./CreateBlueprint";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import Loading from "../../../UI/Loading/Loading";
import Shelf from "../../../UI/Shelf/Shelf";
import BluePrintSettingsHome from "./BluePrintSettingsHome";
import BlueprintOutputPreview from "./Preview/BlueprintOutputPreview";

export default function BlueprintHome(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("Warming up");
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [error, setError] = useState(null);
  // load individual values per table
  const { defaultDb, defaultConnection /* etlBlueprints*/ } = useSelector(
    (state) => state.dataSetup
  );

  useEffect(() => {
    dispatch(actions.initBlueprint(cb, errorCb));
    function cb(value, state) {
      setLoading(value);
      setShowLoadingIndicator(state);
    }
    function errorCb(value) {
      setError(value);
    }
  }, [dispatch]);

  function createBlueprint(name, table, field) {
    dispatch(
      actions.createBlueprint(
        name,
        table,
        field,
        defaultConnection,
        defaultDb,
        setLoading
      )
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  // If there's no blueprint, where do we go?
  return (
    <div>
      <div>
        <Switch>
          <PrivateRoute
            path={"/admin/data-blueprint"}
            exact
            component={BlueprintMapHome}
            setLoading={setLoading}
            createBlueprint={createBlueprint}
          />

          <PrivateRoute
            path="/admin/data-blueprint/create"
            exact
            component={CreateBlueprint}
            createBlueprint={createBlueprint}
          />

          <PrivateRoute
            path={"/admin/data-blueprint/:blueprintGroupId"}
            exact
            component={BluePrintSettingsHome}
          />

          <PrivateRoute
            path={
              "/admin/data-blueprint/:chainUuid/preview/:blueprintGroupIndex"
            }
            component={BlueprintOutputPreview}
          />
        </Switch>
      </div>
      {/* {(etlBlueprints ?? []).map((blue) => (
        <div
          key={blue.uuid}
          onClick={() => dispatch(actions.deleteBlueprint(blue.uuid))}
        >
          {blue.name}
        </div>
      ))} */}

      {loading ? (
        <Shelf>
          <div data-cy="shelf-loading">
            {showLoadingIndicator && <Loading top={2} />}
            {loading}
          </div>
        </Shelf>
      ) : null}
    </div>
  );
}

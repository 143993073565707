import produce from "immer";
import * as actions from "../actions/actionTypes";
import { orderBy, keyBy, mapValues } from "lodash-es";
import { filterTypes, images } from "../../utils/constants/constants";
import {
  convertDateFiltersToLocalDraft,
  migrateDateFilters,
} from "../../utils/siteConfiguration/dateFilters";
import { customFilterTypes } from "../../utils/constants/filterConstants";

function extractFromObject(item) {
  if (item) {
    return typeof item === "object" ? item.value : item;
  }

  return item;
}

export const setCustomDefaultSelected = (filter) => {
  const { type, fn, direction, defaultSelected } =
    customFilterTypes.find((x) => x.type === filter.name) || {};

  if (type) {
    const withSortValue = filter.values.map((value) => ({
      value: extractFromObject(value),
      sortValue: fn(extractFromObject(value)),
    }));

    const values = orderBy(withSortValue, "sortValue", direction);

    const defaultValues = values
      .filter((x) => defaultSelected.includes(x.value))
      .map(extractFromObject);

    const newFilter = {
      ...filter,
      values: values.map(extractFromObject),
      defaultValues,
    };

    return newFilter;
  }

  return {
    ...filter,
    values: filter.values.map(extractFromObject),
  };
};

const image = {
  imageName: "",
  preview: null,
  file: null,
};

const initialState = {
  siteName: "",
  darkThemeEnabled: false,
  background: image,
  logo: image,
  favicon: image,
  customPrimaryColor: {
    checked: false,
    color: "",
    isValidColor: true,
  },
  additionalTheme: {},
  dateFilters: convertDateFiltersToLocalDraft(migrateDateFilters(null)),
  tenant: {
    name: null,
    uuid: null,
  },
  domain: null,
  menuFilters: {
    checked: false,
    list: [],
    dataSources: [],
    dependencyParentUuidStagedForDeleteMap: {},
  },
  booleanFilters: [""],
  showHeaderKPI: false,
  filtersUpdateHeaderKPIs: false,
  errors: null,
  loading: false,
  loaded: false,
  processing: false,
  recacheMenuFiltersLoading: false,
  recacheMenuFilterLoading: {},
  datasourceFieldsLoading: false,
};

const reducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case actions.GET_SITE_CONFIGURATION_START: {
      draft.loading = true;
      draft.success = null;
      draft.errors = null;
      break;
    }

    case actions.GET_SITE_CONFIGURATION_SUCCESS: {
      draft.dateFilters = convertDateFiltersToLocalDraft(
        migrateDateFilters(
          action.results.dateFilters && JSON.parse(action.results.dateFilters)
        )
      );

      if (action.results.additionalTheme) {
        draft.additionalTheme = JSON.parse(action.results.additionalTheme);
      }

      draft.menuFilters.list =
        action.results.menuFilters.map(assembleMenuFilter);

      images.forEach((item) => {
        draft[item.key].inputName = item.key;
        draft[item.key].label = item.label;
        draft[item.key].preview = action.results[item.key];
        draft[item.key].imageName = action.results[item.key];
      });

      const settings = JSON.parse(action.results.settings);

      draft.menuFilters.checked = action.results.menuFilters.length > 0;
      draft.siteName = action.results.siteName;
      draft.customPrimaryColor.checked = !!action.results.customPrimaryColor;
      draft.customPrimaryColor.color = action.results.customPrimaryColor;
      draft.domain = action.results.domain;
      draft.darkThemeEnabled = action.results.darkThemeEnabled;
      draft.tenant = action.results.tenant;
      draft.showHeaderKPI = action.results.showHeaderKPI;
      draft.filtersUpdateHeaderKPIs = !!settings?.filtersUpdateHeaderKPIs;
      draft.loading = false;
      draft.loaded = true;
      draft.menuPrimary = action.results.menuPrimary;

      break;
    }

    case actions.GET_SITE_CONFIGURATION_FAIL: {
      draft.errors = action.error;
      draft.loading = false;
      break;
    }

    case actions.UPDATE_SITE_CONFIGURATION_START: {
      draft.processing = true;
      draft.errors = null;
      break;
    }

    case actions.UPDATE_SITE_CONFIGURATION_SUCCESS: {
      draft.processing = false;
      draft.errors = null;
      break;
    }

    case actions.UPDATE_SITE_CONFIGURATION_FAIL: {
      draft.errors = action.errors;
      draft.processing = false;
      break;
    }

    case actions.GET_DATA_SOURCES_SUCCESS: {
      draft.menuFilters.dataSources = action.results.sources.map((source) => ({
        value: source.uuid,
        label: source.displayName,
      }));
      const dataSourcesByUuid = keyBy(action.results.sources, "uuid");
      draft.menuFilters.dataSourcesByUuid = dataSourcesByUuid;
      draft.menuFilters.dataSourceFieldsByUuid = keyBy(
        action.results.sources.map((dataSource) => dataSource.fields).flat(),
        "uuid"
      );
      draft.menuFilters.dataSourceFieldOptionsByDataSourceUuid = mapValues(
        dataSourcesByUuid,
        (source) =>
          source.fields.map((field) => ({
            label: field.defaultLabel,
            value: field.uuid,
          }))
      );
      break;
    }

    case actions.GET_DATA_SOURCE_FIELDS_START:
      draft.datasourceFieldsLoading = true;
      break;

    case actions.GET_DATA_SOURCE_FIELDS_SUCCESS: {
      const cache = [];
      draft.datasourceFieldsLoading = false;
      action.results.sourceFields.forEach(({ data }, index) => {
        cache[index] = {
          dataSourceUuid: data.uuid,
          options: data?.fields
            ? data.fields.map((field) => ({
                value: field.uuid,
                label: field.defaultLabel,
                name: field.name,
                type: field.type,
                filterable: field.filterable,
              }))
            : [],
          selected: {},
          selectedField: {},
        };
      });

      // for user defined filters (they have not datasource)
      function removeFalsyItems(item, index) {
        return item[index];
      }

      action.results.list.filter(removeFalsyItems).forEach((item, index) => {
        cache[index].selected =
          cache[index].options.find(
            (f) => f.value === item.dataSourceFieldUuid
          ) || {};
      });

      draft.menuFilters.list = action.results.list.map(assembleMenuFilter);

      draft.menuFilters.dataSourceFields = cache;

      const sortCache = JSON.parse(JSON.stringify(cache));

      action.results.list.filter(removeFalsyItems).forEach((item, index) => {
        sortCache[index].selected =
          sortCache[index].options.find(
            (f) => f.value === item.sortDataSourceFieldUuid
          ) || {};
      });

      break;
    }

    case actions.GET_DATA_SOURCE_FIELDS_FAIL:
      draft.datasourceFieldsLoading = false;
      break;

    case actions.RECACHE_MENU_FILTERS_START:
      draft.recacheMenuFiltersLoading = true;
      break;

    case actions.RECACHE_MENU_FILTERS_SUCCESS:
      draft.recacheMenuFiltersLoading = false;
      break;

    case actions.RECACHE_MENU_FILTERS_FAIL:
      draft.recacheMenuFiltersLoading = false;
      break;

    case actions.RECACHE_MENU_FILTER_START:
      draft.recacheMenuFilterLoading = {
        ...draft.recacheMenuFilterLoading,
        [action.uuid]: true,
      };
      break;

    case actions.RECACHE_MENU_FILTER_SUCCESS:
    case actions.RECACHE_MENU_FILTER_FAIL:
      draft.recacheMenuFilterLoading = {
        ...draft.recacheMenuFilterLoading,
        [action.uuid]: false,
      };
      break;

    default:
      return draft;
  }
});

function assembleMenuFilter(filter) {
  return {
    uuid: filter.uuid,
    dataSourceUuid: filter.dataSourceUuid,
    dataSourceFieldUuid: filter.dataSourceFieldUuid,
    sortDataSourceFieldUuid: filter.sortDataSourceFieldUuid,
    keyDataSourceFieldUuid: filter.keyDataSourceFieldUuid,
    sortOrder: filter.sortOrder,
    displayName: filter.displayName || "",
    hide: filter.hide || [],
    show: filter.show || [],
    type: filter.type || filterTypes[0].value,
    values:
      setCustomDefaultSelected(filter)
        .values?.filter((value) => value?.toString().trim())
        .map((item) => ({
          value: extractFromObject(item),
          label: extractFromObject(item),
        })) || [],
    defaultValues: setCustomDefaultSelected(filter).defaultValues || [],
    dependencyParents: filter.dependencyParents,
    usesCustomValues: filter.usesCustomValues,
    labeledValues: filter.labeledValues,
    name: filter.name,
    fromApi: true,
    position: filter.position,
  };
}

export default reducer;

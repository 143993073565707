import React, { useRef } from "react";
import styled from "@emotion/styled";
import { scrollbar } from "../../../../styles/styledSnippets";
import { lightenDarkenColor } from "../../../../styles/colorConvertor";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { buckets } from "../../../../utils/constants/dataTypes";

const MenuList = styled.div`
  background: ${(props) => props.theme.background.primary};
  border: 1px solid ${(props) => props.theme.gray.gray500};
  margin-top: 8px;
  padding: 6px 0;
  box-shadow: ${(props) => props.theme.general.shadow1dp};
  max-height: 320px;
  overflow-y: auto;
  z-index: 1000;
  position: absolute;
  width: 100%;
  ${(props) => scrollbar(props)}
`;

const Option = styled.div`
  text-align: left;
  padding: 6px 12px;
  cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  ${(props) =>
    !props.disabled
      ? `
        &:hover {
            background: ${lightenDarkenColor(props.theme.primary, 30)};
            color: white;
        }    
    `
      : ""}
`;

const Selector = styled.div`
  cursor: pointer;
  padding: 8px 18px;
  border: 1px solid ${(props) => props.theme.gray.gray500};
  border-radius: 2px;
  &:hover {
    background: ${(props) =>
      props.theme.type === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0,0,0,0.05)"};
  }
`;

const Placeholder = styled.span`
  text-align: left;
  opacity: 0.4;
`;

export default function BuilderFields(props) {
  const { filterApi, position, setShowList, selectedField, fields, showList } =
    props;
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowList(false));

  const handleSelect = (field) => {
    if (disabled(field)) return null;
    applyInFilterApi();
    setShowList(false);

    function applyInFilterApi() {
      const existingSetting = filterApi?.overrides?.[position];
      if (existingSetting) {
        filterApi.setOverride(position, field.name);
      } else {
        filterApi.addOverride(field.name);
      }
    }
  };

  function disabled(field) {
    const fieldType = buckets[field.mapping.type];
    if (fieldType === "number") {
      return false;
    }

    return !!filterApi.overrides.find(
      (override) => override.name === field.name
    );
  }

  const selectedDisplayName =
    selectedField &&
    fields.find((f) => selectedField === f.name).mapping.displayName;

  return (
    <div ref={ref}>
      <Selector
        data-cy="builder-field-selection"
        onClick={() => setShowList((s) => !s)}
      >
        {selectedDisplayName || <Placeholder>Select a Field...</Placeholder>}
      </Selector>

      {showList ? (
        <div style={{ position: "relative" }}>
          <MenuList>
            {fields.map((field) => (
              <Option
                key={field.name}
                data-disabled={disabled(field) ? "1" : ""}
                disabled={disabled(field)}
                data-cy="builder-field-option"
                onClick={() => handleSelect(field)}
              >
                {field.mapping.displayName}
              </Option>
            ))}
          </MenuList>
        </div>
      ) : null}
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import PropTypes from "prop-types";
import ioLogo from "../../styles/io-logo-white.png";

const Container = styled.div`
  background-image: linear-gradient(to right, #1f1f1f, #3c3c3c, #1f1f1f);
  background-size: cover;
  background-position: top;
  color: ${({ theme }) => theme.text.primary};
  height: 100vh;
  width: 100%;
`;

const InnerContainer = styled.div`
  background-image: linear-gradient(
      rgba(0, 88, 136, 0.5),
      rgba(0, 88, 136, 0.5)
    ),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center bottom;
  clip-path: polygon(0 0, 0 90%, 100% 70%, 100% 0);
  height: 100vh;
  font-size: 0.75em;
  padding-top: 18px;
  box-sizing: border-box;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    & > img {
      margin-top: 1%;
      margin-bottom: 1%;
    }

    clip-path: ${({ isFocused }) =>
      isFocused ? "none" : "polygon(0 0, 0 90%, 100% 70%, 100% 0)"};
  }
`;

const LogoContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

const LogoContainer = styled.div`
  height: 140px;
  width: 360px;
  background: url(${(props) => props.url}) center no-repeat;
  background-size: contain;
`;

const Footer = styled.div`
  color: white;
  position: absolute;
  bottom: 1%;
  text-align: center;
  width: 100%;
  z-index: 1000;

  & img {
    width: 200px;
  }

  @media (max-width: ${mobileBreakpoints.mobile}) {
    display: ${({ isFocused }) => (isFocused ? "none" : "block")};
  }
`;

export default function LoginContainer(props) {
  const { isFocused, background, logo } = props;

  return (
    <>
      <Container>
        <InnerContainer isFocused={isFocused} background={background}>
          <LogoContainerWrapper>
            <LogoContainer url={logo} />
          </LogoContainerWrapper>
          {props.children}
        </InnerContainer>
      </Container>
      <Footer isFocused={isFocused}>
        <img alt="Insight Out" src={ioLogo} />
      </Footer>
    </>
  );
}

LoginContainer.propTypes = {
  isFocused: PropTypes.bool,
  background: PropTypes.string,
};

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { Fragment } from "react";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import {
  FILTER_TYPE_DATE_PRESETS,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_MULTI_RANGE,
  FILTER_TYPE_SINGLE_DATE,
  customFilterOptions,
} from "../../utils/constants/constants";
import { absoluteDate } from "../../utils/dates/dateFunc";
import { useSelector } from "react-redux";
import menuFilterSettingsFormatConverter from "../../utils/formatConverters/menuFilterSettingsFormatConverter";
import { getDateWithoutTimezoneDifference } from "../../utils/formatters/dateFormatter";
import { mapToStringThenCompare } from "../../store/reducers/layoutReducer";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterButton = styled.div(
  ({ theme }) => `
  background: ${theme.menuPrimary ? theme.gray.gray100 : theme.gray.gray300};
  color: black;
  display: flex;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  margin-right: 7px;
  margin-bottom: 5px;
  position: relative;
`
);

const CloseIcon = styled.span`
  margin: 2px 0 0 10px;
  &:hover {
    color: ${(props) => props.theme.notification.errorDark};
    cursor: pointer;
  }
`;

const Label = styled.span`
  overflow-wrap: anywhere;
`;

const InStoreIcon = styled.div(
  ({ theme }) => `
  position: absolute;
  left: -4px;
  color: ${
    theme.menuPrimary ? lightenDarkenColor(theme.primary, 50) : theme.primary
  };
`
);

export default function SelectedFilters({
  filter = {},
  select,
  showFilters,
  additionalTheme,
}) {
  const { required = {} } = additionalTheme || {};
  const menuFiltersFromApi = useSelector((state) =>
    menuFilterSettingsFormatConverter.response.toLocal(
      state.auth.menuFilterSettings.data
    )
  );

  const customDatesFilterTypeMap = useSelector(
    (state) => state.layout.customDatesFilterTypeMap
  );

  if (showFilters) {
    return null;
  }

  function getLabel(item, index) {
    switch (filter.type) {
      case FILTER_TYPE_DATE_RANGE:
      case FILTER_TYPE_SINGLE_DATE:
        return format(absoluteDate(item.value), "MM/dd/yyyy");

      case FILTER_TYPE_MULTI_RANGE:
        return `${item.label}`;

      case FILTER_TYPE_DATE_PRESETS:
        return getDatePresetLabel(filter, index);

      default:
        return item.label;
    }
  }

  function getDatePresetLabel(filter, index) {
    const shortTerm =
      customDatesFilterTypeMap[filter.name] ??
      (filter.defaultValues ?? [])[0] ??
      "none";

    const option = customFilterOptions.find(
      (option) => option.value === shortTerm
    );

    const [val1, val2] = filter.values;

    if (index === 0) {
      return option?.label ?? "None...";
    }

    if (Date.parse(val1.value) && Date.parse(val2.value)) {
      const date1 = getDateWithoutTimezoneDifference(val1.value);
      const date2 = getDateWithoutTimezoneDifference(val2.value);

      const start = format(date1, "MM/dd/yyyy");
      const end = format(date2, "MM/dd/yyyy");

      return `${start} - ${end}`;
    }

    return "Invalid Date";
  }

  function hasBookmarkIcon(item, uuid) {
    return mapToStringThenCompare(menuFiltersFromApi[uuid] || [], item.value);
  }

  function showRemoveIcon() {
    return (
      !required[filter.uuid] &&
      filter.type !== FILTER_TYPE_MULTI_RANGE &&
      filter.type !== FILTER_TYPE_DATE_PRESETS
    );
  }

  return (
    <Container data-cy="selected-filter-container">
      {filter.values
        ?.filter((item) => item.checked)
        .map((item, i) => (
          <Fragment key={(item.value ?? item.key ?? item) + i}>
            <FilterButton>
              {hasBookmarkIcon(item, filter.uuid) && (
                <InStoreIcon>
                  <FontAwesomeIcon icon={["fas", "bookmark"]} />
                </InStoreIcon>
              )}
              <Label data-cy="selected-filter-label">{getLabel(item, i)}</Label>
              {showRemoveIcon() ? (
                <CloseIcon>
                  <FontAwesomeIcon
                    data-cy="remove-selected-filter-icon"
                    icon={["fa", "times"]}
                    onClick={() => select(item, filter.type)}
                  />
                </CloseIcon>
              ) : null}
            </FilterButton>
          </Fragment>
        ))}
    </Container>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FormatOverrideActions(props) {
  const {
    collapseIndex,
    index,
    showMoreOptions,
    onOverrideItemRemove,
    override,
  } = props;

  return (
    <>
      <FontAwesomeIcon
        icon={["fas", "times-circle"]}
        className="format-override-remove"
        onClick={() => onOverrideItemRemove(override.index)}
      />

      <FontAwesomeIcon
        icon={[
          "fas",
          collapseIndex === index ? "chevron-down" : "chevron-right",
        ]}
        className="format-override-collapse"
        onClick={() => showMoreOptions(index)}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Input from "../Form/Input/Input";
import { HexColorPicker } from "react-colorful";
import PropTypes from "prop-types";
import usePrevious from "../../utils/usePrevious";

const Container = styled.div`
  width: 198px;
`;

const size = 34;
const Swatch = styled.div`
  height: ${size}px;
  width: ${size}px;
  background: ${(props) => props.color};
  flex-shrink: 0;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
`;

export default function ColorSelector(props) {
  const { value, onChange, open } = props;
  const [tempColor, setTempColor] = useState(value);
  const [showPicker, setShowPicker] = useState(!!open);
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue !== value && tempColor !== value) {
      setTempColor(value);
    }
  }, [value, tempColor, prevValue]);

  function handleTempColorChange(e) {
    const nextValue = e.target.value;
    setTempColor(nextValue);
    const t = nextValue.replace("#", "");
    const reg = /(?:[0-9a-fA-F]{3}){1,2}$/i;
    const isValidHex = reg.test(t);
    if (isValidHex && t.length === 6) {
      onChange((nextValue.at(0) === "#" ? "" : "#") + nextValue);
    }
  }

  return (
    <Container>
      <div style={{ marginBottom: 18, display: "flex" }}>
        <Swatch
          data-cy="picker-swatch"
          color={value}
          onClick={() => setShowPicker((state) => !state)}
        />
        <Input
          fit
          value={tempColor}
          onChange={handleTempColorChange}
          maxLength={7}
        />
      </div>
      {showPicker ? (
        <div>
          <HexColorPicker color={value} onChange={onChange} />
        </div>
      ) : null}
    </Container>
  );
}

ColorSelector.defaultProps = {
  value: "#cccccc",
};

ColorSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

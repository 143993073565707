import styled from "@emotion/styled";
import React from "react";
import Input from "../../../UI/Form/Input/Input";
import Select from "../../../UI/Form/Select/Select";
import { dateFormatsValues, formats } from "../../../utils/constants/constants";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: 15%;
  }
`;

export default function Rollup({ state, setState, fields }) {
  const matchForRow = (_, { action, option, removedValue }) => {
    if (action === "select-option") {
      selectField(option);
    } else if (action === "remove-value") {
      removeField(removedValue);
    }
  };

  const selectField = (option) => {
    const matchForRow = [...state.chart.rollup.matchForRow];
    matchForRow.push(option.name);

    setState({
      ...state,
      chart: {
        ...state.chart,
        rollup: {
          ...state.chart.rollup,
          matchForRow,
        },
      },
    });
  };

  const removeField = (option) => {
    setState({
      ...state,
      chart: {
        ...state.chart,
        rollup: {
          ...state.chart.rollup,
          matchForRow: state.chart.rollup.matchForRow.filter(
            (r) => r !== option.name
          ),
        },
      },
    });
  };

  if (!state.chart.rollup) {
    return null;
  }
  return (
    <>
      <span>Rollup</span>
      <br />
      <Container>
        <Select
          options={fields}
          getOptionValue={(o) => o.dataSourceFieldUuid}
          getOptionLabel={(o) => o.name}
          onChange={matchForRow}
          isClearable={false}
          closeMenuOnSelect={false}
          label="Match For Row"
          isMulti
          value={fields.filter((f) =>
            state.chart.rollup.matchForRow?.includes(f.name)
          )}
        />
        <Select
          options={fields}
          getOptionValue={(o) => o.dataSourceFieldUuid}
          getOptionLabel={(o) => o.name}
          onChange={(o) =>
            setState({
              ...state,
              chart: {
                ...state.chart,
                rollup: {
                  ...state.chart.rollup,
                  matchForRowLabelKey: o?.name || null,
                },
              },
            })
          }
          isClearable={true}
          label="Match For Row Label Key"
          value={fields.find(
            (f) => f.name === state.chart.rollup.matchForRowLabelKey
          )}
        />
        <Select
          options={fields}
          getOptionValue={(o) => o.dataSourceFieldUuid}
          getOptionLabel={(o) => o.name}
          onChange={(o) =>
            setState({
              ...state,
              chart: {
                ...state.chart,
                rollup: {
                  ...state.chart.rollup,
                  dynamicColumnKey: o?.name || null,
                },
              },
            })
          }
          isClearable={true}
          label="Dynamic Column Key"
          value={fields.find(
            (f) => f.name === state.chart.rollup.dynamicColumnKey
          )}
        />
        <Select
          options={formats}
          placeholder="Formats"
          onChange={(o) =>
            setState({
              ...state,
              chart: {
                ...state.chart,
                rollup: {
                  ...state.chart.rollup,
                  dynamicColumnFormat: o?.value || null,
                },
              },
            })
          }
          value={formats.find(
            (format) => state.chart.rollup.dynamicColumnFormat === format.value
          )}
          label="Dynamic Column Format"
        />
        <Select
          options={dateFormatsValues}
          placeholder="Date Formats"
          onChange={(o) =>
            setState({
              ...state,
              chart: {
                ...state.chart,
                rollup: {
                  ...state.chart.rollup,
                  dynamicHeaderFormat: o?.value || null,
                },
              },
            })
          }
          value={formats.find(
            (format) => state.chart.rollup.dynamicHeaderFormat === format.value
          )}
          label="Dynamic Header Format"
        />
        <Input
          label="Dynamic Column Value"
          onChange={({ target }) =>
            setState({
              ...state,
              chart: {
                ...state.chart,
                rollup: {
                  ...state.chart.rollup,
                  dynamicColumnValue: target.value,
                },
              },
            })
          }
          value={state.chart.rollup.dynamicColumnValue}
        />
      </Container>
    </>
  );
}

import React from "react";
import VisualizationBase from "./BaseChart/VisualizationBase";
import Scatter from "./Scatter/Scatter";
import { extent } from "d3-array";

export default function ScatterVisualization(props) {
  const {
    xKey,
    yKey,
    sizeKey,
    data,
    valueFormat,
    legendItems,
    colorKey,
    visualizationId,
  } = props.chart;

  const withValues = data
    .filter((d) => d[yKey])
    .map((d) => ({
      ...d,
      [xKey]: new Date(d[xKey]),
      color: legendItems.find((l) => l.name === d[colorKey]).color,
    }));

  const xExtent = extent(withValues.map((d) => new Date(d[xKey])));
  const yExtent = extent(withValues.map((d) => d[yKey]));
  const sizeExtent = extent(withValues.map((d) => d[sizeKey]));

  return (
    <div id={visualizationId} style={{ position: "relative" }}>
      <VisualizationBase {...{ ...props, margin }}>
        <Scatter
          data={withValues}
          xKey={xKey}
          yKey={yKey}
          sizeKey={sizeKey}
          xExtent={xExtent}
          yExtent={yExtent}
          sizeExtent={sizeExtent}
          margin={margin}
          valueFormat={valueFormat}
        />
      </VisualizationBase>
    </div>
  );
}

const margin = {
  top: 10,
  right: 15,
  bottom: 40,
  left: 50,
};

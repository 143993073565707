import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export default function FlexWide(props) {
  return (
    <Container data-cy={props.cy} style={props.style}>
      {props.children}
    </Container>
  );
}

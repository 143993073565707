import styled from "@emotion/styled";
import { useCallback } from "react";
import { scrollbarDe } from "../../styles/styledSnippets";
import formatter from "../../utils/formatters/formatter";
import { getMappingType } from "../../utils/getVisualizationLabel";
import ProductStyleImage from "./ProductStyleImage";
import ProductStyleDetail from "./ProductStyleDetail";

const Continer = styled.div(
  ({ theme }) => `
  max-height: calc(100vh - 200px);
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  ${scrollbarDe(theme)};
  overflow-y: auto;
  color: ${theme.text.primary};
`
);

const Title = styled.div(
  ({ theme, background }) => `
  background: ${background || theme.primary};
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  width: fit-content;
`
);

const DetailsContainer = styled.div`
  display: flex;
  width: calc(100% - 300px);
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 20px;
`;

export default function ProductStyleVisualization(props) {
  const { data, meta, imageKey, titleKey, background } = props;

  const names = Object.keys(data);
  const title = data[titleKey];

  const getFormattedValue = useCallback(
    (key) => {
      const type = getMappingType(meta.fields, key);
      return formatter(data[key], type);
    },
    [data, meta.fields]
  );

  return (
    <>
      <Title background={background}>{title}</Title>
      <Continer>
        <ProductStyleImage
          imageLink={data[imageKey]}
          title={title}
          data={data}
        />

        <DetailsContainer>
          {names.map((name) => (
            <ProductStyleDetail
              key={name}
              name={name}
              getFormattedValue={getFormattedValue}
              fields={meta.fields}
            />
          ))}
        </DetailsContainer>
      </Continer>
    </>
  );
}

import React, { useRef, useEffect, useMemo } from "react";
import { curveCatmullRom, curveLinear, line } from "d3-shape";
import "d3-transition";
import { select } from "d3-selection";
import { isEqual } from "lodash-es";

export default function SpringLine(props) {
  const {
    values,
    curved,
    x,
    y,
    width,
    xOffset = 0,
    bandwidth = 0,
    ...remaining
  } = props;

  const lineRef = useRef(null);

  const zeroValues = values.map((v) => ({ ...v, value: 0 }));

  const l = useMemo(() => {
    return (curved) =>
      line()
        .x((d) => {
          const pos = x(d.xValue) + xOffset + bandwidth;
          return isNaN(pos) ? 0 : pos;
        })
        .y((d) => y(+d.value))
        .curve(curved ? curveCatmullRom : curveLinear);
  }, [bandwidth, x, xOffset, y]);

  const lastDValueRef = useRef(null);

  useEffect(() => {
    const selected = select(lineRef.current);

    if (!lastDValueRef.current) {
      const startingDValue = l(curved)(zeroValues);
      selected.attr("d", () => startingDValue);
    }
    const targetDValue = l(curved)(values);

    if (isEqual(targetDValue, lastDValueRef.current)) {
      // No change. Do not animate.
      return;
    }

    selected.transition().attr("d", () => targetDValue);
    lastDValueRef.current = targetDValue;
  }, [curved, l, values, width, xOffset, zeroValues]);

  return <path ref={lineRef} {...remaining} />;
}

import React from "react";
import { scaleLinear, scaleTime } from "d3-scale";
import XAxis from "../Axes/XAxis";
import YAxis from "../Axes/YAxis";
// THIS IS NOT AN ACTIVE USED CHART CURRENTLY
export default function Scatter(props) {
  const {
    data,
    xKey,
    yKey,
    sizeKey,
    xExtent,
    yExtent,
    sizeExtent,
    height,
    width,
    valueFormat,
  } = props;
  const x = scaleTime()
    .domain(xExtent)
    .range([70, width - 70]);

  const y = scaleLinear()
    .domain(yExtent)
    .range([height - 70, 70]);

  const size = scaleLinear().domain(sizeExtent).range([20, 80]);

  return (
    <>
      <g transform={`translate(0, 10)`}>
        <text fontSize={14} fill="white" opacity={0.7}>
          Size of circle: {sizeKey}
        </text>
      </g>
      <g>
        {data.map((d, i) => (
          <circle
            key={i}
            cx={x(d[xKey])}
            cy={y(d[yKey])}
            r={size(d[sizeKey])}
            fill={d.color}
            opacity={0.2}
          />
        ))}
      </g>
      <XAxis
        xScale={x}
        dateFormat="M/d"
        margin={null}
        height={height - 20}
        width={width}
        isLinear
        numTicks={6}
        hideXAxisLine
      />
      <YAxis
        yScale={y}
        yAxisGridColor={"gray"}
        yAxisGrid
        yTicksCount={6}
        width={width}
        hideYAxisLine
        yAxisFormat={valueFormat}
      />
    </>
  );
}

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Title = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > svg {
    font-size: 12px;
    cursor: pointer;
  }
`;

export default function AddNewRowsTitle({ anchorText, closeModal }) {
  return (
    <Title>
      Add New {anchorText}
      <FontAwesomeIcon icon={["fas", "times"]} onClick={closeModal} />
    </Title>
  );
}

import { format as d3Format } from "d3-format";
import dateFnsFormat from "date-fns/format";
import {
  averaged,
  diffDiv,
  differenced,
  counted,
  divided,
  doNothing,
  first,
  last,
  maxed,
  minned,
  previous,
  summed,
} from "./func";
import {
  absoluteDate,
  dateFromMonth,
  dateFromWeek,
  getDateFromMonthNumberYear,
} from "./dates/dateFunc";
import formatter from "./formatters/formatter";

export default (value, config = {}, extras = {}, lowerValueKey) => {
  if (lowerValueKey)
    return d3Format(config.lowerValueFormat)(value).concat(
      ` ${config.lowerValueLabel}`
    );

  // Get a raw value from an array
  if (config.formula && !formulaFns[config.formula]) return "Formula Err";
  // const formulatedValues = config.valueKey1Formula ? [] : value;
  const rawValue = formulaFns[config.formula || "none"](value);
  if (Array.isArray(rawValue) && !rawValue.length) return "--";

  if (rawValue === "Err" || rawValue === -Infinity || rawValue === Infinity)
    return "--";

  // Return the formatted raw value
  if (config.numberFormat) {
    // for Bosco info cards
    if (config.numberFormat.includes("uk")) {
      return formatter(rawValue, config.numberFormat);
    }
    if (!rawValue && rawValue !== 0) return "--";

    if (Array.isArray(rawValue) && !isNaN(rawValue[0])) {
      return d3Format(config.numberFormat)(rawValue[0]).replace("G", "B");
    }

    if (isNaN(rawValue)) return "Err";
    return d3Format(config.numberFormat)(rawValue).replace("G", "B");
  }

  if (config.dateFormat) {
    const fns = {
      monthly: dateFromMonth,
      weekly: dateFromWeek,
      monthYear: getDateFromMonthNumberYear,
    };
    const fn = fns[config.dateParserStyle] || absoluteDate;
    const useDate = fn(
      Array.isArray(rawValue) ? rawValue[0] : rawValue,
      extras.dateParserYear || "2020"
    );

    // @todo check for invalid date

    return dateFnsFormat(useDate, config.dateFormat);
  }
  return rawValue || "--";
};

export const arrFormula = (arr, fn) => formulaFns[fn](arr);

const formulaFns = {
  division: divided,
  sum: summed,
  max: maxed,
  min: minned,
  avg: averaged,
  count: counted,
  first: first,
  last: last,
  previous: previous,
  none: doNothing,
  diff: differenced,
  diffDiv: diffDiv,
};

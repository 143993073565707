import {
  addDays,
  addQuarters,
  addMonths,
  format,
  min,
  parse,
  parseISO,
  startOfWeek,
  getWeek,
  getYear,
} from "date-fns";

export default function padDataWithZeroes(data, dates, zeroPad) {
  // Return data if zeroPad is not set or if data is empty or if the date key is not found in the data
  if (!zeroPad || !data.length || (data.length && !data[0][zeroPad.dateKey])) {
    return data;
  }

  // Initialize an array to store required dates
  let requiredDates = [];

  // Initialize the start date based on the zeroPad type
  let startDateWeekly = startOfWeek(parseISO(dates.start.value), {
    weekStartsOn: 1,
  });

  // Define the end date
  const toDate = min([parseISO(dates.end.value), new Date()]);

  // Define the date format based on the zeroPad type
  let dateFormat =
    zeroPad.dateTerm === "weekly"
      ? "yyyy-MM-dd"
      : zeroPad.dateTerm === "monthly"
      ? "yyyy 'M'MM"
      : "yyyy QQQ";

  let tempDate =
    zeroPad.dateTerm === "weekly"
      ? startDateWeekly
      : parseISO(dates.start.value);
  // Loop through dates and push them into the requiredDates array
  while (tempDate <= toDate) {
    requiredDates.push(format(tempDate, dateFormat));
    tempDate =
      zeroPad.dateTerm === "weekly"
        ? addDays(tempDate, 7)
        : zeroPad.dateTerm === "monthly"
        ? addMonths(tempDate, 1)
        : addQuarters(tempDate, 1);
  }

  // Convert dates to weekly format if required
  if (zeroPad.convertToWeekly) {
    requiredDates = requiredDates.map(convertToWeeklyString);
  }

  // Map requiredDates to data and pad with zeroes if necessary
  const result = requiredDates.map((td) => {
    const match = data.find((d) => d[zeroPad.dateKey] === td);
    const obj = {
      [zeroPad.dateKey]: td,
    };

    // Pad data with zeroes
    if (zeroPad.amountKeys) {
      zeroPad.amountKeys.forEach((amountKey) => {
        obj[amountKey] = 0;
      });
    } else {
      obj[zeroPad.amountKey] = 0;
    }

    // Add xKey if present
    if (zeroPad.xKey)
      obj[zeroPad.xKey] = format(
        parse(td, "yyyy QQQ", new Date()),
        zeroPad.xKeyFormat
      );

    return match || obj;
  });

  // Reverse the result if required
  return zeroPad.reverse ? result.reverse() : result;
}

// Function to convert date string to weekly format
function convertToWeeklyString(dateString) {
  const date = new Date(dateString);
  return getYear(date) + " W" + getWeek(date, { weekStartsOn: 1 });
}

import React from "react";
import styled from "@emotion/styled";

const general = `
  border-radius: 3px;
  height: 5px;
  position: absolute;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Track = styled.div(
  ({ theme }) => `
  ${general}
  background: ${theme.gray.gray400};
  width: 98%;
  margin-top: 1px;
  z-index: 1;
`
);

const Range = styled.div(
  ({ theme }) => `
  ${general}
  background: ${theme.primary};;
  margin-top: 1px;
  z-index: 2;
`
);

export default function Slider(props) {
  const { rangeRef } = props;
  return (
    <SliderContainer>
      <Track />
      <Range ref={rangeRef} />
    </SliderContainer>
  );
}

import React, { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import Select from "../../../UI/Form/Select/Select";
import Input from "../../../UI/Form/Input/Input";

export default function WriteBacksCrmSubField({
  fieldsArray,
  register,
  fieldName,
  control,
}) {
  const registerOptions = {
    required: true,
  };

  const { fields, replace } = useFieldArray({
    control,
    name: fieldName,
  });

  useEffect(() => {
    replace(
      fieldsArray.map((f) => ({
        label: f.name,
        value: f.value,
        uuid: f.uuid,
        additionalLabel: f.additionalLabel,
        ...(f.additionalField && { additionalField: f.additionalField }),
      }))
    );
  }, [replace, fieldsArray]);

  return fields.map((field, index) => {
    return (
      <div key={index}>
        <Controller
          control={control}
          name={`${fieldName}.${index}.value`}
          rules={registerOptions}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              label={
                field.label +
                (field.additionalLabel
                  ? " - " + field.additionalLabel + " "
                  : "")
              }
              options={[
                { label: "0", value: "0" },
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
              ]}
              required
              onBlur={onBlur}
              menuPlacement="top"
              simpleValue={value}
              onChange={(option) => {
                onChange(option.value);
              }}
            />
          )}
        />
        {field.additionalField ? (
          <Input
            label={field.additionalField.label}
            {...register(`${fieldName}.${index}.additionalField.value`)}
          />
        ) : null}
      </div>
    );
  });
}

import styled from "@emotion/styled";
import Checkbox from "../../UI/Form/Checkbox/Checkbox";
import { getTimeExecutionStandartColor } from "./ChartItem";

const Filters = styled.div`
  display: flex;
  width: 40%;
  margin-bottom: 30px;
`;

export default function MonitoringFilters({
  Title,
  execStatuses,
  filters,
  theme,
  onFilterChange,
}) {
  return (
    <>
      <Title>Filters</Title>
      <Filters>
        {execStatuses.map((filter) => (
          <Checkbox
            key={filter}
            label={filter}
            onChange={() => onFilterChange(filter)}
            checked={filters.includes(filter)}
            color={getTimeExecutionStandartColor(filter, theme)}
          />
        ))}
      </Filters>
    </>
  );
}

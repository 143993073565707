import queryBuilder, {
  queryBuilderRuntimeConfig,
  QueryBuilderError,
} from "./queryBuilder/queryBuilder";
import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import { RESET_VISUALIZATION_PREVIEW } from "./actionTypes";
import { showToast } from "./message";
import { handleError } from "../../utils/errorHandling";
import { createAsyncTaskQueue, wrapThunkInQueue } from "../../utils/taskQueue";
import * as qs from "qs";
import { wrapAsyncThunk } from "../../utils/apiResultWrapper";
import { buildLocalRowExpandGroupedData } from "../../utils/dataOptionsValues";

export function setLimitOnJsonAndPowerEditor(config) {
  return config?.limit ? config : { ...config, limit: 1000 };
}

export const loadTableChartPreview = (config) => async (dispatch, getState) => {
  const qs = queryBuilder(config, getState);

  dispatch({ type: actionTypes.GET_TABLE_PREVIEW_DATA_START });

  try {
    const res = await axios.get(
      `api/v1/queries/${config["queryId"]}/exec?${qs}`
    );

    dispatch({
      type: actionTypes.GET_TABLE_PREVIEW_DATA_SUCCESS,
      table: res.data,
    });
  } catch (err) {
    handleError(dispatch, err, { showToast: false });

    showToast(
      actionTypes.GET_ATTRIBUTE_LIST_FAIL,
      dispatch,
      ["Table configuration is incorrect"],
      "danger"
    );
  }
};

const asyncQueue = createAsyncTaskQueue();
/** @type {Record<string, AbortController>} */
const abortControllers = {};

export function pauseVisualizationPreview(message, visualizationUuid) {
  return {
    type: actionTypes.SET_INVALID_PREVIEW_CONFIG_MESSAGE,
    message,
    visualizationUuid,
  };
}

async function runVisualizationPreviewExec(
  config,
  queryString,
  getState,
  key,
  dispatch,
  dateFilters
) {
  const { convertGetToPost } = config;

  const cacheKey = `${config.queryUuid}-${queryString}`;

  function getExecIsCompleted() {
    return getState().charts.visualizations[key]?.cacheKey === cacheKey;
  }

  if (getExecIsCompleted()) {
    return;
  }

  dispatch({
    type: actionTypes.GET_VISUALIZATION_PREVIEW_START,
    payload: key,
    config,
  });

  const body = {
    method: convertGetToPost ? "POST" : "GET",
    url: `api/v1/queries/${config.queryUuid}/exec?${
      convertGetToPost ? "" : queryString
    }`,
    signal: abortControllers[key].signal,
  };
  if (convertGetToPost) {
    body.data = qs.parse(queryString, { depth: 10 });
  }

  const res = await axios(body);

  dispatch({
    type: actionTypes.GET_VISUALIZATION_PREVIEW_SUCCESS,
    result: res.data,
    term: config.term,
    key: String(key),
    cacheKey,
    dateFilters,
    chart: config,
  });

  if (config.localRowExpandGrouping) {
    // make work localRowExpandGrouping on json chart config
    const { rowExpandedCharts } = buildLocalRowExpandGroupedData(
      res.data.data,
      config,
      res.data.meta
    );

    // because we cant do dispatch in reducer so we set rowExpandedCharts separate then summary data
    dispatch({
      type: actionTypes.SET_LOCAL_DRILLDOWNS_DATA,
      rowExpandedCharts,
    });
  }

  return res;
}

function configureQueryString(config, getState) {
  const oldStrictValue = queryBuilderRuntimeConfig.strict;

  try {
    queryBuilderRuntimeConfig.strict = true;
    return queryBuilder(config, getState, undefined, undefined, {
      editorMode: true,
    });
  } finally {
    queryBuilderRuntimeConfig.strict = oldStrictValue;
  }
}

export function loadVisualizationPreview(config, key) {
  const chart = setLimitOnJsonAndPowerEditor(config);

  const thunk = async (dispatch, getState) => {
    if (abortControllers[key]) {
      abortControllers[key].abort();
    }

    abortControllers[key] = new AbortController();

    try {
      const queryString = configureQueryString(chart, getState);
      const dateFilters = getState().layout.dateFilters;
      return await runVisualizationPreviewExec(
        chart,
        queryString,
        getState,
        key,
        dispatch,
        dateFilters
      );
    } catch (err) {
      showVisualizationPreviewError(err, dispatch, key);
    }
  };
  return wrapAsyncThunk(wrapThunkInQueue(asyncQueue, thunk));
}

function showVisualizationPreviewError(err, dispatch, key) {
  if (err instanceof QueryBuilderError) {
    // This should be handled separately.
    throw err;
  }
  const normalizedError = handleError(dispatch, err);
  dispatch({
    type: actionTypes.GET_VISUALIZATION_PREVIEW_FAIL,
    payload: {
      key,
      error: normalizedError,
    },
  });
  throw err;
}

export function resetVisualizationPreview(key) {
  if (abortControllers[key]) {
    abortControllers[key].abort();
  }

  return {
    type: RESET_VISUALIZATION_PREVIEW,
    payload: key,
  };
}

export const setChartType = (chartType) => {
  return {
    type: actionTypes.CHART_BUILDER_SET_CHART_TYPE,
    chartType,
  };
};

export const setXValue = (xValue) => {
  return {
    type: actionTypes.CHART_BUILDER_SET_X_VALUE,
    xValue,
  };
};

export const setYValue = (yValue) => {
  return {
    type: actionTypes.CHART_BUILDER_SET_Y_VALUE,
    yValue,
  };
};

export const setGroupBy = (groupBy) => {
  return {
    type: actionTypes.CHART_BUILDER_SET_GROUP_BY,
    groupBy,
  };
};

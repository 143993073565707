import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router";

import PrivateRoute from "../PrivateRoute/PrivateRoute";
import { ROLE_TENANT_OWNER } from "../../utils/constants/constants";
import Loading from "../../UI/Loading/Loading";
import { usePathName } from "../../hooks/usePathName";
import Alerts from "../Alerts/Alerts";
import ChartsPreview from "../ChartsPreview/ChartsPreview";
import DataExplorer from "../DataManagement/DataExplorer/DataExplorer";
import InsightsPage from "../DataManagement/Insights/InsightsPage";
import DataMap from "../EtlConfig/DataMap/DataMap";
import useUserAccess from "../../hooks/useUserAccess";
import { useShallowEqualSelector } from "../../store";
import Monitoring from "../Monitoring/Monitoring";
import BlueprintHome from "../DataManagement/Blueprint/BlueprintHome";
import MonitoringHistory from "../Monitoring/MonitoringHistory/MonitoringHistory";
import Automation from "../Automation/Automation";

const UserManagement = lazy(() => import("../UserManagement/UserManagement"));
const CreateUserOrUpdate = lazy(() =>
  import("../UserManagement/Users/CreateUserOrUpdate")
);

const FileManagement = lazy(() => import("../Files/FileManagement"));
const DataManagementHome = lazy(() =>
  import("../DataManagement/DataManagementHome")
);
const SiteConfiguration = lazy(() =>
  import("../SiteConfiguration/SiteConfiguration")
);
const PageManagement = lazy(() => import("../PageManagement/PageManagement"));
const HeaderKPIPage = lazy(() => import("../HeaderKPIs/HeaderKPIsPage"));
const DataSetup = lazy(() => import("../DataManagement/DataSetup/DataSetup"));

const AdminRouting = () => {
  const { role } = useShallowEqualSelector((state) => ({
    logo: state.layout.logo,
    theme: state.layout.theme,
    role: state.auth.role,
    permissions: state.auth.role,
  }));

  const defaultDashboardTab = usePathName();
  const access = useUserAccess();

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute path={["/admin/insights"]} component={InsightsPage} />
        )}
        {access.userManagement && (
          <PrivateRoute
            path={[
              "/admin/user-management/users",
              "/admin/user-management/groups",
              "/admin/user-management/roles",
            ]}
            component={UserManagement}
          />
        )}
        {access.userManagement && (
          <PrivateRoute
            path={[
              "/admin/user-management/create",
              "/admin/user-management/edit/:userId",
            ]}
            exact
            component={CreateUserOrUpdate}
          />
        )}
        {access.fileUploading && (
          <PrivateRoute
            path={"/admin/files"}
            exact
            component={FileManagement}
          />
        )}
        {access.dataSourcesRead && (
          <PrivateRoute
            path={"/admin/data-settings"}
            component={DataManagementHome}
          />
        )}
        {access.siteConfiguration && (
          <PrivateRoute
            path={"/admin/site-configuration"}
            component={SiteConfiguration}
          />
        )}
        {access.dataExplorer && (
          <PrivateRoute
            path={"/admin/data-explorer"}
            component={DataExplorer}
          />
        )}
        {access.pageManagement && (
          <PrivateRoute
            path={"/admin/page-management/:pageUuid?"}
            component={PageManagement}
          />
        )}
        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute path={"/admin/alerts"} component={Alerts} />
        )}
        {access.pageManagement && (
          <PrivateRoute path={"/admin/charts"} component={ChartsPreview} />
        )}
        {access.pageManagement && (
          <PrivateRoute path={"/admin/data-map"} component={DataMap} />
        )}

        {access.pageManagement && (
          <PrivateRoute path={"/admin/header-kpis"} component={HeaderKPIPage} />
        )}

        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute path="/admin/data-setup" component={DataSetup} />
        )}
        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute
            path={"/admin/data-blueprint"}
            component={BlueprintHome}
          />
        )}
        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute
            exact
            path={"/admin/monitoring"}
            component={Monitoring}
          />
        )}
        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute
            exact
            path={"/admin/monitoring/history"}
            component={MonitoringHistory}
          />
        )}
        {ROLE_TENANT_OWNER === role && (
          <PrivateRoute
            path={[
              "/admin/automation",
              "/admin/automation/scheduled-emails",
              "/admin/automation/scheduled-emails/new",
              "/admin/automation/scheduled-emails/edit/:uuid",
            ]}
            component={Automation}
          />
        )}
        {ROLE_TENANT_OWNER === role && (
          <Redirect to={"/admin/user-management/users"} />
        )}
        <Redirect to={defaultDashboardTab} />
      </Switch>
    </Suspense>
  );
};

export default AdminRouting;

import React, { useCallback, useMemo, useRef, useState } from "react";
import { array } from "prop-types";
import { keyBy, uniq, without } from "lodash-es";
import { reportError } from "../../../utils/errorHandling";
import produce from "immer";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faClock,
  faEyeSlash,
  faMountain,
} from "@fortawesome/pro-regular-svg-icons";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { css } from "../../../utils/ide";
import { getFieldKey } from "../../../utils/charts/fields";

export const INSIGHT_FIELD_TYPE_DATE = "date";
export const INSIGHT_FIELD_TYPE_VALUE = "value";
export const INSIGHT_FIELD_TYPE_GROUP = "group";

const possibleFieldTypes = [
  {
    icon: faBox,
    name: "Group",
    type: INSIGHT_FIELD_TYPE_GROUP,
  },
  {
    icon: faClock,
    name: "Date",
    type: INSIGHT_FIELD_TYPE_DATE,
  },
  {
    icon: faMountain,
    name: "Value",
    type: INSIGHT_FIELD_TYPE_VALUE,
  },
];

const possibleFieldTypesByKey = keyBy(possibleFieldTypes, "type");

export const defaultFieldTypesState = {
  [INSIGHT_FIELD_TYPE_DATE]: null,
  [INSIGHT_FIELD_TYPE_VALUE]: null,
  [INSIGHT_FIELD_TYPE_GROUP]: [],
};

const insightFieldTypeSingleValues = [
  INSIGHT_FIELD_TYPE_DATE,
  INSIGHT_FIELD_TYPE_VALUE,
];

const Container = styled.div(
  ({ theme }) => css`
    color: ${theme.text.primary};

    .clickable {
      cursor: pointer;

      &:hover {
        color: ${theme.notification.infoMain};
      }
    }
  `
);

const Field = styled.div`
  display: inline-block;
  margin-bottom: 20px;
`;

const SelectableFieldTypeContainer = styled.div`
  padding: 0.5rem;
`;

function SelectableFieldType({ displayName, icon, onClick }) {
  return (
    <SelectableFieldTypeContainer onClick={onClick} className="clickable">
      <FontAwesomeIcon icon={icon} pull="left" fixedWidth />
      {displayName}
    </SelectableFieldTypeContainer>
  );
}

/**
 * Lets you assign insight field types for fields.
 */
export default function SetFieldTypes({ fieldTypesState, fields }) {
  const [fieldTypes, setFieldTypes] = fieldTypesState;
  const [expandedField, setExpandedField] = useState(null);
  const clickOutsideRef = useRef();
  const fieldsContainerRef = useRef();
  useOnClickOutside(
    clickOutsideRef,
    () => setExpandedField(null),
    fieldsContainerRef
  );

  const fieldsAndDisplayNames = useMemo(() => {
    return fields.map((field) => ({
      key: getFieldKey(field), // AKA. the key.
      displayName: field.mapping?.displayName ?? field.name,
    }));
  }, [fields]);

  const fieldTypeByKey = useMemo(() => {
    const ret = {};
    if (fieldTypes[INSIGHT_FIELD_TYPE_DATE] != null) {
      ret[fieldTypes[INSIGHT_FIELD_TYPE_DATE]] = INSIGHT_FIELD_TYPE_DATE;
    }
    if (fieldTypes[INSIGHT_FIELD_TYPE_VALUE] != null) {
      ret[fieldTypes[INSIGHT_FIELD_TYPE_VALUE]] = INSIGHT_FIELD_TYPE_VALUE;
    }
    for (const key of fieldTypes[INSIGHT_FIELD_TYPE_GROUP]) {
      ret[key] = INSIGHT_FIELD_TYPE_GROUP;
    }
    return ret;
  }, [fieldTypes]);

  // Unssigns an insight type for a field.
  const unassignField = useCallback(
    (fieldKey) => {
      const fieldType = fieldTypeByKey[fieldKey];
      if (!fieldType) {
        // Field key was not assigned.
        return;
      }
      setFieldTypes((fieldTypes) =>
        produce(fieldTypes, (fieldTypes) => {
          if (insightFieldTypeSingleValues.includes(fieldType)) {
            fieldTypes[fieldType] = null;
          } else if (fieldType === INSIGHT_FIELD_TYPE_GROUP) {
            fieldTypes[fieldType] = without(fieldTypes[fieldType], fieldKey);
          }
        })
      );
    },
    [fieldTypeByKey, setFieldTypes]
  );

  // Assigns an insight type for a field.
  const assignField = useCallback(
    (fieldType, fieldName) => {
      if (
        fieldTypeByKey[fieldName] &&
        fieldTypeByKey[fieldName] !== fieldType
      ) {
        // Un-assign any other value first.
        unassignField(fieldName);
      }
      setFieldTypes((fieldTypes) =>
        produce(fieldTypes, (fieldTypes) => {
          if (insightFieldTypeSingleValues.includes(fieldType)) {
            fieldTypes[fieldType] = fieldName;
          } else if (fieldType === INSIGHT_FIELD_TYPE_GROUP) {
            fieldTypes[fieldType] = uniq([...fieldTypes[fieldType], fieldName]);
          } else {
            reportError(new Error("Unknown field type: " + fieldType));
          }
        })
      );
    },
    [fieldTypeByKey, setFieldTypes, unassignField]
  );

  return (
    <Container>
      <h2>Set Field Types</h2>

      <div
        style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}
        ref={fieldsContainerRef}
      >
        {fieldsAndDisplayNames.map((field) => (
          <Field key={field.key} data-cy="insight-select-available-field">
            <span
              className="clickable field"
              style={{
                marginRight: 40,
                fontWeight: fieldTypeByKey[field.key] ? "bold" : undefined,
              }}
              onClick={() =>
                setExpandedField((expandedField) =>
                  expandedField !== field.key ? field.key : null
                )
              }
            >
              <FontAwesomeIcon
                icon={
                  possibleFieldTypesByKey[fieldTypeByKey[field.key]]?.icon ??
                  faEyeSlash
                }
                style={{ marginRight: "0.5rem" }}
                pull="left"
                fixedWidth
              />
              {field.displayName}
            </span>

            {expandedField === field.key && (
              <div ref={clickOutsideRef}>
                <div style={{ height: 10 }} />
                <SelectableFieldType
                  onClick={() =>
                    setExpandedField(null) || unassignField(field.key)
                  }
                  icon={faEyeSlash}
                  displayName="Ignore"
                />
                {possibleFieldTypes.map((fieldTypeConfigItem) => (
                  <SelectableFieldType
                    key={fieldTypeConfigItem.name}
                    onClick={() =>
                      setExpandedField(null) ||
                      assignField(fieldTypeConfigItem.type, field.key)
                    }
                    icon={fieldTypeConfigItem.icon}
                    displayName={fieldTypeConfigItem.name}
                  />
                ))}{" "}
              </div>
            )}
          </Field>
        ))}
      </div>
    </Container>
  );
}

SetFieldTypes.propTypes = {
  fieldTypesState: array,
  fields: array,
};

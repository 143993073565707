import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  position: absolute;
  bottom: 12px;
  display: flex;
  div {
    margin: 0 8px;
  }
`;

const InListActionButtons = (props) => {
  return <Container>{props.children}</Container>;
};

export default InListActionButtons;

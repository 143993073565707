import styled from "@emotion/styled";
import { omit } from "lodash-es";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";
import MathOperation from "./MathOperation";

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const notNumberAggregations = [
  6,
  7,
  8,
  9,
  10,
  "YEAR",
  "QUARTER",
  "MONTH",
  "WEEK",
  "DAY",
];

const notNumberTypes = ["date", "string"];

export default function MathOperations({
  selectedColumn,
  setChartState,
  setSelectedColumn,
  overrides,
  query = {},
  chart,
}) {
  const { name, ops, subIndex, column } = selectedColumn;

  const updateState = (withOps) => {
    setChartState({
      ...chart,
      overrides: chart.overrides.map((override) => {
        const newOverride = withOps
          ? { ...override, ops: withOps }
          : omit(override, "ops");
        return override.name === name ? newOverride : override;
      }),
      ...(chart.subTitles && {
        subTitles: chart.subTitles.map((subTitle, index) =>
          subTitle.map((sub) => {
            if (subIndex === index && (sub === column || sub === ops?.alias)) {
              return withOps?.alias ?? column;
            }

            return sub;
          })
        ),
      }),
    });

    setSelectedColumn({ ...selectedColumn, ops: withOps });
  };

  const setUnsetMathOperation = () => {
    const { ops, aggregation } = selectedColumn;
    const newOps = ops
      ? null
      : {
          ...(aggregation && { aggregation }),
          type: "add",
          fields: [{}],
          alias: "DefaultAlias",
        };

    updateState(newOps);
  };

  const isDisabled = () => {
    const aggregation = selectedColumn.aggregation;
    if (aggregation && !notNumberAggregations.includes(aggregation)) {
      return false;
    }

    const mapping = overrides.find((o) => o.name === name)?.mapping;
    if (mapping?.type && !notNumberTypes.includes(mapping.type)) {
      return false;
    }

    const fields = query.dataSources.find((ds) =>
      ds.fields.find((field) => field.name === name)
    )?.fields;

    if (fields) {
      const type = fields.find((field) => field.name === name)?.mapping?.type;
      return !!notNumberTypes.includes(type);
    }
  };

  return (
    <div>
      <Title>
        <span>Math Operations</span>
        <Checkbox
          label="Enable"
          checked={!!ops}
          onChange={setUnsetMathOperation}
          disabled={!!isDisabled}
        />
      </Title>
      <br />

      <MathOperation
        overrides={overrides}
        selectedColumn={selectedColumn}
        updateState={updateState}
      />
    </div>
  );
}

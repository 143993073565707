import {
  INSIGHT_FIELD_TYPE_DATE,
  INSIGHT_FIELD_TYPE_GROUP,
  INSIGHT_FIELD_TYPE_VALUE,
} from "./SetFieldTypes";

export const insightTestFields = [
  { name: "SpecieName", mapping: { displayName: "Species" } },
  { name: "Name", mapping: { displayName: "Target Human" } },
  { name: "Bites" },
  { name: "Date" },
  { name: "IrrelevantData", mapping: { displayName: "Irrelevant Data" } },
];

export const insightTestData = [
  {
    Date: "2020 M01",
    SpecieName: "sharks",
    Name: "Fred",
    Bites: 10,
    IrrelevantData: 1,
  },
  {
    Date: "2020 M01",
    SpecieName: "sharks",
    Name: "Bill",
    Bites: 60,
    IrrelevantData: 2,
  },
  {
    Date: "2020 M02",
    SpecieName: "sharks",
    Name: "Fred",
    Bites: 10,
    IrrelevantData: 3,
  },
  {
    Date: "2020 M02",
    SpecieName: "sharks",
    Name: "Bill",
    Bites: 80,
    IrrelevantData: 4,
  },
];

export const insightTestFieldTypes = {
  [INSIGHT_FIELD_TYPE_DATE]: "Date",
  [INSIGHT_FIELD_TYPE_VALUE]: "Bites",
  [INSIGHT_FIELD_TYPE_GROUP]: ["SpecieName", "Name"],
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DrilldownIcon({ enumColorStyles }) {
  if (!enumColorStyles?.drilldownIcon) {
    return null;
  }

  return (
    <FontAwesomeIcon
      icon={["far", "folder-tree"]}
      style={{ position: "absolute", right: 10 }}
    />
  );
}

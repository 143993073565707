import React from "react";
import SampleBox from "./SampleBox";
import { format } from "d3-format";
import dateFormat from "date-fns/format";

import styled from "@emotion/styled";
import { absoluteDate } from "../../../../../utils/dates/dateFunc";

const Container = styled.div`
  position: absolute;
  left: ${(props) => (props.double ? 480 : 240)}px;
  top: -2px;
  height: 48px;
  padding: 0;
  z-index: 500;
  background: ${(props) => props.theme.background.primary};
`;

export default function SampleNumberPreview(props) {
  const { sampleResults, show, date, double } = props;
  if (!show) return null;

  function formatValue(val) {
    if (date) {
      return dateFormat(absoluteDate(val), "M/d/yyyy");
    } else return format(".3s")(+val);
  }

  return (
    <Container double={double}>
      {sampleResults?.max ? (
        <div style={{ display: "flex", marginBottom: 18 }}>
          <SampleBox label="Min" value={formatValue(sampleResults.min)} />
          <SampleBox label="Max" value={formatValue(sampleResults.max)} />
          {!date ? (
            <SampleBox
              style={{ marginLeft: 30 }}
              label="Avg"
              value={formatValue(sampleResults.avg)}
            />
          ) : null}
        </div>
      ) : null}
    </Container>
  );
}

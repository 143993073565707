import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../../../styles/colorConvertor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnForwardBack from "../DataExplorerUI/ColumnForwardBack";
import usePrevious from "../../../../utils/usePrevious";
import BuilderSaveButtons from "./BuilderSaveButtons";
import BuilderAggregation from "./BuilderAggregation";
import BuilderMapping from "./BuilderMapping";
import BuilderFields from "./BuilderFields";

const ColumnBuilderContainer = styled.div`
  color: ${(props) => props.theme.text.secondary};
  display: inline-block;
  width: 280px;
  height: 490px;
  min-height: 440px;
  font-size: 14px;
  margin-left: 12px;
  padding: 18px;
  background: ${(props) => props.theme.background.secondary};
  position: fixed;
  bottom: 57px;
  right: 16px;
  border: 2px solid ${(props) => props.theme.notification.infoMain};
`;

const Header = styled.div`
  margin-bottom: 12px;
  text-align: left;
  font-weight: 700;
  path {
    fill: ${(props) => lightenDarkenColor(props.theme.primary, 30)};
  }
  svg {
    padding-left: 4px;
  }
`;

export default function ColumnBuilder(props) {
  const { position, fields, setVisibility, filterApi, setPosition, addColumn } =
    props;
  const [showList, setShowList] = useState(false);
  const [showAggregates, setShowAggregates] = useState(false);

  const selectedField = filterApi.overrides[position]?.name;
  const previousPosition = usePrevious(position);

  const handleApplySettings = () => {
    setVisibility(false);
  };

  const override = filterApi.overrides && filterApi.overrides[position];
  useEffect(() => {
    setShowAggregates(!!override?.aggregation);
  }, [override]);

  useEffect(() => {
    if (!showAggregates && filterApi.overrides[position]?.aggregation) {
      if (previousPosition === position) {
        filterApi.removeAggregation(position);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAggregates, position, previousPosition]);

  const matchedField = fields.find(
    (f) => f.name === filterApi.overrides[position]?.name
  );

  return (
    <ColumnBuilderContainer data-cy="column-builder">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header>
          Configure Column <FontAwesomeIcon icon={["fas", "info-circle"]} />
        </Header>
        <ColumnForwardBack position={position} setPosition={setPosition} />
        <div
          data-cy="column-builder-close"
          style={{ cursor: "pointer" }}
          onClick={() => setVisibility(false)}
        >
          <FontAwesomeIcon icon={["fal", "times"]} />
        </div>
      </div>

      <BuilderFields
        filterApi={filterApi}
        position={position}
        setShowList={setShowList}
        selectedField={selectedField}
        fields={fields}
        showList={showList}
      />

      {matchedField ? (
        <>
          <BuilderAggregation
            aggregate={showAggregates}
            setAggregate={setShowAggregates}
            filterApi={filterApi}
            position={position}
            matchedField={matchedField}
          />

          <BuilderMapping
            override={filterApi.overrides[position]}
            field={matchedField}
            updateMapping={(val) => filterApi.setMapping(position, val)}
          />

          <BuilderSaveButtons
            filterApi={filterApi}
            position={position}
            handleApplySettings={handleApplySettings}
            addColumn={addColumn}
          />
        </>
      ) : null}
    </ColumnBuilderContainer>
  );
}

ColumnBuilder.defaultProps = {
  position: 0,
};

import React, { useMemo, useState } from "react";
import showFilter from "./showFilter";
import MenuFilter from "./MenuFilter";
import ComparisonTypeSelector from "./ComparisonTypeSelector";
import { getComparisonModeItem } from "../Block/interruptDashboard";

export default React.memo(function MenuFiltersList(props) {
  const {
    filters,
    currentTabId,
    dataSourceAccessConfig,
    menuFilterShortList,
    domain,
    additionalTheme,
    select,
    comparisonMode,
    selectPowerEditorModeFilter,
    searchFilterLoading,
  } = props;
  const [selectedComparisonMode, setSelectedComparisonMode] = useState(null);

  const comparisonModeItem = getComparisonModeItem(
    comparisonMode,
    currentTabId
  );

  const displayedFilters = filters
    .filter((filter) => !filter.extended)
    .filter((filter) =>
      showFilter(filter, currentTabId, dataSourceAccessConfig)
    )
    .map((filter) =>
      menuFilterShortList[filter.uuid] === "loading"
        ? { ...filter, loading: true }
        : filter
    )
    .filter(comparisonModeVisibility);

  function comparisonModeVisibility(filter) {
    if (!comparisonModeItem) {
      return true;
    }

    // we have other filters which should show on comparison page
    const others = !comparisonModeItem.options.find((o) => o === filter.name);

    return selectedComparisonMode === filter.name || others;
  }

  const displayFilters = useMemo(() => {
    return displayedFilters.map((item, i) => (
      <MenuFilter
        filterType={item}
        key={item.uuid ?? i}
        open={
          !!(
            filters.length === 1 ||
            selectedComparisonMode === item.name ||
            (additionalTheme?.openDefault &&
              additionalTheme.openDefault[item.uuid])
          )
        }
        select={select}
        menuFilterShortList={menuFilterShortList[item.uuid]}
        dataSourceAccessConfig={dataSourceAccessConfig}
        domain={domain}
        additionalTheme={additionalTheme}
        comparisonModeItem={comparisonModeItem}
        selectPowerEditorModeFilter={selectPowerEditorModeFilter}
        searchFilterLoading={searchFilterLoading}
      />
    ));
  }, [
    additionalTheme,
    dataSourceAccessConfig,
    displayedFilters,
    domain,
    filters.length,
    menuFilterShortList,
    select,
    selectedComparisonMode,
    comparisonModeItem,
    selectPowerEditorModeFilter,
    searchFilterLoading,
  ]);

  return (
    <>
      {comparisonMode ? (
        <ComparisonTypeSelector
          comparisonMode={comparisonModeItem}
          selectedComparisonMode={selectedComparisonMode}
          setSelectedComparisonMode={setSelectedComparisonMode}
          filters={filters}
        />
      ) : null}
      {displayFilters}
    </>
  );
});

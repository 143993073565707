import { ROLE_TENANT_OWNER } from "../utils/constants/constants";
import { useShallowEqualSelector } from "../store";
import { permissionName } from "../Pages/UserManagement/Roles/models";
import { useSelector } from "react-redux";
import { databaseSwitcherDomains } from "../utils/env";

export default function useUserAccess() {
  const { permissions = [], role } = useShallowEqualSelector((state) => ({
    permissions: state.auth.permissions,
    role: state.auth.role,
  }));
  const domain = useSelector((state) => state.layout.domain);

  const isTenantOwner = role === ROLE_TENANT_OWNER;
  /**
   * data source,
   * query management,
   * connection,
   * attributes,
   */
  const hasDataSourceRead = permissions.includes(
    permissionName.DATA_SOURCE_READ
  );

  const hasVisualizationsPermission = permissions.includes(
    permissionName.VISUALIZATIONS_READ
  );
  const isTenantOwnerOrHasVisualizationsPermission =
    isTenantOwner || hasVisualizationsPermission;

  const hasDataSourcesAccess =
    isTenantOwner || role === "data_manager" || hasDataSourceRead;

  return {
    userManagement: isTenantOwner,
    siteConfiguration: isTenantOwnerOrHasVisualizationsPermission,
    pageManagement: isTenantOwnerOrHasVisualizationsPermission,
    dataSources: hasDataSourcesAccess,
    dataSourcesRead: hasDataSourcesAccess || hasVisualizationsPermission,
    dataExplorer:
      isTenantOwner || permissions.includes(permissionName.DATA_EDITOR),
    fileUploading:
      isTenantOwner || permissions.includes(permissionName.DATA_IMPORT),
    insights:
      isTenantOwner || permissions.includes(permissionName.INSIGHTS_READ),
    editDataDictionary: isTenantOwner,
    databaseSwitching:
      isTenantOwner && databaseSwitcherDomains.includes(domain),
    monitoring: isTenantOwner,
    emailReports: isTenantOwner,
  };
}

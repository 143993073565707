import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { aggregations } from "../../../../../utils/constants/constants";
import Input from "../../../../../UI/Form/Input/Input";
import Select from "../../../../../UI/Form/Select/Select";
import Operation from "./Operation";

const OperationView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > input,
  & > div {
    width: 50%;
  }

  & > svg {
    cursor: pointer;
  }
`;

export default function MathOperation({
  selectedColumn,
  overrides,
  updateState,
}) {
  const { ops, name } = selectedColumn;

  if (!ops) {
    return null;
  }

  function setFieldOrValue() {
    const { fields, value, ...rest } = ops;
    const newOps = fields ? { ...rest, value: "" } : { ...rest, fields: [{}] };
    updateState(newOps);
  }

  function changeField(option) {
    const newOps = {
      ...ops,
      fields: [{ ...ops.fields[0], name: option.name }],
    };
    updateState(newOps);
  }

  function onInputChange(e) {
    const newOps = { ...ops, [e.target.name]: e.target.value };
    updateState(newOps);
  }

  function changeFieldAggregation(option) {
    const newOps = {
      ...ops,
      fields: [{ ...ops.fields[0], aggregation: option.value }],
    };
    updateState(newOps);
  }

  return (
    <>
      <OperationView>
        <span>{name}</span>
        <Operation updateState={updateState} ops={ops} />
        {ops?.fields ? (
          <Select
            options={overrides}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={selectedColumn.ops?.fields[0]}
            onChange={changeField}
          />
        ) : (
          <Input value={ops?.value} onChange={onInputChange} name="value" />
        )}
        <FontAwesomeIcon
          icon={["fa", "exchange-alt"]}
          onClick={setFieldOrValue}
        />
      </OperationView>
      <br />
      <Input
        label="Alias"
        value={ops?.alias}
        onChange={onInputChange}
        name="alias"
      />
      <br />
      {ops?.fields && (
        <Select
          options={[{ label: "None", prefix: "" }, ...aggregations]}
          value={
            aggregations.find(
              (aggr) => aggr.value === ops.fields[0].aggregation
            ) || {
              label: "None",
              prefix: "",
            }
          }
          label="Aggregate"
          onChange={changeFieldAggregation}
        />
      )}
    </>
  );
}

import { useState, memo } from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import DatePickerContainer from "../../UI/DatePicker/DatePickerContainer";
import { FILTER_TYPE_DATE_RANGE } from "../../utils/constants/constants";

const Container = styled.div(
  ({ theme }) => `
    left: 6px;
`
);

const ApplyButton = styled.span(
  ({ theme, disabled }) => `
 color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.primary};
  padding: 0px 12px;
  font-size: 10px;
  margin-left: 10px;
  text-transform: uppercase;

  ${
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `
  };

  &:hover {
      text-decoration: underline;
      cursor: pointer;
  }`
);

export default memo(function DateRangeFilter(props) {
  const theme = useTheme();
  const { type, filterType, select } = props;
  const { values = [] } = filterType ?? {};

  const [range, setRange] = useState(values);
  const [start = {}, end = {}] = range;

  if (type !== FILTER_TYPE_DATE_RANGE) {
    return null;
  }

  function applyFilters() {
    select(range, FILTER_TYPE_DATE_RANGE);
  }

  function changeRange(e, name) {
    setRange((prevRange) =>
      prevRange.map((range) =>
        range.label === name
          ? { ...range, value: e.target.value, checked: true }
          : range
      )
    );
  }

  // @todo should we have two date range pickers?
  // CustomDateRange Filter also exists
  return (
    <Container data-cy={"date-range-container"}>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          value={start.value}
          onChange={(e) => changeRange(e, start.label)}
          data-cy={"date-range-start-input"}
          max={end.value}
        />
      </DatePickerContainer>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          value={end.value}
          onChange={(e) => changeRange(e, end.label)}
          data-cy={"date-range-end-input"}
          min={start.value}
        />
      </DatePickerContainer>
      <ApplyButton
        onClick={applyFilters}
        disabled={range.some((item) => !item.checked)}
        data-cy={"date-range-apply-button"}
      >
        Set Date
      </ApplyButton>
    </Container>
  );
});

import { customFilterOptionsByValue } from "../../../utils/constants/constants";
import { useShallowEqualSelector } from "../../../store";
import ToDateFilters from "../../LeftMenu/ToDateFilters";
import { useEffect, useMemo, useState } from "react";
import {
  buildFilters,
  setDateFilters,
} from "../../../utils/formatters/dateFormatter";
import { useDispatch } from "react-redux";
import { saveSelectedDatePresets } from "../../../store/actions/layout";

export default function DatePresets({ type, select, name, defaultValues }) {
  const { dateFiltersConfig, domain, customDatesFilterTypeMap, activeTab } =
    useShallowEqualSelector((state) => state.layout);

  const { pageOriented } = dateFiltersConfig ?? {};

  const dateTypeValue = useMemo(() => {
    // user selected value from list is most actual
    const userValue = customDatesFilterTypeMap[name];
    // default selected preset value from site config next
    const defaultValue = defaultValues && defaultValues[0];

    const value = userValue ?? defaultValue ?? null;

    // if there is a restriction on pages use only allowed values
    const restricted = (pageOriented ?? []).find(
      (page) =>
        page.pageUuids?.includes(activeTab.uuid) ||
        page.pageUuid === activeTab.uuid
    );

    if (restricted?.dateTypes) {
      return restricted.dateTypes.find((type) => type === value);
    }

    return value;
  }, [
    activeTab.uuid,
    customDatesFilterTypeMap,
    defaultValues,
    name,
    pageOriented,
  ]);

  const [dateType, setDateType] = useState({
    value: dateTypeValue,
  });

  useEffect(() => {
    setDateType({ value: dateTypeValue });
  }, [activeTab.uuid, dateTypeValue]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (dateType) {
      dispatch(saveSelectedDatePresets({ [name]: dateType.value }));
    }
  }, [dateType, dispatch, name]);

  function getHasParameterProperty(type) {
    return customFilterOptionsByValue[type.value]?.hasParameter;
  }

  function recentMonthsChange(option) {
    const { from, to } = option;
    const filters = buildFilters(from, to, name);
    select(filters, type);
  }

  function setSelectedDateType(dateType) {
    setDateType(dateType);
    const hasParameter = getHasParameterProperty(dateType);

    // if has not additional parameter its mean that we selecting regular dates
    // YTD, MTD, QTD and etc.
    if (!hasParameter) {
      if (!dateType.value) {
        return select([{ key: name }], type); // handle "None..." option
      }

      const { from, to } = setDateFilters(domain, dateType.value);
      const filters = buildFilters(from, to, name);
      select(filters, type);
    }
  }

  function setCustomDateRange(from, to) {
    const filters = buildFilters(from, to, name);
    select(filters, type);
  }

  return (
    <div>
      <ToDateFilters
        setSelectedDateType={setSelectedDateType}
        selectedDateType={dateType}
        recentMonthsChange={recentMonthsChange}
        dateFiltersConfig={dateFiltersConfig}
        domain={domain}
        dateConfig={{ setCustomDateRange }}
        dropDateGroup
      />
    </div>
  );
}

import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorageRemovebleKeys.forEach((key) => {
      localStorage.removeItem(key);
    });

    dispatch(actions.logout());
  }, [dispatch]);

  return <Redirect to="/login" />;
}

const localStorageRemovebleKeys = [
  "user",
  "myPermissions",
  "pusherTransportTLS",
];

import styled from "@emotion/styled";

import Portal from "../Portal/Portal";
import TipperBubble from "./TipperBubble";

const TipperOverlay = styled.div(`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
`);

export default function TipperTip({
  showTooltip,
  label,
  noBg,
  elementClientRect,
  ...rest
}) {
  if (!showTooltip) {
    return null;
  }

  return (
    <Portal>
      <TipperOverlay role="tooltip" data-cy="tipper-overlay">
        <TipperBubble
          label={label}
          elementClientRect={elementClientRect}
          noBg={noBg}
          data-cy={rest.cy || rest["data-cy"]}
          {...rest}
        />
      </TipperOverlay>
    </Portal>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import useLabel from "../Form/LabelWrapper/useLabel";

const Label = styled.label(
  ({ theme, size = 21, offset = 7 }) => `
    display: block;
    position: relative;
    padding-left: 34px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.9em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: ${theme.text.secondary};

    & > label {
        margin-top: 2px;
        display: block;
        cursor: pointer;
    }

    & > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    & > span {
        position: absolute;
        top: 0;
        left: 0;
        height: ${size}px;
        width: ${size}px;
        background: ${theme.background.secondary};
        border: 1px solid ${theme.divider};
        border-radius: 50%;
    }

    &:hover input ~ span {
        background: ${theme.background.primary};
    }

    & > input:checked ~ span {
        background: ${
          theme.menuPrimary
            ? lightenDarkenColor(theme.primary, 50)
            : theme.primary || theme.buttons.selected
        };
    }

    & > span:after {
        content: "";
        position: absolute;
        display: none;
    }

    & > input:checked ~ span:after {
        display: block;
    }

    & > span:after {
        top: ${offset}px;
        left: ${offset}px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: ${theme.background.primary};
    }
`
);

export default function RadioButton({
  cy,
  label,
  required,
  name,
  size,
  offset,
  ...rest
}) {
  const withLabel = useLabel(label, required);
  const el = (
    <Label size={size} offset={offset}>
      <label onClick={rest.onChange ? rest.onChange : () => null}>{name}</label>
      <input type="radio" {...rest} data-cy={cy} />
      <span />
    </Label>
  );

  return withLabel(el);
}

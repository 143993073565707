import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ColumnsList = styled.ul(
  ({ paddingLeft = 0 }) => `
  margin: 0;
  padding-left: ${paddingLeft};
  font-size: 0.75rem;
  list-style: none;
`
);

export const ColumnListItem = styled.li`
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const EyeIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;

  &.svg-inline--fa {
    width: 1.25rem;
  }
`;

import { format } from "date-fns";
import { absoluteDate } from "../../../utils/dates/dateFunc";
import { rangeAndAggregationOptionsByValue } from "../../../utils/constants/constants";

export const getStartEndDate = (
  key,
  customKey,
  dateFiltersConfig,
  dateFilters,
  isFilterModeCustom,
  dualPage,
  dualType
  // customRangeDate
) => {
  const startDate = getDate("start");
  const endDate = getDate("end");
  return { startDate, endDate };
  function getDate(k) {
    const displayFormat =
      rangeAndAggregationOptionsByValue[dateFiltersConfig.rangeSettingsTerm]
        ?.displayFormat;

    if (key.filterMode === "rangeOnly") {
      return customKey[k].value;
    }

    if (!dateFilters[k]) {
      return null;
    }

    const customOrRange = !isFilterModeCustom || (!dualPage && dualType);

    if (dateFiltersConfig && customOrRange) {
      return format(absoluteDate(dateFilters[k].value), displayFormat);
    }

    // This should no longer happen here
    // return customRangeDate[customKey];
  }
};

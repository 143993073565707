import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Container = styled.div`
  cursor: pointer;
  path {
    fill: ${(props) =>
      props.bw ? props.theme.text.secondary : props.theme.primary};
  }
  &:hover {
    path {
      fill: ${(props) =>
        props.bw
          ? props.theme.text.primary
          : props.theme.notification.errorMain};
    }
  }
`;

export default function RemoveButton(props) {
  const { onClick, style, bw, light } = props;
  return (
    <Container bw={bw} style={style} onClick={onClick}>
      <FontAwesomeIcon icon={[light ? "fal" : "far", "times-circle"]} />
    </Container>
  );
}

RemoveButton.defaultProps = {
  style: {},
};

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  bw: PropTypes.bool,
  light: PropTypes.bool,
};

import React from "react";
import { useSpring, animated } from "react-spring";

export default function StaggeredOpacitySpring({
  value,
  children,
  i,
  drawZeros,
}) {
  const aniProps = useSpring({
    opacity: value === "--" ? (drawZeros ? 1 : 0.2) : 1,
    delay: i * 150,
  });

  return <animated.div style={aniProps}>{children}</animated.div>;
}

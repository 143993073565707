import React from "react";
import styled from "@emotion/styled";
import CellValue from "./CellValue";

const Cell = styled.div`
  padding: 6px 6px;
  box-sizing: border-box;
  width: ${(props) => props.width || 10}px;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-align: ${(props) => props.align || "inherit"};
  background: ${(props) => props.theme.background.primary};
  ${(props) =>
    props.active
      ? `
        border-left: 2px solid ${props.theme.notification.infoMain};
        border-right: 2px solid ${props.theme.notification.infoMain};
    `
      : null}
`;

export default function NavigationCell(props) {
  const { cellWidth, d, column, active } = props;

  const aCellData = new CellValue(d, column);

  return (
    <Cell
      data-cy="nav-td"
      width={cellWidth}
      active={active}
      align={aCellData.align()}
    >
      {aCellData.display()}
    </Cell>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switcher from "../../../../../UI/Switcher/Switcher";
import Select from "../../../../../UI/Form/Select/Select";
import Input from "../../../../../UI/Form/Input/Input";

export default function LoadMore({
  chart,
  setChartState,
  isParameterized,
  Note,
  parameters = [],
}) {
  const { loadMoreSettings } = chart ?? {};
  const { parameter, rows } = loadMoreSettings ?? {};

  function switchLoadMoreSettings() {
    setChartState({
      ...chart,
      loadMoreSettings: loadMoreSettings
        ? null
        : { parameter: null, rows: 200 },
    });
  }

  function changeLoadMoreInnerParameters(key, value) {
    setChartState &&
      setChartState({
        ...chart,
        loadMoreSettings: { ...loadMoreSettings, [key]: value },
      });
  }

  return (
    <div>
      <Switcher
        rightLabel="Load More"
        checked={!!loadMoreSettings}
        handleSwitchChange={switchLoadMoreSettings}
        disabled={!isParameterized}
        cy="load-more-switcher"
      />

      {!isParameterized && Note ? (
        <Note color="warningDark" data-cy="load-more-note">
          For parameterized queries only
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
        </Note>
      ) : null}

      {loadMoreSettings && (
        <>
          <br />
          <Select
            options={parameters}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            onChange={(option) =>
              changeLoadMoreInnerParameters("parameter", option.name)
            }
            simpleValue={parameter}
            label="Parameter"
            cy="load-more-parameter-select"
          />
          <br />
          <Input
            value={rows ?? ""}
            onChange={(e) =>
              changeLoadMoreInnerParameters("rows", e.target.value)
            }
            label="Rows"
            pattern="[0-9]{10}"
            cy="load-more-rows-input"
          />
        </>
      )}

      <br />
    </div>
  );
}

import React from "react";
import ButtonOutline from "../ButtonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { themeColor, themeGrayIf } from "../../../styles/styledSnippets";

const Container = styled.div`
  display: flex;
  position: relative;
  & > div:first-of-type {
    width: 60px;
    margin-right: 12px;
    background: ${themeGrayIf(700, 300)};
    ${(props) =>
      props.active
        ? `background: ${themeColor("infoMain")(props)}; color: white;`
        : ""}
    font-size: 24px;
    height: 50px;
    padding-top: 8px;
    position: absolute;
    left: -12px;
    top: -10px;
    svg {
      margin-left: 18px;
    }
  }
  & > div:nth-of-type(2) {
    padding-left: 60px;
  }
`;
export default function ViewModeButton(props) {
  const { title, description, icon, active, setMode } = props;
  return (
    <ButtonOutline
      style={{ boxSizing: "border-box" }}
      onClick={setMode}
      cy={`mode-${title.toLowerCase()}`}
      active={!!active}
    >
      <Container active={active}>
        <div>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div>
          {title} <br />
          <span style={{ fontWeight: 300 }}>{description}</span>
        </div>
      </Container>
    </ButtonOutline>
  );
}

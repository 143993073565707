import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import Tipper from "../Tipper/Tipper";
import PropTypes from "prop-types";

const InfoSpan = styled.span`
  font-size: ${(props) => props.size || 14}px;
  margin-left: 4px;
  cursor: pointer;
  position: relative;
  top: -7px;
  right: -2px;
  path {
    fill: ${({ theme }) =>
      theme.gray[`gray${theme.type === "dark" ? 600 : 400}`]};
  }
`;

export default function Info(props) {
  return (
    <Tipper label={props.label}>
      <InfoSpan size={props.size}>
        <FontAwesomeIcon icon={["fas", "info-circle"]} />
      </InfoSpan>
    </Tipper>
  );
}

Info.propTypes = {
  label: PropTypes.string,
  size: PropTypes.number,
};

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  position: absolute;
  top: 20px;
  display: flex;
`;

const Type = styled.div(
  ({ theme, isActive }) => `
  width: 20px;
  height: 20px;
  margin-right: 18px;
  background: ${isActive ? theme.primary : theme.divider};
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
`
);

export default function ColumnFiltering({ filterApi = {} }) {
  const { setActiveFilterType, isActiveType } = filterApi;

  return (
    <Container>
      {filterApi.filterTypes?.map((filterType) => (
        <Type
          key={filterType.name}
          onClick={() => setActiveFilterType(filterType)}
          isActive={isActiveType(filterType)}
        >
          <FontAwesomeIcon icon={filterType.icon} />
        </Type>
      ))}
    </Container>
  );
}

import styled from "@emotion/styled";
import FilterPanel from "../../../../Editors/BlockEdit/VisualizationGuiEditSection/FilterPanel";
import convertFiltersToOperatorsMode, {
  convertFiltersToConfigFormat,
} from "../../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import { useFilters } from "../../../DataManagement/DataExplorer/hooks/useExplorerFilters/useFilters";
import shouldRunQuery from "../../../DataManagement/DataExplorer/hooks/useExplorerFilters/shouldRunQuery";
import usePrevious from "../../../../utils/usePrevious";
import { useEffect } from "react";
import { clearDrilldownsFromFilters } from "../../utils/tableEditorHelper";

const Container = styled.div`
  padding: 10px;
`;

const settings = { filters: [] };

export default function Filtering(props) {
  const { chart, setChartState } = props;
  const filters = chart.filters ?? [];

  const realFilters = clearDrilldownsFromFilters(filters);
  const converted = convertFiltersToOperatorsMode(realFilters);

  const filterApi = useFilters(converted);
  const draftFilters = filterApi.draftFilters;

  const muted = filterApi.muted;

  const draftFiltersNotMuted = draftFilters.filter(
    (_, index) => !muted.includes(index)
  );

  const prevFilters = usePrevious(draftFiltersNotMuted);

  const run = shouldRunQuery(
    settings,
    settings,
    chart.queryId,
    draftFiltersNotMuted,
    prevFilters
  );

  useEffect(() => {
    if (run) {
      const filters = convertFiltersToConfigFormat(draftFiltersNotMuted);
      setChartState({ ...chart, filters });
    }
  }, [draftFilters, setChartState, run, chart, draftFiltersNotMuted]);

  return (
    <Container>
      <FilterPanel filterApi={filterApi} queryId={chart.queryId} />
    </Container>
  );
}

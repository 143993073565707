import { SELECT_ALL_FILTERS } from "../../utils/constants/constants";
import FilterItem from "./FilterItem";

export default function GeneralSelectAll(props) {
  const { select, type, filters, filterUuid, additionalTheme } = props;
  const { selectAllIncluded = {} } = additionalTheme ?? {};
  const isAllFiltersSelected = filters.every((filter) => filter.checked);

  if (!selectAllIncluded[filterUuid]) {
    return null;
  }

  function selectAll() {
    const selected = filters.map((filter) => ({
      ...filter,
      checked: !isAllFiltersSelected,
    }));

    select(selected, SELECT_ALL_FILTERS);
  }

  return (
    <FilterItem
      name="Select All"
      checked={isAllFiltersSelected ? "checked" : ""}
      onChange={selectAll}
      cy="filter-menu-input-select-all"
      type={type}
    />
  );
}

import styled from "@emotion/styled";

import Input from "../../../../UI/Form/Input/Input";
import Select from "../../../../UI/Form/Select/Select";
import Switcher from "../../../../UI/Switcher/Switcher";
import Divider from "./Divider";
import PinnedRows from "./PinnedRows/PinnedRows";
import { ExcludeFromDrilldown } from "./ExcludeFromDrilldown";

export const FieldWrapper = styled.div`
  margin-left: 1.25rem;
`;

export default function GlobalSettings({
  chart,
  setChartState,
  theme,
  filters,
  immutableChart,
  fields,
  previewChart,
}) {
  const {
    ignoreMenuFilters,
    hideDetailLink,
    pinnedRows,
    overrides,
    limitPercentAmount,
    showHideColumns,
    stickyHeaders,
    ignoreDateTerm,
    hideSubTitleHeaders,
    isSortable,
    isGroupingSortable,
  } = chart;

  return (
    <div>
      <Switcher
        rightLabel="Ignore Global Filters"
        checked={!!ignoreMenuFilters}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            ignoreMenuFilters: !chart.ignoreMenuFilters
              ? immutableChart.ignoreMenuFilters || []
              : null,
          })
        }
      />
      <br />
      {ignoreMenuFilters && (
        <FieldWrapper>
          <Select
            options={filters}
            getOptionLabel={(option) =>
              option.displayName + " (" + option.name + ")"
            }
            getOptionValue={(option) => option.name}
            value={filters.filter((f) => ignoreMenuFilters?.includes(f.name))}
            onChange={(o) =>
              setChartState({
                ...chart,
                ignoreMenuFilters: o?.map((o) => o.name),
              })
            }
            isMulti
          />
          <br />
          <hr color={theme.background.secondary} />
        </FieldWrapper>
      )}

      <br />
      <Switcher
        rightLabel="Hide Detail Link"
        checked={!!hideDetailLink}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            hideDetailLink: !hideDetailLink,
          })
        }
      />

      <br />

      <Switcher
        rightLabel="Show/Hide columns"
        checked={!!showHideColumns}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            showHideColumns: !showHideColumns,
          })
        }
      />

      <br />

      <Switcher
        rightLabel="Fixed Height"
        checked={!!stickyHeaders}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            stickyHeaders: !stickyHeaders,
          })
        }
      />

      <br />
      <Switcher
        rightLabel="Hide Header Row"
        checked={!!hideSubTitleHeaders}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            hideSubTitleHeaders: !hideSubTitleHeaders,
          })
        }
      />

      <br />

      <Switcher
        rightLabel="Column Sorting"
        checked={!!isSortable}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            isSortable: !isSortable,
          })
        }
      />

      <br />

      <Switcher
        rightLabel="Group Sorting"
        checked={!!isGroupingSortable}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            isGroupingSortable: !isGroupingSortable,
          })
        }
      />

      <br />

      <ExcludeFromDrilldown
        setChartState={setChartState}
        chart={chart}
        fields={fields}
        data={previewChart?.data ?? []}
      />

      <br />

      <PinnedRows
        overrides={overrides}
        setChartState={setChartState}
        pinnedRows={pinnedRows}
        theme={theme}
        chart={chart}
      />

      <br />

      <Switcher
        rightLabel="Simplify Large Percents"
        checked={!!limitPercentAmount}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            limitPercentAmount: limitPercentAmount ? null : "999+%",
          })
        }
      />
      <br />
      {limitPercentAmount && (
        <FieldWrapper>
          <Input
            value={limitPercentAmount}
            label="Limit Percent Amount"
            onChange={(e) =>
              setChartState({
                ...chart,
                limitPercentAmount: e.target.value,
              })
            }
          />
        </FieldWrapper>
      )}

      <br />
      <Divider chart={chart} setChartState={setChartState} />
      <br />
      <Switcher
        rightLabel="Ignore Date Term"
        checked={!!ignoreDateTerm}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            ignoreDateTerm: !ignoreDateTerm,
          })
        }
      />
      <br />
      <hr color={theme.background.secondary} />
      <br />
    </div>
  );
}

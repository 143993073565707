import * as actionTypes from "../actionTypes";
import * as actions from "../index";
import axios from "../../../axios";
import prepareSettings from "./prepareSettings";
import { normalizeError } from "../../../utils/errorHandling";

export function loadQueryExecSettings() {
  return {
    type: actionTypes.GET_QUERY_EXEC_SETTINGS_START,
    meta: {
      api: {
        public: true,
        method: "GET",
        endpoint: "/api/v1/query-exec-settings",
      },
    },
  };
}

export const saveQueryExec =
  (name, queryUuid, draftSettings, settingsUuid, callback) =>
  async (dispatch) => {
    const settings = prepareSettings(draftSettings);
    const payload = { name, queryUuid, settings };
    const updateSuffix = settingsUuid ? `/${settingsUuid}` : "";
    const method = settingsUuid ? "put" : "post";
    const type = settingsUuid
      ? actionTypes.UPDATE_QUERY_EXEC_START
      : actionTypes.SAVE_QUERY_EXEC_START;

    try {
      const res = await dispatch({
        type,
        meta: {
          api: {
            endpoint: "api/v1/query-exec-settings" + updateSuffix,
            method,
            payload,
            callback,
          },
          toasts: [
            {
              type: "success",
              title: "Way to go!",
              message: "Your explorer settings have been saved",
              condition: actionTypes.SAVE_QUERY_EXEC_SUCCESS,
            },
            {
              type: "danger",
              title: "Uh oh...",
              message: "Your explorer settings could not be saved",
              condition: actionTypes.SAVE_QUERY_EXEC_FAIL,
            },
            {
              type: "success",
              title: "Way to go!",
              message: "Your explorer settings have been updated",
              condition: actionTypes.SAVE_QUERY_EXEC_SUCCESS,
            },
            {
              type: "danger",
              title: "Uh oh...",
              message: "Your explorer settings could not be updated",
              condition: actionTypes.SAVE_QUERY_EXEC_FAIL,
            },
          ],
        },
      }).unwrap();
      const uuid = res?.data?.data?.uuid;
      return callback(uuid);
    } catch (e) {
      // eslint-disable-next-line
      console.log("error", e);
    }
  };

export const deleteQueryExec = (uuid) => {
  return {
    type: actionTypes.DELETE_QUERY_EXEC_START,
    uuid,
    meta: {
      api: {
        endpoint: `api/v1/query-exec-settings/${uuid}`,
        method: "delete",
      },
    },
    toasts: [
      {
        type: "success",
        title: "Bye Bye!",
        message: "Your explorer setting has been removed",
        condition: actionTypes.SAVE_QUERY_EXEC_SUCCESS,
      },
    ],
  };
};

export function resetQueryExecSettings() {
  return {
    type: actionTypes.GET_QUERY_EXEC_SETTINGS_RESET,
  };
}

export const initFromSettings =
  (settingsId, applySettings) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_QUERY_EXEC_SETTINGS_START });

    const result = await axios
      .get("/api/v1/query-exec-settings/" + settingsId)
      .catch((e) => {
        const normalizedError = normalizeError(e);
        dispatch({
          type: actionTypes.GET_QUERY_EXEC_SETTINGS_FAIL,
          errors: normalizedError,
        });
        throw e;
      });
    applyInFilterApi();
    sendDispatchSequence(result.data);

    function applyInFilterApi() {
      applySettings(result.data.data);
    }

    function sendDispatchSequence(result) {
      dispatch({
        type: actionTypes.GET_QUERY_EXEC_SETTINGS_SUCCESS,
        results: result,
      });
      dispatch({
        type: actionTypes.SET_ACTIVE_EXEC_SETTINGS,
        result: result.data,
        queryUuid: result.data.queryUuid,
      });
      dispatch(actions.getQuery(result.data.queryUuid));
    }
  };

function setExplorerMode(type, dispatch) {
  dispatch({ type: actionTypes.SET_EXPLORER_MODE, mode: type });
}

export const clearExplorer = () => ({ type: actionTypes.CLEAR_EXPLORER_MODE });

export const initTable =
  (type, queryId, settingsId, applySettings) => (dispatch) => {
    setExplorerMode(type, dispatch);
    if (type === "new") {
      return getQuery(queryId, dispatch).then((fields) => {
        applySettings({ settings: { overrides: { fields } } });
      });
    } else {
      return dispatch(initFromSettings(settingsId, applySettings));
    }
  };

async function getQuery(queryId, dispatch) {
  const queryFields = await runGetQuery(queryId, dispatch);
  return queryFields.map(stripUnusedSettings);
}

async function runGetQuery(queryId, dispatch) {
  dispatch({ type: actionTypes.GET_QUERY_START });

  try {
    const result = await axios.get(`/api/v1/queries/${queryId}`);
    dispatch({ type: actionTypes.GET_QUERY_SUCCESS, results: result.data });

    return result.data.data.dataSources[0].fields;
  } catch (e) {
    const normalizedError = normalizeError(e);
    dispatch({ type: actionTypes.GET_QUERY_FAIL, error: normalizedError });
    throw e;
  }
}

function stripUnusedSettings(field) {
  const { type, displayName } = field?.mapping || {};

  return {
    name: field.name,
    mapping: { type, displayName },
  };
}

import { useState } from "react";
import {
  getConditionKeyValueOption,
  getConditionKeyOptions,
  getConditionValueOptions,
  getUniqueFormatOverridePairs,
  getUpdatedPairState,
  updateConditionKeyValue,
  filterFormatOverrideGroup,
  getGroupedFormatOverrides,
} from "../../utils/tableEditorHelper";
import { isEmpty } from "lodash-es";

export default function useFormatOverrides(props) {
  const { chart, previewChart, setChartState } = props;
  const { formatOverrides } = chart;

  const data = previewChart?.data ?? [];
  const dataItem = data[0] ?? {};

  const emptyPair = {
    conditionKey: "",
    conditionValue: "",
    value: null,
    label: "...|...",
  };

  const pairs = getUniqueFormatOverridePairs(formatOverrides ?? []);
  const [pair, setPair] = useState(pairs[0] ?? {});

  const indexedFormatOverrides = (formatOverrides ?? []).map(
    (override, index) => ({ ...override, index })
  );

  const groupedFormatOverrides = getGroupedFormatOverrides(
    indexedFormatOverrides,
    pair
  );

  const conditionKeyOptions = getConditionKeyOptions(dataItem);
  const conditionKeyOption = getConditionKeyValueOption(
    conditionKeyOptions,
    pair,
    "conditionKey"
  );

  const conditionValueOptions = getConditionValueOptions(data, pair);
  const conditionValueOption = getConditionKeyValueOption(
    conditionValueOptions,
    pair,
    "conditionValue"
  );

  const disabled = isEmpty(pair);

  function enableDisableFormatOverrides() {
    setChartState({
      ...chart,
      formatOverrides: formatOverrides ? null : [],
    });
    setPair({});
  }

  function onConditionChange(option, property) {
    const newFormatOverrides = formatOverrides.map((override) =>
      updateConditionKeyValue(override, option, pair, property)
    );

    setChartState({
      ...chart,
      formatOverrides: newFormatOverrides,
    });

    const pairState = getUpdatedPairState(newFormatOverrides, {
      [property]: option.value,
      ...(property === "conditionKey"
        ? { conditionValue: pair.conditionValue }
        : { conditionKey: pair.conditionKey }),
    });

    setPair(pairState);
  }

  function onOverrideItemChange(option, property, index) {
    setChartState({
      ...chart,
      formatOverrides: formatOverrides.map((override, i) =>
        i === index ? { ...override, [property]: option.value } : override
      ),
    });
  }

  function onOverrideItemRemove(index) {
    setChartState({
      ...chart,
      formatOverrides: formatOverrides.filter((_, i) => i !== index),
    });
  }

  function onOverrideItemAdd() {
    setChartState({
      ...chart,
      formatOverrides: [
        ...formatOverrides,
        {
          conditionKey: pair.conditionKey,
          conditionValue: pair.conditionValue,
        },
      ],
    });
  }

  function addNewGroupOnEmptyOverrides() {
    setChartState({
      ...chart,
      formatOverrides: [emptyPair],
    });
  }

  function cloneFormatOverrideGroup() {
    if (disabled) {
      addNewGroupOnEmptyOverrides();
    } else {
      setChartState({
        ...chart,
        formatOverrides: [
          ...formatOverrides,
          ...groupedFormatOverrides.map((override) => ({
            ...override,
            ...emptyPair,
          })),
        ],
      });
    }

    setPair({ ...pair, ...emptyPair });
  }

  function removeFormatOverrideGroup() {
    const newFormatOverrides = formatOverrides.filter((override) =>
      filterFormatOverrideGroup(override, pair)
    );

    const pairs = getUniqueFormatOverridePairs(newFormatOverrides ?? []);

    setChartState({
      ...chart,
      formatOverrides: newFormatOverrides,
    });
    setPair(pairs[0] ?? {});
  }

  return {
    properties: {
      pairs,
      pair,
      conditionKeyOptions,
      conditionKeyOption,
      conditionValueOptions,
      conditionValueOption,
      formatOverrides,
      groupedFormatOverrides,
      disabled,
    },
    callbacks: {
      setPair,
      enableDisableFormatOverrides,
      onConditionChange,
      onOverrideItemChange,
      onOverrideItemRemove,
      onOverrideItemAdd,
      removeFormatOverrideGroup,
      cloneFormatOverrideGroup,
    },
  };
}

import styled from "@emotion/styled";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Order from "./Order";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  hr {
    width: 100%;
  }
  & > span {
    display: flex;
    justify-content: space-between;
  }
  & > span > svg {
    margin-left: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
  }
`;

const order = { sort: "ASC", name: "name" };

export default function Orders({ setChartState, chart, theme, fields }) {
  const { orders } = chart;
  const isEmpty = !orders?.length;

  function addNewOrder() {
    setChartState({
      ...chart,
      orders: [...(chart.orders || []), order],
    });
  }

  const changeOrderColumn = useCallback(
    (option, index, field) => {
      setChartState({
        ...chart,
        orders: isEmpty
          ? [{ ...order, [field]: option.name }] // always keep orders open
          : orders.map((order, i) =>
              i === index ? { ...order, [field]: option.name } : order
            ),
      });
    },
    [setChartState, chart, isEmpty, orders]
  );

  return (
    <Container>
      <span>
        Order By
        <FontAwesomeIcon icon={["fas", "plus"]} onClick={addNewOrder} />
      </span>
      <br />
      {(!isEmpty ? orders : [order]).map((order, index) => (
        <Order
          fields={fields}
          changeOrderColumn={changeOrderColumn}
          order={order}
          index={index}
          setChartState={setChartState}
          chart={chart}
          key={order.name}
        />
      ))}
      <hr color={theme.background.secondary} />
    </Container>
  );
}

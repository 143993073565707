import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postMessageFromIframe } from "../functions/dynamicDrillDown";

const Container = styled.span`
  margin-left: 30px;
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

export default function ZoomInDrilldown({
  i,
  setRowData,
  row,
  attachedURLParameter,
}) {
  if (!row) {
    return null;
  }

  const values = row.values[0];

  const onZoomClick = (e) => {
    e.stopPropagation();
    setRowData(row.values[0]);

    if (attachedURLParameter) {
      postMessageFromIframe(attachedURLParameter, values[attachedURLParameter]);
    }
  };

  if (i === 0 && row?.values) {
    return (
      <Container data-cy="zoom-in-drilldown-container">
        <FontAwesomeIcon
          icon={["far", "search-plus"]}
          onClick={onZoomClick}
          data-cy="zoom-in-drilldown-icon"
        />
      </Container>
    );
  }

  return null;
}

import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../../UI/Form/Input/Input";
import { useSelector } from "react-redux";
import Select from "../../../UI/Form/Select/Select";
import IOButton from "../../../UI/Form/Button/IOButton";
import { filterTableColumns } from "../../../store/actions/dataSettings/blueprintHelpers";

export default function CreateBlueprint(props) {
  const { dwTables, blueprints } = useSelector((state) => state.dataSetup);
  const allTables = dwTables.map((d) => ({ label: d.name, value: d.name }));
  const [name, setName] = useState("");
  const [table, setTable] = useState(null);
  const [field, setField] = useState(null);

  const allStringFields = useMemo(() => {
    if (table?.value) {
      return filterTableColumns(dwTables, table.value);
    }

    return [];
  }, [dwTables, table?.value]);

  function handleSave() {
    props.createBlueprint(name, table?.value, field?.value);
  }

  const sameName = (blueprints ?? []).some(
    (blueprint) => name.toLowerCase() === blueprint.name.toLowerCase()
  );

  function validateNewBlueprint() {
    return !name || !field || !table || sameName;
  }

  const isDisabled = validateNewBlueprint();

  return (
    <div>
      <div style={{ maxWidth: 400 }}>
        <div>
          <Input
            onChange={(e) => setName(e.target.value)}
            value={name}
            label="Blueprint name"
            invalid={sameName}
            errorMessage={
              sameName && "A Blueprint with the same name already exists."
            }
          />
        </div>
        <div>
          Need fancy instruction animal: Select at least one table this will be
          applied to.
        </div>
        <div style={{ marginTop: 24 }}>
          <Select
            onChange={setTable}
            value={table}
            options={allTables}
            label="Table"
          />
        </div>
        {table ? (
          <div style={{ marginTop: 24 }}>
            <div>Field</div>
            <Select
              value={field}
              onChange={setField}
              options={allStringFields}
              label="Column"
            />
          </div>
        ) : null}
        <div style={{ marginTop: 24 }}>
          Note: You can add and change tables at any time.
        </div>
        <div style={{ marginTop: 12 }}>
          <IOButton disabled={isDisabled} onClick={handleSave}>
            Save Blueprint
          </IOButton>
        </div>
      </div>
      <div style={{ marginTop: 36 }}>
        <Link to={"/admin/data-blueprint"}>Return to Map</Link>
      </div>
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Toast from "./Toast";
import EnterToast from "./EnterToast";

const ToastContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 40px;
  z-index: 2000000;
`;

export default function ToastManager(props) {
  const items = props.toasts.map((t, i) => (
    <Toast handleDismiss={props.handleDismiss} key={i} {...t} />
  ));

  return (
    <ToastContainer>
      <EnterToast items={items} />
    </ToastContainer>
  );
}

ToastManager.defaultProps = {
  toasts: [],
};

ToastManager.propTypes = {
  toasts: PropTypes.array,
};

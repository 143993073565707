import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tipper from "../Tipper/Tipper";
import { useState } from "react";
import FilterEditorBox from "../../Pages/DataManagement/DataExplorer/Filters/FilterEditorBox";

const Container = styled.div`
  font-size: 16px;
  margin: 0px 10px;
  &:hover {
    cursor: pointer;
  }
`;

export default function AggregationButton({
  isParameterized,
  valueType,
  fieldOptions,
  index,
  filterApi,
  filter,
}) {
  const [aggregating, setAggregating] = useState(filter.subQueryValues);
  const numbers = ["integer", "number", "decimal", "float"];

  if (isParameterized || !numbers.includes(valueType)) {
    return null;
  }

  function aggregate() {
    setAggregating(!aggregating);
    filterApi.setAggregating(index);
  }

  return (
    <div style={{ position: "relative" }}>
      <Tipper
        label={
          aggregating ? "Apply before aggregation" : "Apply after aggregation"
        }
      >
        <Container onClick={aggregate}>
          <FontAwesomeIcon icon={[aggregating ? "fad" : "fal", "sigma"]} />
        </Container>
      </Tipper>

      {aggregating && (
        <div style={{ position: "absolute", top: 30, left: 335 }}>
          <FilterEditorBox
            i={index}
            value={filter?.name || ""}
            handleSetField={(i, value) =>
              filterApi.setAggregatedGroupBy(i, value)
            }
            options={fieldOptions}
            placeholder="Set group by field..."
            cy="filter-group-by-field-selector"
          />
        </div>
      )}
    </div>
  );
}

import React from "react";
import Flex from "../../../UI/Flex/Flex";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NodeContainer } from "./NodeContainer";
import BlueprintInputDelete from "./BlueprintInputDelete";

const Arrow = styled.div`
  margin: 0 8px;
`;
export default function BlueprintInput(props) {
  const {
    table,
    field,
    onClick,
    onBlueprintChainDelete,
    chainUuid,
    deletingInput,
  } = props;

  return (
    <Flex alignItems="baseline">
      <BlueprintInputDelete
        onBlueprintChainDelete={onBlueprintChainDelete}
        chainUuid={chainUuid}
        deletingInput={deletingInput}
      />
      <Flex
        onClick={onClick}
        center
        data-cy="ruleset-input"
        style={{ marginBottom: 12 }}
      >
        <NodeContainer data-cy="input-table">
          <FontAwesomeIcon icon={["fal", "table"]} />
          {table}
        </NodeContainer>
        <Arrow>
          <FontAwesomeIcon icon={["fal", "arrow-right"]} />
        </Arrow>
        <NodeContainer data-cy="input-field">
          {" "}
          <FontAwesomeIcon icon={["fal", "tablet"]} />
          {field}
        </NodeContainer>
        <Arrow>
          <FontAwesomeIcon icon={["fal", "arrow-right"]} />
        </Arrow>
      </Flex>
    </Flex>
  );
}

import React, { useState } from "react";
import Input from "../../../UI/Form/Input/Input";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import Select from "../../../UI/Form/Select/Select";

export default function ActiveTableViewSettings(props) {
  const {
    name,
    setName,
    setDefaultViewFlag,
    displaySettings,
    views,
    setNextView,
    setNextColumns,
    setFilters,
  } = props;

  const { isDefaultView } = displaySettings;
  const [view, setView] = useState(null);

  function setViewFromTemplate(view) {
    const {
      displaySettings,
      filters,
      name: nameOverride,
      visibleFields,
    } = view ?? {};

    setView(view);
    setNextView({ displaySettings: displaySettings ?? null });
    setName(name || nameOverride || "");
    setNextColumns(visibleFields ?? []);
    setFilters(filters);
  }

  return (
    <div style={{ maxWidth: 400 }}>
      <Input
        label="View name"
        fit
        cy="view-name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <Checkbox
        label="Default View"
        style={{ marginLeft: 0 }}
        onChange={() => setDefaultViewFlag()}
        checked={!!isDefaultView}
      />
      <br />
      <Select
        options={views}
        getOptionValue={(o) => o.uuid}
        getOptionLabel={(o) => o.name}
        onChange={setViewFromTemplate}
        value={view}
        isClearable
        label="View based on settings from an existing View"
      />
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";

function color1(props) {
  return props.theme.notification.warningDark;
}
function color2(props) {
  return "#0000";
}

const Container = styled.div`
  width: 60px;
  aspect-ratio: 6;
  --_g: no-repeat radial-gradient(circle closest-side,${color1} 80%,${color2});
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: d7 1.5s infinite linear;
}
@keyframes d7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
`;

export default function ConnectingLoader(props) {
  return <Container />;
}

import styled from "@emotion/styled";
import Switcher from "../../../../UI/Switcher/Switcher";
import { RowStyleContainer } from "../General/RowStyles";
import TooltipRow from "./TooltipRow";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import {
  findFieldInOverrides,
  getReorderedTooltipRows,
} from "../../utils/tableEditorHelper";
import TooltipDependencySettings from "./TooltipDependencySettings";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";

const Container = styled.div(
  ({ theme }) => `
  overflow-y: auto;
  
  ${scrollbarDe(theme)};
  margin: 5px 0px;
  padding-top: 10px;
  height: calc(100vh - 220px);
`
);

export default function TooltipsSettings(props) {
  const { chart, setChartState, fields } = props;
  const { tooltipConfig, overrides } = chart;
  const { tooltipRows } = tooltipConfig ?? {};
  const [rows, setRows] = useState(tooltipRows);

  /**
   * Updates a specific tooltip row in the chart state.
   *
   * @param {string} key - The key to update in the tooltip row.
   * @param {any} value - The new value to set for the specified key.
   * @param {number} index - The index of the tooltip row to update.
   */
  function onTooltipRowChange(key, value, index, nonAggregatedName) {
    const newOverrides = findFieldInOverrides(overrides, nonAggregatedName)
      ? overrides
      : [...overrides, { name: nonAggregatedName }];

    setChartState({
      ...chart,
      overrides: newOverrides,
      tooltipConfig: {
        ...tooltipConfig,
        tooltipRows: tooltipRows.map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        ),
      },
    });
  }

  /**
   * Removes a specific tooltip row item from the chart state.
   *
   * @param {number} index - The index of the tooltip row to remove.
   */
  function removeTooltipRowItem(index) {
    const newRows = rows.filter((_, i) => i !== index);

    setRows(newRows);

    setChartState({
      ...chart,
      tooltipConfig: {
        ...tooltipConfig,
        tooltipRows: newRows,
      },
    });
  }

  function onDragEnd(result) {
    const reorderedRows = getReorderedTooltipRows(result, rows);
    // Update the rows state with the reordered rows
    setRows(reorderedRows);

    // Update the chart state with the new tooltip configuration
    setChartState({
      ...chart,
      tooltipConfig: {
        ...tooltipConfig,
        tooltipRows: reorderedRows,
      },
    });
  }

  function enableDisableTooltip() {
    setChartState({
      ...chart,
      tooltipConfig: tooltipConfig ? null : { tooltipRows: [] },
    });

    setRows([]);
  }

  return (
    <RowStyleContainer style={{ padding: 15 }}>
      <div>Tooltip configuration</div>
      <br />
      <Switcher
        rightLabel="Enable"
        checked={!!tooltipConfig}
        handleSwitchChange={enableDisableTooltip}
      />

      <TooltipDependencySettings
        tooltipConfig={tooltipConfig}
        setChartState={setChartState}
        chart={chart}
        fields={fields}
        rows={rows}
        setRows={setRows}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Container {...provided.droppableProps} ref={provided.innerRef}>
              {(rows ?? []).map((row, index) => (
                <Draggable
                  key={row.key}
                  draggableId={(index + "-" + row.key).toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <TooltipRow
                      row={row}
                      key={row.key + index}
                      fields={fields}
                      overrides={overrides}
                      index={index}
                      onTooltipRowChange={onTooltipRowChange}
                      removeTooltipRowItem={removeTooltipRowItem}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      <br />
    </RowStyleContainer>
  );
}

import {
  GET_DATA_DICTIONARY_RESET,
  GET_DATA_DICTIONARY_FAIL,
  GET_DATA_DICTIONARY_SUCCESS,
  GET_DATA_DICTIONARY_START,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_DICTIONARY_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case GET_DATA_DICTIONARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    }

    case GET_DATA_DICTIONARY_FAIL: {
      return {
        loading: false,
        error: action.payload,
        data: null,
      };
    }

    case GET_DATA_DICTIONARY_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}

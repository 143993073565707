import React, { useEffect, useState } from "react";
import * as actions from "../../../../store/actions/index";
import ExplorerLeftTray from "../DataExplorerUI/ExplorerLeftTray";
import SavedExplorerItem from "./SavedExplorerItem";
import styled from "@emotion/styled";
import { scrollbarConfig } from "../../../../styles/styledSnippets";
import { unique } from "../../../../utils/func";
import Select from "../../../../UI/Form/Select/Select";

const ListContainer = styled.div`
  height: calc(100vh - 140px);
  overflow: auto;
  padding-right: 24px;
  padding-top: 12px;
  ${scrollbarConfig({ thumbBorder: false, size: ".4em" })};
`;

const SelectContainer = styled.div`
  max-width: 200px;
  position: absolute;
  width: 200px;
  top: 38px;
  right: 12px;
`;
export default function SavedExplorerList(props) {
  const {
    handleClose,
    handleSelect,
    dispatch,
    data,
    loading,
    errors,
    deleting,
    authUserName = "All users",
  } = props;
  useEffect(() => {
    dispatch(actions.loadQueryExecSettings());
  }, [dispatch]);
  const [filteredUser, setFilteredUser] = useState({
    value: authUserName,
    label: authUserName,
  });
  let list = data;
  if (filteredUser.value !== "All users" && Array.isArray(list)) {
    list = list
      .filter((item) => item.createdBy)
      .filter((item) => item.createdBy.name === filteredUser.value);
  }

  const creators = Array.isArray(data)
    ? [
        "All users",
        ...unique(data.filter((v) => v.createdBy).map((v) => v.createdBy.name)),
      ].map((v) => ({ value: v, label: v }))
    : [];

  const handleClick = (uuid) => {
    handleSelect(uuid);
    handleClose();
  };

  const handleDelete = (uuid) => {
    dispatch(actions.deleteQueryExec(uuid));
  };

  const handleUserSelect = (user) => {
    setFilteredUser(user);
  };

  return (
    <ExplorerLeftTray
      cy="saved-explorer-list"
      title="Saved settings"
      loading={loading}
      handleClose={handleClose}
      list
    >
      {creators?.length > 1 ? (
        <SelectContainer>
          <Select
            options={creators}
            onChange={handleUserSelect}
            value={filteredUser}
            bgPrimary
          />
        </SelectContainer>
      ) : null}
      <ListContainer>
        {list?.length ? (
          list.map((item) => (
            <SavedExplorerItem
              onClick={() => handleClick(item.uuid)}
              handleDelete={() => handleDelete(item.uuid)}
              deleting={deleting === item.uuid}
              active={props.activeSettings?.uuid === item.uuid}
              {...item}
              key={item.uuid}
            />
          ))
        ) : (
          <div>No settings have been saved (yet)</div>
        )}
        {errors ? <div>Something went wrong</div> : null}
      </ListContainer>
    </ExplorerLeftTray>
  );
}

export default function (direction, key, fullPayload) {
  const {
    synonyms = [],
    labels = [],
    groupUnders = [],
    ignored = [],
    colors = [],
  } = fullPayload;
  const synonymKeys = synonyms ? Object.keys(synonyms) : [];

  const resolvedSynonyms = synonymKeys.reduce((acc, curr) => {
    const nextSynonyms = synonyms[curr].map((syn) => ({
      originalValue: syn.label,
      synonym: curr,
    }));
    return [...acc, ...nextSynonyms];
  }, []);

  const resolvedDisplayNames = labels.map((l) => ({
    originalValue: l.original.label,
    displayName: l.override.label,
  }));

  const resolvedGrouping = groupUnders.reduce((acc, curr) => {
    return [
      ...acc,
      ...curr.children.map((c) => ({
        originalValue: c.label,
        groupName: curr.label,
      })),
    ];
  }, []);

  const resolvedColors = colors.map(({ label, color }) => ({
    groupNameOrOriginalValue: label,
    color,
  }));

  const resolvedExcludes = ignored.map((v) => v.label);

  return {
    synonyms: resolvedSynonyms,
    displayNames: resolvedDisplayNames,
    grouping: resolvedGrouping,
    excludes: resolvedExcludes,
    colors: resolvedColors,
  };
}

import React from "react";
import BigInputItem from "./BigInputItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { themeGrayIf } from "../../styles/styledSnippets";
import { boxShadows, scrollbar } from "./snippets";
import Flex from "../Flex/Flex";
import BlueprintPagination from "../../Pages/DataManagement/Blueprint/components/BlueprintPagination";

const LeftArea = styled.div`
  border-right: 4px solid ${themeGrayIf(900, 300)};
  box-shadow: ${boxShadows}
  background: ${themeGrayIf(900, 200)};
  overflow-y: auto;
  direction: rtl;
  height: 100%;
  ${scrollbar};
`;
const LeftAreaInner = styled.div`
  direction: ltr;
`;

const swatchSize = 18;

function swatchBackground(props) {
  return props.color || themeGrayIf(800, 300)(props);
}

const Swatch = styled.div`
  height: ${swatchSize}px;
  width: ${swatchSize}px;
  background: green;
  margin-top: -6px;
  margin-left: 14px;
  border-radius: 50%;
  background: ${swatchBackground};
`;

export default function LeftAreaBlock(props) {
  const {
    thirdValues,
    folders,
    folderMode,
    leftData,
    handleLeftToRight,
    handleScroll,
    colorMode,
    colors,
    getUniqueRawValuesCallback,
    uniqueValuesTotalCount,
  } = props;

  const lockedData =
    thirdValues &&
    Object.keys(thirdValues).reduce((acc, curr) => {
      return [...acc, ...thirdValues[curr]];
    }, []);

  function isLocked(item) {
    if (folderMode && !folders.length) return true;
    if (!lockedData) return false;
    return lockedData.find((l) => l.id === item.id);
  }

  function renderItem(item, index) {
    if (colorMode) {
      const colorOverride = colors.find((c) => c.id === item.id);
      return (
        <div
          style={{ display: "flex", alignItems: "center", width: "100%" }}
          key={item.id + index}
          onClick={() => !isLocked(item) && handleLeftToRight(item)}
        >
          <Swatch data-cy="color-swatch" color={colorOverride?.color} />
          <BigInputItem data-cy="item-source-location" left colorMode>
            {item.label}
          </BigInputItem>
        </div>
      );
    } else {
      return (
        <BigInputItem
          key={item.id + index}
          locked={isLocked(item)}
          data-cy="item-source-location"
          onClick={() => !isLocked(item) && handleLeftToRight(item)}
          left
        >
          {item.label}
          {!isLocked(item) ? (
            <FontAwesomeIcon icon={["fal", "arrow-right"]} />
          ) : null}
        </BigInputItem>
      );
    }
  }

  return (
    <Flex direction="column" position="relative">
      <LeftArea data-cy="input-left-area" onScroll={handleScroll}>
        <LeftAreaInner>{leftData.map(renderItem)}</LeftAreaInner>
      </LeftArea>
      <BlueprintPagination
        onPageChange={getUniqueRawValuesCallback}
        uniqueValuesTotalCount={uniqueValuesTotalCount}
      />
    </Flex>
  );
}

import React from "react";
import DynamicSubTitles from "./DynamicSubTitles";
import StaticGroupingKeys from "./StaticGroupingKeys";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export default function RepeatingGroups({
  Section,
  setChartState,
  selectedColumn,
  setSelectedColumn,
  removeColumn,
  removeDynamicSubTitleColumn,
  setSelectedGroup,
  removeGroupingEffect,
  selectedGroup,
  subTitles,
  overrides,
  dynamicColumnsWidth,
  generalColumnsWidth,
  maxColumnsWidth,
  dynamicSubTitleKeys,
  hiddenSubTitles,
  chart,
  setNavigationState,
  isParameterized,
}) {
  // dynamicGroups: dynamic header names which comes from the BE
  const dynamicGroups = (subTitles || []).map((_, i) => alphabet[i]);

  if (dynamicSubTitleKeys) {
    return (
      <DynamicSubTitles
        overrides={overrides}
        setChartState={setChartState}
        dynamicSubTitleKeys={dynamicSubTitleKeys}
        setSelectedColumn={setSelectedColumn}
        isParameterized={isParameterized}
        hiddenSubTitles={hiddenSubTitles}
        removeGroupingEffect={removeGroupingEffect}
        selectedColumn={selectedColumn}
        chart={chart}
        setNavigationState={setNavigationState}
        removeDynamicSubTitleColumn={removeDynamicSubTitleColumn}
      />
    );
  }

  return (
    <StaticGroupingKeys
      dynamicGroups={dynamicGroups}
      setChartState={setChartState}
      Section={Section}
      subTitles={subTitles}
      removeColumn={removeColumn}
      overrides={overrides}
      selectedColumn={selectedColumn}
      setSelectedColumn={setSelectedColumn}
      dynamicColumnsWidth={dynamicColumnsWidth}
      generalColumnsWidth={generalColumnsWidth}
      maxColumnsWidth={maxColumnsWidth}
      setSelectedGroup={setSelectedGroup}
      romveColumnEffect={removeGroupingEffect}
      selectedGroup={selectedGroup}
      chart={chart}
      setNavigationState={setNavigationState}
    />
  );
}

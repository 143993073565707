import { useState } from "react";
import Input from "../../../../../UI/Form/Input/Input";
import Select from "../../../../../UI/Form/Select/Select";
import { getUpdatedOverrides } from "../helper";
import useDebounceChange from "../../../../../hooks/useDebounceChange";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";

export default function RowGroupKeyContainer(props) {
  const { chart, setChartState, removeAnyColumnType, repeatingOptions } = props;
  const { rowGroupKey, dynamicSubTitleKeys, subTitles, overrides } = chart;

  const { debounce } = useDebounceChange();

  const nonAggregatedRowGroupKey = getRidOfAggregation(
    rowGroupKey ?? "",
    overrides
  );

  const { mapping } =
    (overrides ?? []).find(
      (override) => override.name === nonAggregatedRowGroupKey
    ) ?? {};

  const [rowGroupKeyDisplayName, setRowGroupKeyDisplayName] = useState(
    mapping?.displayName ?? ""
  );

  function updateChartOverridesOnChange(newOption) {
    const overrides = removeAnyColumnType(
      chart,
      rowGroupKey || "",
      dynamicSubTitleKeys ?? subTitles,
      true
    );

    const options = newOption ? [newOption] : [];

    return getUpdatedOverrides(options, overrides, "value");
  }

  function setGroupingColumn(option) {
    setChartState({
      ...chart,
      overrides: updateChartOverridesOnChange(option),
      rowGroupKey: option?.value,
      rawRows: false,
    });
  }

  function updateOverrideDisplayName(override, displayName) {
    if (override.name === nonAggregatedRowGroupKey) {
      return {
        ...override,
        mapping: {
          ...(override.mapping ?? {}),
          displayName,
        },
      };
    }

    return override;
  }

  function isWhitespaceString(str) {
    return !str.replace(/\s/g, "").length;
  }

  function changeRowGroupKeyDisplayName(e) {
    const displayName = e.target.value;

    if (displayName && isWhitespaceString(displayName)) {
      return;
    }

    setRowGroupKeyDisplayName(displayName);

    debounce(
      () =>
        setChartState({
          ...chart,
          overrides: overrides.map((override) =>
            updateOverrideDisplayName(override, displayName)
          ),
        }),
      1000
    );
  }

  return (
    <>
      <Select
        options={repeatingOptions}
        value={repeatingOptions.find((ro) => ro.value === rowGroupKey)}
        label="Grouping column (Rows)"
        onChange={setGroupingColumn}
        isClearable
      />
      <br />
      {rowGroupKey && (
        <Input
          label="Grouping column Display name"
          value={rowGroupKeyDisplayName}
          onChange={changeRowGroupKeyDisplayName}
        />
      )}

      <br />
    </>
  );
}

import { useEffect } from "react";
import Modal from "../../../UI/Modal/Modal";
import ChartFactory from "../../../charts/ChartFactory/ChartFactory";
import { useDispatch, useSelector } from "react-redux";
import { executeQuery } from "../../../store/actions";
import styled from "@emotion/styled";
import { isEmpty } from "lodash-es";
import Loading from "../../../UI/Loading/Loading";

const Container = styled.div`
  padding: 20px;
  min-height: 400px;
`;

export default function ChartPreview({ chart, setShowPreview, showPreview }) {
  const dispatch = useDispatch();

  const previewChart = useSelector(
    (state) => state.dashboard.tableEditorPreviewChart
  );

  useEffect(() => {
    if (!isEmpty(chart) && showPreview) {
      dispatch(
        executeQuery(chart, [], null, undefined, null, true, undefined, {})
      );
    }
  }, [chart, dispatch, showPreview]);

  if (!showPreview) {
    return null;
  }

  const loading = !previewChart;

  return (
    <Modal title={"Preview"} close={() => setShowPreview(false)} fullScreen>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : isEmpty(chart) ? (
        <div>No Results</div>
      ) : (
        <Container>
          <ChartFactory chart={previewChart} type={chart.type} />
        </Container>
      )}
    </Modal>
  );
}

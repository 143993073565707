import React from "react";
import { assignAsKey, groupUnder, pipe } from "../../utils/func";
import { toDateType } from "../../utils/dates/dateFunc";
import { hasAttr } from "../LineBarChart/LineBarChartVisualization";
import YAxis from "../Axes/YAxis";
import SpringLine from "../MultipleLineChart/SpringLine";
import PositionCirclesPlaced from "../PositionCircle/PositionCirclesPlaced";

export default function StackedLinesVisualization(props) {
  const {
    lineKyes,
    circleKeys,
    initialData,
    xKey,
    dashedKeys,
    width,
    rightYAxisFormat,
    xScale,
    setStaticColor,
    bandWidth,
    setTooltip,
    meta,
    yScale,
  } = props;

  const combinedYKeys = [...lineKyes, ...circleKeys];

  if (!combinedYKeys.length) {
    return null;
  }

  function buildInitialGroups(keys) {
    return pipe(
      assignAsKey(xKey, "xValue"),
      toDateType("xValue", null, null, true),
      assignAsKey(keys[0], "value"),
      groupUnder(keys, [], xKey)
    )(initialData);
  }

  const initialLineGroups = buildInitialGroups(lineKyes);
  const initialCircleGroups = buildInitialGroups(circleKeys);

  const lineGroups = initialLineGroups.map((line) => ({
    ...line,
    curved: hasAttr(lineKyes, line.key),
    dashed: hasAttr(dashedKeys, line.key),
    values: line.values.map((v) => ({ ...v, value: +v.value })),
  }));

  const circleGroups = initialCircleGroups.map((circle) => ({
    ...circle,
    values: circle.values.map((v) => ({ ...v, value: +v.value })),
  }));

  return (
    <>
      {combinedYKeys.length > 0 && (
        <YAxis
          {...props}
          yScale={yScale}
          yTicksCount={props.yTicksCount}
          yTicksColor={props.yTicksColor}
          hideYAxisLine={props.hideYAxisLine}
          hideYAxisTicks={props.hideYAxisTicks}
          yAxisGrid={props.yAxisGrid}
          yAxisGridColor={props.yAxisGridColor}
          width={width}
          yAxisFormat={rightYAxisFormat}
          translateAxis={width + 15}
          orient="right"
        />
      )}

      {lineKyes.length > 0 &&
        lineGroups.map((section, index) => (
          <g
            data-cy="multiple-lines-line"
            key={section.key + index}
            style={{ zIndex: 1 }}
          >
            <SpringLine
              width={width}
              values={section.values}
              stroke={setStaticColor(section.key, index)}
              style={section.dashed ? { strokeDasharray: "5, 8" } : {}}
              curved={section.curved}
              strokeWidth={1}
              fill="none"
              x={xScale}
              y={yScale}
              xOffset={bandWidth + bandWidth / 3}
            />
          </g>
        ))}

      {circleKeys.length > 0 &&
        circleGroups.map((section, index) => (
          <g data-cy="circles" key={section.key + index}>
            <PositionCirclesPlaced
              data={section.values}
              markerKey="value"
              yScale={yScale}
              xScale={xScale}
              xKey="xValue"
              yKey="value"
              xLabel={xKey}
              yLabel={circleKeys[index] || circleKeys[0]}
              segment={section.key}
              color={setStaticColor(section.key, index)}
              setTooltip={setTooltip}
              borderSize={0.5}
              radius={bandWidth / 4}
              meta={meta}
              noDate
              xOffset={bandWidth + bandWidth / 4}
              skipTooltipConfig
            />
          </g>
        ))}
    </>
  );
}

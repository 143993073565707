import styled from "@emotion/styled";
import Input from "../../../../UI/Form/Input/Input";

const Container = styled.div`
  margin-bottom: 20px;
  width: 50%;
`;

export default function OutputColumnName({
  setOutputColumnName,
  outputColumnName,
}) {
  if (!setOutputColumnName) {
    return null;
  }

  return (
    <Container>
      <Input
        label="Output Column Name"
        inPlaceEdit
        value={outputColumnName}
        onChange={(e) => setOutputColumnName(e.target.value)}
      />
    </Container>
  );
}

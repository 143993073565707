import React, { useRef } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tipper from "../../../../UI/Tipper/Tipper";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { hexToRgba } from "../../../../styles/colorConvertor";

const Container = styled.div`
  position: absolute;
  bottom: 10px;
  width: calc(100vw - 100px);
  left: 10px;
  height: 100px;
  background: black;
  color: white;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: visible;
`;

const SortItem = styled.div`
  padding: 2px 38px 2px 38px;
  border: 1px solid ${(props) => hexToRgba(props.theme.background.primary, 0.5)};
  margin-right: 12px;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  background: ${(props) =>
    props.isDragging ? props.theme.notification.infoMain : "transparent"};
  &:hover {
    background: ${(props) => props.theme.background.primary};
    color: black;
  }
  svg {
    margin-left: 8px;
    font-size: 10px;
  }
`;
const Delete = styled.div`
  position: absolute;
  left: 1px;
  top: 1px;
  height: 18px;
  width: 18px;
  background: ${(props) => props.theme.notification.errorMain};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  padding-right: 6px;
`;
const MoveUp = styled.div`
  position: absolute;
  right: -37px;
  top: -18px;
  height: 18px;
  width: 22px;
  background: ${(props) => props.theme.notification.successMain};
  border-radius: 2px;
`;

const Close = styled.div`
  position: absolute;
  right: 12px;
  top: 6px;
  color: white;
  font-size: 22px;
  cursor: pointer;
`;

export default function EditSortPanel(props) {
  const { orders, handleClick, handleDragEnd, handleRemove, close } = props;
  const ref = useRef(null);
  useOnClickOutside(ref, close);
  return (
    <Container ref={ref}>
      <Close onClick={close}>
        <FontAwesomeIcon icon={["fal", "times-circle"]} />
      </Close>
      <div>Update sort options</div>
      <div style={{ marginTop: 28, display: "flex" }}>
        {orders.map((item, i) => (
          <SortItem
            data-cy="sort-order-item"
            key={item.name}
            onClick={() => handleClick(item.name)}
          >
            <Delete
              data-cy="remove-sort-column"
              onClick={(e) => {
                e.stopPropagation();
                handleRemove(item.name);
              }}
            >
              <FontAwesomeIcon icon={["fas", "times"]} />
            </Delete>
            {item.label || item.name}
            <FontAwesomeIcon icon={["fas", icons[item.sort]]} />
            {i !== 0 ? (
              <Tipper label="Increase search order position">
                <MoveUp onClick={(e) => handleDragEnd(e, i)}>
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                </MoveUp>
              </Tipper>
            ) : null}
          </SortItem>
        ))}
      </div>
    </Container>
  );
}

const icons = {
  ASC: "chevron-down",
  DESC: "chevron-up",
};

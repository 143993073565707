import { useState, useCallback } from "react";
import styled from "@emotion/styled";

const ImageContainer = styled.img`
  width: ${(props) => props.width * 100}%;
  @media (max-width: 800px) {
    width: ${(props) => props.width * 60}%;
  }
`;

export const defaultLogoSrc =
  "https://d3gppnp7ccufr1.cloudfront.net/io/default_logo.png";

export default function HomeLogo(props) {
  const { src } = props;
  const [width, setWidth] = useState(null);

  const img = new Image();
  img.onload = useCallback(function () {
    const ratio = this.height / this.width;
    setWidth(ratio > 0.4 ? 0.75 : 1);
  }, []);

  img.src = src;

  return !width ? null : (
    <ImageContainer
      data-cy="site-logo"
      src={src}
      alt="Insight Out"
      width={width}
      height="auto"
    />
  );
}

import styled from "@emotion/styled";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import Input from "../../../../UI/Form/Input/Input";

const Container = styled.div`
  display: flex;
  align-items: center;
  div {
    width: 46%;
  }
`;

export default function CollapsableMenu(props) {
  const { index, collapseIndex, override, onOverrideItemChange } = props;

  if (index !== collapseIndex) {
    return null;
  }

  return (
    <Container>
      <Checkbox
        label="Reverse Color"
        checked={override.reverseColor}
        onChange={() =>
          onOverrideItemChange(
            { value: !override.reverseColor },
            "reverseColor",
            override.index
          )
        }
      />

      {override.overrideFormat === "percent" && (
        <>
          <Input
            style={{ width: 100, marginRight: 10 }}
            value={override.overridePrecision ?? ""}
            onChange={(e) =>
              onOverrideItemChange(
                { value: +e.target.value },
                "overridePrecision",
                override.index
              )
            }
          />
          <label>Precision</label>
        </>
      )}
    </Container>
  );
}

import React from "react";
import HeroBlockLayout from "../HeroBlockLayout";
import LeftRightArea from "../../../../UI/BigInput/LeftRightArea";

export default function ProductGroupUnder(props) {
  const { sourceValues, handleSave, values, ...rest } = props;
  const message = {
    title: "Got inconsistent product names?",
    text: `
        You may have products you’re no longer tracking, or test products, or
        just products you don’t want anyone to know about. A solis synonym list 
        will help keep everything tidy and tracked
      `,
  };

  return (
    <HeroBlockLayout
      message={message}
      handleSave={handleSave}
      initFolders={values}
      values={values}
      folderMode
      {...rest}
    >
      <LeftRightArea data={sourceValues} />
    </HeroBlockLayout>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionChip from "../../../UI/UserChip/ActionChip";
import { SeeMore } from "../../UserManagement/Groups/GroupMembers";
import { useState } from "react";
import Select from "../../../UI/Form/Select/Select";
import Flex from "../../../UI/Flex/Flex";

export default function ChipList(props) {
  const {
    visible,
    all,
    width = 20,
    height = 20,
    fontSize = 12,
    limit = 5,
    addMore,
    deleteItem,
    addLabel = "Add recipient",
  } = props;

  const [showSelect, setShowSelect] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);
  const [dynamicLimit, setDynamicLimit] = useState(limit);

  function onChipClick(chip) {
    setSelectedChip(selectedChip?.uuid === chip.uuid ? null : chip);
  }

  function renderChip(chip, index, array) {
    if (index === dynamicLimit) {
      return (
        <SeeMore onClick={() => setDynamicLimit(array.length)} key={index}>
          {array.length - limit} more
        </SeeMore>
      );
    }

    if (index > dynamicLimit) {
      return null;
    }

    if (index === array.length - 1 && dynamicLimit > limit) {
      return (
        <SeeMore onClick={() => setDynamicLimit(limit)} key={index}>
          hide {array.length - limit} items
        </SeeMore>
      );
    }

    return (
      <ActionChip
        label={chip.name}
        data={chip}
        fontSize={fontSize}
        width={width}
        height={height}
        chipOnly={selectedChip?.uuid !== chip.uuid}
        clickItem={onChipClick}
        key={chip.uuid}
        deleteItem={deleteItem}
      />
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Flex alignItems="center" wrap="wrap">
        {addMore &&
          (showSelect ? (
            <FontAwesomeIcon
              icon={["fas", "minus-circle"]}
              style={{ cursor: "pointer" }}
              onClick={() => setShowSelect(false)}
            />
          ) : (
            <FontAwesomeIcon
              icon={["fas", "plus-circle"]}
              style={{ cursor: "pointer" }}
              onClick={() => setShowSelect(true)}
            />
          ))}
        {visible.map(renderChip)}
      </Flex>

      {showSelect && (
        <>
          <br />
          <Select
            options={all.filter(
              (chip) => !visible.some((vis) => vis.uuid === chip.uuid)
            )}
            label={addLabel}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.uuid}
            onChange={addMore}
            closeMenuOnSelect={false}
          />
          <br />
        </>
      )}
    </div>
  );
}

export const mergeColumnsAndQuery = (activeTableFields, queryFields) => {
  if (!queryFields?.length) {
    return activeTableFields ?? [];
  } else {
    return queryFields.map(
      (f) =>
        activeTableFields.find((a) => a.name === f.name) ?? {
          ...f,
          queryOnly: true,
          type: typeConversions[f?.mapping?.type] ?? "string",
          colId: f.dataSourceFieldUuid,
        }
    );
  }
};

const typeConversions = {
  currency: "currency",
  date: "date",
  datetime: "date",
  integer: "integer",
  float: "decimal",
  string: "string",
  text: "text",
  decimal: "decimal",
  boolean: "boolean",
};

import { useEffect, useState } from "react";
import isEqual from "react-fast-compare";

export default (selectedView, setSelectedView) => {
  const initialState = selectedView === "new";

  const [showDetailPanel, handleSetShowDetailPanel] = useState(initialState);
  const initialTabView = selectedView === "new" ? "settings" : "columns";
  const [activeTab, setActiveTab] = useState(initialTabView);
  const [previousSelectedView, setPreviousSelectedView] = useState(null);

  useEffect(() => {
    if (selectedView !== "new") {
      setPreviousSelectedView(selectedView);
    }

    if (!isEqual(previousSelectedView, selectedView)) {
      if (!selectedView) {
        return handleSetShowDetailPanel(false);
      } else if (selectedView === "new") {
        return handleSetShowDetailPanel(true);
      } else {
        setActiveTab("columns");
      }
    }
  }, [previousSelectedView, selectedView]);

  function setShowDetailPanel(booleanState) {
    if (!booleanState) {
      if (selectedView === "new") {
        setSelectedView(previousSelectedView);
      }
      handleSetShowDetailPanel(false);
    } else {
      const nextState = selectedView === "new" ? true : !!booleanState;
      handleSetShowDetailPanel(nextState);
    }
  }

  return { showDetailPanel, setShowDetailPanel, activeTab, setActiveTab };
};

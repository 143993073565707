import differenceInCalendarQuarters from "date-fns/differenceInCalendarQuarters";
import addQuarters from "date-fns/addQuarters";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import addMonths from "date-fns/addMonths";
import { absoluteDate } from "../../../utils/dates/dateFunc";
import {
  endOfQuarter,
  endOfYear,
  format as dateFormat,
  subYears,
  subQuarters,
  subDays,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
  endOfMonth,
} from "date-fns";
import {
  addFilterValue,
  getParameterizedDateFilters,
  getQuarterlies,
  setFilterString,
} from "./queryBuilder";

export default (dateFilters, term = "quarterly", dateFormatting) => {
  const { start, end } = dateFilters;
  const startDate = absoluteDate(start.value);
  const endDate = absoluteDate(end.value);
  const v = {
    quarterly: {
      fn: differenceInCalendarQuarters,
      add: addQuarters,
      formatting: dateFormatting || "yyyy QQQ",
    },
    monthly: {
      fn: differenceInCalendarMonths,
      add: addMonths,
      formatting: "yyyy 'M'MM",
    },
  };
  const number = v[term].fn(endDate, startDate);

  return Array(number + 1)
    .fill(null)
    .map((val, i) => {
      const da = v[term].add(startDate, i);
      return dateFormat(da, v[term].formatting);
    });
};

export const getDateMinusOneYear = (query, dateFilters) => {
  if (query.endDateMinusOneYear) {
    const startDate = dateFormat(
      subYears(new Date(dateFilters.end.value), 1),
      "yyyy-MM-dd"
    );

    if (query.isParameterized) {
      return getParameterizedDateFilters(
        query.dateKeys,
        startDate,
        dateFilters.end.value
      );
    }

    return (
      setFilterString(query.dateKey ?? dateFilters.start.type) +
      addFilterValue(startDate) +
      addFilterValue(dateFilters.end.value)
    );
  }

  return "";
};

export const getDaysOffsetFromCurrent = (query) => {
  if (!query.daysOffsetFromCurrent) {
    return "";
  }

  const end = dateFormat(new Date(), "yyyy-MM-dd");
  const start = dateFormat(
    subDays(parseISO(end), query.daysOffsetFromCurrent),
    "yyyy-MM-dd"
  );

  if (query.isParameterized) {
    return getParameterizedDateFilters(query.dateKeys, start, end);
  }

  return (
    setFilterString(query.dateKey) + addFilterValue(start) + addFilterValue(end)
  );
};

export const getDaysOffsetFromPrevYear = (query) => {
  if (!query.daysOffsetFromPrevYear) {
    return "";
  }

  const end = dateFormat(subYears(new Date(), 1), "yyyy-MM-dd");
  const start = dateFormat(
    subDays(parseISO(end), query.daysOffsetFromPrevYear),
    "yyyy-MM-dd"
  );

  if (query.isParameterized) {
    return getParameterizedDateFilters(query.dateKeys, start, end);
  }

  return (
    setFilterString(query.dateKey) + addFilterValue(start) + addFilterValue(end)
  );
};

export const getToDateType = (query) => {
  if (!query.toDateType) {
    return "";
  }

  let start;
  let end;

  const format = query.toDateFormat ?? "yyyy-MM-dd";

  switch (query.toDateType) {
    case "MTD": {
      end = dateFormat(new Date(), format);
      start = dateFormat(startOfMonth(new Date()), format);
      break;
    }

    case "QTD": {
      end = dateFormat(new Date(), format);
      start = dateFormat(startOfQuarter(new Date()), format);
      break;
    }

    case "LAST_8_QUARTERS": {
      end = dateFormat(endOfQuarter(new Date()), "yyyy-MM-dd");
      start = dateFormat(
        startOfQuarter(subQuarters(new Date(), 8)),
        "yyyy-MM-dd"
      );

      if (query.toDateListType === "quarters") {
        return getQuarterlies(
          {
            quarterRange: true,
            quarterRangeKey: query.dateKey,
            isParameterized: query.isParameterized,
            dateFormatting: format,
          },
          { start: { value: start }, end: { value: end } }
        );
      }
      break;
    }

    case "YTD": {
      end = dateFormat(new Date(), format);
      start = dateFormat(startOfYear(new Date()), format);
      break;
    }

    case "CURRENT_YEAR": {
      end = dateFormat(endOfYear(new Date()), format);
      start = dateFormat(startOfYear(new Date()), format);
      break;
    }

    case "LESS_THEN_ONE_YEAR": {
      end = dateFormat(new Date(), format);
      start = dateFormat(subYears(new Date(), 1), format);
      break;
    }

    case "PREV_MONTH": {
      end = dateFormat(endOfMonth(subMonths(new Date(), 1)), format);
      start = dateFormat(startOfMonth(subMonths(new Date(), 1)), format);
      break;
    }

    case "LAST_12_MONTHS": {
      end = dateFormat(endOfMonth(new Date()), format);
      const lastYear = subYears(new Date(), 1);
      start = dateFormat(startOfMonth(lastYear), format);
      break;
    }

    case "PRIOR_30_DAYS": {
      end = dateFormat(new Date(), format);
      start = dateFormat(subDays(new Date(), 30), format);
      break;
    }

    case "P&L_SPECIAL_PERIOD": {
      end = dateFormat(endOfMonth(subMonths(new Date(), 1)), format);

      start = dateFormat(
        endOfMonth(subYears(subMonths(new Date(), 0), 1)),
        format
      );

      break;
    }

    default:
      break;
  }

  if (query.isParameterized) {
    return getParameterizedDateFilters(query.dateKeys, start, end);
  }

  return (
    setFilterString(query.dateKey) + addFilterValue(start) + addFilterValue(end)
  );
};

import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import {
  flexCenter,
  inputBorders,
  inputFill,
  inputMenu,
} from "../../../../styles/styledSnippets";

const Container = styled.div`
  ${(props) => inputBorders(props)}
  ${(props) => inputFill(props)}
    ${flexCenter}
    border-radius: 50%;
  position: relative;
  font-size: 16px;
  width: 38px;
  cursor: pointer;
`;

const Menu = styled.div`
  position: absolute;
  left: 40px;
  top: -4px;
  font-size: 12px;
  border-radius: 2px;
  white-space: nowrap;
  ${(props) => inputMenu(props)}
`;

const MenuItem = styled.div`
  padding: 4px 18px;
  text-align: left;
  &:hover {
    background: ${(props) => props.theme.primary};
    color: white;
  }
`;

export default function FieldAction(props) {
  const { removeRow } = props;
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowMenu(false));

  return (
    <Container
      ref={ref}
      active={showMenu}
      data-cy="field-action-toggle"
      onClick={() => setShowMenu((menu) => !menu)}
    >
      <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
      {showMenu ? (
        <Menu data-cy="field-action-menu">
          <MenuItem onClick={removeRow} data-cy="field-action-menu-item">
            remove entry
          </MenuItem>
        </Menu>
      ) : null}
    </Container>
  );
}

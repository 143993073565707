import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";

import { UploadContainer } from "./styles";
import { imageUploadFormats } from "../../../utils/constants/constants";

const UserAvatar = ({ removeAvatar, image, attachImage }) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleImageChange = ({ target }) => {
    const file = target.files[0];

    if (!imageUploadFormats.some((item) => file.type.includes(item))) {
      setIsInvalid(true);
      return;
    }

    setIsInvalid(false);
    attachImage({
      file,
      url: URL.createObjectURL(file),
    });
  };

  const onDrop = (image) => {
    setIsInvalid(false);

    if (image.length > 1) {
      setIsInvalid(true);
      return;
    }

    handleImageChange({ target: { files: image } });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onRemoveImage = (event) => {
    removeAvatar();
    event.stopPropagation();
  };

  const previewSection = (
    <>
      {image.url ? (
        <img src={image.url} alt="User avatar" width="100%" height="100%" />
      ) : (
        <>
          <FontAwesomeIcon icon={["fas", "upload"]} />
          <label>
            {isDragActive
              ? "Drop image here"
              : isInvalid
              ? "Only single image with (jpeg, png) types allowed"
              : "Click or drop image to upload"}
          </label>
        </>
      )}
    </>
  );

  return (
    <UploadContainer
      {...getRootProps()}
      isInvalid={isInvalid}
      data-cy="upload-image-profile-block"
    >
      {image.url && (
        <span onClick={onRemoveImage}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </span>
      )}
      <input
        {...getInputProps()}
        type="file"
        accept="image/png, image/jpeg"
        multiple={false}
      />
      {previewSection}
    </UploadContainer>
  );
};

export default UserAvatar;

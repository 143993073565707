import React from "react";
import AlertMessage from "../../../UI/Alerts/AlertMessage";
import Input from "../../../UI/Form/Input/Input";
import Select from "../../../UI/Form/Select/Select";
import IOButton from "../../../UI/Form/Button/IOButton";

export default function CreateNewRuleset(props) {
  const {
    tableNames,
    blueprintName,
    setBlueprintName,
    selectedTable,
    setSelectedTable,
    availableFields,
    selectedField,
    setSelectedField,
    creating,
    prepareBlueprintCreate,
    blueprints,
  } = props;

  const sameName = (blueprints ?? []).some(
    (blueprint) => blueprintName.toLowerCase() === blueprint.name.toLowerCase()
  );

  function validateNewBlueprint() {
    return selectedTable && selectedField && blueprintName && !sameName;
  }

  return (
    <>
      <AlertMessage cy="message" status="info">
        Pick a table and field to use as a base.
      </AlertMessage>
      <div style={{ maxWidth: 400 }}>
        <div style={{ marginTop: 24 }}>
          <Input
            label="Name of Blueprint"
            cy="blueprint-name"
            placeholder="ex: My Product Names..."
            value={blueprintName}
            onChange={(e) => setBlueprintName(e.target.value)}
            invalid={sameName}
            errorMessage={
              sameName && "A Blueprint with the same name already exists."
            }
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <Select
            options={tableNames}
            onChange={setSelectedTable}
            value={selectedTable}
            label="Select a source table."
            cy="raw-table-list"
          />
        </div>
        {selectedTable && (
          <div style={{ marginTop: 24 }}>
            <Select
              cy="raw-field-list"
              options={availableFields}
              label="Select a source field."
              value={selectedField}
              onChange={setSelectedField}
            />
          </div>
        )}
        {validateNewBlueprint() && (
          <div style={{ marginTop: 36 }}>
            <IOButton
              cy="create-blueprint"
              onClick={prepareBlueprintCreate}
              processing={creating}
            >
              Create your rules
            </IOButton>
          </div>
        )}
      </div>
    </>
  );
}

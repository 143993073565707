import React, { useState } from "react";
import styled from "@emotion/styled";
import IOButton from "../../UI/Form/Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeGrayIf } from "../../styles/styledSnippets";
import ButtonOutline from "./ButtonOutline";
import Input from "../../UI/Form/Input/Input";
import ActiveTableViews from "./ActiveTableViews/ActiveTableViews";
import useDebounceChange from "../../hooks/useDebounceChange";
import SplitBy from "./components/SplitBy";

const Container = styled.div`
  color: ${(props) => props.theme.gray.gray900};
  padding: 12px 0;
  margin-bottom: 12px;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  button {
    margin-left: 12px;
    svg {
      margin-right: 6px;
    }
  }
  button:first-of-type {
    margin-left: 0;
  }
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: ${themeGrayIf(700, 300)};
  margin: 0 18px 0 18px;
`;

export default function ActiveTableNavigationShelf(props) {
  const {
    handleAdd,
    handleSplit,
    columns = [],
    adminMode,
    openSettings,
    splitBy,
    handleCollapse,
    setStringMatch,
    views,
    selectedView,
    setSelectedView,
    queryId,
    handleSaveView,
    user,
    stringMatch,
    viewFilteredColumns,
    inJoinedMode,
  } = props;
  const [tempStringMatch, setTempStringMatch] = useState(stringMatch);

  const { debounce } = useDebounceChange();

  function handleStringMatchChange(e) {
    setTempStringMatch(e.target.value);
    debounce(() => setStringMatch(e.target.value), 2000);
  }

  const showSplitBy = !!viewFilteredColumns.length;

  return (
    <Container>
      {!inJoinedMode && (
        <IOButton
          standard
          onClick={handleAdd}
          smallPadding
          style={{ whiteSpace: "nowrap" }}
        >
          <FontAwesomeIcon icon={["fal", "plus-circle"]} /> Add Row
        </IOButton>
      )}

      {adminMode ? (
        <IOButton
          outline
          onClick={openSettings}
          cy="active-table-settings"
          smallPadding
        >
          <FontAwesomeIcon icon={["fal", "cog"]} /> Settings
        </IOButton>
      ) : null}
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {showSplitBy && (
            <SplitBy
              columns={columns}
              handleSplit={handleSplit}
              splitBy={splitBy}
            />
          )}

          {splitBy?.value ? (
            <>
              <div style={{ marginLeft: 6 }}>
                <ButtonOutline
                  title="Collapse all"
                  cy="collapse-all"
                  onClick={() => handleCollapse("collapse")}
                >
                  <FontAwesomeIcon icon={["fal", "chevron-double-up"]} />
                </ButtonOutline>
              </div>
              <div style={{ marginLeft: 6 }}>
                <ButtonOutline
                  title="Expand all"
                  cy="expand-all"
                  onClick={() => handleCollapse("expand")}
                >
                  <FontAwesomeIcon icon={["fal", "chevron-double-down"]} />
                </ButtonOutline>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div
        style={{
          marginLeft: splitBy?.value ? 24 : 48,
          width: "100%",
          float: "right",
          maxWidth: 300,
        }}
      >
        <Input
          search
          cy="filter-active-results"
          fit
          onChange={handleStringMatchChange}
          value={tempStringMatch}
        />
      </div>
      <div style={{ marginLeft: 28, flexShrink: 0 }}>
        <ActiveTableViews
          views={views}
          columns={columns}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          queryId={queryId}
          handleSaveView={handleSaveView}
          user={user}
          handleSplit={handleSplit}
        />
      </div>
    </Container>
  );
}

import produce from "immer";
import { groupBy, isNumber, keyBy, maxBy, remove } from "lodash-es";
import {
  AGGREGATION_SUM,
  aggregations,
  dateAggregationValues,
} from "../../../../../utils/constants/constants";
import { aggPrefixByNumber } from "../../../../../utils/charts/visualizationGui/helpers";

export function convertDraftSettingsToEffectiveSettings(draftSettings) {
  if (
    !draftSettings.filters?.length ||
    !draftSettings.overrides?.fields?.length
  ) {
    return draftSettings;
  }

  return produce(draftSettings, (draft) => {
    /*
     * Convert any filters that reference any non-date aggregated fields to use "havings" instead.
     */

    const aggregatedFieldsByName = groupBy(
      draft.overrides.fields.filter(
        (v) =>
          v.aggregation && !dateAggregationValues.includes(v.aggregation.type)
      ),
      "name"
    );

    remove(draft.filters, (filter) => {
      const aggregatedFields = aggregatedFieldsByName[filter.name];
      if (!aggregatedFields) {
        return false;
      }
      const aggregatedField = maxBy(aggregatedFields, (field) =>
        // Prefer one with the SUM type.
        field.aggregation.type === AGGREGATION_SUM ? 1 : 0
      );
      const newHaving = { ...filter, name: getFieldKey(aggregatedField) };
      draft.havings.push(newHaving);
      return true;
    });
  });
}

export function convertEffectiveSettingsToDraftSettings(effectiveSettings) {
  const nextFilters = patchRangeOperator();
  const orders = patchOrder();
  const nextSettings = { ...effectiveSettings, filters: nextFilters };
  if (orders) {
    nextSettings.orders = orders;
  }

  if (
    !effectiveSettings?.havings?.length ||
    !effectiveSettings.overrides?.fields?.length
  ) {
    return nextSettings;
  }

  return produce(nextSettings, (draft) => {
    const aggregatedFieldByAlias = keyBy(
      draft.overrides.fields.filter(
        (v) =>
          v.aggregation && !dateAggregationValues.includes(v.aggregation.type)
      ),
      (v) => getFieldKey(v)
    );

    remove(draft.havings, (having) => {
      const aggregatedField = aggregatedFieldByAlias[having.name];
      if (!aggregatedField) {
        return false;
      }

      const newFilter = { ...having, name: aggregatedField.name };
      if (!draft.filters) {
        draft.filters = [];
      }
      draft.filters.push(newFilter);
      return true;
    });
  });

  function patchOrder() {
    if (!effectiveSettings.order) return null;
    const orderKeys = Object.keys(effectiveSettings.order);
    const orders = orderKeys.map((k) => ({
      name: k,
      sort: effectiveSettings.order[k],
    }));
    delete nextFilters.order;
    return orders;
  }
  function patchRangeOperator() {
    return effectiveSettings.filters
      ? effectiveSettings.filters.map((f) =>
          isDateRange(f) ? { ...f, operator: "range" } : f
        )
      : [];
    function isDateRange(f) {
      return f.values && !f.operator && f.values.length === 2;
    }
  }
}

function getFieldKey(field) {
  if (!field.aggregation?.type) {
    return field.name;
  }

  const aggregation = aggregations.find(
    (v) => v.value === field.aggregation.type
  );

  return aggregation.prefix + field.name;
}

export function addDisplayNameToFields(fields) {
  if (!fields?.length) return [];
  // Iterate over fields
  return fields.map((field) => {
    if (field.aggregation && field.aggregation.type) {
      // Create display name from the aggregation type
      let displayName = generateDisplayNameWithAggregationType(
        field.aggregation.type,
        field.name
      );

      if (field.mapping) {
        if (!field.mapping.displayName) {
          field.mapping.displayName = displayName;
        }
      } else {
        field.mapping = {
          displayName: displayName,
        };
      }
    }

    return field; // Return the possibly modified field
  });
}

// This function will generate a displayName based on an aggregation type and a field name
export const generateDisplayNameWithAggregationType = (type, fieldName) => {
  const aggregationType = isNumber(type)
    ? aggPrefixByNumber[type].toUpperCase()
    : type;

  return (
    aggregationType.charAt(0).toUpperCase() +
    aggregationType.slice(1).toLowerCase() +
    " " +
    generateDisplayName(fieldName)
  );
};

export const generateDisplayName = (fieldName) => {
  return (
    fieldName
      // Insert a space before all found uppercase letters.
      .replace(/([A-Z])/g, " $1")
      // Remove any leading space.
      .replace(/^ /, "")
      // Capitalize each word.
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
  );
};

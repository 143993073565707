import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import { showToast, showToastWithTimeout } from "./message";
import { setActiveTab } from "./layout";
import { handleError } from "../../utils/errorHandling";
import { wrapAsyncThunk } from "../../utils/apiResultWrapper";
import { noop, sortBy } from "lodash-es";
import { initLoadCharts } from "./dashboard/dashboard";
import { pageFormatConverter } from "../../utils/pages";

export const getPages = () =>
  wrapAsyncThunk(async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_PAGES_START });
      const res = await axios.get("/api/v1/pages");

      dispatch({ type: actionTypes.GET_PAGES_SUCCESS, results: res.data.data });
    } catch (error) {
      const normalizedError = handleError(dispatch, error);
      dispatch({
        type: actionTypes.GET_PAGES_FAIL,
        payload: normalizedError,
      });
    }
  });

export const createPage = (formData) => async (dispatch, getState) => {
  try {
    const requestData = pageFormatConverter.form.toRequest(formData, true);
    dispatch({ type: actionTypes.CREATE_PAGE_START });
    const response = await axios.post("/api/v1/pages", requestData);

    dispatch(getPages());
    setPage(dispatch, getState);
    showToast(
      actionTypes.CREATE_PAGE_SUCCESS,
      dispatch,
      ["Page was successfully created"],
      "success"
    );
    return response;
  } catch (error) {
    dispatch({ type: actionTypes.CREATE_PAGE_FAIL });
    handleError(dispatch, error);
    throw error;
  }
};

export const updatePage =
  (uuid, formData, groups) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_PAGE_START });
    const requestData = pageFormatConverter.form.toRequest(formData);

    try {
      await axios.put(`/api/v1/pages/${uuid}`, requestData);
      await axios.put(`/api/v1/pages/${uuid}/groups`, {
        groups: groups.map((g) => ({ uuid: g.uuid })),
      }); // update groups for page

      dispatch(getPages());
      setPage(dispatch, getState);
      showToast(
        actionTypes.UPDATE_PAGE_SUCCESS,
        dispatch,
        ["Page was successfully updated"],
        "success"
      );
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_PAGE_FAIL });
      handleError(dispatch, error);
    }
  };

export const deletePage = (page) => async (dispatch, getState) => {
  try {
    dispatch({ type: actionTypes.DELETE_PAGE_START });
    await axios.delete(`/api/v1/pages/${page.uuid}`);

    dispatch({ type: actionTypes.DELETE_PAGE_SUCCESS, results: page });
    showToast(
      actionTypes.DELETE_PAGE_SUCCESS_TOAST,
      dispatch,
      ["Page was successfully deleted"],
      "success"
    );
    dispatch(getPages());
    setPage(dispatch, getState);
  } catch (error) {
    const handleErrors = error.response.data.errors
      ? Object.values(error.response.data.errors).map((error) => error[0])
      : [error.response.data.message];
    showToast(actionTypes.DELETE_PAGE_FAIL, dispatch, handleErrors, "danger");
  }
};

export const reorderPages = (pages) => async (dispatch, getState) => {
  const payload = {
    ordering: pages.map((page) => ({
      uuid: page.uuid,
      position: page.position,
    })),
  };

  // Optimistic update
  dispatch({
    type: actionTypes.GET_PAGES_SUCCESS,
    results: sortBy(getState().pageManagement.pages, (page) =>
      pages.findIndex((reorderedPage) => page.uuid === reorderedPage.uuid)
    ),
  });

  try {
    dispatch({ type: actionTypes.REORDER_PAGES_START });
    const res = await axios.post("/api/v1/pages/ordering", payload);

    showToast(
      actionTypes.REORDER_PAGES_SUCCESS,
      dispatch,
      ["Reorder pages success"],
      "success"
    );
    dispatch({ type: actionTypes.GET_PAGES_SUCCESS, results: res.data.data });
    setPage(dispatch, getState);
  } catch (error) {
    const handleErrors = error.response.data.errors
      ? Object.values(error.response.data.errors).map((error) => error[0])
      : [error.response.data.message];
    showToast(actionTypes.REORDER_PAGES_FAIL, dispatch, handleErrors, "danger");
    dispatch(getPages());
  }
};

export const createBlock = (pageUuid, block) => {
  return wrapAsyncThunk(async (dispatch) => {
    const promise = dispatch({
      type: actionTypes.CREATE_PAGE_BLOCK_START,
      meta: {
        api: {
          method: "POST",
          endpoint: `api/v1/pages/${pageUuid}/blocks`,
          payload: block,
          toastOnFailure: true,
        },
      },
      pageUuid,
    }).unwrap();
    promise.then(() => {
      showToastWithTimeout(dispatch, "Block saved.", "success");
    }, noop);
    return promise;
  });
};

export function updateBlock(pageId, blockId, block) {
  return wrapAsyncThunk(async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PAGE_BLOCK_START,
    });
    try {
      const response = await axios.put(
        `api/v1/pages/${pageId}/blocks/${blockId}`,
        block
      );
      dispatch(getPages());
      showToastWithTimeout(dispatch, "Block saved.", "success");
      return response;
    } catch (e) {
      handleError(dispatch, e);
      throw e;
    }
  });
}

export const deleteBlock = (pageUuid, blockUuid) => {
  return {
    type: actionTypes.DELETE_PAGE_BLOCK_START,
    meta: {
      api: {
        method: "DELETE",
        endpoint: `api/v1/blocks/${blockUuid}`,
      },
    },
    pageUuid,
    blockUuid,
  };
};

export const sortBlocks = (pageUuid, orderedBlocks) => {
  return {
    type: actionTypes.SORT_BLOCKS_START,
    meta: {
      api: {
        endpoint: `api/v1/pages/${pageUuid}/blocks/sort`,
        method: "PUT",
        payload: { blocks: orderedBlocks },
        toastOnFailure: true,
      },
    },
    pageUuid,
    updatedBlockOrder: orderedBlocks,
  };
};

export function createVisualization(
  pageUuid,
  blockUuid,
  queryUuid,
  overridesObject,
  filtersObject,
  ordersObject,
  settingsObject,
  visualizationUuid,
  sortOrder,
  updateChart
) {
  const overrides = JSON.stringify(overridesObject);
  const filters = JSON.stringify(filtersObject);
  const orders = JSON.stringify(ordersObject);
  const settings = JSON.stringify(settingsObject);
  const method = visualizationUuid ? "PUT" : "POST";

  const type = visualizationUuid
    ? actionTypes.UPDATE_VISUALIZATION_START
    : actionTypes.CREATE_VISUALIZATION_START;

  const commonObject = {
    pageUuid,
    blockUuid,
    visualizationUuid,
  };

  return wrapAsyncThunk(async (dispatch) => {
    dispatch({
      type,
      ...commonObject,
    });
    try {
      const response = await axios.request({
        method,
        url: `api/v1/pages/${pageUuid}/blocks/${blockUuid}/visualizations/${
          visualizationUuid || ""
        }`,
        data: {
          queryUuid,
          filters,
          overrides,
          orders,
          settings,
          ...(visualizationUuid && { sortOrder }),
        },
      });

      const type = visualizationUuid
        ? actionTypes.UPDATE_VISUALIZATION_SUCCESS
        : actionTypes.CREATE_VISUALIZATION_SUCCESS;
      dispatch({
        type,
        results: response.data,
        ...commonObject,
      });
      showToastWithTimeout(
        dispatch,
        "Visualization saved successfully.",
        "success"
      );

      updateChart && dispatch(initLoadCharts(visualizationUuid));

      return response;
    } catch (e) {
      handleError(dispatch, e);
      throw e;
    }
  });
}

export function deleteVisualization(pageUuid, blockUuid, visualizationUuid) {
  const values = { pageUuid, blockUuid, visualizationUuid };
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_VISUALIZATION_START, ...values });
      await axios.delete(
        `api/v1/pages/${pageUuid}/blocks/${blockUuid}/visualizations/${visualizationUuid}`
      );
      dispatch({ type: actionTypes.DELETE_VISUALIZATION_SUCCESS, ...values });
      showToastWithTimeout(
        dispatch,
        "Visualization deleted successfully.",
        "success"
      );
    } catch (e) {
      handleError(dispatch, e);
    }
  };
}

export function reorderVisualizations(pageUuid, blockUuid, visualizationUuids) {
  return async (dispatch) => {
    const payload = {
      visualizations: visualizationUuids.map((uuid, index) => ({
        uuid,
        sortOrder: index,
      })),
    };
    try {
      await axios.put(
        `api/v1/pages/${pageUuid}/blocks/${blockUuid}/visualizations/sort`,
        payload
      );
      await dispatch(getPages()).unwrap();
      showToastWithTimeout(
        dispatch,
        "Visualization sort order change was saved.",
        "success"
      );
    } catch (e) {
      handleError(dispatch, e);
    }
  };
}

const setPage = (dispatch, getState) => {
  dispatch(setActiveTab(getState().layout.tabs[0]));
};

export const setFullPageScreenshotMode = (mode) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FULL_PAGE_SCREENSHOT_MODE,
    mode,
  });
};

export function clonePage(pageUuid) {
  return async (dispatch) => {
    await dispatch({
      type: actionTypes.CLONE_PAGE_START,
      meta: {
        api: {
          endpoint: `api/v1/pages/${pageUuid}/clone`,
          method: "POST",
          toastOnFailure: true,
        },
        toasts: [
          {
            type: "success",
            title: "Clone Page!",
            message: "Page cloned",
            condition: actionTypes.CLONE_PAGE_SUCCESS,
          },
        ],
      },
    });
    dispatch(getPages());
  };
}

import styled from "@emotion/styled";
import ChartItem from "./ChartItem";
import { useMemo } from "react";
import MonitoringFilters from "./Filters";

const PageContainer = styled.div`
  padding: 10px;
  margin: 20px;
`;

const PageTitle = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.divider};
  color: white;
  border-radius: 5px;
  color: white;
`;

const BlockTitle = styled.div`
  margin-left: 20px;
  padding: 8px;
`;

export default function Details({
  mapSettingsToConfigurations,
  executedCharts,
  theme,
  Title,
  filters,
  execStatuses,
  onFilterChange,
}) {
  const pages = useMemo(
    () =>
      mapSettingsToConfigurations
        .filter((page) =>
          page.blocks.find((block) => {
            return block.charts.find((chart) => {
              const executed = executedCharts[chart.visualizationId] ?? {};
              return filters.some((filter) => executed.type === filter);
            });
          })
        )
        .map((page) => {
          return {
            ...page,
            blocks: page.blocks.filter((block) => {
              return block.charts.find((chart) => {
                const executed = executedCharts[chart.visualizationId] ?? {};
                return filters.some((filter) => executed.type === filter);
              });
            }),
          };
        }),
    [executedCharts, filters, mapSettingsToConfigurations]
  );

  return (
    <>
      <MonitoringFilters
        Title={Title}
        execStatuses={execStatuses}
        filters={filters}
        theme={theme}
        onFilterChange={onFilterChange}
      />

      <Title id="details">Details</Title>
      {pages.map((page) => (
        <PageContainer key={page.uuid}>
          <PageTitle>{page.displayName}</PageTitle>
          {page.blocks.map((block) => (
            <div key={block.uuid}>
              <BlockTitle>
                {block.displayName || block.title || "Untitled"}
              </BlockTitle>

              {block.charts.map((chart) => (
                <ChartItem
                  chart={chart}
                  executedCharts={executedCharts}
                  key={chart.visualizationId}
                  theme={theme}
                  link={`/admin/page-management/${page.uuid}/blocks/${block.uuid}/visualizations/${chart.visualizationId}`}
                />
              ))}
            </div>
          ))}
        </PageContainer>
      ))}
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";
import WizardBlock from "./WizardBlock";
import PropTypes from "prop-types";
import LeftRightAreaForm from "../../../UI/BigInput/LeftRightAreaForm";
import HeroBlockLayoutActionButtons from "./HeroBlockLayoutActionButtons";
import OutputColumnName from "../Blueprint/components/OutputColumnName";
const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 200px);
`;

const TableContainer = styled.div`
  height: calc(100vh - 438px);
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.wide ? 880 : 740)}px;
`;

const HeroBlockLayout = (props) => {
  const {
    message,
    wide,
    children,
    onClose,
    setOutputColumnName,
    outputColumnName,
    ...rest
  } = props;

  return (
    <Container>
      <LeftRightAreaForm {...rest}>
        <TableContainer wide={wide}>
          <div style={{ height: "100%", marginBottom: 12 }}>{children}</div>
        </TableContainer>

        <OutputColumnName
          setOutputColumnName={setOutputColumnName}
          outputColumnName={outputColumnName}
        />

        <HeroBlockLayoutActionButtons onClose={props.onClose} />

        <WizardBlock {...message} />
      </LeftRightAreaForm>
    </Container>
  );
};

HeroBlockLayout.propTypes = {
  message: PropTypes.object,
  initRightArray: PropTypes.any,
  initThirdValues: PropTypes.any,
  handleSave: PropTypes.func,
};

export default HeroBlockLayout;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { flexCenter } from "../../../styles/styledSnippets";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import PropTypes from "prop-types";
import ConnectingLoader from "./UI/ConnectingLoader";

const large = 180;
const small = 80;
const normal = 140;

function size(props) {
  return props.small ? small : props.large ? large : normal;
}

function backgroundColor(props) {
  const clear = props.small || !props.panel;
  if (clear) return "transparent";
  return lightenDarkenColor(props.theme.background.secondary, -1);
}

const Icon = styled.div`
  height: ${size}px;
  width: ${size}px;
  position: relative;
  background: ${backgroundColor};
  border-radius: ${(props) => (props.circle ? "50%" : "4px")};
  margin: 0
    ${(props) => (props.isSmall || props.noHover || props.circle ? 0 : 12)}px;
  color: ${(props) => props.theme.text.secondary};
  cursor: ${(props) => (props.panel ? "pointer" : "default")};
  border: ${(props) => (props.noHover ? 0 : 4)}px transparent;
  box-sizing: content-box;
  ${(props) =>
    props.panel
      ? `
      &:hover {
        border: 4px solid ${backgroundColor(props)};
        ${
          props.panel
            ? `img {
          width: 90%;
        }`
            : ""
        }
        svg {
          font-size: 48px;
          margin-bottom: 8px;
        }
      }
    `
      : ""}
  svg {
    font-size: ${(props) => (props.isSmall ? 20 : 30)}px;
    margin-bottom: 6px;
  }
  ${flexCenter}
`;

const InnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusContainer = styled.div`
  position: absolute;
  bottom: 34px;
  right: 2px;
  svg {
    font-size: 28px;
    color: ${({ error, theme }) =>
      error ? theme.notification.errorMain : theme.notification.successMain};
  }
`;

export default function IntegrationLogo(props) {
  const {
    setType,
    type,
    small,
    noHover,
    circle,
    panel,
    large,
    loading,
    success,
    error,
  } = props;
  const option = options.find((o) => o.key === type);
  if (!option) return null;

  function handleClick() {
    setType && setType(option.name);
  }

  return (
    <Icon
      onClick={handleClick}
      isSmall={small}
      noHover={noHover}
      circle={circle}
      panel={panel}
      large={large}
      data-cy="integration-logo"
    >
      {option.image ? (
        <img width="80%" height="auto" src={option.image} alt={option.name} />
      ) : (
        <InnerDiv>
          <FontAwesomeIcon icon={option.icon} />
          <div>{option.name}</div>
        </InnerDiv>
      )}
      <StatusContainer error={error} success={success}>
        {loading ? <ConnectingLoader /> : null}
        {success ? <FontAwesomeIcon icon={["fal", "check"]} /> : null}
        {error ? <FontAwesomeIcon icon={["fal", "times"]} /> : null}
      </StatusContainer>
    </Icon>
  );
}

IntegrationLogo.propTypes = {
  type: PropTypes.string,
  small: PropTypes.bool,
  setType: PropTypes.func,
  noHover: PropTypes.bool,
  circle: PropTypes.bool,
  loading: PropTypes.bool,
  panel: PropTypes.bool,
  large: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
};

const options = [
  { key: "salesforce", name: "Salesforce", image: "/media/salesforce.png" },
  { key: "ga", name: "Google Analytics", image: "/media/google-analytics.png" },
  { key: "database", name: "Database", icon: ["fal", "database"] },
  { key: "upload", name: "File", icon: ["fal", "file-spreadsheet"] },
];

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import ButtonGroup from "../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../UI/Form/Button/IOButton";
import { hideActionToast } from "../../store/actions/message";
import { scheduleExport } from "../../store/actions/fileManagement";
import { useShallowEqualSelector } from "../../store";

const Container = styled.div(
  ({ theme, show }) => `
    position: fixed;
    display: flex;
    bottom: 25px;
    left: ${show ? 10 : -1000}px;
    color: ${theme.text.secondary};
    z-index: 200000;
    background: ${theme.background.primary};
    width: 450px;
    height: 200px;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: all .5s ease 0s;
`
);

const Message = styled.div`
  padding: 25px 0px;
`;

const Arrow = styled.div(
  ({ theme }) => `
    &::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 25%;
        margin-left: -5px;
        border-width: 15px;
        border-style: solid;
        border-color: ${theme.background.primary} transparent transparent transparent;
    }
`
);

const Title = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  font-weight: 500;
`;

export default function ActionToast() {
  const {
    actionMessage,
    scheduleLoading,
    link,
    settings = {},
  } = useShallowEqualSelector((state) => ({
    ...state.message,
    ...state.fileManagement,
  }));
  const dispatch = useDispatch();

  const completeSettings = {
    ...settings,
    additionalConfirmation: 1,
    format: "excel",
    isFancyExcel: settings?.format === "fancy-excel",
  };

  return (
    <Container show={!!actionMessage}>
      <Title>
        <span>Export</span>
        <FontAwesomeIcon icon={["fas", "cloud-download-alt"]} />
      </Title>
      <Message>{actionMessage}</Message>
      <br />
      <ButtonGroup style={{ width: "fit-content" }} spacing={20}>
        <IOButton
          cancel
          smallPadding
          onClick={() => dispatch(hideActionToast())}
        >
          Cancel
        </IOButton>
        <IOButton
          processing={scheduleLoading}
          smallPadding
          onClick={() => dispatch(scheduleExport(link, completeSettings))}
        >
          Schedule
        </IOButton>
      </ButtonGroup>
      <Arrow />
    </Container>
  );
}

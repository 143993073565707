import Flex from "../../UI/Flex/Flex";
import Pagination from "../../UI/Pagination/Pagination";
import { menuFilterDefaultPerPageValues } from "../../utils/constants/constants";
import formatter from "../../utils/formatters/formatter";

export default function FilterValuesPagination(props) {
  const {
    totalCount,
    quantityInStore,
    page,
    fetchFilterValues,
    searchValue,
    setPage,
  } = props;

  if (
    quantityInStore >= totalCount ||
    quantityInStore < menuFilterDefaultPerPageValues
  ) {
    return null;
  }

  const pages = parseInt(totalCount / menuFilterDefaultPerPageValues) + 1;

  function setFirstPage() {
    fetchFilterValues(searchValue, 1);
    setPage(1);
  }

  function setLastPage() {
    fetchFilterValues(searchValue, pages);
    setPage(pages);
  }

  function setNextPage() {
    fetchFilterValues(searchValue, page + 1);
    setPage(page + 1);
  }

  function setPreviousPage() {
    fetchFilterValues(searchValue, page - 1);
    setPage(page - 1);
  }

  return (
    <>
      <Pagination
        totalRows={totalCount}
        currentPage={page}
        position="center"
        first={page === 1 ? null : setFirstPage}
        last={page === pages ? null : setLastPage}
        next={page === pages ? null : setNextPage}
        previous={page === 1 ? null : setPreviousPage}
      />
      <Flex justifyContent="center">
        Total items: {formatter(totalCount, "integer")}
      </Flex>
    </>
  );
}

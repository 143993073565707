import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import QuarterRange from "./QuarterRange";
import SingleDay from "./SingleDay";
import CustomRange from "./CustomRange";

const Container = styled.div(
  ({ theme }) => `
    margin-top: 24px;
    left: 6px;
    & > span {
        color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.primary};
        padding: 0px 12px;
        font-size: 0.7em;
        margin-left: 10px;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`
);

export default function CustomDateFilters({
  dateType,
  recentMonthsChange,
  RecentPeriods,
  dateFiltersConfig,
  getPosition,
  dateConfig,
}) {
  const theme = useTheme();

  // single day
  if (dateType === "sd") {
    return (
      <SingleDay
        recentMonthsChange={recentMonthsChange}
        Container={Container}
        theme={theme}
      />
    );
  }

  // custom range
  if (dateType === "cr") {
    return (
      <Container>
        <CustomRange dateConfig={dateConfig} />
      </Container>
    );
  }

  // fiscal quarter range
  if (dateType === "fiscalQuarterRange") {
    return (
      <QuarterRange
        dateFiltersConfig={dateFiltersConfig}
        RecentPeriods={RecentPeriods}
        getPosition={getPosition}
        quarterChange={recentMonthsChange}
      />
    );
  }

  return null;
}

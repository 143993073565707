import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { lightenDarkenColor } from "../../styles/colorConvertor";

const Label = styled.label(
  ({ theme, size, inContent }) => `
    position: relative;
    display: inline-block;
    width: ${sizes[size].width}px;
    height: ${sizes[size].height}px;
    margin-right: 10px;
    & > input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    & > span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${
          inContent
            ? theme.type === "dark"
              ? lightenDarkenColor(theme.background.secondary, 40)
              : theme.gray.gray300
            : theme.menuPrimary
            ? lightenDarkenColor(theme.primary, -30)
            : theme.type === "dark"
            ? theme.gray.gray900 + " !important"
            : theme.gray.gray300 + "!important"
        };
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }
    & > span:before {
        position: absolute;
        content: "";
        top: ${sizes[size].top}px;
        bottom: 1.5px;
        height: ${sizes[size].circle}px;
        width: ${sizes[size].circle}px;
        left: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }
    & > input:checked + span {
        background-color: ${
          inContent
            ? theme.primary
            : theme.menuPrimary
            ? lightenDarkenColor(theme.background.primary, 40)
            : theme.background.primary
        };
    }
    & > input:checked + span:before {
        background-color: ${
          inContent
            ? theme.type === "dark"
              ? theme.background.secondary
              : theme.gray.gray300
            : theme.menuPrimary
            ? lightenDarkenColor(theme.primary, -30)
            : theme.gray.gray400
        };
    }
    & > input:focus + span {
        box-shadow: 0 0 1px ${theme.background.primary};
    }
    & > input:checked + span:before {
        -webkit-transform: translateX(${sizes[size].transform}px);
        -ms-transform: translateX(${sizes[size].transform}px);
        transform: translateX(${sizes[size].transform}px);
    }
`
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => sizes[props.size].font}px;
  color: ${(props) =>
    !props.inContent && props.theme.menuPrimary
      ? "white"
      : props.theme.text.secondary};
  & > span {
    margin-right: 10px;
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  }
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const sizes = {
  small: {
    width: 40,
    height: 19,
    transform: 20,
    circle: 16,
    top: 1.5,
    font: 14,
  },
  medium: {
    width: 80,
    height: 38,
    transform: 38,
    circle: 32,
    top: 3.5,
    font: 22,
  },
  large: {
    width: 120,
    height: 57,
    transform: 57,
    circle: 48,
    top: 4,
    font: 36,
  },
};

export default function Switcher(props) {
  const {
    handleSwitchChange,
    value,
    disabled,
    leftLabel,
    rightLabel,
    checked,
    size = "small",
    cy,
    inContent = true,
    wrapperCy,
    title,
    containerStyle,
    ...rest
  } = props;

  return (
    <Wrapper
      size={size}
      inContent={inContent}
      disabled={disabled}
      data-cy={wrapperCy}
      title={title}
      style={containerStyle}
    >
      {leftLabel && (
        <span
          onClick={(e) => !disabled && checked && handleSwitchChange(e)}
          data-cy="switcher-left-label"
        >
          {leftLabel}
        </span>
      )}

      <Label size={size} inContent={inContent}>
        <input
          disabled={props.disabled}
          data-cy={cy}
          type="checkbox"
          onChange={handleSwitchChange}
          checked={checked}
          {...rest}
        />
        <span />
      </Label>

      <span
        onClick={(e) => !disabled && !checked && handleSwitchChange(e)}
        data-cy="switcher-right-label"
      >
        {rightLabel}
      </span>
    </Wrapper>
  );
}

Switcher.propTypes = {
  handleSwitchChange: PropTypes.func.isRequired,
  cy: PropTypes.string,
  checked: PropTypes.bool,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  inContent: PropTypes.bool,
  disabled: PropTypes.bool,
};

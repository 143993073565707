import React from "react";

export default function FixedRowHeader(props) {
  const { colSpan, color, label } = props;
  return (
    <tr>
      <td style={{ padding: "16px 0 8px 0" }} colSpan={colSpan}>
        <div
          style={{
            background: color || null,
            padding: "8px 12px",
            textAlign: "left",
            borderRadius: 4,
            fontWeight: color ? 700 : 400,
          }}
        >
          {label}
        </div>
      </td>
    </tr>
  );
}

import React, { useRef, useState, forwardRef, useCallback } from "react";
import styled from "@emotion/styled";
import ButtonOutline from "../ButtonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeGray } from "../../../styles/styledSnippets";
import useApiImage from "../useApiImage";

const Container = styled.div`
  width: 400px;
  height: 340px;
  border: 1px solid ${themeGray(400)};
  background: ${themeGray(200)};
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  margin-left: -90px;
  margin-top: -340px;
  position: fixed;
`;

const UploadButton = styled.button`
  color: ${themeGray(800)};
  background-color: ${themeGray(200)};
  border: 1px solid ${(props) => props.theme.gray.gray400};
  padding: 10px 20px;
  margin: 10px 0;
  ${(props) =>
    props.backgroundColor
      ? `
    border: none;
    background: ${props.theme.notification.infoMain};
    color: white;
    margin-left: 8px;
  `
      : ""}

  border-radius: 4px;
  cursor: pointer;
`;

const PreviewContainer = styled.div`
  width: 360px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  background-color: white;
  border: 1px solid ${themeGray(300)};
`;
const NoImage = styled.div`
  height: 200px;
  width: 360px;
  font-size: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${themeGray(700)};
  border: 1px solid ${themeGray(300)};
  background: ${themeGray(800)};
`;

const ActiveTableImageEditor = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);

  const existingImage = props.data[props.colDef.field];
  const { image } = useApiImage(existingImage);

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
      const reader = new FileReader();

      reader.onload = function (e) {
        setPreviewImage(e.target.result);
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  const onUploadButtonClick = () => {
    inputRef.current.click();
  };

  const containerRefCallback = useCallback((current) => {
    if (current) {
      current.parentElement.style.zIndex = 1002;
    }
  }, []);

  function handleSave() {
    const nextRowData = { ...props.data, [props.column?.getColId()]: file };
    const event = {
      rowIndex: props.rowIndex,
      colDef: props.column.colDef,
      data: nextRowData,
      value: file,
    };
    props.handleChange(event);
    props.stopEditing();
  }

  return (
    <Container ref={containerRefCallback}>
      <div style={{ marginBottom: 24, fontWeight: 500 }}>
        Select your image and save
      </div>

      <input
        ref={inputRef}
        id="imageUpload"
        style={{ display: "none" }}
        type="file"
        onChange={onFileChange}
      />
      {previewImage ? (
        <PreviewContainer
          style={{ backgroundImage: `url(${previewImage})` }}
        ></PreviewContainer>
      ) : existingImage ? (
        <PreviewContainer
          style={{ backgroundImage: `url(${image?.data})` }}
        ></PreviewContainer>
      ) : (
        <NoImage>
          <FontAwesomeIcon icon={["fas", "image"]} />
        </NoImage>
      )}
      <div style={{ position: "absolute", top: 2, right: 4 }}>
        <ButtonOutline hideOutline onClick={() => props.stopEditing()}>
          <FontAwesomeIcon icon={["fal", "times"]} color="black" />
        </ButtonOutline>
      </div>
      <label htmlFor="imageUpload">
        <UploadButton onClick={onUploadButtonClick}>
          {previewImage ? "Change" : "Upload"} Image
        </UploadButton>
      </label>
      <label htmlFor="imageUpload">
        <UploadButton backgroundColor onClick={handleSave}>
          Save
        </UploadButton>
      </label>
    </Container>
  );
});

export default ActiveTableImageEditor;

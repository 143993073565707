import React from "react";
import styled from "@emotion/styled";
import Tipper from "../../../UI/Tipper/Tipper";

const Container = styled.div`
  width: 200px;
  height: 100px;
  overflow: hidden;
`;

export default function ExternalTag(props) {
  const frame = (
    <div>
      <iframe
        scrolling="no"
        src={props.draftValue}
        title="External Tag"
        width={728}
        height={90}
        frameBorder={0}
        marginWidth={0}
      />
    </div>
  );

  return (
    <Tipper noBg label={frame}>
      <Container>{frame}</Container>
    </Tipper>
  );
}

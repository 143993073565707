import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../styles/theme";
import { NavLink } from "react-router-dom";

const MenuItem = styled.div(
  ({ theme }) => `
    color: ${theme.text.secondary};
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 2px;
    border-radius: 4px;
    &:hover {
        color: ${theme.text.primary};
        & > div {
            path {
                fill: ${theme.text.primary};
            }
        }
    }
`
);

const Icon = styled.div(
  ({ theme }) => `
    font-size: 1em;
    margin: 10px;
    path {
        fill: ${theme.text.secondary};
    };
`
);

const Label = styled.p`
  margin: 10px;
`;

export default function DropdownMenuItem(props) {
  const { name, icon, clicked, cy, path } = props;

  const menuItem = (
    <MenuItem onClick={clicked} data-cy={cy}>
      {icon ? (
        <Icon>
          <FontAwesomeIcon
            fixedWidth
            style={{ color: theme.primary, fontSize: "1em" }}
            icon={["fas", icon]}
          />
        </Icon>
      ) : null}
      <Label>{name}</Label>
    </MenuItem>
  );

  if (!path) return menuItem;

  return <NavLink to={path}>{menuItem}</NavLink>;
}

DropdownMenuItem.defaultProps = {
  clicked: () => null,
};

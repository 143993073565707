import { useTheme } from "emotion-theming";
import DatePickerContainer from "../../UI/DatePicker/DatePickerContainer";
import { FILTER_TYPE_SINGLE_DATE } from "../../utils/constants/constants";
import { useEffect, useState } from "react";
import format from "date-fns/format";

const currentDate = format(new Date(), "yyyy-MM-dd");

export default function SingleDate({ select, filterType = {} }) {
  const theme = useTheme();
  const [date, setDate] = useState(currentDate);

  useEffect(() => {
    if (filterType.type === FILTER_TYPE_SINGLE_DATE && select) {
      select(
        [
          {
            key: filterType.name,
            type: filterType.name,
            checked: true,
            value: date,
            label: date,
          },
        ],
        FILTER_TYPE_SINGLE_DATE
      );
    }
  }, [date, filterType.name, filterType.type, select]);

  if (filterType.type !== FILTER_TYPE_SINGLE_DATE) {
    return null;
  }

  return (
    <DatePickerContainer menuPrimary={theme.menuPrimary}>
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        data-cy={"single-date-input"}
      />
    </DatePickerContainer>
  );
}

import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const ErrorField = styled.div(
  ({ theme }) => `
    font-size: 12px;
    color: ${theme.notification.errorMain};
    display: flex;
    padding: 5px 20px;
`
);

export default function FieldErrors({ errors }) {
  if (!errors) {
    return null;
  }

  return (!Array.isArray(errors) ? [errors] : errors).map((message, index) => (
    <ErrorField key={index}>{message}</ErrorField>
  ));
}

FieldErrors.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

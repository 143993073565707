import React from "react";
import styled from "@emotion/styled";
import {
  themeColor,
  themeGrayIf,
  themeText,
} from "../../../styles/styledSnippets";

const Container = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${themeText("secondary")};
  ${(props) =>
    props.collapsed
      ? `
      border: 1px solid ${themeGrayIf(700, 300)(props)};
      border-radius: 4px;
      width: 100%;
      padding: 12px 0
    `
      : ""}
`;

const size = 12;
const Circle = styled.div`
  background: ${themeColor("infoMain")};
  border-radius: 50%;
  height: ${size}px;
  width: ${size}px;
  margin: 0 8px 0 12px;
  ${(props) =>
    !props.collapsed
      ? `
    background: none;
    border: 1px solid ${props.theme.notification.infoMain};
    `
      : ""}
`;
export default function GridSplitCollapse(props) {
  const { selection, handleToggle, collapsed } = props;
  return (
    <Container
      data-cy="active-collapse-toggle"
      onClick={handleToggle}
      collapsed={collapsed}
    >
      <Circle title="Collapse/Expand section" collapsed={collapsed} />
      {selection ?? "Empty Value"}
    </Container>
  );
}

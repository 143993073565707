import React from "react";
import styled from "@emotion/styled";
import BlueprintSampleTable from "./BlueprintSampleTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import IOButton from "../../../../UI/Form/Button/IOButton";
import Flex from "../../../../UI/Flex/Flex";
import Select from "../../../../UI/Form/Select/Select";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { format, isValid } from "date-fns";
import { PlayCircle } from "../PlayCircle";
import { processRulesetSingleChain } from "../../../../store/actions";
import { useDispatch } from "react-redux";

const Container = styled.div`
  color: ${(props) => props.theme.text.primary};
`;

const Info = styled.div`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
`;

const Process = styled.div`
  font-size: 12px;
  margin-left: 30px;
`;

export default function BlueprintOutputPreviewHome(props) {
  const { chain, sample, etlChains } = props;
  const history = useHistory();
  const { blueprintGroupIndex } = useParams();
  const dispatch = useDispatch();

  function setCleanTableName(chain) {
    return {
      ...chain,
      tableName: chain?.tableName + "_clean_" + blueprintGroupIndex,
    };
  }

  function changePreviewTable(chain) {
    history.replace(
      `/admin/data-blueprint/${chain.uuid}/preview/${blueprintGroupIndex}`
    );
  }

  function getProcessedAt() {
    const date = new Date(chain?.processingEndedAt);

    if (!isNaN(date) && isValid(date)) {
      return format(date, "MM/dd/yyy  HH:MM");
    }

    return chain?.processingEndedAt;
  }

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="end">
        <Flex>
          <div>
            <Info>Status: {chain?.status}</Info>
            <Info data-cy="processed-at">Processed at: {getProcessedAt()}</Info>
          </div>
          <Process>
            <PlayCircle
              onClick={() =>
                dispatch(
                  processRulesetSingleChain(chain.uuid, chain?.tableName)
                )
              }
            />
          </Process>
        </Flex>

        <Flex width="600px" justifyContent="space-between" alignItems="end">
          <div style={{ width: "300px" }}>
            <Select
              label="Change Output Table"
              getOptionLabel={(option) => option.tableName}
              getOptionValue={(option) => option.uuid}
              options={(etlChains ?? []).map(setCleanTableName)}
              onChange={changePreviewTable}
              value={setCleanTableName(chain)}
            />
          </div>
          <Link to="/admin/data-blueprint">
            <IOButton cy="back" cancel>
              Back
            </IOButton>
          </Link>
        </Flex>
      </Flex>

      <BlueprintSampleTable data={sample} />
    </Container>
  );
}

import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import Switcher from "../../../../UI/Switcher/Switcher";
import { useTheme } from "emotion-theming";
import ActiveFormatOverrides from "./ActiveFormatOverrides";
import useFormatOverrides from "./useFormatOverrides";
import CloneFormatOverrideGroup from "./CloneFormatOverrideGroup";
import Flex from "../../../../UI/Flex/Flex";
import { Note } from "../General/LimitRows/LimitRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  padding: 20px;
`;

export default function FormatOverrides(props) {
  const { chart, setChartState, previewChart } = props;
  const theme = useTheme();

  const { properties, callbacks } = useFormatOverrides({
    chart,
    previewChart,
    setChartState,
  });

  const {
    setPair,
    enableDisableFormatOverrides,
    onConditionChange,
    onOverrideItemChange,
    onOverrideItemRemove,
    onOverrideItemAdd,
    removeFormatOverrideGroup,
    cloneFormatOverrideGroup,
  } = callbacks;

  const {
    pair,
    pairs,
    disabled,
    formatOverrides,
    conditionKeyOption,
    conditionKeyOptions,
    conditionValueOption,
    conditionValueOptions,
    groupedFormatOverrides,
  } = properties;

  return (
    <Container>
      <div>Format Overrides</div>
      <br />
      <Switcher
        rightLabel="Enable Row Overrides"
        checked={!!formatOverrides}
        handleSwitchChange={enableDisableFormatOverrides}
      />
      {!!formatOverrides && (
        <>
          <br />
          <br />
          <Flex alignItems="center">
            <Select
              options={pairs}
              label="Match Column | Value"
              value={pair}
              onChange={(pair) => setPair(pair)}
            />

            <CloneFormatOverrideGroup
              removeFormatOverrideGroup={removeFormatOverrideGroup}
              cloneFormatOverrideGroup={cloneFormatOverrideGroup}
              disabled={disabled}
            />
          </Flex>
          {disabled && (
            <Note color="infoDark">
              Format Override list is currently empty. Please click on the plus
              icon to add items.
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </Note>
          )}
          <br />
          <hr color={theme.background.secondary} />
          <Select
            options={conditionKeyOptions}
            label="Match Column"
            value={conditionKeyOption}
            onChange={(option) => onConditionChange(option, "conditionKey")}
            isDisabled={disabled}
          />
          <br />
          <Select
            options={conditionValueOptions}
            label="Match Value"
            value={conditionValueOption}
            onChange={(option) => onConditionChange(option, "conditionValue")}
            isDisabled={disabled}
          />
          <br />
          <hr color={theme.background.secondary} />
          <ActiveFormatOverrides
            formatOverrides={formatOverrides}
            disabled={disabled}
            conditionKeyOptions={conditionKeyOptions}
            groupedFormatOverrides={groupedFormatOverrides}
            onOverrideItemChange={onOverrideItemChange}
            onOverrideItemRemove={onOverrideItemRemove}
            onOverrideItemAdd={onOverrideItemAdd}
            pair={pair}
          />
        </>
      )}
    </Container>
  );
}

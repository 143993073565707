import { useState } from "react";

export default function useResetDateOptions() {
  let timeoutId;
  const [needRefresh, setNeedRefresh] = useState(false);

  function scheduleRefresh() {
    const now = new Date();
    const tomorrow = new Date();

    // Set the time we want to auto refresh the page
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 1, 0, 0); // 12:01:00.000 AM
    // Calculate the difference in milliseconds
    const timeUntilTomorrow = tomorrow.getTime() - now.getTime();
    // Set a timeout to refresh the page
    timeoutId = setTimeout(function () {
      setNeedRefresh(true);
    }, timeUntilTomorrow);
  }
  // Schedule the first refresh when the page loads
  scheduleRefresh();
  // Also clear and re-schedule the refresh whenever the user interacts with the page
  window.addEventListener("click", function () {
    setNeedRefresh(false);
    clearTimeout(timeoutId);
    scheduleRefresh();
  });

  return {
    needRefresh,
  };
}

import React from "react";
import styled from "@emotion/styled";

const Container = styled.div(
  ({ theme }) => `
  width: 90%;
  background: ${theme.background.secondary};
  position: absolute;
  margin-left: 2px;
  top: 0px;
  left: 0px;
`
);

function clearNumber(str) {
  return (str || "")
    .toString()
    .replaceAll(",", "")
    .replaceAll("$", "")
    .replaceAll("£", "");
}

const isPositive = (width, reverseColor) => {
  if (!reverseColor && reverseColor !== 0) {
    return parseFloat(clearNumber(width)) > 0;
  }
  return reverseColor > 0 && parseFloat(clearNumber(width)) > 0;
};

const getFilledWidth = (width) => {
  const number = Math.abs(parseFloat(clearNumber(width)));

  if (number > 100) {
    return "100%";
  }

  return (!number || !isFinite(number) ? 0 : number) + "%";
};

const getBadDebtColor = (badDebt, width, reverseColor) => {
  if (badDebt) {
    return width === "100%" ? "infoMain" : "errorMain";
  }

  return isPositive(width, reverseColor) > 0 ? "infoMain" : "errorMain";
};

const Bar = styled.div(
  ({ theme, width, reverseColor, badDebt, color }) => `
  width: ${getFilledWidth(width)};
  height: 4px;
  background: ${
    color || theme.notification[getBadDebtColor(badDebt, width, reverseColor)]
  };
`
);

export default React.memo(function ProgressHorizontal({
  width,
  reverseColor,
  badDebt,
  progressBarTotals,
  cellKey,
  progressBarsSettings,
}) {
  const isReversed = (badDebt && width === "100%") || reverseColor;

  function preCalculateWidth() {
    if (!progressBarTotals || !cellKey) {
      return width;
    }

    const total = progressBarTotals[cellKey];
    const widthToNumber = parseFloat(clearNumber(width));
    const res = (widthToNumber / total) * 100;

    return res.toString();
  }

  return (
    <Container>
      <Bar
        width={preCalculateWidth()}
        reverseColor={isReversed}
        badDebt={badDebt}
        color={progressBarsSettings?.color}
      />
    </Container>
  );
});

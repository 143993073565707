import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import SliderValueInput from "./SliderValueInput";
const Values = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: ${(props) => props.theme.text.secondary};
`;

export default function SliderValues(props) {
  const { minValue, maxValue, format, handleRangeChange, limitMin, limitMax } =
    props;
  const [editingMin, setEditingMin] = useState(false);
  const [editingMax, setEditingMax] = useState(false);
  const [tempMin, setTempMin] = useState(minValue);
  const [tempMax, setTempMax] = useState(maxValue);

  useEffect(() => {
    setTempMin(minValue);
    setTempMax(maxValue);
  }, [minValue, maxValue]);

  function inRange(value) {
    return value >= limitMin && value <= limitMax;
  }

  function changeMin(e) {
    setTempMin(e.target.value);
    if (inRange(e.target.value)) {
      handleRangeChange(e.target.value, maxValue);
    }
  }

  function changeMax(e) {
    setTempMax(e.target.value);
    if (inRange(e.target.value)) {
      handleRangeChange(minValue, e.target.value);
    }
  }

  return (
    <Values>
      <SliderValueInput
        editing={editingMin}
        setEditing={setEditingMin}
        onChange={changeMin}
        value={tempMin}
        format={format}
        error={!inRange(tempMin)}
      />
      <SliderValueInput
        editing={editingMax}
        setEditing={setEditingMax}
        onChange={changeMax}
        value={tempMax}
        format={format}
        error={!inRange(tempMax)}
      />
    </Values>
  );
}

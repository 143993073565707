import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { rowIndexStrictComparison } from "../functions/getExpandedRow";

const ExpanderIcon = styled.span(
  ({ theme, icon }) => `
  margin-right: 10px;
  color: ${icon === "plus" ? theme.primary : theme.notification.errorMain};
`
);

export default React.memo(function ExpandRowIcon({
  i,
  hasRowExpand,
  rowIndexes,
  index,
  tableRowIndex,
}) {
  const icon = rowIndexStrictComparison(rowIndexes, index, tableRowIndex)
    ? "minus"
    : "plus";

  if (hasRowExpand && i === 0) {
    return (
      <ExpanderIcon
        title={`Click to ${icon === "plus" ? "expand" : "close"} row.`}
        icon={icon}
      >
        <FontAwesomeIcon icon={["fas", icon]} />
      </ExpanderIcon>
    );
  }

  return null;
});

import React, { useCallback } from "react";
import formatter from "../../utils/formatters/formatter";
import styled from "@emotion/styled";

const TypeInput = styled.input`
  width: 80px;
  background: rgba(255, 255, 255, 0.2);
  color: ${(props) =>
    props.error
      ? props.theme.notification.errorMain
      : props.theme.text.primary};
  border: 1px solid ${(props) => (props.error ? "red" : "transparent")};
`;
export default function SliderValueInput(props) {
  const { setEditing, onChange, value, format, editing, error } = props;
  const getLabel = useCallback(
    (val) => {
      return formatter(val, format ?? ".3s");
    },
    [format]
  );
  return editing ? (
    <div
      style={{
        width: 80,
        fontSize: 14,
      }}
    >
      <TypeInput
        value={value}
        onChange={onChange}
        onBlur={() => setEditing(false)}
        error={error}
      />
    </div>
  ) : (
    <span onClick={() => setEditing(true)}>{getLabel(value)}</span>
  );
}

/**
 * Creates a queue that processes one async callback at a time.
 * When you push to it, processing begins immediately automatically if not
 * processing already.
 */
export function createAsyncTaskQueue() {
  const tasks = [];
  let tasksProcessing = false;

  async function push(taskCallback) {
    tasks.push(taskCallback);

    if (!tasksProcessing) {
      tasksProcessing = true;
      processTasks();
    }
  }

  return { push };

  async function processTasks() {
    while (tasks.length > 0) {
      const task = tasks.shift();
      try {
        await task();
      } catch (e) {}
    }

    tasksProcessing = false;
  }
}

/**
 * Wrapper around thunks to limit processing them to one at a time.
 * A queue must have been created beforehand.
 *
 * @param queue {{push: Function}}
 * @param thunk {(dispatch: Function, getState: Function) => any}
 * @returns {function(*, *): Promise}
 */
export function wrapThunkInQueue(queue, thunk) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const task = async () => {
        try {
          const result = await thunk(dispatch, getState);
          resolve(result);
        } catch (e) {
          reject(e);
        }
      };
      queue.push(task);
    });
  };
}

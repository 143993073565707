import { useEffect } from "react";

import { useHistory } from "react-router";
import { useTheme } from "emotion-theming";
import { useDispatch, useSelector } from "react-redux";

import ChartFactory from "../../../charts/ChartFactory/ChartFactory";
import { getMonitoringHistory } from "../../../store/actions/monitoring";
import Loading from "../../../UI/Loading/Loading";
import Flex from "../../../UI/Flex/Flex";

import {
  buildTableChartConfig,
  buildTableChartConfigData,
  getNonEmptyResuts,
} from "./monitoringHelper";

import TopElements from "./TopElements";
import DateFilters from "./DateFilters";

export default function MonitoringHistory() {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { historyLoading, monitoringHistory } = useSelector(
    (state) => state.monitoring
  );

  const pages = useSelector((state) => state.pageManagement.pages);

  function goBack() {
    history.replace("/admin/monitoring");
  }

  useEffect(() => {
    dispatch(getMonitoringHistory());
  }, [dispatch]);

  const list = getNonEmptyResuts(monitoringHistory.list);
  const data = buildTableChartConfigData(list, pages);
  const chart = buildTableChartConfig(data, theme);

  return (
    <Flex direction="column">
      <TopElements
        goBack={goBack}
        monitoringHistory={monitoringHistory}
        dispatch={dispatch}
        getMonitoringHistory={getMonitoringHistory}
      />

      <br />

      <DateFilters
        dispatch={dispatch}
        getMonitoringHistory={getMonitoringHistory}
      />

      <br />
      <Flex alignItems="center" style={{ minHeight: 300 }}>
        {historyLoading ? (
          <Loading />
        ) : (
          <div style={{ width: "100%" }}>
            <ChartFactory chart={chart} type="TableChart" />
          </div>
        )}
      </Flex>
    </Flex>
  );
}

import React from "react";
import AlertMessage from "../../../UI/Alerts/AlertMessage";
import IntegrationLogo from "../Integrations/IntegrationLogo";
import styled from "@emotion/styled";
import DataMapConnections from "./DataMapConnections";

const RightBorder = styled.div`
  border-right: 1px solid ${(props) => props.theme.primary};
  width: 1px;
`;

const Line = styled.div`
  width: 38px;
  height: 1px;
  background: ${(props) => props.theme.primary};
`;

export default function DataMapStage(props) {
  const { flows } = props;
  if (!flows)
    return (
      <AlertMessage
        cy="no-connections"
        message="You don't have any connections available"
        status="info"
      />
    );

  const connectionKeys = ["databases", "uploads", "salesforce", "ga"];

  return connectionKeys.map((ck) =>
    flows[ck]?.length ? (
      <div key={ck} style={{ display: "flex", marginBottom: 18 }}>
        <div
          data-cy={`${ck}-root-container`}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IntegrationLogo typeKey={ck} circle />
        </div>
        <div style={{ width: 38, padding: "45px 0", display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Line />
          </div>
          <RightBorder />
        </div>
        {/*column 2*/}
        <div>
          <DataMapConnections flows={flows[ck]} type={ck} />
        </div>
      </div>
    ) : null
  );
}

import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import mobileBreakpoints from "../../styles/mobileBreakpoints";

const bg = (theme) =>
  theme.menuPrimary ? theme.primary : theme.background.secondary;
const lightenAmount = (theme) =>
  theme.menuPrimary || theme.type === "dark" ? 25 : -25;

const Container = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    overflow-y: scroll;
    height: 100vh;
      &::-webkit-scrollbar {
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${bg(theme)};
        margin-top: 90px;
        margin-bottom: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: none;
        border-radius: 8px;
    }   
    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: ${lightenDarkenColor(
              bg(theme),
              lightenAmount(theme)
            )};
        }    
    }

    &:focus {
        outline: 0;
    }

    @media (max-width: ${mobileBreakpoints.mobile}) {
      height: 80vh;
    }
`
);

export default function LeftMenuWrapper(props) {
  return <Container data-cy="left-menu-container">{props.children}</Container>;
}

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

export default function Form(props) {
  const { fields, required, initial, onSubmit, autoComplete } = props;

  const init = useCallback(() => {
    const values = {};
    const touched = {};
    const errors = {};

    fields.forEach((field) => {
      values[field] = isRequiredValueSet(initial?.[field])
        ? initial[field]
        : "";
      touched[field] = false;
    });

    required.forEach((field) => (errors[field] = { type: "required" }));

    const formValid = required.length
      ? required.every((field) => values[field].length > 0)
      : false;

    return {
      id: initial && initial.id,
      touched,
      errors,
      values,
      formValid,
      active: null,
    };
  }, [fields, initial, required]);

  const [state, setState] = useState(init());

  useEffect(() => {
    if (!Object.values(state.touched).some((t) => t)) {
      setState(init());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, initial]);

  const updatedErrors = (name) => {
    const updatedErrors = { ...state.errors, [name]: undefined };

    return Object.entries(updatedErrors).reduce((acc, curr) => {
      const error = state.errors[curr[0]];
      const value = state.values[curr[0]];
      const hasRequired = error && error.type === "required";

      return {
        ...acc,
        [curr[0]]:
          hasRequired && isRequiredValueSet(value) ? undefined : curr[1],
      };
    }, {});
  };

  const isFormValid = (errors) =>
    !fields.some((fieldName) => typeof errors[fieldName] !== "undefined");

  const set = (event, field) => {
    const name =
      field && field !== "checked" ? field : event.target.getAttribute("name");
    const errors = updatedErrors(name);
    const formValid = isFormValid(errors);

    const newValue = field && field !== "checked" ? event : event.target.value;
    const values = { ...state.values, [name]: newValue };
    const touched = { ...state.touched, [name]: true };

    setState({ ...state, touched, values, errors, formValid });
  };

  const setError = (event, type) => {
    const name = event.target.getAttribute("name");
    const values = { ...state.values, [name]: event.target.value };
    const errors = { ...state.errors, [name]: { type } };
    const touched = { ...state.touched, [name]: true };

    setState({ ...state, touched, values, errors, formValid: false });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = state.id ? { ...state.values, id: state.id } : state.values;
    onSubmit(payload);
  };

  const handleFocus = (event) => {
    if (!event) {
      setState({ ...state, active: null });
    } else {
      const name = event.target.getAttribute("name");
      setState({ ...state, active: name });
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete={autoComplete}>
      {props.children({ ...state, set, setError, setFocus: handleFocus })}
    </form>
  );
}

function isRequiredValueSet(value) {
  return typeof value === "boolean" ? true : !!value;
}

Form.defaultProps = {
  required: [],
};

Form.propTypes = {
  fields: PropTypes.array.isRequired,
  initial: PropTypes.object,
  onSubmit: PropTypes.func,
  autoComplete: PropTypes.string,
};

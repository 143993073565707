import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Td } from "./utils";

const TableRow = styled.tr(
  ({ theme }) => `
  td {
    background: ${theme.gray.gray100};
    vertical-align: middle;
    color: black;
    height: 40px;
  }

  td:first-of-type {
    border-radius: 10px 0 0 10px;
    text-align: center;
    svg {
      color: ${theme.notification.warningDark};
      
    }
  }

  td:last-of-type {
    border-radius: 0 10px 10px 0;
    svg {
      color: ${theme.notification.errorDark};
      cursor: pointer;
    }
  }

`
);

export default function Rows({ rows, keys, confirm, getColumnTextAlign }) {
  return (
    <tbody>
      {rows.map((row, index) => (
        <TableRow key={"row-" + index}>
          <td>
            <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
          </td>

          {keys.map((key, tdIndex) => (
            <Td key={key + tdIndex + index} align={getColumnTextAlign(key)}>
              {row[key]}
            </Td>
          ))}

          <td>
            <FontAwesomeIcon
              icon={["fas", "trash"]}
              onClick={() => confirm.setConfirming(row)}
            />
          </td>
        </TableRow>
      ))}
    </tbody>
  );
}

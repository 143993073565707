import React, { useCallback } from "react";
import FilterGroup from "./FilterGroup";
import DeluxeBoolean from "../Filters/DeluxeFilter/DeluxeBoolean";

export default function BooleanFilters(props) {
  const { booleanFilters, selectBoolean, currentTabId } = props;
  const renderBooleans = useCallback(() => {
    if (!booleanFilters?.length) {
      return null;
    }

    return booleanFilters.map((b) =>
      !b.hideOnPages?.includes(currentTabId) ? (
        <FilterGroup name={b.label} cy="boolean-filter-group" key={b.key}>
          <DeluxeBoolean
            key={b.key}
            onChange={(val) =>
              selectBoolean({
                ...val,
                key: b.key,
                hideOnPages: b.hideOnPages,
              })
            }
            name={b.label}
            checked={b.checked}
            value={b.value}
            cy="filter-boolean-input"
            onLabelOverride={b.onLabelOverride}
            offLabelOverride={b.offLabelOverride}
          />
        </FilterGroup>
      ) : null
    );
  }, [booleanFilters, currentTabId, selectBoolean]);

  return renderBooleans();
}

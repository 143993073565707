import styled from "@emotion/styled";

const PADDING_X = 12;

export const DatePickerContainer = styled.div(
  ({ theme, menuPrimary }) => `
    input {
        width: calc(100% - ${PADDING_X * 2}px);
        margin-bottom: 12px;
        padding: 10px ${PADDING_X}px;
        outline: none;
        border: .01rem solid ${menuPrimary ? "white" : theme.divider};
        color: ${menuPrimary ? "white" : theme.text.secondary};
        border-radius: 4px;
        box-sizing: content-box;
        background: transparent;
        &::-webkit-calendar-picker-indicator {
            filter: invert(1) brightness(80%);
        }
        &:focus {
            border: 2px solid ${theme.menuPrimary ? "white" : theme.primary};
        }
    }
`
);

export default DatePickerContainer;

import React, { useMemo, useState } from "react";
import StackedBarTwo from "./StackedBarTwo";
import VisualizationBase from "../BaseChart/VisualizationBase";
import Details from "../../Layout/Details/Details";
import { schemeTableau10 } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";
import { unique } from "../../utils/func";
import Legend from "../Legend/Legend";
import xKeyParser from "../../utils/charts/xKeyParser";
import { getMappingType } from "../../utils/getVisualizationLabel";
import ChartActionsToggle from "../ChartActions/ChartActionsToggle";
import prepareStackedData, { getDataReducer } from "./prepareStackedData";
import styled from "@emotion/styled";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const defaultMargin = { top: 40, left: 50, bottom: 42, right: 50 };

/**
 *
 * @param props
 * @param props.yValueKeys This is an array of keys going into the Y values and determines what the stack components
 *  should be made of. Each item in this array can either be a string or an array.
 *  - If an item is a string
 *  then each row has a values for a single stack component at a time (but possibly for multiple groups), and the
 *  string acts as the filter/discriminator that tells you if that row of data belongs to that component stack.
 *  - If an item is an array, then that means that all stack components for a group appear in a single row.
 *    This array can in turn consist of either:
 *      1. an object with {"source": string, "target": string} where "source" is the key to take the data from and
 *          "target" is what stack component should be mapped to
 *      2. a simple string as a shorthand for if "source" and "target" would be the same.
 * @returns {JSX.Element}
 * @constructor
 */
export default function StackedBarTwoVisualization(props) {
  const { chart, details, dashboardId, term } = props;
  const {
    data,
    xKey,
    yFormat,
    xAxisDate,
    xAxisFormat,
    xKeyFormat,
    breakdownKey,
    // yValueKey, // not used
    yValueKeys: staticValueKeys, // Grouping / Bar sections
    overValue,
    overValueValues,
    visualizationId,
    ignoreDateTerm,
    xSort,
    xSortKey,
    yLabelFormat,
    yAxisFormat,
    ySortReverse,
    bands,
    tooltipFormat = "currency",
    meta,
    legendItems,
    orderByLegendNames,
    hideBarLabels,
    variableValueKeys,
    yKeys,
    legendHeight,
    colors,
    hideLegend,
    lineYKeys,
    circleYKeys,
    dashedLineYKeys,
    margin = {},
  } = chart;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineKyes = lineYKeys ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const circleKeys = circleYKeys ?? [];
  const dashedKeys = dashedLineYKeys ?? [];

  const [activeValueKey, setActiveValueKey] = useState(
    variableValueKeys?.length && variableValueKeys[0]
  );

  const mappedXKey = xKeyParser(term, xKey, ignoreDateTerm);
  const yValueKeys = staticValueKeys || [activeValueKey?.valueKey];
  const yKey = chart.yKey ?? yValueKeys[0];
  const positiveValuesOnly = data.filter((d) => (!yKey ? d : d[yKey] >= 0));

  const sortedData = useMemo(
    () => [...positiveValuesOnly],
    [positiveValuesOnly]
  );
  if (xSort) {
    const keyToSortWith = xSortKey || mappedXKey;
    sortedData.sort((a, b) =>
      a[keyToSortWith] < b[keyToSortWith]
        ? -1
        : a[keyToSortWith] > b[keyToSortWith]
        ? 1
        : 0
    );
  }

  const defaultColorFn = scaleOrdinal(schemeTableau10);

  let colorsFn = colors
    ? (i) => colors[i] ?? defaultColorFn(i)
    : defaultColorFn;

  const yValueForTotals = yValueKeys ? yValueKeys[0] : null; // If yValueKey not used, where does it go?
  const allKeysWithTotals = useMemo(() => {
    return sortedData.reduce(
      getDataReducer(yValueForTotals, breakdownKey, yKeys),
      {}
    );
  }, [breakdownKey, sortedData, yValueForTotals, yKeys]);

  const mappedWithTotals = Object.entries(allKeysWithTotals).map((e) => ({
    segmentKey: e[0],
    value: e[1],
  }));

  mappedWithTotals.sort((a, b) => {
    if (a.value === b.value) {
      return 0;
    }

    if (a.value < b.value) {
      return ySortReverse ? -1 : 1;
    }

    return ySortReverse ? 1 : -1;
  });

  if (legendItems) {
    const legendKeys = legendItems.map((v) => v.name);
    mappedWithTotals.sort((a, b) => {
      const indexA = legendKeys.indexOf(a.segmentKey);
      const indexB = legendKeys.indexOf(b.segmentKey);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // All date keys listed
  // These need to happen for both versions
  const allXKeys = unique(sortedData.map((d) => d[mappedXKey])).filter(
    (v) => v !== undefined
  );

  // this need tp keep order like in legend config
  const names = orderByLegendNames ? legendItems.map((li) => li.name) : null;

  const { keys, convertedArray, totals } = prepareStackedData(
    names,
    mappedWithTotals,
    allXKeys,
    sortedData,
    mappedXKey,
    yValueKeys,
    breakdownKey,
    yKeys
  );

  const tooltipConfig = keys.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr]: { label: getLabel(curr), format: tooltipFormat },
      };
    },
    {
      x: { label: "Date", format: "string" },
    }
  );
  function getLabel(curr) {
    const match = meta && meta.fields.find((f) => f.alias === curr);
    return match?.label || curr;
  }

  const orderedByLegendItems = useMemo(() => {
    if (!legendItems) {
      return [...keys, ...lineKyes, ...circleKeys];
    }

    const legendKeys = legendItems.map((li) => li.name);

    return [...keys, ...lineKyes, ...circleKeys].sort(
      (a, b) => legendKeys.indexOf(a) - legendKeys.indexOf(b)
    );
  }, [circleKeys, keys, legendItems, lineKyes]);

  return (
    <>
      <Container id={visualizationId}>
        <VisualizationBase
          {...{ ...props, margin: { ...defaultMargin, ...margin } }}
          tooltipConfig={tooltipConfig}
        >
          <StackedBarTwo
            {...chart}
            data={convertedArray}
            keys={keys}
            xKeys={allXKeys}
            xAxisDate={xAxisDate}
            xAxisFormat={xAxisFormat}
            yAxisFormat={yAxisFormat || getMappingType(meta?.fields, yKey)}
            xKeyFormat={xKeyFormat}
            colors={colorsFn}
            yFormat={yFormat}
            summedValues={totals}
            overValue={overValue}
            overValueValues={overValueValues}
            yLabelFormat={yLabelFormat}
            term={term}
            bands={bands}
            legendItems={legendItems}
            hideBarLabels={hideBarLabels}
            initialData={data}
            circleKeys={circleKeys}
            lineKyes={lineKyes}
            dashedKeys={dashedKeys}
          />
        </VisualizationBase>
        <Legend
          colorFunction
          colors={colorsFn}
          horizontal
          sections={orderedByLegendItems}
          wrapping
          legendItems={legendItems}
          meta={meta}
          legendHeight={legendHeight}
          hide={hideLegend}
        />
        <ChartActionsToggle
          variableValueKeys={variableValueKeys}
          setVariableValueKey={setActiveValueKey}
          activeValueKey={activeValueKey}
        />
      </Container>
      {details && chart.data.length > 0 ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={visualizationId}
        />
      ) : null}
    </>
  );
}

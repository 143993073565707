import React from "react";
import styled from "@emotion/styled";

const LegendCircleContainer = styled.div`
  border-radius: 50%;
  height: ${(props) => (props.small ? 15 : 20)}px;
  width: ${(props) => (props.small ? 15 : 20)}px;
  margin-right: 6px;
  background: #f2efe9;
  box-sizing: border-box;
`;

const LegendCircle = styled.div`
  border-radius: 50%;
  background: ${(props) => props.color};
  height: ${(props) => (props.small ? 15 : 20)}px;
  width: ${(props) => (props.small ? 15 : 20)}px;
  opacity: 0.8;
  box-sizing: border-box;
`;
const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.small ? 10 : 12)}px;
  margin-right: 18px;
  margin-bottom: 5px;
`;

export default function CircleLegend(props) {
  const {
    uniqueColorResults,
    uniqueColorMappings,
    colorBand,
    legendMappings = {},
    legendItems,
  } = props;

  const small = uniqueColorResults.length > 15;

  const renderLegend = (v, i, color) => {
    return (
      <LegendItem key={v + i} small={small}>
        <LegendCircleContainer small={small}>
          <LegendCircle color={color || colorBand(v)} small={small} />
        </LegendCircleContainer>
        {legendMappings[v] || v || "Unknown"}
      </LegendItem>
    );
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {legendItems
        ? legendItems
            .filter((item) => uniqueColorMappings.includes(item.name))
            .map((item, i) => renderLegend(item.name, i, item.color))
        : uniqueColorMappings.map((v, i) =>
            uniqueColorResults.find((r) => r === v) ? renderLegend(v, i) : null
          )}
    </div>
  );
}

import React from "react";
import NotificationItem from "../../UI/Alerts/NotificationItem";

export default function FileUploadReviewNotification() {
  return (
    <>
      <NotificationItem>
        <div className="item-inner">
          You currently do not have any notifications.
        </div>
      </NotificationItem>
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import LayoutSettings from "./Layout/LayoutSettings";
import ColumnSettings from "./Layout/Column/ColumnSettings";
import GeneralSettings from "./General/GeneralSettings";
import DrilldownHierarchy from "./Drilldown/DrilldownHierarchy";
import Filtering from "./Filtering/Filtering";
import MenuFilters from "./MenuFilters/MenuFilters";
import FormatOverrides from "./FormatOverrides/FormatOverrides";
import TooltipsSettings from "./Tooltips/TooltipsSettings";

function getDynamicWidth(isWide) {
  return isWide ? "40%" : "300px";
}

function getDynamicOffset(isWide) {
  return isWide ? "calc(-40% + 70px)" : "-232px";
}

const ColapsableMenu = styled.div(
  ({ theme, hide, isWide }) => `
  position: ${hide ? "absolute" : "relative"};
  width: ${getDynamicWidth(isWide)};
  background: ${lightenDarkenColor(theme.background.primary, -10)};
  margin-left: ${hide ? getDynamicOffset(isWide) : 0};
  transition: margin-left .5s;
  font-size: 12px;
  height: 100%;
  ${
    hide &&
    `
    z-index: 1;
  `
  };
`
);

const Arrow = styled.div`
  width: 20px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  right: -20px;
  z-index: 100;
  background: ${(props) => props.theme.primary};
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
`;

export default function SettingsMenu(props) {
  const components = {
    Layout: <LayoutSettings {...props} />,
    Settings: <GeneralSettings {...props} />,
    Drilldown: <DrilldownHierarchy {...props} chart={props.chartState} />,
    Filtering: <Filtering {...props} chart={props.chart} />,
    Column: <ColumnSettings {...props} {...props.chart} />,
    MenuFilters: (
      <MenuFilters showPreview={props.showPreview} chart={props.chart} />
    ),
    Rows: <FormatOverrides {...props} />,
    Tooltips: <TooltipsSettings {...props} />,
  };

  return (
    <ColapsableMenu hide={props.hideMenu} isWide={props.isFiltering}>
      <Arrow onClick={() => props.setHideMenu((prev) => !prev)}>
        <FontAwesomeIcon
          icon={["fas", props.hideMenu ? "angle-right" : "angle-left"]}
        />
      </Arrow>
      {components[props.navigationState]}
    </ColapsableMenu>
  );
}

import styled from "@emotion/styled";
import Input from "../../../UI/Form/Input/Input";
import Select from "../../../UI/Form/Select/Select";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";

const Container = styled.div`
  margin: 20px 0;
  width: 400px;
  border: 1px solid ${(props) => props.theme.divider};
  padding: 20px 10px;
  border-radius: 10px;
`;

export default function ActiveTableJoinedMode({
  joinedMode,
  setJoinedMode,
  tableColumns,
  columnsLoading,
  isActiveTableExist,
  isViewExist,
}) {
  const { mode, viewName, activeTableName, columnToJoinName } = joinedMode;

  function onJoinedModeChange(name, value) {
    setJoinedMode((prev) => ({ ...prev, [name]: value }));
  }

  const columnOptions = tableColumns
    // you cant use uuid column name for join column
    .filter((col) => col.name !== "uuid")
    .map((col) => ({
      ...col,
      value: col.name,
      label: col.name,
    }));

  return (
    <>
      <br />
      <Checkbox
        checked={!mode}
        cy="join-mode-toggle"
        onChange={() => onJoinedModeChange("mode", !mode)}
        label="Modify existing table (Not recommended for tables that are updated by ETL)"
      />

      {mode && (
        <Container>
          <Input
            label="Active Table (Table Name)"
            value={activeTableName}
            onChange={(e) =>
              onJoinedModeChange("activeTableName", e.target.value)
            }
            invalid={isActiveTableExist}
            errorMessage={
              isActiveTableExist
                ? "Table already exists on this connection in this database."
                : null
            }
            cy="active-table-table-name"
          />
          <br />
          <Input
            label="Output View Name (For use in Data Explorer and Table Editors)"
            value={viewName}
            onChange={(e) => onJoinedModeChange("viewName", e.target.value)}
            cy="joined-output-view-name"
            invalid={isViewExist}
            errorMessage={
              isViewExist
                ? "View already exists on this connection in this database."
                : null
            }
          />
          <br />
          <Select
            label="Unique field (This is required)"
            simpleValue={columnToJoinName}
            options={columnOptions}
            onChange={(option) => {
              onJoinedModeChange("columnToJoinName", option.value);
              onJoinedModeChange("columnToJoinType", option.type);
            }}
            isLoading={columnsLoading}
            cy="join-field"
          />
        </Container>
      )}
    </>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RangeArrow() {
  return (
    <div style={{ margin: "0 9px" }}>
      <FontAwesomeIcon icon={["fas", "arrow-right"]} />
    </div>
  );
}

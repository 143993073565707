import React from "react";
import styled from "@emotion/styled";
import {
  themeColor,
  themeGrayIf,
  themeText,
} from "../../styles/styledSnippets";
import PropTypes from "prop-types";

const speed = 0.1;

const Item = styled.div`
  margin-bottom: 4px;
  color: ${(props) => (props.active ? "white" : themeText("secondary")(props))};
  font-weight: 300;
  cursor: ${(props) => (props.locked ? "cursor" : "pointer")};
  position: relative;
  padding: 4px 12px;
  box-sizing: border-box;
  font-size: 14px;
  border-bottom: 1px solid transparent;
  opacity: ${(props) => (props.locked ? 0.25 : 1)};
  background: ${(props) =>
    props.active ? props.theme.notification.infoMain : "transparent"};
  svg {
    font-size: 12px;
    transition: left ${speed}s, right ${speed}s linear;
    path {
      fill: ${themeColor("warningDark")};
    }
    position: absolute;
    ${(props) =>
      props.left
        ? `
    right: 18px;
    top: 4px;
  `
        : `
    left: 6px;
  `}
    color: ${themeGrayIf(900, 700)};
    opacity: 0;
  }
  &:hover {
    color: ${(props) => (props.active ? "white" : themeText("primary")(props))};
    border-bottom: 1px solid
      ${(props) =>
        props.noArrow || props.locked || props.colorMode
          ? "transparent"
          : themeColor("warningDark")(props)};
    svg {
      opacity: 1;
      ${(props) =>
        props.left
          ? `
    right: 12px;
    top: 4px;
  `
          : `
    left: 4px;
  `}
    }
  }
`;

const BigInputItem = (props) => {
  return <Item {...props}>{props.children}</Item>;
};

BigInputItem.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BigInputItem;

import React, { useCallback } from "react";
import styled from "@emotion/styled";
import Input from "../../../../UI/Form/Input/Input";

const Container = styled.div`
  label {
    color: black;
  }

  input {
    height: 38px;
    width: 100%;
  }
`;

export default function ColumnWidth({
  setVisualization,
  column,
  visualization,
}) {
  const { [column]: width } = visualization.settings.dynamicColumnsWidth ?? {};

  const onWidthChange = useCallback(
    (e) => {
      setVisualization((prev) => {
        return {
          ...prev,
          settings: {
            ...prev.settings,
            dynamicColumnsWidth: {
              ...prev.settings.dynamicColumnsWidth,
              [column]: +e.target.value,
            },
          },
        };
      });
    },
    [column, setVisualization]
  );

  return (
    <Container>
      <Input
        label="Width"
        onChange={onWidthChange}
        type="number"
        min={1}
        value={width}
        inPlaceEdit
        whiteBg
      />
    </Container>
  );
}

export function getTenantDomainFromURL(url) {
  return new URL(url).hostname.split(".")[0];
}

export function getCurrentTenantDomain() {
  return (
    process.env.REACT_APP_DEFAULT_DOMAIN ||
    window.location.hostname.split(".")[0]
  );
}

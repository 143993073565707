import React from "react";
import CircleMarker from "./CircleMarker";
import TriangleMarker from "./TriangleMarker";

export default function CustomMarkerContainer(props) {
  const {
    d,
    radius,
    colorBand,
    colorKey,
    colorLabel,
    setActivePopup,
    dynamicColor,
    zoomLevel,
    labeledColumns = [],
    triangleShapeOptions = {},
    markersLength,
    legendItems,
    colorsConfig,
  } = props;

  const { column, value } = triangleShapeOptions;

  function opacity() {
    return Math.max(0.3, Math.min(1, 0.1 * zoomLevel));
  }

  const showTooltip =
    labeledColumns.length > 0 && zoomLevel > 8 && markersLength <= 30;

  if (column && d[column] === value) {
    return (
      <TriangleMarker
        d={d}
        dynamicColor={dynamicColor}
        colorBand={colorBand}
        colorLabel={colorLabel}
        colorKey={colorKey}
        opacity={opacity}
        showTooltip={showTooltip}
        labeledColumns={labeledColumns}
        setActivePopup={setActivePopup}
      />
    );
  }

  return (
    <CircleMarker
      d={d}
      radius={radius}
      zoomLevel={zoomLevel}
      dynamicColor={dynamicColor}
      colorBand={colorBand}
      colorLabel={colorLabel}
      colorKey={colorKey}
      opacity={opacity}
      showTooltip={showTooltip}
      labeledColumns={labeledColumns}
      setActivePopup={setActivePopup}
      legendItems={legendItems}
      colorsConfig={colorsConfig}
    />
  );
}

import React, { useEffect, useMemo } from "react";
import LabelWrapper from "../../../UI/Form/LabelWrapper/LabelWrapper";
import { useWatch } from "react-hook-form";
import { formatISO, parseISO, setDate, subMonths } from "date-fns";
import Select from "../../../UI/Form/Select/Select";
import { monthNames } from "../../../utils/constants/constants";
import { padStart } from "lodash-es";

export default function WriteBacksCrmFieldMonthYear({
  writeBackOptions,
  inputProps,
  registerOptions,
  fieldName,
  register,
  control,
  updateValue,
}) {
  const value = useWatch({ control, name: fieldName });

  register(fieldName, {
    ...registerOptions,
    validate: {
      year_month: (value) =>
        value && !parseISO(value) ? "Field must be a valid date" : null,
    },
  });

  useEffect(() => {
    if (!value) {
      if (writeBackOptions.defaultNow) {
        updateValue(formatDate(setDate(new Date(), 1)));
      } else if (writeBackOptions.default === "lastMonth") {
        updateValue(formatDate(setDate(subMonths(new Date(), 1), 1)));
      }
    }
  }, [
    updateValue,
    value,
    writeBackOptions.default,
    writeBackOptions.defaultNow,
  ]);
  const [year, month] = (value ?? "").split("-");

  const yearOptions = useMemo(() => {
    const options = [];
    for (let year = new Date().getFullYear(); year >= 2000; year--) {
      options.push({ value: String(year), label: year });
    }
    return options;
  }, []);

  const monthOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ value: padStart(i, 2, "0"), label: monthNames[i - 1] });
    }
    return options;
  }, []);

  return (
    <LabelWrapper
      label={inputProps.label}
      tooltip={inputProps.tooltip}
      required={inputProps.required}
    >
      <div style={{ display: "flex", gap: 20 }}>
        <div style={{ flex: 1 }}>
          <Select
            simpleValue={year}
            options={yearOptions}
            onChange={({ value }) =>
              updateValue(
                [value, padStart(month ?? "", 2, "0"), "01"].join("-")
              )
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <Select
            simpleValue={month}
            options={monthOptions}
            onChange={({ value }) =>
              updateValue([padStart(year ?? "", 4, "0"), value, "01"].join("-"))
            }
          />
        </div>
      </div>
    </LabelWrapper>
  );
}

function formatDate(date) {
  return formatISO(date, { representation: "date" });
}

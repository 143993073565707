import React from "react";
import styled from "@emotion/styled";
import { inputBorders } from "../../../../../styles/styledSnippets";

const DateInput = styled.input`
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  &::-webkit-calendar-picker-indicator {
    ${(props) => (props.theme.type === "dark" ? `filter: invert(.6);` : "")}
  }
  ${(props) => inputBorders(props)}
  ${(props) =>
    props.validationError
      ? `border-color: ${props.theme.notification.errorMain};`
      : ""}
`;

export default function ExplorerDateInputField(props) {
  const { value, onChange, setIsFocus, validationError } = props;
  return (
    <DateInput
      data-cy="explorer-date-select"
      type="date"
      value={value}
      onChange={onChange}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      validationError={validationError}
    />
  );
}

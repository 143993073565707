import Input from "../../../../../UI/Form/Input/Input";

export default function Name({ chart, setChartState }) {
  const {
    subTitles,
    coloredColumns,
    dynamicSubTitleKeys,
    groupingExtraColumns,
  } = chart;
  const { column } = groupingExtraColumns;

  // target can be dynamicSubTitleKeys or coloredColumns
  // one level arrays with column names
  function updateKey(target, value) {
    return target.map((key) => (key === column ? value : key));
  }

  function updateSubTitles(value) {
    return subTitles.map((subTitles) =>
      subTitles.map((sub) => (sub === column ? value : sub))
    );
  }

  function onExtraColumnNameChange(e) {
    const val = e.target.value;

    setChartState({
      ...chart,
      groupingExtraColumns: { ...groupingExtraColumns, column: val },
      ...(dynamicSubTitleKeys && {
        dynamicSubTitleKeys: updateKey(dynamicSubTitleKeys, val),
      }),
      ...(subTitles && { subTitles: updateSubTitles(val) }),
      ...(coloredColumns && { coloredColumns: updateKey(coloredColumns, val) }),
    });
  }

  return (
    <Input
      inPlaceEdit
      value={column}
      label="Display Name"
      onChange={onExtraColumnNameChange}
    />
  );
}

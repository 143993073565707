import { Column } from "./SubTitles";
import { useCallback } from "react";
import { getRidOfAggregation } from "../../../charts/TableView/Elements/EditableMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { columnToLabel } from "../SettingsMenu/Layout/helper";

export default function DynamicSubTitles(props) {
  const {
    setSelectedColumn,
    overrides,
    dynamicSubTitleKeys,
    isParameterized,
    hiddenSubTitles,
    removeGroupingEffect,
    setChartState,
    selectedColumn,
    chart,
    setNavigationState,
    removeDynamicSubTitleColumn,
  } = props;

  const setColumn = useCallback(
    (column) => {
      const nonAggregatedColumn = isParameterized
        ? column
        : getRidOfAggregation(column, overrides);

      if (selectedColumn?.name === nonAggregatedColumn) {
        setSelectedColumn(null);
        setNavigationState("Layout");
        return;
      }

      const override = overrides.find(
        (override) =>
          override.name === nonAggregatedColumn ||
          override.ops?.alias === column
      );

      setSelectedColumn({
        ...override,
        column,
        isParameterized,
        hidden: (hiddenSubTitles ?? []).includes(column),
      });
      setNavigationState("Column");
    },
    [
      hiddenSubTitles,
      isParameterized,
      overrides,
      selectedColumn?.name,
      setSelectedColumn,
      setNavigationState,
    ]
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (props) => {
    if (!props.destination) {
      return;
    }

    const items = reorder(
      dynamicSubTitleKeys,
      props.source.index,
      props.destination.index
    );

    setChartState({
      ...chart,
      dynamicSubTitleKeys: items,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            style={{ display: "flex" }}
            {...provided.droppableProps}
          >
            {dynamicSubTitleKeys.map((column, index) => (
              <Draggable key={column} draggableId={column} index={index}>
                {(provided) => (
                  <Column
                    ref={provided.innerRef}
                    margin={true}
                    onClick={() => setColumn(column)}
                    shake={removeGroupingEffect}
                    isActive={selectedColumn?.column === column}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {columnToLabel(chart.overrides, column)}
                    {removeGroupingEffect && (
                      <FontAwesomeIcon
                        icon={["fas", "times-circle"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeDynamicSubTitleColumn(column);
                        }}
                      />
                    )}
                  </Column>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

import GroupedBarVisualization from "../../charts/GroupedBarVisualization";
import MultiGroupedBarVisualization from "../../charts/MultiGroupedBarVisualization";
import StackedBarTwoVisualization from "../../charts/StackedBar/StackedBarTwoVisualization";
import SalesFunnelVisualization from "../../charts/SalesFunnelVisualization";
import LineVisualization from "../../charts/LineVisualization";
import InfoCardGroup from "../../charts/InfoCard/InfoCardGroup";
import GaugeVisualization from "../../charts/GaugeVisualization";
import SummaryDetailPieVisualization from "../../charts/PieChart/SummaryDetailPieVisualization";
import ScatterVisualization from "../../charts/ScatterVisualization";
import TableParentVisualization from "../../charts/TableView/TableParentVisualization";
import GeoMapVisualization from "../../charts/GeoMapVisualization";
import WaterfallVisualization from "../../charts/Waterfall/WaterfallVisualization";
import LineBarChartVisualization from "../../charts/LineBarChart/LineBarChartVisualization";
import ChartDivider from "../../charts/Dividers/ChartDivider";
import { keyBy } from "lodash-es";
import VerticalDivider from "../../charts/Dividers/VerticalDivider";
import {
  falTable,
  falBolt,
  fasChartBar,
  fasChartLine,
  fasTachometer,
  fasChartPie,
  fasPencilRuler,
  faSuperscript,
} from "../../styles/fontLibrary";
import { faTasksAlt as fasTasksAlt } from "@fortawesome/pro-solid-svg-icons";
import JustLegend from "../../charts/MultipleLineChart/JustLegend";
import OKRCard from "../../charts/OKRCard/OKRCard";
import WatchlistVisualization from "../../charts/Watchlist/WatchlistVisualization";
import ProductStyleVisualization from "../../charts/ProductStyle/ProductStyleVisualization";
import WorldMapVisualization from "../../charts/WorldMap/WorldMapVisualization";
import ActiveTableVisualization from "../../charts/ActiveTable/ActiveTableVisualization";

/**
 * @typedef {Object} ChartType
 * @property {string} key
 * @property {string} name Display name of the chart type
 * @property {React.Component|null} Component
 * @property {?import("@fortawesome/fontawesome-common-types").IconDefinition} icon
 * @property {?boolean} noQuery No query is to be associated with this chart e.g. dividers.
 */

export const chartSectionsLimit = 10;

export const chartTypeKeyMap = {
  GroupedBar: "GroupedBar",
  MultiBar: "MultiBar",
  StackedBars: "StackedBars",
  HorizontalBars: "HorizontalBars",
  LineChart: "LineChart",
  InfoCards: "Info Cards",
  OKRCard: "OKR Card",
  GaugeChart: "Gauge Chart",
  SummaryDetailPie: "SummaryDetailPie",
  Scatter: "Scatter",
  TableChart: "TableChart",
  GeoMap: "GeoMap",
  Waterfall: "Waterfall",
  LineBarChart: "LineBarChart",
  Divider: "Divider",
  VerticalDivider: "Vertical Divider",
  Legend: "Legend",
  Watchlist: "Watchlist",
  ProductStyle: "ProductStyle",
  WorldMap: "WorldMap",
  ActiveTable: "ActiveTable",
};

/**
 * @typedef {Array} ChartTypeArray
 * @type {ChartType[]}
 */
export const chartTypes = Object.freeze([
  {
    key: chartTypeKeyMap.GroupedBar,
    Component: GroupedBarVisualization,
  },
  {
    key: chartTypeKeyMap.MultiBar,
    name: "Bar Chart",
    icon: fasChartBar,
    Component: MultiGroupedBarVisualization,
  },
  {
    key: chartTypeKeyMap.StackedBars,
    name: "Stacked Bar Chart",
    icon: fasTasksAlt,
    iconProps: {
      rotation: 270,
    },
    Component: StackedBarTwoVisualization,
  },
  {
    key: chartTypeKeyMap.HorizontalBars,
    Component: SalesFunnelVisualization,
  },
  {
    key: chartTypeKeyMap.LineChart,
    name: "Line Chart",
    icon: fasChartLine,
    Component: LineVisualization,
  },
  {
    key: chartTypeKeyMap.InfoCards,
    Component: InfoCardGroup,
    icon: faSuperscript,
  },
  {
    key: chartTypeKeyMap.OKRCard,
    Component: OKRCard,
  },
  {
    key: chartTypeKeyMap.GaugeChart,
    icon: fasTachometer,
    Component: GaugeVisualization,
  },
  {
    key: chartTypeKeyMap.SummaryDetailPie,
    icon: fasChartPie,
    Component: SummaryDetailPieVisualization,
  },
  {
    // Not in use
    key: chartTypeKeyMap.Scatter,
    Component: ScatterVisualization,
  },
  {
    key: chartTypeKeyMap.TableChart,
    name: "Table Chart",
    icon: falTable,
    Component: TableParentVisualization,
  },
  {
    key: chartTypeKeyMap.GeoMap,
    Component: GeoMapVisualization,
  },
  {
    key: chartTypeKeyMap.Waterfall,
    Component: WaterfallVisualization,
  },
  {
    key: chartTypeKeyMap.LineBarChart,
    Component: LineBarChartVisualization,
  },
  {
    key: chartTypeKeyMap.Divider,
    icon: fasPencilRuler,
    Component: ChartDivider,
    noQuery: true,
  },
  {
    key: chartTypeKeyMap.VerticalDivider,
    icon: fasPencilRuler,
    Component: VerticalDivider,
    noQuery: true,
  },
  {
    key: chartTypeKeyMap.Legend,
    Component: JustLegend,
  },
  {
    key: chartTypeKeyMap.Watchlist,
    Component: WatchlistVisualization,
  },
  {
    key: chartTypeKeyMap.ProductStyle,
    Component: ProductStyleVisualization,
  },
  {
    key: chartTypeKeyMap.WorldMap,
    Component: WorldMapVisualization,
  },
  {
    key: chartTypeKeyMap.ActiveTable,
    Component: ActiveTableVisualization,
    icon: falBolt,
  },
]);

/**
 * @type {Record<ChartTypeArray[number]['key'], ChartType>}
 */
export const chartTypeMap = keyBy(chartTypes, "key");

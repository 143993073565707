import React from "react";
import HeroBlockLayout from "../HeroBlockLayout";
import LeftRightArea from "../../../../UI/BigInput/LeftRightArea";

export default function ProductSynonyms(props) {
  const { sourceValues, synonyms, values, handleSave, ...rest } = props;

  function getKeysForInit() {
    const allKeys = Object.keys(synonyms);

    return allKeys
      .map((k) => sourceValues.find((sv) => sv.id?.toString() === k))
      .filter(Boolean);
  }
  const initRightArray = getKeysForInit();
  const initThirdValues = synonyms;

  const message = {
    title: "Got inconsistent product names?",
    text: `
        You may have products you’re no longer tracking, or test products, or
        just products you don’t want anyone to know about. A solid synonym list 
        will help keep everything tidy and tracked.
      `,
  };

  return (
    <HeroBlockLayout
      wide
      message={message}
      handleSave={handleSave}
      initRightArray={initRightArray}
      initThirdValues={initThirdValues}
      tripleMode
      {...rest}
    >
      <LeftRightArea
        data={sourceValues}
        rightLabel="Resolves to"
        tripleLabel="Matches"
        wide
      />
    </HeroBlockLayout>
  );
}

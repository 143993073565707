import React from "react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";
import Input from "../../../UI/Form/Input/Input";
import Select from "../../../UI/Form/Select/Select";
import Filters from "./Filters";
import TextArea from "../../../UI/Form/Textarea/Textarea";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const QueryBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div:first-of-type {
    width: 30%;
  }
  & > div:last-of-type {
    width: 65%;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputSection = styled.div`
  width: 25%;
`;

export default function GeneralSettings({
  showSettings,
  state,
  setState,
  queries,
  fieldsOptions,
}) {
  const selectQuery = (query) => {
    setState({
      chart: {
        id: uuidv4(),
        visualizationId: uuidv4(),
        queryId: query.uuid,
        type: "TableChart",
        overrides: [],
        orders: [],
        subTitles: [],
        calculatedRows: [],
        calculateNAColumns: [],
        formatOverrides: [],
        staticGroupingKeys: [],
        hideOnFilters: [],
      },
      query,
      fields: [],
    });
  };

  const selectFields = (fields, { action, option, removedValue }) => {
    if (action === "select-option") {
      selectField(fields, option);
    } else if (action === "remove-value") {
      removeField(fields, removedValue);
    }
  };

  const selectField = (fields, option) => {
    const overrides = [...state.chart.overrides];
    overrides.push(option);

    setState({
      ...state,
      fields,
      chart: {
        ...state.chart,
        overrides,
      },
    });
  };

  const removeField = (fields, option) => {
    setState({
      ...state,
      fields: fields || [],
      chart: {
        ...state.chart,
        overrides: state.chart.overrides.filter(
          (o) => o.dataSourceFieldUuid !== option.dataSourceFieldUuid
        ),
      },
    });
  };

  const handleTextAreaChange = ({ value }, key) => {
    setState({
      ...state,
      chart: {
        ...state.chart,
        [key]: value ? value.split(",") : [],
      },
    });
  };

  return (
    <>
      <Container>
        <QueryBlock>
          <Select
            options={queries}
            getOptionValue={(o) => o.uuid}
            getOptionLabel={(o) => o.name}
            placeholder="Select Query..."
            onChange={selectQuery}
            label="Query"
            value={state.query}
          />
          <Select
            options={fieldsOptions}
            getOptionValue={(o) => o.dataSourceFieldUuid}
            getOptionLabel={(o) => o.name}
            isMulti
            onChange={selectFields}
            isClearable={false}
            closeMenuOnSelect={false}
            label="Fields"
            value={fieldsOptions.filter((fo) =>
              state.fields.find(
                (f) => f.dataSourceFieldUuid === fo.dataSourceFieldUuid
              )
            )}
          />
        </QueryBlock>

        <br />

        {showSettings && (
          <>
            <Section>
              <InputSection>
                <Input
                  label="Ignore Menu Filters"
                  onChange={({ target }) =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        ignoreMenuFilters: target.value
                          .split(",")
                          .map((v) => v.trim()),
                      },
                    })
                  }
                />
              </InputSection>
              {/* <InputSection>
                <Input
                  label="Colored Value Key"
                  onChange={({ target }) =>
                    setState({
                      ...state,
                      chart: { ...state.chart, coloredValueKey: target.value },
                    })
                  }
                />
              </InputSection> */}
              <div style={{ marginTop: "25px" }}>
                <Checkbox
                  label="Ignore Dates"
                  value={!!state.chart.allDates}
                  onChange={() =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        allDates: !state.chart.allDates,
                      },
                    })
                  }
                />
              </div>
              <div style={{ marginTop: "25px" }}>
                <Checkbox
                  label="Sortable"
                  value={!!state.chart.isSortable}
                  onChange={() =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        isSortable: !state.chart.isSortable,
                      },
                    })
                  }
                />
              </div>
              <div style={{ marginTop: "25px" }}>
                <Checkbox
                  label="Value Key as Subtitle"
                  value={!!state.chart.valueKeyAsSubTitle}
                  onChange={() =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        valueKeyAsSubTitle: !state.chart.valueKeyAsSubTitle,
                      },
                    })
                  }
                />
              </div>
            </Section>
            <br />
            <Section>
              <InputSection>
                <Input
                  label="Unique Value Key"
                  onChange={({ target }) =>
                    setState({
                      ...state,
                      chart: { ...state.chart, uniqueValueKey: target.value },
                    })
                  }
                />
              </InputSection>
              <InputSection>
                <Input
                  label="Freeze Width"
                  onChange={({ target }) =>
                    setState({
                      ...state,
                      chart: { ...state.chart, freezeWidth: target.value },
                    })
                  }
                  type="number"
                />
              </InputSection>
              <div style={{ marginTop: "25px" }}>
                <Checkbox
                  label="Freeze Left"
                  value={!!state.chart.freezeLeft}
                  onChange={() =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        freezeLeft: !state.chart.freezeLeft,
                      },
                    })
                  }
                />
              </div>
              <div style={{ marginTop: "25px" }}>
                <Checkbox
                  label="Date Style"
                  value={!!state.chart.dateStyle}
                  onChange={() =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        dateStyle: !state.chart.dateStyle,
                      },
                    })
                  }
                />
              </div>
              <div style={{ marginTop: "25px" }}>
                <Checkbox
                  label="Has Future"
                  value={!!state.chart.hasFuture}
                  onChange={() =>
                    setState({
                      ...state,
                      chart: {
                        ...state.chart,
                        hasFuture: !state.chart.hasFuture,
                      },
                    })
                  }
                />
              </div>
            </Section>
            <br />
            <Section>
              <div style={{ width: "30%" }}>
                <Select
                  options={fieldsOptions}
                  getOptionValue={(o) => o.dataSourceFieldUuid}
                  getOptionLabel={(o) => o.name}
                  onChange={(o) =>
                    setState({
                      ...state,
                      chart: { ...state.chart, dateKey: o.name },
                    })
                  }
                  isClearable={false}
                  closeMenuOnSelect={false}
                  label="Date Key"
                  value={fieldsOptions.find(
                    (f) => f.name === state.chart.dateKey
                  )}
                />
              </div>
              <InputSection>
                <Input
                  label="Max Date Count"
                  onChange={({ target }) =>
                    setState({
                      ...state,
                      chart: { ...state.chart, maxDateCount: target.value },
                    })
                  }
                  type="number"
                />
              </InputSection>
              <InputSection>
                <Input
                  label="Max Date Type"
                  onChange={({ target }) =>
                    setState({
                      ...state,
                      chart: { ...state.chart, maxDateType: target.value },
                    })
                  }
                />
              </InputSection>
            </Section>
            <br />
            <Section>
              <div style={{ width: "45%" }}>
                <TextArea
                  label="Static Grouping Keys"
                  onChange={({ target }) =>
                    handleTextAreaChange(target, "staticGroupingKeys")
                  }
                  value={state.chart.staticGroupingKeys.join(",")}
                />
              </div>

              <div style={{ width: "45%" }}>
                <TextArea
                  label="Hide On Filters"
                  onChange={({ target }) =>
                    handleTextAreaChange(target, "hideOnFilters")
                  }
                  value={state.chart.hideOnFilters.join(",")}
                />
              </div>
            </Section>
            <br />
            <Section>
              <Filters state={state} setState={setState} />
            </Section>
          </>
        )}
      </Container>
    </>
  );
}

import React from "react";
import Switcher from "../../UI/Switcher/Switcher";

export default function RoundingSelector(props) {
  if (!props.show) return null;

  function handleChange() {
    props.setActive(!props.active);
  }

  return (
    <div style={{ marginTop: 16 }}>
      <Switcher
        handleSwitchChange={handleChange}
        checked={!props.active}
        rightLabel="precision mode"
      />
    </div>
  );
}

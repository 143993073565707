export default function (data, operation, key) {
  const total = data.reduce((acc, curr) => {
    return acc + curr[key];
  }, 0);
  if (operation === "SUM") {
    return total;
  } else {
    return total / data.length;
  }
}

export function findWhere(data, valueKey, conditionKey, conditionValue) {
  const match = data.find((d) => d[conditionKey] === conditionValue);
  return (match && match[valueKey]) || null;
}

import { addMonths, format, parse } from "date-fns";

export default (fields = [], name, dateFilters) => {
  const { splitByLabel } = fields.find((f) => f.splitByAlias === name) ?? {};

  const field = fields.find((f) => f.alias === name || f.name === name);

  const placeholder = getDatePlaceholder(field, dateFilters);
  return placeholder || splitByLabel || field?.label || name;
};

export const mapping = (fields = [], name) => {
  const splitBy = fields.find((f) => f.splitByAlias === name);
  const field = fields.find((f) => f.alias === name || f.name === name);

  if (splitBy) {
    return { ...splitBy, label: splitBy.splitByLabel };
  }

  return field || name; // @todo do we make this act as a default here?
};

export const getFieldNameByLabel = (fields = [], label) => {
  const { alias, name } = fields.find((f) => f.label === label);

  return alias || name;
};

export const getFieldTypeByAlias = (fields = [], alias) =>
  fields.find((f) => f.alias === alias)?.type || "";

export const isFieldHidden = (fields = [], name) => {
  return !!mapping(fields, name)?.hideOnTables;
};

export const getMappingType = (fields = [], name) => {
  return fields.find((f) => f.alias === name || f.name === name)?.type;
};

export const getVisualizationHeaderDescription = (fields = [], name) => {
  const field = fields.find((f) => f.alias === name || f.name === name);
  return field ? field.description : name;
};

// Replaces placeholders in the field label with formatted date values.
export function getDatePlaceholder(field, dateFilters) {
  if (!dateFilters?.start?.value || !field?.label) {
    return;
  }

  const { start } = dateFilters;
  const { value } = start;
  const { label } = field;

  const placeholders = {
    MONTH_1: 0,
    MONTH_2: 1,
    MONTH_3: 2,
  };

  return Object.keys(placeholders).reduce((updatedLabel, placeholder) => {
    if (label.includes(placeholder)) {
      return updatedLabel.replace(
        placeholder,
        format(
          addMonths(
            parse(value, "yyyy-MM-dd", new Date()),
            placeholders[placeholder]
          ),
          "MMM"
        )
      );
    }
    return updatedLabel;
  }, label);
}

import parse from "date-fns/parse";
import { monthNames } from "../constants/constants";
import { parseAsDate } from "../formatters/formatter";
import {
  addMinutes,
  subMinutes,
  subDays,
  isWeekend,
  formatISO,
} from "date-fns";

export const absoluteDate = (str) => {
  if (!str) return null;
  const s = str.substr(0, 10);
  return parse(s, "yyyy-MM-dd", new Date());
};

export const absoluteUSDate = (str) => {
  if (!str) return null;
  const s = str.substr(0, 10);
  return parse(s, "MM/dd/yyyy", new Date());
};

export const dateFromMonth = (month, year = 2020) => {
  const yearDate = parse(year, "yyyy", new Date());
  return parse(+month, "M", yearDate);
};

export const dateFromWeek = (week, year) => {
  if (!week || !year) return null;

  const yearDate = new Date(year, 1, 1);
  return parse(+week, "I", yearDate);
};

export const toDate = (key) => (arr) => {
  return arr.map((a) => ({ ...a, [key]: parseAsDate(a[key]) }));
};

export const dateFromQuarter = (quarter, year = 2020) => {
  return new Date(year, quarter * 3 - 3, 1);
};

export const splitYearByKey = (str, shortKey, key) => {
  return {
    [key]: str?.split(" ")[1]?.split(shortKey)[1],
    year: str?.split(" ")[0],
  };
};

const types = {
  weekly: {
    shortKey: "W",
    key: "week",
    fn: dateFromWeek,
  },
  quarterly: {
    shortKey: "Q",
    key: "quarter",
    fn: dateFromQuarter,
  },
  monthly: {
    shortKey: "M",
    key: "month",
    fn: dateFromMonth,
  },
};

export const toDateType = (key, type, yearKey, ownFormat) => (arr) => {
  if (ownFormat) {
    return arr.map((row) => ({
      ...row,
      [key]: row[key],
    }));
  }

  if (!types[type]) {
    return toDate(key)(arr);
  }

  const { shortKey, key: longKey, fn } = types[type];

  return arr.map((row) => {
    if (!yearKey) {
      const obj = splitYearByKey(row[key], shortKey, longKey);

      return {
        ...row,
        [key]: fn(obj[longKey], obj.year),
      };
    }

    return {
      ...row,
      [key]: fn(row[key], row[yearKey]),
    };
  });
};

export const getDateFromMonthYear = (monthYear) => {
  if (!monthYear) {
    return;
  }

  const string = monthYear.value || monthYear || "";
  const [month, year] = string.split(" ");

  return new Date(
    year,
    monthNames.findIndex((name) => name === month)
  );
};

export const getDateFromMonthNumberYear = (monthYear) => {
  if (!monthYear) return;
  const [year, month] = monthYear.split(" ");
  const m = +month.replace("M", "");
  return new Date(year, m - 1);
};

/**
 * This does not literally convert to UTC, but rather shows what the date would
 * show if it were in UTC.
 */
function convertLocalDateToUTC(date) {
  return addMinutes(date, date.getTimezoneOffset());
}

/**
 * This does not literally convert from UTC, but rather with a date pretending
 * to be in UTC, converts it to a local date.
 */
export function convertUTCToLocalDate(date) {
  return subMinutes(date, date.getTimezoneOffset());
}

/**
 * Is the last weekday (excluding today) from the same month?
 * This can be used to determine if maybe it's OK for charts to show No Results
 * at this time.
 *
 * @param date {Date=}
 * @returns {boolean}
 */
export function isLastWeekdayFromSameMonth(date = new Date()) {
  let initialUtcDate = convertLocalDateToUTC(date);
  const yearMonth = formatYearMonth(initialUtcDate);
  let utcDate = initialUtcDate;
  while (isWeekend((utcDate = subDays(utcDate, 1)))) {}
  return formatYearMonth(utcDate) === yearMonth;
}

/**
 * @param date {Date}
 * @returns {string} e.g. '2012-12-25'
 */
export function formatISODate(date) {
  return formatISO(date, { representation: "date" });
}

/**
 * @param date
 * @returns {string} e.g. '2012-12'
 */
export function formatYearMonth(date) {
  return formatISODate(date).substring(0, 7);
}

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import selectStyles from "../../../styles/selectStyles";
import useLabel from "../LabelWrapper/useLabel";
import Checkbox from "../Checkbox/Checkbox";
import Select from "./Select";

const OptionContainer = styled.div`
  & > div {
    cursor: pointer;
    padding: 4px;
  }
  &:hover div {
    color: white;
  }
`;

const OptionLabel = styled.div(
  ({ theme, selected }) => `
  color: ${selected ? "white" : theme.text.secondary};
  width: fit-content;
  margin-left: 40px;
  margin-top: -6px;
  font-size: 12px;
  padding-bottom: 6px;
  &:hover {
    color: white;
  }
`
);

const allOption = {
  value: "*",
};

const Option = (props) => {
  const theme = useTheme();

  return (
    <OptionContainer active={props.isSelected} data-cy="select-custom-option">
      <components.Option {...props}>
        <Checkbox
          checked={!!props.isSelected}
          onChange={() => null}
          height="16px"
          width="16px"
          top="0px"
          left="4px"
          overPrimary={theme.menuPrimary}
        />
        {/* using checkox label closing menu on select on label (no idea why) */}
        <OptionLabel selected={!!props.isSelected}>{props.label}</OptionLabel>
      </components.Option>
    </OptionContainer>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;

  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = (props) => {
  let labelToBeDisplayed = props.data.label;

  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export default function SelectWithCheckboxes(props) {
  const {
    range,
    belowValue,
    bgPrimary,
    options,
    allOption,
    value,
    async,
    isLoading,
    searchValue,
    onSelectInputChange,
    menuIsOpen,
  } = props;
  const theme = useTheme();
  const withLabel = useLabel(props.label);

  const { active, all } = {
    active: value ? value.filter((v) => v.value !== allOption.value).length : 0,
    all: options.length,
  };

  if (!async) {
    allOption.label = `Select all (${active}/${all})`;
  }

  const shouldHaveSelectAllOption = options.length <= 100 && !async;

  const onChange = useMemo(
    () =>
      shouldHaveSelectAllOption
        ? (selected, actionObject) => {
            const { action, option } = actionObject;
            if (selected !== null && selected.length > 0) {
              if (selected[selected.length - 1].value === allOption.value) {
                // The "Select all" option was selected/deselected.
                if (action === "select-option" && !options.includes(option)) {
                  return props.onChange([allOption, ...options], {
                    action: "select-all",
                  });
                }
              }

              let result = [];

              if (selected.length === options.length) {
                if (selected.includes(allOption)) {
                  result = selected.filter(
                    (option) => option.value !== allOption.value
                  );
                } else if (action === "select-option") {
                  result = [allOption, ...options];
                }

                return props.onChange(result);
              }
            }

            return props.onChange(selected, actionObject);
          }
        : props.onChange,
    [allOption, options, props, shouldHaveSelectAllOption]
  );

  const finalOptions = useMemo(() => {
    return shouldHaveSelectAllOption ? [allOption, ...options] : options;
  }, [allOption, options, shouldHaveSelectAllOption]);

  const getValue = () => {
    if (value?.length === options.length && options.length && !async) {
      return [allOption, ...value];
    }

    return value;
  };

  const el = (
    <Select
      styles={selectStyles(
        theme,
        range,
        belowValue || theme.primary,
        bgPrimary
      )}
      options={finalOptions}
      filterOptions={false}
      onChange={onChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, MultiValue, ValueContainer, ...props.components }}
      isMulti
      isClearable={false}
      value={getValue()}
      isLoading={isLoading}
      inputValue={searchValue}
      onInputChange={onSelectInputChange}
      menuIsOpen={menuIsOpen}
    />
  );

  return withLabel(el);
}

SelectWithCheckboxes.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

SelectWithCheckboxes.defaultProps = {
  allOption,
};

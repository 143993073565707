import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { inputBorders } from "../../../styles/styledSnippets";
import RemoveDot from "./RemoveDot";
import AddDot from "./AddDot";
import Flex from "../../Flex/Flex";
import PropTypes from "prop-types";
import produce from "immer";
import { isNumber } from "lodash-es";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
const Input = styled.input`
  font-size: 12px;
  ${(props) => inputBorders(props)}

  width: 100%;
  box-sizing: border-box;

  &:focus {
    border: 2px solid #2684ff;
    outline: none;
  }
  margin-right: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 14px;
`;

export default function InputSet(props) {
  const [adding, setAdding] = useState(!props.values.length);
  const [tempValue, setTempValue] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const ref = useRef(null);
  useOnClickOutside(ref, reset);

  const showLabels = props.showLabels;

  function handleChange(e, key) {
    setTempValue({ ...tempValue, [key]: e.target.value });
  }

  function commitChange(index, key) {
    const hasNextArray = shouldSendNewArray(index, key);
    if (hasNextArray) {
      const nextArray = assembleNextArray();

      props.onChange(nextArray);
    }
    reset();

    function assembleNextArray() {
      const nextArray = produce(props.values, (draft) => {
        if (isNumber(index)) {
          if (tempValue[key] === "") {
            delete draft[index][key];
          } else {
            draft[index][key] = tempValue[key];
          }
        } else {
          draft.push(tempValue);
        }
      });
      return nextArray.filter((v) => v);
    }
  }
  function shouldSendNewArray(index, key) {
    const isExistingValue = isNumber(index);

    if (isExistingValue && props.values[index][key] === tempValue[key]) {
      return false;
    } else if (!isExistingValue && !tempValue[key]) {
      return false;
    } else return true;
  }

  function reset() {
    setTempValue({});
    setEditingIndex(null);
    return setAdding(false);
  }

  function handleRemove(index) {
    const nextArray = props.values.filter((v, i) => i !== index);
    return props.onChange(nextArray);
  }

  function startEdit(index) {
    setTempValue(props.values[index]);
    setEditingIndex(index);
  }

  function handleEnter(e, index, key) {
    if (e.key === "Enter" || e.code === "13") {
      e.preventDefault();
      return commitChange(index, key);
    }
  }

  return (
    <Container>
      <div style={{ width: "100%" }}>
        {props.values.map((item, i) => (
          <Flex
            style={{ marginBottom: 8 }}
            key={item.value + i}
            justifyContent="space-between"
          >
            <RemoveDot
              cy="input-set-remove"
              relative
              onClick={() => handleRemove(i)}
            />
            <Input
              value={(editingIndex === i ? tempValue.value : item.value) ?? ""}
              data-cy="input-set-value"
              onChange={(e) => handleChange(e, "value")}
              onBlur={() => commitChange(i, "value")}
              onKeyDown={(e) => handleEnter(e, i, "value")}
              onFocus={() => startEdit(i)}
            />

            {showLabels && (
              <Input
                value={
                  (editingIndex === i ? tempValue.label : item.label) ?? ""
                }
                data-cy="input-set-label"
                onChange={(e) => handleChange(e, "label")}
                onBlur={() => commitChange(i, "label")}
                onKeyDown={(e) => handleEnter(e, i, "label")}
                onFocus={() => startEdit(i)}
              />
            )}
          </Flex>
        ))}
        {adding ? (
          <Flex
            style={{ marginBottom: 8, paddingLeft: 35 }}
            justifyContent="space-between"
          >
            <Input
              value={tempValue.value ?? ""}
              onChange={(e) => handleChange(e, "value")}
              onBlur={() => commitChange(null, "value")}
              onKeyDown={(e) => handleEnter(e, null, "value")}
              data-cy="input-set-value-new"
              placeholder="Enter value..."
              autoFocus
            />
            {showLabels && (
              <Input
                value={tempValue.label ?? ""}
                onChange={(e) => handleChange(e, "label")}
                onBlur={() => commitChange(null, "label")}
                onKeyDown={(e) => handleEnter(e, null, "label")}
                data-cy="input-set-label-new"
                placeholder="Enter value..."
              />
            )}
          </Flex>
        ) : null}
      </div>
      {!adding ? (
        <div>
          <AddDot
            cy={"input-set-add"}
            relative
            onClick={() => setAdding(true)}
          />
        </div>
      ) : null}
    </Container>
  );
}

InputSet.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
};

InputSet.defaultProps = {
  values: [],
};

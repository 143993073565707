import { useSelector } from "react-redux";
import Switcher from "../../../../UI/Switcher/Switcher";
import Select from "../../../../UI/Form/Select/Select";
import { supportedOperators } from "../../../../utils/constants/operators";
import styled from "@emotion/styled";
import Flex from "../../../../UI/Flex/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConditionItem = styled.div`
  background: ${(props) => props.theme.divider};
  padding: 12px 8px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const AddMoreLink = styled.div`
  cursor: pointer;
  text-align: right;
  &:hover {
    text-decoration: underline;
  }
  margin: 20px 0px;
`;

export default function RunQueryOnFilters({ setChartState, chart, theme }) {
  const { runQueryOnFilters } = chart;

  const menuFilters = useSelector((state) => state.layout.menuFilters);

  const operatorOptions = supportedOperators.map((operator) => ({
    value: operator,
    label: operator ?? "None",
  }));

  function getConditionFilterOption(condition) {
    return menuFilters.find((f) => f.name === condition.name);
  }

  function getConditionFilterValues(condition) {
    return getConditionFilterOption(condition)?.values ?? [];
  }

  function setRunQueryOnFilters() {
    setChartState({
      ...chart,
      runQueryOnFilters: runQueryOnFilters ? null : [{}],
    });
  }

  function changeConditionFilterProperty(value, index, key) {
    setChartState({
      ...chart,
      runQueryOnFilters: runQueryOnFilters.map((condition, i) =>
        i === index ? { ...condition, [key]: value } : condition
      ),
    });
  }

  function addCondition() {
    setChartState({
      ...chart,
      runQueryOnFilters: [...runQueryOnFilters, {}],
    });
  }

  function removeCondition(index) {
    const filters = runQueryOnFilters.filter((_, i) => i !== index);

    setChartState({
      ...chart,
      runQueryOnFilters: filters.length ? filters : null,
    });
  }

  return (
    <div>
      <div>Run Query On Filters</div>
      <br />
      <Switcher
        rightLabel="Conditions on Query Run"
        checked={!!runQueryOnFilters}
        handleSwitchChange={setRunQueryOnFilters}
      />
      <br />

      {(runQueryOnFilters ?? []).map((condition, index) => (
        <ConditionItem key={(condition.name ?? "") + index}>
          <Flex alignItems="end">
            <Select
              options={menuFilters}
              getOptionLabel={(option) => option.displayName}
              getOptionValue={(option) => option.name}
              value={getConditionFilterOption(condition)}
              label="Filter Name"
              menuPlacement="top"
              onChange={(option) =>
                changeConditionFilterProperty(option.name, index, "name")
              }
            />
            <FontAwesomeIcon
              icon={["fal", "times-circle"]}
              style={{
                color: "orangered",
                fontSize: 22,
                cursor: "pointer",
                margin: "0px 5px 10px 5px",
              }}
              onClick={() => removeCondition(index)}
            />
          </Flex>

          <br />
          {condition.name && (
            <Select
              options={operatorOptions}
              simpleValue={condition.operator}
              label="Operator"
              onChange={(option) =>
                changeConditionFilterProperty(option.value, index, "operator")
              }
              menuPlacement="top"
            />
          )}

          <br />
          {condition.operator && (
            <Select
              options={getConditionFilterValues(condition)}
              simpleValue={condition.value}
              label="Condition Value"
              onChange={(option) =>
                changeConditionFilterProperty(option.value, index, "value")
              }
              menuPlacement="top"
            />
          )}
        </ConditionItem>
      ))}

      {!!runQueryOnFilters && (
        <AddMoreLink onClick={addCondition}>+ Add More</AddMoreLink>
      )}

      <hr color={theme.background.secondary} />
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";
import Flex from "../../../UI/Flex/Flex";

const Container = styled.div(
  ({ colors }) => `
  width: 200px;
  height: 12px;
  background-image: linear-gradient(to left, ${colors.join(", ")});
  margin: 0 6px;
`
);

const Label = styled.div`
  font-size: 12px;
`;

export default function GradientKey({ colorsConfig }) {
  const leftLabel = colorsConfig?.legendLeftLabel ?? defaultLeftLabel;
  const rightLabel = colorsConfig?.legendRightLabel ?? defaultRightLabel;

  const colors = colorsConfig?.colors
    ? [...colorsConfig.colors].reverse()
    : defaultColors;

  return (
    <Flex>
      <Label>{leftLabel}</Label>
      <Container colors={colors} />
      <Label>{rightLabel}</Label>
    </Flex>
  );
}

const defaultColors = ["#920355", " #2b691a"];
const defaultLeftLabel = "Less than 5 miles";
const defaultRightLabel = ";100+ miles";

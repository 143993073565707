import React from "react";
import { useDispatch } from "react-redux";
import { resetFilters } from "../../store/actions";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const Container = styled.div(
  ({ theme }) => `
  padding: 10px;
  width: fit-content;
  color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.secondary};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
);

const Clear = styled.span`
  font-size: 12px;
  margin-left: 5px;
`;

export default function ResetFilters({ selectPowerEditorModeFilter }) {
  const dispatch = useDispatch();
  const confirm = useConfirmDelete(() => {
    dispatch(resetFilters());

    if (selectPowerEditorModeFilter) {
      selectPowerEditorModeFilter();
    }
  });

  return (
    <Container data-cy="clear-filters" mt2>
      <FontAwesomeIcon icon={["fas", "backspace"]} />
      <Clear onClick={() => confirm.setConfirming()}>CLEAR FILTERS</Clear>
      {confirm.confirming}
    </Container>
  );
}

import React from "react";
import styled from "@emotion/styled";
import Input from "../../../UI/Form/Input/Input";
import Subtitles from "./Subtitles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IOButton from "../../../UI/Form/Button/IOButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowValue = styled.div`
  border: 1px solid ${(props) => props.theme.divider};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 10px;
  margin: 1% 1% 1% 0;
  position: relative;
  & > svg {
    cursor: pointer;
    top: 5px;
    right: 5px;
    position: absolute;
    color: ${(props) => props.theme.notification.errorMain};
    font-size: 12px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default function CalculatedRows({ state, setState }) {
  const addSubtitle = (index) => {
    const calcRows = [...state.chart.calculatedRows];
    calcRows[index].subTitles.push([""]);

    setState({ ...state, chart: { ...state.chart, calculatedRows: calcRows } });
  };

  const onSubtitleChange = (value, subIndex, rowIndex) => {
    const calcRows = [...state.chart.calculatedRows];
    calcRows[rowIndex].subTitles[subIndex] = value.split(",");

    setState({ ...state, chart: { ...state.chart, calculatedRows: calcRows } });
  };

  const deleteSubtitle = (subIndex, rowIndex) => {
    const calcRows = [...state.chart.calculatedRows];
    calcRows[rowIndex].subTitles = state.chart.calculatedRows[
      rowIndex
    ].subTitles.filter((_, i) => i !== subIndex);

    setState({
      ...state,
      chart: {
        ...state.chart,
        calculatedRows: calcRows,
      },
    });
  };

  const addCalculatedRow = () => {
    const calcRows = [...state.chart.calculatedRows];
    calcRows.push({ rowValue: "", subTitles: [] });

    setState({ ...state, chart: { ...state.chart, calculatedRows: calcRows } });
  };

  const changeRowValue = ({ value }, rowIndex) => {
    const calcRows = [...state.chart.calculatedRows];
    calcRows[rowIndex].rowValue = value;

    setState({ ...state, chart: { ...state.chart, calculatedRows: calcRows } });
  };

  const removeCalculatedRow = (index) => {
    const calculatedRows = state.chart.calculatedRows.filter(
      (_, i) => i !== index
    );
    setState({ ...state, chart: { ...state.chart, calculatedRows } });
  };

  return (
    <Container>
      <IOButton
        style={{ width: "fit-content", alignSelf: "flex-end" }}
        smallPadding
        onClick={addCalculatedRow}
        add
        outline
        disabled={!state.chart.calculatedRows}
      >
        Add Subtitle
      </IOButton>

      <Rows>
        {state.chart.calculatedRows?.map((cr, index) => (
          <RowValue key={"row_" + index}>
            <FontAwesomeIcon
              icon={["fa", "times"]}
              onClick={() => removeCalculatedRow(index)}
            />
            <Input
              label="Row Value"
              value={cr.rowValue}
              onChange={({ target }) => changeRowValue(target, index)}
            />
            <Subtitles
              subTitles={cr.subTitles}
              addSubtitle={() => addSubtitle(index)}
              onSubtitleChange={({ value }, subIndex) =>
                onSubtitleChange(value, subIndex, index)
              }
              deleteSubtitle={(subIndex) => deleteSubtitle(subIndex, index)}
              fullSize
            />
          </RowValue>
        ))}
      </Rows>
    </Container>
  );
}

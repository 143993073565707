import React from "react";
import GaugeChart from "./GaugeChart/GaugeChart";
import { useTheme } from "emotion-theming";
import { format as d3Format } from "d3-format";
import VisualizationBase from "./BaseChart/VisualizationBase";
import Flex from "../UI/Flex/Flex";
import styled from "@emotion/styled";
import { hexToRgba, lightenDarkenColor } from "../styles/colorConvertor";
import Details from "../Layout/Details/Details";
import DataNotAvailable from "./DataNotAvailable/DataNotAvailable";
import Target from "./utils/Target";

const Title = styled.div`
  color: ${(props) => props.theme.text.secondary};
  font-size: 14px;
`;

export default function GaugeVisualization(props) {
  const {
    data,
    total,
    part,
    format = ".0%",
    title,
    themePrimary,
    color,
    details,
    restricted,
    error,
    invertSuccess,
    noResultsMessageOverride,
    hideLabel,
  } = props.chart;
  const theme = useTheme();
  const [values = {}] = data || []; // Initial parsing before data returned

  if (restricted) {
    return (
      <DataNotAvailable
        restricted={restricted}
        text={error}
        noResultsMessageOverride={noResultsMessageOverride}
      />
    );
  }

  if (!values[total]) return null;

  const TargetObj = new Target(values[total], values[part]);
  if (invertSuccess) {
    TargetObj.setInvertSuccess();
  }
  const notNumber = isNaN(TargetObj.value) || !isFinite(TargetObj.value);

  const segmentValues = [
    notNumber ? 100 : Math.abs(TargetObj.value),
    +(1 - Math.abs(TargetObj.value)).toFixed(4),
  ];

  const colorType = "success";
  const themeColor = theme.notification[`${colorType}Main`];

  const setColors = () => {
    if (notNumber) {
      return [theme.gray.gray500];
    } else if (color) {
      return [color, lightenDarkenColor(color, 80)];
    } else if (themePrimary) {
      return [theme.primary, lightenDarkenColor(theme.primary, 80)];
    }
    return [hexToRgba(themeColor, 1), hexToRgba(themeColor, 0.4)];
  };

  const colors = setColors();

  return (
    <>
      {title ? (
        <Flex justifyContent="center" mt2>
          <Title>{title}</Title>
        </Flex>
      ) : null}

      <VisualizationBase
        {...{ ...props, margin: { top: 5, right: 0, bottom: 0, left: 0 } }}
      >
        <GaugeChart
          themePrimary={themePrimary}
          data={segmentValues}
          label={
            notNumber
              ? "--"
              : format
              ? d3Format(format)(TargetObj.value)
              : TargetObj.formatted
          }
          colors={colors}
          labelColor={
            notNumber
              ? theme.gray.gray500
              : color ?? theme.notification[`${colorType}Main`]
          }
          title={title}
          hideLabel={hideLabel}
        />
      </VisualizationBase>
      {details && (
        <Details
          dashboardName={props.dashboardId}
          visualizationId={props.chart.visualizationId}
        />
      )}
    </>
  );
}

import Select from "../../../../../UI/Form/Select/Select";
import { groupingFormats } from "../../../../../utils/constants/constants";

export default function GroupingFormatContainer(props) {
  const { chart, setChartState } = props;

  // set format of headers in dynamic repeating columns
  function setRepeatColumnFormat(option) {
    setChartState({
      ...chart,
      groupingKeyMapping: option.value,
    });
  }

  return (
    <>
      <Select
        options={groupingFormats}
        value={
          groupingFormats.find((ro) => ro.value === chart.groupingKeyMapping) ??
          null
        }
        label="Repeat column format"
        onChange={setRepeatColumnFormat}
      />
      <br />
    </>
  );
}

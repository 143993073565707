import ButtonGroup from "../../../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../../../UI/Form/Button/IOButton";

export default function AddFormatOverride({ onOverrideItemAdd, disabled }) {
  return (
    <ButtonGroup position="center">
      <IOButton
        smallPadding
        outline
        add
        onClick={onOverrideItemAdd}
        disabled={disabled}
      >
        Add Value
      </IOButton>
    </ButtonGroup>
  );
}

import React from "react";
import Modal from "../../../UI/Modal/Modal";
import styled from "@emotion/styled";

const Details = styled.div`
  height: 630px;
  max-width: 980px;
  top: 120px;
  right: 290px;
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
`;

const DetailsInner = styled.div`
  position: relative;
  height: 100%;
`;

export default function ActiveTableViewsDetailContainer(props) {
  return (
    <>
      <Modal
        close={props.handleClose}
        renderInPortal
        additionalPortal="select-menu-portal"
        controlledFromOutside
      >
        <Details data-cy="view-details-container">
          <DetailsInner>{props.children}</DetailsInner>
        </Details>
      </Modal>
    </>
  );
}

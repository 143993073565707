import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SortArrow = styled.span`
  padding-right: 6px;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const icons = { ASC: "arrow-down", DESC: "arrow-up" };

export default function TableSorting(props) {
  const { setRowOrder, name, direction } = props;
  return (
    <SortArrow onClick={() => setRowOrder(name)}>
      <FontAwesomeIcon icon={["fal", icons[direction]]} size="xs" />
    </SortArrow>
  );
}

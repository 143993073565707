import React, { Children, cloneElement, useCallback } from "react";
import PropTypes from "prop-types";

export default function Validate(props) {
  const {
    required,
    email,
    min,
    max,
    match,
    number,
    formApi,
    children,
    checked,
  } = props;
  const { set, setError, values } = formApi;

  const handleChange = useCallback(
    (event) => {
      if (required && event.target.value === "") {
        return setError(event, "required");
      }

      if (email && !validateEmail(event.target.value)) {
        return setError(event, "email");
      }

      if (min && event.target.value.length < min) {
        return setError(event, "min");
      }

      if (max && event.target.value.length > max) {
        return setError(event, "max");
      }
      if (match && event.target.value !== values[match]) {
        return setError(event, "match");
      }

      if (number && isNaN(event.target.value)) {
        return setError(event, "number");
      }

      if (checked && !event.target.checked) {
        return setError(event, "checked");
      }

      return set(event);
    },
    [checked, email, match, max, min, number, required, set, setError, values]
  );

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          onKeyUp: handleChange,
          onChange: handleChange,
          onFocus: formApi.setFocus,
          onBlur: () => formApi.setFocus(null),
        });
      })}
    </div>
  );
}

Validate.propTypes = {
  formApi: PropTypes.object.isRequired,
  required: PropTypes.bool,
  email: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  number: PropTypes.bool,
};

import { useCallback, useEffect, useRef, useState } from "react";
import { stringify } from "qs";
import octane from "../../octane";
import { convertResponseToInlineImage } from "../TableView/Elements/ApiImage";

export default (value) => {
  const [fullSrcState, setFullSrcState] = useState({
    loading: false,
    data: null,
    error: null,
  });
  const [error, setError] = useState(null);
  const abortControllerRef = useRef();

  const loadFullSizedImage = useCallback(() => {
    if (fullSrcState.loading || fullSrcState.data) {
      return;
    }

    const queryString = stringify({
      path: value,
    });
    if (!value) {
      return;
    } else {
      octane
        .get(`/api/warehouse-image?${queryString}`, {
          responseType: "arraybuffer",
          signal: abortControllerRef.current.signal,
        })
        .then(convertResponseToInlineImage)
        .then((src) => {
          setFullSrcState({
            loading: false,
            error: null,
            data: src,
          });
        })
        .catch((err) => {
          if (err?.message !== "canceled") {
            reportError(err);
          }

          setError("Failed to load image.");
        });
    }
  }, [fullSrcState.data, fullSrcState.loading, value]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    loadFullSizedImage();
    return () => {
      abortControllerRef.current.abort();
    };
  }, [loadFullSizedImage]);

  return {
    image: fullSrcState,
    error: error,
  };
};

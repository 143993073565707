import styled from "@emotion/styled";

export const NodeContainer = styled.div`
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.borderColor};
  cursor: pointer;
  border-radius: 4px;
  min-width: 100px;
  font-size: 12px;
  white-space: nowrap;
  svg {
    padding-right: 8px;
  }
`;

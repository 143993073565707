export default function (config) {
  if (config.valueKeys) {
    return;
  }
  if (!config.valueKey) {
    throw new Error("Pie configuration requires a value key");
  }
  if (!config.groupKey) {
    throw new Error("Pie configuration requires a group key");
  }
}

import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const initialState = {
  toasts: [],
  actionMessage: null,
  link: null,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.ADD_TOAST:
        draft.toasts.push(action.toast);
        break;

      case actionTypes.REMOVE_TOAST: {
        const index = draft.toasts.findIndex((t) => t.id === action.id);
        draft.toasts.splice(index, 1);
        break;
      }

      case actionTypes.SHOW_ACTION_TOAST_MESSAGE: {
        draft.actionMessage = action.message;
        draft.link = action.link;
        draft.settings = action.settings;
        break;
      }

      case actionTypes.HIDE_ACTION_TOAST_MESSAGE: {
        draft.actionMessage = null;
        break;
      }

      default:
        return draft;
    }
  });

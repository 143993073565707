import React from "react";
import Select from "../../../UI/Form/Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faTablet } from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import IOButton from "../../../UI/Form/Button/IOButton";
import { Note } from "../../TableEditor/SettingsMenu/General/LimitRows/LimitRows";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  padding: 20px;
`;

const LeftColumn = styled.div`
  max-width: 400px;
`;

const IconSelectRow = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 12px;
  position: relative;
  top: 12px;
  color: ${(props) => props.theme.text.primary};
`;

export default function CreateBlueprintInput(props) {
  const {
    tables,
    selectedTable,
    setSelectedTable,
    selectedField,
    setSelectedField,
    availableFields,
    handleSaveInput,
    closeCallback,
    rulesets = [],
    activeGroupId,
    showEditingInputs,
  } = props;

  function handleTableChange(option) {
    setSelectedTable(option);
  }

  function handleFieldChange(option) {
    setSelectedField(option);
  }

  function saveInput() {
    handleSaveInput(showEditingInputs?.chainUuid);
  }

  // Process tables data
  const processedTables = tables.map((table) => ({
    value: table.value,
    label: table.label,
  }));

  // do not allow save same table/field in one group
  function validateNewInput() {
    const { inputs = [] } =
      rulesets.find((set) => set.groupId === activeGroupId) ?? {};

    const matchTableName = !!inputs.find(
      (input) => input.table === "dw." + selectedTable?.value
    );

    const matchFieldName = !!inputs.find(
      (input) => input.field === selectedField?.value
    );

    // on edit mode check table and field
    if (showEditingInputs?.chainUuid) {
      return matchTableName && matchFieldName;
    }

    // on create mode only table
    return matchTableName;
  }

  const notAllowSameTableAndField = validateNewInput();

  return (
    <OuterContainer>
      <div style={{ marginBottom: 24 }}>
        Select a field to update values with these rules
      </div>
      <LeftColumn>
        <IconSelectRow>
          <Icon>
            <FontAwesomeIcon icon={faDatabase} />
          </Icon>
          <div style={{ flexGrow: 1 }}>
            <Select
              id="tables"
              value={selectedTable}
              onChange={handleTableChange}
              options={processedTables}
              label="Choose a table"
              cy="raw-table-list"
            />
          </div>
        </IconSelectRow>
        {selectedTable ? (
          <IconSelectRow style={{ marginTop: 24 }}>
            <Icon>
              <FontAwesomeIcon icon={faTablet} />
            </Icon>
            <div style={{ flexGrow: 1 }}>
              <Select
                value={(availableFields ?? []).find(
                  (field) => field.value === selectedField?.value
                )}
                onChange={handleFieldChange}
                options={availableFields}
                label="Choose a field"
                cy="select-field"
              />
            </div>
          </IconSelectRow>
        ) : null}
        {notAllowSameTableAndField && (
          <Note color="warningDark" style={{ margin: "20px 0px 0px 25px" }}>
            This input already exists. Please select another table.
          </Note>
        )}
        <div style={{ marginTop: 36, marginLeft: 26, display: "flex" }}>
          {/* Cancel Button */}
          <IOButton
            style={{ marginRight: 12 }} // Space between buttons
            cy="cancel-blueprint-input"
            cancel
            onClick={closeCallback} // Call the closeCallback on click
          >
            Cancel
          </IOButton>

          {/* Save Button */}
          <IOButton
            disabled={
              !selectedTable || !selectedField || notAllowSameTableAndField
            }
            cy="save-blueprint-input"
            onClick={saveInput}
          >
            Save
          </IOButton>
        </div>
      </LeftColumn>
    </OuterContainer>
  );
}

import React, { useEffect, useState } from "react";
import SectionLabel from "../DataExplorerUI/SectionLabel";
import TypeEditor from "../../../../Editors/TypeEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { allowed, buckets } from "../../../../utils/constants/dataTypes";

const Check = styled.div`
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
  &:hover {
    path {
      fill: ${(props) => props.theme.notification.successMain};
    }
  }
`;

export default function BuilderMapping(props) {
  const { override, field, updateMapping } = props;

  const [overrideLabel, setOverrideLabel] = useState("");

  const overrideDisplayName = override?.mapping?.displayName;
  const initialValue = overrideDisplayName || field?.mapping?.displayName;
  const misMatched = initialValue && overrideLabel !== initialValue;

  useEffect(() => {
    setOverrideLabel(initialValue || "");
  }, [field, initialValue]);

  const bucketMatch = buckets[field?.mapping?.type] || ["string"];
  const allowedTypes = allowed[bucketMatch];

  return (
    <div style={{ marginTop: 24 }}>
      {field ? (
        <div style={{ textAlign: "left", marginBottom: 10, fontSize: 12 }}>
          Field Name: {field.name}
        </div>
      ) : null}
      <SectionLabel>
        Label{" "}
        <input
          data-cy="column-builder-displayName"
          value={overrideLabel}
          onChange={(e) => setOverrideLabel(e.target.value)}
        />
        {misMatched ? (
          <Check
            data-cy="column-builder-displayName-commit"
            onClick={() =>
              updateMapping({
                displayName: overrideLabel,
                type: field.mapping.type,
              })
            }
          >
            <FontAwesomeIcon icon={["fas", "check"]} />
          </Check>
        ) : null}
      </SectionLabel>

      <SectionLabel style={{ marginBottom: 6 }}>Display</SectionLabel>

      <div style={{ position: "relative", left: 18 }}>
        <TypeEditor
          setValue={(type) =>
            updateMapping({ displayName: overrideLabel, type })
          }
          type={override?.mapping?.type || field?.mapping?.type}
          open
          allowed={allowedTypes}
        />
      </div>
    </div>
  );
}

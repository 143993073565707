import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { useWriteBacksContext, getRowIoUuid } from "../WriteBacksContext";
import { pick, keyBy } from "lodash-es";
import WriteBacksCrmField from "./WriteBacksCrmField";
import IOButton from "../../../UI/Form/Button/IOButton";
import Modal from "../../../UI/Modal/Modal";
import useWarnBeforeTabWindowClose from "../../../hooks/useWarnBeforeTabWindowClose";
import { writeBacksFormatConverter } from "../../../utils/writeBacks";
import { ROLE_TENANT_OWNER } from "../../../utils/constants/constants";
import useConfirmDelete from "../../../hooks/useConfirmDelete";

const FieldsContainer = styled.div`
  display: "flex";
  flex-direction: "column";
  gap: 20px;
  flex: 1;
  padding-left: 48px;
  padding-right: 38px;
  overflow: "auto";
`;

export default function WriteBacksCrmEditor({
  row,
  config,
  overrides,
  meta,
  closeModal,
  visualizationId,
}) {
  const writeBacks = useWriteBacksContext();
  const uuid = getRowIoUuid(row.values?.[0]);
  const user = JSON.parse(localStorage.getItem("user"));
  const confirm = useConfirmDelete(
    () => onDelete(),
    `Do you want to delete this record?`,
    null,
    null,
    8000000
  );

  const writableFieldNames = overrides
    .filter((v) => v.writeBack)
    .map((v) => v.name);

  const authWritableFields = overrides
    .filter((v) => v.writeBack)
    .filter((v) => v.writeBack.auth);

  const fieldMetaMap = useMemo(() => {
    return keyBy(meta.fields, "name");
  }, [meta.fields]);

  const initialWritableRow = useMemo(() => {
    const ret = {};

    for (const fieldName of writableFieldNames) {
      ret[fieldName] = writeBacksFormatConverter.response.toForm(
        row.values ? row.values[0][fieldName] : "",
        fieldMetaMap[fieldName]
      );
    }

    for (const authField of authWritableFields) {
      ret[authField.name] = writeBacksFormatConverter.response.toForm(
        user[authField.writeBack.auth.value],
        authField.name
      );
    }

    return ret;
  }, [fieldMetaMap, row.values, writableFieldNames, authWritableFields, user]);

  const writableRowWithOverrides = useMemo(() => {
    if (!uuid) {
      return { ...initialWritableRow };
    }
    return {
      ...initialWritableRow,
      ...writeBacks.writtenBacks[getRowIoUuid(row.values[0])],
    };
  }, [initialWritableRow, row.values, uuid, writeBacks.writtenBacks]);
  const overridesByFieldName = useMemo(() => {
    return keyBy(overrides, "name");
  }, [overrides]);

  const form = useForm({
    defaultValues: writableRowWithOverrides,
    mode: "onBlur",
  });
  const { handleSubmit, formState, reset } = form;
  const { isDirty, dirtyFields, isSubmitting } = formState;

  const drillDownSaveFields = useMemo(() => {
    return Object.keys(overridesByFieldName)
      .map((k) => overridesByFieldName[k].writeBack)
      .filter((w) => w && w.drilldownField);
  }, [overridesByFieldName]);

  async function onSubmit(data) {
    const dirtyData = pick(data, Object.keys(dirtyFields));

    Object.keys(dirtyData).forEach((d) => {
      if (overridesByFieldName[d] && overridesByFieldName[d].mapping) {
        const currency = overridesByFieldName[d].mapping.type === "currency";

        if (currency) {
          dirtyData[d] = dirtyData[d] ? dirtyData[d] : 0;
        }
      }
    });

    if (drillDownSaveFields && drillDownSaveFields.length) {
      const uuid = row.values?.length
        ? row.values[0][drillDownSaveFields[0].drilldownQuerySave.uuidField]
        : undefined;
      const editMode = typeof uuid !== "undefined";
      const newDirtyData = { ...dirtyData };
      const keysToRemove = [
        ...drillDownSaveFields.map((d) => d.drilldownField),
      ];

      keysToRemove.forEach((k) => delete newDirtyData[k]);

      const newUuid = !uuid
        ? await writeBacks.doWriteBackRow(uuid, newDirtyData, undefined)
        : uuid;

      for (let drillDownSaveField of drillDownSaveFields) {
        const saveData = dirtyData[drillDownSaveField.drilldownField];

        if (saveData) {
          const drillDownSaveData = saveData.map((d) => ({
            [drillDownSaveField.drilldownQuerySave.uuidField]: newUuid,
            [drillDownSaveField.drilldownQuerySave.saveField]: d.value,
            [drillDownSaveField.drilldownField]: d.label,
            ...(d.additionalField && {
              [d.additionalField.saveField]: d.additionalField.value,
            }),
            uuid: editMode ? d.uuid : undefined,
          }));

          await writeBacks.writeBackDrillDownRows(
            drillDownSaveField.drilldownQuerySave.id,
            drillDownSaveData,
            saveData.uuid
          );
        }
      }

      if (config.updateAllChartsOnAdd) {
        writeBacks.refreshCharts(visualizationId);
      }
    } else {
      if (writeBacks.dbtPostProcessing) {
        const triggerValidation = writeBacks.dbtPostProcessing.trigger
          ? writeBacks.dbtPostProcessing.trigger.reduce((acc, curr) => {
              if (acc === false) {
                return false;
              }

              if (typeof curr.value === "boolean") {
                return !!data[curr.field] === curr.value;
              }

              return data[curr.field] === curr.value;
            }, true)
          : true;

        if (triggerValidation) {
          await writeBacks.writeBackRow(
            uuid,
            dirtyData,
            writeBacks.dbtPostProcessing
          );
        } else {
          await writeBacks.writeBackRow(
            uuid,
            dirtyData,
            undefined,
            visualizationId
          );
        }
      } else {
        await writeBacks.writeBackRow(uuid, dirtyData);
      }
    }

    reset(data);

    closeModal();
  }

  async function onDelete() {
    if (!uuid) {
      alert("Delete operation is not allowed.");
    }

    await writeBacks.deleteWriteBackRow(uuid, visualizationId);
    closeModal();
  }

  const { prompt } = useWarnBeforeTabWindowClose(isDirty);

  const fields = useMemo(() => {
    const rowByGroupChildName = {};
    const rows = [];

    for (const writableFieldName of writableFieldNames) {
      const writeBackOptions =
        overridesByFieldName[writableFieldName].writeBack;

      if (
        writeBackOptions.tenant_owner_only &&
        user.role !== ROLE_TENANT_OWNER
      ) {
        continue;
      }

      let field = (
        <WriteBacksCrmField
          register={form.register}
          control={form.control}
          writeBackOptions={writeBackOptions}
          key={writableFieldName}
          fieldName={writableFieldName}
          uuid={uuid}
          type={fieldMetaMap[writableFieldName].type}
          meta={fieldMetaMap[writableFieldName]}
          setValue={form.setValue}
          row={row}
        />
      );

      if (rowByGroupChildName[writableFieldName]) {
        // Add this item to be part of the same row as its "parent".
        rowByGroupChildName[writableFieldName].push(field);

        continue;
      }

      const rowField = [field];

      if (writeBackOptions.sameRowField) {
        // This field has "children" that are to be put in the same row.
        rowByGroupChildName[writeBackOptions.sameRowField] = rowField;

        rows.push(rowField);

        continue;
      }

      rows.push(rowField);
    }

    return rows.map((row) =>
      row.length <= 1 ? (
        row[0]
      ) : (
        <div key={row[0].props.fieldName} style={{ display: "flex", gap: 30 }}>
          {row.map((field) => (
            <div key={field.props.fieldName} style={{ flex: 1 }}>
              {field}
            </div>
          ))}
        </div>
      )
    );
  }, [
    fieldMetaMap,
    form.control,
    form.register,
    form.setValue,
    overridesByFieldName,
    uuid,
    writableFieldNames,
    row,
    user.role,
  ]);

  function onModalCloseAttempt() {
    if (
      isDirty &&
      !window.confirm(
        "You have unsaved changed. Are you sure you would like to leave this form?"
      )
    ) {
      return;
    }

    closeModal();
  }

  return (
    <>
      <Modal
        close={onModalCloseAttempt}
        controlledFromOutside
        renderInPortal
        showClose
        xPadding={0}
      >
        <form
          onSubmit={(event) => {
            event.stopPropagation(); // Prevent bubbling to BlockEditor's form.
            return handleSubmit(onSubmit)(event);
          }}
          style={{
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <FieldsContainer>
            {config.instructions && (
              <div style={{ whiteSpace: "pre-wrap", marginBottom: 20 }}>
                {config.instructions}
              </div>
            )}

            {fields}
          </FieldsContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 100,
            }}
          >
            {writeBacks.canDelete && uuid ? (
              <IOButton
                error
                processing={isSubmitting}
                type="button"
                onClick={confirm.setConfirming}
              >
                Delete
              </IOButton>
            ) : null}
            <IOButton disabled={!isDirty} processing={isSubmitting}>
              Save
            </IOButton>
          </div>
        </form>

        {prompt}
      </Modal>
      {confirm.confirming}
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { colors } from "../constants";

function background(props) {
  const { active, theme, visible } = props;
  if (visible && !active) return colors.visible;
  return active
    ? theme.notification.infoMain
    : theme.type === "dark"
    ? theme.gray.gray700
    : theme.gray.gray500;
}

const ColumnTile = styled.div`
  background: ${background};
  color: white;
  padding: 6px ${(props) => (props.editable ? 30 : 8)}px 6px 8px;
  margin-right: 6px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  //overflow: hidden;
  white-space: nowrap;
`;

const Editable = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  height: 22px;
  width: 16px;
  svg {
    position: relative;
    left: 2px;
    top: 2px;
    path {
      fill: ${(props) => (props.visible ? colors.editable : "white")};
    }
  }
`;

export default function DraggableColumnTile(props) {
  const { provided, handleSetActive, active, col, maxChars, dragging } = props;

  return (
    <ColumnTile
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() => handleSetActive(col.colId)}
      active={active(col)}
      data-cy={dragging ? "dragging-column-tile" : "column-tile"}
      data-active={active(col)}
      maxChars={maxChars}
      visible={col.visible}
      editable={col.editable}
      title={col.field + " | " + col.type}
    >
      {col.editable ? (
        <Editable>
          <FontAwesomeIcon icon={faPencil} />
        </Editable>
      ) : null}
      {col.displayName}
    </ColumnTile>
  );
}

import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Form/Input/Input";
import styled from "@emotion/styled";
import { themeColor, themeText } from "../../styles/styledSnippets";
import BigInputItem from "./BigInputItem";

const FolderRowContainer = styled.div`
  display: flex;
`;
const IconWrapper = styled.span`
  cursor: pointer;
  svg {
    color: ${(props) =>
      props.active
        ? themeColor("infoMain")(props)
        : themeText("secondary")(props)};
    position: relative;
    top: 4px;
    left: -4px;
  }
`;

export default function FoldersRows(props) {
  const {
    folders,
    handleInputChange,
    activeIndex,
    setActive,
    removeFromFolder,
  } = props;

  return folders.map((folder, folderIndex) => (
    <Fragment key={folderIndex}>
      <FolderRowContainer key={folderIndex}>
        <IconWrapper
          data-cy="folder-action"
          onClick={() => setActive(folderIndex)}
          active={activeIndex === folderIndex}
        >
          <FontAwesomeIcon icon={["fal", "folder-open"]} />
        </IconWrapper>
        <Input
          value={folder.label}
          onChange={handleInputChange}
          onFocus={() => setActive(folderIndex)}
          inPlaceEdit
          transparent
          cy="folder-input"
          small
        />
      </FolderRowContainer>
      {folder.children.map((child, childIndex) => (
        <div key={child.id} style={{ paddingLeft: 28 }}>
          <BigInputItem
            data-cy="folder-child"
            key={child.id}
            onClick={() => removeFromFolder(childIndex, folderIndex)}
          >
            {child.label}
          </BigInputItem>
        </div>
      ))}
    </Fragment>
  ));
}

FoldersRows.defaultProps = {
  folders: [],
};

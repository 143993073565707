import React from "react";
import LegendBlocks from "./Legend/LegendBlocks";
import FixtureSalesFunnel from "./FixtureSalesFunnel";
import Details from "../Layout/Details/Details";
import { useSelector } from "react-redux";
import VisualizationBase from "./BaseChart/VisualizationBase";
import { scaleOrdinal } from "d3-scale";
import { schemeTableau10 } from "d3-scale-chromatic";
// import {unique} from '../utils/func';

export default function SalesFunnelVisualization(props) {
  const { dateFilters, chart, details, dashboardId, height } = props;
  const {
    xKey,
    yKeyInner = "SalesFunnelCategory",
    yKeyInnerValues = [
      "Installations",
      "Bookings",
      "Proposals",
      "Pipeline",
      "Installation Backlog",
    ],
    yKeyOuter = "QuarterYear",
    barDimension,
    disableRounding,
    colorFunction,
    dynamicMode,
    orderedKeys,
    meta,
    defaultFormatting,
    overrideColors,
    xLabelFormat,
    labelFormat,
    names,
    data,
    visualizationId,
    yLabelOuter,
  } = chart;

  const filters = useSelector((state) => state.layout.menuFilters);
  const colors = scaleOrdinal(schemeTableau10);

  // @todo names come from BarDimension, issue is order, and how do we make sortable in configurable way
  // ALSO legend order and color order isn't the same
  // const names = unique(data.map(d => d[barDimension]));
  // const names = ["Entertain 360", "Engage 360", "Ensure 360", "Engage E3"];

  return (
    <>
      <div id={visualizationId} style={{ position: "relative" }}>
        <LegendBlocks
          names={names}
          colors={colorFunction ? colors : overrideColors || companyColors}
          filters={filters || []}
          colorFunction={colorFunction}
        />
        <VisualizationBase {...{ ...props, margin, height }}>
          <FixtureSalesFunnel
            names={names}
            data={data}
            filters={filters}
            dateFilters={dateFilters}
            colors={colorFunction ? colors : overrideColors || companyColors}
            xKey={xKey}
            yKeyInner={yKeyInner}
            yKeyInnerValues={yKeyInnerValues}
            yKeyOuter={yKeyOuter}
            barDimension={barDimension}
            disableRounding={disableRounding}
            colorFunction={colorFunction}
            dynamicMode={dynamicMode}
            orderedKeys={orderedKeys}
            meta={meta}
            defaultFormatting={defaultFormatting}
            xLabelFormat={xLabelFormat}
            labelFormat={labelFormat}
            yLabelOuter={yLabelOuter}
          />
        </VisualizationBase>
      </div>
      {details && data.length > 0 ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={visualizationId}
        />
      ) : null}
    </>
  );
}

const margin = { left: 120, top: 20, right: 55, bottom: 0 };

const companyColors = ["#309CE0", "#E4DB00", "#83D1F6", "#9C3A8F"];

import React, { useRef, useState } from "react";
import ChartActions from "./ChartActions";
import styled from "@emotion/styled";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled.div`
  font-size: 14px;
  position: absolute;
  top: -44px;
  right: 0;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.text.primary};
  }
`;

export default React.memo(function ChartActionsToggle(props) {
  const [show, setShow] = useState(false);
  const node = useRef(null);
  useOnClickOutside(node, () => setShow(false));
  if (!props.variableValueKeys) return null;

  return (
    <>
      <Icon onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={["fal", "cogs"]} />
      </Icon>
      <div ref={node}>
        <ChartActions show={show} {...props} />
      </div>
    </>
  );
});

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/pro-light-svg-icons";

export default function DetailInstructions(props) {
  return (
    <div style={{ marginBottom: 28, fontWeight: 300 }}>
      <FontAwesomeIcon
        icon={faHandPointRight}
        style={{
          marginRight: 12,
          fontSize: 22,
          position: "relative",
          top: 3,
        }}
      />
      {props.children}
    </div>
  );
}

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listQueries } from "../../../../store/actions";
import DrilldownItem from "./DrilldownItem";
import EditDrilldown from "./EditDrilldown";
import {
  deleteNestedDrilldownConfig,
  setDynamicFiltersWithValues,
  updateNestedDrilldownProperty,
  setNullIfNone,
  getDynamicDrilldownParent,
  updateParentKey,
  cleanDrilldownParent,
} from "../../utils/tableEditorHelper";
import { scrollbarDe } from "../../../../styles/styledSnippets";

const Container = styled.div(
  ({ disabled, theme }) => `
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 40px);
  ${scrollbarDe(theme)};
  ${
    disabled &&
    `pointer-events: none;
    opacity: 0.4;`
  };
`
);

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

export default function DrilldownHierarchy({
  chart,
  setChartState,
  previewChart,
  levelIndicator,
  setlevelIndicator,
  editLevel,
  setEditLevel,
  levelData = {},
  setLevelData,
  fields,
  drilldownParent,
  setDrilldownParent,
  queries,
}) {
  const { data = [], refreshing, loading } = previewChart ?? {};
  const [localChart, setLocalChart] = useState(chart);
  const [parent, setParent] = useState("");
  // temporary config with new added level or edit some level
  const [temporaryChartConfig, setTemporaryChartConfig] = useState(null);
  const currentLocalChart = temporaryChartConfig || localChart;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listQueries());
  }, [dispatch]);

  // save first row of top level data
  useEffect(() => {
    if (!levelData[0] && data.length) {
      setLevelData((prev) => ({ ...prev, 0: data[0] }));
    }
  }, [data, levelData, setLevelData]);

  const getTableRow = (level, dynamicFilters = []) => {
    const row = data.find((item) =>
      dynamicFilters.some((filter) => item[filter])
    );

    return levelData[level] ?? row ?? data[0];
  };

  const updatePreview = (level, parent, dynamicFilters) => {
    const row = getTableRow(level, dynamicFilters);

    // drop branch values
    if (level === 0) {
      dropDynamicLevelIndicators();
    }

    if (parent) {
      const keys = getDynamicDrilldownParent(drilldownParent, level, parent);
      setDrilldownParent(keys);
    }

    if (!levelData[level]) {
      setLevelData((prev) => ({ ...prev, [level]: row }));
    }

    const res = setDynamicFiltersWithValues({
      row,
      chart,
      level,
      drilldownKeys: { ...drilldownParent, [level]: parent },
    });

    setlevelIndicator(level);
    setChartState(res, true);
    setLocalChart(res);
  };

  const dropDynamicLevelIndicators = () => {
    setDrilldownParent({});
    setLevelData((prev) => prev[0]);
  };

  const dropLevelIndicators = (isDeleteAction) => {
    setEditLevel(0);
    if (isDeleteAction) {
      setlevelIndicator(0);
    }
  };

  const deleteDrilldown = () => {
    const res = deleteNestedDrilldownConfig(
      localChart,
      editLevel,
      drilldownParent
    );

    setChartState(res, true);
    setLocalChart(res);
    dropLevelIndicators(true);
    dropDynamicLevelIndicators();
    setTemporaryChartConfig(null);
  };

  const saveDrilldown = (level) => {
    const isKeyChanged = parent !== drilldownParent[level];

    const res = isKeyChanged
      ? updateParentKey({
          chart: currentLocalChart,
          value: parent,
          level,
          drilldownParent,
        })
      : currentLocalChart;

    setChartState(res, true);
    setLocalChart(res);

    // if you updating or adding to top level you need dropp all indicators
    const dropAll = !res.addAction || level === 1;

    dropLevelIndicators(dropAll);
    setDrilldownParent((prev) => cleanDrilldownParent(prev, level));

    if (dropAll) {
      dropDynamicLevelIndicators();
    }

    setTemporaryChartConfig(null);
  };

  const onDynamicFiltersChange = (options, propertyName, key) => {
    if (!options) {
      return;
    }

    const values = Array.isArray(options)
      ? options.map((o) => o[key])
      : setNullIfNone(options[key]);

    const res = updateNestedDrilldownProperty({
      chart: currentLocalChart,
      propertyName,
      values,
      level: editLevel,
      drilldownKeys: drilldownParent,
      queries,
    });

    setTemporaryChartConfig(res);
  };

  const innerProps = {
    chart: localChart,
    queries,
    setEditLevel,
    setChartState,
    levelData,
    setLocalChart,
    localChart,
    setTemporaryChartConfig,
    fields,
    drilldownParent,
    setDrilldownParent,
    parent,
    setParent,
    dropDynamicLevelIndicators,
  };

  return (
    <Container disabled={loading || refreshing}>
      <Title>Drilldown hierarchy</Title>

      <DrilldownItem
        {...innerProps}
        levelIndicator={levelIndicator}
        updatePreview={updatePreview}
      />

      <EditDrilldown
        {...innerProps}
        chart={currentLocalChart}
        level={editLevel}
        deleteDrilldown={deleteDrilldown}
        saveDrilldown={saveDrilldown}
        onDynamicFiltersChange={onDynamicFiltersChange}
      />
    </Container>
  );
}

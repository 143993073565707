import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";

export default function HideNullableColumns({ setChartState, chart, column }) {
  const hiddens = chart.hiddenNullableColumns ?? [];
  const isHiddenOnEmpty = hiddens.includes(column);

  function hideOnEmptyData() {
    setChartState({
      ...chart,
      hiddenNullableColumns: isHiddenOnEmpty
        ? hiddens.filter((item) => item !== column)
        : [...hiddens, column],
    });
  }

  return (
    <>
      <br />
      <Checkbox
        label="Hide column if column data is empty"
        checked={isHiddenOnEmpty}
        onChange={hideOnEmptyData}
      />
    </>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { themeText } from "../../styles/styledSnippets";
const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  color: ${themeText("secondary")};
  font-size: 14px;
  margin-bottom: 4px;
`;

const HeaderLabels = (props) => {
  const { leftLabel, rightLabel, tripleMode, tripleLabel } = props;
  return leftLabel || rightLabel ? (
    <LabelContainer>
      <div style={{ width: "50%", paddingLeft: 12 }}>{leftLabel}</div>
      <div style={{ width: "50%", paddingLeft: 12 }}>{rightLabel}</div>
      {tripleMode ? (
        <div style={{ width: "50%", paddingLeft: 12 }}>{tripleLabel}</div>
      ) : null}
    </LabelContainer>
  ) : null;
};

export default HeaderLabels;

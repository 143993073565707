import styled from "@emotion/styled";

export const UploadContainer = styled.div(
  ({ theme, isInvalid }) => `
    padding: 2px;
    display: flex;
    color: ${isInvalid ? theme.notification.errorMain : theme.text.secondary};
    border: 1px dashed ${
      isInvalid ? theme.notification.errorMain : theme.text.secondary
    };
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 2px;
    cursor: pointer;
    width: 240px;
    height: 240px;
    font-size: 1em;
    position: relative;

    & > label {
      margin-top: 10px;
      cursor: pointer;
    }

    & > span {
      position: absolute;
      top: 0px;
      right: 5px;
      color: ${theme.notification.errorMain};
    }
`
);

import { themeGrayIf, themeShadow } from "../../styles/styledSnippets";
import { lightenDarkenColor } from "../../styles/colorConvertor";

export const scrollbar = (props) => `
    &::-webkit-scrollbar {
        height: .8em;
        width: .8em;
    }
   
    &::-webkit-scrollbar-track {
      background: ${themeGrayIf(900, 200)(props)};
    }
    
    &::-webkit-scrollbar-thumb {
        border: .2em solid ${themeGrayIf(900, 200)(props)};
        transition: .8s linear border;
        background-color: ${lightenDarkenColor(
          themeGrayIf(800, 300)(props),
          -5
        )};
        border-radius: 6px;
        ${themeShadow(1)(props)};
    }
    
    &::-webkit-scrollbar-thumb:hover {
         background-color: ${lightenDarkenColor(
           themeGrayIf(800, 300)(props),
           -5
         )};
    }
`;

const shadows = {
  dark: {
    from: "rgba(0,0,0, .3)",
    to: "rgba(0, 0, 0, 0.1)",
  },
  light: {
    from: "rgba(204, 219, 232, .5)",
    to: "rgba(255, 255, 255, 0.2)",
  },
};

export const boxShadows = (props) => `
  ${shadows[props.theme.type].from} 3px 3px 6px 0px inset,
  ${shadows[props.theme.type].to} -3px -3px 6px 1px inset;
`;

import React from "react";
import { animated, useSpring } from "react-spring";

export default function SpringGroupDriver(props) {
  const { x, y } = props;

  const aniProps = useSpring({
    transform: `translate(${x} ${y})`,
    opacity: 1,
    from: {
      transform: `translate(${x} 200)`,
      opacity: 0,
    },
  });
  return (
    <animated.g transform={aniProps.transform} opacity={aniProps.opacity}>
      {props.children}
    </animated.g>
  );
}

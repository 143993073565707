import { lightenDarkenColor } from "../../styles/colorConvertor";

export default function color(v, theme) {
  switch (v) {
    case "success":
      return theme.notification.successMain;
    case "failure":
      return theme.notification.errorMain;
    case "primary":
      return theme.primary;
    case "primary-light":
      return lightenDarkenColor(theme.primary, 50);
    case "primary-dark":
      return lightenDarkenColor(theme.primary, -30);
    case "text-tint":
      return theme.text.secondary;
    case "text-full":
      return theme.text.primary;
    case "other":
      return theme.gray.gray800;
    default:
      return theme.primary;
  }
}

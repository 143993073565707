import React from "react";
import { pie, arc } from "d3-shape";
import { scaleOrdinal } from "d3-scale";
import Arc from "./Arc";
import styled from "@emotion/styled";

const DisplayText = styled.text(
  ({ width, theme, large, hasSubtitle, color, themePrimary }) => `
    text-anchor: middle;
    font-size: ${width / (large ? 4 : 5)}px;
    font-weight: 700;
    fill: ${themePrimary ? theme.primary : color || theme.gray700};
    transform: translateY(${width / 17 + (hasSubtitle ? 20 : 0)}px);    
`
);

const DisplaySubTitle = styled.text(
  ({ width, theme }) => `
    text-anchor: middle;
    font-size: ${width / 14}px;
    fill: ${theme.gray700};
    transform: translateY(${width / 17 - 40}px);
`
);

const defaultColors = scaleOrdinal([
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
]);

export default function GaugeChart(props) {
  const {
    colors,
    data,
    thickness,
    width,
    height,
    label,
    subtitle,
    labelColor,
    themePrimary,
    hideLabel,
  } = props;
  const chartColors = colors || defaultColors;

  const size = Math.min(...[+height, +width]);
  const leftMargin = width > height ? (width - size) / 2 : 0;

  const arcs = pie().sort(null)(data);
  const createArc = arc()
    .innerRadius((size / 2) * (1 - thickness))
    .outerRadius(size / 2);

  return (
    <g
      transform={`translate(${leftMargin + size / 2} ${size / 2})`}
      data-cy="gauge-chart-group"
    >
      {arcs.map((d, i) => (
        <Arc
          key={i}
          data={d}
          index={i}
          createArc={createArc}
          colors={chartColors}
        />
      ))}
      {subtitle ? (
        <DisplaySubTitle width={size}>{subtitle}</DisplaySubTitle>
      ) : null}

      {!hideLabel ? (
        <DisplayText
          data-cy="gauge-label"
          width={size}
          hasSubtitle={!!subtitle}
          color={labelColor}
          themePrimary={themePrimary}
        >
          {label}
        </DisplayText>
      ) : null}
    </g>
  );
}

GaugeChart.defaultProps = {
  thickness: 0.25,
  label: "",
};

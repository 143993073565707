import React, { useContext } from "react";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../../UI/Form/Button/IOButton";
import LeftRightAreaContext from "../../../UI/BigInput/LeftRightAreaContext";

export default function HeroBlockLayoutActionButtons(props) {
  const { prepareSave } = useContext(LeftRightAreaContext);

  function closeAndSetLocally() {
    prepareSave();
    props.onClose();
  }

  return (
    <div style={{ flexShrink: 0 }}>
      <ButtonGroup spacing={6}>
        <IOButton cy="button-cancel" onClick={props.onClose} cancel>
          Cancel
        </IOButton>
        <IOButton cy="button-save" standard onClick={closeAndSetLocally}>
          Save
        </IOButton>
      </ButtonGroup>
    </div>
  );
}

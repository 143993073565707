import React from "react";
import RightArea from "./RightArea";
import ColorSelector from "../ColorSelector/ColorSelector";
import produce from "immer";
import styled from "@emotion/styled";
import { themeText } from "../../styles/styledSnippets";
import IOButton from "../Form/Button/IOButton";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

const Label = styled.div`
  color: ${themeText("primary")};
  font-size: 16px;
  margin-bottom: 12px;
  span {
    color: ${themeText("secondary")};
    font-weight: 100;
    font-size: 14px;
  }
`;

export default function RightAreaColorBlock(props) {
  const { active, setActive, colors, setColors } = props;
  const activeColorSetting = active && colors.find((c) => c.id === active.id);

  function handleSetColors(nextColor) {
    const activeIndex = colors.findIndex((c) => c.id === active.id);

    const nextColors = produce(colors, (draft) => {
      if (activeIndex !== -1) {
        draft[activeIndex] = { ...active, color: nextColor };
      } else draft.push({ ...active, color: nextColor });
    });

    return setColors(nextColors);
  }

  function handleRemoveSetting() {
    setColors(colors.filter((c) => c.id !== active.id));
  }

  return (
    <RightArea data-cy="input-right-area-colors">
      {active ? (
        <div style={{ position: "absolute", left: 18, top: 14 }}>
          <Label>
            {active.label}{" "}
            {!activeColorSetting ? (
              <span>
                <em>default</em>
              </span>
            ) : null}
          </Label>
          <ColorSelector
            onChange={handleSetColors}
            value={activeColorSetting?.color}
            open
          />
        </div>
      ) : null}

      {active ? (
        <div style={{ position: "absolute", top: 310, left: 22 }}>
          <ButtonGroup position="flex-start" spacing={6} layout="horizontal">
            {activeColorSetting ? (
              <IOButton cancel onClick={handleRemoveSetting} smallPadding>
                Remove
              </IOButton>
            ) : null}
            <IOButton cancel smallPadding onClick={() => setActive(null)}>
              Close
            </IOButton>
          </ButtonGroup>
        </div>
      ) : null}
    </RightArea>
  );
}

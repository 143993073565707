import React, { useMemo, useState } from "react";
import IOButton from "../../../UI/Form/Button/IOButton";

import styled from "@emotion/styled";
import BlueprintInput from "./BlueprintInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import Modal from "../../../UI/Modal/Modal";

import CreateNewRuleset from "./CreateNewRuleset";
import CreateBlueprintInput from "./CreateBlueprintInput";
import { PlayCircle } from "./PlayCircle";
import BlueprintOutputTable from "./BlueprintOutputTable";
import { scrollbarDe } from "../../../styles/styledSnippets";
import { filterTableColumns } from "../../../store/actions/dataSettings/blueprintHelpers";

const RulesetRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 40px;
  & > div {
    padding: 12px 24px;
    padding: 20px 10px 10px 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.position || "center"};
    align-items: center;
  }
`;

const RulesContainer = styled(Link)`
  color: white;
  background: ${(props) => props.theme.notification.infoMain};
  width: 40%;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 140px;
  justify-content: center;
  border-radius: 5px;
  &:hover {
    background: ${(props) => props.theme.notification.infoLight};
  }
  a {
    text-decoration: none;
    color: white;
  }
`;

const InputsContainer = styled.div(
  ({ theme }) => `
  width: 40%;
  margin-right: 24px;
  color: ${(props) => props.theme.text.primary};
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 5px;
  & > div {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10px;
    ${scrollbarDe(theme)};
  }
`
);

const InputButton = styled.div`
  align-self: baseline;
  font-size: 12px;
  cursor: pointer;
  margin-left: 10px;
  svg {
    margin-right: 8px;
  }
`;

export default function BlueprintMap(props) {
  const {
    rulesets,
    tables = [],
    createBlueprint,
    creating,
    handleSaveInput,
    processRuleset,
    handleDeleteInput,
    deletingInput,
    blueprints,
  } = props;
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [blueprintName, setBlueprintName] = useState("");
  const [showEditingInputs, setShowEditingInputs] = useState(false);
  const [activeGroupId, setActiveGroupId] = useState(null);

  const tableNames = tables.map((t) => ({ label: t.name, value: t.name }));

  const availableFields = useMemo(() => {
    if (selectedTable) {
      return filterTableColumns(tables, selectedTable.value);
    }

    return [];
  }, [selectedTable, tables]);

  function prepareBlueprintCreate() {
    createBlueprint(blueprintName, selectedTable.value, selectedField.value);
  }

  function setActiveRuleset(groupId) {
    setShowEditingInputs(true);
    setActiveGroupId(groupId);
  }

  function saveInput(chainUuid) {
    handleSaveInput(
      activeGroupId,
      selectedTable.value,
      selectedField.value,
      onCloseModal,
      chainUuid
    );
  }

  function selectBlueprintInput(set, input) {
    // Remove 'dw.' prefix before setting the state
    const tableName = input.table.replace("dw.", "");

    setSelectedTable({ label: tableName, value: tableName });
    setSelectedField({ label: input.field, value: input.field });

    // Set the activeGroupId state
    setActiveGroupId(set.groupId);

    // Display the Modal for editing inputs
    setShowEditingInputs(input);
  }

  function onCloseModal() {
    setShowEditingInputs(false);
    setActiveGroupId(null);
    setSelectedTable(null);
    setSelectedField(null);
  }

  return (
    <div>
      {!rulesets.length ? (
        <CreateNewRuleset
          tableNames={tableNames}
          blueprintName={blueprintName}
          setBlueprintName={setBlueprintName}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          availableFields={availableFields}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          creating={creating}
          prepareBlueprintCreate={prepareBlueprintCreate}
          blueprints={blueprints}
        />
      ) : null}
      {rulesets.map((set, i) => (
        <RulesetRow key={set.name + i}>
          <InputsContainer position="flex-start">
            <div>
              {set?.inputs ? (
                set.inputs.map((input, index) => (
                  <BlueprintInput
                    key={input.field + index}
                    onClick={() => selectBlueprintInput(set, input)}
                    onBlueprintChainDelete={handleDeleteInput}
                    deletingInput={deletingInput}
                    {...input}
                  />
                ))
              ) : (
                <div data-cy="input-message">
                  You have not defined any inputs...
                </div>
              )}
            </div>
            <InputButton
              data-cy="add-input"
              onClick={() => setActiveRuleset(set.groupId)}
            >
              <span>
                <FontAwesomeIcon icon={["fal", "plus"]} />
                New Input
              </span>
            </InputButton>
          </InputsContainer>

          <RulesContainer
            data-cy="ruleset-container"
            to={`/admin/data-blueprint/${set.groupId || set.groupUuid}`}
          >
            <div
              style={{
                fontSize: 16,
                textAlign: "center",
              }}
              data-cy="ruleset-title"
            >
              {set.name}
            </div>
          </RulesContainer>

          <PlayCircle onClick={() => processRuleset(set.groupId)} />

          <InputsContainer style={{ width: "20%" }}>
            <div>
              {set.inputs
                ? set.inputs.map((input, index) => (
                    <BlueprintOutputTable
                      set={set}
                      input={input}
                      key={input.chainUuid + index}
                      blueprintGroupIndex={i}
                    />
                  ))
                : null}
            </div>
          </InputsContainer>
        </RulesetRow>
      ))}
      {rulesets?.length ? (
        <div style={{ marginTop: 100 }}>
          <Link to="/admin/data-blueprint/create">
            <IOButton cy="add-ruleset" outline add>
              Add Ruleset
            </IOButton>
          </Link>
          <br />
        </div>
      ) : null}
      {showEditingInputs ? (
        <Modal close={onCloseModal}>
          <CreateBlueprintInput
            tables={tableNames}
            selectedTable={selectedTable}
            setSelectedTable={setSelectedTable}
            availableFields={availableFields}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            handleSaveInput={saveInput}
            closeCallback={onCloseModal}
            rulesets={rulesets}
            activeGroupId={activeGroupId}
            showEditingInputs={showEditingInputs}
          />
        </Modal>
      ) : null}
    </div>
  );
}

BlueprintMap.defaultProps = {
  rulesets: [],
};

import React from "react";
import styled from "@emotion/styled";

const general = `
  border-radius: 3px;
  height: 5px;
  position: absolute;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

function background({ theme, filled, background }) {
  if (filled) {
    return background ?? theme.primary;
  }
  return theme.gray.gray400;
}

function width({ filled, theme, percentFilled }) {
  if (filled) {
    return percentFilled + "%";
  } else {
    return "98%";
  }
}

const Track = styled.div`
  ${general}
  background: ${background};
  width: ${width};
  margin-top: 1px;
  z-index: 1;
`;

const Range = styled.div(
  ({ theme }) => `
  ${general}
  background: ${theme.primary};
  margin-top: 1px;
  z-index: 2;
`
);
export default function FilledSlider(props) {
  const { percentFilled } = props;
  return (
    <SliderContainer>
      <Track />
      <Track
        filled
        percentFilled={percentFilled}
        background={props.background}
      />
      <Range />
    </SliderContainer>
  );
}

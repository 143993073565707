export function getLimitable(value, count = 100) {
  return typeof value === "string" && value.length > count;
}

export function getLimitedValue(limitable, value, count = 100) {
  if (limitable) {
    return value.substring(0, count) + "...";
  }
  return value;
}

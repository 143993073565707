import React from "react";
import { useSelector } from "react-redux";
import MultiRangeSlider from "./MultiRangeSlider";

// @todo, this needs to be broken out into reusable component for slider
export default function MultiRangeFilter({ onChange, filter }) {
  const multiRangeFilters = useSelector(
    (state) => state.layout.multiRangeFilters
  );
  const { type, format, values } = filter;

  // We are creating on the fly checked values on changes in the reducer
  const [checkedMin, checkedMax] = values.filter((v) => v.checked);
  const checked = {
    min: checkedMin.value,
    max: checkedMax.value,
  };

  function handleChange(nextMin, nextMax) {
    if (filter.min !== nextMin || filter.max !== nextMax) {
      onChange(
        {
          ...filter,
          min: nextMin,
          max: nextMax,
          type: values[0]?.type,
        },
        type
      );
    }
  }

  return (
    <MultiRangeSlider
      onChange={handleChange}
      type={type}
      format={format}
      values={values}
      min={filter.limitMin}
      max={filter.limitMax}
      multiRangeFilters={multiRangeFilters}
      checked={checked}
    />
  );
}

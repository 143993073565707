import React from "react";
import styled from "@emotion/styled";
import { css } from "../../../../utils/ide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faMinus,
} from "@fortawesome/pro-regular-svg-icons";

const ChangeCellDiv = styled.td(
  ({ theme }) => css`
    &.positive {
      color: ${theme.notification.successDark};
    }

    &.negative {
      color: ${theme.notification.errorDark};
    }
  `
);

export default function ChangeCell(props) {
  const { resultRow } = props;
  return (
    <ChangeCellDiv className={resultRow.changeDirection}>
      <FontAwesomeIcon
        pull="left"
        icon={
          resultRow.change > 0
            ? faArrowUp
            : resultRow.change < 0
            ? faArrowDown
            : faMinus
        }
      />
      {resultRow.change}
    </ChangeCellDiv>
  );
}

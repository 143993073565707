import React, { Fragment } from "react";
import { scaleBand, scaleLinear } from "d3-scale";
import { useTheme } from "emotion-theming";
import { select } from "d3-selection";
import { max } from "d3-array";
import XAxis from "../Axes/XAxis";
import YAxis from "../Axes/YAxis";
import formatter from "../../utils/formatters/formatter";
import { mapping } from "../../utils/getVisualizationLabel";
import SpringBar from "../GroupedBar/SpringBar";
import SpringGroup from "../GroupedBar/SpringGroup";
import mobileBreakpoints from "../../styles/mobileBreakpoints";

export default function Waterfall(props) {
  const theme = useTheme();
  const {
    data,
    height,
    width,
    margin,
    padding = 0.3,
    xValueKey,
    yValueKey,
    setTooltip,
    yAxisFormat = "currency-round",
    meta,
  } = props;
  const wWidth = width - margin.left - margin.right;
  const wHeight = height - margin.top - margin.bottom;

  const x = scaleBand().range([0, wWidth]).padding(padding);
  const y = scaleLinear().range([wHeight, 0]);

  // for mobiles
  const rotateDeg = mobileBreakpoints.isMobile ? "-90deg" : "0deg";

  // labels
  const xValueMapping = mapping(meta.fields, xValueKey);
  const yValueMapping = mapping(meta.fields, yValueKey);

  select("svg")
    .attr("width", wWidth + margin.left + margin.right)
    .attr("height", wHeight + margin.top + margin.bottom)
    .append("g");

  // const xDomainArg = data.map(d => d[xValueKey]);
  const xDomainArg = data.map((d, index) => index);
  x.domain(xDomainArg);
  y.domain([0, max(data, (d) => d.end)]);

  const showTooltip = (d, index) => {
    setTooltip({
      x: x(index) + x.bandwidth() / 2,
      y: y(d.end),
      tooltip: [
        { ...xValueMapping, value: d[xValueKey] },
        { ...yValueMapping, value: d[yValueKey] },
      ],
      allValues: d,
    });
  };

  return (
    <>
      <XAxis
        {...props}
        height={wHeight}
        width={wWidth}
        xScale={x}
        values={data}
        tickFormat={(index) => data[index][xValueKey]}
      />

      <YAxis
        {...props}
        yScale={y}
        height={wHeight}
        width={wWidth}
        yAxisGridColor={theme.divider}
        yAxisGrid
        yTicksCount={5}
        yAxisFormat={yAxisFormat}
      />

      {data.map(
        (d, i) =>
          d[yValueKey] != null &&
          !isNaN(d[yValueKey]) && (
            <Fragment key={d[xValueKey] + i}>
              <SpringBar
                data-cy="grouped-bar-bar"
                startPos={y(Math.max(d.start, d.end))}
                y={y(d.end)}
                x={x(i)}
                height={Math.abs(y(d.start) - y(d.end))}
                width={x.bandwidth()}
                color={d.color}
                onMouseOver={() => showTooltip(d, i)}
                onMouseOut={() => setTooltip(null)}
              />

              <SpringGroup y={y(d.end) - 5} x={x(i) + x.bandwidth() / 2}>
                <text
                  data-cy="grouped-bar-label"
                  textAnchor="middle"
                  fontSize="12"
                  fontWeight={"bold"}
                  fill={theme.text.secondary}
                  style={{ transform: `rotate(${rotateDeg})` }}
                >
                  {formatter(d[yValueKey], yAxisFormat)}
                </text>
              </SpringGroup>
            </Fragment>
          )
      )}
    </>
  );
}

import React, { useCallback, useEffect } from "react";
import BluePrintSettings from "./BluePrintSettings";
import { useDispatch, useSelector } from "react-redux";
import { getUniqueRawValues } from "../../../store/actions";
import { useParams } from "react-router";
import { saveBlueprint } from "../../../store/actions/dataSettings/dataSetup";
import Loading from "../../../UI/Loading/Loading";
import usePrevious from "../../../utils/usePrevious";

export default function BluePrintSettingsHome(props) {
  const dispatch = useDispatch();
  const {
    blueprints,
    etlBlueprints,
    uniqueValues,
    defaultDb,
    defaultConnection,
    isInitComplete,
    uniqueValuesTotalCount,
    etlChains,
  } = useSelector((state) => state.dataSetup);
  const { blueprintGroupId } = useParams();

  const activeBlueprints = blueprints.filter(
    (blueprint) => blueprint.groupId === blueprintGroupId
  );

  function handleSave(preset, values, object, outputColumnName) {
    dispatch(
      saveBlueprint(blueprintGroupId, preset, values, object, outputColumnName)
    );
  }

  const getUniqueRawValuesCallback = useCallback(
    (page) => {
      const inputs = activeBlueprints[0].inputs.map((b) => ({
        columnName: b.field,
        tableName: b.table,
      }));

      dispatch(
        getUniqueRawValues(inputs, defaultConnection.uuid, defaultDb, page)
      );
    },
    [activeBlueprints, defaultConnection, defaultDb, dispatch]
  );

  const previousLength = usePrevious(activeBlueprints.length);
  useEffect(() => {
    if (activeBlueprints.length && activeBlueprints.length !== previousLength) {
      getUniqueRawValuesCallback();
    }
  }, [
    dispatch,
    defaultDb,
    activeBlueprints,
    previousLength,
    getUniqueRawValuesCallback,
  ]);

  const chainUuid = (activeBlueprints[0]?.inputs ?? [])[0]?.chainUuid;

  return isInitComplete ? (
    <BluePrintSettings
      sourceValues={uniqueValues}
      saveBlueprint={handleSave}
      blueprints={etlBlueprints}
      getUniqueRawValuesCallback={getUniqueRawValuesCallback}
      uniqueValuesTotalCount={uniqueValuesTotalCount}
      etlChains={etlChains}
      chainUuid={chainUuid}
    />
  ) : (
    <Loading />
  );
}

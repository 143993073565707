import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import { getAggregationPrefix } from "./Column/RegularColumn";
import Input from "../../../../UI/Form/Input/Input";
import { getNestedFormat } from "./helper";
import { columnFormats } from "../../../../utils/constants/constants";
import { findFieldInOverrides } from "../../utils/tableEditorHelper";

const Container = styled.div`
  margin: 20px 0px;
`;

export default function HorizontalTotals({ chart, setChartState, fields }) {
  const { horizontalTotals, overrides } = chart;

  if (!horizontalTotals) {
    return null;
  }

  const { columns, header, type } = horizontalTotals;

  function getColumns() {
    return (fields ?? []).filter((field) => {
      const override = findFieldInOverrides(overrides, field.name);

      return (columns ?? []).includes(
        getAggregationPrefix(override?.aggregation) + field.name
      );
    });
  }

  function setHorizontalTotalColumns(options) {
    const columns = (options ?? []).map((option) => {
      const override = findFieldInOverrides(overrides, option.name);
      return getAggregationPrefix(override?.aggregation) + option.name;
    });

    setChartState({
      ...chart,
      horizontalTotals: { ...horizontalTotals, columns },
    });
  }

  function setHorizontalTotalProp(key, value) {
    setChartState({
      ...chart,
      horizontalTotals: { ...horizontalTotals, [key]: value },
    });
  }

  return (
    <Container>
      <Select
        options={fields ?? []}
        value={getColumns()}
        onChange={setHorizontalTotalColumns}
        isClearable={false}
        isMulti
        menuPlacement="top"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        label="Horizontal total columns"
      />
      <br />
      <Input
        value={header}
        label="Header title"
        onChange={(e) => setHorizontalTotalProp("header", e.target.value)}
      />
      <br />
      <Select
        options={columnFormats}
        value={getNestedFormat(type)}
        onChange={(option) => setHorizontalTotalProp("type", option.value)}
        label="Total format"
      />
    </Container>
  );
}

import getVisualizationLabel from "../../../utils/getVisualizationLabel";
import SelectionListItemIcon from "./SelectionListItemIcon";
import { ColumnListItem } from "./styles";

export default function ColumnSelectionListItem({
  column,
  metaFields,
  onItemClick,
}) {
  const splitColumn = getVisualizationLabel(metaFields, column.name).split(
    "::"
  );
  const text = splitColumn.at(splitColumn.length - 1);

  return (
    <ColumnListItem
      data-cy="selection-list-item"
      key={column.name}
      onClick={() => onItemClick(column, !column.hidden)}
    >
      <SelectionListItemIcon hidden={column.hidden} />
      {text}
    </ColumnListItem>
  );
}

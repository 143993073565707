import React from "react";
import Switcher from "../../UI/Switcher/Switcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const SwitchLabel = styled.div`
  font-size: 14px;
  margin-right: 18px;
  color: ${(props) => props.theme.primary};
  position: relative;
  top: 2px;
`;

export default function ModeSelector(props) {
  const { mode, setMode } = props;

  const doMode = (val) => {
    setMode(mode === "circle" ? "pin" : "circle");
  };
  return (
    <div style={{ display: "flex" }}>
      <SwitchLabel>
        Map View <FontAwesomeIcon icon={["fas", "arrow-right"]} />
      </SwitchLabel>
      <Switcher
        handleSwitchChange={doMode}
        checked={mode === "pin"}
        leftLabel="Value Circles"
        rightLabel="Cluster Markers"
      />
    </div>
  );
}

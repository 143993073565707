import { useState, useEffect } from "react";

function ThreeDotLoader() {
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots < 3 ? prevDots + 1 : 1));
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return <div>{".".repeat(dots)}</div>;
}

export default ThreeDotLoader;

import styled from "@emotion/styled";
import Switcher from "../../../../../UI/Switcher/Switcher";
import Limit from "./Limit";
import DisplayRows from "./DisplayRows";
import LoadMore from "./LoadMore";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  hr {
    width: 100%;
  }
`;

export const Note = styled.div(
  ({ theme, color }) => `
  margin: 10px 0px;
  color: ${theme.notification[color]};
  font-weight: 500;
  font-size: 12px;
  svg {
    margin-left: 5px;
  }
`
);

export default function LimitRows({
  setChartState,
  isParameterized,
  chart,
  theme,
  parameters,
}) {
  const { limit, showSearch } = chart;

  const addOrRemoveLimit = () => {
    setChartState({
      ...chart,
      limit: limit ? null : 20,
      enablePagination: false,
      queryLevelPaginatedSorting: false,
    });
  };

  const changeShowSearch = () => {
    setChartState({
      ...chart,
      showSearch: !showSearch,
    });
  };

  return (
    <Container>
      <Switcher
        rightLabel="Show search field"
        checked={!!showSearch}
        handleSwitchChange={changeShowSearch}
      />
      <br />
      <Switcher
        rightLabel="Limit (number of rows)"
        checked={!!limit || limit === ""}
        handleSwitchChange={addOrRemoveLimit}
      />

      <Limit
        chart={chart}
        setChartState={setChartState}
        isParameterized={isParameterized}
        Note={Note}
      />

      <DisplayRows chart={chart} setChartState={setChartState} Note={Note} />

      <LoadMore
        chart={chart}
        setChartState={setChartState}
        isParameterized={isParameterized}
        Note={Note}
        parameters={parameters}
      />

      <hr color={theme.background.secondary} />
    </Container>
  );
}

import React from "react";
import { animated, useTransition } from "react-spring";
import PropTypes from "prop-types";

export default function EnterTransition({ show, children }) {
  const transitions = useTransition(show, {
    from: { position: "fixed", top: 10, left: -400, zIndex: 506 },
    enter: { left: 76 },
    leave: { left: -400 },
  });

  return transitions(
    (styles, item) =>
      item && <animated.div style={styles}>{children}</animated.div>
  );
}

EnterTransition.propTypes = {
  show: PropTypes.bool,
};

import styled from "@emotion/styled";
import { scrollbar } from "../../../../styles/styledSnippets";
import PropTypes from "prop-types";

export const FilterContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 4px;
  width: ${(props) => props.width || 200}px;
  height: 26px;
  padding: 8px 3px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  font-size: 14px;
  margin-right: 12px;
  position: relative;
  input {
    background: ${(props) =>
      props.theme.name === "dark"
        ? props.theme.background.secondary
        : props.theme.background.primary};
  }
  flex-shrink: 0;
  left: 0;
  div {
    ${(props) => scrollbar(props)}
  }
`;

FilterContainer.propTypes = {
  column: PropTypes.number,
  double: PropTypes.bool,
};

export default FilterContainer;

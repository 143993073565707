import React from "react";
import styled from "@emotion/styled";

const configs = {
  1: { left: 8, animation: 1 },
  2: { left: 8, animation: 2 },
  3: { left: 32, animation: 2 },
  4: { left: 56, animation: 3 },
};

const Container = styled.div`
  position: relative;
  margin: ${(props) => (props.text ? 40 : 0)}px auto; // Increase top margin to accommodate the text
  align-self: center;
  float: left;
  height: 1px;
  width: 80px;
  zoom: ${(props) => (props.size === "small" ? 0.5 : 1)};

  div {
    position: absolute;
    top: ${(props) => props.top}px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.divider ?? "#2196f3"};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
`;

const Circle = styled.div(
  ({ order }) => `
  left: ${configs[order].left}px;
  animation: lds-ellipsis${configs[order].animation} 0.6s infinite;
  animation-timing-function: linear;

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerText = styled.div`
  margin-top: 42px;
  margin-bottom: 18px;
  text-align: center;
  white-space: nowrap;
  color: ${(props) => props.theme.text.primary};
`;

export default function Loading(props) {
  const { top, size, text } = props;
  const spinner = (
    <Container top={top} size={size} data-cy="loading-spinner">
      <Circle order={1} />
      <Circle order={2} />
      <Circle order={3} />
      <Circle order={4} />
    </Container>
  );
  return text ? (
    <Wrapper hasText>
      <SpinnerText>{text}</SpinnerText>
      {spinner}
    </Wrapper>
  ) : (
    spinner
  );
}

Loading.defaultProps = {
  top: 4,
};

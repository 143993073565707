import styled from "@emotion/styled";
import React from "react";
import SimpleTooltip from "../../../../../UI/SimpleTooltip/SimpleTooltip";
import { possibleMathOperations } from "../../../../../utils/constants/constants";

const Operations = styled.div`
  display: flex;
  width: 100px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  span:hover {
    cursor: pointer;
    color: ${(props) => props.theme.primary};
    transform: scale(1.5);
    transition: transform 0.5s;
  }
`;

export default function Operation({ updateState, ops }) {
  const operation = possibleMathOperations.find(
    (pmo) => pmo.value === ops?.type
  )?.sign;

  function updateMathOperationType(type) {
    updateState({ ...ops, type });
  }

  return (
    <SimpleTooltip
      label={
        <Operations>
          {possibleMathOperations.map((pmo) => (
            <span
              onClick={() => updateMathOperationType(pmo.value)}
              title={pmo.label}
              key={pmo.value}
            >
              {pmo.sign}
            </span>
          ))}
        </Operations>
      }
      position="bottom"
      useTooltipBody
      style={{ width: "fit-content" }}
    >
      <span style={{ cursor: "pointer", fontSize: 14, fontWeight: 600 }}>
        {operation}
      </span>
    </SimpleTooltip>
  );
}

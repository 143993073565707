import React from "react";
import styled from "@emotion/styled";

const Container = styled.div(
  ({ theme }) => `
    font-size: 12px;
    font-weight: 600;
    padding: 5px 0px;
    color: ${theme.text.secondary};
`
);

export default function InfoCardSecondaryValue(props) {
  return <Container>{props.value}</Container>;
}

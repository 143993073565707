import Flex from "../../../../UI/Flex/Flex";
import PropTypes from "prop-types";

export default function DrilldownInformation({ title, values, level }) {
  if (!level) {
    return null;
  }

  return (
    <Flex direction="column" width="100%" cy="drill-down-information">
      <span style={{ opacity: 0.7 }}>{title}</span>
      {values.map((value, index) => (
        <span key={value + index} style={{ overflowWrap: "break-word" }}>
          {value}
        </span>
      ))}
    </Flex>
  );
}

DrilldownInformation.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

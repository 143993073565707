import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ImageContainer = styled.div(
  ({ showBackground, theme }) => `
  width: 300px;
  height: 300px;
  ${showBackground && `background: ${theme.divider};`};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
);

const Img = styled.img`
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
`;

export default function ProductStyleImage({ imageLink, title }) {
  const image = imageLink ? (
    <Img src={imageLink} alt={title} data-cy="product-style-image" />
  ) : (
    <FontAwesomeIcon
      icon={["fas", "images"]}
      style={{ fontSize: 40 }}
      data-cy="product-style-image-icon"
    />
  );

  return <ImageContainer showBackground={!imageLink}>{image}</ImageContainer>;
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../UI/Flex/Flex";
import styled from "@emotion/styled";
import BlueprintPagination from "../../DataManagement/Blueprint/components/BlueprintPagination";

export const BackLink = styled.span`
  font-size: 12px;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  svg {
    margin-right: 6px;
    color: ${({ theme }) => theme.primary};
  }
`;

export default function TopElements({
  goBack,
  monitoringHistory,
  dispatch,
  getMonitoringHistory,
}) {
  return (
    <Flex justifyContent="space-between">
      <BackLink onClick={goBack}>
        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
        Back to Monitoring page
      </BackLink>
      <BlueprintPagination
        uniqueValuesTotalCount={monitoringHistory.total}
        onPageChange={(page) => dispatch(getMonitoringHistory(page))}
      />
    </Flex>
  );
}

import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { hexToRgba } from "../../../../styles/colorConvertor";

const EmptyColumnContainer = styled.div`
  width: 160px;
  height: calc(100vh);
  max-height: calc(100vh - 150px);
  background: ${(props) => props.theme.background.secondary};
  border: 2px solid ${(props) => props.theme.notification.infoMain};
  position: absolute;
  right: -160px;
  box-sizing: border-box;
  top: 0;
`;

const Header = styled.div`
  width: 100%;
  height: 26px;
  margin: 0;
  background: ${(props) => hexToRgba(props.theme.notification.infoLight, 0.2)};
`;

const Line = styled.div`
  width: calc(100vw - ${(props) => props.rightPos}px - 276px);
  position: absolute;
  top: 100px;
  right: calc(-100vw + ${(props) => props.rightPos}px + 274px);
  height: 2px;
  background: ${(props) => props.theme.notification.infoMain};
`;

export default function EmptyColumn(props) {
  const ref = useRef(null);
  const [rightPos, setRightPos] = useState(0);
  useEffect(() => {
    const specs = ref.current.getBoundingClientRect();
    setRightPos(specs.right);
  }, [ref]);

  return (
    <EmptyColumnContainer ref={ref}>
      <Header />
      <Line rightPos={rightPos} />
    </EmptyColumnContainer>
  );
}

import React from "react";
import EnterLeft from "./EnterLeft";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Container = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  border-radius: 10px;
  padding: 20px 50px;
  width: 300px;
  margin: auto;
  a {
    margin-top: 12px;
    color: ${(props) => props.theme.text.primary};
  }
`;

export default function LoginElementContainer(props) {
  return (
    <EnterLeft show={props.show}>
      <Container>{props.children}</Container>
    </EnterLeft>
  );
}

LoginElementContainer.defaultProps = {
  show: true,
};

LoginElementContainer.propTypes = {
  show: PropTypes.bool,
};

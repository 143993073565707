import * as actionTypes from "../actions/actionTypes";

let delay = 5000; // ms

export default (store) => (next) => (action) => {
  if (action.meta && action.meta.toasts) {
    const matched = action.meta.toasts.find((t) => t.condition === action.type);
    if (!matched) return next(action);

    // use apiMessages = true to show validation messages from api
    if (action.error && matched.apiMessages) {
      matched.message = action.error.data
        ? Object.values(action.error.data).reduce(
            (acc, curr) => (acc += curr),
            ""
          )
        : action.error.message;
      delay = 8000;
    }

    store.dispatch({
      type: actionTypes.ADD_TOAST,
      toast: matched,
    });

    setTimeout(() => {
      store.dispatch({
        type: actionTypes.REMOVE_TOAST,
        id: matched.id,
      });
    }, delay);
  }
  return next(action);
};

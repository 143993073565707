import React from "react";
import styled from "@emotion/styled";
import { format } from "d3-format";

function color({ colorIndex, theme, reverse }) {
  if (colorIndex === 0) {
    return reverse
      ? theme.notification.successMain
      : theme.notification.errorMain;
  } else if (colorIndex === 2) {
    return reverse
      ? theme.notification.errorMain
      : theme.notification.successMain;
  } else return theme.background.secondary;
}

const Stage = styled.div`
  width: calc(100% - 12px);
  display: flex;
  margin: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  div {
    padding: 4px 6px;
  }
`;

const BandSection = styled.div`
  font-size: 12px;
  color: ${(props) =>
    props.colorIndex === 1 ? props.theme.text.primary : "white"};
  background: ${color};
  width: 30%;
  text-align: center;
`;

export default function Bands(props) {
  const { data, ranges, numberFormat, reverse } = props;
  if (!data?.length) return null;
  const rangeKeys = ranges.map((r) => r.key);
  const rangeStrings = rangeKeys.map((r) => format(numberFormat)(+data[0][r]));
  const valueStrings = [
    rangeStrings[0],
    rangeStrings[0] + " - " + rangeStrings[1],
    rangeStrings[1],
  ];
  return (
    <Stage>
      {valueStrings.map((v, i) => (
        <BandSection key={i} colorIndex={i} reverse={reverse}>
          {v}
        </BandSection>
      ))}
    </Stage>
  );
}

import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import Grouping from "./Groupings";
import Totals from "./Totals";
import { useMemo } from "react";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 97%;
  padding: 10px;
  overflow-y: auto;
  ${scrollbarDe(theme)};
`
);

const RadioGroup = styled.div`
  display: flex;
  margin: 15px 0px;
  align-items: center;
  height: 24px;
  & > label:last-of-type {
    align-self: end;
  }
`;

const Group = styled.div(
  ({ width, background, color, fontSize = 16 }) => `
  height: 25px;
  background: ${background};
  line-height: 25px;
  font-size: ${fontSize}px;
  margin-bottom: 15px;
  width: ${width || "100%"};
  color: ${color};
  text-align: center;
`
);

export default function LayoutSettings(props) {
  const theme = useTheme();
  const {
    chart,
    setChartState,
    immutableChart,
    fields,
    removeDynamicSubTitleColumn,
    removeAnyColumnType,
    isParameterized,
  } = props;

  const repeatingOptions = useMemo(
    () =>
      fields.map((field) => ({
        value: field.name,
        label: field.mapping?.displayName ?? field.name,
        type: field.mapping?.type,
      })),
    [fields]
  );

  return (
    <Container>
      <Grouping
        RadioGroup={RadioGroup}
        Group={Group}
        theme={theme}
        chart={chart}
        setChartState={setChartState}
        repeatingOptions={repeatingOptions}
        immutableChart={immutableChart}
        removeDynamicSubTitleColumn={removeDynamicSubTitleColumn}
        removeAnyColumnType={removeAnyColumnType}
        isParameterized={isParameterized}
      />

      <Totals
        RadioGroup={RadioGroup}
        Group={Group}
        theme={theme}
        chart={chart}
        setChartState={setChartState}
        fields={fields}
      />
    </Container>
  );
}

import {
  CALCULATE_INSIGHTS_FAIL,
  CALCULATE_INSIGHTS_START,
  CALCULATE_INSIGHTS_SUCCESS,
} from "../actions/actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export default function insightsReducer(state = initialState, action) {
  switch (action.type) {
    case CALCULATE_INSIGHTS_START: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      });
    }

    case CALCULATE_INSIGHTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = null;
        draft.data = action.results.data;
      });
    }

    case CALCULATE_INSIGHTS_FAIL: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.error;
        draft.data = null;
      });
    }

    default:
      return state;
  }
}

import React from "react";
import styled from "@emotion/styled";

const InputGroup = styled.div`
  width: 100%;

  & input {
    font-size: 1em;
    margin: 5px 0;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export default function FormInputGroup(props) {
  return <InputGroup>{props.children}</InputGroup>;
}

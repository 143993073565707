import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import useLabel from "../LabelWrapper/useLabel";

const Textarea = styled.textarea(
  ({ theme, resize }) => `
    background-color: ${theme.background.secondary};
    box-sizing: border-box;
    color: ${theme.text.secondary};
    border-radius: 5px;
    font-size: 1em;
    margin: 0px auto;
    min-height: 100px;
    padding: 10px;
    width: 100%;
    resize: ${resize};
`
);

const TextArea = forwardRef((props, ref) => {
  const { cy, ...rest } = props;
  const withLabel = useLabel(props.label, props.required);

  const el = <Textarea ref={ref} {...rest} data-cy={cy} />;
  return withLabel(el);
});

export default TextArea;

import styled from "@emotion/styled";
import { themeGrayIf } from "../../styles/styledSnippets";
import { boxShadows, scrollbar } from "./snippets";

export default styled.div`
  background: ${themeGrayIf(900, 200)};
  box-shadow: ${boxShadows}
  overflow-y: ${(props) => (props.hideScroll ? "hidden" : "auto")};
  position: relative;
  & > div {
    padding-left: ${(props) => (props.third ? 18 : 30)}px;
  }
  ${scrollbar}
`;

import React, { useState } from "react";
import ColumnBuilder from "../ColumnBuilder/ColumnBuilder";
import EmptyColumn from "../DataExplorerUI/EmptyColumn";
import NavigationTable from "../NavigationTable/NavigationTable";
import ColumnActions from "../ActionBlocks/ColumnActions";
import { mapCustomFields } from "./mappedCustomFields";
import produce from "immer";

export default function ExplorerTable(props) {
  const {
    loading,
    results,
    filterApi,
    showFilters,
    fields = [],
    addColumn,
    setAddColumn,
    showOptions,
    setShowOptions,
  } = props;

  const [builderPosition, setBuilderPosition] = useState(0);
  const [columnWidths, setColumnWidths] = useState([]);

  const visibleFields = mapCustomFields(fields, filterApi.overrides);

  const handleSetPosition = (position) => {
    setBuilderPosition(position);
    setAddColumn(true);
  };

  const handleRemoveColumn = (position) => {
    filterApi.removeOverride(position);
    setAddColumn(false);
  };

  const handleMove = (direction, i) => {
    const restricted = checkGuardrails(direction, i);

    if (restricted) return;
    const nextPosition = direction === "left" ? i - 1 : i + 1;
    filterApi.setOrder(i, nextPosition);
    setShowOptions(false);
    setColumnWidths((columnWidths) => {
      return produce(columnWidths, swapWidths);
    });
    function swapWidths(draft) {
      const originalWidth = columnWidths[i];
      draft[i] = columnWidths[nextPosition];
      draft[nextPosition] = originalWidth;
    }
  };
  function checkGuardrails(direction, i) {
    return (
      (direction === "left" && i === 0) ||
      (direction === "right" && i === visibleFields.length - 1)
    );
  }

  const columnActions = (
    <ColumnActions
      handleMove={handleMove}
      setEditing={handleSetPosition}
      setRowSort={filterApi.setRowOrder}
      removeOverride={handleRemoveColumn}
    />
  );

  if (!results || !fields.length) return null;

  return (
    <>
      <NavigationTable
        data={results}
        columns={visibleFields}
        activeKey={builderPosition}
        showActive={addColumn}
        actions={columnActions}
        subtractWidth={
          addColumn ? (filterApi.overrides[builderPosition] ? 280 : 440) : 2
        }
        overrides={filterApi.overrides}
        emptyColumn={
          addColumn && !filterApi.overrides[builderPosition]?.name ? (
            <EmptyColumn />
          ) : null
        }
        showFilters={showFilters}
        loading={loading}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        setBuilderPosition={setBuilderPosition}
        setAddColumn={setAddColumn}
        orders={filterApi.settings?.orders}
        setRowOrder={filterApi.setRowOrder}
        columnWidths={columnWidths}
        setColumnWidths={setColumnWidths}
      />

      {addColumn ? (
        <ColumnBuilder
          fields={fields}
          filterApi={filterApi}
          setVisibility={setAddColumn}
          position={builderPosition}
          setPosition={handleSetPosition}
          addColumn={() => {
            setAddColumn(true);
            setBuilderPosition(filterApi.overrides.length);
          }}
        />
      ) : null}
    </>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import mobileBreakpoints from "../../styles/mobileBreakpoints";

const Container = styled.div`
  display: none;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const MenuItem = styled.div(
  ({ theme }) => `
    color: ${
      theme.menuPrimary
        ? theme.textOnPrimary
          ? theme.textOnPrimary
          : "#FFF"
        : theme.text.secondary
    };
    cursor: pointer;
    display: none;
    text-align: center;
    align-items: center;
    margin: 0 2px;
    padding: 5px;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
    
    @media (max-width: ${mobileBreakpoints.mobile}) {
        display: flex;
    }
`
);

const Burger = styled.div(
  ({ theme }) => `
    cursor: pointer;
    path {
        fill: ${
          theme.menuPrimary
            ? theme.textOnPrimary
              ? theme.textOnPrimary
              : "#FFF"
            : theme.text.secondary
        };
    }
    font-size: 24px;
    padding: 16px;
`
);

export default function MobileLogoutBlock(props) {
  const { handleLogout, setShow } = props;
  return (
    <Container onClick={() => setShow(false)}>
      <MenuItem onClick={handleLogout}>
        <FontAwesomeIcon
          style={{ fontSize: "1em", margin: "5px" }}
          icon={["fas", "sign-out-alt"]}
        />
        <p>Logout</p>
      </MenuItem>
      <Burger>
        <FontAwesomeIcon icon={["fas", "bars"]} color="white" />
      </Burger>
    </Container>
  );
}

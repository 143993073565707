import queryBuilder from "../queryBuilder/queryBuilder";
import * as queryString from "qs";
import * as actionTypes from "../actionTypes";

export const loadFilteredTable = (chart) => (dispatch, getState) => {
  const qs = queryBuilder(
    { ...chart, filters: [], watchlistFilters: chart.filters },
    getState
  );

  const apiPrimary = {
    public: false,
    method: chart.convertGetToPost ? "POST" : "GET",
    endpoint: `api/v1/queries/${chart["queryId"]}/exec?${
      chart.convertGetToPost ? "" : qs
    }`,
    reportAllErrors: true,
    ...(chart.convertGetToPost && {
      payload: queryString.parse(qs, { depth: 10 }),
    }),
  };

  if (!Object.values(chart.filters).length) {
    dispatch({
      type: actionTypes.GET_WATCHLIST_TABLE_DATA_SUCCESS,
      results: { data: [] },
    });
  } else {
    dispatch({
      type: actionTypes.GET_WATCHLIST_TABLE_DATA_START,
      meta: {
        api: apiPrimary,
      },
    });
  }
};

// get list of watchlist by visualization uuid for current user
export const getWatchlist = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_WATCHLISTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `/api/v1/visualizations/${uuid}/watchlist`,
      },
    },
  });
};

// update or create new watchlist
export const setWatchlist =
  (visualizationUuid, filters, isUpdate) => (dispatch) => {
    dispatch({
      type: actionTypes.SET_WATCHLIST_CONFIG_START,
      meta: {
        api: {
          method: isUpdate ? "PUT" : "POST",
          endpoint: `/api/v1/visualizations/${visualizationUuid}/watchlist`,
          payload: {
            items: Object.values(filters).map((item) => ({ filters: item })),
          },
        },
      },
    });
  };

// delete watchlist
export const deleteWatchlist = (visualizationUuid) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_WATCHLIST_CONFIG_START,
    meta: {
      api: {
        method: "DELETE",
        endpoint: `/api/v1/visualizations/${visualizationUuid}/watchlist`,
      },
    },
  });
};

import { useEffect, useState } from "react";
import Flex from "../../../UI/Flex/Flex";
import Input from "../../../UI/Form/Input/Input";

export default function DateFilters({ dispatch, getMonitoringHistory }) {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    if (start && end) {
      dispatch(getMonitoringHistory(1, start, end));
    }
  }, [start, end, dispatch, getMonitoringHistory]);

  return (
    <Flex width="400px" justifyContent="space-between">
      <Input
        type="date"
        label="From"
        style={{ width: 140 }}
        value={start}
        onChange={(e) => setStart(e.target.value)}
        max={end}
      />
      <Input
        type="date"
        label="To"
        style={{ width: 140 }}
        value={end}
        onChange={(e) => setEnd(e.target.value)}
        min={start}
      />
    </Flex>
  );
}

import { aggregations } from "../constants/constants";

/**
 * Gets the field key a field based on the query field config.
 */
export function getFieldKey(field) {
  const aggregationType =
    typeof field.aggregation === "number"
      ? field.aggregation
      : field.aggregation?.type;

  if (!aggregationType) {
    return field.name;
  }

  const aggregation = aggregations.find((v) => v.value === aggregationType);

  return aggregation.prefix + field.name;
}

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import TableVisualization from "../../../charts/TableView/TableVisualization";
import IOButton from "../../../UI/Form/Button/IOButton";
import TextArea from "../../../UI/Form/Textarea/Textarea";

const PreviewTypes = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 15px;
  & > svg {
    margin-right: 10px;
    cursor: pointer;
  }
`;

const TableWrapper = styled.div`
  height: 370px;
  overflow: auto;
`;
const spaces = 4;

export default function JsonConfiguration({ onClose, config, setConfig }) {
  const { tablePreview } = useSelector((state) => state.charts);
  const [previewType, setPreviewType] = useState("json");
  const theme = useTheme();

  const removeEmptyProps = (config) =>
    Object.fromEntries(Object.entries(config).filter(([_, v]) => !isEmpty(v)));
  const chart = removeEmptyProps(config);
  const json = JSON.stringify(removeEmptyProps(config), null, spaces);

  return (
    <>
      <PreviewTypes>
        <FontAwesomeIcon
          icon={["fas", "code"]}
          onClick={() => setPreviewType("json")}
          style={{
            color:
              previewType === "json" ? theme.primary : theme.text.secondary,
          }}
        />
        {tablePreview.data && (
          <FontAwesomeIcon
            icon={["fas", "th"]}
            onClick={() => setPreviewType("table")}
            style={{
              color:
                previewType === "table" ? theme.primary : theme.text.secondary,
            }}
          />
        )}
      </PreviewTypes>
      {previewType === "json" ? (
        <TextArea
          rows="23"
          value={json}
          readOnly
          resize={"none"}
          // onChange={({ target }) => setConfig(target.value)}
        />
      ) : (
        <TableWrapper>
          <TableVisualization chart={{ ...chart, ...tablePreview }} />
        </TableWrapper>
      )}
      <br />
      <IOButton cancel onClick={onClose}>
        Cancel
      </IOButton>
    </>
  );
}

import {
  endOfMonth,
  endOfWeek,
  parseISO,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import format from "date-fns/format";
import { store } from "../../../store";

export const getConditionalFilters = (filters) => {
  const { menuFilters, booleanFilters, dateFilters } = store.getState().layout;

  const selectedFilters = filters.reduce((acc, curr) => {
    let applyWhenChecked = null;
    const combined = [
      ...(menuFilters || []).map((x) => x.values),
      ...(booleanFilters || []),
    ].flat();

    const isMoreThenOneSelected =
      combined.filter((x) => x.key === curr.applyWhen && x.checked).length > 1;

    if (!isMoreThenOneSelected) {
      applyWhenChecked =
        combined.find((x) => x.key === curr.applyWhen && x.checked) ||
        !curr.applyWhen;
    }

    const datesBased = dateFilters && dateFilters.start.type === curr.applyWhen;

    if (datesBased) {
      acc.push(collectFilters(curr));
    }

    if (applyWhenChecked) {
      acc.push(collectFilters(curr, Boolean(+applyWhenChecked.value)));
    }

    return acc;
  }, []);

  const hasPriority = selectedFilters.every(
    (filter) => !isNaN(filter.priority)
  );

  if (selectedFilters.length > 1 && hasPriority) {
    return selectedFilters.filter((filter) => filter.priority);
  }

  return selectedFilters;
};

const collectFilters = (filter, applyWhenValue) => {
  const { dateFilters } = store.getState().layout;
  const { start, end } = dateFilters || {};
  const defaultDate = format(new Date(), "yyyy-MM-dd");
  const type = filter.conditionalType || filter.value;

  switch (type) {
    case "FROM_START_DATE_MINUS_ONE_YEAR": {
      const date = parseISO(start?.value || defaultDate);
      const calcYear = format(startOfMonth(subYears(date, 1)), "yyyy-MM-dd");

      return {
        ...filter,
        operator: applyWhenValue ? "<=" : ">",
        type: filter.type,
        value: calcYear,
      };
    }

    case "FROM_END_DATE_MINUS_ONE_YEAR": {
      const date = parseISO(end?.value || defaultDate);
      const calcYear = format(subYears(date, 1), "yyyy-MM-dd");

      return {
        ...filter,
        type: filter.type,
        value: calcYear,
      };
    }

    case "FROM_START_DATE_YEAR_MINUS_ONE_YEAR": {
      const date = parseISO(start?.value || defaultDate);
      const calcYear = format(startOfYear(subYears(date, 1)), "yyyy-MM-dd");

      return {
        ...filter,
        operator: applyWhenValue ? "<=" : ">",
        type: filter.type,
        value: calcYear,
      };
    }

    case "MIN_MAX_RANGE": {
      return [
        {
          type: filter.type,
          operator: ">=",
          value: filter.min,
        },
        {
          type: filter.type + SPECIAL_FILTER_INDEX_DIVIDER,
          operator: "<=",
          value: filter.max,
        },
      ];
    }

    case "BASED_ON_END_DATE": {
      return {
        ...filter,
        type: filter.type,
        operator: filter.operator,
        value: end.value,
      };
    }

    case "LAST_6_QUARTERS": {
      const date = startOfMonth(subQuarters(new Date(), 5));
      return {
        ...filter,
        value: format(date, "yyyy-MM-dd"),
      };
    }

    case "WEEK": {
      const weekStartsOn = filter.weekStartsOn || 0;
      const startWeek = startOfWeek(subWeeks(new Date(), filter.offset || 0), {
        weekStartsOn,
      });
      const endWeek = endOfWeek(subWeeks(new Date(), filter.offset || 0), {
        weekStartsOn,
      });

      return [
        {
          type: filter.type,
          value: format(startWeek, "yyyy-MM-dd"),
        },
        {
          type: filter.type,
          value: format(endWeek, "yyyy-MM-dd"),
        },
      ];
    }

    case "MONTH": {
      const startMonth = startOfMonth(
        subMonths(new Date(), filter.offset || 0)
      );
      const endMonth = endOfMonth(subMonths(new Date(), filter.offset || 0));
      return [
        { type: filter.type, value: format(startMonth, "yyyy-MM-dd") },
        { type: filter.type, value: format(endMonth, "yyyy-MM-dd") },
      ];
    }

    case "CURRENT_MONTH": {
      return {
        type: filter.type,
        operator: filter.operator,
        value: format(startOfMonth(new Date()), filter.format || "yyyy-MM-dd"),
      };
    }

    default:
      return filter;
  }
};

// Constant used as a special value to indicate a divider in filter indices
// used in multi range slider where we need to use operators
export const SPECIAL_FILTER_INDEX_DIVIDER = "SPECIAL_FILTER_INDEX_DIVIDER";

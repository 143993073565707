import React from "react";
import { NodeContainer } from "./NodeContainer";
import { Link } from "react-router-dom";

export default function BlueprintOutputTable(props) {
  const { input, blueprintGroupIndex } = props;
  const chainUuid = input.chainUuid;
  // set will be helpful for additional info maybe
  return (
    <Link
      to={`/admin/data-blueprint/${chainUuid}/preview/${blueprintGroupIndex}`}
    >
      <NodeContainer
        data-cy="output-table-preview"
        style={{ marginBottom: 12 }}
      >
        {props.input.table + "_clean_" + blueprintGroupIndex}
      </NodeContainer>
    </Link>
  );
}

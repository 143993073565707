import format from "date-fns/format";
const formatString = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'";

export const ourDate = (date) => {
  return format(date, formatString);
};

export const dayFormat = (date) => {
  return format(date, "yyyy-MM-dd");
};

export default function (data, config) {
  const calculateAverage = (values) => {
    return values.reduce((a, b) => a + b, 0) / values.length;
  };

  const getValue = (config, data) => {
    const values = data
      .filter((v, i) => (config.limit ? i < config.limit : true))
      .map((item) => +item[config.key]);
    if (config.formula === "average") {
      return calculateAverage(values);
    } else {
      return values[0];
    }
  };

  const value1 = getValue(config.value1, data);
  const value2 = getValue(config.value2, data);

  if (config.formula === "division") {
    return value1 / value2;
  } else if (config.formula === "percent-difference") {
    return Number(((value1 - value2) / value2).toFixed(config.precision));
  }

  return undefined;
}

import { cloneDeep } from "lodash-es";
import { setGroupingKeyMapping } from "../TableVisualizationCollection";
import { getPinnedRows } from "./tableMapper";

export const sortDynamicGroupingValues = (
  rows,
  target,
  groupingKey,
  value,
  direction,
  pinnedRows,
  groupingKeyMapping
) => {
  const cloned = cloneDeep(rows);

  const commonArgs = {
    value,
    groupingKey,
    target,
    direction,
    groupingKeyMapping,
  };

  if (!pinnedRows) {
    return cloned.sort((a, b) => sortFn({ a, b, ...commonArgs }));
  }

  const { pinned, index } = cutPinnedRow(rows, pinnedRows);

  const sorted = cloned
    .filter((_, i) => filterFn(i, index, pinned))
    .sort((a, b) => sortFn({ a, b, ...commonArgs }));

  if (pinnedRows[0]?.position === "top") {
    return [pinned, ...sorted];
  }

  return [...sorted, pinned];
};

function getCompareValue(row, value, groupingKey, target, groupingKeyMapping) {
  return (
    row[value] ||
    (row.values.find(
      (v) =>
        setGroupingKeyMapping(v[groupingKey], null, groupingKeyMapping) ===
        value
    ) ?? {})[target]
  );
}

function compareNumbers(a, b, direction) {
  return direction === "ASC" ? a - b : b - a;
}

function compareStrings(a, b, direction) {
  return direction === "ASC"
    ? (a ?? "").localeCompare(b)
    : (b ?? "").localeCompare(a);
}

function compareDates(a, b, direction) {
  if (direction === "ASC") {
    return new Date(a ?? date) - new Date(b ?? date);
  }

  return new Date(b ?? date) - new Date(a ?? date);
}

function filterFn(i, index, pinned) {
  if (pinned) {
    return i !== index;
  }

  return true;
}

function sortFn({
  a,
  b,
  value,
  groupingKey,
  target,
  direction,
  groupingKeyMapping,
}) {
  const x =
    getCompareValue(a, value, groupingKey, target, groupingKeyMapping) ?? 0;
  const y =
    getCompareValue(b, value, groupingKey, target, groupingKeyMapping) ?? 0;

  if (!isNaN(+x) && !isNaN(+y)) {
    return compareNumbers(+x, +y, direction);
  }

  if (!isNaN(Date.parse(x)) || !isNaN(Date.parse(y))) {
    return compareDates(x, y, direction);
  }

  return compareStrings(x, y, direction);
}

function cutPinnedRow(rows, pinnedRows = []) {
  return {
    pinned: rows.find((row) =>
      row.values.every((item) => getPinnedRows(item, pinnedRows))
    ),
    index: rows.findIndex((row) =>
      row.values.every((item) => getPinnedRows(item, pinnedRows))
    ),
  };
}

const date = "1900-01-01"; // if date is undefined then use this

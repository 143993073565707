import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Input from "../../../UI/Form/Input/Input";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const FixedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 99%;
  border: 1px solid ${(props) => props.theme.divider};
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;

  & > svg:first-of-type {
    position: absolute;
    top: 2px;
    right: 5px;
    color: ${(props) => props.theme.notification.errorMain};
    font-size: 12px;
    cursor: pointer;
  }
  & > svg:last-of-type {
    position: absolute;
    top: 2px;
    right: 20px;
    color: ${(props) => props.theme.primary};
    font-size: 12px;
    cursor: pointer;
  }
`;

const Headers = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 30%;
  }
`;
const Values = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;

  & > div {
    width: 40%;
  }
  & > svg {
    position: absolute;
    right: 3%;
    top: 32px;
    cursor: pointer;
    color: ${(props) => props.theme.notification.errorMain};
  }
`;

const Color = styled.div`
  background: ${(props) => props.color};
  width: 29px !important;
  height: 29px;
  position: absolute;
  right: 11px;
  top: 38px;
`;

export default function FixedRows({ state, setState }) {
  if (!state.chart.fixedRows) {
    return null;
  }

  const headerChange = (value, key, index) => {
    const fixedRows = [...state.chart.fixedRows];
    fixedRows[index][key] = value;

    setState({ ...state, chart: { ...state.chart, fixedRows } });
  };

  const addValueAndType = (index) => {
    const fixedRows = [...state.chart.fixedRows];
    fixedRows[index].values.push({ type: "match", value: "" });

    setState({ ...state, chart: { ...state.chart, fixedRows } });
  };

  const valueChange = (value, key, rIndex, vIndex) => {
    const fixedRows = [...state.chart.fixedRows];
    fixedRows[rIndex].values[vIndex][key] = value;

    setState({ ...state, chart: { ...state.chart, fixedRows } });
  };

  const removeRow = (index) => {
    setState({
      ...state,
      chart: {
        ...state.chart,
        fixedRows: state.chart.fixedRows.filter((_, i) => i !== index),
      },
    });
  };

  const removeValue = (rIndex, vIndex) => {
    const fixedRows = [...state.chart.fixedRows];
    fixedRows[rIndex].values = fixedRows[rIndex].values.filter(
      (_, i) => i !== vIndex
    );

    setState({ ...state, chart: { ...state.chart, fixedRows } });
  };

  return (
    <>
      <span>Fixed Rows</span>
      <br />
      <Container>
        {state.chart.fixedRows.map((fr, index) => (
          <FixedRow key={"fixed_row_" + index}>
            <Headers>
              <Input
                label="Type"
                value={fr.type}
                onChange={({ target }) =>
                  headerChange(target.value, "type", index)
                }
              />
              <Input
                label="Label"
                value={fr.label}
                onChange={({ target }) =>
                  headerChange(target.value, "label", index)
                }
              />
              <Input
                label="Color"
                value={fr.color}
                onChange={({ target }) =>
                  headerChange(target.value, "color", index)
                }
              />
              <Color color={fr.color} />
            </Headers>

            <br />

            {fr.values?.map((v, vIndex) => (
              <Values key={"fixed_value_" + vIndex}>
                <Input
                  label="Type"
                  value={v.type}
                  onChange={({ target }) =>
                    valueChange(target.value, "type", index, vIndex)
                  }
                />
                <Input
                  label="Value"
                  value={v.value}
                  onChange={({ target }) =>
                    valueChange(target.value, "value", index, vIndex)
                  }
                />
                <FontAwesomeIcon
                  icon={["fa", "times-circle"]}
                  onClick={() => removeValue(index, vIndex)}
                />
              </Values>
            ))}

            <br />

            <FontAwesomeIcon
              icon={["fa", "times"]}
              onClick={() => removeRow(index)}
            />
            <FontAwesomeIcon
              icon={["fa", "plus"]}
              onClick={() => addValueAndType(index)}
            />
          </FixedRow>
        ))}
      </Container>
    </>
  );
}
